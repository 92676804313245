// source: automation-inspection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.automation.InspectionAutomationPreset', null, global);
goog.exportSymbol('proto.automation.InspectionSettings', null, global);
goog.exportSymbol('proto.automation.LaunchInspectionAutomationRequest', null, global);
goog.exportSymbol('proto.automation.OpportunityInspectionExportRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.LaunchInspectionAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.LaunchInspectionAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.LaunchInspectionAutomationRequest.displayName = 'proto.automation.LaunchInspectionAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.InspectionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.InspectionSettings.repeatedFields_, null);
};
goog.inherits(proto.automation.InspectionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.InspectionSettings.displayName = 'proto.automation.InspectionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.InspectionAutomationPreset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.InspectionAutomationPreset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.InspectionAutomationPreset.displayName = 'proto.automation.InspectionAutomationPreset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.OpportunityInspectionExportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.OpportunityInspectionExportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.OpportunityInspectionExportRequest.displayName = 'proto.automation.OpportunityInspectionExportRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.LaunchInspectionAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.LaunchInspectionAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.LaunchInspectionAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignBatch: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    settings: (f = msg.getSettings()) && proto.automation.InspectionSettings.toObject(includeInstance, f),
    allOpportunities: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.LaunchInspectionAutomationRequest}
 */
proto.automation.LaunchInspectionAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.LaunchInspectionAutomationRequest;
  return proto.automation.LaunchInspectionAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.LaunchInspectionAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.LaunchInspectionAutomationRequest}
 */
proto.automation.LaunchInspectionAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCampaignBatch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 7:
      var value = new proto.automation.InspectionSettings;
      reader.readMessage(value,proto.automation.InspectionSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllOpportunities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.LaunchInspectionAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.LaunchInspectionAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.LaunchInspectionAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCampaignBatch();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.automation.InspectionSettings.serializeBinaryToWriter
    );
  }
  f = message.getAllOpportunities();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 campaign_batch = 2;
 * @return {number}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getCampaignBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setCampaignBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 organization_id = 4;
 * @return {number}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 pipeline_id = 5;
 * @return {number}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 user_id = 6;
 * @return {number}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional InspectionSettings settings = 7;
 * @return {?proto.automation.InspectionSettings}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getSettings = function() {
  return /** @type{?proto.automation.InspectionSettings} */ (
    jspb.Message.getWrapperField(this, proto.automation.InspectionSettings, 7));
};


/**
 * @param {?proto.automation.InspectionSettings|undefined} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
*/
proto.automation.LaunchInspectionAutomationRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool all_opportunities = 8;
 * @return {boolean}
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.getAllOpportunities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.LaunchInspectionAutomationRequest} returns this
 */
proto.automation.LaunchInspectionAutomationRequest.prototype.setAllOpportunities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.InspectionSettings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.InspectionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.InspectionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.InspectionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.InspectionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instructionList: jspb.Message.toObjectList(msg.getInstructionList(),
    common_pb.InstructionPair.toObject, includeInstance),
    includeSummaries: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.InspectionSettings}
 */
proto.automation.InspectionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.InspectionSettings;
  return proto.automation.InspectionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.InspectionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.InspectionSettings}
 */
proto.automation.InspectionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 3:
      var value = new common_pb.InstructionPair;
      reader.readMessage(value,common_pb.InstructionPair.deserializeBinaryFromReader);
      msg.addInstruction(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.InspectionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.InspectionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.InspectionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.InspectionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstructionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_pb.InstructionPair.serializeBinaryToWriter
    );
  }
  f = message.getIncludeSummaries();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string model = 1;
 * @return {string}
 */
proto.automation.InspectionSettings.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.InspectionSettings} returns this
 */
proto.automation.InspectionSettings.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated common.InstructionPair instruction = 3;
 * @return {!Array<!proto.common.InstructionPair>}
 */
proto.automation.InspectionSettings.prototype.getInstructionList = function() {
  return /** @type{!Array<!proto.common.InstructionPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.InstructionPair, 3));
};


/**
 * @param {!Array<!proto.common.InstructionPair>} value
 * @return {!proto.automation.InspectionSettings} returns this
*/
proto.automation.InspectionSettings.prototype.setInstructionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.InstructionPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.InstructionPair}
 */
proto.automation.InspectionSettings.prototype.addInstruction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.InstructionPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.InspectionSettings} returns this
 */
proto.automation.InspectionSettings.prototype.clearInstructionList = function() {
  return this.setInstructionList([]);
};


/**
 * optional bool include_summaries = 4;
 * @return {boolean}
 */
proto.automation.InspectionSettings.prototype.getIncludeSummaries = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.InspectionSettings} returns this
 */
proto.automation.InspectionSettings.prototype.setIncludeSummaries = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.InspectionAutomationPreset.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.InspectionAutomationPreset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.InspectionAutomationPreset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.InspectionAutomationPreset.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    automationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    settings: (f = msg.getSettings()) && proto.automation.InspectionSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.InspectionAutomationPreset}
 */
proto.automation.InspectionAutomationPreset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.InspectionAutomationPreset;
  return proto.automation.InspectionAutomationPreset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.InspectionAutomationPreset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.InspectionAutomationPreset}
 */
proto.automation.InspectionAutomationPreset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutomationId(value);
      break;
    case 3:
      var value = new proto.automation.InspectionSettings;
      reader.readMessage(value,proto.automation.InspectionSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.InspectionAutomationPreset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.InspectionAutomationPreset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.InspectionAutomationPreset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.InspectionAutomationPreset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAutomationId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.automation.InspectionSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_default = 1;
 * @return {boolean}
 */
proto.automation.InspectionAutomationPreset.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.InspectionAutomationPreset} returns this
 */
proto.automation.InspectionAutomationPreset.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 automation_id = 2;
 * @return {number}
 */
proto.automation.InspectionAutomationPreset.prototype.getAutomationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.InspectionAutomationPreset} returns this
 */
proto.automation.InspectionAutomationPreset.prototype.setAutomationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional InspectionSettings settings = 3;
 * @return {?proto.automation.InspectionSettings}
 */
proto.automation.InspectionAutomationPreset.prototype.getSettings = function() {
  return /** @type{?proto.automation.InspectionSettings} */ (
    jspb.Message.getWrapperField(this, proto.automation.InspectionSettings, 3));
};


/**
 * @param {?proto.automation.InspectionSettings|undefined} value
 * @return {!proto.automation.InspectionAutomationPreset} returns this
*/
proto.automation.InspectionAutomationPreset.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.InspectionAutomationPreset} returns this
 */
proto.automation.InspectionAutomationPreset.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.InspectionAutomationPreset.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.OpportunityInspectionExportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.OpportunityInspectionExportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.OpportunityInspectionExportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.OpportunityInspectionExportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.OpportunityInspectionExportRequest}
 */
proto.automation.OpportunityInspectionExportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.OpportunityInspectionExportRequest;
  return proto.automation.OpportunityInspectionExportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.OpportunityInspectionExportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.OpportunityInspectionExportRequest}
 */
proto.automation.OpportunityInspectionExportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.OpportunityInspectionExportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.OpportunityInspectionExportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.OpportunityInspectionExportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.OpportunityInspectionExportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.automation.OpportunityInspectionExportRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.OpportunityInspectionExportRequest} returns this
 */
proto.automation.OpportunityInspectionExportRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.OpportunityInspectionExportRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.OpportunityInspectionExportRequest} returns this
 */
proto.automation.OpportunityInspectionExportRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pipeline_id = 3;
 * @return {number}
 */
proto.automation.OpportunityInspectionExportRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.OpportunityInspectionExportRequest} returns this
 */
proto.automation.OpportunityInspectionExportRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.automation);
