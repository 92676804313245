/**
 * NOT CHECKED
 */
import React, { ReactNode, useState } from 'react';
import cn from 'class-names';

import Display from '../../components/Display/Display';
import { SVGIcon, SvgIconType } from '../Icon/Icon';

import './DropdownPanel.scss';

type DropdownPanelStylesType = {
  container?: React.CSSProperties;
  containerOpen?: React.CSSProperties;
  content?: React.CSSProperties;
};

type DropdownPanelClassNamesType = {
  header?: string;
  container?: string;
  containerOpen?: string;
  content?: string;
};

type DropdownPanelPropsType = {
  headerIcon?: string;
  headerText: ReactNode;
  children: ReactNode;
  classNames?: DropdownPanelClassNamesType;
  styles?: DropdownPanelStylesType;
  initiallyOpen?: boolean;
  disabled?: boolean;
};

const DropdownPanel = ({
  headerIcon,
  headerText,
  children,
  classNames,
  initiallyOpen = true,
  styles,
  disabled,
}: DropdownPanelPropsType): JSX.Element => {
  const [isOpen, changeIsOpen] = useState(initiallyOpen);

  const handleHeaderClick = () => (disabled ? null : changeIsOpen((prevState) => !prevState));

  const cnDropdownPanel = cn('dropdown-panel', {
    [classNames?.container]: !!classNames?.container,
    'dropdown-panel--opened': isOpen,
    [classNames?.containerOpen]: isOpen && !!classNames?.containerOpen,
  });

  return (
    <div
      className={cnDropdownPanel}
      style={{ ...styles?.container, ...(isOpen ? styles?.containerOpen : {}) }}
    >
      <div
        className={cn('dropdown-panel__header', { [classNames?.header]: !!classNames?.header })}
        style={{ height: disabled ? 'auto' : '', cursor: disabled ? 'default' : '' }}
        onClick={handleHeaderClick}
      >
        {headerIcon ? (
          <span className="dropdown-panel__header-icon">
            <SVGIcon icon={headerIcon as SvgIconType} />
          </span>
        ) : null}
        {headerText}
        {!disabled && (
          <span className="dropdown-panel__header-chevron">
            <SVGIcon icon="chevron" />
          </span>
        )}
      </div>
      <Display isVisible={isOpen}>
        <div
          className={cn('dropdown-panel__content', {
            [classNames?.content]: !!classNames?.content,
          })}
          style={styles?.content}
        >
          {children}
        </div>
      </Display>
    </div>
  );
};

export default DropdownPanel;
