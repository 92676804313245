import React from 'react';

export default function ({ color = '#221CB6', size = 22 }: { color?: string; size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26598_199)">
        <path
          d="M5.01453 18.538C6.19559 16.5002 8.43366 15.125 11 15.125C13.5663 15.125 15.8044 16.5002 16.9855 18.538"
          stroke={color}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 20.625C16.3157 20.625 20.625 16.3157 20.625 11C20.625 5.68426 16.3157 1.375 11 1.375C5.68426 1.375 1.375 5.68426 1.375 11C1.375 16.3157 5.68426 20.625 11 20.625Z"
          stroke={color}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6939 7.45365C12.5995 7.41319 12.5243 7.33797 12.4838 7.24356L11.3677 4.63912C11.2291 4.31588 10.7709 4.31588 10.6323 4.63912L9.51615 7.24356C9.47569 7.33797 9.40047 7.41319 9.30606 7.45365L6.70162 8.56984C6.37838 8.70837 6.37838 9.16663 6.70162 9.30516L9.30606 10.4213C9.40047 10.4618 9.47569 10.537 9.51615 10.6314L10.6323 13.2359C10.7709 13.5591 11.2291 13.5591 11.3677 13.2359L12.4838 10.6314C12.5243 10.537 12.5995 10.4618 12.6939 10.4213L15.2984 9.30516C15.6216 9.16663 15.6216 8.70837 15.2984 8.56984L12.6939 7.45365Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_26598_199">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
