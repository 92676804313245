export enum OpenAiModel {
  O3Mini = 'o3-mini',
  O1 = 'o1',
  GPT4o = 'gpt-4o',
  GPT4oMini = 'gpt-4o-mini',
}

export const getOpenAiModelOptions = (): { value: OpenAiModel; label: string }[] => [
  { value: OpenAiModel.O3Mini, label: 'o3-mini' },
  { value: OpenAiModel.O1, label: 'o1' },
  { value: OpenAiModel.GPT4o, label: 'gpt-4o' },
  { value: OpenAiModel.GPT4oMini, label: 'gpt-4o-mini' },
];

const stringToEnum = (mode: string): OpenAiModel | undefined => {
  if (Object.values(OpenAiModel).includes(mode as OpenAiModel)) {
    return mode as OpenAiModel;
  }
  return undefined;
};

export const getPriceByModel = (model: string): number => {
  const modelEnum = stringToEnum(model);
  switch (modelEnum) {
    case OpenAiModel.O3Mini:
      return 5;
    case OpenAiModel.O1:
      return 15;
    case OpenAiModel.GPT4o:
      return 5;
    case OpenAiModel.GPT4oMini:
      return 1;
    default:
      return 5;
  }
};
