/**
 * NOT CHECKED
 */
import React, { ReactNode } from 'react';

import './Radio.scss';

type CheckboxPropsType = {
  children?: ReactNode;
  onChange: any;
  isChecked: boolean;
  value: string | number;
  className?: string;
  size?: number;
  isDisabled?: boolean;
};

function Radio({
  children = null,
  onChange,
  value,
  isChecked = false,
  className = '',
  size = 16,
  isDisabled = false,
}: CheckboxPropsType) {
  return (
    <label className={`radio ${className}`}>
      <input
        onChange={onChange}
        className="radio__field"
        type="radio"
        checked={isChecked}
        value={value}
        disabled={isDisabled}
      />
      {children}
      <span className="radio__checkmark" style={{ width: size, height: size }} />
      <span
        className="radio__checkmark-inner"
        style={{
          width: size - 6,
          height: size - 6,
          top: 3,
          left: 3,
          bottom: 3,
        }}
      />
    </label>
  );
}

export default Radio;
