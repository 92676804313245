import React from 'react';

import './InfoCard.css';

const InfoCard = ({
  text,
  isActive,
  onClick,
}: {
  text: string,
  isActive?: boolean,
  onClick?: (any) => void;
}): JSX.Element => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div className="info-card" onClick={handleClick}>
      <p>{text}</p>
    </div>
  )
};

export default InfoCard;