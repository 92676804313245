// eslint-disable-next-line import/no-extraneous-dependencies
import { Location, History } from 'history';

import { IntegrationSettingsType, IntegrationType } from '@ts/userOrganization.types';

import { AppIntegrationRequest } from 'respona_api/generated/user-organization_pb';

import {
  AhrefIntegrationSettings,
  ContactSearchSource as CONTACT_SEARCH_SOURCE,
  HunterIntegrationSettings,
  IntegrationType as INTEGRATION_TYPE,
  IntegrationTypeMap,
  MozIntegrationSettings,
  RocketReachIntegrationSettings,
  SemrushIntegrationSettings,
  SnovioIntegrationSettings,
} from 'respona_api/generated/common_pb';

import { parseUrlSearch } from '@utils/url.utils';
import { redirectUserTo } from '@utils/historyHandler';
import { INTEGRATION_MODES } from '@constants/app-modes';

const integrationDescriptionTypeMaps = {
  backlinks: {
    [INTEGRATION_TYPE.AHREFS_INTEGRATION]: {
      key: 'rows_left',
      title: 'Rows Remaining:',
    },
    [INTEGRATION_TYPE.SEMRUSH_INTEGRATION]: {
      key: 'remaining',
      title: 'Searches remaining:',
    },
    [INTEGRATION_TYPE.MOZ_INTEGRATION]: {
      key: 'lookupCreditBalance',
      title: 'Balance:',
    },
  },
  default: {
    [CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE]: null,
    [CONTACT_SEARCH_SOURCE.HUNTER_SEARCH_SOURCE]: {
      key: 'requestsSearchesAvailable',
      title: 'Searches Available:',
      get(data) {
        return String(
          data?.find((row) => row.name === 'requestsSearchesAvailable')?.value -
            data?.find((row) => row.name === 'requestsSearchesUsed')?.value
        );
      },
    },
    [CONTACT_SEARCH_SOURCE.SNOVIO_SEARCH_SOURCE]: { key: 'balance', title: 'Balance:' },
    [CONTACT_SEARCH_SOURCE.ROCKET_REACH_SEARCH_SOURCE]: {
      key: 'lookupCreditBalance',
      title: 'Lookup Credit Balance:',
    },
  },
};

export const customIntegrationDetailsTitleMap = {
  [CONTACT_SEARCH_SOURCE.HUNTER_SEARCH_SOURCE]: {
    authToken: 'API Key',
  },
  [CONTACT_SEARCH_SOURCE.SNOVIO_SEARCH_SOURCE]: {
    clientId: 'API User ID',
    clientSecret: 'API Secret',
  },
  [INTEGRATION_TYPE.MOZ_INTEGRATION]: {
    accessId: 'Access ID',
    secretKey: 'Secret Key',
  },
};

class IntegrationsService {
  getIntegrationSettings = (integration: IntegrationType) => {
    switch (integration?.type) {
      case INTEGRATION_TYPE.AHREFS_INTEGRATION:
        return {};
      case INTEGRATION_TYPE.HUNTER_INTEGRATION:
        return {
          authToken: integration.hunter?.authToken || '',
        };
      case INTEGRATION_TYPE.ROCKET_REACH_INTEGRATION:
        return {
          authToken: integration.rocketReach?.authToken || '',
        };
      case INTEGRATION_TYPE.SNOVIO_INTEGRATION:
        return {
          clientId: integration.snovio?.clientId || '',
          clientSecret: integration.snovio?.clientSecret || '',
        };
      case INTEGRATION_TYPE.MOZ_INTEGRATION:
        return {
          accessId: integration.moz?.accessId || '',
          secretKey: integration.moz?.secretKey || '',
        };
      case INTEGRATION_TYPE.SEMRUSH_INTEGRATION:
        return {};
      default:
        return null;
    }
  };

  getContactSourceAvailableIntegrations = (
    integrations: IntegrationType[],
    backlinksIntegrations?: boolean
  ): (IntegrationType & { subtitleDetails?: string })[] => {
    const data = integrations
      .slice()
      .filter((item) => item.id !== -1)
      .filter((item) =>
        backlinksIntegrations
          ? [
              INTEGRATION_TYPE.AHREFS_INTEGRATION,
              INTEGRATION_TYPE.SEMRUSH_INTEGRATION,
              INTEGRATION_TYPE.MOZ_INTEGRATION,
            ].includes(
              // @ts-ignore
              item.type
            )
          : [
              INTEGRATION_TYPE.HUNTER_INTEGRATION,
              INTEGRATION_TYPE.SNOVIO_INTEGRATION,
              INTEGRATION_TYPE.ROCKET_REACH_INTEGRATION,
            ].includes(
              // @ts-ignore
              item.type
            )
      );

    if (backlinksIntegrations) {
      return (
        data.map((item) =>
          this.fillIntegrationDescription(item, integrationDescriptionTypeMaps.backlinks)
        ) || []
      );
    }

    return (
      data.map((item) =>
        this.fillIntegrationDescription(item, integrationDescriptionTypeMaps.default)
      ) || []
    );
  };

  fillIntegrationDescription = (
    integration: IntegrationType,
    descriptionTypeMap: {
      [key: number]: { title?: string; key?: string; get?: (details: unknown) => string };
    }
  ) => {
    const descriptionRow = descriptionTypeMap[integration.type];

    return {
      ...integration,
      description: descriptionRow?.title,
      subtitleDetails: descriptionRow?.get
        ? descriptionRow.get(integration.detailsList)
        : integration.detailsList.find(
            (row) => row.name === descriptionTypeMap[integration.type]?.key
          )?.value,
    };
  };

  setRequestIntegrationSettings = (
    type: IntegrationTypeMap[keyof IntegrationTypeMap],
    request: AppIntegrationRequest,
    settings: Partial<IntegrationSettingsType>
  ) => {
    let integrationSettings;

    switch (type) {
      case INTEGRATION_TYPE.AHREFS_INTEGRATION:
        integrationSettings = new AhrefIntegrationSettings();
        integrationSettings.setCode((settings as AhrefIntegrationSettings.AsObject).code);
        request.setAhref(integrationSettings);
        break;
      case INTEGRATION_TYPE.HUNTER_INTEGRATION:
        integrationSettings = new RocketReachIntegrationSettings();
        integrationSettings.setAuthToken(
          (settings as HunterIntegrationSettings.AsObject).authToken
        );
        request.setHunter(integrationSettings);
        break;
      case INTEGRATION_TYPE.SNOVIO_INTEGRATION:
        integrationSettings = new SnovioIntegrationSettings();
        integrationSettings.setClientId((settings as SnovioIntegrationSettings.AsObject).clientId);
        integrationSettings.setClientSecret(
          (settings as SnovioIntegrationSettings.AsObject).clientSecret
        );
        request.setSnovio(integrationSettings);
        break;
      case INTEGRATION_TYPE.ROCKET_REACH_INTEGRATION:
        integrationSettings = new RocketReachIntegrationSettings();
        integrationSettings.setAuthToken(
          (settings as RocketReachIntegrationSettings.AsObject).authToken
        );
        request.setRocketReach(integrationSettings);
        break;
      case INTEGRATION_TYPE.SEMRUSH_INTEGRATION:
        integrationSettings = new SemrushIntegrationSettings();
        integrationSettings.setCode((settings as SemrushIntegrationSettings.AsObject).code);
        request.setSemrush(integrationSettings);
        break;
      case INTEGRATION_TYPE.MOZ_INTEGRATION:
        integrationSettings = new MozIntegrationSettings();
        integrationSettings.setAccessId((settings as MozIntegrationSettings.AsObject).accessId);
        integrationSettings.setSecretKey((settings as MozIntegrationSettings.AsObject).secretKey);
        request.setMoz(integrationSettings);
        break;
      default:
        break;
    }
  };

  performOauthIntegrations = (
    history: History,
    location: Location,
    connectIntegrationMutation,
    workspaces
  ) => {
    if (location.pathname.split('/').includes('ahrefs')) {
      const { code } = parseUrlSearch(location.search);

      if (code) {
        connectIntegrationMutation.mutate(
          {
            type: INTEGRATION_TYPE.AHREFS_INTEGRATION,
            workspaceIdsList: workspaces.map((item) => item.id),
            settings: {
              code: code as string,
            },
          },
          {
            onSuccess: () => redirectUserTo(history, '/settings/integrations'),
            onError: () => {
              redirectUserTo(history, '/settings/integrations');
            },
          }
        );
      }
    }

    if (location.pathname.split('/').includes(INTEGRATION_MODES.SEMRUSH)) {
      const { code } = parseUrlSearch(location.search);

      if (code) {
        connectIntegrationMutation.mutate(
          {
            type: INTEGRATION_TYPE.SEMRUSH_INTEGRATION,
            workspaceIdsList: workspaces.map((item) => item.id),
            settings: {
              code: code as string,
            },
          },
          {
            onSuccess: () => redirectUserTo(history, '/settings/integrations'),
            onError: () => {
              redirectUserTo(history, '/settings/integrations');
            },
          }
        );
      }
    }
  };
}

export const integrationsService = new IntegrationsService();
