/**
 * NOT CHECKED
 */
import {
  DefaultGroup,
  RenderConfigurableSidebarLink,
  RenderGroup,
} from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';
import React from 'react';

import { SvgIconType } from 'src/uikit/Icon/Icon';

import './ConfigurableSidebar.scss';

export type ConfigurableSidebarLinkType = {
  icon?: SvgIconType;
  title?: string;
  RenderLinkComponent?: (any) => JSX.Element;
  key: string;
  url?: string;
  renderProps: {
    [key: string]: any;
  };
};

export type ConfigurableSidebarPropsType = {
  SidebarHeader?: (props: { [key: string]: any }) => JSX.Element;
  config: any;
  renderProps?: {
    [key: string]: any;
  };
  isDisabled?: boolean;
  style?: React.CSSProperties;
};

const RenderLink = ({
  title,
  icon,
  RenderLinkComponent,
  renderProps,
}: ConfigurableSidebarLinkType) =>
  RenderLinkComponent ? (
    <RenderLinkComponent {...renderProps} />
  ) : (
    <RenderConfigurableSidebarLink title={title} icon={icon} />
  );

export const RenderDefaultGroup = ({ title, pages, renderProps }): JSX.Element => (
  <DefaultGroup title={title}>
    {pages.map((page) => (
      <RenderLink {...page} renderProps={renderProps} />
    ))}
  </DefaultGroup>
);

const ConfigurableSidebar = ({
  SidebarHeader,
  config,
  renderProps = {},
  style,
}: ConfigurableSidebarPropsType): JSX.Element => {
  const Footer = renderProps.footerRender;

  return (
    <div className="configurable-sidebar">
      {SidebarHeader ? (
        <div className="configurable-sidebar__header">
          <SidebarHeader />
        </div>
      ) : null}

      <div className="configurable-sidebar__body" style={style}>
        {config.map((configItem) =>
          configItem.isGroup ? (
            <RenderGroup key={configItem.pageKey} {...configItem} renderProps={renderProps} />
          ) : (
            <RenderLink
              {...configItem}
              key={configItem.pageKey}
              RenderLinkComponent={configItem.RenderLink}
              renderProps={renderProps}
            />
          )
        )}
      </div>
      <div className="configurable-sidebar__footer">{Footer ? <Footer /> : null}</div>
    </div>
  );
};

export default ConfigurableSidebar;
