import { BillingSubscriptionTypeMapValue as BillingSubscriptionTypeName } from '@ts/billing.types';
import { format } from 'date-fns';
import React from 'react';

import getShortNumber from '@utils/getShortNumber';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';

import BillingCard from '@components/Settings/Billing/_components/BillingCard/BillingCard';

import './CreditsCard.scss';
import { BillingSubscriptionType } from 'respona_api/generated/billing_pb';

function CreditsCard({
  totalCreditsNumber,
  currentCreditsNumber,
  bonusCreditsNumber,
  nextChargeTime,
  onAddMore,
  type,
}: {
  totalCreditsNumber: number;
  currentCreditsNumber: number;
  bonusCreditsNumber: number;
  nextChargeTime: number;
  onAddMore: () => void;
  type: BillingSubscriptionTypeName;
}): JSX.Element {
  return (
    <BillingCard
      title="Credits"
      subtitle={
        nextChargeTime > 0 ? (
          <div className="subscription-card__footer">
            Next refill: {format(new Date(nextChargeTime), 'MMM dd, yyyy')}
          </div>
        ) : null
      }
      headerRight={
        <div onClick={onAddMore}>
          <TextWithIcon icon="arrowUpCircle" iconColor="#3424EB" iconSize={18}>
            Buy credits
          </TextWithIcon>
        </div>
      }
      chartInfo={{ value: totalCreditsNumber - currentCreditsNumber, max: totalCreditsNumber }}
      chartTitle={
        <div className="credits-card__info">
          <div className="credits-card__info-title">Used plan credits</div>

          {bonusCreditsNumber > 0 ? (
            <div className="credits-card__info-subtitle">
              Bonus credits remaining:{' '}
              <span className="credits-card__info-highlight">
                {getShortNumber(bonusCreditsNumber)}
              </span>
            </div>
          ) : null}
        </div>
      }
      warningReversed
    />
  );
}

export default CreditsCard;
