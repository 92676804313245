import React, { useMemo } from 'react';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { CampaignPipelineType, CampaignPipelineTypeMap } from 'respona_api/generated/pipeline_pb';

type PipelineStatisticsProps = {
  type: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap];
  opportunitiesAdded: number;
  opportunitiesWithContacts: number;
  importErrorRowsNumber: number;
  inspectionRemovedNumber: number;
  contactsAdded: number;
  creditsSpent: number;
};

const getBlockValues = (
  type: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap],
  opportunitiesAdded: number,
  opportunitiesWithContacts: number,
  importErrorRowsNumber: number,
  inspectionRemovedNumber: number,
  contactsAdded: number
) => {
  const firstBlockValue =
    type === CampaignPipelineType.IMPORT
      ? opportunitiesAdded + importErrorRowsNumber
      : opportunitiesAdded + inspectionRemovedNumber;
  const secondBlockValue = opportunitiesAdded;
  const thirdBlockValue =
    type === CampaignPipelineType.IMPORT ? importErrorRowsNumber : inspectionRemovedNumber;
  return { firstBlockValue, secondBlockValue, thirdBlockValue };
};

const getBlockTitles = (
  type: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap],
  values: { firstBlockValue: number; secondBlockValue: number; thirdBlockValue: number }
) => {
  const { firstBlockValue, secondBlockValue, thirdBlockValue } = values;

  const firstBlockTitle =
    firstBlockValue <= 1
      ? type === CampaignPipelineType.IMPORT
        ? 'Row processed'
        : 'Item processed'
      : type === CampaignPipelineType.IMPORT
        ? 'Rows processed'
        : 'Items processed';

  const secondBlockTitle =
    secondBlockValue <= 1
      ? type === CampaignPipelineType.IMPORT
        ? 'Row added'
        : 'Item added'
      : type === CampaignPipelineType.IMPORT
        ? 'Rows added'
        : 'Items added';

  const thirdBlockTitle =
    thirdBlockValue <= 1
      ? type === CampaignPipelineType.IMPORT
        ? 'Row flagged'
        : 'Item flaggedd'
      : type === CampaignPipelineType.IMPORT
        ? 'Rows flagged'
        : 'Items flagged';

  return { firstBlockTitle, secondBlockTitle, thirdBlockTitle };
};

const PipelineStatisticsCard: React.FC<{
  icon: SvgIconType;
  color: string;
  backgroundColor: string;
  value: number;
  title: string;
}> = React.memo(({ icon, color, backgroundColor, value, title }) => (
  <div className="review-step-list__statistics__counters__card">
    <div className="review-step-list__statistics__counters__card--img" style={{ backgroundColor }}>
      <SVGIcon icon={icon} color={color} size={24} />
    </div>
    <div>
      <div className="review-step-list__statistics__counters__card--value">{value}</div>
      <div className="review-step-list__statistics__counters__card--title">{title}</div>
    </div>
  </div>
));

const PipelineStatistics: React.FC<PipelineStatisticsProps> = ({
  type,
  opportunitiesAdded,
  opportunitiesWithContacts,
  importErrorRowsNumber,
  inspectionRemovedNumber,
  contactsAdded,
  creditsSpent,
}) => {
  const values = useMemo(
    () =>
      getBlockValues(
        type,
        opportunitiesAdded,
        opportunitiesWithContacts,
        importErrorRowsNumber,
        inspectionRemovedNumber,
        contactsAdded
      ),
    [
      type,
      opportunitiesAdded,
      opportunitiesWithContacts,
      importErrorRowsNumber,
      contactsAdded,
      inspectionRemovedNumber,
    ]
  );
  const titles = useMemo(() => getBlockTitles(type, values), [type, values]);

  return (
    <div className="review-step-list__statistics__counters">
      <PipelineStatisticsCard
        icon="recurringIcon"
        color="#50ABF1"
        backgroundColor="#EDF7FF"
        value={values.firstBlockValue}
        title={titles.firstBlockTitle}
      />
      <PipelineStatisticsCard
        icon="createdOpportunitiesIcon"
        color="#1DD165"
        backgroundColor="#EBFFF3"
        value={values.secondBlockValue}
        title={titles.secondBlockTitle}
      />
      <PipelineStatisticsCard
        icon={type === CampaignPipelineType.IMPORT ? 'flaggedIcon' : 'messageIcon'}
        color="#FC8E0E"
        backgroundColor="#FFF2E2"
        value={values.thirdBlockValue}
        title={titles.thirdBlockTitle}
      />
      <PipelineStatisticsCard
        icon="creditsIcon"
        color="#251FB6"
        backgroundColor="#DBE2FE"
        value={creditsSpent}
        title={creditsSpent === 1 ? 'Credit used' : 'Credits used'}
      />
    </div>
  );
};

export default PipelineStatistics;
