import React from 'react';

import Button from '@uikit/Button/Button';
import { IMoreActions } from '@components/HeaderTemplate/HeaderTemplate.model';
import HeaderSearch from '@components/HeaderTemplate/HeaderSearch/HeaderSearch';
import {
  FilterField
} from '@components/HeaderTemplate/HeaderTemplateFilters/FiltersTooltipContentRaw/FiltersTooltipContentRaw';

import { FilterFieldType } from '@ts/filterField.type';

import './HeaderActions.scss';

function HeaderActions({
  addNewBtn,
  selectedItems,
  handleRemoveItems,
  handleSearchValue,
  moreActions,
  initialFilterState,
  filterFields,
  appliedFilters,
  onApplyFilters,
}: {
  addNewBtn?: {
    title: string;
    handleClick: () => void;
    isLoading?: boolean;
    isLoadingText?: string;
  };
  selectedItems?: any[];
  handleRemoveItems?: () => void;
  handleSearchValue: (value: string) => void;
  moreActions?: IMoreActions[];
  initialFilterState: any;
  filterFields: FilterField[];
  appliedFilters: FilterFieldType<any>[];
  onApplyFilters: any;
}): JSX.Element {
  return (
    <div className="page-header__actions-wrapper">
      <HeaderSearch
        selectedItems={selectedItems}
        handleRemoveItems={handleRemoveItems}
        handleSearchValue={handleSearchValue}
        moreActions={moreActions}
        initialFilterState={initialFilterState}
        filterFields={filterFields}
        appliedFilters={appliedFilters}
        onApplyFilters={onApplyFilters}
      />
      {addNewBtn ? (
        <Button
          isLoading={addNewBtn.isLoading}
          loadingText={addNewBtn.isLoadingText || ''}
          className="page-header__button"
          onClick={addNewBtn.handleClick}
        >
          {addNewBtn.title}
        </Button>
      ) : null}
    </div>
  );
}

export default HeaderActions;
