// package: automation
// file: automation-inspection.proto

var automation_inspection_pb = require("./automation-inspection_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var OpportunityInspectionAutomationService = (function () {
  function OpportunityInspectionAutomationService() {}
  OpportunityInspectionAutomationService.serviceName = "automation.OpportunityInspectionAutomationService";
  return OpportunityInspectionAutomationService;
}());

OpportunityInspectionAutomationService.getPreset = {
  methodName: "getPreset",
  service: OpportunityInspectionAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: automation_inspection_pb.InspectionAutomationPreset
};

OpportunityInspectionAutomationService.exportOpportunityInspectionResult = {
  methodName: "exportOpportunityInspectionResult",
  service: OpportunityInspectionAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: automation_inspection_pb.OpportunityInspectionExportRequest,
  responseType: common_pb.FileResponse
};

exports.OpportunityInspectionAutomationService = OpportunityInspectionAutomationService;

function OpportunityInspectionAutomationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

OpportunityInspectionAutomationServiceClient.prototype.getPreset = function getPreset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OpportunityInspectionAutomationService.getPreset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

OpportunityInspectionAutomationServiceClient.prototype.exportOpportunityInspectionResult = function exportOpportunityInspectionResult(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(OpportunityInspectionAutomationService.exportOpportunityInspectionResult, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.OpportunityInspectionAutomationServiceClient = OpportunityInspectionAutomationServiceClient;

