import AutomationCampaignService from 'respona_api/generated/automation-campaign_pb_service';

declare const API_HOST: string;

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AutomationCampaignService.CampaignAutomationServiceClient => {
      if (!instance) {
        instance = new AutomationCampaignService.CampaignAutomationServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
