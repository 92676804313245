import AiPromptsService from 'respona_api/generated/ai-prompts_pb_service';

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AiPromptsService.AiAgentPromptApiServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AiPromptsService.AiAgentPromptApiServiceClient(API_HOST);
      }

      return instance;
    },
  };
})();

export default service;
