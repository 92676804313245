import React, { useCallback, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { useRefCallback } from '@helpers/refHelpers';

import { CampaignPersonStatisticType } from '@ts/people.types';

import { getCampaignPeopleStatisticsByFilterApi } from '@api/analytics.api';
import { addToBlackListApi } from '@api/people.api';
import { cancelAllQueuesByPersonIdAndReCalculateQueuesApi } from '@api/mailbox.api';

import withSmallScreensCheck from '@hocs/withSmallScreensCheck';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import useIntersectionObserver from '@hooks/useIntersectionObserver';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';
import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';

import ExportPeopleSidebar from '@components/ExportCsvSidebar/ExportCsvSidebar';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';

import tableConfig, { RenderRow } from './tableConfig';

import './People.scss';
import { MailboxQueueCancelRequest } from 'respona_api/generated/mailbox_pb';
import Display from '@components/Display/Display';
import MoreActionsButton from '@components/CampaignAnalytics/People/_components/MoreActionsButton/MoreActionsButton';
import {ExportPeopleRequest} from "respona_api/generated/people_pb";

type PeoplePropsType = {
  RenderHeader?: (any) => JSX.Element;
  campaignId: number;
  workspaceId: number;
};

export type SelectedIdsState = { [key: string]: string };

function People({
  campaignId,
  workspaceId,
  RenderHeader,
  isSmallScreen,
}: PeoplePropsType & { isSmallScreen: boolean }): JSX.Element {
  const dispatch = useDispatch();

  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();

  const [searchQuery, changeSearchQuery] = useState<string>('');
  const [isExportPeopleOpen, changeIsExportPeopleOpen] = useState(false);
  const [selectedIds, changeSelectedIds] = useState<SelectedIdsState>({});

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery<CampaignPersonStatisticType[]>(
    ['analytics-people', campaignId, workspaceId, searchQuery],
    ({ pageParam = 0 }) => {
      const params = [campaignId, workspaceId] as const;

      return getCampaignPeopleStatisticsByFilterApi(...params, pageParam, 50, searchQuery);
    },
    {
      getNextPageParam: (lastPage: CampaignPersonStatisticType[], allPages) => {
        if (lastPage?.length < 50) return false;

        return allPages?.length;
      },
      enabled: !!workspaceId && !!campaignId,
      refetchOnWindowFocus: false,
    }
  );
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const handleOpenExportPeople = useCallback(() => changeIsExportPeopleOpen(true), []);
  const handleCloseExportPeople = useCallback(() => changeIsExportPeopleOpen(false), []);

  // @ts-ignore
  const people = data?.pages?.flat();

  const handleTogglePersonSelection = (id: number) => {
    if (selectedIds[id] === undefined) {
      changeSelectedIds((prevState) => ({
        ...prevState,
        [id]: id,
      }));
    } else {
      changeSelectedIds((prevState) => {
        // @ts-ignore
        const { [id]: omitted, ...newState } = prevState;
        return newState;
      });
    }
  };

  const handleToggleAllSelected = (isSelected) => {
    if (isSelected) {
      changeSelectedIds(
        people.reduce(
          (acc, { personId }) => ({
            ...acc,
            [personId]: personId,
          }),
          {}
        )
      );
    } else {
      changeSelectedIds({});
    }
  };

  const handleUnsubscribe = (personId: number) => {
    addToBlackListApi([personId], currentWorkspaceId).then(() => {
      refetch();
      dispatch(addNotification({ title: 'Person was unsubscribed', type: 'success' }));
    });
  };

  const handleRemovePerson = (personId: number) => {
    cancelAllQueuesByPersonIdAndReCalculateQueuesApi(
      workspaceId,
      campaignId,
      [personId],
      MailboxQueueCancelRequest.MailboxQueueCancelRelatedType.PEOPLE
    ).then(() => {
      refetch();
      dispatch(
        addNotification({ title: 'Queries of selected people were canceled', type: 'success' })
      );
    });
  };

  const isSelectedAll = people?.every(({ personId }) => selectedIds[personId] !== undefined);

  const tableRenderProps = {
    onToggleSelection: handleTogglePersonSelection,
    onRemove: handleRemovePerson,
    onUnsubscribe: handleUnsubscribe,
    onToggleAllSelected: handleToggleAllSelected,
    isAllSelected: isSelectedAll,
    selectedIds,
  };

  const renderLoader = () => {
    if (isFetchingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (people?.length && people[0].totalItems > people.length && hasNextPage) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return null;
  };

  useIntersectionObserver(loadingRef, () => fetchNextPage(), [ready]);

  const handleStopSelected = () => {
    cancelAllQueuesByPersonIdAndReCalculateQueuesApi(
      workspaceId,
      campaignId,
      Object.keys(selectedIds).map(Number),
      MailboxQueueCancelRequest.MailboxQueueCancelRelatedType.PEOPLE
    ).then(() => {
      refetch();
      dispatch(
        addNotification({ title: 'Queries of selected people were canceled', type: 'success' })
      );
    });
  };

  // const handleUnsubscribeSelected = () => {
  //   const values = Object.keys(selectedIds).map(Number)
  //   addBlackListPatternsApi([], [currentWorkspaceId]).then(() => {
  //     dispatch(
  //       addNotification({
  //         title: `Pattern${[].length > 1 ? 's' : ''} added`,
  //         type: 'success',
  //       })
  //     );
  //   });
  // };

  return (
    <>
      <RenderHeader>
        <div className="people-analytic-header-actions">
          <HeaderSearchInput
            searchQuery={searchQuery}
            onUpdateSearchQuery={changeSearchQuery}
            placeholder="Search by Email"
          />
          <Display
            isVisible={people?.length > 0 && (isSelectedAll || Object.keys(selectedIds).length > 0)}
          >
            <Button
              onClick={handleOpenExportPeople}
              className="people-analytic-header-actions__export-users-btn"
              type="additional"
              size="xl"
            >
              <SVGIcon icon="download" />
              {isSmallScreen ? null : 'Export'}
            </Button>
            <MoreActionsButton
              onStop={handleStopSelected}
              // onUnsubscribe={handleUnsubscribeSelected}
              isSmallScreen={isSmallScreen}
            />
          </Display>
        </div>
      </RenderHeader>
      <Loader
        isLoading={(isLoading || isFetching) && !isFetchingNextPage && !isRefetching}
        withTopMargin
      >
        <>
          <ConfigurableTable
            config={tableConfig}
            data={people}
            availableColumns={tableConfig.map(({ dataKey }) => dataKey)}
            tableClassName="analytic-people-table"
            renderProps={tableRenderProps}
            RenderRow={RenderRow}
          />
          {renderLoader()}
        </>
      </Loader>
      <ExportPeopleSidebar
        campaignId={campaignId}
        isOpen={isExportPeopleOpen}
        onClose={handleCloseExportPeople}
        selectedIds={Object.keys(selectedIds).map(Number)}
        itemsNumber={
          Object.keys(selectedIds).map(Number).length || (people?.length && people[0].totalItems)
        }
        type="people"
        sidebarExportType={ExportPeopleRequest.ExportType.CAMPAIGN}
      />
    </>
  );
}

export default withSmallScreensCheck<PeoplePropsType>(People);
