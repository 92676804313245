import React from 'react';

import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';

import './ComponentError.scss';

export function ComponentError({
  onRetry,
  style,
}: {
  onRetry?: () => void;
  style?: React.CSSProperties;
}) {
  return (
    <div className="component-error" style={style}>
      <div className="component-error__icon-container">
        <SVGIcon icon="warningTriangle" color="#ED483D" size={24} />
      </div>

      <div className="component-error__title">We are working on an improvement at the moment</div>
      <div className="component-error__subtitle">Please, feel free to use the rest of the app</div>

      {onRetry ? (
        <Button className="component-error__button" onClick={onRetry}>
          Retry
        </Button>
      ) : null}
    </div>
  );
}
