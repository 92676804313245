// source: campaign.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var people$website_pb = require('./people-website_pb.js');
goog.object.extend(proto, people$website_pb);
var mailbox$template_pb = require('./mailbox-template_pb.js');
goog.object.extend(proto, mailbox$template_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityListRequest', null, global);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityListRequest.Opportunity', null, global);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.MetricsCase', null, global);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityListResponse', null, global);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityListResponse.Opportunity', null, global);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityRequest', null, global);
goog.exportSymbol('proto.campaigns.AddCampaignOpportunityRequest.MetricsCase', null, global);
goog.exportSymbol('proto.campaigns.AddPeopleToCampaign', null, global);
goog.exportSymbol('proto.campaigns.BatchOpportunitiesRequest', null, global);
goog.exportSymbol('proto.campaigns.BatchOpportunitiesRequest.OperationType', null, global);
goog.exportSymbol('proto.campaigns.CampaignBaseResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignBatchOperations', null, global);
goog.exportSymbol('proto.campaigns.CampaignBatchOperations.CampaignBatchAction', null, global);
goog.exportSymbol('proto.campaigns.CampaignContentSearchInput', null, global);
goog.exportSymbol('proto.campaigns.CampaignCreateRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignCrudRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignCrudResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignDeleteRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignDuplicateRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignDuplicateResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignFilter', null, global);
goog.exportSymbol('proto.campaigns.CampaignFilterField', null, global);
goog.exportSymbol('proto.campaigns.CampaignFilterRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignFilterStatus', null, global);
goog.exportSymbol('proto.campaigns.CampaignLaunchRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignLaunchRequest.CampaignLaunchType', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityDetails', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityDetails.MetricsCase', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityListRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityPageResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityPageResponse.MetricsCase', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityShort', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityStat', null, global);
goog.exportSymbol('proto.campaigns.CampaignOpportunityVerificationRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignPersonStatistics', null, global);
goog.exportSymbol('proto.campaigns.CampaignPersonStatistics.Email', null, global);
goog.exportSymbol('proto.campaigns.CampaignPersonStatisticsFilterRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignSettings', null, global);
goog.exportSymbol('proto.campaigns.CampaignShortcutResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignShortcutResponsePageable', null, global);
goog.exportSymbol('proto.campaigns.CampaignShortcutsRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignStatus', null, global);
goog.exportSymbol('proto.campaigns.CampaignSuggestionEmailResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateCreateRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateDetailsResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateInputRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateInputResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateInputType', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateLevel', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplatePreviewResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignTemplateType', null, global);
goog.exportSymbol('proto.campaigns.CampaignValidationProgressResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignValidationRequest', null, global);
goog.exportSymbol('proto.campaigns.CampaignVerificationStatus', null, global);
goog.exportSymbol('proto.campaigns.ChangeCampaignStatus', null, global);
goog.exportSymbol('proto.campaigns.FieldType', null, global);
goog.exportSymbol('proto.campaigns.GetCampaignOpportunities', null, global);
goog.exportSymbol('proto.campaigns.GetCampaignOpportunitiesCriteriaPresence', null, global);
goog.exportSymbol('proto.campaigns.OpportunityAdditionalField', null, global);
goog.exportSymbol('proto.campaigns.OpportunityArticleMetrics', null, global);
goog.exportSymbol('proto.campaigns.OpportunityAudioEpisodeMetrics', null, global);
goog.exportSymbol('proto.campaigns.OpportunityAudioPodcastMetrics', null, global);
goog.exportSymbol('proto.campaigns.OpportunityBacklinksMetrics', null, global);
goog.exportSymbol('proto.campaigns.OpportunityComposedDataRequest', null, global);
goog.exportSymbol('proto.campaigns.OpportunityContactContainer', null, global);
goog.exportSymbol('proto.campaigns.OpportunityContactOrderRequest', null, global);
goog.exportSymbol('proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair', null, global);
goog.exportSymbol('proto.campaigns.OpportunityContactRequest', null, global);
goog.exportSymbol('proto.campaigns.OpportunityFilter', null, global);
goog.exportSymbol('proto.campaigns.OpportunityFilterField', null, global);
goog.exportSymbol('proto.campaigns.OpportunityPersonContactResponse', null, global);
goog.exportSymbol('proto.campaigns.OpportunityPersonalizationDeleteRequest', null, global);
goog.exportSymbol('proto.campaigns.OpportunityPersonalizationGetRequest', null, global);
goog.exportSymbol('proto.campaigns.OpportunityPersonalizationResponse', null, global);
goog.exportSymbol('proto.campaigns.OpportunityPersonalizationUpdateRequest', null, global);
goog.exportSymbol('proto.campaigns.OpportunityTrendingContentMetrics', null, global);
goog.exportSymbol('proto.campaigns.OpportunityVerificationStatus', null, global);
goog.exportSymbol('proto.campaigns.PersonalizationResponse', null, global);
goog.exportSymbol('proto.campaigns.PersonalizationThreadRequest', null, global);
goog.exportSymbol('proto.campaigns.PersonalizationThreadResponse', null, global);
goog.exportSymbol('proto.campaigns.PersonalizationThreadType', null, global);
goog.exportSymbol('proto.campaigns.RemoveOpportunityRequest', null, global);
goog.exportSymbol('proto.campaigns.SendingPriority', null, global);
goog.exportSymbol('proto.campaigns.SendingStrategy', null, global);
goog.exportSymbol('proto.campaigns.UpdateOpportunityUrlRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignShortcutsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignShortcutsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignShortcutsRequest.displayName = 'proto.campaigns.CampaignShortcutsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.AddPeopleToCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.AddPeopleToCampaign.repeatedFields_, null);
};
goog.inherits(proto.campaigns.AddPeopleToCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.AddPeopleToCampaign.displayName = 'proto.campaigns.AddPeopleToCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.ChangeCampaignStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.ChangeCampaignStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.ChangeCampaignStatus.displayName = 'proto.campaigns.ChangeCampaignStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignDeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignDeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignDeleteRequest.displayName = 'proto.campaigns.CampaignDeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignBatchOperations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignBatchOperations.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignBatchOperations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignBatchOperations.displayName = 'proto.campaigns.CampaignBatchOperations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignPersonStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignPersonStatistics.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignPersonStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignPersonStatistics.displayName = 'proto.campaigns.CampaignPersonStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignPersonStatistics.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignPersonStatistics.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignPersonStatistics.Email.displayName = 'proto.campaigns.CampaignPersonStatistics.Email';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignPersonStatisticsFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignPersonStatisticsFilterRequest.displayName = 'proto.campaigns.CampaignPersonStatisticsFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignLaunchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignLaunchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignLaunchRequest.displayName = 'proto.campaigns.CampaignLaunchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignCreateRequest.displayName = 'proto.campaigns.CampaignCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignDuplicateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignDuplicateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignDuplicateRequest.displayName = 'proto.campaigns.CampaignDuplicateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignDuplicateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignDuplicateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignDuplicateResponse.displayName = 'proto.campaigns.CampaignDuplicateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignCrudRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignCrudRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignCrudRequest.displayName = 'proto.campaigns.CampaignCrudRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignSettings.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignSettings.displayName = 'proto.campaigns.CampaignSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignCrudResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignCrudResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignCrudResponse.displayName = 'proto.campaigns.CampaignCrudResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignBaseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignBaseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignBaseResponse.displayName = 'proto.campaigns.CampaignBaseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignSuggestionEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignSuggestionEmailResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignSuggestionEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignSuggestionEmailResponse.displayName = 'proto.campaigns.CampaignSuggestionEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.displayName = 'proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignShortcutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignShortcutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignShortcutResponse.displayName = 'proto.campaigns.CampaignShortcutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignShortcutResponsePageable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignShortcutResponsePageable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignShortcutResponsePageable.displayName = 'proto.campaigns.CampaignShortcutResponsePageable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignContentSearchInput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignContentSearchInput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignContentSearchInput.displayName = 'proto.campaigns.CampaignContentSearchInput';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.AddCampaignOpportunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_);
};
goog.inherits(proto.campaigns.AddCampaignOpportunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.AddCampaignOpportunityRequest.displayName = 'proto.campaigns.AddCampaignOpportunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.UpdateOpportunityUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.UpdateOpportunityUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.UpdateOpportunityUrlRequest.displayName = 'proto.campaigns.UpdateOpportunityUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityArticleMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityArticleMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityArticleMetrics.displayName = 'proto.campaigns.OpportunityArticleMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityTrendingContentMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityTrendingContentMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityTrendingContentMetrics.displayName = 'proto.campaigns.OpportunityTrendingContentMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityAudioPodcastMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityAudioPodcastMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityAudioPodcastMetrics.displayName = 'proto.campaigns.OpportunityAudioPodcastMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityAudioEpisodeMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityAudioEpisodeMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityAudioEpisodeMetrics.displayName = 'proto.campaigns.OpportunityAudioEpisodeMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityBacklinksMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityBacklinksMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityBacklinksMetrics.displayName = 'proto.campaigns.OpportunityBacklinksMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityAdditionalField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityAdditionalField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityAdditionalField.displayName = 'proto.campaigns.OpportunityAdditionalField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignOpportunityDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignOpportunityDetails.repeatedFields_, proto.campaigns.CampaignOpportunityDetails.oneofGroups_);
};
goog.inherits(proto.campaigns.CampaignOpportunityDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignOpportunityDetails.displayName = 'proto.campaigns.CampaignOpportunityDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityPersonContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.OpportunityPersonContactResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.OpportunityPersonContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityPersonContactResponse.displayName = 'proto.campaigns.OpportunityPersonContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignOpportunityPageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignOpportunityPageResponse.repeatedFields_, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_);
};
goog.inherits(proto.campaigns.CampaignOpportunityPageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignOpportunityPageResponse.displayName = 'proto.campaigns.CampaignOpportunityPageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignOpportunityShort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignOpportunityShort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignOpportunityShort.displayName = 'proto.campaigns.CampaignOpportunityShort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignOpportunityStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignOpportunityStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignOpportunityStat.displayName = 'proto.campaigns.CampaignOpportunityStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.AddCampaignOpportunityListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.AddCampaignOpportunityListRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.AddCampaignOpportunityListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.AddCampaignOpportunityListRequest.displayName = 'proto.campaigns.AddCampaignOpportunityListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_);
};
goog.inherits(proto.campaigns.AddCampaignOpportunityListRequest.Opportunity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.displayName = 'proto.campaigns.AddCampaignOpportunityListRequest.Opportunity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.AddCampaignOpportunityListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.AddCampaignOpportunityListResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.AddCampaignOpportunityListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.AddCampaignOpportunityListResponse.displayName = 'proto.campaigns.AddCampaignOpportunityListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.AddCampaignOpportunityListResponse.Opportunity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.displayName = 'proto.campaigns.AddCampaignOpportunityListResponse.Opportunity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignOpportunityVerificationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignOpportunityVerificationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignOpportunityVerificationRequest.displayName = 'proto.campaigns.CampaignOpportunityVerificationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignOpportunityListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignOpportunityListRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignOpportunityListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignOpportunityListRequest.displayName = 'proto.campaigns.CampaignOpportunityListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.RemoveOpportunityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.RemoveOpportunityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.RemoveOpportunityRequest.displayName = 'proto.campaigns.RemoveOpportunityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.GetCampaignOpportunities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.GetCampaignOpportunities.repeatedFields_, null);
};
goog.inherits(proto.campaigns.GetCampaignOpportunities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.GetCampaignOpportunities.displayName = 'proto.campaigns.GetCampaignOpportunities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.BatchOpportunitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.BatchOpportunitiesRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.BatchOpportunitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.BatchOpportunitiesRequest.displayName = 'proto.campaigns.BatchOpportunitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityFilter.displayName = 'proto.campaigns.OpportunityFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.GetCampaignOpportunitiesCriteriaPresence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.displayName = 'proto.campaigns.GetCampaignOpportunitiesCriteriaPresence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignFilterRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignFilterRequest.displayName = 'proto.campaigns.CampaignFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignRequest.displayName = 'proto.campaigns.CampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignFilter.displayName = 'proto.campaigns.CampaignFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityContactContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.OpportunityContactContainer.repeatedFields_, null);
};
goog.inherits(proto.campaigns.OpportunityContactContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityContactContainer.displayName = 'proto.campaigns.OpportunityContactContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityContactRequest.displayName = 'proto.campaigns.OpportunityContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityContactOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.OpportunityContactOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.OpportunityContactOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityContactOrderRequest.displayName = 'proto.campaigns.OpportunityContactOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.displayName = 'proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityPersonalizationGetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityPersonalizationGetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityPersonalizationGetRequest.displayName = 'proto.campaigns.OpportunityPersonalizationGetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityComposedDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityComposedDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityComposedDataRequest.displayName = 'proto.campaigns.OpportunityComposedDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityPersonalizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.OpportunityPersonalizationResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.OpportunityPersonalizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityPersonalizationResponse.displayName = 'proto.campaigns.OpportunityPersonalizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.PersonalizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.PersonalizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.PersonalizationResponse.displayName = 'proto.campaigns.PersonalizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityPersonalizationUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityPersonalizationUpdateRequest.displayName = 'proto.campaigns.OpportunityPersonalizationUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.OpportunityPersonalizationDeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.OpportunityPersonalizationDeleteRequest.displayName = 'proto.campaigns.OpportunityPersonalizationDeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.PersonalizationThreadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.PersonalizationThreadResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.PersonalizationThreadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.PersonalizationThreadResponse.displayName = 'proto.campaigns.PersonalizationThreadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.PersonalizationThreadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.PersonalizationThreadRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.PersonalizationThreadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.PersonalizationThreadRequest.displayName = 'proto.campaigns.PersonalizationThreadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignValidationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignValidationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignValidationRequest.displayName = 'proto.campaigns.CampaignValidationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignValidationProgressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignValidationProgressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignValidationProgressResponse.displayName = 'proto.campaigns.CampaignValidationProgressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignTemplatePreviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignTemplatePreviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignTemplatePreviewResponse.displayName = 'proto.campaigns.CampaignTemplatePreviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignTemplateDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignTemplateDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignTemplateDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignTemplateDetailsResponse.displayName = 'proto.campaigns.CampaignTemplateDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignTemplateInputResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignTemplateInputResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignTemplateInputResponse.displayName = 'proto.campaigns.CampaignTemplateInputResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignTemplateInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.campaigns.CampaignTemplateInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignTemplateInputRequest.displayName = 'proto.campaigns.CampaignTemplateInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignTemplateCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignTemplateCreateRequest.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignTemplateCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignTemplateCreateRequest.displayName = 'proto.campaigns.CampaignTemplateCreateRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignShortcutsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignShortcutsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignShortcutsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignShortcutsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalCampaignTitle: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignShortcutsRequest}
 */
proto.campaigns.CampaignShortcutsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignShortcutsRequest;
  return proto.campaigns.CampaignShortcutsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignShortcutsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignShortcutsRequest}
 */
proto.campaigns.CampaignShortcutsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalCampaignTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignShortcutsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignShortcutsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignShortcutsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignShortcutsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptionalCampaignTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.campaigns.CampaignShortcutsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignShortcutsRequest} returns this
 */
proto.campaigns.CampaignShortcutsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.campaigns.CampaignShortcutsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignShortcutsRequest} returns this
 */
proto.campaigns.CampaignShortcutsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.campaigns.CampaignShortcutsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignShortcutsRequest} returns this
 */
proto.campaigns.CampaignShortcutsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string optional_campaign_title = 4;
 * @return {string}
 */
proto.campaigns.CampaignShortcutsRequest.prototype.getOptionalCampaignTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignShortcutsRequest} returns this
 */
proto.campaigns.CampaignShortcutsRequest.prototype.setOptionalCampaignTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.AddPeopleToCampaign.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.AddPeopleToCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.AddPeopleToCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.AddPeopleToCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddPeopleToCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    personIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    unverifiedEmails: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    workEmails: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    personalEmails: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    genericEmails: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.AddPeopleToCampaign}
 */
proto.campaigns.AddPeopleToCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.AddPeopleToCampaign;
  return proto.campaigns.AddPeopleToCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.AddPeopleToCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.AddPeopleToCampaign}
 */
proto.campaigns.AddPeopleToCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPersonIdsList(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnverifiedEmails(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWorkEmails(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalEmails(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGenericEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.AddPeopleToCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.AddPeopleToCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.AddPeopleToCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddPeopleToCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPersonIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getUnverifiedEmails();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getWorkEmails();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPersonalEmails();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getGenericEmails();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 person_ids = 3;
 * @return {!Array<number>}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getPersonIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setPersonIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.addPersonIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.clearPersonIdsList = function() {
  return this.setPersonIdsList([]);
};


/**
 * optional bool unverified_emails = 4;
 * @return {boolean}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getUnverifiedEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setUnverifiedEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool work_emails = 5;
 * @return {boolean}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getWorkEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setWorkEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool personal_emails = 6;
 * @return {boolean}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getPersonalEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setPersonalEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool generic_emails = 7;
 * @return {boolean}
 */
proto.campaigns.AddPeopleToCampaign.prototype.getGenericEmails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.AddPeopleToCampaign} returns this
 */
proto.campaigns.AddPeopleToCampaign.prototype.setGenericEmails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.ChangeCampaignStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.ChangeCampaignStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.ChangeCampaignStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.ChangeCampaignStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.ChangeCampaignStatus}
 */
proto.campaigns.ChangeCampaignStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.ChangeCampaignStatus;
  return proto.campaigns.ChangeCampaignStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.ChangeCampaignStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.ChangeCampaignStatus}
 */
proto.campaigns.ChangeCampaignStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.campaigns.CampaignStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.ChangeCampaignStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.ChangeCampaignStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.ChangeCampaignStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.ChangeCampaignStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.ChangeCampaignStatus.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.ChangeCampaignStatus} returns this
 */
proto.campaigns.ChangeCampaignStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.ChangeCampaignStatus.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.ChangeCampaignStatus} returns this
 */
proto.campaigns.ChangeCampaignStatus.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CampaignStatus status = 3;
 * @return {!proto.campaigns.CampaignStatus}
 */
proto.campaigns.ChangeCampaignStatus.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.CampaignStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.campaigns.CampaignStatus} value
 * @return {!proto.campaigns.ChangeCampaignStatus} returns this
 */
proto.campaigns.ChangeCampaignStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignDeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignDeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignDeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignDeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hardDelete: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignDeleteRequest}
 */
proto.campaigns.CampaignDeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignDeleteRequest;
  return proto.campaigns.CampaignDeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignDeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignDeleteRequest}
 */
proto.campaigns.CampaignDeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHardDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignDeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignDeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignDeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignDeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHardDelete();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignDeleteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignDeleteRequest} returns this
 */
proto.campaigns.CampaignDeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignDeleteRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignDeleteRequest} returns this
 */
proto.campaigns.CampaignDeleteRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool hard_delete = 3;
 * @return {boolean}
 */
proto.campaigns.CampaignDeleteRequest.prototype.getHardDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignDeleteRequest} returns this
 */
proto.campaigns.CampaignDeleteRequest.prototype.setHardDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignBatchOperations.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignBatchOperations.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignBatchOperations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignBatchOperations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignBatchOperations.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    optDestinationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hardDelete: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignBatchOperations}
 */
proto.campaigns.CampaignBatchOperations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignBatchOperations;
  return proto.campaigns.CampaignBatchOperations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignBatchOperations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignBatchOperations}
 */
proto.campaigns.CampaignBatchOperations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.campaigns.CampaignBatchOperations.CampaignBatchAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCampaignIdsList(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptDestinationId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHardDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignBatchOperations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignBatchOperations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignBatchOperations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignBatchOperations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCampaignIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getOptDestinationId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHardDelete();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.campaigns.CampaignBatchOperations.CampaignBatchAction = {
  DELETE: 0,
  CHANGE_OWNER: 1,
  MOVE_TO_WORKSPACE: 2
};

/**
 * optional CampaignBatchAction action = 1;
 * @return {!proto.campaigns.CampaignBatchOperations.CampaignBatchAction}
 */
proto.campaigns.CampaignBatchOperations.prototype.getAction = function() {
  return /** @type {!proto.campaigns.CampaignBatchOperations.CampaignBatchAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.campaigns.CampaignBatchOperations.CampaignBatchAction} value
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignBatchOperations.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 campaign_ids = 3;
 * @return {!Array<number>}
 */
proto.campaigns.CampaignBatchOperations.prototype.getCampaignIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.setCampaignIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.addCampaignIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.clearCampaignIdsList = function() {
  return this.setCampaignIdsList([]);
};


/**
 * optional int64 opt_destination_id = 4;
 * @return {number}
 */
proto.campaigns.CampaignBatchOperations.prototype.getOptDestinationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.setOptDestinationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool hard_delete = 5;
 * @return {boolean}
 */
proto.campaigns.CampaignBatchOperations.prototype.getHardDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignBatchOperations} returns this
 */
proto.campaigns.CampaignBatchOperations.prototype.setHardDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignPersonStatistics.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignPersonStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignPersonStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignPersonStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPersonStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    personId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    proto.campaigns.CampaignPersonStatistics.Email.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deliveryNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sentNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    openNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    openRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    replyNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    replyRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    nextStepNumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalStepNumber: jspb.Message.getFieldWithDefault(msg, 12, 0),
    lastActivityTimestamp: jspb.Message.getFieldWithDefault(msg, 13, 0),
    nextActivityTimestamp: jspb.Message.getFieldWithDefault(msg, 14, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignPersonStatistics}
 */
proto.campaigns.CampaignPersonStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignPersonStatistics;
  return proto.campaigns.CampaignPersonStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignPersonStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignPersonStatistics}
 */
proto.campaigns.CampaignPersonStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.campaigns.CampaignPersonStatistics.Email;
      reader.readMessage(value,proto.campaigns.CampaignPersonStatistics.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 4:
      var value = /** @type {!proto.common.ContactStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryNumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOpenRate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReplyNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setReplyRate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextStepNumber(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalStepNumber(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivityTimestamp(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextActivityTimestamp(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignPersonStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignPersonStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignPersonStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPersonStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.campaigns.CampaignPersonStatistics.Email.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeliveryNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSentNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOpenNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOpenRate();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getReplyNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getReplyRate();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getNextStepNumber();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalStepNumber();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLastActivityTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getNextActivityTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignPersonStatistics.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignPersonStatistics.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPersonStatistics.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignPersonStatistics.Email}
 */
proto.campaigns.CampaignPersonStatistics.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignPersonStatistics.Email;
  return proto.campaigns.CampaignPersonStatistics.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignPersonStatistics.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignPersonStatistics.Email}
 */
proto.campaigns.CampaignPersonStatistics.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignPersonStatistics.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignPersonStatistics.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPersonStatistics.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignPersonStatistics.Email} returns this
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics.Email} returns this
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignPersonStatistics.Email} returns this
 */
proto.campaigns.CampaignPersonStatistics.Email.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 person_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Email emails = 3;
 * @return {!Array<!proto.campaigns.CampaignPersonStatistics.Email>}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.campaigns.CampaignPersonStatistics.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.CampaignPersonStatistics.Email, 3));
};


/**
 * @param {!Array<!proto.campaigns.CampaignPersonStatistics.Email>} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
*/
proto.campaigns.CampaignPersonStatistics.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.campaigns.CampaignPersonStatistics.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignPersonStatistics.Email}
 */
proto.campaigns.CampaignPersonStatistics.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.campaigns.CampaignPersonStatistics.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * optional common.ContactStatus status = 4;
 * @return {!proto.common.ContactStatus}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getStatus = function() {
  return /** @type {!proto.common.ContactStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.ContactStatus} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 delivery_number = 5;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getDeliveryNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setDeliveryNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sent_number = 6;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getSentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setSentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 open_number = 7;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getOpenNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setOpenNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional float open_rate = 8;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getOpenRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setOpenRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 reply_number = 9;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getReplyNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setReplyNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float reply_rate = 10;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getReplyRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setReplyRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int32 next_step_number = 11;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getNextStepNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setNextStepNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_step_number = 12;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getTotalStepNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setTotalStepNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 last_activity_timestamp = 13;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getLastActivityTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setLastActivityTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 next_activity_timestamp = 14;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getNextActivityTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setNextActivityTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 total_items = 15;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatistics.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatistics} returns this
 */
proto.campaigns.CampaignPersonStatistics.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignPersonStatisticsFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignPersonStatisticsFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    query: jspb.Message.getFieldWithDefault(msg, 3, ""),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignPersonStatisticsFilterRequest;
  return proto.campaigns.CampaignPersonStatisticsFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignPersonStatisticsFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignPersonStatisticsFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignPersonStatisticsFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest} returns this
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest} returns this
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string query = 3;
 * @return {string}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest} returns this
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest} returns this
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPersonStatisticsFilterRequest} returns this
 */
proto.campaigns.CampaignPersonStatisticsFilterRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignLaunchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignLaunchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignLaunchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignLaunchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignLaunchRequest}
 */
proto.campaigns.CampaignLaunchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignLaunchRequest;
  return proto.campaigns.CampaignLaunchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignLaunchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignLaunchRequest}
 */
proto.campaigns.CampaignLaunchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.campaigns.CampaignLaunchRequest.CampaignLaunchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignLaunchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignLaunchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignLaunchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignLaunchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.campaigns.CampaignLaunchRequest.CampaignLaunchType = {
  ALL: 0,
  VERIFIED: 2
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignLaunchRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignLaunchRequest} returns this
 */
proto.campaigns.CampaignLaunchRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignLaunchRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignLaunchRequest} returns this
 */
proto.campaigns.CampaignLaunchRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CampaignLaunchType type = 3;
 * @return {!proto.campaigns.CampaignLaunchRequest.CampaignLaunchType}
 */
proto.campaigns.CampaignLaunchRequest.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignLaunchRequest.CampaignLaunchType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.campaigns.CampaignLaunchRequest.CampaignLaunchType} value
 * @return {!proto.campaigns.CampaignLaunchRequest} returns this
 */
proto.campaigns.CampaignLaunchRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignCreateRequest}
 */
proto.campaigns.CampaignCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignCreateRequest;
  return proto.campaigns.CampaignCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignCreateRequest}
 */
proto.campaigns.CampaignCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignCreateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCreateRequest} returns this
 */
proto.campaigns.CampaignCreateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 optional_folder_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignCreateRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCreateRequest} returns this
 */
proto.campaigns.CampaignCreateRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignDuplicateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignDuplicateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignDuplicateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignDuplicateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignDuplicateRequest}
 */
proto.campaigns.CampaignDuplicateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignDuplicateRequest;
  return proto.campaigns.CampaignDuplicateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignDuplicateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignDuplicateRequest}
 */
proto.campaigns.CampaignDuplicateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignDuplicateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignDuplicateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignDuplicateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignDuplicateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignDuplicateRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignDuplicateRequest} returns this
 */
proto.campaigns.CampaignDuplicateRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignDuplicateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignDuplicateRequest} returns this
 */
proto.campaigns.CampaignDuplicateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_folder_id = 3;
 * @return {number}
 */
proto.campaigns.CampaignDuplicateRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignDuplicateRequest} returns this
 */
proto.campaigns.CampaignDuplicateRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignDuplicateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignDuplicateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignDuplicateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignDuplicateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    progress: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaign: (f = msg.getCampaign()) && proto.campaigns.CampaignBaseResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignDuplicateResponse}
 */
proto.campaigns.CampaignDuplicateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignDuplicateResponse;
  return proto.campaigns.CampaignDuplicateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignDuplicateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignDuplicateResponse}
 */
proto.campaigns.CampaignDuplicateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    case 3:
      var value = new proto.campaigns.CampaignBaseResponse;
      reader.readMessage(value,proto.campaigns.CampaignBaseResponse.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignDuplicateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignDuplicateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignDuplicateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignDuplicateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.campaigns.CampaignBaseResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.campaigns.CampaignDuplicateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignDuplicateResponse} returns this
 */
proto.campaigns.CampaignDuplicateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 progress = 2;
 * @return {number}
 */
proto.campaigns.CampaignDuplicateResponse.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignDuplicateResponse} returns this
 */
proto.campaigns.CampaignDuplicateResponse.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CampaignBaseResponse campaign = 3;
 * @return {?proto.campaigns.CampaignBaseResponse}
 */
proto.campaigns.CampaignDuplicateResponse.prototype.getCampaign = function() {
  return /** @type{?proto.campaigns.CampaignBaseResponse} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.CampaignBaseResponse, 3));
};


/**
 * @param {?proto.campaigns.CampaignBaseResponse|undefined} value
 * @return {!proto.campaigns.CampaignDuplicateResponse} returns this
*/
proto.campaigns.CampaignDuplicateResponse.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignDuplicateResponse} returns this
 */
proto.campaigns.CampaignDuplicateResponse.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignDuplicateResponse.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignCrudRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignCrudRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignCrudRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignCrudRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    settings: (f = msg.getSettings()) && proto.campaigns.CampaignSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignCrudRequest}
 */
proto.campaigns.CampaignCrudRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignCrudRequest;
  return proto.campaigns.CampaignCrudRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignCrudRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignCrudRequest}
 */
proto.campaigns.CampaignCrudRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new proto.campaigns.CampaignSettings;
      reader.readMessage(value,proto.campaigns.CampaignSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignCrudRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignCrudRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignCrudRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignCrudRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.campaigns.CampaignSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignCrudRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudRequest} returns this
 */
proto.campaigns.CampaignCrudRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignCrudRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudRequest} returns this
 */
proto.campaigns.CampaignCrudRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.campaigns.CampaignCrudRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignCrudRequest} returns this
 */
proto.campaigns.CampaignCrudRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CampaignSettings settings = 4;
 * @return {?proto.campaigns.CampaignSettings}
 */
proto.campaigns.CampaignCrudRequest.prototype.getSettings = function() {
  return /** @type{?proto.campaigns.CampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.CampaignSettings, 4));
};


/**
 * @param {?proto.campaigns.CampaignSettings|undefined} value
 * @return {!proto.campaigns.CampaignCrudRequest} returns this
*/
proto.campaigns.CampaignCrudRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignCrudRequest} returns this
 */
proto.campaigns.CampaignCrudRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignCrudRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignSettings.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendingStrategy: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sendingPriority: jspb.Message.getFieldWithDefault(msg, 2, 0),
    senderMailboxIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    ccEmailsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    bccEmailsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    trackOpens: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    trackClicks: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    scheduleId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    notRepliedStrategyBufferDays: jspb.Message.getFieldWithDefault(msg, 9, 0),
    enabledTrackingInOtherActiveCampaign: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    enabledTrackingInPastDays: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    contactedPastDaysNumber: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignSettings}
 */
proto.campaigns.CampaignSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignSettings;
  return proto.campaigns.CampaignSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignSettings}
 */
proto.campaigns.CampaignSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.campaigns.SendingStrategy} */ (reader.readEnum());
      msg.setSendingStrategy(value);
      break;
    case 2:
      var value = /** @type {!proto.campaigns.SendingPriority} */ (reader.readEnum());
      msg.setSendingPriority(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSenderMailboxIdList(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCcEmails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBccEmails(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackOpens(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackClicks(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotRepliedStrategyBufferDays(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledTrackingInOtherActiveCampaign(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledTrackingInPastDays(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactedPastDaysNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendingStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSendingPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSenderMailboxIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCcEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBccEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getTrackOpens();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTrackClicks();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getScheduleId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNotRepliedStrategyBufferDays();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getEnabledTrackingInOtherActiveCampaign();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getEnabledTrackingInPastDays();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getContactedPastDaysNumber();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional SendingStrategy sending_strategy = 1;
 * @return {!proto.campaigns.SendingStrategy}
 */
proto.campaigns.CampaignSettings.prototype.getSendingStrategy = function() {
  return /** @type {!proto.campaigns.SendingStrategy} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.campaigns.SendingStrategy} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setSendingStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SendingPriority sending_priority = 2;
 * @return {!proto.campaigns.SendingPriority}
 */
proto.campaigns.CampaignSettings.prototype.getSendingPriority = function() {
  return /** @type {!proto.campaigns.SendingPriority} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.campaigns.SendingPriority} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setSendingPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated int64 sender_mailbox_id = 3;
 * @return {!Array<number>}
 */
proto.campaigns.CampaignSettings.prototype.getSenderMailboxIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setSenderMailboxIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.addSenderMailboxId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.clearSenderMailboxIdList = function() {
  return this.setSenderMailboxIdList([]);
};


/**
 * repeated string cc_emails = 4;
 * @return {!Array<string>}
 */
proto.campaigns.CampaignSettings.prototype.getCcEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setCcEmailsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.addCcEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.clearCcEmailsList = function() {
  return this.setCcEmailsList([]);
};


/**
 * repeated string bcc_emails = 5;
 * @return {!Array<string>}
 */
proto.campaigns.CampaignSettings.prototype.getBccEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setBccEmailsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.addBccEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.clearBccEmailsList = function() {
  return this.setBccEmailsList([]);
};


/**
 * optional bool track_opens = 6;
 * @return {boolean}
 */
proto.campaigns.CampaignSettings.prototype.getTrackOpens = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setTrackOpens = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool track_clicks = 7;
 * @return {boolean}
 */
proto.campaigns.CampaignSettings.prototype.getTrackClicks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setTrackClicks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int64 schedule_id = 8;
 * @return {number}
 */
proto.campaigns.CampaignSettings.prototype.getScheduleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 not_replied_strategy_buffer_days = 9;
 * @return {number}
 */
proto.campaigns.CampaignSettings.prototype.getNotRepliedStrategyBufferDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setNotRepliedStrategyBufferDays = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool enabled_tracking_in_other_active_campaign = 10;
 * @return {boolean}
 */
proto.campaigns.CampaignSettings.prototype.getEnabledTrackingInOtherActiveCampaign = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setEnabledTrackingInOtherActiveCampaign = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool enabled_tracking_in_past_days = 11;
 * @return {boolean}
 */
proto.campaigns.CampaignSettings.prototype.getEnabledTrackingInPastDays = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setEnabledTrackingInPastDays = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 contacted_past_days_number = 12;
 * @return {number}
 */
proto.campaigns.CampaignSettings.prototype.getContactedPastDaysNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignSettings} returns this
 */
proto.campaigns.CampaignSettings.prototype.setContactedPastDaysNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignCrudResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignCrudResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignCrudResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignCrudResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastBatch: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lastSequenceId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    settings: (f = msg.getSettings()) && proto.campaigns.CampaignSettings.toObject(includeInstance, f),
    verificationStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    recalculationQueuesActive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    advanced: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    opportunitiesNumber: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignCrudResponse}
 */
proto.campaigns.CampaignCrudResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignCrudResponse;
  return proto.campaigns.CampaignCrudResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignCrudResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignCrudResponse}
 */
proto.campaigns.CampaignCrudResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.campaigns.CampaignStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastBatch(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSequenceId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new proto.campaigns.CampaignSettings;
      reader.readMessage(value,proto.campaigns.CampaignSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 9:
      var value = /** @type {!proto.campaigns.CampaignVerificationStatus} */ (reader.readEnum());
      msg.setVerificationStatus(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecalculationQueuesActive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdvanced(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignCrudResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignCrudResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignCrudResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignCrudResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLastBatch();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLastSequenceId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.campaigns.CampaignSettings.serializeBinaryToWriter
    );
  }
  f = message.getVerificationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getRecalculationQueuesActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAdvanced();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOpportunitiesNumber();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignCrudResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignCrudResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.campaigns.CampaignCrudResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CampaignStatus status = 4;
 * @return {!proto.campaigns.CampaignStatus}
 */
proto.campaigns.CampaignCrudResponse.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.CampaignStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.campaigns.CampaignStatus} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 last_batch = 5;
 * @return {number}
 */
proto.campaigns.CampaignCrudResponse.prototype.getLastBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setLastBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 last_sequence_id = 6;
 * @return {number}
 */
proto.campaigns.CampaignCrudResponse.prototype.getLastSequenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setLastSequenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.campaigns.CampaignCrudResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional CampaignSettings settings = 8;
 * @return {?proto.campaigns.CampaignSettings}
 */
proto.campaigns.CampaignCrudResponse.prototype.getSettings = function() {
  return /** @type{?proto.campaigns.CampaignSettings} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.CampaignSettings, 8));
};


/**
 * @param {?proto.campaigns.CampaignSettings|undefined} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
*/
proto.campaigns.CampaignCrudResponse.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignCrudResponse.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CampaignVerificationStatus verification_status = 9;
 * @return {!proto.campaigns.CampaignVerificationStatus}
 */
proto.campaigns.CampaignCrudResponse.prototype.getVerificationStatus = function() {
  return /** @type {!proto.campaigns.CampaignVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.campaigns.CampaignVerificationStatus} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setVerificationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool recalculation_queues_active = 10;
 * @return {boolean}
 */
proto.campaigns.CampaignCrudResponse.prototype.getRecalculationQueuesActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setRecalculationQueuesActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool advanced = 11;
 * @return {boolean}
 */
proto.campaigns.CampaignCrudResponse.prototype.getAdvanced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setAdvanced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 opportunities_number = 12;
 * @return {number}
 */
proto.campaigns.CampaignCrudResponse.prototype.getOpportunitiesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignCrudResponse} returns this
 */
proto.campaigns.CampaignCrudResponse.prototype.setOpportunitiesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignBaseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignBaseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignBaseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignBaseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    folderId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    opportunitiesNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    launchedNumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    scheduledNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    contactedNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    openedNumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    repliedNumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    ownerName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    ownerAvatar: jspb.Message.getFieldWithDefault(msg, 16, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 17, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignBaseResponse}
 */
proto.campaigns.CampaignBaseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignBaseResponse;
  return proto.campaigns.CampaignBaseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignBaseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignBaseResponse}
 */
proto.campaigns.CampaignBaseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFolderId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {!proto.campaigns.CampaignStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLaunchedNumber(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduledNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactedNumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenedNumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepliedNumber(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedAt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerAvatar(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignBaseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignBaseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignBaseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignBaseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFolderId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOpportunitiesNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLaunchedNumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getScheduledNumber();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getContactedNumber();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOpenedNumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRepliedNumber();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDeletedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOwnerAvatar();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 folder_id = 3;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.campaigns.CampaignBaseResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CampaignStatus status = 5;
 * @return {!proto.campaigns.CampaignStatus}
 */
proto.campaigns.CampaignBaseResponse.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.CampaignStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.campaigns.CampaignStatus} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 opportunities_number = 6;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getOpportunitiesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setOpportunitiesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 launched_number = 7;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getLaunchedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setLaunchedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 scheduled_number = 8;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getScheduledNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setScheduledNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 contacted_number = 9;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getContactedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setContactedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 opened_number = 10;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getOpenedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setOpenedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 replied_number = 11;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getRepliedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setRepliedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool deleted = 12;
 * @return {boolean}
 */
proto.campaigns.CampaignBaseResponse.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int64 deleted_at = 13;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setDeletedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 owner_id = 14;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string owner_name = 15;
 * @return {string}
 */
proto.campaigns.CampaignBaseResponse.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string owner_avatar = 16;
 * @return {string}
 */
proto.campaigns.CampaignBaseResponse.prototype.getOwnerAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setOwnerAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 created_at = 17;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 total_items = 18;
 * @return {number}
 */
proto.campaigns.CampaignBaseResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignBaseResponse} returns this
 */
proto.campaigns.CampaignBaseResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignSuggestionEmailResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignSuggestionEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignSuggestionEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ccEmailsList: jspb.Message.toObjectList(msg.getCcEmailsList(),
    proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.toObject, includeInstance),
    bccEmailsList: jspb.Message.toObjectList(msg.getBccEmailsList(),
    proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse}
 */
proto.campaigns.CampaignSuggestionEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignSuggestionEmailResponse;
  return proto.campaigns.CampaignSuggestionEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse}
 */
proto.campaigns.CampaignSuggestionEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion;
      reader.readMessage(value,proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.deserializeBinaryFromReader);
      msg.addCcEmails(value);
      break;
    case 2:
      var value = new proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion;
      reader.readMessage(value,proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.deserializeBinaryFromReader);
      msg.addBccEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignSuggestionEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignSuggestionEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCcEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.serializeBinaryToWriter
    );
  }
  f = message.getBccEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion}
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion;
  return proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion}
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion} returns this
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion} returns this
 */
proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated EmailSuggestion cc_emails = 1;
 * @return {!Array<!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion>}
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.getCcEmailsList = function() {
  return /** @type{!Array<!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion, 1));
};


/**
 * @param {!Array<!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion>} value
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse} returns this
*/
proto.campaigns.CampaignSuggestionEmailResponse.prototype.setCcEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion}
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.addCcEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse} returns this
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.clearCcEmailsList = function() {
  return this.setCcEmailsList([]);
};


/**
 * repeated EmailSuggestion bcc_emails = 2;
 * @return {!Array<!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion>}
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.getBccEmailsList = function() {
  return /** @type{!Array<!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion, 2));
};


/**
 * @param {!Array<!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion>} value
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse} returns this
*/
proto.campaigns.CampaignSuggestionEmailResponse.prototype.setBccEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion}
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.addBccEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.campaigns.CampaignSuggestionEmailResponse.EmailSuggestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignSuggestionEmailResponse} returns this
 */
proto.campaigns.CampaignSuggestionEmailResponse.prototype.clearBccEmailsList = function() {
  return this.setBccEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignShortcutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignShortcutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignShortcutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignShortcutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignShortcutResponse}
 */
proto.campaigns.CampaignShortcutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignShortcutResponse;
  return proto.campaigns.CampaignShortcutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignShortcutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignShortcutResponse}
 */
proto.campaigns.CampaignShortcutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignShortcutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignShortcutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignShortcutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignShortcutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignShortcutResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignShortcutResponse} returns this
 */
proto.campaigns.CampaignShortcutResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.campaigns.CampaignShortcutResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignShortcutResponse} returns this
 */
proto.campaigns.CampaignShortcutResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignShortcutResponsePageable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignShortcutResponsePageable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignShortcutResponsePageable.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignShortcutResponsePageable}
 */
proto.campaigns.CampaignShortcutResponsePageable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignShortcutResponsePageable;
  return proto.campaigns.CampaignShortcutResponsePageable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignShortcutResponsePageable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignShortcutResponsePageable}
 */
proto.campaigns.CampaignShortcutResponsePageable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignShortcutResponsePageable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignShortcutResponsePageable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignShortcutResponsePageable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignShortcutResponsePageable} returns this
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignShortcutResponsePageable} returns this
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 total_items = 3;
 * @return {number}
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignShortcutResponsePageable} returns this
 */
proto.campaigns.CampaignShortcutResponsePageable.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignContentSearchInput.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignContentSearchInput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignContentSearchInput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignContentSearchInput.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignContentSearchInput}
 */
proto.campaigns.CampaignContentSearchInput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignContentSearchInput;
  return proto.campaigns.CampaignContentSearchInput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignContentSearchInput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignContentSearchInput}
 */
proto.campaigns.CampaignContentSearchInput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignContentSearchInput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignContentSearchInput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignContentSearchInput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignContentSearchInput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.campaigns.CampaignContentSearchInput.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignContentSearchInput} returns this
 */
proto.campaigns.CampaignContentSearchInput.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.ContentSearchType type = 2;
 * @return {!proto.common.ContentSearchType}
 */
proto.campaigns.CampaignContentSearchInput.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.campaigns.CampaignContentSearchInput} returns this
 */
proto.campaigns.CampaignContentSearchInput.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_ = [[8,9,10,11,12,13,14,15,16,17]];

/**
 * @enum {number}
 */
proto.campaigns.AddCampaignOpportunityRequest.MetricsCase = {
  METRICS_NOT_SET: 0,
  GOOGLE_SEARCH: 8,
  BING_NEWS: 9,
  PODCAST: 10,
  EPISODE: 11,
  TRENDING_CONTENT: 12,
  BING_SEARCH: 13,
  AHREF_BACKLINKS: 14,
  SEMRUSH_BACKLINKS: 15,
  MOZ_BACKLINKS: 16,
  GOOGLE_NEWS: 17
};

/**
 * @return {proto.campaigns.AddCampaignOpportunityRequest.MetricsCase}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getMetricsCase = function() {
  return /** @type {proto.campaigns.AddCampaignOpportunityRequest.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.AddCampaignOpportunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.AddCampaignOpportunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    googleSearch: (f = msg.getGoogleSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.campaigns.OpportunityAudioPodcastMetrics.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.campaigns.OpportunityAudioEpisodeMetrics.toObject(includeInstance, f),
    trendingContent: (f = msg.getTrendingContent()) && proto.campaigns.OpportunityTrendingContentMetrics.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest}
 */
proto.campaigns.AddCampaignOpportunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.AddCampaignOpportunityRequest;
  return proto.campaigns.AddCampaignOpportunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.AddCampaignOpportunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest}
 */
proto.campaigns.AddCampaignOpportunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 9:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 10:
      var value = new proto.campaigns.OpportunityAudioPodcastMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 11:
      var value = new proto.campaigns.OpportunityAudioEpisodeMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    case 12:
      var value = new proto.campaigns.OpportunityTrendingContentMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinaryFromReader);
      msg.setTrendingContent(value);
      break;
    case 13:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 14:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 15:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 16:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 17:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.AddCampaignOpportunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.AddCampaignOpportunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.campaigns.OpportunityAudioPodcastMetrics.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.campaigns.OpportunityAudioEpisodeMetrics.serializeBinaryToWriter
    );
  }
  f = message.getTrendingContent();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.campaigns.OpportunityTrendingContentMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string uid = 4;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional common.ContentSearchType type = 7;
 * @return {!proto.common.ContentSearchType}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional OpportunityArticleMetrics google_search = 8;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getGoogleSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 8));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional OpportunityArticleMetrics bing_news = 9;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getBingNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 9));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setBingNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional OpportunityAudioPodcastMetrics podcast = 10;
 * @return {?proto.campaigns.OpportunityAudioPodcastMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getPodcast = function() {
  return /** @type{?proto.campaigns.OpportunityAudioPodcastMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioPodcastMetrics, 10));
};


/**
 * @param {?proto.campaigns.OpportunityAudioPodcastMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OpportunityAudioEpisodeMetrics episode = 11;
 * @return {?proto.campaigns.OpportunityAudioEpisodeMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getEpisode = function() {
  return /** @type{?proto.campaigns.OpportunityAudioEpisodeMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioEpisodeMetrics, 11));
};


/**
 * @param {?proto.campaigns.OpportunityAudioEpisodeMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setEpisode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional OpportunityTrendingContentMetrics trending_content = 12;
 * @return {?proto.campaigns.OpportunityTrendingContentMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getTrendingContent = function() {
  return /** @type{?proto.campaigns.OpportunityTrendingContentMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityTrendingContentMetrics, 12));
};


/**
 * @param {?proto.campaigns.OpportunityTrendingContentMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setTrendingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearTrendingContent = function() {
  return this.setTrendingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasTrendingContent = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional OpportunityArticleMetrics bing_search = 13;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getBingSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 13));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setBingSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional OpportunityBacklinksMetrics ahref_backlinks = 14;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 14));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional OpportunityBacklinksMetrics semrush_backlinks = 15;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 15));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional OpportunityBacklinksMetrics moz_backlinks = 16;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getMozBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 16));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional OpportunityArticleMetrics google_news = 17;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.getGoogleNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 17));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityRequest.prototype.setGoogleNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.campaigns.AddCampaignOpportunityRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityRequest.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.UpdateOpportunityUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.UpdateOpportunityUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.UpdateOpportunityUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.UpdateOpportunityUrlRequest}
 */
proto.campaigns.UpdateOpportunityUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.UpdateOpportunityUrlRequest;
  return proto.campaigns.UpdateOpportunityUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.UpdateOpportunityUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.UpdateOpportunityUrlRequest}
 */
proto.campaigns.UpdateOpportunityUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.UpdateOpportunityUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.UpdateOpportunityUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.UpdateOpportunityUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.UpdateOpportunityUrlRequest} returns this
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.UpdateOpportunityUrlRequest} returns this
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.UpdateOpportunityUrlRequest} returns this
 */
proto.campaigns.UpdateOpportunityUrlRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityArticleMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityArticleMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityArticleMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainRating: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    domainTraffic: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    domainTrafficValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    domainTrafficKeywordsNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    referringDomains: jspb.Message.getFieldWithDefault(msg, 7, 0),
    backlinksTotal: jspb.Message.getFieldWithDefault(msg, 8, 0),
    backlinksNofollow: jspb.Message.getFieldWithDefault(msg, 9, 0),
    backlinksDofollow: jspb.Message.getFieldWithDefault(msg, 10, 0),
    backlinksOther: jspb.Message.getFieldWithDefault(msg, 11, 0),
    domainSpamScore: jspb.Message.getFieldWithDefault(msg, 12, 0),
    pageAuthority: jspb.Message.getFieldWithDefault(msg, 13, 0),
    publishingDate: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.OpportunityArticleMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityArticleMetrics;
  return proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityArticleMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainRating(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTraffic(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTrafficValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainTrafficKeywordsNumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReferringDomains(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksTotal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksNofollow(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksDofollow(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksOther(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainSpamScore(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageAuthority(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishingDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityArticleMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainRating();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDomainTraffic();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDomainTrafficValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDomainTrafficKeywordsNumber();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getReferringDomains();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getBacklinksTotal();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBacklinksNofollow();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getBacklinksDofollow();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getBacklinksOther();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDomainSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPageAuthority();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getPublishingDate();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 domain_rating = 2;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getDomainRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setDomainRating = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 rank = 3;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double domain_traffic = 4;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getDomainTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setDomainTraffic = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double domain_traffic_value = 5;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getDomainTrafficValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setDomainTrafficValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 domain_traffic_keywords_number = 6;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getDomainTrafficKeywordsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setDomainTrafficKeywordsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 referring_domains = 7;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getReferringDomains = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setReferringDomains = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 backlinks_total = 8;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getBacklinksTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setBacklinksTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 backlinks_nofollow = 9;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getBacklinksNofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setBacklinksNofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 backlinks_dofollow = 10;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getBacklinksDofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setBacklinksDofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 backlinks_other = 11;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getBacklinksOther = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setBacklinksOther = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 domain_spam_score = 12;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getDomainSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setDomainSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 page_authority = 13;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getPageAuthority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setPageAuthority = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 publishing_date = 14;
 * @return {number}
 */
proto.campaigns.OpportunityArticleMetrics.prototype.getPublishingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityArticleMetrics} returns this
 */
proto.campaigns.OpportunityArticleMetrics.prototype.setPublishingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityTrendingContentMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityTrendingContentMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityTrendingContentMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    pinterestSharesNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    twitterSharesNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    redditEngagementsNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    facebookSharesNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAtTimestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    publishedAtTimestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalShares: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics}
 */
proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityTrendingContentMetrics;
  return proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityTrendingContentMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics}
 */
proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPinterestSharesNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTwitterSharesNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRedditEngagementsNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFacebookSharesNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAtTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishedAtTimestamp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalShares(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityTrendingContentMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityTrendingContentMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityTrendingContentMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPinterestSharesNumber();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTwitterSharesNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRedditEngagementsNumber();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFacebookSharesNumber();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAtTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPublishedAtTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalShares();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 pinterest_shares_number = 1;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getPinterestSharesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setPinterestSharesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 twitter_shares_number = 2;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getTwitterSharesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setTwitterSharesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 reddit_engagements_number = 3;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getRedditEngagementsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setRedditEngagementsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 facebook_shares_number = 4;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getFacebookSharesNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setFacebookSharesNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at_timestamp = 5;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getUpdatedAtTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setUpdatedAtTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 published_at_timestamp = 6;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getPublishedAtTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setPublishedAtTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 total_shares = 7;
 * @return {number}
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.getTotalShares = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityTrendingContentMetrics} returns this
 */
proto.campaigns.OpportunityTrendingContentMetrics.prototype.setTotalShares = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityAudioPodcastMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityAudioPodcastMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityAudioPodcastMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    rrsUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iTunesUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    publishingDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    publisherName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    listenScore: jspb.Message.getFieldWithDefault(msg, 6, 0),
    globalRank: jspb.Message.getFieldWithDefault(msg, 7, ""),
    totalEpisodes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    podcastUid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    publisherEmail: jspb.Message.getFieldWithDefault(msg, 11, ""),
    hasGuestInterviews: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityAudioPodcastMetrics;
  return proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityAudioPodcastMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRrsUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setITunesUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishingDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListenScore(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalRank(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalEpisodes(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodcastUid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherEmail(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasGuestInterviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityAudioPodcastMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityAudioPodcastMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityAudioPodcastMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRrsUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getITunesUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPublishingDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPublisherName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getListenScore();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGlobalRank();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTotalEpisodes();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPodcastUid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPublisherEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHasGuestInterviews();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string rrs_url = 1;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getRrsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setRrsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_url = 2;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string i_tunes_url = 3;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getITunesUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setITunesUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 publishing_date = 4;
 * @return {number}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getPublishingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setPublishingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string publisher_name = 5;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getPublisherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setPublisherName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 listen_score = 6;
 * @return {number}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getListenScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setListenScore = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string global_rank = 7;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getGlobalRank = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setGlobalRank = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 total_episodes = 8;
 * @return {number}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getTotalEpisodes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setTotalEpisodes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string podcast_uid = 9;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getPodcastUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setPodcastUid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string publisher_email = 11;
 * @return {string}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getPublisherEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setPublisherEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool has_guest_interviews = 12;
 * @return {boolean}
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.getHasGuestInterviews = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.OpportunityAudioPodcastMetrics} returns this
 */
proto.campaigns.OpportunityAudioPodcastMetrics.prototype.setHasGuestInterviews = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityAudioEpisodeMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityAudioEpisodeMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    podcastUid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rrsUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    audioUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    iTunesUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    publishingDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    publisherName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    listenScore: jspb.Message.getFieldWithDefault(msg, 8, 0),
    globalRank: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    publisherEmail: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityAudioEpisodeMetrics;
  return proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityAudioEpisodeMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodcastUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRrsUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudioUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setITunesUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPublishingDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListenScore(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlobalRank(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityAudioEpisodeMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityAudioEpisodeMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPodcastUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRrsUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAudioUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getITunesUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPublishingDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPublisherName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getListenScore();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGlobalRank();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPublisherEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string podcast_uid = 1;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getPodcastUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setPodcastUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rrs_url = 2;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getRrsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setRrsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string audio_url = 3;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getAudioUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setAudioUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image_url = 4;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string i_tunes_url = 5;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getITunesUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setITunesUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 publishing_date = 6;
 * @return {number}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getPublishingDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setPublishingDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string publisher_name = 7;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getPublisherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setPublisherName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 listen_score = 8;
 * @return {number}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getListenScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setListenScore = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string global_rank = 9;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getGlobalRank = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setGlobalRank = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string publisher_email = 11;
 * @return {string}
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.getPublisherEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAudioEpisodeMetrics} returns this
 */
proto.campaigns.OpportunityAudioEpisodeMetrics.prototype.setPublisherEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityBacklinksMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityBacklinksMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityBacklinksMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstSeenDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    urlTo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    textPre: jspb.Message.getFieldWithDefault(msg, 3, ""),
    anchor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    textPost: jspb.Message.getFieldWithDefault(msg, 5, ""),
    noFollow: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    domainRating: jspb.Message.getFieldWithDefault(msg, 7, 0),
    domainTraffic: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    domainTrafficValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    domainTrafficKeywordsNumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    referringDomains: jspb.Message.getFieldWithDefault(msg, 11, 0),
    backlinksTotal: jspb.Message.getFieldWithDefault(msg, 12, 0),
    backlinksNofollow: jspb.Message.getFieldWithDefault(msg, 13, 0),
    backlinksDofollow: jspb.Message.getFieldWithDefault(msg, 14, 0),
    backlinksOther: jspb.Message.getFieldWithDefault(msg, 15, 0),
    domainSpamScore: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pageAuthority: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.OpportunityBacklinksMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityBacklinksMetrics;
  return proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityBacklinksMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstSeenDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextPre(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnchor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextPost(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoFollow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainRating(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTraffic(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDomainTrafficValue(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainTrafficKeywordsNumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReferringDomains(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksTotal(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksNofollow(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksDofollow(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBacklinksOther(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDomainSpamScore(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageAuthority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityBacklinksMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstSeenDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUrlTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTextPre();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnchor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTextPost();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNoFollow();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDomainRating();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDomainTraffic();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDomainTrafficValue();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDomainTrafficKeywordsNumber();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getReferringDomains();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getBacklinksTotal();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getBacklinksNofollow();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getBacklinksDofollow();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getBacklinksOther();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDomainSpamScore();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPageAuthority();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional int64 first_seen_date = 1;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getFirstSeenDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setFirstSeenDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url_to = 2;
 * @return {string}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getUrlTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setUrlTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text_pre = 3;
 * @return {string}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getTextPre = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setTextPre = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string anchor = 4;
 * @return {string}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getAnchor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setAnchor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text_post = 5;
 * @return {string}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getTextPost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setTextPost = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool no_follow = 6;
 * @return {boolean}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getNoFollow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setNoFollow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 domain_rating = 7;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getDomainRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setDomainRating = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double domain_traffic = 8;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getDomainTraffic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setDomainTraffic = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double domain_traffic_value = 9;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getDomainTrafficValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setDomainTrafficValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int64 domain_traffic_keywords_number = 10;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getDomainTrafficKeywordsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setDomainTrafficKeywordsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 referring_domains = 11;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getReferringDomains = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setReferringDomains = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 backlinks_total = 12;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getBacklinksTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setBacklinksTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 backlinks_nofollow = 13;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getBacklinksNofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setBacklinksNofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 backlinks_dofollow = 14;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getBacklinksDofollow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setBacklinksDofollow = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 backlinks_other = 15;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getBacklinksOther = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setBacklinksOther = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 domain_spam_score = 16;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getDomainSpamScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setDomainSpamScore = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 page_authority = 17;
 * @return {number}
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.getPageAuthority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityBacklinksMetrics} returns this
 */
proto.campaigns.OpportunityBacklinksMetrics.prototype.setPageAuthority = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityAdditionalField.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityAdditionalField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityAdditionalField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityAdditionalField.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityAdditionalField}
 */
proto.campaigns.OpportunityAdditionalField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityAdditionalField;
  return proto.campaigns.OpportunityAdditionalField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityAdditionalField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityAdditionalField}
 */
proto.campaigns.OpportunityAdditionalField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.campaigns.FieldType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityAdditionalField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityAdditionalField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityAdditionalField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityAdditionalField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional FieldType type = 1;
 * @return {!proto.campaigns.FieldType}
 */
proto.campaigns.OpportunityAdditionalField.prototype.getType = function() {
  return /** @type {!proto.campaigns.FieldType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.campaigns.FieldType} value
 * @return {!proto.campaigns.OpportunityAdditionalField} returns this
 */
proto.campaigns.OpportunityAdditionalField.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.campaigns.OpportunityAdditionalField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityAdditionalField} returns this
 */
proto.campaigns.OpportunityAdditionalField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignOpportunityDetails.repeatedFields_ = [9,10,11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.campaigns.CampaignOpportunityDetails.oneofGroups_ = [[17,18,19,20,21,22,23,24,25,26]];

/**
 * @enum {number}
 */
proto.campaigns.CampaignOpportunityDetails.MetricsCase = {
  METRICS_NOT_SET: 0,
  GOOGLE_SEARCH: 17,
  BING_NEWS: 18,
  PODCAST: 19,
  EPISODE: 20,
  TRENDING_CONTENT: 21,
  BING_SEARCH: 22,
  AHREF_BACKLINKS: 23,
  SEMRUSH_BACKLINKS: 24,
  MOZ_BACKLINKS: 25,
  GOOGLE_NEWS: 26
};

/**
 * @return {proto.campaigns.CampaignOpportunityDetails.MetricsCase}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getMetricsCase = function() {
  return /** @type {proto.campaigns.CampaignOpportunityDetails.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignOpportunityDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignOpportunityDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    edited: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    additionalFieldsList: jspb.Message.toObjectList(msg.getAdditionalFieldsList(),
    proto.campaigns.OpportunityAdditionalField.toObject, includeInstance),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.campaigns.OpportunityPersonContactResponse.toObject, includeInstance),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.campaigns.PersonalizationResponse.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    verificationReason: jspb.Message.getFieldWithDefault(msg, 13, ""),
    optionalTargetUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    optionalAnchorText: jspb.Message.getFieldWithDefault(msg, 15, ""),
    website: (f = msg.getWebsite()) && people$website_pb.PeopleWebsiteExtendedResponse.toObject(includeInstance, f),
    googleSearch: (f = msg.getGoogleSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.campaigns.OpportunityAudioPodcastMetrics.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.campaigns.OpportunityAudioEpisodeMetrics.toObject(includeInstance, f),
    trendingContent: (f = msg.getTrendingContent()) && proto.campaigns.OpportunityTrendingContentMetrics.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    metricsType: jspb.Message.getFieldWithDefault(msg, 27, 0),
    reviewed: jspb.Message.getBooleanFieldWithDefault(msg, 28, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignOpportunityDetails}
 */
proto.campaigns.CampaignOpportunityDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignOpportunityDetails;
  return proto.campaigns.CampaignOpportunityDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignOpportunityDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignOpportunityDetails}
 */
proto.campaigns.CampaignOpportunityDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEdited(value);
      break;
    case 8:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = new proto.campaigns.OpportunityAdditionalField;
      reader.readMessage(value,proto.campaigns.OpportunityAdditionalField.deserializeBinaryFromReader);
      msg.addAdditionalFields(value);
      break;
    case 10:
      var value = new proto.campaigns.OpportunityPersonContactResponse;
      reader.readMessage(value,proto.campaigns.OpportunityPersonContactResponse.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 11:
      var value = new proto.campaigns.PersonalizationResponse;
      reader.readMessage(value,proto.campaigns.PersonalizationResponse.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    case 12:
      var value = /** @type {!proto.campaigns.OpportunityVerificationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationReason(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalTargetUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionalAnchorText(value);
      break;
    case 16:
      var value = new people$website_pb.PeopleWebsiteExtendedResponse;
      reader.readMessage(value,people$website_pb.PeopleWebsiteExtendedResponse.deserializeBinaryFromReader);
      msg.setWebsite(value);
      break;
    case 17:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 18:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 19:
      var value = new proto.campaigns.OpportunityAudioPodcastMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 20:
      var value = new proto.campaigns.OpportunityAudioEpisodeMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    case 21:
      var value = new proto.campaigns.OpportunityTrendingContentMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinaryFromReader);
      msg.setTrendingContent(value);
      break;
    case 22:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 23:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 24:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 25:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 26:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    case 27:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setMetricsType(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReviewed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignOpportunityDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignOpportunityDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEdited();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getAdditionalFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.campaigns.OpportunityAdditionalField.serializeBinaryToWriter
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.campaigns.OpportunityPersonContactResponse.serializeBinaryToWriter
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.campaigns.PersonalizationResponse.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getVerificationReason();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOptionalTargetUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOptionalAnchorText();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getWebsite();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      people$website_pb.PeopleWebsiteExtendedResponse.serializeBinaryToWriter
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.campaigns.OpportunityAudioPodcastMetrics.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.campaigns.OpportunityAudioEpisodeMetrics.serializeBinaryToWriter
    );
  }
  f = message.getTrendingContent();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.campaigns.OpportunityTrendingContentMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMetricsType();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getReviewed();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 pipeline_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string uid = 4;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool edited = 7;
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getEdited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setEdited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional common.ContentSearchType type = 8;
 * @return {!proto.common.ContentSearchType}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated OpportunityAdditionalField additional_fields = 9;
 * @return {!Array<!proto.campaigns.OpportunityAdditionalField>}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getAdditionalFieldsList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityAdditionalField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityAdditionalField, 9));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityAdditionalField>} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setAdditionalFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.campaigns.OpportunityAdditionalField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityAdditionalField}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.addAdditionalFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.campaigns.OpportunityAdditionalField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearAdditionalFieldsList = function() {
  return this.setAdditionalFieldsList([]);
};


/**
 * repeated OpportunityPersonContactResponse contacts = 10;
 * @return {!Array<!proto.campaigns.OpportunityPersonContactResponse>}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityPersonContactResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityPersonContactResponse, 10));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityPersonContactResponse>} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.campaigns.OpportunityPersonContactResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityPersonContactResponse}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.campaigns.OpportunityPersonContactResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * repeated PersonalizationResponse steps = 11;
 * @return {!Array<!proto.campaigns.PersonalizationResponse>}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.campaigns.PersonalizationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.PersonalizationResponse, 11));
};


/**
 * @param {!Array<!proto.campaigns.PersonalizationResponse>} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.campaigns.PersonalizationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.PersonalizationResponse}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.campaigns.PersonalizationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};


/**
 * optional OpportunityVerificationStatus status = 12;
 * @return {!proto.campaigns.OpportunityVerificationStatus}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.OpportunityVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.campaigns.OpportunityVerificationStatus} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string verification_reason = 13;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getVerificationReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setVerificationReason = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string optional_target_url = 14;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getOptionalTargetUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setOptionalTargetUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string optional_anchor_text = 15;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getOptionalAnchorText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setOptionalAnchorText = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional people.PeopleWebsiteExtendedResponse website = 16;
 * @return {?proto.people.PeopleWebsiteExtendedResponse}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getWebsite = function() {
  return /** @type{?proto.people.PeopleWebsiteExtendedResponse} */ (
    jspb.Message.getWrapperField(this, people$website_pb.PeopleWebsiteExtendedResponse, 16));
};


/**
 * @param {?proto.people.PeopleWebsiteExtendedResponse|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearWebsite = function() {
  return this.setWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional OpportunityArticleMetrics google_search = 17;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getGoogleSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 17));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional OpportunityArticleMetrics bing_news = 18;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getBingNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 18));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setBingNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional OpportunityAudioPodcastMetrics podcast = 19;
 * @return {?proto.campaigns.OpportunityAudioPodcastMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getPodcast = function() {
  return /** @type{?proto.campaigns.OpportunityAudioPodcastMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioPodcastMetrics, 19));
};


/**
 * @param {?proto.campaigns.OpportunityAudioPodcastMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional OpportunityAudioEpisodeMetrics episode = 20;
 * @return {?proto.campaigns.OpportunityAudioEpisodeMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getEpisode = function() {
  return /** @type{?proto.campaigns.OpportunityAudioEpisodeMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioEpisodeMetrics, 20));
};


/**
 * @param {?proto.campaigns.OpportunityAudioEpisodeMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setEpisode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional OpportunityTrendingContentMetrics trending_content = 21;
 * @return {?proto.campaigns.OpportunityTrendingContentMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getTrendingContent = function() {
  return /** @type{?proto.campaigns.OpportunityTrendingContentMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityTrendingContentMetrics, 21));
};


/**
 * @param {?proto.campaigns.OpportunityTrendingContentMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setTrendingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearTrendingContent = function() {
  return this.setTrendingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasTrendingContent = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional OpportunityArticleMetrics bing_search = 22;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getBingSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 22));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setBingSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional OpportunityBacklinksMetrics ahref_backlinks = 23;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 23));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional OpportunityBacklinksMetrics semrush_backlinks = 24;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 24));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional OpportunityBacklinksMetrics moz_backlinks = 25;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getMozBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 25));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional OpportunityArticleMetrics google_news = 26;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getGoogleNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 26));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
*/
proto.campaigns.CampaignOpportunityDetails.prototype.setGoogleNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.campaigns.CampaignOpportunityDetails.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional common.ContentMetricsType metrics_type = 27;
 * @return {!proto.common.ContentMetricsType}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getMetricsType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setMetricsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional bool reviewed = 28;
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityDetails.prototype.getReviewed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignOpportunityDetails} returns this
 */
proto.campaigns.CampaignOpportunityDetails.prototype.setReviewed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.OpportunityPersonContactResponse.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityPersonContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityPersonContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contactOrder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contactEmail: (f = msg.getContactEmail()) && common_pb.Email.toObject(includeInstance, f),
    personId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    jobPosition: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    socialLinksList: jspb.Message.toObjectList(msg.getSocialLinksList(),
    common_pb.SocialLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityPersonContactResponse}
 */
proto.campaigns.OpportunityPersonContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityPersonContactResponse;
  return proto.campaigns.OpportunityPersonContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityPersonContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityPersonContactResponse}
 */
proto.campaigns.OpportunityPersonContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactOrder(value);
      break;
    case 3:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.setContactEmail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobPosition(value);
      break;
    case 7:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new common_pb.SocialLink;
      reader.readMessage(value,common_pb.SocialLink.deserializeBinaryFromReader);
      msg.addSocialLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityPersonContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityPersonContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContactOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getContactEmail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getJobPosition();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSocialLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_pb.SocialLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 contact_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getContactId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.setContactId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 contact_order = 2;
 * @return {number}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getContactOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.setContactOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.Email contact_email = 3;
 * @return {?proto.common.Email}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getContactEmail = function() {
  return /** @type{?proto.common.Email} */ (
    jspb.Message.getWrapperField(this, common_pb.Email, 3));
};


/**
 * @param {?proto.common.Email|undefined} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
*/
proto.campaigns.OpportunityPersonContactResponse.prototype.setContactEmail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.clearContactEmail = function() {
  return this.setContactEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.hasContactEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 person_id = 4;
 * @return {number}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string job_position = 6;
 * @return {string}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getJobPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.setJobPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional common.PeopleRelationshipStatus status = 7;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated common.SocialLink social_links = 8;
 * @return {!Array<!proto.common.SocialLink>}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.getSocialLinksList = function() {
  return /** @type{!Array<!proto.common.SocialLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.SocialLink, 8));
};


/**
 * @param {!Array<!proto.common.SocialLink>} value
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
*/
proto.campaigns.OpportunityPersonContactResponse.prototype.setSocialLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.SocialLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.SocialLink}
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.addSocialLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.SocialLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.OpportunityPersonContactResponse} returns this
 */
proto.campaigns.OpportunityPersonContactResponse.prototype.clearSocialLinksList = function() {
  return this.setSocialLinksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignOpportunityPageResponse.repeatedFields_ = [12];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_ = [[14,15,16,17,18,19,20,21,22,23]];

/**
 * @enum {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.MetricsCase = {
  METRICS_NOT_SET: 0,
  GOOGLE_SEARCH: 14,
  BING_NEWS: 15,
  PODCAST: 16,
  EPISODE: 17,
  TRENDING_CONTENT: 18,
  BING_SEARCH: 19,
  AHREF_BACKLINKS: 20,
  SEMRUSH_BACKLINKS: 21,
  MOZ_BACKLINKS: 22,
  GOOGLE_NEWS: 23
};

/**
 * @return {proto.campaigns.CampaignOpportunityPageResponse.MetricsCase}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getMetricsCase = function() {
  return /** @type {proto.campaigns.CampaignOpportunityPageResponse.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignOpportunityPageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignOpportunityPageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityPageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    opportunityImageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.campaigns.OpportunityPersonContactResponse.toObject, includeInstance),
    totalItems: jspb.Message.getFieldWithDefault(msg, 13, 0),
    googleSearch: (f = msg.getGoogleSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.campaigns.OpportunityAudioPodcastMetrics.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.campaigns.OpportunityAudioEpisodeMetrics.toObject(includeInstance, f),
    trendingContent: (f = msg.getTrendingContent()) && proto.campaigns.OpportunityTrendingContentMetrics.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    metricsType: jspb.Message.getFieldWithDefault(msg, 24, 0),
    reviewed: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    relationshipStatus: jspb.Message.getFieldWithDefault(msg, 26, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse}
 */
proto.campaigns.CampaignOpportunityPageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignOpportunityPageResponse;
  return proto.campaigns.CampaignOpportunityPageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignOpportunityPageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse}
 */
proto.campaigns.CampaignOpportunityPageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityImageUrl(value);
      break;
    case 8:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {!proto.campaigns.OpportunityVerificationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = new proto.campaigns.OpportunityPersonContactResponse;
      reader.readMessage(value,proto.campaigns.OpportunityPersonContactResponse.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalItems(value);
      break;
    case 14:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 15:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 16:
      var value = new proto.campaigns.OpportunityAudioPodcastMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 17:
      var value = new proto.campaigns.OpportunityAudioEpisodeMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    case 18:
      var value = new proto.campaigns.OpportunityTrendingContentMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinaryFromReader);
      msg.setTrendingContent(value);
      break;
    case 19:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 20:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 21:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 22:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 23:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    case 24:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setMetricsType(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReviewed(value);
      break;
    case 26:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setRelationshipStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignOpportunityPageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignOpportunityPageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityPageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOpportunityImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.campaigns.OpportunityPersonContactResponse.serializeBinaryToWriter
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.campaigns.OpportunityAudioPodcastMetrics.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.campaigns.OpportunityAudioEpisodeMetrics.serializeBinaryToWriter
    );
  }
  f = message.getTrendingContent();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.campaigns.OpportunityTrendingContentMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMetricsType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getReviewed();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getRelationshipStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 pipeline_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string uid = 4;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string opportunity_image_url = 7;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getOpportunityImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setOpportunityImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional common.ContentSearchType type = 8;
 * @return {!proto.common.ContentSearchType}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional OpportunityVerificationStatus status = 9;
 * @return {!proto.campaigns.OpportunityVerificationStatus}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.OpportunityVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.campaigns.OpportunityVerificationStatus} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 owner_id = 10;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 created_at = 11;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated OpportunityPersonContactResponse contacts = 12;
 * @return {!Array<!proto.campaigns.OpportunityPersonContactResponse>}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityPersonContactResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityPersonContactResponse, 12));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityPersonContactResponse>} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.campaigns.OpportunityPersonContactResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityPersonContactResponse}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.campaigns.OpportunityPersonContactResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional int64 total_items = 13;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional OpportunityArticleMetrics google_search = 14;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getGoogleSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 14));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional OpportunityArticleMetrics bing_news = 15;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getBingNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 15));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setBingNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional OpportunityAudioPodcastMetrics podcast = 16;
 * @return {?proto.campaigns.OpportunityAudioPodcastMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getPodcast = function() {
  return /** @type{?proto.campaigns.OpportunityAudioPodcastMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioPodcastMetrics, 16));
};


/**
 * @param {?proto.campaigns.OpportunityAudioPodcastMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional OpportunityAudioEpisodeMetrics episode = 17;
 * @return {?proto.campaigns.OpportunityAudioEpisodeMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getEpisode = function() {
  return /** @type{?proto.campaigns.OpportunityAudioEpisodeMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioEpisodeMetrics, 17));
};


/**
 * @param {?proto.campaigns.OpportunityAudioEpisodeMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setEpisode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional OpportunityTrendingContentMetrics trending_content = 18;
 * @return {?proto.campaigns.OpportunityTrendingContentMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getTrendingContent = function() {
  return /** @type{?proto.campaigns.OpportunityTrendingContentMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityTrendingContentMetrics, 18));
};


/**
 * @param {?proto.campaigns.OpportunityTrendingContentMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setTrendingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearTrendingContent = function() {
  return this.setTrendingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasTrendingContent = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional OpportunityArticleMetrics bing_search = 19;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getBingSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 19));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setBingSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional OpportunityBacklinksMetrics ahref_backlinks = 20;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 20));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional OpportunityBacklinksMetrics semrush_backlinks = 21;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 21));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional OpportunityBacklinksMetrics moz_backlinks = 22;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getMozBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 22));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional OpportunityArticleMetrics google_news = 23;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getGoogleNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 23));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
*/
proto.campaigns.CampaignOpportunityPageResponse.prototype.setGoogleNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.campaigns.CampaignOpportunityPageResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional common.ContentMetricsType metrics_type = 24;
 * @return {!proto.common.ContentMetricsType}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getMetricsType = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setMetricsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional bool reviewed = 25;
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getReviewed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setReviewed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional common.PeopleRelationshipStatus relationship_status = 26;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.getRelationshipStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.campaigns.CampaignOpportunityPageResponse} returns this
 */
proto.campaigns.CampaignOpportunityPageResponse.prototype.setRelationshipStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignOpportunityShort.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignOpportunityShort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignOpportunityShort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityShort.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    edited: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    verificationReason: jspb.Message.getFieldWithDefault(msg, 7, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignOpportunityShort}
 */
proto.campaigns.CampaignOpportunityShort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignOpportunityShort;
  return proto.campaigns.CampaignOpportunityShort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignOpportunityShort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignOpportunityShort}
 */
proto.campaigns.CampaignOpportunityShort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEdited(value);
      break;
    case 6:
      var value = /** @type {!proto.campaigns.OpportunityVerificationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationReason(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignOpportunityShort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignOpportunityShort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignOpportunityShort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityShort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEdited();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getVerificationReason();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uid = 2;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool edited = 5;
 * @return {boolean}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getEdited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setEdited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional OpportunityVerificationStatus status = 6;
 * @return {!proto.campaigns.OpportunityVerificationStatus}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.OpportunityVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.campaigns.OpportunityVerificationStatus} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string verification_reason = 7;
 * @return {string}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getVerificationReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setVerificationReason = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 total_items = 8;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityShort.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityShort} returns this
 */
proto.campaigns.CampaignOpportunityShort.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignOpportunityStat.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignOpportunityStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignOpportunityStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    withContacts: jspb.Message.getFieldWithDefault(msg, 1, 0),
    withoutContacts: jspb.Message.getFieldWithDefault(msg, 2, 0),
    withPersonalizedVariables: jspb.Message.getFieldWithDefault(msg, 3, 0),
    withoutPersonalizedVariables: jspb.Message.getFieldWithDefault(msg, 4, 0),
    withoutPersonalizedTextVariables: jspb.Message.getFieldWithDefault(msg, 5, 0),
    withoutPersonalizedAudioVariables: jspb.Message.getFieldWithDefault(msg, 6, 0),
    withPersonalization: jspb.Message.getFieldWithDefault(msg, 7, 0),
    withoutPersonalization: jspb.Message.getFieldWithDefault(msg, 8, 0),
    total: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignOpportunityStat}
 */
proto.campaigns.CampaignOpportunityStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignOpportunityStat;
  return proto.campaigns.CampaignOpportunityStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignOpportunityStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignOpportunityStat}
 */
proto.campaigns.CampaignOpportunityStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithContacts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithoutContacts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithPersonalizedVariables(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithoutPersonalizedVariables(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithoutPersonalizedTextVariables(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithoutPersonalizedAudioVariables(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithPersonalization(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithoutPersonalization(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignOpportunityStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignOpportunityStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignOpportunityStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithContacts();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWithoutContacts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWithPersonalizedVariables();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getWithoutPersonalizedVariables();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWithoutPersonalizedTextVariables();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWithoutPersonalizedAudioVariables();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getWithPersonalization();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getWithoutPersonalization();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 with_contacts = 1;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithContacts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithContacts = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 without_contacts = 2;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithoutContacts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithoutContacts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 with_personalized_variables = 3;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithPersonalizedVariables = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithPersonalizedVariables = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 without_personalized_variables = 4;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithoutPersonalizedVariables = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithoutPersonalizedVariables = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 without_personalized_text_variables = 5;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithoutPersonalizedTextVariables = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithoutPersonalizedTextVariables = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 without_personalized_audio_variables = 6;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithoutPersonalizedAudioVariables = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithoutPersonalizedAudioVariables = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 with_personalization = 7;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithPersonalization = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithPersonalization = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 without_personalization = 8;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getWithoutPersonalization = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setWithoutPersonalization = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 total = 9;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityStat.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityStat} returns this
 */
proto.campaigns.CampaignOpportunityStat.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.AddCampaignOpportunityListRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.AddCampaignOpportunityListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest}
 */
proto.campaigns.AddCampaignOpportunityListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.AddCampaignOpportunityListRequest;
  return proto.campaigns.AddCampaignOpportunityListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest}
 */
proto.campaigns.AddCampaignOpportunityListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = new proto.campaigns.AddCampaignOpportunityListRequest.Opportunity;
      reader.readMessage(value,proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.AddCampaignOpportunityListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_ = [[5,6,7,8,9,10,11,12,13,14]];

/**
 * @enum {number}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.MetricsCase = {
  METRICS_NOT_SET: 0,
  GOOGLE_SEARCH: 5,
  BING_NEWS: 6,
  PODCAST: 7,
  EPISODE: 8,
  TRENDING_CONTENT: 9,
  BING_SEARCH: 10,
  AHREF_BACKLINKS: 11,
  SEMRUSH_BACKLINKS: 12,
  MOZ_BACKLINKS: 13,
  GOOGLE_NEWS: 14
};

/**
 * @return {proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.MetricsCase}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getMetricsCase = function() {
  return /** @type {proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.MetricsCase} */(jspb.Message.computeOneofCase(this, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    googleSearch: (f = msg.getGoogleSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    bingNews: (f = msg.getBingNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    podcast: (f = msg.getPodcast()) && proto.campaigns.OpportunityAudioPodcastMetrics.toObject(includeInstance, f),
    episode: (f = msg.getEpisode()) && proto.campaigns.OpportunityAudioEpisodeMetrics.toObject(includeInstance, f),
    trendingContent: (f = msg.getTrendingContent()) && proto.campaigns.OpportunityTrendingContentMetrics.toObject(includeInstance, f),
    bingSearch: (f = msg.getBingSearch()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f),
    ahrefBacklinks: (f = msg.getAhrefBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    semrushBacklinks: (f = msg.getSemrushBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    mozBacklinks: (f = msg.getMozBacklinks()) && proto.campaigns.OpportunityBacklinksMetrics.toObject(includeInstance, f),
    googleNews: (f = msg.getGoogleNews()) && proto.campaigns.OpportunityArticleMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.AddCampaignOpportunityListRequest.Opportunity;
  return proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.common.ContentSearchType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleSearch(value);
      break;
    case 6:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingNews(value);
      break;
    case 7:
      var value = new proto.campaigns.OpportunityAudioPodcastMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioPodcastMetrics.deserializeBinaryFromReader);
      msg.setPodcast(value);
      break;
    case 8:
      var value = new proto.campaigns.OpportunityAudioEpisodeMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityAudioEpisodeMetrics.deserializeBinaryFromReader);
      msg.setEpisode(value);
      break;
    case 9:
      var value = new proto.campaigns.OpportunityTrendingContentMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityTrendingContentMetrics.deserializeBinaryFromReader);
      msg.setTrendingContent(value);
      break;
    case 10:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setBingSearch(value);
      break;
    case 11:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setAhrefBacklinks(value);
      break;
    case 12:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setSemrushBacklinks(value);
      break;
    case 13:
      var value = new proto.campaigns.OpportunityBacklinksMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityBacklinksMetrics.deserializeBinaryFromReader);
      msg.setMozBacklinks(value);
      break;
    case 14:
      var value = new proto.campaigns.OpportunityArticleMetrics;
      reader.readMessage(value,proto.campaigns.OpportunityArticleMetrics.deserializeBinaryFromReader);
      msg.setGoogleNews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGoogleSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingNews();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getPodcast();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.campaigns.OpportunityAudioPodcastMetrics.serializeBinaryToWriter
    );
  }
  f = message.getEpisode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.campaigns.OpportunityAudioEpisodeMetrics.serializeBinaryToWriter
    );
  }
  f = message.getTrendingContent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.campaigns.OpportunityTrendingContentMetrics.serializeBinaryToWriter
    );
  }
  f = message.getBingSearch();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
  f = message.getAhrefBacklinks();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getSemrushBacklinks();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getMozBacklinks();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.campaigns.OpportunityBacklinksMetrics.serializeBinaryToWriter
    );
  }
  f = message.getGoogleNews();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.campaigns.OpportunityArticleMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.ContentSearchType type = 4;
 * @return {!proto.common.ContentSearchType}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getType = function() {
  return /** @type {!proto.common.ContentSearchType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.ContentSearchType} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional OpportunityArticleMetrics google_search = 5;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getGoogleSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 5));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setGoogleSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearGoogleSearch = function() {
  return this.setGoogleSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasGoogleSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OpportunityArticleMetrics bing_news = 6;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getBingNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 6));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setBingNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearBingNews = function() {
  return this.setBingNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasBingNews = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OpportunityAudioPodcastMetrics podcast = 7;
 * @return {?proto.campaigns.OpportunityAudioPodcastMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getPodcast = function() {
  return /** @type{?proto.campaigns.OpportunityAudioPodcastMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioPodcastMetrics, 7));
};


/**
 * @param {?proto.campaigns.OpportunityAudioPodcastMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setPodcast = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearPodcast = function() {
  return this.setPodcast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasPodcast = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional OpportunityAudioEpisodeMetrics episode = 8;
 * @return {?proto.campaigns.OpportunityAudioEpisodeMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getEpisode = function() {
  return /** @type{?proto.campaigns.OpportunityAudioEpisodeMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityAudioEpisodeMetrics, 8));
};


/**
 * @param {?proto.campaigns.OpportunityAudioEpisodeMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setEpisode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearEpisode = function() {
  return this.setEpisode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasEpisode = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional OpportunityTrendingContentMetrics trending_content = 9;
 * @return {?proto.campaigns.OpportunityTrendingContentMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getTrendingContent = function() {
  return /** @type{?proto.campaigns.OpportunityTrendingContentMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityTrendingContentMetrics, 9));
};


/**
 * @param {?proto.campaigns.OpportunityTrendingContentMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setTrendingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearTrendingContent = function() {
  return this.setTrendingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasTrendingContent = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional OpportunityArticleMetrics bing_search = 10;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getBingSearch = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 10));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setBingSearch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearBingSearch = function() {
  return this.setBingSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasBingSearch = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional OpportunityBacklinksMetrics ahref_backlinks = 11;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getAhrefBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 11));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setAhrefBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearAhrefBacklinks = function() {
  return this.setAhrefBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasAhrefBacklinks = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional OpportunityBacklinksMetrics semrush_backlinks = 12;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getSemrushBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 12));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setSemrushBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearSemrushBacklinks = function() {
  return this.setSemrushBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasSemrushBacklinks = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional OpportunityBacklinksMetrics moz_backlinks = 13;
 * @return {?proto.campaigns.OpportunityBacklinksMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getMozBacklinks = function() {
  return /** @type{?proto.campaigns.OpportunityBacklinksMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityBacklinksMetrics, 13));
};


/**
 * @param {?proto.campaigns.OpportunityBacklinksMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setMozBacklinks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearMozBacklinks = function() {
  return this.setMozBacklinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasMozBacklinks = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional OpportunityArticleMetrics google_news = 14;
 * @return {?proto.campaigns.OpportunityArticleMetrics}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.getGoogleNews = function() {
  return /** @type{?proto.campaigns.OpportunityArticleMetrics} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.OpportunityArticleMetrics, 14));
};


/**
 * @param {?proto.campaigns.OpportunityArticleMetrics|undefined} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.setGoogleNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.clearGoogleNews = function() {
  return this.setGoogleNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListRequest.Opportunity.prototype.hasGoogleNews = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Opportunity items = 4;
 * @return {!Array<!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity>}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity, 4));
};


/**
 * @param {!Array<!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity>} value
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest} returns this
*/
proto.campaigns.AddCampaignOpportunityListRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest.Opportunity}
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.campaigns.AddCampaignOpportunityListRequest.Opportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.AddCampaignOpportunityListRequest} returns this
 */
proto.campaigns.AddCampaignOpportunityListRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.AddCampaignOpportunityListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.AddCampaignOpportunityListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.toObject, includeInstance),
    successItems: jspb.Message.getFieldWithDefault(msg, 2, 0),
    failedItems: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse}
 */
proto.campaigns.AddCampaignOpportunityListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.AddCampaignOpportunityListResponse;
  return proto.campaigns.AddCampaignOpportunityListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse}
 */
proto.campaigns.AddCampaignOpportunityListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.campaigns.AddCampaignOpportunityListResponse.Opportunity;
      reader.readMessage(value,proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSuccessItems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFailedItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.AddCampaignOpportunityListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.serializeBinaryToWriter
    );
  }
  f = message.getSuccessItems();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFailedItems();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.AddCampaignOpportunityListResponse.Opportunity;
  return proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uid = 2;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool success = 3;
 * @return {boolean}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string error_message = 4;
 * @return {string}
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.Opportunity.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Opportunity items = 1;
 * @return {!Array<!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity>}
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.AddCampaignOpportunityListResponse.Opportunity, 1));
};


/**
 * @param {!Array<!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity>} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse} returns this
*/
proto.campaigns.AddCampaignOpportunityListResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse.Opportunity}
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.campaigns.AddCampaignOpportunityListResponse.Opportunity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int32 success_items = 2;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.getSuccessItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.setSuccessItems = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 failed_items = 3;
 * @return {number}
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.getFailedItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.AddCampaignOpportunityListResponse} returns this
 */
proto.campaigns.AddCampaignOpportunityListResponse.prototype.setFailedItems = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignOpportunityVerificationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignOpportunityVerificationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityVerificationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignOpportunityVerificationRequest}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignOpportunityVerificationRequest;
  return proto.campaigns.CampaignOpportunityVerificationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignOpportunityVerificationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignOpportunityVerificationRequest}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignOpportunityVerificationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignOpportunityVerificationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityVerificationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityVerificationRequest} returns this
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityVerificationRequest} returns this
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityVerificationRequest} returns this
 */
proto.campaigns.CampaignOpportunityVerificationRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignOpportunityListRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignOpportunityListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignOpportunityListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    opportunityIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignOpportunityListRequest}
 */
proto.campaigns.CampaignOpportunityListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignOpportunityListRequest;
  return proto.campaigns.CampaignOpportunityListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignOpportunityListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignOpportunityListRequest}
 */
proto.campaigns.CampaignOpportunityListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOpportunityIdList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignOpportunityListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignOpportunityListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignOpportunityListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOpportunityIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityListRequest} returns this
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityListRequest} returns this
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignOpportunityListRequest} returns this
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated int64 opportunity_id = 4;
 * @return {!Array<number>}
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.getOpportunityIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.campaigns.CampaignOpportunityListRequest} returns this
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.setOpportunityIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignOpportunityListRequest} returns this
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.addOpportunityId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignOpportunityListRequest} returns this
 */
proto.campaigns.CampaignOpportunityListRequest.prototype.clearOpportunityIdList = function() {
  return this.setOpportunityIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.RemoveOpportunityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.RemoveOpportunityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.RemoveOpportunityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.RemoveOpportunityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    opportunityUid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.RemoveOpportunityRequest}
 */
proto.campaigns.RemoveOpportunityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.RemoveOpportunityRequest;
  return proto.campaigns.RemoveOpportunityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.RemoveOpportunityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.RemoveOpportunityRequest}
 */
proto.campaigns.RemoveOpportunityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.RemoveOpportunityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.RemoveOpportunityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.RemoveOpportunityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.RemoveOpportunityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOpportunityUid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.RemoveOpportunityRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.RemoveOpportunityRequest} returns this
 */
proto.campaigns.RemoveOpportunityRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.RemoveOpportunityRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.RemoveOpportunityRequest} returns this
 */
proto.campaigns.RemoveOpportunityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.RemoveOpportunityRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.RemoveOpportunityRequest} returns this
 */
proto.campaigns.RemoveOpportunityRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string opportunity_uid = 4;
 * @return {string}
 */
proto.campaigns.RemoveOpportunityRequest.prototype.getOpportunityUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.RemoveOpportunityRequest} returns this
 */
proto.campaigns.RemoveOpportunityRequest.prototype.setOpportunityUid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.GetCampaignOpportunities.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.GetCampaignOpportunities.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.GetCampaignOpportunities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.GetCampaignOpportunities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.GetCampaignOpportunities.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    launched: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.campaigns.OpportunityFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.GetCampaignOpportunities}
 */
proto.campaigns.GetCampaignOpportunities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.GetCampaignOpportunities;
  return proto.campaigns.GetCampaignOpportunities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.GetCampaignOpportunities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.GetCampaignOpportunities}
 */
proto.campaigns.GetCampaignOpportunities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLaunched(value);
      break;
    case 7:
      var value = new proto.campaigns.OpportunityFilter;
      reader.readMessage(value,proto.campaigns.OpportunityFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.GetCampaignOpportunities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.GetCampaignOpportunities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.GetCampaignOpportunities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.GetCampaignOpportunities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLaunched();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.campaigns.OpportunityFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool launched = 6;
 * @return {boolean}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getLaunched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.setLaunched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated OpportunityFilter optional_filters = 7;
 * @return {!Array<!proto.campaigns.OpportunityFilter>}
 */
proto.campaigns.GetCampaignOpportunities.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityFilter, 7));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityFilter>} value
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
*/
proto.campaigns.GetCampaignOpportunities.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.campaigns.OpportunityFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityFilter}
 */
proto.campaigns.GetCampaignOpportunities.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.campaigns.OpportunityFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.GetCampaignOpportunities} returns this
 */
proto.campaigns.GetCampaignOpportunities.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.BatchOpportunitiesRequest.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.BatchOpportunitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.BatchOpportunitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.BatchOpportunitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.campaigns.OpportunityFilter.toObject, includeInstance),
    optionalSelectedList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    launched: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.BatchOpportunitiesRequest}
 */
proto.campaigns.BatchOpportunitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.BatchOpportunitiesRequest;
  return proto.campaigns.BatchOpportunitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.BatchOpportunitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.BatchOpportunitiesRequest}
 */
proto.campaigns.BatchOpportunitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {!proto.campaigns.BatchOpportunitiesRequest.OperationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.campaigns.OpportunityFilter;
      reader.readMessage(value,proto.campaigns.OpportunityFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOptionalSelectedList(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLaunched(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.BatchOpportunitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.BatchOpportunitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.BatchOpportunitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.campaigns.OpportunityFilter.serializeBinaryToWriter
    );
  }
  f = message.getOptionalSelectedList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getLaunched();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.campaigns.BatchOpportunitiesRequest.OperationType = {
  ALL: 0,
  FILTERED: 1,
  SELECTED: 2
};

/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional OperationType type = 4;
 * @return {!proto.campaigns.BatchOpportunitiesRequest.OperationType}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getType = function() {
  return /** @type {!proto.campaigns.BatchOpportunitiesRequest.OperationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.campaigns.BatchOpportunitiesRequest.OperationType} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated OpportunityFilter optional_filters = 5;
 * @return {!Array<!proto.campaigns.OpportunityFilter>}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityFilter, 5));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityFilter>} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
*/
proto.campaigns.BatchOpportunitiesRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.campaigns.OpportunityFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityFilter}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.campaigns.OpportunityFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};


/**
 * repeated int64 optional_selected = 6;
 * @return {!Array<number>}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getOptionalSelectedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.setOptionalSelectedList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.addOptionalSelected = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.clearOptionalSelectedList = function() {
  return this.setOptionalSelectedList([]);
};


/**
 * optional bool launched = 7;
 * @return {boolean}
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.getLaunched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.BatchOpportunitiesRequest} returns this
 */
proto.campaigns.BatchOpportunitiesRequest.prototype.setLaunched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityFilter}
 */
proto.campaigns.OpportunityFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityFilter;
  return proto.campaigns.OpportunityFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityFilter}
 */
proto.campaigns.OpportunityFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.campaigns.OpportunityFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.campaigns.OpportunityFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityFilter} returns this
 */
proto.campaigns.OpportunityFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional OpportunityFilterField field = 2;
 * @return {!proto.campaigns.OpportunityFilterField}
 */
proto.campaigns.OpportunityFilter.prototype.getField = function() {
  return /** @type {!proto.campaigns.OpportunityFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.campaigns.OpportunityFilterField} value
 * @return {!proto.campaigns.OpportunityFilter} returns this
 */
proto.campaigns.OpportunityFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.campaigns.OpportunityFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.OpportunityFilter} returns this
 */
proto.campaigns.OpportunityFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.campaigns.OpportunityFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.campaigns.OpportunityFilter} returns this
 */
proto.campaigns.OpportunityFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.campaigns.OpportunityFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.campaigns.OpportunityFilter} returns this
 */
proto.campaigns.OpportunityFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    page: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    present: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    allOpportunities: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.GetCampaignOpportunitiesCriteriaPresence;
  return proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPresent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllOpportunities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPresent();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getAllOpportunities();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page = 4;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 limit = 5;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool present = 6;
 * @return {boolean}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setPresent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 pipeline_id = 7;
 * @return {number}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool all_opportunities = 8;
 * @return {boolean}
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.getAllOpportunities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.GetCampaignOpportunitiesCriteriaPresence} returns this
 */
proto.campaigns.GetCampaignOpportunitiesCriteriaPresence.prototype.setAllOpportunities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignFilterRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    titleQuery: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dateRange: (f = msg.getDateRange()) && common_pb.TimestampRange.toObject(includeInstance, f),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    page: jspb.Message.getFieldWithDefault(msg, 6, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignFilterRequest}
 */
proto.campaigns.CampaignFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignFilterRequest;
  return proto.campaigns.CampaignFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignFilterRequest}
 */
proto.campaigns.CampaignFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitleQuery(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOwnerIdsList(value);
      break;
    case 3:
      var value = new common_pb.TimestampRange;
      reader.readMessage(value,common_pb.TimestampRange.deserializeBinaryFromReader);
      msg.setDateRange(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {!proto.campaigns.CampaignFilterStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitleQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDateRange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string title_query = 1;
 * @return {string}
 */
proto.campaigns.CampaignFilterRequest.prototype.getTitleQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setTitleQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 owner_ids = 2;
 * @return {!Array<number>}
 */
proto.campaigns.CampaignFilterRequest.prototype.getOwnerIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setOwnerIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.addOwnerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.clearOwnerIdsList = function() {
  return this.setOwnerIdsList([]);
};


/**
 * optional common.TimestampRange date_range = 3;
 * @return {?proto.common.TimestampRange}
 */
proto.campaigns.CampaignFilterRequest.prototype.getDateRange = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, common_pb.TimestampRange, 3));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
*/
proto.campaigns.CampaignFilterRequest.prototype.setDateRange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.clearDateRange = function() {
  return this.setDateRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.CampaignFilterRequest.prototype.hasDateRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 workspace_id = 4;
 * @return {number}
 */
proto.campaigns.CampaignFilterRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 optional_folder_id = 5;
 * @return {number}
 */
proto.campaigns.CampaignFilterRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 page = 6;
 * @return {number}
 */
proto.campaigns.CampaignFilterRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 limit = 7;
 * @return {number}
 */
proto.campaigns.CampaignFilterRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional CampaignFilterStatus status = 8;
 * @return {!proto.campaigns.CampaignFilterStatus}
 */
proto.campaigns.CampaignFilterRequest.prototype.getStatus = function() {
  return /** @type {!proto.campaigns.CampaignFilterStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.campaigns.CampaignFilterStatus} value
 * @return {!proto.campaigns.CampaignFilterRequest} returns this
 */
proto.campaigns.CampaignFilterRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.campaigns.CampaignFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignRequest}
 */
proto.campaigns.CampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignRequest;
  return proto.campaigns.CampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignRequest}
 */
proto.campaigns.CampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = new proto.campaigns.CampaignFilter;
      reader.readMessage(value,proto.campaigns.CampaignFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.campaigns.CampaignFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignRequest} returns this
 */
proto.campaigns.CampaignRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.campaigns.CampaignRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignRequest} returns this
 */
proto.campaigns.CampaignRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.campaigns.CampaignRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignRequest} returns this
 */
proto.campaigns.CampaignRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CampaignFilter optional_filters = 4;
 * @return {!Array<!proto.campaigns.CampaignFilter>}
 */
proto.campaigns.CampaignRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.campaigns.CampaignFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.CampaignFilter, 4));
};


/**
 * @param {!Array<!proto.campaigns.CampaignFilter>} value
 * @return {!proto.campaigns.CampaignRequest} returns this
*/
proto.campaigns.CampaignRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.campaigns.CampaignFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignFilter}
 */
proto.campaigns.CampaignRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.campaigns.CampaignFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignRequest} returns this
 */
proto.campaigns.CampaignRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignFilter}
 */
proto.campaigns.CampaignFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignFilter;
  return proto.campaigns.CampaignFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignFilter}
 */
proto.campaigns.CampaignFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.campaigns.CampaignFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.campaigns.CampaignFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignFilter} returns this
 */
proto.campaigns.CampaignFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CampaignFilterField field = 2;
 * @return {!proto.campaigns.CampaignFilterField}
 */
proto.campaigns.CampaignFilter.prototype.getField = function() {
  return /** @type {!proto.campaigns.CampaignFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.campaigns.CampaignFilterField} value
 * @return {!proto.campaigns.CampaignFilter} returns this
 */
proto.campaigns.CampaignFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.campaigns.CampaignFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignFilter} returns this
 */
proto.campaigns.CampaignFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.campaigns.CampaignFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.campaigns.CampaignFilter} returns this
 */
proto.campaigns.CampaignFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.campaigns.CampaignFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.campaigns.CampaignFilter} returns this
 */
proto.campaigns.CampaignFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.OpportunityContactContainer.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityContactContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityContactContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityContactContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.campaigns.OpportunityPersonContactResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityContactContainer}
 */
proto.campaigns.OpportunityContactContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityContactContainer;
  return proto.campaigns.OpportunityContactContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityContactContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityContactContainer}
 */
proto.campaigns.OpportunityContactContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 2:
      var value = new proto.campaigns.OpportunityPersonContactResponse;
      reader.readMessage(value,proto.campaigns.OpportunityPersonContactResponse.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityContactContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityContactContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityContactContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.campaigns.OpportunityPersonContactResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 opportunity_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityContactContainer.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactContainer} returns this
 */
proto.campaigns.OpportunityContactContainer.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated OpportunityPersonContactResponse contacts = 2;
 * @return {!Array<!proto.campaigns.OpportunityPersonContactResponse>}
 */
proto.campaigns.OpportunityContactContainer.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityPersonContactResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityPersonContactResponse, 2));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityPersonContactResponse>} value
 * @return {!proto.campaigns.OpportunityContactContainer} returns this
*/
proto.campaigns.OpportunityContactContainer.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.campaigns.OpportunityPersonContactResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityPersonContactResponse}
 */
proto.campaigns.OpportunityContactContainer.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.campaigns.OpportunityPersonContactResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.OpportunityContactContainer} returns this
 */
proto.campaigns.OpportunityContactContainer.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    personId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    email: (f = msg.getEmail()) && common_pb.Email.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    category: jspb.Message.getFieldWithDefault(msg, 7, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityContactRequest}
 */
proto.campaigns.OpportunityContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityContactRequest;
  return proto.campaigns.OpportunityContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityContactRequest}
 */
proto.campaigns.OpportunityContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPersonId(value);
      break;
    case 5:
      var value = new common_pb.Email;
      reader.readMessage(value,common_pb.Email.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {!proto.common.FoundAtCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPersonId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.Email.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getBatch();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 opportunity_id = 3;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 person_id = 4;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getPersonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setPersonId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional common.Email email = 5;
 * @return {?proto.common.Email}
 */
proto.campaigns.OpportunityContactRequest.prototype.getEmail = function() {
  return /** @type{?proto.common.Email} */ (
    jspb.Message.getWrapperField(this, common_pb.Email, 5));
};


/**
 * @param {?proto.common.Email|undefined} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
*/
proto.campaigns.OpportunityContactRequest.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.OpportunityContactRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional common.FoundAtCategory category = 7;
 * @return {!proto.common.FoundAtCategory}
 */
proto.campaigns.OpportunityContactRequest.prototype.getCategory = function() {
  return /** @type {!proto.common.FoundAtCategory} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.FoundAtCategory} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int64 campaign_id = 8;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 batch = 9;
 * @return {number}
 */
proto.campaigns.OpportunityContactRequest.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactRequest} returns this
 */
proto.campaigns.OpportunityContactRequest.prototype.setBatch = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.OpportunityContactOrderRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityContactOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityContactOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityContactOrderRequest}
 */
proto.campaigns.OpportunityContactOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityContactOrderRequest;
  return proto.campaigns.OpportunityContactOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityContactOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityContactOrderRequest}
 */
proto.campaigns.OpportunityContactOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = new proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair;
      reader.readMessage(value,proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityContactOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityContactOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair}
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair;
  return proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair}
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 contact_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.prototype.getContactId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair} returns this
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.prototype.setContactId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair} returns this
 */
proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 opportunity_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactOrderRequest} returns this
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityContactOrderRequest} returns this
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ContactOrderPair orders = 3;
 * @return {!Array<!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair>}
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair, 3));
};


/**
 * @param {!Array<!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair>} value
 * @return {!proto.campaigns.OpportunityContactOrderRequest} returns this
*/
proto.campaigns.OpportunityContactOrderRequest.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair}
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.campaigns.OpportunityContactOrderRequest.ContactOrderPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.OpportunityContactOrderRequest} returns this
 */
proto.campaigns.OpportunityContactOrderRequest.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityPersonalizationGetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityPersonalizationGetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationGetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityPersonalizationGetRequest}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityPersonalizationGetRequest;
  return proto.campaigns.OpportunityPersonalizationGetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityPersonalizationGetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityPersonalizationGetRequest}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityPersonalizationGetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityPersonalizationGetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationGetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationGetRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationGetRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 opportunity_id = 3;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationGetRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationGetRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityComposedDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityComposedDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityComposedDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    templateId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityComposedDataRequest}
 */
proto.campaigns.OpportunityComposedDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityComposedDataRequest;
  return proto.campaigns.OpportunityComposedDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityComposedDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityComposedDataRequest}
 */
proto.campaigns.OpportunityComposedDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityComposedDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityComposedDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityComposedDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityComposedDataRequest} returns this
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityComposedDataRequest} returns this
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 opportunity_id = 3;
 * @return {number}
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityComposedDataRequest} returns this
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 template_id = 4;
 * @return {number}
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.getTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityComposedDataRequest} returns this
 */
proto.campaigns.OpportunityComposedDataRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.OpportunityPersonalizationResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityPersonalizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityPersonalizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.campaigns.PersonalizationResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityPersonalizationResponse}
 */
proto.campaigns.OpportunityPersonalizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityPersonalizationResponse;
  return proto.campaigns.OpportunityPersonalizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityPersonalizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityPersonalizationResponse}
 */
proto.campaigns.OpportunityPersonalizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 2:
      var value = new proto.campaigns.PersonalizationResponse;
      reader.readMessage(value,proto.campaigns.PersonalizationResponse.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityPersonalizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityPersonalizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.campaigns.PersonalizationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 opportunity_id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationResponse} returns this
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PersonalizationResponse steps = 2;
 * @return {!Array<!proto.campaigns.PersonalizationResponse>}
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.campaigns.PersonalizationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.PersonalizationResponse, 2));
};


/**
 * @param {!Array<!proto.campaigns.PersonalizationResponse>} value
 * @return {!proto.campaigns.OpportunityPersonalizationResponse} returns this
*/
proto.campaigns.OpportunityPersonalizationResponse.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.campaigns.PersonalizationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.PersonalizationResponse}
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.campaigns.PersonalizationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.OpportunityPersonalizationResponse} returns this
 */
proto.campaigns.OpportunityPersonalizationResponse.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.PersonalizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.PersonalizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.PersonalizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.PersonalizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stepId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    enabledThreads: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    sendDelay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    threadA: (f = msg.getThreadA()) && proto.campaigns.PersonalizationThreadResponse.toObject(includeInstance, f),
    threadB: (f = msg.getThreadB()) && proto.campaigns.PersonalizationThreadResponse.toObject(includeInstance, f),
    enabledUnsubscribeContent: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    enabledReferralContent: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.PersonalizationResponse}
 */
proto.campaigns.PersonalizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.PersonalizationResponse;
  return proto.campaigns.PersonalizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.PersonalizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.PersonalizationResponse}
 */
proto.campaigns.PersonalizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStepId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledThreads(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSendDelay(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = new proto.campaigns.PersonalizationThreadResponse;
      reader.readMessage(value,proto.campaigns.PersonalizationThreadResponse.deserializeBinaryFromReader);
      msg.setThreadA(value);
      break;
    case 7:
      var value = new proto.campaigns.PersonalizationThreadResponse;
      reader.readMessage(value,proto.campaigns.PersonalizationThreadResponse.deserializeBinaryFromReader);
      msg.setThreadB(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledUnsubscribeContent(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabledReferralContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.PersonalizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.PersonalizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.PersonalizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.PersonalizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStepId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEnabledThreads();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSendDelay();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getThreadA();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.campaigns.PersonalizationThreadResponse.serializeBinaryToWriter
    );
  }
  f = message.getThreadB();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.campaigns.PersonalizationThreadResponse.serializeBinaryToWriter
    );
  }
  f = message.getEnabledUnsubscribeContent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEnabledReferralContent();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.PersonalizationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 step_id = 2;
 * @return {number}
 */
proto.campaigns.PersonalizationResponse.prototype.getStepId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setStepId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool enabled_threads = 3;
 * @return {boolean}
 */
proto.campaigns.PersonalizationResponse.prototype.getEnabledThreads = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setEnabledThreads = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 send_delay = 4;
 * @return {number}
 */
proto.campaigns.PersonalizationResponse.prototype.getSendDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setSendDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.campaigns.PersonalizationResponse.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional PersonalizationThreadResponse thread_a = 6;
 * @return {?proto.campaigns.PersonalizationThreadResponse}
 */
proto.campaigns.PersonalizationResponse.prototype.getThreadA = function() {
  return /** @type{?proto.campaigns.PersonalizationThreadResponse} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.PersonalizationThreadResponse, 6));
};


/**
 * @param {?proto.campaigns.PersonalizationThreadResponse|undefined} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
*/
proto.campaigns.PersonalizationResponse.prototype.setThreadA = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.clearThreadA = function() {
  return this.setThreadA(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.PersonalizationResponse.prototype.hasThreadA = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PersonalizationThreadResponse thread_b = 7;
 * @return {?proto.campaigns.PersonalizationThreadResponse}
 */
proto.campaigns.PersonalizationResponse.prototype.getThreadB = function() {
  return /** @type{?proto.campaigns.PersonalizationThreadResponse} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.PersonalizationThreadResponse, 7));
};


/**
 * @param {?proto.campaigns.PersonalizationThreadResponse|undefined} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
*/
proto.campaigns.PersonalizationResponse.prototype.setThreadB = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.clearThreadB = function() {
  return this.setThreadB(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.PersonalizationResponse.prototype.hasThreadB = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool enabled_unsubscribe_content = 8;
 * @return {boolean}
 */
proto.campaigns.PersonalizationResponse.prototype.getEnabledUnsubscribeContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setEnabledUnsubscribeContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool enabled_referral_content = 9;
 * @return {boolean}
 */
proto.campaigns.PersonalizationResponse.prototype.getEnabledReferralContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.PersonalizationResponse} returns this
 */
proto.campaigns.PersonalizationResponse.prototype.setEnabledReferralContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityPersonalizationUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityPersonalizationUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    templateId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    templateStepId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    thread: (f = msg.getThread()) && proto.campaigns.PersonalizationThreadRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityPersonalizationUpdateRequest;
  return proto.campaigns.OpportunityPersonalizationUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityPersonalizationUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTemplateStepId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 6:
      var value = /** @type {!proto.campaigns.PersonalizationThreadType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = new proto.campaigns.PersonalizationThreadRequest;
      reader.readMessage(value,proto.campaigns.PersonalizationThreadRequest.deserializeBinaryFromReader);
      msg.setThread(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityPersonalizationUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityPersonalizationUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTemplateStepId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getThread();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.campaigns.PersonalizationThreadRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 opportunity_id = 2;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 template_id = 3;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 template_step_id = 4;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getTemplateStepId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setTemplateStepId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 campaign_id = 5;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional PersonalizationThreadType type = 6;
 * @return {!proto.campaigns.PersonalizationThreadType}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getType = function() {
  return /** @type {!proto.campaigns.PersonalizationThreadType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.campaigns.PersonalizationThreadType} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PersonalizationThreadRequest thread = 7;
 * @return {?proto.campaigns.PersonalizationThreadRequest}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.getThread = function() {
  return /** @type{?proto.campaigns.PersonalizationThreadRequest} */ (
    jspb.Message.getWrapperField(this, proto.campaigns.PersonalizationThreadRequest, 7));
};


/**
 * @param {?proto.campaigns.PersonalizationThreadRequest|undefined} value
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
*/
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.setThread = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.OpportunityPersonalizationUpdateRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.clearThread = function() {
  return this.setThread(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.OpportunityPersonalizationUpdateRequest.prototype.hasThread = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.OpportunityPersonalizationDeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.OpportunityPersonalizationDeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    opportunityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.OpportunityPersonalizationDeleteRequest}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.OpportunityPersonalizationDeleteRequest;
  return proto.campaigns.OpportunityPersonalizationDeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.OpportunityPersonalizationDeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.OpportunityPersonalizationDeleteRequest}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.OpportunityPersonalizationDeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.OpportunityPersonalizationDeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationDeleteRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 opportunity_id = 2;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationDeleteRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 campaign_id = 3;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationDeleteRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 workspace_id = 4;
 * @return {number}
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.OpportunityPersonalizationDeleteRequest} returns this
 */
proto.campaigns.OpportunityPersonalizationDeleteRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.PersonalizationThreadResponse.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.PersonalizationThreadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.PersonalizationThreadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.PersonalizationThreadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sameThread: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    emailFrom: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.PersonalizationThreadResponse}
 */
proto.campaigns.PersonalizationThreadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.PersonalizationThreadResponse;
  return proto.campaigns.PersonalizationThreadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.PersonalizationThreadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.PersonalizationThreadResponse}
 */
proto.campaigns.PersonalizationThreadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameThread(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailFrom(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    case 7:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.PersonalizationThreadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.PersonalizationThreadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.PersonalizationThreadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSameThread();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEmailFrom();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool same_thread = 3;
 * @return {boolean}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getSameThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.setSameThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string email_from = 4;
 * @return {string}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getEmailFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.setEmailFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string emails_to_cc = 5;
 * @return {!Array<string>}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 6;
 * @return {!Array<string>}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};


/**
 * repeated common.FileAttachment attachments = 7;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 7));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
*/
proto.campaigns.PersonalizationThreadResponse.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.campaigns.PersonalizationThreadResponse.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.PersonalizationThreadResponse} returns this
 */
proto.campaigns.PersonalizationThreadResponse.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.PersonalizationThreadRequest.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.PersonalizationThreadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.PersonalizationThreadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.PersonalizationThreadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sameThread: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    common_pb.FileAttachment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.PersonalizationThreadRequest}
 */
proto.campaigns.PersonalizationThreadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.PersonalizationThreadRequest;
  return proto.campaigns.PersonalizationThreadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.PersonalizationThreadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.PersonalizationThreadRequest}
 */
proto.campaigns.PersonalizationThreadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameThread(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    case 6:
      var value = new common_pb.FileAttachment;
      reader.readMessage(value,common_pb.FileAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.PersonalizationThreadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.PersonalizationThreadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.PersonalizationThreadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSameThread();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_pb.FileAttachment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool same_thread = 3;
 * @return {boolean}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.getSameThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.setSameThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string emails_to_cc = 4;
 * @return {!Array<string>}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 5;
 * @return {!Array<string>}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};


/**
 * repeated common.FileAttachment attachments = 6;
 * @return {!Array<!proto.common.FileAttachment>}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.common.FileAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.FileAttachment, 6));
};


/**
 * @param {!Array<!proto.common.FileAttachment>} value
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
*/
proto.campaigns.PersonalizationThreadRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.FileAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.FileAttachment}
 */
proto.campaigns.PersonalizationThreadRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.FileAttachment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.PersonalizationThreadRequest} returns this
 */
proto.campaigns.PersonalizationThreadRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignValidationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignValidationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignValidationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignValidationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    checkOnEmptyVariables: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    checkOnPlaceholderBrackets: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    checkOnContactedInPast: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    optionalContactedInPastDaysNumber: jspb.Message.getFieldWithDefault(msg, 6, 0),
    checkOnExistingInOtherActiveCampaign: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    checkOnUnsubscribeList: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignValidationRequest}
 */
proto.campaigns.CampaignValidationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignValidationRequest;
  return proto.campaigns.CampaignValidationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignValidationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignValidationRequest}
 */
proto.campaigns.CampaignValidationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckOnEmptyVariables(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckOnPlaceholderBrackets(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckOnContactedInPast(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalContactedInPastDaysNumber(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckOnExistingInOtherActiveCampaign(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckOnUnsubscribeList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignValidationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignValidationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignValidationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignValidationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCheckOnEmptyVariables();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCheckOnPlaceholderBrackets();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCheckOnContactedInPast();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOptionalContactedInPastDaysNumber();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCheckOnExistingInOtherActiveCampaign();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCheckOnUnsubscribeList();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignValidationRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignValidationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool check_on_empty_variables = 3;
 * @return {boolean}
 */
proto.campaigns.CampaignValidationRequest.prototype.getCheckOnEmptyVariables = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setCheckOnEmptyVariables = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool check_on_placeholder_brackets = 4;
 * @return {boolean}
 */
proto.campaigns.CampaignValidationRequest.prototype.getCheckOnPlaceholderBrackets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setCheckOnPlaceholderBrackets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool check_on_contacted_in_past = 5;
 * @return {boolean}
 */
proto.campaigns.CampaignValidationRequest.prototype.getCheckOnContactedInPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setCheckOnContactedInPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 optional_contacted_in_past_days_number = 6;
 * @return {number}
 */
proto.campaigns.CampaignValidationRequest.prototype.getOptionalContactedInPastDaysNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setOptionalContactedInPastDaysNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool check_on_existing_in_other_active_campaign = 7;
 * @return {boolean}
 */
proto.campaigns.CampaignValidationRequest.prototype.getCheckOnExistingInOtherActiveCampaign = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setCheckOnExistingInOtherActiveCampaign = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool check_on_unsubscribe_list = 8;
 * @return {boolean}
 */
proto.campaigns.CampaignValidationRequest.prototype.getCheckOnUnsubscribeList = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignValidationRequest} returns this
 */
proto.campaigns.CampaignValidationRequest.prototype.setCheckOnUnsubscribeList = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignValidationProgressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignValidationProgressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignValidationProgressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    verifiedNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flaggedNumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignValidationProgressResponse}
 */
proto.campaigns.CampaignValidationProgressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignValidationProgressResponse;
  return proto.campaigns.CampaignValidationProgressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignValidationProgressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignValidationProgressResponse}
 */
proto.campaigns.CampaignValidationProgressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVerifiedNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlaggedNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignValidationProgressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignValidationProgressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignValidationProgressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTotalNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getVerifiedNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getFlaggedNumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationProgressResponse} returns this
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float progress = 2;
 * @return {number}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationProgressResponse} returns this
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 total_number = 3;
 * @return {number}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.getTotalNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationProgressResponse} returns this
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.setTotalNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 verified_number = 4;
 * @return {number}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.getVerifiedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationProgressResponse} returns this
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.setVerifiedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 flagged_number = 5;
 * @return {number}
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.getFlaggedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignValidationProgressResponse} returns this
 */
proto.campaigns.CampaignValidationProgressResponse.prototype.setFlaggedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignTemplatePreviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignTemplatePreviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplatePreviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    level: jspb.Message.getFieldWithDefault(msg, 5, 0),
    favorite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    icon: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse}
 */
proto.campaigns.CampaignTemplatePreviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignTemplatePreviewResponse;
  return proto.campaigns.CampaignTemplatePreviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignTemplatePreviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse}
 */
proto.campaigns.CampaignTemplatePreviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.campaigns.CampaignTemplateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.campaigns.CampaignTemplateLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavorite(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignTemplatePreviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignTemplatePreviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplatePreviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFavorite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CampaignTemplateType type = 4;
 * @return {!proto.campaigns.CampaignTemplateType}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignTemplateType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.campaigns.CampaignTemplateType} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CampaignTemplateLevel level = 5;
 * @return {!proto.campaigns.CampaignTemplateLevel}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getLevel = function() {
  return /** @type {!proto.campaigns.CampaignTemplateLevel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.campaigns.CampaignTemplateLevel} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool favorite = 6;
 * @return {boolean}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string icon = 7;
 * @return {string}
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplatePreviewResponse} returns this
 */
proto.campaigns.CampaignTemplatePreviewResponse.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignTemplateDetailsResponse.repeatedFields_ = [7,8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignTemplateDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignTemplateDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    level: jspb.Message.getFieldWithDefault(msg, 5, 0),
    videoLink: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sequenceStepsList: jspb.Message.toObjectList(msg.getSequenceStepsList(),
    mailbox$template_pb.TemplateStep.toObject, includeInstance),
    inputsList: jspb.Message.toObjectList(msg.getInputsList(),
    proto.campaigns.CampaignTemplateInputResponse.toObject, includeInstance),
    howToUseLink: jspb.Message.getFieldWithDefault(msg, 9, ""),
    aiSuggestionPromptPresetsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse}
 */
proto.campaigns.CampaignTemplateDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignTemplateDetailsResponse;
  return proto.campaigns.CampaignTemplateDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignTemplateDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse}
 */
proto.campaigns.CampaignTemplateDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.campaigns.CampaignTemplateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.campaigns.CampaignTemplateLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideoLink(value);
      break;
    case 7:
      var value = new mailbox$template_pb.TemplateStep;
      reader.readMessage(value,mailbox$template_pb.TemplateStep.deserializeBinaryFromReader);
      msg.addSequenceSteps(value);
      break;
    case 8:
      var value = new proto.campaigns.CampaignTemplateInputResponse;
      reader.readMessage(value,proto.campaigns.CampaignTemplateInputResponse.deserializeBinaryFromReader);
      msg.addInputs(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHowToUseLink(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addAiSuggestionPromptPresets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignTemplateDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignTemplateDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVideoLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSequenceStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      mailbox$template_pb.TemplateStep.serializeBinaryToWriter
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.campaigns.CampaignTemplateInputResponse.serializeBinaryToWriter
    );
  }
  f = message.getHowToUseLink();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAiSuggestionPromptPresetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CampaignTemplateType type = 4;
 * @return {!proto.campaigns.CampaignTemplateType}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignTemplateType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.campaigns.CampaignTemplateType} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CampaignTemplateLevel level = 5;
 * @return {!proto.campaigns.CampaignTemplateLevel}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getLevel = function() {
  return /** @type {!proto.campaigns.CampaignTemplateLevel} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.campaigns.CampaignTemplateLevel} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string video_link = 6;
 * @return {string}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getVideoLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setVideoLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated templates.TemplateStep sequence_steps = 7;
 * @return {!Array<!proto.templates.TemplateStep>}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getSequenceStepsList = function() {
  return /** @type{!Array<!proto.templates.TemplateStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, mailbox$template_pb.TemplateStep, 7));
};


/**
 * @param {!Array<!proto.templates.TemplateStep>} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
*/
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setSequenceStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.templates.TemplateStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templates.TemplateStep}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.addSequenceSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.templates.TemplateStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.clearSequenceStepsList = function() {
  return this.setSequenceStepsList([]);
};


/**
 * repeated CampaignTemplateInputResponse inputs = 8;
 * @return {!Array<!proto.campaigns.CampaignTemplateInputResponse>}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getInputsList = function() {
  return /** @type{!Array<!proto.campaigns.CampaignTemplateInputResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.CampaignTemplateInputResponse, 8));
};


/**
 * @param {!Array<!proto.campaigns.CampaignTemplateInputResponse>} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
*/
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.campaigns.CampaignTemplateInputResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignTemplateInputResponse}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.addInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.campaigns.CampaignTemplateInputResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.clearInputsList = function() {
  return this.setInputsList([]);
};


/**
 * optional string how_to_use_link = 9;
 * @return {string}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getHowToUseLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setHowToUseLink = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string ai_suggestion_prompt_presets = 10;
 * @return {!Array<string>}
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.getAiSuggestionPromptPresetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.setAiSuggestionPromptPresetsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.addAiSuggestionPromptPresets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignTemplateDetailsResponse} returns this
 */
proto.campaigns.CampaignTemplateDetailsResponse.prototype.clearAiSuggestionPromptPresetsList = function() {
  return this.setAiSuggestionPromptPresetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignTemplateInputResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignTemplateInputResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateInputResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    example: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    variable: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    optional: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    linesLimit: jspb.Message.getFieldWithDefault(msg, 8, 0),
    linesCharLimit: jspb.Message.getFieldWithDefault(msg, 9, 0),
    autoPopulateButtonEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    autoPopulateSource: jspb.Message.getFieldWithDefault(msg, 11, ""),
    preset: jspb.Message.getFieldWithDefault(msg, 12, ""),
    aiSuggestionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    aiSuggestionPrompt: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignTemplateInputResponse}
 */
proto.campaigns.CampaignTemplateInputResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignTemplateInputResponse;
  return proto.campaigns.CampaignTemplateInputResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignTemplateInputResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignTemplateInputResponse}
 */
proto.campaigns.CampaignTemplateInputResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExample(value);
      break;
    case 4:
      var value = /** @type {!proto.campaigns.CampaignTemplateInputType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariable(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptional(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinesLimit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinesCharLimit(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoPopulateButtonEnabled(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutoPopulateSource(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreset(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAiSuggestionEnabled(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAiSuggestionPrompt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignTemplateInputResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignTemplateInputResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateInputResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExample();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVariable();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOptional();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLinesLimit();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLinesCharLimit();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAutoPopulateButtonEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAutoPopulateSource();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPreset();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAiSuggestionEnabled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getAiSuggestionPrompt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string example = 3;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getExample = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setExample = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CampaignTemplateInputType type = 4;
 * @return {!proto.campaigns.CampaignTemplateInputType}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignTemplateInputType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.campaigns.CampaignTemplateInputType} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string variable = 5;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getVariable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setVariable = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool optional = 7;
 * @return {boolean}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getOptional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setOptional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 lines_limit = 8;
 * @return {number}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getLinesLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setLinesLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 lines_char_limit = 9;
 * @return {number}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getLinesCharLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setLinesCharLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool auto_populate_button_enabled = 10;
 * @return {boolean}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getAutoPopulateButtonEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setAutoPopulateButtonEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string auto_populate_source = 11;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getAutoPopulateSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setAutoPopulateSource = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string preset = 12;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getPreset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setPreset = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool ai_suggestion_enabled = 13;
 * @return {boolean}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getAiSuggestionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setAiSuggestionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string ai_suggestion_prompt = 14;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.getAiSuggestionPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputResponse} returns this
 */
proto.campaigns.CampaignTemplateInputResponse.prototype.setAiSuggestionPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignTemplateInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignTemplateInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    variable: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optional: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignTemplateInputRequest}
 */
proto.campaigns.CampaignTemplateInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignTemplateInputRequest;
  return proto.campaigns.CampaignTemplateInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignTemplateInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignTemplateInputRequest}
 */
proto.campaigns.CampaignTemplateInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariable(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.campaigns.CampaignTemplateInputType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignTemplateInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignTemplateInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariable();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOptional();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string variable = 1;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.getVariable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputRequest} returns this
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.setVariable = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignTemplateInputRequest} returns this
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CampaignTemplateInputType type = 3;
 * @return {!proto.campaigns.CampaignTemplateInputType}
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignTemplateInputType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.campaigns.CampaignTemplateInputType} value
 * @return {!proto.campaigns.CampaignTemplateInputRequest} returns this
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool optional = 4;
 * @return {boolean}
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.getOptional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignTemplateInputRequest} returns this
 */
proto.campaigns.CampaignTemplateInputRequest.prototype.setOptional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignTemplateCreateRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignTemplateCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignTemplateCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sequenceStepsList: jspb.Message.toObjectList(msg.getSequenceStepsList(),
    mailbox$template_pb.TemplateStep.toObject, includeInstance),
    inputsList: jspb.Message.toObjectList(msg.getInputsList(),
    proto.campaigns.CampaignTemplateInputRequest.toObject, includeInstance),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignTemplateCreateRequest}
 */
proto.campaigns.CampaignTemplateCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignTemplateCreateRequest;
  return proto.campaigns.CampaignTemplateCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignTemplateCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignTemplateCreateRequest}
 */
proto.campaigns.CampaignTemplateCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = new mailbox$template_pb.TemplateStep;
      reader.readMessage(value,mailbox$template_pb.TemplateStep.deserializeBinaryFromReader);
      msg.addSequenceSteps(value);
      break;
    case 4:
      var value = new proto.campaigns.CampaignTemplateInputRequest;
      reader.readMessage(value,proto.campaigns.CampaignTemplateInputRequest.deserializeBinaryFromReader);
      msg.addInputs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignTemplateCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignTemplateCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignTemplateCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSequenceStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      mailbox$template_pb.TemplateStep.serializeBinaryToWriter
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.campaigns.CampaignTemplateInputRequest.serializeBinaryToWriter
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated templates.TemplateStep sequence_steps = 3;
 * @return {!Array<!proto.templates.TemplateStep>}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.getSequenceStepsList = function() {
  return /** @type{!Array<!proto.templates.TemplateStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, mailbox$template_pb.TemplateStep, 3));
};


/**
 * @param {!Array<!proto.templates.TemplateStep>} value
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
*/
proto.campaigns.CampaignTemplateCreateRequest.prototype.setSequenceStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.templates.TemplateStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.templates.TemplateStep}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.addSequenceSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.templates.TemplateStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.clearSequenceStepsList = function() {
  return this.setSequenceStepsList([]);
};


/**
 * repeated CampaignTemplateInputRequest inputs = 4;
 * @return {!Array<!proto.campaigns.CampaignTemplateInputRequest>}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.getInputsList = function() {
  return /** @type{!Array<!proto.campaigns.CampaignTemplateInputRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.campaigns.CampaignTemplateInputRequest, 4));
};


/**
 * @param {!Array<!proto.campaigns.CampaignTemplateInputRequest>} value
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
*/
proto.campaigns.CampaignTemplateCreateRequest.prototype.setInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.campaigns.CampaignTemplateInputRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignTemplateInputRequest}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.addInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.campaigns.CampaignTemplateInputRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.clearInputsList = function() {
  return this.setInputsList([]);
};


/**
 * optional int64 optional_folder_id = 5;
 * @return {number}
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignTemplateCreateRequest} returns this
 */
proto.campaigns.CampaignTemplateCreateRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.campaigns.FieldType = {
  EMAIL: 0,
  NAME: 1,
  JOB_POSITION: 2,
  LOCATION: 3,
  WEBSITE_NAME: 4,
  WEBSITE_URL: 5
};

/**
 * @enum {number}
 */
proto.campaigns.OpportunityFilterField = {
  OPPORTUNITY_URL: 0,
  OPPORTUNITY_TITLE: 1,
  OPPORTUNITY_TYPE: 2,
  OPPORTUNITY_VERIFICATION_STATUS: 3,
  OPPORTUNITY_CONTACT_EMAIL: 4,
  OPPORTUNITY_ADDED_DATE: 5,
  OPPORTUNITY_OWNER_ID: 6,
  OPPORTUNITY_RELATIONSHIP_STATUS: 7,
  OPPORTUNITY_REVIEWED_STATE: 8,
  OPPORTUNITY_CONTACTS_EXISTS: 9
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignFilterField = {
  STRING_CAMPAIGN_TITLE: 0,
  DROPDOWN_CAMPAIGN_OWNER_ID: 1,
  DROPDOWN_CAMPAIGN_FOLDER_ID: 2,
  DROPDOWN_CAMPAIGN_STATUS: 3,
  NUMBER_CAMPAIGN_LAUNCHED: 4,
  NUMBER_CAMPAIGN_SCHEDULED: 5,
  NUMBER_CAMPAIGN_CONTACTED: 6,
  NUMBER_CAMPAIGN_OPENED: 7,
  NUMBER_CAMPAIGN_REPLIED: 8,
  NUMBER_CAMPAIGN_TOTAL_OPPORTUNITIES: 9,
  DATE_CAMPAIGN_CREATED_AT: 10
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignFilterStatus = {
  ALL: 0,
  ONLY_DRAFT: 1,
  ONLY_LAUNCHED: 2,
  ONLY_PAUSED: 3,
  ONLY_FINISHED: 4
};

/**
 * @enum {number}
 */
proto.campaigns.PersonalizationThreadType = {
  THREAD_A: 0,
  THREAD_B: 1
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignStatus = {
  PREPARED: 0,
  LAUNCHED: 1,
  PAUSED: 2,
  REMOVED: 3,
  FINISHED: 4,
  STOPPED: 5,
  INFO: 100,
  ERROR: 101
};

/**
 * @enum {number}
 */
proto.campaigns.SendingStrategy = {
  BOUNCE_STRATEGY: 0,
  SEND_ALL_STRATEGY: 1,
  CC_STRATEGY: 2,
  NOT_REPLIED_STRATEGY: 3
};

/**
 * @enum {number}
 */
proto.campaigns.SendingPriority = {
  HIGH: 0,
  MEDIUM: 1,
  LOW: 2
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignVerificationStatus = {
  NOT_VERIFIED: 0,
  IN_PROGRESS: 1,
  VERIFIED: 2
};

/**
 * @enum {number}
 */
proto.campaigns.OpportunityVerificationStatus = {
  OPPORTUNITY_VERIFIED: 0,
  OPPORTUNITY_NOT_VERIFIED: 1,
  OPPORTUNITY_FLAGGED: 2
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignTemplateType = {
  CONTENT_PROMOTION: 0,
  PARTNER_RECRUITMENT: 1,
  DIGITAL_PR: 2,
  LINK_BUILDING: 3
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignTemplateLevel = {
  EASY: 0,
  INTERMEDIATE: 1,
  ADVANCED: 2
};

/**
 * @enum {number}
 */
proto.campaigns.CampaignTemplateInputType = {
  URL: 0,
  URL_MULTIPLY: 1,
  TEXT: 2,
  TEXT_MULTIPLY: 3,
  TEXT_AREA: 4,
  DATE_RANGE_DROPDOWN: 5,
  LOCATION_DROPDOWN: 6,
  LANGUAGE_DROPDOWN: 7
};

goog.object.extend(exports, proto.campaigns);
