import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import './AiAgentsChat.css';
import AiChatIllustration from '@uikit/Icon/illustrations/AiChatIllustration';
import InfoCard from '@uikit/InfoCard/InfoCard';
import Input from '@uikit/Input/Input';
import { Button } from '@uikit/Button/Button';
import { executePromptApi, getAllConversationMessagesApi } from '@api/aiPrompts.api';
import {
  AiConversationMessageRole,
  AiConversationRelatedType,
  AiPromptName,
} from 'respona_api/generated/common_pb';
import { updateExecutedCampaignAutomationApi } from '@api/campaignAutomation.api';
import { useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import transformObjectToJsonString from '@utils/transformObjectToJsonString';
import { SVGIcon } from '@uikit/Icon/Icon';
import AiAgentsSidebar from '@components/AiAgents/AiAgentsSidebar/AiAgentsSidebar';
import qs from 'query-string';
import { useQuery, useQueryClient } from 'react-query';
import { CampaignAutomationDetailsType } from '@ts/automationCampaigns.types';
import { AiConversationType } from '@ts/aiPrompts.types';
import Loader from '@uikit/Loader/Loader';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';

function AiAgentsChat({
  agent,
  queryAgentKey,
  match: {
    params: { paramsPage },
  },
  location: { search },
}: {
  agent?: CampaignAutomationDetailsType;
  queryAgentKey?: any[];
  match: { params: { paramsPage: string } };
  location: { search: string };
}): JSX.Element {
  const containerRef = useRef(null);
  const queryClient = useQueryClient();
  const searchParams: { folderId?: string | undefined; id?: string } = qs.parse(search);

  const workspaceId = useSelector(getCurrentWorkspaceId);
  const { info: userProfile } = useSelector(userProfileSelector);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const [isGreeting, setIsGreeting] = useState<boolean>(true);
  const [value, setValue] = useState('');
  const [instructions, setInstructions] = useState<string>(null);
  const [settings, setSettings] = useState<string>(null);
  const cardList = [
    {
      title: 'Getting Started With Setup',
      message: 'Hi there! Please, help me create my new link-building strategy!',
    },
  ];

  const isEnum = (key: string) => {
    return key === 'autonomyLevel';
  };

  const isArrayOfObjects = (key: string) => {
    return key === 'targetPageToPreferredAnchorList';
  };

  const transformEnum = (key: string, value: string) => {
    switch (key) {
      case 'autonomyLevel':
        return value === 'FULLY_AUTONOMOUS' ? 0 : 1;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (!!agent?.linkBuilding && instructions === null) {
      setInstructions(transformObjectToJsonString(agent.linkBuilding));
    }
    if (!!agent?.linkBuilding && settings === null) {
      setSettings(transformObjectToJsonString(agent.settings));
    }
  }, [agent, instructions, settings]);

  const queryConversationKey = ['ai-agent-conversation', workspaceId, agent?.id];

  const { data: conversation = [], isLoading: isConversationLoading } = useQuery(
    queryConversationKey,
    () =>
      getAllConversationMessagesApi(
        workspaceId,
        agent?.id,
        AiConversationRelatedType.CAMPAIGN_AGENT
      ),
    {
      enabled: agent?.id > 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: 'smooth', // Smooth scrolling effect
    });
  }, [conversation]);

  useEffect(() => {
    if (conversation.length === 0 && cardList.length > 0) {
      const timer = setTimeout(() => {
        onHandleUserMessage(cardList[0].message); // Call the first card's message
      }, 3000);

      // Clear the timer if the component is dismantled
      return () => clearTimeout(timer);
    }
  }, [conversation, cardList]);

  const addConversation = (newItem: AiConversationType) => {
    queryClient.setQueryData(queryConversationKey, (cache: AiConversationType[]) => [
      ...cache,
      newItem,
    ]);
  };

  const handleChangeInput = (event) => {
    if (event) {
      setValue(event.target.value);

      if (event.target.value.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  };

  const onSendClick = () => {
    if (value.trim() === '') {
      return;
    }
    return onHandleUserMessage(value.trim());
  };

  const onHandleUserMessage = (userMessage: string) => {
    addConversation({
      role: AiConversationMessageRole.USER,
      message: userMessage,
      sentAt: Date.now(),
    });
    const instructionsJson = JSON.stringify(instructions);
    const settingsJson = JSON.stringify(settings);

    const prompt = AiPromptName.LINK_BUILDING_ASSISTANT_PROMPT; /** isGreeting
      ? AiPromptName.GREETING_AGENT_PROMPT
      : AiPromptName.LINK_BUILDING_AGENT_PROMPT; */

    setIsExecuting(true);
    executePromptApi(
      prompt,
      userMessage,
      instructionsJson,
      settingsJson,
      workspaceId,
      agent?.id,
      agent?.threadId,
      AiConversationRelatedType.CAMPAIGN_AGENT
    )
      .then((res) => {
        const responseInstruction = JSON.parse(res.instructionsJson);
        const responseSettings = JSON.parse(res.settingsJson);
        const updatedInstructions = JSON.parse(instructions);
        const updatedSettings = JSON.parse(settings);
        if (isGreeting) setIsGreeting(false);
        let instructionsUpdated = false;
        let settingsUpdated = false;
        Object.keys(responseInstruction).forEach((key) => {
          if (key in updatedInstructions) {
            const originalValue = updatedInstructions[key];
            const responseValue = responseInstruction[key];
            if (originalValue != responseValue) {
              instructionsUpdated = true;
              updatedInstructions[key] = responseValue;
            }
          }
        });
        Object.keys(responseSettings).forEach((key) => {
          if (key in updatedSettings) {
            const originalValue = updatedSettings[key];
            const responseValue = isEnum(key)
              ? transformEnum(key, responseSettings[key])
              : responseSettings[key];
            if (originalValue != responseValue) {
              settingsUpdated = true;
              updatedSettings[key] = responseValue;
            }
          }
        });

        const mergedInstructions = transformObjectToJsonString(updatedInstructions);
        const mergedSettings = transformObjectToJsonString(updatedSettings);
        addConversation({
          role: AiConversationMessageRole.ASSISTANT,
          message: res.aiReplyMessage,
          sentAt: Date.now(),
        });
        setInstructions(mergedInstructions);
        setSettings(mergedSettings);

        if (instructionsUpdated || settingsUpdated) {
          updateExecutedCampaignAutomationApi(
            { ...agent, threadId: res.threadId },
            workspaceId,
            updatedInstructions,
            updatedSettings
          ).then((response) => {
            queryClient.setQueryData(queryAgentKey, (cache: CampaignAutomationDetailsType) => {
              return {
                ...cache,
                ...response,
              };
            });
          });
        }
      })
      .finally(() => setIsExecuting(false));

    setValue('');
    setIsDisabled(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSendClick();
    }
  };

  return (
    <div className="ai-agents-page__content-chat">
      <AiAgentsSidebar searchParams={searchParams} activePage={paramsPage} campaignId={agent?.id} />
      <Loader isLoading={isConversationLoading || agent == null}>
        <div className="ai-agents-page__content-body">
          {conversation.length === 0 ? (
            <>
              <AiChatIllustration />
              <h2 className="ai-agents-page__content-body-title">
                Hey <span>{userProfile?.firstName || '%username%'}</span>! <br /> What shall we
                start with?
              </h2>

              <div className="ai-agents-page__content-body-list">
                {cardList.map((card, index) => (
                  <InfoCard
                    key={index}
                    onClick={() => onHandleUserMessage(card.message)}
                    text={card.title}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="chat-container" ref={containerRef}>
              {conversation.map((item, index) => (
                <div key={item.sentAt} className="chat-bubble">
                  <div className="chat-bubble-system-icon">
                    {' '}
                    {item.role !== AiConversationMessageRole.USER && (
                      <SVGIcon icon="aiIcon" size={41} />
                    )}
                  </div>
                  <div
                    key={index}
                    className={`chat-bubble-message ${item.role === AiConversationMessageRole.USER ? 'chat-bubble-user' : 'chat-bubble-system'}`}
                  >
                    {item.message}
                  </div>
                </div>
              ))}
              {isExecuting && (
                <div className="chat-bubble">
                  <div className="chat-bubble-system-icon">
                    {' '}
                    <SVGIcon icon="aiIcon" size={41} />
                  </div>
                  <div className="chat-bubble-message chat-bubble-system">Processing...</div>
                </div>
              )}
            </div>
          )}
          <div className="ai-agents-page__content-body-input">
            <Input
              type="text"
              placeholder="Ask the agent questions"
              value={value}
              onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
            />
            <Button
              type="primary"
              disabled={isDisabled}
              leftIcon="sendArrow"
              colorIcon={isDisabled ? '#777D8A' : '#ffffff'}
              onClick={onSendClick}
            />
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default AiAgentsChat;
