import AutomationInspectionService from 'respona_api/generated/automation-inspection_pb_service';

declare const API_HOST: string;

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AutomationInspectionService.OpportunityInspectionAutomationServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AutomationInspectionService.OpportunityInspectionAutomationServiceClient(
          API_HOST
        );
      }

      return instance;
    },
  };
})();

export default service;
