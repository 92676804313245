/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import createEmptyArray from 'src/utils/createEmptyArray';

import './Steps.scss';

type StepsPropsType = {
  curStep: number;
  stepsCount: number;
};

export default ({ curStep, stepsCount }: StepsPropsType): JSX.Element => {
  const createStepCn = (stepIndex) =>
    cn('steps__step-item', {
      'steps__step-item--active': stepIndex <= curStep,
    });

  return (
    <div className="steps">
      <div className="steps__title">Step {curStep}</div>
      <div className="steps__items">
        {createEmptyArray(stepsCount).map((val) => (
          <div key={val} className={createStepCn(val + 1)} />
        ))}
      </div>
    </div>
  );
};
