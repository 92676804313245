/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './BooleanToggler.scss';

type BooleanTogglerPropsType = {
  truthlyButtonText?: string;
  falsyButtonText?: string;
  value: boolean;
  isReversed?: boolean;
  onChange: (boolean) => void;
  renderTruthly?: (isActive: boolean) => JSX.Element;
  renderFalsy?: (isActive: boolean) => JSX.Element;
  isDisabled?: boolean;
  onDisabledModeClick?: () => void;
};

const BooleanToggler = ({
  truthlyButtonText,
  falsyButtonText,
  value,
  onChange,
  isReversed = false,
  renderTruthly,
  renderFalsy,
  isDisabled = false,
  onDisabledModeClick,
}: BooleanTogglerPropsType): JSX.Element => {
  const cnButtonToggler = cn('boolean-toggler', {
    'boolean-toggler--reversed': isReversed,
  });

  const cnTruthlyButton = cn('boolean-toggler__button', {
    'boolean-toggler__button--active': value,
  });

  const cnFalsyButton = cn('boolean-toggler__button', {
    'boolean-toggler__button--active': !value,
  });

  const isDisabledMode = () => {
    if (isDisabled) {
      if (onDisabledModeClick) {
        onDisabledModeClick();
      }
      return true;
    }

    return false;
  };

  const handleTruthlyClick = (e) => {
    e.stopPropagation();
    if (isDisabledMode()) return;

    if (!value) {
      onChange(true);
    }
  };

  const handleFalsyClick = (e) => {
    e.stopPropagation();
    if (isDisabledMode()) return;

    if (value) {
      onChange(false);
    }
  };

  return (
    <div className={cnButtonToggler}>
      <div className={cnTruthlyButton} onClick={handleTruthlyClick} tabIndex={0} role="button">
        {renderTruthly ? renderTruthly(value) : truthlyButtonText}
      </div>
      <div className={cnFalsyButton} onClick={handleFalsyClick} tabIndex={0} role="button">
        {renderFalsy ? renderFalsy(!value) : falsyButtonText}
      </div>
    </div>
  );
};

export default BooleanToggler;
