import request from '@api/base/request';
import processStreamRequest from '@api/base/processStreamRequest';

import automationCampaignService from '@api/services/automationCampaignService';

import streamRequest from '@api/base/streamRequest';
import CommonModel, {
  CampaignAutomationAgentInstructionType,
  CampaignAutomationStrategyMap,
} from 'respona_api/generated/common_pb';
import {
  CampaignAutomationActivityType,
  CampaignAutomationDetailsType,
  CampaignAutomationSettingsType,
  CampaignAutomationType,
  GeneratedInstructionsType,
  LinkBuildingStrategyInstructionsType,
} from '@ts/automationCampaigns.types';
import {
  CampaignAutomationChangeStatusRequest,
  CampaignAutomationDetailsRequest,
  CampaignAutomationLaunchRequest,
  CampaignAutomationRequest,
  CampaignAutomationSettings,
  GenerateInstructionsRequest,
  LinkBuildingStrategyInstructions,
} from 'respona_api/generated/automation-campaign_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import { StageOpportunityFilter } from 'respona_api/generated/relationship-pipeline_pb';
import { ChangeCampaignStatusType } from '@ts/apiModels.types';
import TargetPageToPreferredAnchor = LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor;
import { TestConnectionResponseStreamContainer } from 'respona_api/generated/mailbox-account_pb';

export const getAutomationCampaignsApi = (
  workspaceId: number,
  page: number,
  limit: number,
  filters
): Promise<CampaignAutomationType[]> => {
  const service = automationCampaignService.getInstance();
  const pageReqeust = new CampaignAutomationRequest();

  pageReqeust.setWorkspaceId(workspaceId);
  pageReqeust.setPage(page);
  pageReqeust.setLimit(limit);

  if (filters.length > 0) {
    pageReqeust.setOptionalFiltersList(
      filters.map((it) => {
        const filterRequest = new StageOpportunityFilter();

        filterRequest.setJoin(it.join);
        filterRequest.setOperation(it.operation);
        filterRequest.setOrder(it.order);
        filterRequest.setField(it.field);

        if (it.value.value) {
          filterRequest.setValue(it.value.value.toString());
        } else {
          filterRequest.setValue(it.value.toString());
        }
        return filterRequest;
      })
    );
  }

  return streamRequest<CampaignAutomationType[]>(
    service,
    service.getCampaignAutomations,
    pageReqeust
  );
};

export const getCampaignAutomationActivitiesApi = (
  automationId: number,
  workspaceId: number,
  page: number,
  limit: number
): Promise<CampaignAutomationActivityType[]> => {
  const service = automationCampaignService.getInstance();
  const pageReqeust = new CommonModel.PageRequest();

  pageReqeust.setOptionalEntityId(automationId);
  pageReqeust.setOptionalWorkspaceId(workspaceId);
  pageReqeust.setPage(page);
  pageReqeust.setLimit(limit);

  return streamRequest<CampaignAutomationActivityType[]>(
    service,
    service.getCampaignAutomationActivities,
    pageReqeust
  );
};

export const generateCampaignAutomationInstructionsApi = (
  agentId: number,
  workspaceId: number,
  callback: (data: [GeneratedInstructionsType, any]) => void,
  type: common_pb.CampaignAutomationAgentInstructionTypeMap[keyof common_pb.CampaignAutomationAgentInstructionTypeMap] = CampaignAutomationAgentInstructionType.LINK_BUILDING_GENERAL,
  domain: string = null
): Promise<GeneratedInstructionsType> => {
  const service = automationCampaignService.getInstance();
  const dataReqeust = new GenerateInstructionsRequest();

  dataReqeust.setId(agentId);
  dataReqeust.setWorkspaceId(workspaceId);
  dataReqeust.setType(type);
  if (domain?.length > 0) {
    dataReqeust.setOptDomain(domain);
  }

  return processStreamRequest(
    service,
    service.generateCampaignAutomationInstructions,
    dataReqeust,
    callback
  );
};

export const createCampaignAutomationApi = (
  workspaceId: number
): Promise<CampaignAutomationDetailsType> => {
  const service = automationCampaignService.getInstance();
  const requestModel = new CommonModel.IdRequest();

  requestModel.setOptionalWorkspaceId(workspaceId);

  return request<CampaignAutomationDetailsType>(
    service,
    service.createCampaignAutomation,
    requestModel
  );
};

export const openCampaignAutomationApi = (
  automationId: number,
  workspaceId: number
): Promise<CampaignAutomationDetailsType> => {
  const service = automationCampaignService.getInstance();
  const requestModel = new CommonModel.IdRequest();

  requestModel.setId(automationId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return request<CampaignAutomationDetailsType>(
    service,
    service.openCampaignAutomation,
    requestModel
  );
};

export const deleteCampaignAutomationApi = (
  automationId: number,
  workspaceId: number
): Promise<void> => {
  const service = automationCampaignService.getInstance();
  const requestModel = new CommonModel.IdRequest();

  requestModel.setId(automationId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.deleteCampaignAutomation, requestModel);
};

export const launchCampaignAutomationApi = (
  automationId: number,
  workspaceId: number
): Promise<void> => {
  const service = automationCampaignService.getInstance();
  const requestModel = new CampaignAutomationLaunchRequest();

  requestModel.setId(automationId);
  requestModel.setWorkspaceId(workspaceId);

  return request<void>(service, service.launchCampaignAutomation, requestModel);
};

export const changeCampaignAutomationStatusApi = (
  campaignId: number,
  workspaceId: number,
  status: common_pb.CampaignAgentStatusMap[keyof common_pb.CampaignAgentStatusMap]
): Promise<ChangeCampaignStatusType> => {
  const service = automationCampaignService.getInstance();
  const changeStatusRequest = new CampaignAutomationChangeStatusRequest();

  changeStatusRequest.setId(campaignId);
  changeStatusRequest.setWorkspaceId(workspaceId);
  changeStatusRequest.setStatus(status);

  return request<ChangeCampaignStatusType>(
    service,
    service.changeCampaignAutomationStatus,
    changeStatusRequest
  );
};

export const renameCampaignAutomationApi = (
  currentAgent: CampaignAutomationDetailsType,
  currentWorkspaceId: number,
  newTitle: string
): Promise<CampaignAutomationDetailsType> => {
  return updateCampaignAutomationApi(
    currentAgent.id,
    currentWorkspaceId,
    newTitle,
    currentAgent.threadId,
    currentAgent.status,
    currentAgent.settings,
    currentAgent.strategy,
    currentAgent.linkBuilding
  );
};

export const updateExecutedCampaignAutomationApi = (
  currentAgent: CampaignAutomationDetailsType,
  currentWorkspaceId: number,
  jsonInstructions: any,
  settings: CampaignAutomationSettingsType
): Promise<CampaignAutomationDetailsType> => {
  const instructions = {
    targetPageToPreferredAnchorList: jsonInstructions.targetPageToPreferredAnchorList,
    incentive: jsonInstructions.incentive,
    competingWebsitesList: jsonInstructions.competingWebsitesList,
    websiteName: jsonInstructions.websiteName,
    websiteDescription: jsonInstructions.websiteDescription,
    websiteDomain: jsonInstructions.websiteDomain,
    parentKeywordsList: jsonInstructions.parentKeywordsList,
    additionalNotes: jsonInstructions.additionalNotes,
    linksNumber: jsonInstructions.linksNumber,
  } as LinkBuildingStrategyInstructionsType;

  return updateCampaignAutomationApi(
    currentAgent.id,
    currentWorkspaceId,
    currentAgent.title,
    currentAgent.threadId,
    currentAgent.status,
    settings,
    currentAgent.strategy,
    instructions
  );
};

export const updateInstructionsCampaignAutomationApi = (
  currentAgent: CampaignAutomationDetailsType,
  currentWorkspaceId: number,
  jsonInstructions: any
): Promise<CampaignAutomationDetailsType> => {
  const instructions = {
    targetPageToPreferredAnchorList: jsonInstructions.targetPageToPreferredAnchorList,
    incentive: jsonInstructions.incentive,
    competingWebsitesList: jsonInstructions.competingWebsitesList,
    websiteName: jsonInstructions.websiteName,
    websiteDescription: jsonInstructions.websiteDescription,
    websiteDomain: jsonInstructions.websiteDomain,
    parentKeywordsList: jsonInstructions.parentKeywordsList,
    additionalNotes: jsonInstructions.additionalNotes,
    linksNumber: jsonInstructions.linksNumber,
  } as LinkBuildingStrategyInstructionsType;

  console.log('== instructions ==', instructions);

  return updateCampaignAutomationApi(
    currentAgent.id,
    currentWorkspaceId,
    currentAgent.title,
    currentAgent.threadId,
    currentAgent.status,
    currentAgent.settings,
    currentAgent.strategy,
    instructions
  );
};

export const updateSettingsCampaignAutomationApi = (
  currentAgent: CampaignAutomationDetailsType,
  currentWorkspaceId: number,
  settings: CampaignAutomationSettingsType
): Promise<CampaignAutomationDetailsType> => {
  return updateCampaignAutomationApi(
    currentAgent.id,
    currentWorkspaceId,
    currentAgent.title,
    currentAgent.threadId,
    currentAgent.status,
    settings,
    currentAgent.strategy,
    currentAgent.linkBuilding
  );
};

const updateCampaignAutomationApi = (
  automationId: number,
  workspaceId: number,
  title: string,
  threadId: string,
  status: common_pb.CampaignAgentStatusMap[keyof common_pb.CampaignAgentStatusMap],
  settings: CampaignAutomationSettingsType,
  strategy: CampaignAutomationStrategyMap[keyof CampaignAutomationStrategyMap],
  linkBuilding: LinkBuildingStrategyInstructionsType
): Promise<CampaignAutomationDetailsType> => {
  const service = automationCampaignService.getInstance();
  const requestModel = new CampaignAutomationDetailsRequest();

  const instructions = new LinkBuildingStrategyInstructions();
  instructions.setIncentive(linkBuilding.incentive);
  instructions.setAdditionalNotes(linkBuilding.additionalNotes);
  instructions.setCompetingWebsitesList(linkBuilding.competingWebsitesList);
  instructions.setLinksNumber(linkBuilding.linksNumber);
  instructions.setParentKeywordsList(linkBuilding.parentKeywordsList);

  const targetPages = linkBuilding.targetPageToPreferredAnchorList
    .filter((it) => it.targetPage?.length > 0 && it.preferredAnchor?.length > 0)
    .map((it) => {
      const pair = new TargetPageToPreferredAnchor();
      pair.setTargetPage(it.targetPage);
      pair.setPreferredAnchor(it.preferredAnchor);
      return pair;
    });

  const settingsRequest = new CampaignAutomationSettings();
  settingsRequest.setScheduleId(settings.scheduleId);
  settingsRequest.setMailboxAccountIdsList(settings.mailboxAccountIdsList);
  settingsRequest.setAutonomyLevel(settings.autonomyLevel);
  settingsRequest.setNotificationEmail(settings.notificationEmail);

  instructions.setTargetPageToPreferredAnchorList(targetPages);
  instructions.setWebsiteName(linkBuilding.websiteName);
  instructions.setWebsiteDomain(linkBuilding.websiteDomain);
  instructions.setWebsiteDescription(linkBuilding.websiteDescription);

  requestModel.setId(automationId);
  requestModel.setWorkspaceId(workspaceId);
  requestModel.setTitle(title);
  requestModel.setThreadId(threadId);
  requestModel.setStatus(status);
  requestModel.setSettings(settingsRequest);
  requestModel.setStrategy(strategy);
  requestModel.setLinkBuilding(instructions);

  return request<CampaignAutomationDetailsType>(
    service,
    service.updateCampaignAutomation,
    requestModel
  );
};
