/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import HorizontalScrollWithArrows from '@uikit/HorizontalScrollWithArrows/HorizontalScrollWithArrows';

import './SectionSelect.scss';

type Props = {
  options: { key: any; title: React.ReactNode }[];
  getSelectedOption?: (option: { key: any; title: React.ReactNode }) => boolean;
  selectedOption?: any;
  onChange: (value: any) => void;
  RightComponent?: () => JSX.Element;
  Renderer?: ({
    onClick,
    isActive,
    item,
    onRemoveClick,
    onRenameClick,
  }: {
    onClick: () => void;
    item: { key: any; title: React.ReactNode };
    isActive: boolean;
    onRenameClick: (id: number, title: string) => Promise<void>;
    onRemoveClick: (id: number) => Promise<void>;
  }) => JSX.Element;
  classNames?: {
    container?: string;
  };
  onRenameClick?: (id: number, title: string) => Promise<void>;
  onRemoveClick?: (id: number) => Promise<void>;
};

function SectionSelect({
  options,
  selectedOption,
  getSelectedOption,
  onChange,
  RightComponent,
  classNames,
  Renderer,
  onRenameClick = (id: number, title: string) => Promise.resolve(),
  onRemoveClick = (id: number) => Promise.resolve(),
}: Props) {
  return (
    <div className={cn('section-select', { [classNames?.container]: !!classNames?.container })}>
      <HorizontalScrollWithArrows className="section-select__container">
        {options.map((item) =>
          Renderer ? (
            <Renderer
              key={item.key}
              onClick={() => onChange(item.key)}
              item={item}
              isActive={getSelectedOption ? getSelectedOption(item) : selectedOption === item.key}
              onRemoveClick={onRemoveClick}
              onRenameClick={onRenameClick}
            />
          ) : (
            <div
              key={item.key}
              onClick={() => onChange(item.key)}
              className={cn('section-select__item', {
                'section-select__item--active': getSelectedOption
                  ? getSelectedOption(item)
                  : selectedOption === item.key,
              })}
            >
              {item.title}
            </div>
          )
        )}

        {RightComponent && <RightComponent />}
      </HorizontalScrollWithArrows>
    </div>
  );
}

export default SectionSelect;
