import React, { useState } from 'react';
import qs from 'query-string';

import { campaignPageTypes } from '@constants/campaigns.constants';
import { CampaignType } from '@ts/campaigns.types';

import CampaignsSidebar from '@components/Campaigns/CampaignsSidebar/CampaignsSidebar';
import CampaignsTable from '@components/Campaigns/CampaignsTable/CampaignsTable';

import './CampaignsPage.scss';
import { CampaignBatchOperations } from 'respona_api/generated/campaign_pb';
import { batchCampaignOperationsApi } from '@api/campaign.api';
import { useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import CampaignBatchAction = CampaignBatchOperations.CampaignBatchAction;
import PageHeader from '@components/PageHeader/PageHeader';
import CampaignsHeaderActions from '@components/CampaignsHeaderActions/CampaignsHeaderActions';

function CampaignsPage({
  location: { pathname, search },
  match: {
    params: { campaignsType },
  },
}): JSX.Element {
  const [checkboxes, setCheckboxes] = useState<CampaignType[]>([]);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const [refetchCampaigns, setRefetchCampaigns] = useState<() => void>();
  const { folderId } = qs.parse(search);
  const isDeletedType = campaignsType === campaignPageTypes.DELETED;

  const handleCampaignBatchAction = (
    action: CampaignBatchOperations.CampaignBatchActionMap[keyof CampaignBatchOperations.CampaignBatchActionMap],
    optDestinationId?: number
  ) =>
    batchCampaignOperationsApi(
      workspaceId,
      action,
      checkboxes.map((it) => it.id),
      optDestinationId
    ).then(() => {
      if (action === CampaignBatchAction.DELETE) {
        setCheckboxes([]);
        refetchCampaigns();
      }
    });

  return (
    <div className="campaigns-page" data-testid="campaigns-page">
      <div className="campaigns-page__content">
        <PageHeader
          isFixed
          title={isDeletedType ? 'Deleted Campaigns' : 'Campaigns'}
          howToLink="https://help.respona.com/en/articles/6332415-how-to-use-campaigns"
          renderHeaderActions={() => (
            <CampaignsHeaderActions
              selectedCampaigns={checkboxes}
              handleCampaignBatchAction={handleCampaignBatchAction}
              refetchCampaigns={refetchCampaigns}
            />
          )}
        />

        <div>
          <CampaignsSidebar pathname={pathname} search={search} isDeletedType={isDeletedType} />

          <CampaignsTable
            folderId={folderId ? Number(folderId) : undefined}
            isDeletedType={isDeletedType}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            getRefetchCampaigns={(refetchCampaigns) => setRefetchCampaigns(() => refetchCampaigns)}
          />
        </div>
      </div>
    </div>
  );
}

export default CampaignsPage;
