// package: templates
// file: mailbox-template.proto

var mailbox_template_pb = require("./mailbox-template_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TemplateService = (function () {
  function TemplateService() {}
  TemplateService.serviceName = "templates.TemplateService";
  return TemplateService;
}());

TemplateService.createTemplate = {
  methodName: "createTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.TemplateRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.duplicateTemplate = {
  methodName: "duplicateTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.saveTemplateWithSteps = {
  methodName: "saveTemplateWithSteps",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.TemplateWithStepsRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.saveAsNewTemplate = {
  methodName: "saveAsNewTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.SaveAsNewTemplateRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.updateTemplate = {
  methodName: "updateTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.TemplateRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.deleteTemplate = {
  methodName: "deleteTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

TemplateService.getAllTemplates = {
  methodName: "getAllTemplates",
  service: TemplateService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_template_pb.TemplateFilterRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.getTemplateSteps = {
  methodName: "getTemplateSteps",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_template_pb.TemplateContainerResponse
};

TemplateService.applyTemplate = {
  methodName: "applyTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.ApplyTemplateRequest,
  responseType: mailbox_template_pb.TemplateContainerResponse
};

TemplateService.changeOwnerOfTemplate = {
  methodName: "changeOwnerOfTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

TemplateService.changeReferralContentStateOfTemplate = {
  methodName: "changeReferralContentStateOfTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.ChangeStateRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.changeUnsubscribeContentStateOfTemplate = {
  methodName: "changeUnsubscribeContentStateOfTemplate",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.ChangeStateRequest,
  responseType: mailbox_template_pb.TemplateResponse
};

TemplateService.createTemplateStep = {
  methodName: "createTemplateStep",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.TemplateStep,
  responseType: mailbox_template_pb.TemplateStep
};

TemplateService.updateTemplateStep = {
  methodName: "updateTemplateStep",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.TemplateStep,
  responseType: mailbox_template_pb.TemplateStep
};

TemplateService.updateTemplateStepDelay = {
  methodName: "updateTemplateStepDelay",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.UpdateStepDelayRequest,
  responseType: mailbox_template_pb.TemplateStep
};

TemplateService.updateTemplateStepAndFutureQueues = {
  methodName: "updateTemplateStepAndFutureQueues",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_template_pb.TemplateStep,
  responseType: mailbox_template_pb.TemplateStep
};

TemplateService.deleteTemplateStep = {
  methodName: "deleteTemplateStep",
  service: TemplateService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_template_pb.TemplateContainerResponse
};

exports.TemplateService = TemplateService;

function TemplateServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TemplateServiceClient.prototype.createTemplate = function createTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.createTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.duplicateTemplate = function duplicateTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.duplicateTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.saveTemplateWithSteps = function saveTemplateWithSteps(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.saveTemplateWithSteps, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.saveAsNewTemplate = function saveAsNewTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.saveAsNewTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.updateTemplate = function updateTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.updateTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.deleteTemplate = function deleteTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.deleteTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.getAllTemplates = function getAllTemplates(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(TemplateService.getAllTemplates, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.getTemplateSteps = function getTemplateSteps(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.getTemplateSteps, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.applyTemplate = function applyTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.applyTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.changeOwnerOfTemplate = function changeOwnerOfTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.changeOwnerOfTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.changeReferralContentStateOfTemplate = function changeReferralContentStateOfTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.changeReferralContentStateOfTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.changeUnsubscribeContentStateOfTemplate = function changeUnsubscribeContentStateOfTemplate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.changeUnsubscribeContentStateOfTemplate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.createTemplateStep = function createTemplateStep(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.createTemplateStep, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.updateTemplateStep = function updateTemplateStep(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.updateTemplateStep, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.updateTemplateStepDelay = function updateTemplateStepDelay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.updateTemplateStepDelay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.updateTemplateStepAndFutureQueues = function updateTemplateStepAndFutureQueues(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.updateTemplateStepAndFutureQueues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TemplateServiceClient.prototype.deleteTemplateStep = function deleteTemplateStep(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TemplateService.deleteTemplateStep, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TemplateServiceClient = TemplateServiceClient;

