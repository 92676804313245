import { useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { QueryClient, useQuery } from 'react-query';
import {
  // createTemplateApi,
  // createTemplateStepApi,
  deleteTemplateApi, duplicateTemplateApi,
  getAllTemplatesApi,
  getTemplateStepsApi,
  saveTemplateWithStepsApi,
  // updateTemplateStepDelayApi,
} from '@api/templates.api';
import { UseQueryResult } from 'react-query/types/react/types';
import {
  TemplateContainerType,
  TemplateContainerTypeWithTransformedStepsType,
  TemplateScopeType,
  TemplateStepTransformedType,
  TemplateStepType,
  TemplateType,
} from '@ts/template.types';
import queryCacheKeysConstants from '@constants/queryCacheKeys.constants';
// import { emptyEditorHtml } from '@constants/html.constants';
import { prepareSequenceSteps } from '@helpers/sequenceHelpers';

// import { updateItemInListByIdHelper } from '@helpers/commonHelpers';
// import { updateTemplateTitleApi } from '@api/emailTemplates.api';

export const useQueryAllTemplates = (
  page = 0,
  limit = 100,
  query = '',
  ownersIds?: number[]
): UseQueryResult<TemplateType[]> => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  return useQuery(
    [queryCacheKeysConstants.templates, query, ownersIds],
    () => getAllTemplatesApi(page, limit, workspaceId, query, ownersIds),
    {
      enabled: !!workspaceId,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};

// export const useQueryGetTemplateById = (
//   tempateId: number,
//   page = 0,
//   limit = 100
// ): TemplateType | null => {
//   const { data, isFetching } = useQueryAllTemplates(page, limit);
//
//   if (isFetching) return null;
//
//   return data.find(({ id }) => id === tempateId);
// };

export const saveTemplateWithStepsByQueryClient = (
  queryClient: QueryClient,
  templateId: number,
  workspaceId: number,
  title: string,
  scope: TemplateScopeType,
  stepsList: TemplateStepType[]
): Promise<TemplateType> => {
  return saveTemplateWithStepsApi(templateId, workspaceId, title, scope, stepsList).then(
    queryMutation
  );

  function queryMutation(template: TemplateType) {
    const templatesCache = queryClient.getQueryState([queryCacheKeysConstants.templates, '', []]);
    const templatesContainerCache = queryClient.getQueryState([
      queryCacheKeysConstants.templatesSteps,
      templateId,
    ]);

    // Cache of list
    if (templatesCache) {
      queryClient.setQueryData(
        [queryCacheKeysConstants.templates, '', []],
        (cache: TemplateType[]) => [...cache, template]
      );
    }

    // Cache of template editor
    if (templatesContainerCache) {
      queryClient.setQueryData(
        [queryCacheKeysConstants.templatesSteps, templateId],
        (cache: TemplateContainerType) => {
          const preparedSteps: TemplateStepTransformedType[] = prepareSequenceSteps(stepsList);

          return {
            ...template,
            stepsList: preparedSteps,
          };
        }
      );
    }

    return template;
  }
};

// export const addTemplateByQueryClient = (
//   queryClient: QueryClient,
//   title: string,
//   workspaceId: number
// ): Promise<TemplateType> => {
//   return createTemplateApi(title, workspaceId).then(queryMutation);
//
//   function queryMutation(template: TemplateType) {
//     const templatesCache = queryClient.getQueryState([queryCacheKeysConstants.templates, '', []]);
//
//     if (templatesCache) {
//       queryClient.setQueryData(
//         [queryCacheKeysConstants.templates, '', []],
//         (cache: TemplateType[]) => [...cache, template]
//       );
//     }
//
//     return template;
//   }
// };
//
// export const updateTemplateByQueryClient = (
//   queryClient: QueryClient,
//   workspaceId: number,
//   templateId: number,
//   title: string
// ): void => {
//   updateTemplateTitleApi(workspaceId, templateId, title).then((updatedTemplate: TemplateType) => {
//     /*
//         We can have two caches (for all templates and for template container
//         We need to update them all if they exists
//        */
//     const templatesCache = queryClient.getQueryState(queryCacheKeysConstants.templatesSteps);
//     const templatesContainerCache = queryClient.getQueryState([
//       queryCacheKeysConstants.templatesSteps,
//       templateId,
//     ]);
//
//     if (templatesCache) {
//       queryClient.setQueryData(queryCacheKeysConstants.templates, (cache: TemplateType[]) => {
//         return updateItemInListByIdHelper(cache, updatedTemplate);
//       });
//     }
//
//     if (templatesContainerCache) {
//       queryClient.setQueryData(
//         [queryCacheKeysConstants.templatesSteps, templateId],
//         (cache: TemplateContainerType) => {
//           return {
//             ...cache,
//             title: updatedTemplate.title,
//           };
//         }
//       );
//     }
//   });
// };

export const removeTemplateByQueryClient = (
  queryClient: QueryClient,
  templateId: number,
  workspaceId?: number
): Promise<TemplateType[] | void> => {
  return deleteTemplateApi(templateId, workspaceId).then(() => {
    const templatesCache = queryClient.getQueryState([queryCacheKeysConstants.templates, '', []]);

    if (!templatesCache) return;

    return queryClient.setQueryData(
      [queryCacheKeysConstants.templates, '', []],
      (cache: TemplateType[]) => {
        return cache.filter(({ id }) => id !== templateId);
      }
    );
  });
};

export const duplicateTemplateByQueryClient = async (
  queryClient: QueryClient,
  templateId: number,
  workspaceId: number
): Promise<void> => {
  try {
    const duplicatedTemplate = await duplicateTemplateApi(templateId, workspaceId);

    // Update the local cache with the duplicated template
    queryClient.setQueryData(
      [queryCacheKeysConstants.templates, '', []],
      (cache: TemplateType[] | undefined) => {
        return cache ? [duplicatedTemplate, ...cache] : [duplicatedTemplate];
      }
    );
  } catch (error) {
    console.error('Failed to duplicate template:', error);
  }
};

/*
  STEPS
 */

export const useQueryTemplateSteps = (
  templateId: number
): UseQueryResult<TemplateContainerTypeWithTransformedStepsType> => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  return useQuery(
    [queryCacheKeysConstants.templatesSteps, templateId],
    () =>
      getTemplateStepsApi(templateId, workspaceId).then(
        (templateContainer: TemplateContainerType) => {
          const preparedSteps: TemplateStepTransformedType[] = prepareSequenceSteps(
            templateContainer.stepsList
          );

          return {
            ...templateContainer,
            stepsList: preparedSteps,
          };
        }
      ),
    {
      enabled: !!templateId,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
};

// export const addTemplateStepByQueryClient = (
//   queryClient: QueryClient,
//   templateId: number,
//   stepOrder = 0
// ): Promise<TemplateContainerTypeWithTransformedStepsType> => {
//   const creatingStep = {
//     subject: emptyEditorHtml,
//     content: emptyEditorHtml,
//     isSameThread: true,
//     sendDelay: 7,
//     order: stepOrder + 1,
//   };
//
//   return createTemplateStepApi(templateId, creatingStep).then((createdStep: TemplateStepType) => {
//     return queryClient.setQueryData(
//       [queryCacheKeysConstants.templatesSteps, templateId],
//       (cache: TemplateContainerTypeWithTransformedStepsType) => {
//         const formattedSteps = prepareSequenceSteps([...cache.stepsList, createdStep]);
//
//         return {
//           ...cache,
//           stepsList: formattedSteps,
//         };
//       }
//     );
//   });
// };
//
// export const updateTemplateStepDelayByQueryClient = (
//   queryClient: QueryClient,
//   templateId: number,
//   stepId: number,
//   delay: number
// ) => {
//   return updateTemplateStepDelayApi(templateId, stepId, delay).then((newStep) => {
//     return queryClient.setQueryData(
//       [queryCacheKeysConstants.templatesSteps, templateId],
//       (cache: TemplateContainerTypeWithTransformedStepsType) => {
//         return {
//           ...cache,
//           stepsList: updateItemInListByIdHelper(cache.stepsList, newStep),
//         };
//       }
//     );
//   });
// };
