import React from 'react';

export default ({ color = '#212121' }) => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33331 8.16665V16.5L8.33331 12.3333H16.6666C17.5875 12.3333 18.3333 11.5875 18.3333 10.6666V3.99998C18.3333 3.07915 17.5875 2.33331 16.6666 2.33331H9.16665"
      stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.41667 7.33327C5.01417 7.33327 4.6875 7.00661 4.6875 6.60411C4.6875 5.79911 4.03417 5.14577 3.22917 5.14577C2.82667 5.14577 2.5 4.81911 2.5 4.41661C2.5 4.01411 2.82667 3.68744 3.22917 3.68744C4.03417 3.68744 4.6875 3.03411 4.6875 2.22911C4.6875 1.82661 5.01417 1.49994 5.41667 1.49994C5.81917 1.49994 6.14583 1.82661 6.14583 2.22911C6.14583 3.03411 6.79917 3.68744 7.60417 3.68744C8.00667 3.68744 8.33333 4.01411 8.33333 4.41661C8.33333 4.81911 8.00667 5.14577 7.60417 5.14577C6.79917 5.14577 6.14583 5.79911 6.14583 6.60411C6.14583 7.00661 5.81917 7.33327 5.41667 7.33327Z"
      fill={color} />
  </svg>

);