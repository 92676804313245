import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initAppCenterSDK } from '@semcore/app-center-js-sdk';
import { decodeJwt, JWTPayload } from 'jose';
import { isSemrushMode } from '@constants/app-modes';

import Routes from 'src/Routes';
import BaseRequestComponent from 'src/BaseRequestComponent';
import Notifications from '@uikit/Notifications/Notifications';
import { redirectLoginManually } from '@utils/redirections';

import store from 'src/store';
import gtm from 'src/libs/gtm';

import './vendors/normalize.css';
import './vendors/integrations.scss';

const queryClient = new QueryClient();

declare const GAPI_CLIENT_ID: string;
declare const GTM_ID: string;
declare const INTEGRATION_MODE: string;
declare const IS_STANDALONE_MODE: boolean;

const iframeJwtToken = new URLSearchParams(window.location.search).get('jwt');

const composeJwtToken = (): JWTPayload | undefined =>
  iframeJwtToken ? decodeJwt(iframeJwtToken) : undefined;

const isJwtTokenExpired = (): boolean => {
  const token = composeJwtToken();
  if (!token) return true;
  const expDate = token.exp;
  const nowInSeconds = Math.floor(Date.now() / 1000);
  return expDate <= nowInSeconds;
};

const handleRedirects = () => {
  if (IS_STANDALONE_MODE && !localStorage.getItem('token')) {
    redirectLoginManually();
  } else if (isSemrushMode(INTEGRATION_MODE) && isJwtTokenExpired()) {
    // TODO show specific Semrush page
    redirectLoginManually();
  }
};

const initializeIntegration = () => {
  if (isSemrushMode(INTEGRATION_MODE)) {
    initAppCenterSDK();
    const url = composeJwtToken()?.url as string;
    window.history.replaceState({}, '', url);
    window.QueryClient = queryClient;
  }
};

const initializeCustomGTM = () => {
  if (IS_STANDALONE_MODE && GTM_ID?.length) {
    gtm.init(GTM_ID);
    console.log('GTM initialized');
  }
};

handleRedirects();
initializeIntegration();

ReactDOM.render(
  <GoogleOAuthProvider clientId={GAPI_CLIENT_ID}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <BaseRequestComponent>
            <>
              <Routes />
              <Notifications />
              {/* <VersionTag /> */}
            </>
          </BaseRequestComponent>
        </Router>
      </Provider>
    </QueryClientProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

initializeCustomGTM();
