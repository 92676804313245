/**
 * NOT CHECKED
 */
import React, { useEffect, useRef, useState } from 'react';

import Display from '@components/Display/Display';
import DateRangePickerWrapper from '@uikit/DateRangePicker/DateRangePicker';
import { addWeeks } from 'date-fns';
import useOutsideClick from '@hooks/useOutsideClick';

import './DateRangeSelect.scss';

const DateRangeSelect = ({
  value,
  onChange,
  isVisible,
  onChangeIsVisible,
  isRangePicker = true,
  isReverseCheck = false,
  style,
  emptyAllowed,
}: {
  value?: {
    startDate: number;
    endDate: number;
  };
  onChange: (startDate: number, endDate?: number) => void;
  isVisible: boolean;
  onChangeIsVisible: (boolean) => void;
  isRangePicker?: boolean;
  isReverseCheck?: boolean;
  style?: React.CSSProperties;
  emptyAllowed?: boolean;
}): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>();

  useOutsideClick(wrapperRef, () => onChangeIsVisible(false));

  useEffect(() => {
    if (value?.startDate || emptyAllowed) {
      return;
    }

    const aWeekAgoDate = addWeeks(new Date(), -1);
    const aWeekAgoMS = aWeekAgoDate.getTime();
    onChange(aWeekAgoMS, Date.now());
  }, []);

  const handleSelect = ({ selection }) => {
    onChange(+selection.startDate, +selection.endDate);
  };

  const handleSingleDateSelect = (date) => {
    onChange(date);
  };

  return (
    <div className="date-range-select">
      <Display isVisible={isVisible}>
        <div className="date-range-select__picker" style={style} ref={wrapperRef}>
          <DateRangePickerWrapper
            handleSelect={isRangePicker ? handleSelect : handleSingleDateSelect}
            value={value}
            isRangePicker={isRangePicker}
            isReverseCheck={isReverseCheck}
          />
        </div>
      </Display>
    </div>
  );
};

export default DateRangeSelect;
