import React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  ExternalUnsubscribePageGradientLeft,
  ExternalUnsubscribePageGradientRight,
} from '@uikit/ExternalUnsubscribePageGradients/ExternalUnsubscribePageGradients';
import { Button } from '@uikit/Button/Button';

// @ts-ignore
import responaLogo from 'src/assets/ResponaLogoLarge.svg';

import './MaintenancePage.scss';

declare const IS_STANDALONE_MODE: boolean;

function MaintenancePage({
  errorTitle = 'We\'re upgrading your outreach experience.',
  errorText = 'We\'re undergoing a new update. We’ll be back online soon.',
}: {
  errorTitle?: string;
  errorText?: string;
}) {
  const { search } = useLocation();
  const { redirect, message } = qs.parse(search);

  return (
    <div className="maintenance-page">
      {IS_STANDALONE_MODE ? (
        <a href="/" className="maintenance-page__logo-container">
          <img alt="Logo" src={responaLogo} className="maintenance-page__logo" />
        </a>
      ) : null}

      <ExternalUnsubscribePageGradientLeft />
      <ExternalUnsubscribePageGradientRight />

      <div className="maintenance-page__content">
        <div className="maintenance-page__message">
          {message ? (
            <h1 className="maintenance-page__title">Error</h1>
          ) : (
            <h1 className="maintenance-page__title">{errorTitle}</h1>
          )}

          {message ? (
            <p className="maintenance-page__sub-title">{decodeURI(message as string)}</p>
          ) : (
            <>
              <p className="maintenance-page__sub-title">{errorText}</p>
              {IS_STANDALONE_MODE && (
                <p className="maintenance-page__sub-title maintenance-page__contact">
                  Questions? Don&apos;t hesitate to get in touch with us via{' '}
                  <a href="mailto:support@respona.com">support@respona.com</a>
                </p>
              )}
            </>
          )}

          {redirect ? (
            <Button href={redirect as string} className="maintenance-page__refresh-btn">
              Try Again
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MaintenancePage;
