import { OpportunityFilterField, OpportunityFilterFieldMap } from 'respona_api/generated/campaign_pb';

export const getOpportunityFilterFields = (): {
  label: string;
  value: OpportunityFilterFieldMap[keyof OpportunityFilterFieldMap];
  type: 'string' | 'number' | 'boolean' | 'enum' | 'enumString' | 'stringNotEmpty';
  inputOptions?: { value: string | number; label: string }[];
}[] => [
  {
    label: 'Title',
    value: OpportunityFilterField.OPPORTUNITY_TITLE,
    type: 'stringNotEmpty',
  },
  {
    label: 'URL',
    value: OpportunityFilterField.OPPORTUNITY_URL,
    type: 'stringNotEmpty',
  },
  {
    label: 'Source',
    value: OpportunityFilterField.OPPORTUNITY_TYPE,
    type: 'enumString',
    inputOptions: [
      { value: 'GOOGLE_SEARCH', label: 'Google Search' },
      { value: 'PODCAST', label: 'Podcast' },
      { value: 'EPISODE', label: 'Episode' },
      { value: 'IMPORT', label: 'Imported' },
      { value: 'RELATIONSHIPS', label: 'Relationships' },
      { value: 'AHREF_BACKLINKS', label: 'Ahref Backlinks' },
      { value: 'SEMRUSH_BACKLINKS', label: 'Semrush Backlinks' },
      { value: 'MOZ_BACKLINKS', label: 'Moz Backlinks' },
      { value: 'RESPONA_BACKLINKS', label: 'Backlinks' },
      { value: 'GOOGLE_NEWS', label: 'Google News' },
    ],
  },
  {
    label: 'Status',
    value: OpportunityFilterField.OPPORTUNITY_REVIEWED_STATE,
    type: 'enumString',
    inputOptions: [
      { value: 'true', label: 'Reviewed' },
      { value: 'false', label: 'Not reviewed' },
    ],
  },
  {
    label: 'Email',
    value: OpportunityFilterField.OPPORTUNITY_CONTACT_EMAIL,
    type: 'string',
  },
];
