import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import cn from 'class-names';

import HowToUseButton from '@uikit/HowToUseButton/HowToUseButton';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './InboxSidebar.scss';
import InboxSelector from '@components/Inbox/InboxSidebar/_components/InboxSelector/InboxSelector';
import { useQuery, useQueryClient } from 'react-query';
import { addLabelApi, getAllLabelsApi, removeLabelApi, updateLabelApi } from '@api/mailbox.api';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import Loader from '@uikit/Loader/Loader';
import Display from '@components/Display/Display';
import Input from '@uikit/Input/Input';
import { TagType } from '@ts/common.types';
import {
  InboxAccountsPresetType,
  InboxUnreadCounterType,
  QueuePreviewResponseType,
  ThreadPreviewResponseType,
} from '@ts/mailboxInbox.types';
import { setInboxFilters } from '@redux/actions/inbox.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  inboxFiltersSelector,
  inboxSelectedMailboxesIdsSelector,
} from '@redux/selectors/inbox.selectors';
import ExpandableRow from '@components/ExpandableRow/ExpandableRow';
import { pagesLinks } from '@components/Sidebar/pagesLinks';
import { Button } from '@uikit/Button/Button';
import ReactTooltip from 'react-tooltip';
import { addNotification } from '@redux/actions/notifications.actions';
import useOutsideClick from '@hooks/useOutsideClick';
import { linkGoTo, redirectUserTo } from '@utils/historyHandler';

declare const IS_STANDALONE_MODE: boolean;

function InboxSidebarLink({
  icon,
  text,
  link,
  pathname,
  counter,
  onClick,
}: {
  icon: string;
  text: string;
  link: string;
  pathname: string;
  onClick: () => void;
  counter?: number;
}): JSX.Element {
  const history = useHistory();
  const isActive = new RegExp(`/workspaces/\\d+${link}`).test(pathname);

  return (
    <Link
      to={linkGoTo(link)}
      className={cn('inbox-sidebar__link', { 'inbox-sidebar__link--active': isActive })}
      onClick={(event) => {
        event.preventDefault();
        redirectUserTo(history, link);
        onClick();
      }}
    >
      <span className="inbox-sidebar__link-icon">
        <SVGIcon icon={icon as SvgIconType} color={isActive ? '#221CB6' : '#202430'} />
      </span>
      {text}
      {!!counter && counter > 0 && <span className="inbox-sidebar__link-counter">{counter}</span>}
    </Link>
  );
}

const topLinks = IS_STANDALONE_MODE
  ? [
      {
        value: 'assigned',
        icon: 'assigned',
        label: 'Assigned to me',
      },
      {
        value: 'mentioned',
        icon: 'persons',
        label: 'Mentioned me',
      },
    ]
  : [];

const bottomLinks = [
  {
    value: 'starred',
    icon: 'star',
    label: 'Starred',
  },
  {
    value: 'sent',
    icon: 'sent',
    label: 'Sent',
  },
  {
    value: 'drafts',
    icon: 'draft',
    label: 'Drafts',
  },
  {
    value: 'outbox',
    icon: 'outbox',
    label: 'Outbox',
  },
  {
    value: 'needAttention',
    icon: 'needAttention',
    label: 'Bounced',
  },
  // {
  //   value: 'spam',
  //   icon: 'spam',
  //   label: 'Spam',
  // },
];

function InboxSidebarLabel({ title, id, isActive, onLabelClick, onRemoveLabel, onRenameLabel }) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newLabelValue, setNewLabelValue] = useState(title);
  const inputRef = useRef(null);

  useOutsideClick(inputRef, () => {
    setIsEditing(false);
    setNewLabelValue(title);
  });

  const handleSave = (event) => {
    if (event.key === 'Enter') {
      onRenameLabel(id, newLabelValue);
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <Input
        inputRef={inputRef}
        className="inbox-sidebar__labels-input"
        type="text"
        placeholder="Enter label"
        value={newLabelValue}
        onChange={({ target: { value } }) => setNewLabelValue(value)}
        onKeyDown={handleSave}
        maxLength="30"
      />
    );
  }

  return (
    <div
      key={id}
      className={cn('inbox-sidebar__link', {
        'inbox-sidebar__link--active': isActive,
      })}
      onClick={() => onLabelClick(id)}
    >
      <span className="inbox-sidebar__link-icon">
        <SVGIcon icon={'label' as SvgIconType} color={isActive ? '#221CB6' : '#202430'} />
      </span>
      <span className="inbox-sidebar__link-text">{title}</span>
      {isActive ? (
        <>
          <Button
            className="inbox-sidebar__link-show-more"
            type="ghost"
            data-for={`inbox-sidebar__link-show-more-${id}`}
            data-tip=""
            data-event="click"
          >
            <SVGIcon icon="threeDots" />
          </Button>

          <ReactTooltip
            id={`inbox-sidebar__link-show-more-${id}`}
            className="react-tooltip"
            place="bottom"
            effect="solid"
            event="click"
            globalEventOff="click"
            arrowColor="transparent"
            clickable
          >
            <div className="inbox-sidebar__link-tooltip-dropdown">
              <div
                className="inbox-sidebar__link-tooltip-dropdown__item"
                onClick={() => setIsEditing(true)}
              >
                <span className="inbox-sidebar__link-tooltip-dropdown__item-icon">
                  <SVGIcon icon="renameAction" color="white" />
                </span>
                Rename
              </div>
              <div
                className="inbox-sidebar__link-tooltip-dropdown__item"
                onClick={() => onRemoveLabel(id)}
              >
                <span className="inbox-sidebar__link-tooltip-dropdown__item-icon">
                  <SVGIcon icon="trashWhite" color="white" />
                </span>
                Remove
              </div>
            </div>
          </ReactTooltip>
        </>
      ) : null}
    </div>
  );
}

function InboxSidebar({
  allMailboxes,
  isFetchingMailboxes,
  unreadCounter,
  setActiveThread,
}: {
  allMailboxes: InboxAccountsPresetType[];
  isFetchingMailboxes: boolean;
  unreadCounter: InboxUnreadCounterType;
  setActiveThread: Dispatch<
    SetStateAction<(ThreadPreviewResponseType & QueuePreviewResponseType) | null>
  >;
}): JSX.Element {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [isCreating, setCreating] = useState<boolean>(false);
  const [labelValue, setLabelValue] = useState<string>('');
  const [activeLabelIds, setActiveLabelIds] = useState<number[]>([]);
  const inboxFilters = useSelector(inboxFiltersSelector);
  const inboxesSelected: number[] = useSelector(inboxSelectedMailboxesIdsSelector);

  useEffect(() => {
    if (
      inboxFilters !== null &&
      inboxFilters.tagIdsList !== null &&
      inboxFilters.tagIdsList.length
    ) {
      setActiveLabelIds(inboxFilters.tagIdsList);
    }
  }, [inboxFilters]);

  const mailboxLabelsQueryKey = ['mailbox-labels'];

  const {
    data: labelsData,
    isFetching,
    isLoading,
  } = useQuery(mailboxLabelsQueryKey, () => getAllLabelsApi(), {
    enabled: !!currentWorkspaceId && !!inboxesSelected,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const onRenameLabel = (labelId: number, value: string) => {
    updateLabelApi(labelId, value).then((response) => {
      dispatch(addNotification({ title: 'Label updated', type: 'success' }));
      setActiveThread((prevState) => {
        return {
          ...prevState,
          tagsList:
            prevState?.tagsList?.map((tag) => {
              if (tag.id !== labelId) {
                return tag;
              }

              return { ...tag, title: value };
            }) || [],
        };
      });
      queryClient.setQueryData(mailboxLabelsQueryKey, (prevState: TagType[]) => {
        return prevState.map((item) => {
          if (item.id === labelId) {
            return { ...item, title: response.title };
          }

          return item;
        });
      });
    });
  };

  const onRemoveLabel = (labelId: number) => {
    removeLabelApi(labelId, null).then(() => {
      dispatch(addNotification({ title: 'Label removed', type: 'success' }));
      setActiveThread((prevState) => {
        return {
          ...prevState,
          tagsList: prevState?.tagsList?.filter((tag) => tag.id !== labelId) || [],
        };
      });
      queryClient.setQueryData(mailboxLabelsQueryKey, (prevState: TagType[]) => {
        return prevState.filter((item) => item.id !== labelId);
      });
    });
  };

  const onNewLabelClick = () => {
    setCreating(true);
    setLabelValue('');
  };

  const onCreateLabelClick = () => {
    addLabelApi(null, labelValue, null).then((response) => {
      setLabelValue('');
      setCreating(false);
      queryClient.setQueryData(['mailbox-labels'], (cache: TagType[]) => [response, ...cache]);
    });
  };

  function onLabelClick(labelId: number) {
    let updatedActiveLabelIds: number[];
    const isLabelCurrentlyActive = activeLabelIds.includes(labelId);

    if (isLabelCurrentlyActive) {
      updatedActiveLabelIds = activeLabelIds.filter((id) => id !== labelId);
    } else {
      updatedActiveLabelIds = [labelId];
    }

    setActiveLabelIds(updatedActiveLabelIds);

    const alreadyInFilter = inboxFilters.tagIdsList.includes(labelId);
    let updatedTagIdsList: number[];

    if (alreadyInFilter) {
      updatedTagIdsList = inboxFilters.tagIdsList.filter((id) => id !== labelId);
    } else {
      updatedTagIdsList = [labelId];
    }

    if (updatedTagIdsList.length) {
      redirectUserTo(history, `/workspaces/${currentWorkspaceId}/inbox/all`);
    }

    dispatch(setInboxFilters({ ...inboxFilters, tagIdsList: updatedTagIdsList }));
  }

  function pickCounter(value: string): number | null {
    switch (value) {
      case 'assigned':
        return unreadCounter?.assignedNumber;
      case 'mentioned':
        return unreadCounter?.mentionedNumber;
      case 'inbox':
        return unreadCounter?.openNumber;
      default:
        return null;
    }
  }

  const handleSideBarItemClick = () => {
    setActiveLabelIds([]);
    dispatch(setInboxFilters({ ...inboxFilters, tagIdsList: [] }));
  };

  const handleInboxClick = () => {
    handleSideBarItemClick();
    redirectUserTo(history, `/workspaces/${currentWorkspaceId}/inbox/all`);
  };

  const redirectionUrl = '/settings/all-emails?isEmailCreationOpenInUrl=true';

  return (
    <div className="inbox-sidebar">
      <div className="inbox-sidebar__header">Inbox</div>

      <div className="inbox-sidebar__content">
        <div className="inbox-sidebar__mailboxes">
          <InboxSelector
            allMailboxes={allMailboxes}
            isFetchingMailboxes={isFetchingMailboxes}
            numberUnreadMessages={unreadCounter?.openNumber || 0}
            isActive={
              pathname ===
                pagesLinks(currentWorkspaceId).find(({ name }) => name === 'Inbox')?.path &&
              inboxFilters.tagIdsList.length === 0
            }
            onClick={handleInboxClick}
          />
          {topLinks.map(({ value, icon, label }) => {
            return (
              <InboxSidebarLink
                key={value}
                pathname={pathname}
                link={`/inbox/${value}`}
                icon={icon}
                text={label}
                counter={pickCounter(value)}
                onClick={handleSideBarItemClick}
              />
            );
          })}
        </div>

        <div className="inbox-sidebar__folders">
          {bottomLinks.map(({ value, icon, label }) => {
            return (
              <InboxSidebarLink
                key={value}
                pathname={pathname}
                link={`/inbox/${value}`}
                icon={icon}
                text={label}
                counter={pickCounter(value)}
                onClick={handleSideBarItemClick}
              />
            );
          })}
        </div>

        <div className="inbox-sidebar__labels">
          <Loader isLoading={isFetching || isLoading}>
            <div className="inbox-sidebar__labels-header">
              Labels
              <span className="inbox-sidebar__labels-header-add-button" onClick={onNewLabelClick}>
                <SVGIcon icon="plusCircle" color="#C4C6CD" />
              </span>
            </div>
            <Display isVisible={isCreating}>
              <Input
                className="inbox-sidebar__labels-input"
                type="text"
                placeholder="Enter label"
                value={labelValue}
                onChange={({ target: { value } }) => setLabelValue(value)}
                onKeyDown={(e) => e.key === 'Enter' && onCreateLabelClick()}
                maxLength="30"
              />
            </Display>
            {!!labelsData && (
              <div className="inbox-sidebar__labels-container-link">
                <ExpandableRow maxCollapsed={4} isButtonView>
                  {labelsData.map(({ id, title }) => (
                    <InboxSidebarLabel
                      key={id}
                      id={id}
                      title={title}
                      isActive={activeLabelIds.includes(id)}
                      onLabelClick={onLabelClick}
                      onRemoveLabel={onRemoveLabel}
                      onRenameLabel={onRenameLabel}
                    />
                  ))}
                </ExpandableRow>
              </div>
            )}
          </Loader>
        </div>
      </div>

      {IS_STANDALONE_MODE && (
        <div className="inbox-sidebar__how-to">
          <HowToUseButton to="https://help.respona.com/en/articles/6332447-how-to-use-inbox" />
        </div>
      )}
      <Link
        className="inbox-sidebar__footer"
        to={linkGoTo(redirectionUrl)}
        onClick={() => {
          redirectUserTo(history, redirectionUrl);
        }}
      >
        <SVGIcon icon="messageIcon" />
        <span className="inbox-sidebar__footer-text">Add inbox</span>
      </Link>
    </div>
  );
}

export default InboxSidebar;
