import AdvancedSettingsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/AdvancedSettingsWrapper/AdvancedSettingsWrapper';
import NumberInput from '@uikit/Input/NumberInput';
import React, { useState } from 'react';

import DropdownPanel from '@uikit/DropdownPanel/DropdownPanel';
import Radio from '@uikit/Radio/Radio';
import Input from '@uikit/Input/Input';
import Button from '@uikit/Button/Button';
import Checkbox from '@uikit/Checkbox/Checkbox';
import { ImportModeEnum, ParsingDelimiterEnum } from '@ts/fileImport.types';

import AutomationSourceOption, {
  AutomationSourceOptionsRow,
} from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationSourceSelect/_components/AutomationSourceOption';
import PeopleSvg from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/PeopleSVG';
import WebsitesSvg from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/WebsitesSVG';
import Display from '@components/Display/Display';
import FileSeletcStepUploadProgress from '@components/CampaignSettings/ContentSearch/ImportFileFlow/_components/FileSeletcStepUploadProgress/FileSeletcStepUploadProgress';
import DragNDropZone from '@components/CampaignSettings/ContentSearch/ImportFileFlow/_components/DragNDropZone/DragNDropZone';

import './FileSelectStep.scss';

const importTypeOptions = [
  {
    type: ImportModeEnum.OPPORTUNITY,
    title: 'Websites',
    subtitle: 'Import a list of URLs and let automation find contact info.',
    image: <WebsitesSvg />,
    statusText: 'URL required',
  },
  {
    type: ImportModeEnum.PERSON,
    title: 'Contacts',
    subtitle: 'Import your own list of contacts and send automated emails.',
    image: <PeopleSvg />,
    statusText: 'Email required',
  },
];

const FileSelectStep = ({
  onContinue,
  uploadedFile,
  onChangeFile,
  onCancelUpload,
  delimiter,
  onChangeDelimiter,
  importMode,
  onChangeImportMode,
  customDelimiter,
  onChangeCustomDelimiter,
  importSettings,
  onChangeImportSettings,
  isUploading,
  uploadingProgress,
  importSettingsStyle,
}): JSX.Element => {
  const [curFile, changeCurFile] = useState(null);
  const handleFileLoad = (file) => {
    onChangeFile(file);
    changeCurFile(file);
  };
  const handleCancel = () => {
    onCancelUpload();
    changeCurFile(null);
  };

  const handleChangeDelimiter = ({ target: { value } }) => onChangeDelimiter(Number(value));
  const handleCustomDelimiter = ({ target: { value } }) => onChangeCustomDelimiter(value);
  const createImportSettingChangeHandler =
    (fieldName) =>
    ({ target: { checked } }) =>
      onChangeImportSettings(fieldName, checked);

  return (
    <>
      <div className="file-select-step__title">Select import mode:</div>
      <AutomationSourceOptionsRow>
        {importTypeOptions.map(({ type, title, subtitle, image, statusText }) => (
          <AutomationSourceOption
            key={title}
            image={image}
            title={title}
            onSelect={() => onChangeImportMode(type)}
            isSelected={importMode === type}
            subtitle={subtitle}
            statusText={statusText}
          />
        ))}
      </AutomationSourceOptionsRow>

      <div className="file-select-step">
        {uploadingProgress !== null ? (
          <FileSeletcStepUploadProgress
            uploadingProgress={uploadingProgress}
            curFile={curFile}
            uploadedFile={uploadedFile}
            onCancel={handleCancel}
          />
        ) : (
          <DragNDropZone onLoadFile={handleFileLoad} />
        )}
        <AdvancedSettingsWrapper>
          <div className="file-select-step__delimiter-select-title">Delimiter</div>
          <div className="file-select-step__delimiter-select">
            <Radio
              size={16}
              className="file-select-step__delimiter-option"
              onChange={handleChangeDelimiter}
              value={ParsingDelimiterEnum.AUTO_DELIMITER}
              isChecked={delimiter === ParsingDelimiterEnum.AUTO_DELIMITER}
            >
              Auto
            </Radio>
            <Radio
              size={16}
              className="file-select-step__delimiter-option"
              onChange={handleChangeDelimiter}
              value={ParsingDelimiterEnum.TAB_DELIMITER}
              isChecked={delimiter === ParsingDelimiterEnum.TAB_DELIMITER}
            >
              Tab
            </Radio>
            <Radio
              size={16}
              className="file-select-step__delimiter-option"
              onChange={handleChangeDelimiter}
              value={ParsingDelimiterEnum.COMA_DELIMITER}
              isChecked={delimiter === ParsingDelimiterEnum.COMA_DELIMITER}
            >
              Coma
            </Radio>
            <Radio
              size={16}
              className="file-select-step__delimiter-option"
              onChange={handleChangeDelimiter}
              value={ParsingDelimiterEnum.SEMICOLON_DELIMITER}
              isChecked={delimiter === ParsingDelimiterEnum.SEMICOLON_DELIMITER}
            >
              Semicolon
            </Radio>
            <Radio
              size={16}
              className="file-select-step__delimiter-option"
              onChange={handleChangeDelimiter}
              value={ParsingDelimiterEnum.SPACE_DELIMITER}
              isChecked={delimiter === ParsingDelimiterEnum.SPACE_DELIMITER}
            >
              Space
            </Radio>
            <Radio
              size={16}
              className="file-select-step__delimiter-option"
              onChange={handleChangeDelimiter}
              value={ParsingDelimiterEnum.OTHER_DELIMITER}
              isChecked={delimiter === ParsingDelimiterEnum.OTHER_DELIMITER}
            >
              Other
              <Display isVisible={delimiter === ParsingDelimiterEnum.OTHER_DELIMITER}>
                <Input
                  className="file-select-step__delimiter-option-custom-input"
                  value={customDelimiter}
                  onChange={handleCustomDelimiter}
                  placeholder=""
                />
              </Display>
            </Radio>
          </div>
          <div className="file-select-step__import-settings">
            <Display isVisible={importMode === ImportModeEnum.PERSON}>
              <Checkbox
                value={importSettings.groupContactsByDomain}
                onChange={createImportSettingChangeHandler('groupContactsByDomain')}
              >
                Group contacts by domain
              </Checkbox>
            </Display>
            <Checkbox
              value={importSettings.removeDuplicated}
              onChange={createImportSettingChangeHandler('removeDuplicated')}
            >
              Flag duplicate rows
            </Checkbox>
            <Checkbox
              value={importSettings.removeContactedInPast}
              onChange={createImportSettingChangeHandler('removeContactedInPast')}
            >
              Flag rows contacted within
              <NumberInput
                disabled={!importSettings.removeContactedInPast}
                className="file-select-step__days-contacted"
                value={importSettings.daysInThePast}
                maxNumber={999}
                minNumber={1}
                placeholder=""
                onChange={(value) => onChangeImportSettings('daysInThePast', value)}
                disabledArrows
              />
              days
            </Checkbox>
            <Checkbox
              value={importSettings.removeActiveContacts}
              onChange={createImportSettingChangeHandler('removeActiveContacts')}
            >
              Flag rows included in other active/paused campaigns
            </Checkbox>
            <Checkbox
              value={importSettings.removeUnsubscribed}
              onChange={createImportSettingChangeHandler('removeUnsubscribed')}
            >
              Flag rows included in the Unsubscribed list
            </Checkbox>
          </div>
        </AdvancedSettingsWrapper>

        <div className="file-select-step__footer">
          <Button
            disabled={uploadedFile === null || isUploading || uploadingProgress === null}
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default FileSelectStep;
