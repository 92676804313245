/**
 * NOT CHECKED
 */
import React from 'react';

import getExtensionFromFileName from '@utils/getExtensionFromFileName';
import getFileSizeString from '@utils/getFileSizeString';
import Display from '@components/Display/Display';
import { SVGIcon } from '@uikit/Icon/Icon';
import FileExtensionIcon from '@uikit/FileExtensionIcon/FileExtensionIcon';

import './FileAttachment.scss';

const FileAttachment = ({
  title,
  size,
  onRemoveFile,
  onClick = () => {},
}: {
  title: string;
  size: number;
  onRemoveFile?: () => void;
  onClick?: () => void;
}): JSX.Element => (
  <div className="file-attachment" onClick={onClick}>
    <div className="file-attachment__file-title" title={title}>
      <FileExtensionIcon extension={getExtensionFromFileName(title)} />
      {title}
    </div>
    <div className="file-attachment__file-size" title={title}>
      {getFileSizeString(size)}
    </div>
    <Display isVisible={onRemoveFile !== undefined}>
      <div
        className="file-attachment__file-remove"
        onClick={onRemoveFile}
        tabIndex={0}
        role="button"
      >
        <SVGIcon icon="crossDelete" />
      </div>
    </Display>
  </div>
);

export default FileAttachment;
