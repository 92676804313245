import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { addNotification } from '@redux/actions/notifications.actions';

import RelationshipsFiltersTooltipContentRaw from './RelationshipsFiltersTooltipContentRaw/RelationshipsFiltersTooltipContentRaw';
import RelationshipsFiltersTooltipContentFooter from './RelationshipsFiltersTooltipContentFooter/RelationshipsFiltersTooltipContentFooter';

import { FilterFieldType } from '@ts/filterField.type';
import { PeopleFilterFieldMap } from 'respona_api/generated/people_pb';

import './RelationshipsFiltersTooltipContent.scss';

function RelationshipsFiltersTooltipContent({
  onApplyFilters,
  filterFields,
  filtersFromRedux,
  initialFilterState,
}): JSX.Element {
  const dispatch = useDispatch();
  const [filters, changeFilters] = useState<FilterFieldType<PeopleFilterFieldMap>[]>(filtersFromRedux);

  const handleAddFilterInput = () =>
    changeFilters((prevState) => {
      const keys = Object.keys(prevState);
      const newkey = Number(keys[keys.length - 1]) + 1;

      return { ...prevState, [newkey]: initialFilterState };
    });

  const handleChangeFilterState = (filterRawKey, filterInputKey, value) => {
    changeFilters((prevState) => {
      if (filterInputKey === 'join') {
        const newState = { ...prevState };
        Object.keys(newState).forEach((key) => {
          newState[key].join = value;
        });
        return newState;
      }
      return {
        ...prevState,
        [filterRawKey]: {
          ...prevState[filterRawKey],
          [filterInputKey]: value,
        },
      };
    });
  };

  const createHandleDeleteFilterRaw = (dummy: number) => () => {
    changeFilters((prevState) => {
      const newState = { ...prevState };
      delete newState[dummy];
      return newState;
    });
  };

  const handleApplyFilters = () => {
    const filtersArray = Object.values(filters);

    // if there is only one filter raw -> we can apply it to make filters empty or with only one value
    if (filtersArray.length === 1) {
      onApplyFilters(filtersArray);
      return;
    }

    if (filtersArray.some(({ value }) => value === '')) {
      dispatch(addNotification({ type: 'warning', title: 'Some filter fields are empty' }));
      return;
    }

    onApplyFilters(filtersArray);
  };

  return (
    <div className="relationships-filters-tooltip-content">
      <div className="relationships-filters-tooltip-content__body">
        {Object.entries(filters).map((filter, index) => {
          const filterId = Number(filter[0]);
          const filterRawData = filter[1];
          return (
            <RelationshipsFiltersTooltipContentRaw
              key={filterId}
              rawId={filterId}
              isAdditional={!!index}
              onDeleteFilterInput={createHandleDeleteFilterRaw(filterId)}
              filterRawData={filterRawData}
              onChangeFilter={handleChangeFilterState}
              filterFields={filterFields}
            />
          );
        })}
      </div>
      <RelationshipsFiltersTooltipContentFooter
        onApplyFilters={handleApplyFilters}
        onAddFilterInput={handleAddFilterInput}
      />
    </div>
  );
}

export default RelationshipsFiltersTooltipContent;
