import { BillingSubscriptionTypeMapValue as BillingSubscriptionTypeName } from '@ts/billing.types';
import React from 'react';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';

import BillingCard from '@components/Settings/Billing/_components/BillingCard/BillingCard';

import './EmailAccountsCard.scss';
import { BillingSubscriptionType } from 'respona_api/generated/billing_pb';

declare const IS_STANDALONE_MODE: boolean;

function EmailAccountsCard({
  currentMailboxAccountsNumber,
  totalMailboxAccountsNumber,
  onAddMore,
  type,
}: {
  type: BillingSubscriptionTypeName;
  currentMailboxAccountsNumber: number;
  totalMailboxAccountsNumber: number;
  onAddMore: () => void;
}): JSX.Element {
  return (
    <BillingCard
      title="Email accounts"
      showWarning={false}
      headerRight={
        !IS_STANDALONE_MODE ||
        type === BillingSubscriptionType.UNLIMITED ||
        type === BillingSubscriptionType.ENTERPRISE ? undefined : (
          <div onClick={onAddMore}>
            <TextWithIcon icon="arrowUpCircle" iconColor="#3424EB" iconSize={18}>
              Buy emails
            </TextWithIcon>
          </div>
        )
      }
      chartTitle="Connected email accounts"
      chartInfo={{
        value: currentMailboxAccountsNumber,
        max: totalMailboxAccountsNumber,
      }}
    />
  );
}

export default EmailAccountsCard;
