/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { CampaignAutomationType } from '@ts/automationCampaigns.types';

import { Tr } from '@uikit/Table/Table';
import ColoredStatusToggler from '@uikit/ColoredStatusToggler/ColoredStatusToggler';

import ActionsMenu from '@components/ActionsMenu/ActionsMenu';

import './AiAgentsTable.scss';
import { SVGIcon } from '@uikit/Icon/Icon';
import Checkbox from '@uikit/Checkbox/Checkbox';
import { CampaignAgentStatus, CampaignAutomationStatus } from 'respona_api/generated/common_pb';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

type TableRenderProps = {
  row: CampaignAutomationType;
  children?: any;
  renderProps?: {
    [key: string]: any;
  };
};

const agentStatusToStringMapper = {
  1: 'In progress',
  2: 'Completed',
  0: 'Draft',
};

const agentStatusTextColor = {
  1: '#FFFFFF',
  2: '#23E771',
  0: '#A3B2C9',
};

const agentStatusIconColor = {
  1: '#221CB6',
  2: '#FFFFFF',
  0: '#A3B2C9',
};

const agentStatusColor = {
  1: '#221CB6',
  2: '#ECFFF3',
  0: '#F5F6FA',
};

const calculatePercent = (val: number, total: number): number => {
  if (val === 0 || total === 0) {
    return 0;
  }
  return Math.round((val / total) * 100);
};

function TimestampToDate({ timestamp }) {
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <>
      {formattedDate} at {formattedTime}
    </>
  );
}

export default [
  {
    title: 'Title',
    dataKey: 'title',
    RenderTitle: ({
      renderProps: { isSelectedAll, handlerSelectAll },
    }: TableRenderProps): JSX.Element => {
      return (
        <div className="campaigns-table-header">
          <Checkbox onChange={handlerSelectAll} value={isSelectedAll} />
          Agent
        </div>
      );
    },
    RenderCell: ({
      row,
      renderProps: { handlerSelectOne, checkboxes },
    }: TableRenderProps): JSX.Element => {
      return (
        <div className="title-cell">
          <Checkbox
            onChange={() => handlerSelectOne(row)}
            value={checkboxes.find(({ id }) => id === row.id)}
          />
          <div className="title-cell__body">
            <div className="title-cell__title" title={row.title}>
              {row.title}
            </div>
          </div>
        </div>
      );
    },
  },
  {
    title: 'Status',
    dataKey: 'statusToggler',
    RenderCell: ({ row, renderProps: { onUpdateStatus } }: TableRenderProps): JSX.Element => {
      const [isStatusChanging, changeIsStatusChanging] = useState(false);

      const handleUpdateStatus = (status: 1 | 2) => {
        changeIsStatusChanging(true);
        onUpdateStatus(row.id, status).finally(() => changeIsStatusChanging(false));
      };

      const handleStatusToggle = (isActive: boolean) => {
        if (
          isActive &&
          (row.status === CampaignAgentStatus.AGENT_DRAFT_STATUS ||
            row.status === CampaignAgentStatus.AGENT_PAUSED_STATUS)
        ) {
          handleUpdateStatus(CampaignAgentStatus.AGENT_LAUNCHED_STATUS);
        } else if (!isActive && row.status === CampaignAgentStatus.AGENT_LAUNCHED_STATUS) {
          handleUpdateStatus(CampaignAgentStatus.AGENT_PAUSED_STATUS);
        }
      };

      return (
        <div>
          <ColoredStatusToggler
            id={row.id}
            color={agentStatusColor[row.status]}
            isActive={row.status === CampaignAgentStatus.AGENT_LAUNCHED_STATUS}
            onChange={handleStatusToggle}
            isLoading={isStatusChanging}
            title={agentStatusToStringMapper[row.status]}
            status={row.status}
            titleColor={agentStatusTextColor[row.status]}
            iconColor={agentStatusIconColor[row.status]}
            iconSize={null}
          />
        </div>
      );
    },
  },
  {
    dataKey: 'campaigns',
    colspan: 2,
    RenderTitle: (): JSX.Element => {
      return (
        <div>
          <span data-for="opportunity-column-title" data-tip="">
            Campaigns <SVGIcon icon="questionInCircleFilled" size={12} />
            <ReactTooltip
              id="opportunity-column-title"
              place="right"
              effect="solid"
              arrowColor="transparent"
            >
              <div>
                An opportunity is a potential outreach target,
                <br /> such as a website, which may include several contacts.
              </div>
            </ReactTooltip>
          </span>
        </div>
      );
    },
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="total-cell">
        {row.campaignsTotal}
        <span className="subtitle-cell">Total</span>
      </div>
    ),
  },
  {
    dataKey: 'opportunity',
    colspan: 2,
    RenderTitle: (): JSX.Element => {
      return (
        <div>
          <span data-for="opportunity-column-title" data-tip="">
            Opportunities <SVGIcon icon="questionInCircleFilled" size={12} />
            <ReactTooltip
              id="opportunity-column-title"
              place="right"
              effect="solid"
              arrowColor="transparent"
            >
              <div>
                An opportunity is a potential outreach target,
                <br /> such as a website, which may include several contacts.
              </div>
            </ReactTooltip>
          </span>
        </div>
      );
    },
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="total-cell">
        {row.campaignsLaunched}
        <span className="subtitle-cell">Launched</span>
      </div>
    ),
  },
  {
    dataKey: 'email',
    colspan: 2,
    RenderTitle: (): JSX.Element => {
      return (
        <div>
          <span data-for="opportunity-column-title" data-tip="">
            Emails <SVGIcon icon="questionInCircleFilled" size={12} />
            <ReactTooltip
              id="opportunity-column-title"
              place="right"
              effect="solid"
              arrowColor="transparent"
            >
              <div>
                An opportunity is a potential outreach target,
                <br /> such as a website, which may include several contacts.
              </div>
            </ReactTooltip>
          </span>
        </div>
      );
    },
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="total-cell">
        {row.opportunitiesTotal}
        <span className="subtitle-cell">Total</span>
      </div>
    ),
  },
  {
    title: '',
    isHidden: true,
    dataKey: 'contacted',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="launched-cell">
        {row.opportunitiesContacted}
        <span className="subtitle-cell">Contacted</span>
      </div>
    ),
  },
  {
    title: '',
    isHidden: true,
    dataKey: 'send',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="launched-cell">
        {row.emailsSent}
        <span className="subtitle-cell">Sent</span>
      </div>
    ),
  },
  {
    title: '',
    isHidden: true,
    dataKey: 'replies',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => (
      <div className="scheduled-cell">
        {row.emailsReplied}
        <span className="subtitle-cell">Replies received</span>
      </div>
    ),
  },
  {
    title: 'Created on',
    dataKey: 'created',
    RenderCell: ({ row }: TableRenderProps): JSX.Element => {
      const { members: workspaceMembers } = useWorkspaceMembers();

      const fullName = () => {
        if (workspaceMembers == null || workspaceMembers.length <= 0) {
          return '-';
        }
        const user = workspaceMembers.find((it) => it.userId === row.createdBy
        );
        return user?.fullName || 'Deleted User';
      };

      return (
        <div className="created_on-cell">
          <TimestampToDate timestamp={row.createdAt} />
          <p>by {fullName()}</p>
        </div>
      )
    }
  },
  {
    title: '',
    dataKey: 'actions',
    RenderCell: ({
      row,
      renderProps: { onRemove, onEdit },
    }: TableRenderProps): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'editAction',
          title: 'Edit',
          handler: () => onEdit(row),
        },
      ];

      return (
        <ActionsMenu
          actionsConfig={actionsConfig}
          onRemove={() => onRemove(row.id)}
          removeActionTitle="Remove"
          id={`campaign-actions-${row.id}`}
          tooltipPlace="left"
        />
      );
    },
  },
];

export function RenderRow({
  children,
  row,
  renderProps: { onRowClick },
}: TableRenderProps): JSX.Element {
  const handleClick = () => onRowClick(row.id, row.status);

  return (
    <Tr onClick={handleClick} className="campaigns-table__row">
      {children}
    </Tr>
  );
}
