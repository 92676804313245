import { getAiInspectionPresetApi } from '@api/inspectionAutomation.api';
import { setInspectionAutomationData } from '@redux/actions/automationInspection.actions';
import {
  InspectionAutomationSettings,
  InspectionAutomationType,
} from '@ts/automationInspection.types';

export const getAiInspectionPresets =
  (workspaceId: number, pipelineId: number, campaignId: number) =>
  (dispatch): Promise<any> => {
    getAiInspectionPresetApi(campaignId, workspaceId, pipelineId).then(
      (response: InspectionAutomationType) => {
        dispatch(
          setInspectionAutomationData({
            model: response.settings.model,
            instructions: response.settings.instructionList.map((it) => it.value),
            includeSummaries: response.settings.includeSummaries,
          } as InspectionAutomationSettings)
        );
      }
    );
    return Promise.resolve();
  };
