import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import urls, { urlPrefixWithoutWorkspace } from '@constants/urls';

import useAppUpdates from '@hooks/useAppUpdates';
import useIntercom from '@hooks/useIntercom';

import NotificationsSidebar from '@components/NotificationsSidebar/NotificationsSidebar';
import WorkspacesSidebar from '@components/WorkspacesSidebar/WorkspacesSidebar';
import Dashboard from '@components/Campaigns/Dashboard/Dashboard';

import CampaignTemplatesPage from '@pages/CampaignTemplatesPage/CampaignTemplatesPage';
import MaintenancePage from '@pages/MaintenancePage/MaintenancePage';
import BackdoorLoginPage from '@pages/BackdoorLoginPage/BackdoorLoginPage';
import CampaignAnalyticsPage from '@pages/CampaignAnalyticsPage/CampaignAnalyticsPage';
import CampaignSettingsPage from '@pages/CampaignSettingsPage/CampaignSettingsPage';
import ToolboxPage from '@pages/ToolboxPage/ToolboxPage';
import CampaignsPage from '@pages/CampaignsPage/CampaignsPage';
import ExternalUnsubscribePage from '@pages/ExternalUnsubscribePage/ExternalUnsubscribePage';
import InboxPage from '@pages/InboxPage/InboxPage';
import InsightsPage from '@pages/InsightsPage/InsightsPage';
import LoginPage from '@pages/LoginPage/LoginPage';
import RelationshipsPage from '@pages/RelationshipsPage/RelationshipsPage';
import SettingsPage from '@pages/SettingPage/SettingsPage';
import UnsubscribeConfirmationPage from '@pages/UnsubscribeConfirmationPage/UnsubscribeConfirmationPage';
import OnboardingCallPopup from '@components/CalendlyPopup/OnboardingCallPopup';
import { checkLoggedIn } from '@utils/loginDetector';
import withPageWrapper from '@hocs/withPageWrapper';
import OnboardingPage from '@pages/OnboardingPage/OnboardingPage';
import IntegrationBillingFailedPage from '@pages/IntegrationBillingFailedPage/IntegrationBillingFailedPage';

import { redirectUserTo } from '@utils/historyHandler';
import { getAccessToken, getTokenInfo } from '@api/semrush/integration.api';
import { BillingState } from 'respona_api/generated/billing_pb';
import { DispatchType } from 'src/store';
import { BillingType } from '@ts/billing.types';
import { fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { useQuery } from 'react-query';
import { fetchUserProfile } from '@redux/thunks/userProfileRequests';
import { activateSemrushSubscriptionApi, purchaseSemrushSubscriptionApi } from '@api/billing.api';
import Loader from '@uikit/Loader/Loader';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { workspacesSelector } from '@redux/selectors/workspaces.selectors';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';
import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';
import AiAgents from '@pages/AIAgentsPages/AiAgents/AiAgents';
import * as Sentry from '@sentry/react';
import { ApiError } from 'src/errors/ApiError';

declare const IS_STANDALONE_MODE: boolean;
declare const IS_DEV: boolean;
declare const SENTRY_DNS: string;

const SentryRoute = Sentry.withSentryRouting(Route);

function RouteListener() {
  const history = useHistory();
  const workspaceId = useCurrentWorkspaceId();

  useEffect(() => {
    if (workspaceId === null) {
      return;
    }

    const unlisten = history.listen((location) => {
      const { pathname } = location;
      const params = pathname.split('/');
      const startOfUrl = params[1];
      const hasOptionalWorkspace = params[2];
      const requiresWorkspace =
        urlPrefixWithoutWorkspace.find((item) => item === startOfUrl) === undefined;
      if (requiresWorkspace && (!hasOptionalWorkspace || isNaN(hasOptionalWorkspace))) {
        redirectUserTo(history, `/workspaces/${workspaceId}${pathname}`);
      }
    });

    return () => {
      unlisten();
    };
  }, [history, workspaceId]);

  return null;
}

export default (): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const history = useHistory();
  const location = useLocation();
  const [loginStatus, setLoginStatus] = useState<boolean | null>(null);
  const [activeBillingStatus, setActiveBillingStatus] = useState<boolean | null>(null);
  const { workspaces, loadingStatus } = useSelector(workspacesSelector);
  const [isNoAccess, setIsNoAccess] = useState<boolean>(false);
  const workspaceId = useCurrentWorkspaceId();

  useEffect(() => {
    if (IS_STANDALONE_MODE && !IS_DEV && SENTRY_DNS) {
      Sentry.init({
        dsn: SENTRY_DNS,
        beforeSend(event, pipe) {
          if (pipe?.originalException instanceof ApiError) {
            return null;
          }
          return event;
        },
        integrations: [
          Sentry.reactRouterV5BrowserTracingIntegration({
            history,
          }),
          Sentry.replayIntegration(),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        // ignoreErrors: ['ResizeObserver loop limit exceeded'],
        // tracesSampleRate: 1.0,
      });
    }
  }, []);

  useEffect(() => {
    const params = history.location.pathname.split('/');
    const startOfUrl = params[1];

    if (workspaces?.length === 0) return;

    if (urlPrefixWithoutWorkspace.includes(startOfUrl)) {
      setIsNoAccess(false);
      return;
    }

    if (checkIsAnyNotLoaded(loadingStatus)) {
      setIsNoAccess(false);
      return;
    }

    if (startOfUrl !== 'workspaces') {
      setIsNoAccess(false);
      return;
    }

    const workspaceIdFromUrl = parseInt(params[2], 10);
    const hasAccess = workspaces.some((workspace) => workspace.id === workspaceIdFromUrl);
    setIsNoAccess(!hasAccess);
  }, [history.location.pathname, loadingStatus, workspaces, workspaceId]);

  const {
    isSuccess: isUserSuccess,
    data: userProfile,
    refetch: refetchUserProfile,
  } = useQuery('userProfile', () => fetchUserProfile(dispatch), {
    enabled: false,
    retry: false,
    staleTime: 2 * (60 * 1000),
  });

  const updateBillingForIntegrationStatus = useCallback(
    async (billing: BillingType, firstRequest = true) => {
      const token = await getTokenInfo();
      const isActivePayment = token.is_main_product_active;
      const isActiveTrial =
        token.extra_user_data != null ? token.extra_user_data.is_main_product_trial_active : false;

      const processSubscription = async (apiFunc: (token: string) => Promise<void>) => {
        try {
          const accessToken = await getAccessToken();
          await apiFunc(accessToken);
          const billingResponse = (await dispatch(fetchBillingDetails(false))) as BillingType;
          if (firstRequest) {
            checkIsActive(billingResponse, false);
          } else {
            setActiveBillingStatus(billingResponse?.active);
          }
        } catch {
          setActiveBillingStatus(false);
        }
      };

      const isActivation =
        isActivePayment === true &&
        isActiveTrial === false &&
        (billing.state === BillingState.BILLING_TRIAL || !billing.active);

      if (isActivation) {
        await processSubscription(activateSemrushSubscriptionApi);
        return;
      }
      const isPurchaseMaid =
        token.active_products.length !== billing.optSemrushActiveProductsList.length ||
        token.active_products.reduce((sum, current) => sum + current.value, 0) !==
          billing.optSemrushActiveProductsList.reduce((sum, current) => sum + current.value, 0);
      if (isPurchaseMaid) {
        await processSubscription(purchaseSemrushSubscriptionApi);
        return;
      }
      setActiveBillingStatus(isActivePayment);
    },
    [dispatch]
  );

  const checkIsActive = useCallback(
    async (billing: BillingType, firstRequest = true) => {
      if (IS_STANDALONE_MODE) {
        setActiveBillingStatus(billing?.active);
      } else {
        if (!isUserSuccess) refetchUserProfile();

        await updateBillingForIntegrationStatus(billing, firstRequest);
      }
    },
    [isUserSuccess, updateBillingForIntegrationStatus, refetchUserProfile]
  );

  const checkIsLoggedIn = useCallback(async () => {
    checkLoggedIn(history)
      .then((status) => {
        setLoginStatus(status);
        if (status) {
          dispatch(fetchBillingDetails(false)).then((billing: BillingType) =>
            checkIsActive(billing)
          );
        }
      })
      .catch((error) => {
        console.error('Error while checking login status', error);
        setLoginStatus(false);
      });
  }, [dispatch, checkIsActive]);

  useEffect(() => {
    if (activeBillingStatus === false) {
      redirectUserTo(history, urls.BILLING);
    }
  }, [activeBillingStatus]);

  if (IS_STANDALONE_MODE) {
    useIntercom();
    useAppUpdates();
  }

  useEffect(() => {
    if (userProfile == null && loginStatus === true) {
      setLoginStatus(null);
    }
    checkIsLoggedIn();
  }, [checkIsLoggedIn, userProfile]);

  const defaultRedirection = useMemo(() => {
    if (IS_STANDALONE_MODE) return urls.DASHBOARD;
    if (workspaceId > 0) return urls.ALL_CAMPAIGNS(workspaceId);
    return urls.ALL_EMAILS;
  }, [workspaceId]);

  return useMemo(() => {
    if (loginStatus === null) {
      return (
        <div>
          <Loader isLoading />
        </div>
      );
    }

    if (loginStatus === false) {
      if (IS_STANDALONE_MODE) {
        return (
          <Switch>
            <SentryRoute path="/unsubscribe" component={ExternalUnsubscribePage} />
            <SentryRoute path="/unsubscribed" component={UnsubscribeConfirmationPage} />
            <SentryRoute path="/maintenance" component={MaintenancePage} />

            <SentryRoute path="/bd/login" component={BackdoorLoginPage} />

            <SentryRoute path="/authorize/microsoft/error" component={MaintenancePage} />

            <SentryRoute path="/authorize/login?:token" component={LoginPage} />

            <SentryRoute path="*" component={LoginPage} />
            {/* <SentryRoute path="/forgot-password"> */}
            {/*  <ForgotPasswordPage /> */}
            {/* </SentryRoute> */}
            {/* <SentryRoute path="/restore"> */}
            {/*  <RestorePasswordPage /> */}
            {/* </SentryRoute> */}
            {/* <SentryRoute path="/register" component={RegisterPage} /> */}

            <SentryRoute path="/">
              <Redirect to={urls.LOGIN} />
            </SentryRoute>
          </Switch>
        );
      }
      return (
        <Switch>
          <SentryRoute path="/maintenance" component={MaintenancePage} />
          <SentryRoute path="/unsubscribe" component={ExternalUnsubscribePage} />
          <SentryRoute path="/unsubscribed" component={UnsubscribeConfirmationPage} />
          <SentryRoute
            path="*"
            render={() => (
              <MaintenancePage
                errorTitle="Invalid login credentials"
                errorText="Please, reload the page and try again."
              />
            )}
          />

          <SentryRoute path="/">
            <Redirect to={urls.LOGIN} />
          </SentryRoute>
        </Switch>
      );
    }

    if (activeBillingStatus === null) {
      return (
        <div>
          <Loader isLoading />
        </div>
      );
    }

    if (!activeBillingStatus) {
      if (IS_STANDALONE_MODE) {
        return (
          <>
            <RouteListener />
            <Switch>
              <SentryRoute path="/unsubscribe" component={ExternalUnsubscribePage} />
              <SentryRoute path="/unsubscribed" component={UnsubscribeConfirmationPage} />
              <SentryRoute path="/maintenance" component={MaintenancePage} />

              {/* <SentryRoute path="/onboarding/:onboardingPage?" component={OnboardingPage} /> */}

              <SentryRoute path="/trial" component={LoginPage} />

              <SentryRoute
                path="/settings/:settingsPage?"
                component={withPageWrapper(SettingsPage)}
              />
              <SentryRoute path="*">
                {(() => {
                  return <Redirect to={urls.BILLING} />;
                })()}
              </SentryRoute>
            </Switch>
            <WorkspacesSidebar />
            <NotificationsSidebar />
          </>
        );
      }

      return (
        <>
          <RouteListener />
          <Switch>
            <SentryRoute path="/maintenance" component={MaintenancePage} />
            <SentryRoute path="/unsubscribe" component={ExternalUnsubscribePage} />
            <SentryRoute path="/unsubscribed" component={UnsubscribeConfirmationPage} />
            <SentryRoute path="*" component={IntegrationBillingFailedPage} />

            <SentryRoute path="/dashboard/:section?" component={withPageWrapper(Dashboard)} />

            <SentryRoute path="/">
              {(() => {
                return <Redirect to={urls.BILLING} />;
              })()}
            </SentryRoute>
          </Switch>
          <WorkspacesSidebar />
          <NotificationsSidebar />
        </>
      );
    }

    if (isNoAccess) {
      return <NoAccessMessage showButton />;
    }

    return (
      <>
        <RouteListener />
        <Switch>
          <SentryRoute path="/unsubscribe" component={ExternalUnsubscribePage} />
          <SentryRoute path="/unsubscribed" component={UnsubscribeConfirmationPage} />
          <SentryRoute path="/maintenance" component={MaintenancePage} />

          <SentryRoute path="/onboarding/:onboardingPage?" component={OnboardingPage} />
          <SentryRoute
            path="/workspaces/:workspaceId?/inbox/:inboxType/:inboxPath(threads/:inboxId?)?"
            component={withPageWrapper(InboxPage)}
          />
          <SentryRoute path="/toolbox/:toolboxPage" component={withPageWrapper(ToolboxPage)} />

          <SentryRoute path="/dashboard/:section?" component={withPageWrapper(Dashboard)} />

          <SentryRoute
            path="/workspaces/:workspaceId?/campaign-templates/:templateId?"
            component={withPageWrapper(CampaignTemplatesPage)}
          />

          <SentryRoute
            path="/workspaces/:workspaceId?/campaigns/"
            component={withPageWrapper(CampaignsPage)}
          />

          <SentryRoute
            path="/workspaces/:workspaceId?/campaigns/:campaignsType?"
            component={withPageWrapper(CampaignsPage)}
          />
          <SentryRoute
            path="/workspaces/:workspaceId?/campaign-builder/:campaignId/:step?"
            component={withPageWrapper(CampaignSettingsPage, false)}
          />
          <SentryRoute
            path="/workspaces/:workspaceId?/campaign-analytics/:campaignId"
            component={withPageWrapper(CampaignAnalyticsPage)}
          />
          <SentryRoute
            path="/workspaces/:workspaceId?/relationships/:relationshipsPage?"
            component={withPageWrapper(RelationshipsPage)}
          />
          <SentryRoute path="/settings/:settingsPage?" component={withPageWrapper(SettingsPage)} />
          <SentryRoute
            path="/workspaces/:workspaceId?/insights/:insightsPage?"
            component={withPageWrapper(InsightsPage)}
          />

          {IS_DEV && IS_STANDALONE_MODE && (
            <SentryRoute
              path="/workspaces/:workspaceId?/ai-agents/:path(:agentId/:page)?"
              component={withPageWrapper(AiAgents)}
            />
          )}

          <SentryRoute path="*">
            {(() => {
              return <Redirect to={defaultRedirection} />;
            })()}
          </SentryRoute>

          <SentryRoute path="/">
            {(() => {
              return <Redirect to={defaultRedirection} />;
            })()}
          </SentryRoute>
        </Switch>
        <OnboardingCallPopup />
        <WorkspacesSidebar />
        <NotificationsSidebar />
      </>
    );
  }, [isNoAccess, loginStatus, activeBillingStatus, workspaceId, defaultRedirection]);
};
