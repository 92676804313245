import { CampaignShortcutType, CampaignType } from '@ts/campaigns.types';
import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TagType } from '@ts/common.types';
import { WorkspaceMemberShortType } from '@ts/workspace.types';

import { getFirstNamePlusBigFirstCharOfLastName } from '@utils/string.utils';

import {
  companiesFiltersSelector,
  companiesFoldersSelector,
} from '@redux/selectors/companies.selectors';

import Display from '@components/Display/Display';
import MoreActionsButton from '@components/Relationships/OrganizationsHeaderActions/MoreActionsButton/MoreActionsButton';
import Button from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import withSmallScreensCheck from '@hocs/withSmallScreensCheck';

import { getWebsiteFilterFields } from '@constants/filters/websiteFilterFields';
import { setCompaniesFilters, throwCompaniesFilters } from '@redux/actions/companies.actions';

import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import RelationshipsFiltersTooltipContent from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContent';
import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';

import './OrganizationsHeaderActions.scss';
import { OnAddToCampaignFunctionType } from '@components/Relationships/PeopleHeaderActions/MoreActionsButton/MoreActionsButton';
import { WorkspaceShortType } from '@ts/userOrganization.types';
import { initialRelationshipWebsiteFilterRawState } from '@redux/reducers/companies.reducer';

type OrganizationsHeaderActionsPropsType = {
  selectedIds: number[];
  onCreateCompany: () => void;
  onOpenRemoveCompany: () => void;
  onOpenExportCompany: () => void;
  onCreateOrMoveFolder: (folderId?: number) => void;
  searchString: string;
  onChangeSearchString: (string) => void;
  members?: WorkspaceMemberShortType[];
  workspaces?: WorkspaceShortType[];
  tags?: TagType[];
  campaignsList?: CampaignShortcutType[];
  onAddToCampaign: OnAddToCampaignFunctionType;
};

function OrganizationsHeaderActions({
  selectedIds = [],
  onCreateCompany,
  onOpenRemoveCompany,
  onOpenExportCompany,
  onCreateOrMoveFolder,
  searchString,
  onChangeSearchString,
  isSmallScreen,
  members,
  workspaces,
  tags,
  campaignsList,
  onAddToCampaign,
}: OrganizationsHeaderActionsPropsType & { isSmallScreen: boolean }): JSX.Element {
  const dispatch = useDispatch();

  const { items: companiesFolders } = useSelector(companiesFoldersSelector);
  const peopleCompanyFiltersFromRedux = useSelector(companiesFiltersSelector);

  const checkFilterValues = useCallback(
    (filtersArray) => filtersArray.some(({ value }) => value.toString()?.length > 0),
    []
  );
  const areFiltersEnabled = useMemo(
    () => checkFilterValues(Object.values(peopleCompanyFiltersFromRedux)),
    [peopleCompanyFiltersFromRedux]
  );

  const handleApplyPeopleCompanyFilters = useCallback(
    (newFiltersObject) => dispatch(setCompaniesFilters(newFiltersObject)),
    []
  );

  const throwRelationshipsCompaniesFilters = () => dispatch(throwCompaniesFilters());

  return (
    <div className="organizations-header-actions">
      <Display isVisible={!selectedIds.length}>
        <HeaderSearchInput
          onUpdateSearchQuery={onChangeSearchString}
          searchQuery={searchString}
          placeholder="Search websites"
        />
      </Display>

      <Display isVisible={selectedIds.length > 0}>
        <MoreActionsButton
          onCreateOrMoveFolder={onCreateOrMoveFolder}
          folders={companiesFolders}
          isSmallScreen={isSmallScreen}
          onAddToCampaign={onAddToCampaign}
        />
        <Button
          onClick={onOpenRemoveCompany}
          type="alert"
          className="organizations-header-actions__remove-btn"
        >
          <SVGIcon icon="trashWhite" />
          {isSmallScreen ? '' : 'Remove'}
        </Button>
        <div className="organizations-header-actions__separator" />
        <Button
          onClick={onOpenExportCompany}
          className="organizations-header-actions__export-users-btn"
          type="additional"
          size="xl"
        >
          <SVGIcon icon="download" />
          {isSmallScreen ? '' : <span>Export</span>}
        </Button>
      </Display>
      <HeaderFiltersButtonWithTooltip
        areFiltersEnabled={areFiltersEnabled}
        onClearAllFilters={throwRelationshipsCompaniesFilters}
      >
        <RelationshipsFiltersTooltipContent
          onApplyFilters={handleApplyPeopleCompanyFilters}
          filterFields={getWebsiteFilterFields(
            members.map((item) => ({
              value: item.userId,
              label: getFirstNamePlusBigFirstCharOfLastName(item.fullName),
            })),
            tags.map((item) => ({
              value: item.id,
              label: item.title,
            })),
            campaignsList?.map((item: CampaignType) => ({
              value: item.id,
              label: item.title,
            })),
            workspaces?.map((item: WorkspaceShortType) => ({
              value: item.id,
              label: item.title,
            }))
          )}
          filtersFromRedux={peopleCompanyFiltersFromRedux}
          initialFilterState={initialRelationshipWebsiteFilterRawState}
        />
      </HeaderFiltersButtonWithTooltip>

      {/* We comment it because, we delete it temporary */}

      {/* <Button */}
      {/*  onClick={onCreateCompany} */}
      {/*  className="organizations-header-actions__add-user-btn" */}
      {/*  size="l" */}
      {/* > */}
      {/*  <SVGIcon icon="addCompany" /> */}
      {/* </Button> */}
    </div>
  );
}

export default withSmallScreensCheck<OrganizationsHeaderActionsPropsType>(
  OrganizationsHeaderActions
);
