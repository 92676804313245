import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';

import { getDateShort } from '@utils/date';

import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import SidebarCRMSection from '@components/SidebarCRM/sections/SidebarCRMSection/SidebarCRMSection';
import SidebarCRMSectionEmailsPreview from '@components/SidebarCRM/sections/SidebarCRMSectionEmails/_components/SidebarCRMSectionEmailsPreview';

import {RelatedPreviewResponseType} from '@ts/mailboxInbox.types';

import './SidebarCRMSectionEmails.scss';

const EmailTooltipContent = ({
  threadUid,
  lastActivityTime,
  fromEmail,
  subject,
  fromName,
}): JSX.Element => {
  const [isEmailPreviewLoaded, setEmailPreviewLoaded] = useState<boolean>(false);

  return (
    <>
      <div
        onClickCapture={() => {
          setEmailPreviewLoaded(true);
          ReactTooltip.hide();
        }}
        className="contacts-sidebar-section-emails__email"
        data-for={`contacts-sidebar-section-emails-${threadUid}`}
        data-tip=""
      >
        <div className="contacts-sidebar-section-emails__email-date">
          {lastActivityTime > 0 ? getDateShort(new Date(lastActivityTime)) : null}
        </div>
        <div className="contacts-sidebar-section-emails__email-title">
          <SVGIcon icon="email" color="#221CB6" size={20} />
          <span>{fromEmail}</span>
        </div>
        <div className="contacts-sidebar-section-emails__email-text">{subject}</div>
      </div>
      <ReactTooltip
        arrowColor="#ffffff"
        id={`contacts-sidebar-section-emails-${threadUid}`}
        className="react-tooltip contacts-sidebar-section-emails__email-tooltip"
        place="left"
        effect="solid"
        event="click"
        globalEventOff="click"
        clickable
      >
        {isEmailPreviewLoaded ? (
          <SidebarCRMSectionEmailsPreview
            threadUid={threadUid}
            fromName={fromName}
            fromEmail={fromEmail}
            subject={subject}
          />
        ) : null}
      </ReactTooltip>
    </>
  );
};

interface SidebarCRMSectionEmailsProps {
  queryKey: string;
  index: number;
  itemId: number;
  title: string;
  getEmails: (itemId: number) => Promise<RelatedPreviewResponseType[]>;
}

const SidebarCRMSectionEmails: React.FC<SidebarCRMSectionEmailsProps> = ({
  queryKey,
  index,
  itemId,
  title = 'Conversation history',
  getEmails,
}): JSX.Element => {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { data, isLoading } = useQuery<RelatedPreviewResponseType[]>(
    [queryKey, itemId],
    () => getEmails(itemId),
    {
      enabled: isExpanded,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (data?.length) {
      ref.current.setOpen(true);
    }
  }, [data]);

  const emailsList = useMemo(() => {
    return data?.slice(0, isExpanded ? undefined : 5)?.map((email) => (
      <React.Fragment key={email.threadUid}>
        <EmailTooltipContent {...email} />
      </React.Fragment>
    ));
  }, [data, isExpanded]);

  return (
    <SidebarCRMSection
      ref={ref}
      index={index}
      id="emails"
      title={title}
      onOpen={() => {
        setIsExpanded(true);
      }}
    >
      <div className="contacts-sidebar-section-emails">
        <Loader isLoading={isLoading}>
          {emailsList?.length ? (
            emailsList
          ) : (
            <div className="contacts-sidebar-section-emails--empty">Empty</div>
          )}
          {!isExpanded && data?.length > 5 && (
            <div className="contact-sidebar-crm__show-more-btn" onClick={() => setIsExpanded(true)}>
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </Loader>
      </div>
    </SidebarCRMSection>
  );
};

export default SidebarCRMSectionEmails;
