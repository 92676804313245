/**
 * NOT CHECKED
 */
import React, { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './CopyIcon.scss';

export default ({
  text,
  size,
  icon = 'copy',
}: {
  text: string;
  size?: number;
  icon?: SvgIconType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const handleCopyClick = useCallback(() => {
    copy(text);
    dispatch(addNotification({ title: 'Copied to clipboard', type: 'info', delay: 1000 }));
  }, [text]);

  return (
    <span onClick={handleCopyClick} className="copy-icon">
      <SVGIcon icon={icon} size={size} />
    </span>
  );
};
