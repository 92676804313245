// @ts-nocheck
import { addNotification } from '@redux/actions/notifications.actions';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import debounce from '@utils/debounce';

import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';

import Loader from '@uikit/Loader/Loader';
import SlateEditor from '@uikit/RichEditor/SlateEditor';
import InlineSlateEditor from '@uikit/RichEditor/InlineSlateEditor';
import EditorEmailMeta from '@components/EditorEmailMeta/EditorEmailMeta';
import { ScheduleType } from '@ts/schedules.types';
import AutosaveIndicator from '@uikit/AutosaveIndicator/AutosaveIndicator';

import { getVariableByValue, VariableType } from '@constants/variables';

import Display from '@components/Display/Display';
import { OpenCampaignType } from '@ts/campaigns.types';
import {
  removePersonalizationRequest,
  uploadPersonalizationFile,
} from '@redux/thunks/personalization.thunks';

import './PersonalizationEditor.scss';

import { PersonBaseType } from '@ts/people.types';
import { ReferralLinkType } from '@ts/user.types';
import { OpportunityShortType } from '@ts/opportunity.types';
import ModerateChance from '@components/ModerateChance/ModerateChance';
import stripHtml from '@utils/stripHtml';
import { OpportunityPersonContactType } from '@ts/contact.types';

const AUTO_SAVING_DELAY_MS = 1000;

type SequenceEditorPropsType = {
  step: any;
  subjects: string[];
  stepIndex?: number;
  onSave: (any) => void;
  onUpdateMeta: (any) => void;
  contacts?: OpportunityPersonContactType[];
  opportunity: any;
  selectedContact: any;
  onChangeSelectedContact: (contact: OpportunityPersonContactType) => void;
  personDetails: PersonBaseType;
  onTestPersonalization: (any) => void;
  campaignSchedule: ScheduleType;
  senderMailboxId: number;
  campaignInfo: OpenCampaignType;
  customVariablesValues: any[];
  setRelatedOpportunityPersonalized: (fields: Partial<OpportunityShortType>) => void;
  referralContent?: ReferralLinkType;
  isLoading?: boolean;
};

const classHeader = 'personalization-editor__header';
const classEditor = '.personalization-editor__editor .slate-editor__editor .editable';

function PersonalizationEditor({
  step,
  subjects,
  stepIndex,
  onSave,
  onUpdateMeta,
  contacts,
  opportunity,
  selectedContact,
  onChangeSelectedContact,
  personDetails,
  onTestPersonalization,
  campaignSchedule,
  senderMailboxId,
  campaignInfo,
  customVariablesValues,
  setRelatedOpportunityPersonalized,
  referralContent,
  isLoading,
}: SequenceEditorPropsType): JSX.Element {
  const dispatch = useDispatch();
  const [subject, changeSubject] = useState('');
  const [parsedSubject, changeParsedSubject] = useState('');
  const [template, changeTemplate] = useState('');
  const [parsedTemplate, changeParsedTemplate] = useState('');
  const [isAutosavingProgress, changeIsAutosavingProgress] = useState(false);
  const { variables: editorVariables } = useAllVariablesInEditor();
  // getCustomVariableValuesApi need to use to take values of custom variables for concrete person and
  // if we got for custom variable two values -> we need to choose opportuniti's first

  const fileMutation = useMutation((file: File): Promise<any> => {
    return dispatch(
      uploadPersonalizationFile(
        campaignInfo.workspaceId,
        opportunity.id,
        step,
        campaignInfo.lastSequenceId,
        campaignInfo.id,
        file
      )
    ).then(() => setRelatedOpportunityPersonalized({ edited: true }));
  });

  // eslint-disable-next-line no-underscore-dangle
  const _onSave = useRef(
    debounce((sid, curSubject, curTemplate, attachmentsList) => {
      onSave({
        ...step,
        id: sid,
        subject: curSubject,
        content: curTemplate,
        attachmentsList,
      });
      changeIsAutosavingProgress(false);
    }, AUTO_SAVING_DELAY_MS)
  ).current;

  const prepareStringBasedOnHTML = (selector) => {
    const querySelector = document.querySelector(selector);

    if (!querySelector) {
      return '';
    }

    return (
      stripHtml(querySelector.outerHTML)
        // editor includes some special characters which must be removed
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .split(' ')
        .filter((word) => word)
        .join(' ')
    );
  };

  useEffect(() => {
    changeParsedSubject(prepareStringBasedOnHTML(`.${classHeader}`));
    changeParsedTemplate(
      prepareStringBasedOnHTML(classEditor)
    );
  }, [customVariablesValues]);

  useEffect(() => {
    changeSubject(step.subject);
    changeParsedSubject(prepareStringBasedOnHTML(`.${classHeader}`));
  }, [step.subject]);

  useEffect(() => {
    changeTemplate(step.content);
    changeParsedTemplate(
      prepareStringBasedOnHTML(classEditor)
    );
  }, [step.content]);

  const handleChangeSubject = (value) => {
    changeSubject(value);
    changeIsAutosavingProgress(true);
    _onSave(step.id, value, template, step.attachmentsList);
  };

  const handleChangeTemplate = (htmlTemplate) => {
    if (template !== htmlTemplate) {
      changeTemplate(htmlTemplate);
      changeIsAutosavingProgress(true);
      _onSave(step.id, subject, htmlTemplate, step.attachmentsList);
    }
  };

  const handleFileUpload = (files: File[]) => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    const readableFileSizeMB = Math.floor(totalSize / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    fileMutation.mutate(files[0]);
  };

  const handleRemoveFile = (fileKey) => {
    onSave({
      ...step,
      attachmentsList: step.attachmentsList.filter(({ key }) => key !== fileKey),
    });
  };

  const handleRevertPersonalization = () => {
    const { id: opportunityId } = opportunity;
    const personalizationId = step.id;

    dispatch(
      removePersonalizationRequest(
        personalizationId,
        opportunityId,
        campaignInfo.id,
        campaignInfo.workspaceId
      )
    ).then((updatedOpportunity) => {
      setRelatedOpportunityPersonalized({ edited: false, stepsList: updatedOpportunity.stepsList });
    });
  };

  const transformEditorVariable = useCallback(
    (variable: string, fallback: string, allVariables: VariableType[]): string => {
      const { transform } = getVariableByValue(variable, allVariables);
      const { transform: transformDefault } = getVariableByValue(variable);
      if ((transform || transformDefault) && customVariablesValues) {
        return (transform || transformDefault)(
          fallback,
          personDetails,
          opportunity,
          step,
          campaignSchedule,
          customVariablesValues
        );
      }

      if (
        customVariablesValues?.findIndex((item) => item.variableName === variable.toUpperCase()) >=
        0
      ) {
        return customVariablesValues.find((item) => item.variableName === variable.toUpperCase())
          .value;
      }

      return fallback && fallback.length ? `${variable} | ${fallback}` : `${variable}`;
    },
    [customVariablesValues, opportunity]
  );

  const transformEditorLink = (url: string): string => {
    if (url.startsWith('@')) {
      const variable = url.substr(1, url.length);

      const { transformLink } = getVariableByValue(variable);

      return transformLink ? transformLink('', opportunity) : url;
    }

    return url;
  };

  // if the subject is absent for the current step
  // then try to take the first previous none-empty subject
  const getSubject = (sbj): string => {
    const currentSubject = stripHtml(sbj).trim();

    if (currentSubject) {
      return currentSubject;
    }

    for (let i = stepIndex - 1; i >= 0; i--) {
      const item = stripHtml(subjects[i]).trim();

      if (item) {
        return item;
      }
    }

    return '';
  };

  return (
    <div className="personalization-editor">
      <div className="personalization-editor__title">
        <span>
          Step {stepIndex + 1} - Day {step.delayFromFirstDay + 1}
          <Display isVisible={stepIndex !== 0}>
            <span className="personalization-editor__title-description">If no reply</span>
          </Display>
        </span>
        <div className="personalization-editor__autosave-label">
          <AutosaveIndicator isSaving={isAutosavingProgress} />
        </div>
        <ModerateChance
          id={`moderate-chance-tooltip-${step.stepId}`}
          subject={parsedSubject}
          template={parsedTemplate}
        />
      </div>

      <div className="personalization-editor__container">
        <div className={classHeader}>
          <Display isVisible={subject.trim() !== ''}>
            <InlineSlateEditor
              withEmoji={false}
              message={subject}
              onChange={handleChangeSubject}
              placeholder="Keep empty to send as a reply to your previous email"
              disableVariable
              transformVariable={transformEditorVariable}
              key={`${step.stepId}-${personDetails ? personDetails.id : 0}`}
              useMessageUpdate
              variables={editorVariables}
            />
          </Display>
        </div>

        <EditorEmailMeta
          key={selectedContact ? selectedContact.id : 0}
          contacts={contacts}
          step={step}
          onUpdateMeta={onUpdateMeta}
          selectedEmail={selectedContact?.contactEmail?.email}
          onChangeContact={onChangeSelectedContact}
        />
        <Loader isLoading={isLoading} withTopMargin backgroundColor="#FBFCFD">
          <div className="personalization-editor__editor">
            <SlateEditor
              removingText={`Step ${stepIndex + 1}`}
              key={`${step.stepId}-${personDetails ? personDetails.id : 0}`}
              message={step.content}
              onAction={() => {
                onTestPersonalization(step);
              }}
              actionIcon="email"
              actionText="Preview"
              onChange={handleChangeTemplate}
              transformVariable={transformEditorVariable}
              transformLinkUrl={transformEditorLink}
              disableVariable
              onRevert={step.id === -1 ? null : handleRevertPersonalization}
              onFileUpload={handleFileUpload}
              attachments={step.attachmentsList}
              // signature={senderMailbox?.signature}
              isAttachmentsLoading={fileMutation.isLoading}
              onRemoveFile={handleRemoveFile}
              variables={editorVariables}
              disableVarsInLink
              allowFullFallbackEditing
              showUnsubscribeContent={step?.enabledUnsubscribeContent}
              referralContent={step.enabledReferralContent ? referralContent : null}
              showSignaturePlaceholder
              useMessageUpdate
            />
          </div>
        </Loader>
      </div>
    </div>
  );
}

export default PersonalizationEditor;
