import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';

import { SVGIcon } from '@uikit/Icon/Icon';

import './BillingDetailsCard.scss';
import { linkGoTo, redirectUserTo } from '@utils/historyHandler';

function BillingDetailsCard({
  cardNumber,
  contactEmail,
}: {
  cardNumber?: string;
  contactEmail: string;
}): JSX.Element {
  const history = useHistory();
  const redirectionUrl = '/settings/billing/change-billing';
  return (
    <div className="billing-details-card">
      <div className="billing-details-card__header">
        Billing details
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          to={linkGoTo(redirectionUrl)}
          className="billing-details-card__last-invoice-link"
          onClick={(event) => {
            redirectUserTo(history, redirectionUrl);
          }}
        >
          <TextWithIcon icon="refresh" iconColor="#3424EB">
            Update
          </TextWithIcon>
        </Link>
      </div>
      <div className="billing-details-card__body">
        <div className="billing-details-card__body-row">
          <div className="billing-details-card__body-label">Payment method</div>

          {cardNumber ? (
            <div className="billing-details-card__body-value">
              **** {cardNumber}&nbsp;
              <SVGIcon icon="creditCard" />
            </div>
          ) : (
            <div className="billing-details-card__body-value">-</div>
          )}
        </div>

        <div className="billing-details-card__body-row">
          <div className="billing-details-card__body-label">Accounting email</div>
          <div className="billing-details-card__body-value">{contactEmail}</div>
        </div>
      </div>
    </div>
  );
}

export default BillingDetailsCard;
