/**
 * NOT CHECKED
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { NotificationType } from '@ts/notifications.types';

import { notificationsSelector } from '@redux/selectors/notifications.selectors';

import Portal from '@uikit/Portal/Portal';

import NotificationBanner from './_components/NotificationBanner/NotificationBanner';

import './Notifications.scss';

const animatedClassNames = {
  enter: 'animationEnter',
  enterActive: 'animationEnterActive',
  exit: 'animationExit',
  exitActive: 'animationExitActive',
};

const Notifications = (): JSX.Element => {
  const nodeRef = React.useRef(null);

  const notificationList = useSelector(notificationsSelector);

  const persistentFirstSort = (a: NotificationType) => (a.dismissible === false ? -1 : 1);

  return (
    <Portal className="notifications">
      <TransitionGroup appear={false}>
        {notificationList
          .slice()
          .sort(persistentFirstSort)
          .map((notification) => (
            <CSSTransition
              nodeRef={nodeRef}
              key={notification.id}
              timeout={500}
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
              classNames={animatedClassNames}
            >
              <NotificationBanner {...notification} />
            </CSSTransition>
          ))}
      </TransitionGroup>
    </Portal>
  );
};

export default Notifications;
