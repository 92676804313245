import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeader from '@components/PageHeader/PageHeader';
import HeaderActions from '@components/HeaderTemplate/HeaderActions/HeaderActions';
import AiAgentsTable from '@components/AiAgents/AiAgentsTable/AiAgentsTable';
import Loader from '@uikit/Loader/Loader';

import { redirectUserTo } from '@utils/historyHandler';
import { getFirstNamePlusBigFirstCharOfLastName } from '@utils/string.utils';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import urls from '@constants/urls';
import { FilterJoins, FilterOperationsMapForString } from '@constants/filters/filterOperations';
import { aiAgentsListFilterFields } from '@constants/filters/aiAgentsListFilterFields';
import { FilterFieldType } from '@ts/filterField.type';

import { createCampaignAutomationApi } from '@api/campaignAutomation.api';
import {
  CampaignAutomationFilterField,
  CampaignAutomationFilterFieldMap,
} from 'respona_api/generated/automation-campaign_pb';

import './AiAgentsList.css';

const initialFilterState: Record<string, FilterFieldType<CampaignAutomationFilterFieldMap>> = {
  '1': {
    order: 0,
    field: CampaignAutomationFilterField.STRING_AGENT_TITLE,
    value: '',
    operation: FilterOperationsMapForString[2].value,
    join: FilterJoins[0].value,
  },
};

function AiAgentsList() {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { members, isFetching: isFetchingMembers } = useWorkspaceMembers();
  const [appliedFilters, setAppliedFilters] = useState<
    FilterFieldType<CampaignAutomationFilterFieldMap>[]
  >([]);

  function createNewAgent() {
    setIsCreating(true);
    createCampaignAutomationApi(currentWorkspaceId)
      .then((res) =>
        redirectUserTo(history, urls.AI_AGENTS_INSTRUCTIONS(currentWorkspaceId, res.id))
      )
      .finally(() => setIsCreating(false));
  }

  if (isFetchingMembers) {
    return <Loader isLoading />;
  }

  return (
    <div className="ai-agents-page">
      <div className="ai-agents-page__content">
        <PageHeader title="AI Agents" isFixed>
          <HeaderActions
            // selectedItems={selectedItems}
            handleRemoveItems={() => console.error('not implementer')}
            handleSearchValue={(value) => console.log(value)}
            // moreActions={[
            //   { title: 'test', handler: () => console.log('test 1 !!'), iconName: 'user' },
            //   { title: 'test 2', handler: () => console.log('test 2 !!'), iconName: 'folderWhite' },
            //   { title: 'test 3', handler: () => console.log('test 3 !!'), iconName: 'folderWhite' },
            // ]}
            initialFilterState={initialFilterState}
            filterFields={aiAgentsListFilterFields(
              members.map((item) => ({
                value: item.userId,
                label: getFirstNamePlusBigFirstCharOfLastName(item.fullName),
              }))
            )}
            appliedFilters={appliedFilters}
            onApplyFilters={(filters) => setAppliedFilters(filters)}
            addNewBtn={{
              title: 'Create new agent',
              handleClick: () => createNewAgent(),
            }}
          />
        </PageHeader>
        <div>
          <AiAgentsTable
            checkboxes={[]}
            setCheckboxes={() => {}}
            getRefetchCampaigns={() => {}}
            onCreateNewAgentClick={() => createNewAgent()}
            appliedFilters={appliedFilters}
            isLoading={isCreating}
          />
        </div>
      </div>
    </div>
  );
}

export default AiAgentsList;
