import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  IntegrationStatus as INTEGRATION_STATUS,
  IntegrationType as INTEGRATION_TYPE,
} from 'respona_api/generated/common_pb';

import { mapFromToToMinMax, mapMinMaxToFromTo } from '@helpers/commonHelpers';

import { setSemrushMetrics } from '@redux/actions/automationContent.actions';
import { semrushMetricsSelector } from '@redux/selectors/automationContent.selectors';
import { integrationsSelector } from '@redux/selectors/integrations.selectors';

import { Button } from '@uikit/Button/Button';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

import FromToRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromToRow/FromToRow';
import SemrushLogo from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/SemrushLogo';
import MetricsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsWrapper/MetricsWrapper';

import './SemrushMetrics.scss';
import FromRow from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/FromRow/FromRow';
import { redirectUserTo } from '@utils/historyHandler';

const SemrushMetrics: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { includePaidMetrics, domainAuthorityScore, domainMonthlyTraffic, domainOrganicKeywords } =
    useSelector(semrushMetricsSelector);
  const { integrations } = useSelector(integrationsSelector);

  const toggleIncludePaidMetrics = () => {
    dispatch(
      setSemrushMetrics({
        includePaidMetrics: !includePaidMetrics,
      })
    );
  };

  const isSemrushConnected = integrations.some(
    (item) =>
      item.type === INTEGRATION_TYPE.SEMRUSH_INTEGRATION &&
      item.status === INTEGRATION_STATUS.ACTIVE_INTEGRATION
  );

  return (
    <MetricsWrapper
      Logo={() => <SemrushLogo width={225} height={30} />}
      withAddOtherMetricsBtn={false}
    >
      <div className="semrush-metrics-paid">
        <FromToRow
          inputStyle={{ width: '159px' }}
          label={
            <>
              Domain authority score
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="Domain’s overall quality based on the number of backlinks, referring domains, organic search traffic, and other data. The max value is 100."
              />
            </>
          }
          disabled={!isSemrushConnected}
          onChange={(from, to) => {
            dispatch(
              setSemrushMetrics({
                domainAuthorityScore: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainAuthorityScore)}
        />

        <FromRow
          maxNumber={1000000}
          inputStyle={{ width: '318px' }}
          disabled={!isSemrushConnected}
          label={
            <>
              Min domain monthly traffic
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="The estimated number of monthly visits the website gets from organic search."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setSemrushMetrics({
                domainMonthlyTraffic: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainMonthlyTraffic)}
        />

        <FromRow
          maxNumber={1000000}
          inputStyle={{ width: '318px' }}
          disabled={!isSemrushConnected}
          label={
            <>
              Min domain organic keywords
              <QuestionTooltip
                color="#C6C6C6"
                style={{ marginLeft: '7px' }}
                text="The total number of keywords the website ranks for in the top 100 organic search results."
              />
            </>
          }
          onChange={(from, to) => {
            dispatch(
              setSemrushMetrics({
                domainOrganicKeywords: mapFromToToMinMax({ from, to }),
              })
            );
          }}
          {...mapMinMaxToFromTo(domainOrganicKeywords)}
        />

        {!includePaidMetrics || !isSemrushConnected ? (
          <div className="semrush-metrics-include-paid">
            {!isSemrushConnected ? (
              <>
                <span>Connect your Semrush account to view more metrics.</span>
                <Button onClick={() => redirectUserTo(history, '/settings/integrations')}>
                  Connect Semrush
                </Button>
              </>
            ) : (
              <>
                <span>Use your Semrush account to add more filters</span>
                <Button onClick={toggleIncludePaidMetrics}>Add filters</Button>
              </>
            )}
          </div>
        ) : (
          <Button
            className="semrush-metrics-remove-filters"
            onClick={toggleIncludePaidMetrics}
            type="ghost"
            size="m"
          >
            Remove filters
          </Button>
        )}
      </div>
    </MetricsWrapper>
  );
};

export default SemrushMetrics;
