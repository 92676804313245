import React, { useMemo, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Input from '@uikit/Input/Input';
import Button from '@uikit/Button/Button';

import { changeCurrentWorkspace, createWorkspace } from '@redux/actions/workspaces.actions';

import urls from '@constants/urls';

import useHotkeys from '@hooks/useHotkeys';

import './StepCreateWorkspace.scss';
import useWorkspaces from '@hooks/useWorkspaces';
import { createWorkspaceApi } from '@api/userOrganization.api';
import { redirectUserTo } from '@utils/historyHandler';
import { addNotification } from '@redux/actions/notifications.actions';
import createWorkspaceFieldsConfig from './fieldsConfig';

function StepCreateWorkspace({ nextStep }: { nextStep?: () => void }): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  const initialState = useMemo(() => {
    const temp = {};
    createWorkspaceFieldsConfig.forEach(({ key }) => {
      temp[key] = '';
    });

    return temp;
  }, [createWorkspaceFieldsConfig]);

  const [state, changeState] = useState(initialState);
  const [buttonLoading, changeButtonLoading] = useState(false);
  const { addItem } = useWorkspaces();

  const handleChange = ({ target: { value, name } }) =>
    changeState((prev) => ({ ...prev, [name]: value }));

  /*
    If workspace name input is empty - we focus it, otherwise
    we create a workspace and change location to /settings/workspaces
  */
  const handleClickToCreateWorkspace = (e?) => {
    if (e) {
      e.preventDefault();
    }

    const { key: title } = createWorkspaceFieldsConfig[0];
    const { key: optionalDomain } = createWorkspaceFieldsConfig[1];
    if (title == null || title.trim().length === 0) {
      // @ts-ignore
      formRef.current[key].focus();
      dispatch(addNotification({ title: `Please, enter the Workspace Name`, type: 'warning' }));
    } else {
      changeButtonLoading(true);
      return createWorkspaceApi(state[title], state[optionalDomain])
        .then((workspace) => {
          dispatch(createWorkspace(workspace));
          addItem(workspace);
          dispatch(changeCurrentWorkspace(workspace.id));
          redirectUserTo(history, urls.ALL_CAMPAIGNS(workspace.id));
          return workspace;
        })
        .finally(() => changeButtonLoading(false));
    }
  };

  useHotkeys({ Enter: handleClickToCreateWorkspace });

  return (
    <form ref={formRef} className="step-create-workspace">
      {createWorkspaceFieldsConfig.map(({ key, title, additional, maxLength }) => {
        return (
          <div key={key} className="step-create-workspace__row">
            <div className="step-create-workspace__labels">
              <div className="step-create-workspace__labels--main">{title}</div>
              <div className="step-create-workspace__labels--additional">{additional}</div>
            </div>
            <Input
              placeholder={`Write your ${title} here`}
              value={state[key]}
              isFullWidth
              isFullHeight
              maxLength={maxLength}
              name={key}
              className="step-create-workspace__input"
              onChange={handleChange}
            />
          </div>
        );
      })}
      <Button
        size="xl"
        className="step-create-workspace__button-continue"
        onClick={handleClickToCreateWorkspace}
        isLoading={buttonLoading}
        disabled={buttonLoading}
      >
        Create workspace
      </Button>
    </form>
  );
}

export default StepCreateWorkspace;
