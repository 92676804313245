/**
 * NOT CHECKED
 */
import React from 'react';
import ReactTooltip, { Place } from 'react-tooltip';
import { nanoid } from 'nanoid';

import { SVGIcon } from '@uikit/Icon/Icon';

import './QuestionHint.scss';

const QuestionHint = ({
  hintText,
  place = 'bottom',
  marginLeft = 0,
  marginRight = 0,
}: {
  hintText: string;
  place: Place;
  marginLeft?: number;
  marginRight?: number;
}): JSX.Element => {
  const id = nanoid();

  return (
    <>
      <span
        style={{ marginLeft: `${marginLeft}px`, marginRight: `${marginRight}px` }}
        className="question-hint"
        data-tip
        data-for={id}
      >
        <SVGIcon icon="questionInCircle" color="#BDBDBD" />
      </span>
      <ReactTooltip id={id} place={place} className="react-tooltip question-hint-tooltip" arrowColor="transparent">
        <div className="question-hint-tooltip__content">{hintText}</div>
      </ReactTooltip>
    </>
  );
};

export default QuestionHint;
