import React from 'react';

const KeywordsSearcgSvg = ({ size = 32 }: { size?: number }): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15105_2289)">
        <path
          d="M17.2467 5.92C17.2467 6.29333 17.06 6.35333 16.5933 6.35333C16.0933 6.35333 16 6.27333 15.9467 5.9C15.8533 4.93333 15.2067 4.12 14.1133 4.03333C13.78 4.00667 13.68 3.87333 13.68 3.44C13.68 3.04 13.7333 2.86 14.0533 2.86C14.4651 2.85027 14.8747 2.92197 15.2586 3.07099C15.6425 3.22002 15.9932 3.44342 16.2906 3.72838C16.5879 4.01333 16.8261 4.35423 16.9913 4.73147C17.1565 5.10872 17.2456 5.5149 17.2533 5.92667L17.2467 5.92ZM20 5.92C20 3.04 18.0533 0 13.5533 0H4.63334C4.26667 0 4.20001 0.32 4.52001 0.52C5.33334 1.05333 6.47334 1.66 7.48001 2.04667C7.90668 2.2 7.81334 2.58 7.39334 2.58H0.333342C-0.013325 2.58 -0.113325 2.87333 0.173342 3.13333C2.11939 4.89511 4.33384 6.33541 6.73334 7.4C7.16001 7.58667 7.07334 7.96667 6.65334 7.96667H3.48001C3.12001 7.96667 3.02667 8.26 3.32001 8.5C5.02668 9.78 9.00001 12 13.5533 12C18.1067 12 20 8.58 20 5.92ZM14.0533 10C12.9688 9.99648 11.9297 9.5641 11.1628 8.79721C10.3959 8.03032 9.96352 6.9912 9.96001 5.90667C9.97232 4.83159 10.4099 3.80514 11.1769 3.0518C11.944 2.29846 12.9782 1.87955 14.0533 1.88667C15.1292 1.88667 16.1611 2.31406 16.9218 3.07484C17.6826 3.83561 18.11 4.86744 18.11 5.94333C18.11 7.01923 17.6826 8.05106 16.9218 8.81183C16.1611 9.5726 15.1292 10 14.0533 10Z"
          fill="#FF642D"
        />
      </g>
      <defs>
        <clipPath id="clip0_15105_2289">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KeywordsSearcgSvg;
