import React, { useState } from 'react';

import AiAgentsSidebar from '@components/AiAgents/AiAgentsSidebar/AiAgentsSidebar';
import qs from 'query-string';

import './AiAgentActivityLog.css';
import Loader from '@uikit/Loader/Loader';
import AiAgentActivityLogTable from '@components/AiAgents/AiAgentActivityLogTable/AiAgentActivityLogTable';
import { CampaignAutomationDetailsType } from '@ts/automationCampaigns.types';

function AiAgentActivityLog({
  agent,
  match: {
    params: { paramsPage },
  },
  location: { search },
}: {
  agent?: CampaignAutomationDetailsType;
  match: { params: { paramsPage: string } };
  location: { search: string };
}): JSX.Element {
  const selectOptions = [
    { value: 'creditsUsed', label: 'Credits used' },
    { value: 'dateTime', label: 'Created by' },
  ];
  const [selectedValue, setSelectedValue] = useState(selectOptions[0]);
  const searchParams: { folderId?: string | undefined; id?: string } = qs.parse(search);

  return (
    <div className="ai-agents-page__content-activity-log">
      {!agent ? (
        <Loader isLoading />
      ) : (
        <>
          <AiAgentsSidebar searchParams={searchParams} activePage={paramsPage} campaignId={agent.id} />
          <div className="ai-agents-page__content-body">
            <AiAgentActivityLogTable sortingSelectedKey={selectedValue.value} agent={agent} />
          </div>
        </>
      )}
    </div>
  );
}

export default AiAgentActivityLog;
