import {
  PeopleWebsiteFilterField,
  PeopleWebsiteFilterFieldMap,
} from 'respona_api/generated/people-website_pb';
import { PeopleFilterField } from 'respona_api/generated/people_pb';

declare const IS_STANDALONE_MODE: boolean;

type FilterFieldType = {
  label: string;
  value: PeopleWebsiteFilterFieldMap[keyof PeopleWebsiteFilterFieldMap];
  type: 'string' | 'boolean' | 'number' | 'enum' | 'date' | 'enumString';
  inputOptions?: { value: string | number; label: string }[];
};

export const getWebsiteFilterFields = (
  members: { value: string | number; label: string }[] = [],
  tags: { value: string | number; label: string }[] = [],
  campaigns: { value: string | number; label: string }[] = [],
  workspaces: { value: string | number; label: string }[] = []
): FilterFieldType[] => {
  const filterFields: FilterFieldType[] = [
    {
      label: 'Title',
      type: 'string',
      value: PeopleWebsiteFilterField.STRING_TITLE,
    },
    {
      label: 'Website',
      type: 'string',
      value: PeopleWebsiteFilterField.STRING_WEBSITE,
    },
    {
      label: 'Relationship status',
      value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_STATUS,
      type: 'enumString',
      inputOptions: [
        { value: 'RELATIONSHIP_STATUS_NOT_CONTACTED', label: 'Not contacted' },
        { value: 'RELATIONSHIP_STATUS_ATTEMPTING_TO_REACH', label: 'Scheduled' },
        { value: 'RELATIONSHIP_STATUS_CONTACTED', label: 'Contacted' },
        { value: 'RELATIONSHIP_STATUS_REPLIED', label: 'Replied' },
        { value: 'RELATIONSHIP_STATUS_BOUNCED', label: 'Bounced' },
        { value: 'RELATIONSHIP_STATUS_UNSUBSCRIBED', label: 'Unsubscribed' },
        { value: 'RELATIONSHIP_STATUS_CANCELED_MANUALLY', label: 'Stopped' },
        { value: 'RELATIONSHIP_STATUS_INTERESTED', label: 'Interested' },
        { value: 'RELATIONSHIP_STATUS_NOT_INTERESTED', label: 'Not interested' },
        { value: 'RELATIONSHIP_STATUS_WON', label: 'Won' },
        { value: 'RELATIONSHIP_STATUS_LOST', label: 'Lost' },
      ],
    },
    {
      label: 'Number of people',
      type: 'number',
      value: PeopleWebsiteFilterField.NUMBER_WEBSITE_MEMBERS,
    },
    {
      label: 'Created on',
      value: PeopleWebsiteFilterField.DATE_WEBSITE_CREATED_AT,
      type: 'date',
    },
    {
      label: 'Tag',
      value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_TAG,
      type: 'enum',
      inputOptions: tags,
    },
    {
      label: 'Campaign',
      value: PeopleWebsiteFilterField.DROPDOWN_PERSON_CAMPAIGN_ID,
      type: 'enum',
      inputOptions: campaigns,
    },
    {
      label: 'Workspace',
      value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_WORKSPACE_ID,
      type: 'enum',
      inputOptions: workspaces,
    },
  ];

  if (IS_STANDALONE_MODE) {
    filterFields.push({
      label: 'Owner',
      value: PeopleWebsiteFilterField.DROPDOWN_WEBSITE_OWNER_ID,
      type: 'enum',
      inputOptions: members,
    });
  }

  return filterFields;
};
