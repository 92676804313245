import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import './SettingsStep.scss';

import SlideToggler from '@uikit/SlideToggler/SlideToggler';
import Accordion from '@uikit/Accordion/Accordion';
import { SVGIcon } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';
import Input from '@uikit/Input/Input';
import { ContentAutomationSearchContext } from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import { DispatchType } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';
import {
  BacklinksSearchType,
  KeywordsSearchType,
  PodcastSearchType,
} from '@ts/automationContent.types';
import { searchModeSelector } from '@redux/selectors/automationContent.selectors';
import { Button } from '@uikit/Button/Button';
import DateRangePickerWrapper from '@uikit/DateRangePicker/DateRangePicker';
import { ImportModeEnum } from '@ts/fileImport.types';
import ReactTooltip from 'react-tooltip';
import useOutsideClick from '@hooks/useOutsideClick';
import { transformDateToString } from '@helpers/dateTimeRangeHelpers';
import { Option } from '@pages/CampaignCreationPage/fields/sendingStrategy';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import {
  currentContactAutomationStateSelector,
  currentContactAutomationTypeSelector,
} from '@redux/selectors/automationContact.selectors';
import {
  setCurrentContactAutomationType,
  updateCurrentContactAutomationData,
} from '@redux/actions/automationContact.actions';
import { ContactAutomationSearchModes } from '@ts/automationContacts.types';
import Display from '@components/Display/Display';
import PersonalizationInstructionsEditor from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/PersonalizationInstructionsEditor/PersonalizationInstructionsEditor';
import { updateCurrentPersonalizationAutomationData } from '@redux/actions/automationPeronalization.actions';
import { updateCurrentInspectionAutomationData } from '@redux/actions/automationInspection.actions';
import { OpenAIModelType } from 'respona_api/generated/common_pb';
import { personalizationAutomationSelector } from '@redux/selectors/automationPersonalization.selectors';
import { inspectionAutomationSelector } from '@redux/selectors/automationInspection.selectors';
import { sequenceAiStepsSelector, sequenceSelector } from '@redux/selectors/sequence.selectors';
import { addNotification } from '@redux/actions/notifications.actions';
import { CampaignPipelineType, CampaignPipelineTypeMap } from 'respona_api/generated/pipeline_pb';
import { ContentAutomationSearchingMode } from 'respona_api/generated/automation-content_pb';
import { redirectUserTo } from '@utils/historyHandler';
import { isSemrushMode } from '@constants/app-modes';
import { getOpenAiModelOptions, getPriceByModel } from 'src/constants/openAiModels';

declare const IS_DEV: boolean;
declare const INTEGRATION_MODE: string;
declare const IS_STANDALONE_MODE: boolean;

const initialInstructions = [
  'Use short, choppy sentences and paragraphs.',
  'Limit the use of commas.',
  '5th-grade reading level, use simple words and avoid using jargon.',
];

function SettingsStep({
  data,
  type,
  onImportClick = null,
  importMode = null,
  csvFileLength,
}: {
  data?: KeywordsSearchType | BacklinksSearchType | PodcastSearchType;
  type: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap];
  onImportClick?: () => void;
  importMode?: ImportModeEnum;
  csvFileLength?: number;
}): React.JSX.Element {
  const tooltipWrapperRef = useRef();
  const dispatch: DispatchType = useDispatch();
  const searchMode = useSelector(searchModeSelector);
  const { settings, setSettings } = useContext(ContentAutomationSearchContext);
  const {
    data: { extendedFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  useOutsideClick(tooltipWrapperRef, () => ReactTooltip.hide());

  const personalizationModeOptions = [
    { value: OpenAIModelType.GPT_CHEAP, label: 'GPT 4o-mini' },
    { value: OpenAIModelType.GPT_EXPENSIVE, label: 'GPT 4o' },
  ];

  const searchModeOptions = [
    {
      value: ContactAutomationSearchModes.BLOG_POST,
      label: 'Blogs',
      tooltipText:
        'Find the writer of the articles only if they work for the company; otherwise, find other employees who match specific job titles and seniority.',
    },
    {
      value: ContactAutomationSearchModes.NEWS,
      label: 'News',
      tooltipText: "Only find the article's writer and ignore other employees.",
    },
    {
      value: ContactAutomationSearchModes.DOMAINS,
      label: 'Homepages',
      tooltipText:
        'Ignore the writer and find employees who match specific job titles and seniority.',
    },
    {
      value: ContactAutomationSearchModes.PODCAST,
      label: 'Podcasts',
      tooltipText: 'Find the best available email for the podcast.',
    },
  ];

  if (IS_DEV) {
    searchModeOptions.push({
      value: ContactAutomationSearchModes.EXPERIMENTAL,
      label: '[Experimental]',
      tooltipText: '',
    });
  }

  const currentType = useSelector(currentContactAutomationTypeSelector);
  const automationContact = useSelector(currentContactAutomationStateSelector);
  const { data: automationPersonalization } = useSelector(personalizationAutomationSelector);
  const { data: automationInspection } = useSelector(inspectionAutomationSelector);
  const { steps: sequenceSteps } = useSelector(sequenceSelector);
  const aiSequenceSteps = useSelector(sequenceAiStepsSelector);

  const isPersonalizationEnabled = useMemo(() => {
    if (IS_STANDALONE_MODE) {
      return extendedFeaturesEnabled;
    }

    return isSemrushMode(INTEGRATION_MODE);
  }, [extendedFeaturesEnabled, INTEGRATION_MODE, IS_STANDALONE_MODE]);

  useEffect(() => {
    if (aiSequenceSteps.length) {
      handleSettingsState(true, 'togglePersonalization');
    }
  }, []);

  useEffect(() => {
    if (type === CampaignPipelineType.IMPORT && settings.toggleRecurring) {
      handleSettingsState(false, 'toggleRecurring');
    }
  }, [settings.toggleRecurring, type]);

  useEffect(() => {
    if (settings.togglePersonalization) {
      const notEmptyStepsLength =
        sequenceSteps?.filter((step) => step.content !== '<p><span></span></p>')?.length || 0;
      if (notEmptyStepsLength === 0 || (aiSequenceSteps != null && aiSequenceSteps.length <= 0)) {
        handleSettingsState(false, 'togglePersonalization');
      }
    }
  }, [settings.togglePersonalization, aiSequenceSteps]);

  useEffect(() => {
    if (automationContact != null) {
      if (currentType === ContactAutomationSearchModes.NEWS) {
        if (automationContact.assignNumber > 1) {
          handleContactAutomationState(1, 'assignNumber');
        }
      }
      if (currentType === ContactAutomationSearchModes.PODCAST) {
        if (automationContact.assignNumber > 1) {
          handleContactAutomationState(1, 'assignNumber');
        }
      }
    }
  }, [currentType]);

  useEffect(() => {
    if (type === CampaignPipelineType.PODCAST_SEARCH) {
      dispatch(setCurrentContactAutomationType(ContactAutomationSearchModes.PODCAST));
    }
    if (type === CampaignPipelineType.BACKLINK_SEARCH) {
      dispatch(setCurrentContactAutomationType(ContactAutomationSearchModes.BLOG_POST));
    }
    if (type === CampaignPipelineType.GOOGLE_SEARCH) {
      if ((data as KeywordsSearchType).source <= 1) {
        dispatch(setCurrentContactAutomationType(ContactAutomationSearchModes.BLOG_POST));
      } else {
        dispatch(setCurrentContactAutomationType(ContactAutomationSearchModes.NEWS));
      }
    }
  }, [type]);

  useEffect(() => {
    if (
      type === CampaignPipelineType.IMPORT &&
      importMode != null &&
      importMode === ImportModeEnum.PERSON &&
      settings.toggleFindContactInfo
    ) {
      handleSettingsState(false, 'toggleFindContactInfo');
    }
  }, [type, importMode, settings.toggleFindContactInfo]);

  const handleSettingsState = (value: string | number | boolean | Date, field: string): void => {
    setSettings((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleContactAutomationState = (value: string | number | boolean | Date, fieldName) => {
    dispatch(
      updateCurrentContactAutomationData({
        [fieldName]: value,
      })
    );
  };

  const handlePersonalizationAutomationState = (value: string[] | number | boolean, fieldName) => {
    dispatch(
      updateCurrentPersonalizationAutomationData({
        [fieldName]: value,
      })
    );
  };

  const handleInspectionAutomationState = (value: string[] | number | boolean, fieldName) => {
    dispatch(
      updateCurrentInspectionAutomationData({
        [fieldName]: value,
      })
    );
  };

  const getPositionOptions = () => automationContact.positionSuggestions;

  const getSeniorityOptions = () => automationContact.senioritySuggestions;

  const getSearchModeValue = (value: number, options: { value: number; label: string }[]) => {
    return options.find((option) => option.value === value);
  };

  function composeTotalQueriesNumber(): number {
    if (csvFileLength != null) {
      return csvFileLength;
    }
    if (data == null) {
      return 0;
    }
    if (searchMode === 0) {
      return (data as KeywordsSearchType).queriesList.length;
    }
    if (searchMode === 1) {
      return (data as BacklinksSearchType).urlsList.length;
    }
    if (searchMode === 2) {
      return (data as PodcastSearchType).keywordsList.length;
    }
    return 0;
  }

  function pickAnalyzeResultsNumber(): number {
    if (data == null) {
      return 1;
    }
    return data.analyzeResultsNumber;
  }

  function composePersonalizationPricePerOpportunity(): number {
    const stepsNumber = aiSequenceSteps.length;
    let pricePerStep = 0;
    if (automationPersonalization.includeSummaries) {
      if (type === CampaignPipelineType.PODCAST_SEARCH) {
        pricePerStep += 10;
      } else if (
        type === CampaignPipelineType.GOOGLE_SEARCH ||
        type === CampaignPipelineType.BACKLINK_SEARCH
      ) {
        pricePerStep += 1;
      }
    }
    if (automationPersonalization.model === OpenAIModelType.GPT_EXPENSIVE) {
      pricePerStep += 5;
    } else if (automationPersonalization.model === OpenAIModelType.GPT_CHEAP) {
      pricePerStep += 1;
    }
    return stepsNumber * pricePerStep;
  }

  function composeEstimatedCreditsNumber(): number {
    if (
      searchMode === ContentAutomationSearchingMode.IMPORT_MODE &&
      !settings.togglePersonalization &&
      !settings.toggleFindContactInfo
    ) {
      return 0;
    }
    const opportunityMultiplier =
      searchMode === ContentAutomationSearchingMode.IMPORT_MODE ? 1 : pickAnalyzeResultsNumber();

    const opportunitiesNumber = composeTotalQueriesNumber() * opportunityMultiplier;

    const creditsPerContacts = settings.toggleFindContactInfo
      ? opportunitiesNumber * Math.max(automationContact.assignNumber, 1) * 5
      : 0;

    const creditsPerPersonalization = settings.togglePersonalization
      ? opportunitiesNumber * composePersonalizationPricePerOpportunity()
      : 0;

    return creditsPerContacts + opportunitiesNumber + creditsPerPersonalization;
  }

  const toggleJobTitleRequire = (value) => {
    dispatch(
      updateCurrentContactAutomationData({
        requiredPositions: value,
      })
    );
  };

  const history = useHistory();

  function skipCatchAllText() {
    if (automationContact == null) {
      return '';
    }
    if (automationContact.skipCatchAllEmails)
      return 'Skipping domains with accept-all policies for email verification can improve deliverability.';
    return 'Using catch-all emails significantly increases the bounce rate. We recommend turning this toggle on to maximize deliverability.';
  }

  function requiredPositionsText() {
    if (automationContact == null) {
      return '';
    }
    if (automationContact.requiredPositions)
      return 'Adding restrictions to job titles significantly reduces the search results. We recommend turning this toggle off to maximize your results.';
    return `If enabled, our application will assign contacts only if employees with specific job titles are found. We recommend keeping this toggle off to allow our application to find the best available contacts.`;
  }

  const modelOptions = getOpenAiModelOptions();

  return (
    <>
      <div className="accordion">
        <div className="accordion-header">
          <SlideToggler
            value={settings.toggleFindContactInfo}
            isDisabled={
              type === CampaignPipelineType.IMPORT &&
              importMode != null &&
              importMode === ImportModeEnum.PERSON
            }
            onChange={(value) => handleSettingsState(value, 'toggleFindContactInfo')}
          />
          <h3 className="accordion-header-title">Find Contact info</h3>
          <div className="accordion-header-info">
            <SVGIcon size={10} icon="wallet" />
            <span>5 credits per contact</span>
          </div>

          <SVGIcon
            icon="arrowDown"
            className={`icon-arrow ${settings.toggleFindContactInfo ? 'active' : ''}`}
            color="#7B7B7B"
          />
        </div>
        <Accordion isOpen={settings.toggleFindContactInfo}>
          <div className="accordion-content-body">
            <ul className="accordion-content-body-list">
              <li className="accordion-content-body-list__item">
                <div className="accordion-content-body-list__item-label">Opportunity type:</div>
                <Select
                  options={searchModeOptions}
                  value={getSearchModeValue(currentType, searchModeOptions)}
                  onChange={({ value }) => dispatch(setCurrentContactAutomationType(value))}
                  additionalComponents={{ Option }}
                />
              </li>
              {currentType < 3 && (
                <>
                  <li className="accordion-content-body-list__item">
                    <div className="accordion-content-body-list__item-label">
                      Find up to
                      <Input
                        value={automationContact.assignNumber}
                        type="number"
                        onChange={(event) => {
                          const value = Number(event.target.value.trim());
                          if (value > 0) {
                            handleContactAutomationState(value, 'assignNumber');
                          }
                        }}
                      />{' '}
                      contacts per website.
                    </div>
                  </li>
                  <li className="accordion-content-body-list__item">
                    <div className="accordion-content-body-list__item-label">Job titles:</div>
                    <Select
                      closeMenuOnSelect={false}
                      placeholder="Add job titles"
                      onChange={(values) => handleContactAutomationState(values, 'positionsList')}
                      allowCustomInput
                      options={getPositionOptions()}
                      value={automationContact.positionsList}
                      additionalComponents={
                        !!automationContact.positionsList?.length && {
                          DropdownIndicator: () => null,
                        }
                      }
                      isMulti
                      visibleMultiCount={4}
                      additionalStyles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: '40px',
                          borderRadius: '8px',
                        }),
                      }}
                      renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
                    />
                    <div className="accordion-content-body-list__item-block">
                      <span className="accordion-content-body-list__item-block__title">
                        Required{' '}
                      </span>
                      <SlideToggler
                        value={automationContact.requiredPositions}
                        onChange={toggleJobTitleRequire}
                      />
                      <QuestionTooltip
                        place="top"
                        icon={
                          automationContact.requiredPositions
                            ? 'warningTriangle'
                            : 'questionInCircleFilled'
                        }
                        color="#C6C6C6"
                        style={{ marginTop: '2px' }}
                        text={requiredPositionsText()}
                      />
                    </div>
                  </li>
                  <li className="accordion-content-body-list__item">
                    <div className="accordion-content-body-list__item-label">Seniority:</div>
                    <Select
                      closeMenuOnSelect={false}
                      placeholder="Add seniority"
                      onChange={(values) => handleContactAutomationState(values, 'senioritiesList')}
                      allowCustomInput
                      options={getSeniorityOptions()}
                      value={automationContact.senioritiesList}
                      additionalComponents={
                        !!automationContact.senioritiesList?.length && {
                          DropdownIndicator: () => null,
                        }
                      }
                      isMulti
                      visibleMultiCount={4}
                      additionalStyles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: '40px',
                          borderRadius: '8px',
                        }),
                      }}
                      renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
                    />
                  </li>
                </>
              )}
              <Display isVisible={currentType !== ContactAutomationSearchModes.PODCAST}>
                <li className="accordion-content-body-list__item">
                  <SlideToggler
                    value={automationContact.skipCatchAllEmails}
                    onChange={(value) => handleContactAutomationState(value, 'skipCatchAllEmails')}
                  />
                  <div className="accordion-content-body-list__item-label ml-10">
                    Skip catch-all domains{' '}
                    <QuestionTooltip
                      place="top"
                      icon={
                        automationContact.skipCatchAllEmails
                          ? 'questionInCircleFilled'
                          : 'warningTriangle'
                      }
                      color="#C6C6C6"
                      style={{ marginLeft: '6px', marginTop: '1px' }}
                      text={skipCatchAllText()}
                    />
                  </div>
                </li>
              </Display>
              <li className="accordion-content-body-list__item">
                <SlideToggler
                  value={automationContact.assignGenericEmails}
                  onChange={(value) => handleContactAutomationState(value, 'assignGenericEmails')}
                />
                <div className="accordion-content-body-list__item-label ml-10">
                  Use generic emails as needed{' '}
                  <QuestionTooltip
                    place="top"
                    color="#C6C6C6"
                    style={{ marginLeft: '6px', marginTop: '1px' }}
                    text={`If we’re unable to find a suitable match, we use a generic email as a last
                  resort.`}
                  />
                </div>
              </li>
            </ul>
          </div>
        </Accordion>
      </div>

      {(IS_DEV && IS_STANDALONE_MODE) && (
        <div className="accordion">
          <div className={`accordion-header ${extendedFeaturesEnabled ? '' : 'blocked'}`}>
            <SlideToggler
              value={settings.toggleInspection}
              isDisabled={false}
              onChange={(value) => handleSettingsState(value, 'toggleInspection')}
            />
            <h3 className="accordion-header-title">AI Inspection</h3>
            <div className="accordion-header-info">
              <SVGIcon size={10} icon="wallet" />
              <span>
                {getPriceByModel(automationInspection.model) > 1
                  ? `${getPriceByModel(automationInspection.model)} credits`
                  : '1 credit'}{' '}
                per opportunity
              </span>
            </div>
            {!extendedFeaturesEnabled ? (
              <div
                className="accordion-header__lock-icon"
                onClick={() => handleSettingsState(!settings.toggleInspection, 'toggleInspection')}
              >
                <SVGIcon icon="lock" />
              </div>
            ) : (
              <SVGIcon
                icon="arrowDown"
                className={`icon-arrow ${settings.toggleInspection ? 'active' : ''}`}
                color="#7B7B7B"
              />
            )}
          </div>
          <Accordion isOpen={settings.toggleInspection}>
            {!extendedFeaturesEnabled ? (
              <div className="accordion-content-body__started-plan">
                <h2 className="accordion-content-body__started-plan-title">Unlock AI Inspection</h2>
                <p className="accordion-content-body__started-plan-text">
                  Put your AI Inspection on auto-pilot. Available on Pro and Unlimited plans.
                </p>
                <Button
                  className="accordion-content-body__started-plan-button"
                  type="additional"
                  onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}
                >
                  Upgrade my plan
                </Button>
              </div>
            ) : (
              <div className="accordion-content-body">
                <ul className="accordion-content-body-list">
                  <li className="accordion-content-body-list__item">
                    <div className="accordion-content-body-list__item-label">AI model:</div>
                    <Select
                      options={modelOptions}
                      value={modelOptions.find((it) => it.value === automationInspection.model)}
                      onChange={({ label }) => handleInspectionAutomationState(label, 'model')}
                    />
                  </li>

                  <li className="accordion-content-body-list__item flex-column">
                    <PersonalizationInstructionsEditor
                      onChangeInstructions={(value) =>
                        handleInspectionAutomationState(value, 'instructions')
                      }
                      instructions={automationInspection.instructions}
                      instructionsTitle="Instructions"
                      limit={512}
                    />
                  </li>

                  <li className="accordion-content-body-list__item">
                    <SlideToggler
                      value={automationInspection.includeSummaries}
                      onChange={(value) =>
                        handleInspectionAutomationState(value, 'includeSummaries')
                      }
                    />
                    <div className="accordion-content-body-list__item-label ml-10">
                      Include opportunity summaries in the prompt
                      <div className="accordion-header-info ml-10">
                        <SVGIcon size={10} icon="wallet" />
                        <span>1-10 credits per opportunity</span>
                      </div>
                      <QuestionTooltip
                        place="top"
                        color="#C6C6C6"
                        style={{ marginLeft: '6px', marginTop: '1px' }}
                        text={
                          'Including opportunity summaries in the prompt will give the model more context and results in more accurate outputs. \n' +
                          'Websites: 1 credit per summary\n' +
                          'Podcasts: 10 credits per summary'
                        }
                      />
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </Accordion>
        </div>
      )}

      <div className="accordion">
        <div className={`accordion-header ${isPersonalizationEnabled ? '' : 'blocked'}`}>
          <SlideToggler
            value={settings.togglePersonalization}
            onChange={(value) => {
              if (value && aiSequenceSteps.length <= 0) {
                dispatch(
                  addNotification({
                    title: 'Add at least one AI variable to the sequence',
                    type: 'warning',
                  })
                );
              } else {
                handleSettingsState(value, 'togglePersonalization');
              }
            }}
          />
          <h3 className="accordion-header-title">AI Personalization</h3>
          <div className="accordion-header-info">
            <SVGIcon size={10} icon="wallet" />
            <span>
              {automationPersonalization.model === OpenAIModelType.GPT_EXPENSIVE
                ? '5 credits'
                : '1 credit'}{' '}
              per email
            </span>
          </div>
          {isPersonalizationEnabled ? (
            <SVGIcon
              icon="arrowDown"
              className={`icon-arrow ${settings.togglePersonalization ? 'active' : ''}`}
              color="#7B7B7B"
            />
          ) : (
            <div
              className="accordion-header__lock-icon"
              onClick={() =>
                handleSettingsState(!settings.togglePersonalization, 'togglePersonalization')
              }
            >
              <SVGIcon icon="lock" />
            </div>
          )}
        </div>
        <Accordion isOpen={settings.togglePersonalization}>
          <div className="accordion-content-body">
            {/* It's available on Pro and Unlimited plans in standalone app, and for all users of Semrush. */}
            {isPersonalizationEnabled ? (
              <ul className="accordion-content-body-list">
                <li className="accordion-content-body-list__item">
                  <div className="accordion-content-body-list__item-label">AI model:</div>
                  <Select
                    options={personalizationModeOptions}
                    value={personalizationModeOptions.find(
                      (it) => it.value === automationPersonalization.model
                    )}
                    onChange={({ value }) => handlePersonalizationAutomationState(value, 'model')}
                  />
                </li>
                {/* <li className="accordion-content-body-list__item"> */}
                {/*  <div className="accordion-content-body-list__item-label">Tone of Voice</div> */}
                {/*  <Select */}
                {/*    options={personalizationToneOptions} */}
                {/*    value={personalizationToneOptions.find( */}
                {/*      (it) => it.value === automationPersonalization.tone */}
                {/*    )} */}
                {/*    onChange={({ value }) => handlePersonalizationAutomationState(value, 'tone')} */}
                {/*  /> */}
                {/* </li> */}

                <li className="accordion-content-body-list__item flex-column">
                  <PersonalizationInstructionsEditor
                    onChangeInstructions={(value) =>
                      handlePersonalizationAutomationState(value, 'instructions')
                    }
                    instructions={initialInstructions}
                    limit={512}
                  />
                </li>

                <li className="accordion-content-body-list__item">
                  <SlideToggler
                    value={automationPersonalization.includeSummaries}
                    onChange={(value) =>
                      handlePersonalizationAutomationState(value, 'includeSummaries')
                    }
                  />
                  <div className="accordion-content-body-list__item-label ml-10">
                    Include opportunity summaries in the prompt{' '}
                    <div className="accordion-content-body-list__item-info">
                      <SVGIcon size={10} icon="wallet" />
                      <span>1-10 credits per opportunity</span>
                    </div>
                  </div>
                  <QuestionTooltip
                    place="top"
                    icon="questionInCircleFilled"
                    color="#C6C6C6"
                    style={{ marginTop: '2px' }}
                    text={
                      'Including opportunity summaries in the prompt will give the model more context and results in more accurate outputs. \n' +
                      'Websites: 1 credit per summary\n' +
                      'Podcasts: 10 credits per summary'
                    }
                  />
                </li>
              </ul>
            ) : (
              <div className="accordion-content-body__started-plan">
                <h2 className="accordion-content-body__started-plan-title">
                  Unlock AI Personalizer
                </h2>
                <p className="accordion-content-body__started-plan-text">
                  2X your reply rates with AI-powered variables. Available on Pro and Unlimited
                  plans.
                </p>
                <Button
                  className="accordion-content-body__started-plan-button"
                  type="additional"
                  onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}
                >
                  Upgrade my plan
                </Button>
              </div>
            )}
          </div>
        </Accordion>
      </div>

      {IS_STANDALONE_MODE && importMode == null && (
        <div className="accordion">
          <div className={`accordion-header ${extendedFeaturesEnabled ? '' : 'blocked'}`}>
            <SlideToggler
              value={settings.toggleRecurring}
              onChange={(value) => handleSettingsState(value, 'toggleRecurring')}
            />
            <h3 className="accordion-header-title">Recurring</h3>

            {!extendedFeaturesEnabled ? (
              <div
                className="accordion-header__lock-icon"
                onClick={() => handleSettingsState(!settings.toggleRecurring, 'toggleRecurring')}
              >
                <SVGIcon icon="lock" />
              </div>
            ) : (
              <SVGIcon
                icon="arrowDown"
                className={`icon-arrow ${settings.toggleRecurring ? 'active' : ''}`}
                color="#7B7B7B"
              />
            )}
          </div>
          <Accordion isOpen={settings.toggleRecurring}>
            <div className="accordion-content-body">
              {!extendedFeaturesEnabled ? (
                <div className="accordion-content-body__started-plan">
                  <h2 className="accordion-content-body__started-plan-title">
                    Unlock Recurring Searches
                  </h2>
                  <p className="accordion-content-body__started-plan-text">
                    Put your searches on auto-pilot. Available on Pro and Unlimited plans.
                  </p>
                  <Button
                    className="accordion-content-body__started-plan-button"
                    type="additional"
                    onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}
                  >
                    Upgrade my plan
                  </Button>
                </div>
              ) : (
                <ul className="accordion-content-body-list">
                  <li className="accordion-content-body-list__item">
                    <div className="accordion-content-body-list__item-label">
                      Run a new search every{' '}
                      <Input
                        value={settings.repeatSearchEveryWeeks}
                        disabled={type === CampaignPipelineType.IMPORT}
                        type="number"
                        onChange={(event) => {
                          const value = Number(event.target.value.trim());
                          if (value > 0) {
                            handleSettingsState(value, 'repeatSearchEveryWeeks');
                          }
                        }}
                      />
                      week(s)
                    </div>
                  </li>
                  <li className="accordion-content-body-list__item" ref={tooltipWrapperRef}>
                    <div className="accordion-content-body-list__item-label">
                      Stop recurring searches on{' '}
                    </div>
                    <div
                      className="header-date-filter"
                      data-for="header-date-filter-tooltip"
                      data-tip=""
                      data-event="click"
                      role="button"
                      tabIndex={0}
                    >
                      {transformDateToString(new Date(settings.stopRepeatAfterDate))}
                      <SVGIcon icon="chevron" />
                    </div>
                    <ReactTooltip
                      className="react-tooltip react-tooltip--header-date-filter"
                      id="header-date-filter-tooltip"
                      place="bottom"
                      effect="solid"
                      event="off"
                      globalEventOff="click"
                      offset={{ left: 100 }}
                      arrowColor="transparent"
                      clickable
                    >
                      <DateRangePickerWrapper
                        handleSelect={(value) => {
                          handleSettingsState(value, 'stopRepeatAfterDate');
                          ReactTooltip.hide();
                        }}
                        isReverseCheck
                        isRangePicker={false}
                        value={{ startDate: settings.stopRepeatAfterDate, endDate: null }}
                      />
                    </ReactTooltip>
                  </li>
                </ul>
              )}
            </div>
          </Accordion>
        </div>
      )}

      {/* <div className="accordion-footer"> */}
      {/*  <div className="accordion-footer__item"> */}
      {/*    <SVGIcon icon="search" color="#C4C6CD" /> */}
      {/*    <div className="accordion-content-body-list__item-label">Total queries</div> */}
      {/*    <span className="text-green">{composeTotalQueriesNumber()}</span> */}
      {/*  </div> */}

      {/*  <div className="accordion-footer__item"> */}
      {/*    <QuestionTooltip */}
      {/*      style={{ marginLeft: '-20px' }} */}
      {/*      icon="wallet" */}
      {/*      color="#C4C6CD" */}
      {/*      text="Credits are reserved for opportunity searches and will be restored if they’re unused." */}
      {/*    /> */}
      {/*    <div className="accordion-content-body-list__item-label">Reserved credits</div> */}
      {/*    <span className="text-blue">~ {composeEstimatedCreditsNumber()}</span> */}
      {/*  </div> */}
      {/*  <div className="accordion-footer__item"> */}
      {/*    <SVGIcon icon="time" color="#C4C6CD" /> */}
      {/*    <div className="accordion-content-body-list__item-label">Time (mins)</div> */}
      {/*    <span className="text-blue">~ {composeEstimatedTimeNumber()}</span> */}
      {/*  </div> */}
      {/* </div> */}

      {type === CampaignPipelineType.IMPORT && onImportClick != null && (
        <div className="import-file-flow__footer">
          {IS_STANDALONE_MODE && (
            <div className="import-file-flow__footer__notify">
              <SlideToggler
                value={settings.notify}
                onChange={(value) => handleSettingsState(value, 'notify')}
              />
              <div className="import-file-flow__footer__notify-title">
                Email me when the search is complete
              </div>
            </div>
          )}
          <div className="import-file-flow__footer__launch_button">
            <Button onClick={onImportClick}>Import</Button>
          </div>
        </div>
      )}
    </>
  );
}

export default SettingsStep;
