/**
 * NOT CHECKED
 */
import { addNotification } from '@redux/actions/notifications.actions';
import React, { useRef } from 'react';
import cn from 'class-names';

import useAudioPlayer from '@hooks/useAudioPlayer';
import { SVGIcon } from '@uikit/Icon/Icon';
import Display from '@components/Display/Display';
import { useDispatch } from 'react-redux';
import { DispatchType } from 'src/store';

import Bar from './_components/Bar/Bar';
import Volume from './_components/Volume/Volume';

import './Player.scss';

export default ({ src, isInline = false }: { src: string; isInline?: boolean }): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const playerRef = useRef<HTMLAudioElement>();
  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    volume,
    setVolume,
    onMute,
    onUnmute,
  } = useAudioPlayer(playerRef);

  const handleStartPlaying = (evt) => {
    try {
      evt.stopPropagation();
      setPlaying(true);
    } catch (e) {
      if (e?.message?.includes('not allowed')) {
        dispatch(
          addNotification({
            title: 'Please, make sure your browser settings allow playing audio.',
            type: 'error',
          })
        );
      }
    }
  };

  const handleStopPlaying = (evt) => {
    try {
      evt.stopPropagation();
      setPlaying(false);
    } catch (e) {
      if (e?.message?.includes('not allowed')) {
        dispatch(
          addNotification({
            title: 'Please, make sure your browser settings allow playing audio.',
            type: 'error',
          })
        );
      }
    }
  };

  const renderControl = () => {
    return (
      <div className="player__controls">
        {playing ? (
          <span onClick={handleStopPlaying}>
            <SVGIcon icon="pauseBlue" />
          </span>
        ) : (
          <span onClick={handleStartPlaying}>
            <SVGIcon icon="playBlue" />
          </span>
        )}
      </div>
    );
  };

  const cnPlayer = cn('player', {
    'player--inline': isInline,
  });

  return (
    <div className={cnPlayer} onClick={(e) => e.stopPropagation()}>
      <audio ref={playerRef}>
        <source src={src} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <Display isVisible={isInline}>
        <div className="player__controls">{renderControl()}</div>
      </Display>
      <Bar
        curTime={curTime}
        duration={duration}
        onTimeUpdate={(time) => setClickedTime(time)}
        isInline={isInline}
      />
      {isInline ? (
        <Volume volume={volume} onUpdateVolume={setVolume} onMute={onMute} onUnmute={onUnmute} />
      ) : (
        <div className="player__underline-controls">
          {renderControl()}
          <Volume volume={volume} onUpdateVolume={setVolume} onMute={onMute} onUnmute={onUnmute} />
        </div>
      )}
    </div>
  );
};
