// package: ai_prompts
// file: ai-prompts.proto

var ai_prompts_pb = require("./ai-prompts_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AiAgentPromptApiService = (function () {
  function AiAgentPromptApiService() {}
  AiAgentPromptApiService.serviceName = "ai_prompts.AiAgentPromptApiService";
  return AiAgentPromptApiService;
}());

AiAgentPromptApiService.execute = {
  methodName: "execute",
  service: AiAgentPromptApiService,
  requestStream: false,
  responseStream: false,
  requestType: ai_prompts_pb.AgentPromptRequest,
  responseType: ai_prompts_pb.AgentPromptResponse
};

AiAgentPromptApiService.getAllConversationMessages = {
  methodName: "getAllConversationMessages",
  service: AiAgentPromptApiService,
  requestStream: false,
  responseStream: true,
  requestType: ai_prompts_pb.AiConversationRequest,
  responseType: ai_prompts_pb.AiConversationResponse
};

exports.AiAgentPromptApiService = AiAgentPromptApiService;

function AiAgentPromptApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AiAgentPromptApiServiceClient.prototype.execute = function execute(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AiAgentPromptApiService.execute, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AiAgentPromptApiServiceClient.prototype.getAllConversationMessages = function getAllConversationMessages(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(AiAgentPromptApiService.getAllConversationMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.AiAgentPromptApiServiceClient = AiAgentPromptApiServiceClient;

