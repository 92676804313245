import {
  SET_INSPECTION_AUTOMATION_DATA,
  UPDATE_CURRENT_INSPECTION_AUTOMATION_DATA,
} from '@redux/actions/automationInspection.actions';
import { InspectionAutomationSettings } from '@ts/automationInspection.types';

export type InspectionAutomationStateType = {
  data: InspectionAutomationSettings;
};

const initialInspectionInstructions = [
  'Look for signals of a trustworthy or high-quality source (e.g., recognized domain, credible references).',
  'If the page is relevant and high-quality, recommend including it in the campaign.',
  'Flag any pages that appear spammy or low-value.',
];

export const initialState: InspectionAutomationStateType = {
  data: {
    model: 'o3-mini',
    instructions: initialInspectionInstructions,
    includeSummaries: true,
  },
};

export default (state: InspectionAutomationStateType = initialState, { type, payload }) => {
  switch (type) {
    case SET_INSPECTION_AUTOMATION_DATA:
      return {
        ...state,
        data: payload,
      };
    case UPDATE_CURRENT_INSPECTION_AUTOMATION_DATA:
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    default:
      return state;
  }
};
