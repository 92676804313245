import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  updateInstructionsCampaignAutomationApi,
  updateSettingsCampaignAutomationApi,
} from '@api/campaignAutomation.api';
import transformObjectToJsonString from '@utils/transformObjectToJsonString';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import {
  CampaignAutomationDetailsType,
  CampaignAutomationSettingsType,
  LinkBuildingStrategyInstructionsType, TargetPageType,
} from '@ts/automationCampaigns.types';
import qs from 'query-string';
import AiAgentsSidebar from '@components/AiAgents/AiAgentsSidebar/AiAgentsSidebar';
import Input from '@uikit/Input/Input';
import './AiAgentInstructions.css';
import { SVGIcon } from '@uikit/Icon/Icon';
import Select from '@uikit/Select/Select';
import { Button } from '@uikit/Button/Button';
import ActionsMenu, { ActionsIconsTypeEnum } from '@components/ActionsMenu/ActionsMenu';
import ReactTooltip from 'react-tooltip';
import Textarea from '@uikit/Textarea/Textarea';
import AdvancedSettingsWrapper from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/AdvancedSettingsWrapper/AdvancedSettingsWrapper';
import Loader from '@uikit/Loader/Loader';

import { schedulesSelector } from '@redux/selectors/schedules.selectors';
import loadingStatuses from '@constants/loadingStatuses';
import { fetchAllSchedules } from '@redux/thunks/schedulesRequests';
import Menu, { getSchedulesSelectOptions } from '@components/Schedules/Menu/Menu';
import EmailSelect from '@uikit/EmailSelect/EmailSelect';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { CampaignAutomationAutonomyLevel } from 'respona_api/generated/common_pb';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

const automationAutonomyOptions = [
  { label: 'Fully autonomous', value: CampaignAutomationAutonomyLevel.FULLY_AUTONOMOUS },
  { label: 'Requires approval', value: CampaignAutomationAutonomyLevel.REQUIRES_APPROVAL },
];

function AiAgentsChat({
  agent,
  queryAgentKey,
  match: {
    params: { paramsPage },
  },
  location: { search },
}: {
  agent?: CampaignAutomationDetailsType;
  queryAgentKey?: any[];
  match: { params: { paramsPage: string } };
  location: { search: string };
}): JSX.Element {
  const dispatch = useDispatch();
  const searchParams: { folderId?: string | undefined; id?: string } = qs.parse(search);
  const queryClient = useQueryClient();
  const { items: schedules, loadingStatus: schedulesLs } = useSelector(schedulesSelector);
  const [advancedSettings, setAdvancedSettings] = useState<boolean>(true);
  const [selectedSchedule, setSelectedSchedule] = useState<{ label: string; value: number } | null>(
    null
  );
  const [senderEmails, setSenderEmails] = useState<{ label: string; value: number }[]>([]);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const { items: mailboxAccounts } = useMailboxAccounts(workspaceId);
  const { members } = useWorkspaceMembers();
  const [autonomyLevel, setAutonomyLevel] = useState<{ label: string; value: number } | null>(automationAutonomyOptions[1]);
  const [sendNotificationsToUser, setSendNotificationsToUser] = useState<{ label: string; value: number } | null>(null);
  const [linkBuildingData, setLinkBuildingData] =
    useState<LinkBuildingStrategyInstructionsType | null>(null);

  const [agentSettings, setAgentSettings] = useState<CampaignAutomationSettingsType | null>(null);

  const usersOptions = useMemo(
    () => members.map(({ userId, fullName }) => ({ value: userId, label: fullName })),
    [members]
  );

  useEffect(() => {
    if (!schedulesLs || schedulesLs === loadingStatuses.PENDING) {
      fetchAllSchedules(dispatch);
    }
  }, [schedulesLs]);

  useEffect(() => {
    if (!!agent?.linkBuilding && !linkBuildingData) {
      setLinkBuildingData(agent.linkBuilding);
    }
    if (!!agent?.settings && !agentSettings) {
      setAgentSettings(agent.settings);
    }
    if (!!agent?.settings && !autonomyLevel && !!automationAutonomyOptions) {
      const value = automationAutonomyOptions.find(
        (opt) => opt.value === agent.settings.autonomyLevel
      );
      setAutonomyLevel(value);
    }
    if (!selectedSchedule && schedules?.length > 0 && agent?.settings.scheduleId > 0) {
      const activeSchedule = schedules.find(
        (schedule) => schedule.id === agent.settings.scheduleId
      );
      if (activeSchedule) {
        setSelectedSchedule({ label: activeSchedule.title, value: activeSchedule.id });
      }
    }
    if (
      !senderEmails?.length &&
      agent?.settings.mailboxAccountIdsList?.length > 0 &&
      mailboxAccounts?.length > 0
    ) {
      setSenderEmails(
        agent.settings.mailboxAccountIdsList.map((id) => {
          const mailbox = mailboxAccounts.find((account) => account.id === id);
          return { label: mailbox?.email, value: id };
        })
      );
    }
  }, [agent, schedules, mailboxAccounts, automationAutonomyOptions]);

  useEffect(() => {
    if (!linkBuildingData) return;

    const debounceTimer = setTimeout(() => {
      storeInstructions(linkBuildingData);
    }, 3000);

    return () => clearTimeout(debounceTimer);
  }, [linkBuildingData]);

  const storeInstructions = (updatedData: any) => {
    updateInstructionsCampaignAutomationApi(
      agent,
      workspaceId,
      JSON.parse(transformObjectToJsonString(updatedData))
    ).then((res) => {
      queryClient.setQueryData(queryAgentKey, (cache: CampaignAutomationDetailsType) => {
        return {
          ...cache,
          ...res,
        };
      });
    });
  };

  const updateAgentSettings = (settings) => {
    updateSettingsCampaignAutomationApi(agent, workspaceId, settings).then((res) => {
      queryClient.setQueryData(queryAgentKey, (cache: CampaignAutomationDetailsType) => {
        return {
          ...cache,
          ...res,
        };
      });
    });
  };

  const onUpdateMailboxes = (selectedValues: { label: string; value: number }[]) => {
    setSenderEmails(selectedValues);
    setAgentSettings((prev) => {
      if (!prev) {
        return prev;
      }
      const updatedAgentSettings = {
        ...prev,
        mailboxAccountIdsList: selectedValues.map((item) => item.value),
      };
      updateAgentSettings(updatedAgentSettings);
      return updatedAgentSettings;
    });
  };

  const onChangeAutonomyLevel = (selectedValue: { label: string; value: 0 | 1 }) => {
    setAutonomyLevel(selectedValue);
    setAgentSettings((prev) => {
      if (!prev) {
        return prev;
      }
      const updatedAgentSettings = {
        ...prev,
        autonomyLevel: selectedValue.value,
      };
      updateAgentSettings(updatedAgentSettings);
      return updatedAgentSettings;
    });
  };

  const onChangeSendNotificationsTo = (selectedValue: { label: string; value: 0 | 1 }) => {
    setSendNotificationsToUser(selectedValue);
  };

  const onUpdateSchedule = (selectedValue: { label: string; value: number }) => {
    setSelectedSchedule(selectedValue);
    setAgentSettings((prev) => {
      if (!prev) {
        return prev;
      }
      const updatedAgentSettings = {
        ...prev,
        scheduleId: selectedValue.value,
      };
      updateAgentSettings(updatedAgentSettings);
      return updatedAgentSettings;
    });
  };

  const handleInputChange = (field: string, value: string | number) => {
    setLinkBuildingData((prev) => {
      if (!prev) return prev;
      return { ...prev, [field]: value };
    });
  };

  const randomMultiplier = useMemo(() => {
    return Math.floor(Math.random() * 41) + 30;
  }, []);

  const estimatedCredits = useMemo(() => {
    if (linkBuildingData?.linksNumber > 0 && linkBuildingData?.parentKeywordsList?.length > 0) {
      return (
        linkBuildingData.linksNumber * linkBuildingData.parentKeywordsList.length * randomMultiplier
      );
    }
    return 0;
  }, [
    linkBuildingData,
    linkBuildingData?.linksNumber,
    linkBuildingData?.parentKeywordsList?.length,
  ]);

  const handleArrayFieldChange = (field: string, index: number, key: string, value: any) => {
    setLinkBuildingData((prev) => {
      if (!prev || !Array.isArray(prev[field])) {
        return prev;
      }

      const updated = prev[field].map((item, i) => {
        return i === index ? { ...item, [key]: value } : item
      });

      return { ...prev, [field]: updated };
    });
  };

  const filterLinkBuilding = (field: string, index: number)=> {
    setLinkBuildingData((prev) => {
      if (!prev || !Array.isArray(prev[field])) {
        return prev;
      }

      const updated = prev[field].filter((item, i) => i !== index);

      return { ...prev, [field]: updated };
    });
  };

  const handleParentKeywordsChange = (options: any) => {
    setLinkBuildingData((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        parentKeywordsList: options.map((o: any) => o.value),
      };
    });
  };

  const handleCompetingWebsitesChange = (options: any) => {
    setLinkBuildingData((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        competingWebsitesList: options.map((o: any) => o.value),
      };
    });
  };

  const listToOptions = (list: string[]) => {
    return list.map((item) => ({ label: item, value: item }));
  };

  const handleAddPage = () => {
    if (linkBuildingData) {
      setLinkBuildingData({
        ...linkBuildingData,
        targetPageToPreferredAnchorList: [
          ...linkBuildingData.targetPageToPreferredAnchorList,
          { targetPage: '', preferredAnchor: '' } as TargetPageType, // Add empty object to the array
        ],
      });
    }
  };

  return (
    <div className="ai-agents-page__content-instructions">
      <AiAgentsSidebar searchParams={searchParams} activePage={paramsPage} campaignId={agent?.id} />
      <Loader isLoading={!agent}>
        {agent?.linkBuilding != null && linkBuildingData && (
          <div className="ai-agents-page__content-body">
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label>Website address</label>
                <Input
                  value={linkBuildingData.websiteDomain || ''}
                  onChange={(e) => handleInputChange('websiteDomain', e.target.value)}
                />
              </div>
              <div className="ai-agents-page__instructions-column">
                <label>Website Name</label>
                <Input
                  value={linkBuildingData.websiteName || ''}
                  onChange={(e) => handleInputChange('websiteName', e.target.value)}
                />
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label>Website description</label>
                <Input
                  value={linkBuildingData.websiteDescription || ''}
                  onChange={(e) => handleInputChange('websiteDescription', e.target.value)}
                />
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label>Number of links per month</label>
                <Input
                  value={linkBuildingData.linksNumber || ''}
                  type="number"
                  onChange={(e) =>
                    handleInputChange('linksNumber', Number.parseInt(e.target.value, 10))
                  }
                />
              </div>
              <div className="ai-agents-page__instructions-column flex-vertical">
                <div className="ai-agents-page__instructions-column-flex">
                  <SVGIcon icon="wallet" color="#BDBDBD" />
                  <p>
                    Estimated credits
                    <span>{estimatedCredits}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label>Parent keywords</label>
                <Select
                  value={listToOptions(linkBuildingData.parentKeywordsList || [])}
                  onChange={handleParentKeywordsChange}
                  visibleMultiCount={3}
                  options={listToOptions(linkBuildingData.parentKeywordsList || [])}
                  allowCustomInput
                  isMulti
                  placeholder=""
                  additionalComponents={{ DropdownIndicator: () => null }}
                />
              </div>
              <div className="ai-agents-page__instructions-column">
                <label>Competing websites</label>
                <Select
                  value={listToOptions(linkBuildingData.competingWebsitesList || [])}
                  onChange={handleCompetingWebsitesChange}
                  visibleMultiCount={3}
                  options={listToOptions(linkBuildingData.competingWebsitesList || [])}
                  allowCustomInput
                  isMulti
                  placeholder=""
                  additionalComponents={{ DropdownIndicator: () => null }}
                />
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column-list">
                <div className="ai-agents-page__instructions-column-item ai-agents-page__instructions-column-item-header">
                  <div className="ai-agents-page__instructions-column">
                    <label>Target page(s)</label>
                  </div>
                  <div className="ai-agents-page__instructions-column">
                    <label>Preferred anchor</label>
                  </div>
                </div>
                {linkBuildingData.targetPageToPreferredAnchorList.map(
                  (item: any, index: number) => (
                    <div className="ai-agents-page__instructions-column-item" key={index}>
                      <div className="ai-agents-page__instructions-column">
                        <Input
                          value={item.targetPage || ''}
                          onChange={(e) =>
                            handleArrayFieldChange(
                              'targetPageToPreferredAnchorList',
                              index,
                              'targetPage',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="ai-agents-page__instructions-column">
                        <Input
                          value={item.preferredAnchor || ''}
                          onChange={(e) =>
                            handleArrayFieldChange(
                              'targetPageToPreferredAnchorList',
                              index,
                              'preferredAnchor',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <ActionsMenu
                        icon={ActionsIconsTypeEnum.Trash}
                        actionsConfig={[]}
                        onRemove={() => filterLinkBuilding('targetPageToPreferredAnchorList', index)}
                        removeActionTitle=""
                        id={`ai-actions-id-${index}`}
                        tooltipPlace="left"
                      />
                    </div>
                  )
                )}
                <Button className="add-button" type="primary" onClick={handleAddPage}>+ Add page</Button>
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label>Incentive</label>
                <Input
                  value={linkBuildingData.incentive || ''}
                  onChange={(e) => handleInputChange('incentive', e.target.value)}
                />
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label data-for="ai-column-label" data-tip="">
                  Email accounts <SVGIcon icon="questionInCircleFilled" size={12} color="#C6C6C6" />
                  <ReactTooltip
                    id="ai-column-label"
                    place="right"
                    effect="solid"
                    arrowColor="transparent"
                  >
                    <div>Some text</div>
                  </ReactTooltip>
                </label>
                <EmailSelect
                  isFullWidth
                  isMulti
                  value={senderEmails}
                  setLoading={(value) => {}}
                  onChange={onUpdateMailboxes}
                />
              </div>
              <div className="ai-agents-page__instructions-column">
                <label>Schedule</label>
                <Select
                  value={selectedSchedule}
                  options={getSchedulesSelectOptions(schedules)}
                  additionalComponents={{ Menu }}
                  key={schedulesLs}
                  onChange={onUpdateSchedule}
                />
              </div>
            </div>
            <div className="ai-agents-page__instructions-row">
              <div className="ai-agents-page__instructions-column">
                <label>Additional notes</label>
                <Textarea
                  isFullWidth
                  resizeDisabled
                  value={linkBuildingData.additionalNotes || ''}
                  onChange={(e) => handleInputChange('additionalNotes', e.target.value)}
                />
              </div>
            </div>
            {advancedSettings && (
              <AdvancedSettingsWrapper>
                <div className="ai-agents-page__advanced-column">
                  <div className="ai-agents-page-container">
                    <div className="ai-agents-page-section">
                      <h4>Autonomy level</h4>
                      <div className="ai-agents-page-section__item">
                        <label
                          htmlFor="launching-campaigns"
                          data-for="ai-column-label1"
                          className="ai-agents-page-label"
                          data-tip=""
                        >
                          Launching campaigns{' '}
                          <SVGIcon icon="questionInCircleFilled" size={12} color="#C6C6C6" />
                          <ReactTooltip
                            id="ai-column-label1"
                            place="right"
                            effect="solid"
                            arrowColor="transparent"
                          >
                            <div>Some text</div>
                          </ReactTooltip>
                        </label>
                        <Select
                          id="launching-campaigns"
                          options={automationAutonomyOptions}
                          value={autonomyLevel}
                          onChange={(opt) => onChangeAutonomyLevel(opt)}
                        />
                      </div>
                      {/* <div className="ai-agents-page-section__item"> */}
                      {/*  <label */}
                      {/*    htmlFor="email-replies" */}
                      {/*    data-for="ai-column-label2" */}
                      {/*    className="ai-agents-page-label" */}
                      {/*    data-tip="" */}
                      {/*  > */}
                      {/*    Email replies (inbox){' '} */}
                      {/*    <SVGIcon icon="questionInCircleFilled" size={12} color="#C6C6C6" /> */}
                      {/*    <ReactTooltip */}
                      {/*      id="ai-column-label2" */}
                      {/*      place="right" */}
                      {/*      effect="solid" */}
                      {/*      arrowColor="transparent" */}
                      {/*    > */}
                      {/*      <div>Some text</div> */}
                      {/*    </ReactTooltip> */}
                      {/*  </label> */}
                      {/*  <Select */}
                      {/*    id="email-replies" */}
                      {/*    options={[]} */}
                      {/*    value="Requires approval" */}
                      {/*    onChange={() => {}} */}
                      {/*    additionalComponents={{ Option: 'Requires approval' }} */}
                      {/*  /> */}
                      {/* </div> */}
                      {/* <div className="ai-agents-page-section__item"> */}
                      {/*  <label */}
                      {/*    htmlFor="writing-articles" */}
                      {/*    data-for="ai-column-label3" */}
                      {/*    className="ai-agents-page-label" */}
                      {/*    data-tip="" */}
                      {/*  > */}
                      {/*    Writing articles{' '} */}
                      {/*    <SVGIcon icon="questionInCircleFilled" size={12} color="#C6C6C6" /> */}
                      {/*    <ReactTooltip */}
                      {/*      id="ai-column-label3" */}
                      {/*      place="right" */}
                      {/*      effect="solid" */}
                      {/*      arrowColor="transparent" */}
                      {/*    > */}
                      {/*      <div>Some text</div> */}
                      {/*    </ReactTooltip> */}
                      {/*  </label> */}
                      {/*  <Select */}
                      {/*    id="writing-articles" */}
                      {/*    options={[]} */}
                      {/*    value="Fully autonomous" */}
                      {/*    onChange={() => {}} */}
                      {/*    additionalComponents={{ Option: 'Fully autonomous' }} */}
                      {/*  /> */}
                      {/* </div> */}
                    </div>
                    <div className="ai-agents-page-section">
                      <h4>Notifications</h4>
                      <div className="ai-agents-page-section__item">
                        <label
                          htmlFor="send-notifications"
                          data-for="ai-column-label4"
                          className="ai-agents-page-label"
                          data-tip=""
                        >
                          Send notifications to{' '}
                          <SVGIcon icon="questionInCircleFilled" size={12} color="#C6C6C6" />
                          <ReactTooltip
                            id="ai-column-label4"
                            place="right"
                            effect="solid"
                            arrowColor="transparent"
                          >
                            <div>Some text</div>
                          </ReactTooltip>
                        </label>
                        <Select
                          id="send-notifications"
                          options={usersOptions}
                          value={sendNotificationsToUser}
                          onChange={(item) => onChangeSendNotificationsTo(item)}
                          additionalComponents={{}}
                        />
                      </div>
                      {/* <div className="ai-agents-page-section__item mb-small"> */}
                      {/*  <label */}
                      {/*    htmlFor="notification-email" */}
                      {/*    data-for="ai-column-label5" */}
                      {/*    className="ai-agents-page-label" */}
                      {/*    data-tip="" */}
                      {/*  > */}
                      {/*    Notification email{' '} */}
                      {/*    <SVGIcon icon="questionInCircleFilled" size={12} color="#C6C6C6" /> */}
                      {/*    <ReactTooltip */}
                      {/*      id="ai-column-label5" */}
                      {/*      place="right" */}
                      {/*      effect="solid" */}
                      {/*      arrowColor="transparent" */}
                      {/*    > */}
                      {/*      <div>Some text</div> */}
                      {/*    </ReactTooltip> */}
                      {/*  </label> */}
                      {/*  <Input id="notification-email" value="tarzad@respona.com" /> */}
                      {/* </div> */}
                      {/* <div className="ai-agents-page-toggle"> */}
                      {/*  <SlideToggler */}
                      {/*    value={isCampaignLaunched} */}
                      {/*    onChange={() => setIsCampaignLaunched(!isCampaignLaunched)} */}
                      {/*  /> */}
                      {/*  <div className="ai-agents-page-toggle__title"> */}
                      {/*    New campaign is launched */}
                      {/*  </div> */}
                      {/* </div> */}
                      {/* <div className="ai-agents-page-toggle"> */}
                      {/*  <SlideToggler */}
                      {/*    value={isEmailReply} */}
                      {/*    onChange={() => setIsEmailReply(!isEmailReply)} */}
                      {/*  /> */}
                      {/*  <div className="ai-agents-page-toggle__title"> */}
                      {/*    Email reply requires human attention */}
                      {/*  </div> */}
                      {/* </div> */}
                      {/* <div className="ai-agents-page-toggle"> */}
                      {/*  <SlideToggler */}
                      {/*    value={isActivity} */}
                      {/*    onChange={() => setIsActivity(!isActivity)} */}
                      {/*  /> */}
                      {/*  <div className="ai-agents-page-toggle__title"> */}
                      {/*    Daily/WeekhMonthly activity summary */}
                      {/*  </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </AdvancedSettingsWrapper>
            )}
          </div>
        )}
      </Loader>
    </div>
  );
}

export default AiAgentsChat;
