import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { resetContentAutomationConfigState } from '@redux/actions/automationContent.actions';
import { csClearOpenCampaignInfo } from '@redux/actions/campaignSettings.actions';

import OpportunitiesCampaignSettingsPage from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import Personalize from '../PersonalizeCampaignSettingsPage/PersonalizeCampaignSettingsPage';
import CampaignSettingsWrapper from '../../components/CampaignSettingsWrapper/CampaignSettingsWrapper';
import SequenceCampaignSettingsPage from '../SequenceCampaignSettingsPage/SequenceCampaignSettingsPage';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

export default (): JSX.Element => {
  const dispatch = useDispatch();
  const { campaignId, step } = useParams();
  const workspaceId = useCurrentWorkspaceId();

  if (!Number.isInteger(Number(campaignId))) {
    return <Redirect to={`/workspaces/${workspaceId}/campaigns`} />;
  }

  useEffect(() => {
    return () => {
      dispatch(csClearOpenCampaignInfo());
      dispatch(resetContentAutomationConfigState());
    };
  }, []);

  return (
    <CampaignSettingsWrapper step={step} campaignId={Number(campaignId)}>
      <Switch>
        <Route path="/workspaces/:workspaceId?/campaign-builder/:campaignId/sequence">
          <SequenceCampaignSettingsPage />
        </Route>
        <Route path="/workspaces/:workspaceId?/campaign-builder/:campaignId/opportunities">
          <OpportunitiesCampaignSettingsPage />
        </Route>
        <Route path="/workspaces/:workspaceId?/campaign-builder/:campaignId/personalize">
          <Personalize />
        </Route>
        <Route path="/workspaces/:workspaceId?/campaign-builder/:campaignId">
          <Redirect to={`/workspaces/${workspaceId}/campaign-builder/${campaignId}/sequence`} />
        </Route>
      </Switch>
    </CampaignSettingsWrapper>
  );
};
