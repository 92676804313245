import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  launchCampaignAutomationApi,
  openCampaignAutomationApi,
  renameCampaignAutomationApi,
} from '@api/campaignAutomation.api';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import PageHeader from '@components/PageHeader/PageHeader';

import { CampaignAutomationDetailsType } from '@ts/automationCampaigns.types';
import { redirectUserTo } from '@utils/historyHandler';
import { isAllFieldsFull } from '@utils/isAllFieldsFull';
import urls from '@constants/urls';
import { addNotification } from '@redux/actions/notifications.actions';

function AiAgentsWrapperPage({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const queryClient = useQueryClient();
  const [isLaunched, setIsLaunched] = useState<boolean>(false);

  const agentId = useMemo(() => {
    if (pathname?.length > 0) {
      const match = pathname.match(/ai-agents\/([^/]+)/);
      const urlPart = match ? match[1] : undefined;
      if (urlPart?.length > 0 && Number.isInteger(Number(urlPart))) {
        return Number(urlPart);
      }
    }
    return -1;
  }, [pathname]);

  const queryAgentKey = ['ai-agent', workspaceId, agentId];

  const { data: agent } = useQuery<CampaignAutomationDetailsType>(
    queryAgentKey,
    () => openCampaignAutomationApi(agentId, workspaceId),
    {
      enabled: agentId > 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const handleTitleUpdate = (newTitle: string) => {
    renameCampaignAutomationApi(agent, workspaceId, newTitle).then((response) => {
      queryClient.setQueryData(queryAgentKey, (cache: CampaignAutomationDetailsType) => {
        return {
          ...cache,
          ...response,
        };
      });
    });
  };

  const launchAgent = () => {
    launchCampaignAutomationApi(agentId, workspaceId).then((res) => {
      redirectUserTo(history, urls.AI_AGENTS(workspaceId));
    });
  };

  const isInstructionsFilled = useMemo(() => {
    if (!agent) {
      return false;
    }
    return isAllFieldsFull(agent.linkBuilding, [
      'additionalNotes',
      'targetPageToPreferredAnchorList',
      'incentive',
    ]);
  }, [dispatch, agent, agent?.linkBuilding, agent?.linkBuilding?.parentKeywordsList]);

  const isSettingsFilled = useMemo(() => {
    if (!agent) {
      return false;
    }
    return isAllFieldsFull(agent.settings, ['notificationEmail', 'autonomyLevel']);
  }, [dispatch, agent, agent?.settings, agent?.settings.mailboxAccountIdsList]);

  const isDisabledSwitcher = useMemo(() => {
    const isReady = isInstructionsFilled && isSettingsFilled;
    return !isReady;
  }, [isInstructionsFilled, isSettingsFilled]);

  return (
    <div className="ai-agents-page">
      <div className="ai-agents-page__content">
        <PageHeader
          title={agent?.title}
          isEditable
          onUpdate={handleTitleUpdate}
          withCloseButton
          backArrowForceLink={`/workspaces/${workspaceId}/ai-agents`}
          activeSwitcher
          isDisabledSwitcher={isDisabledSwitcher}
          switcherHandler={() => {
            launchAgent();
            setIsLaunched(true);
          }}
          isFixed
        />
        {React.cloneElement(children, { agent, queryAgentKey })}
      </div>
    </div>
  );
}

export default AiAgentsWrapperPage;
