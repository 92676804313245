/**
 * NOT CHECKED
 */
import React, { useState, useEffect } from 'react';
import cn from 'class-names';

import './Tabs.scss';
import HorizontalScrollWithArrows from '@uikit/HorizontalScrollWithArrows/HorizontalScrollWithArrows';

export type TabConfigType = {
  title?: string;
  renderTitle?: (isActive?: boolean) => JSX.Element;
  key: string;
  renderContent: () => JSX.Element;
};

const Tabs = ({ tabs }: { tabs: TabConfigType[] }): JSX.Element => {
  const [activeTabKey, changeActiveTabKey] = useState(null);

  useEffect(() => {
    if (activeTabKey === null && tabs.length > 0) {
      changeActiveTabKey(tabs[0].key);
    }
  }, [tabs]);

  const getTabTitleClassName = (tabKey: string): string =>
    cn('tabs__tab-title', {
      'tabs__tab-title--active': tabKey === activeTabKey,
    });

  const { renderContent: RenderActiveContent } = tabs.find(({ key }) => key === activeTabKey) || {};

  return (
    <div className="tabs">
      <HorizontalScrollWithArrows withBottomBorder={false}>
        <div className="tabs__list">
          {tabs.map(({ title, key, renderTitle }) => (
            <div
              className={getTabTitleClassName(key)}
              key={key}
              onClick={() => changeActiveTabKey(key)}
            >
              {renderTitle ? renderTitle(key === activeTabKey) : title}
            </div>
          ))}
        </div>
      </HorizontalScrollWithArrows>
      <div className="tabs__active-content">
        {RenderActiveContent ? <RenderActiveContent /> : null}
      </div>
    </div>
  );
};

export default Tabs;
