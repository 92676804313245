import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import cn from 'class-names';

import { Option } from '@uikit/Select/selectTypes';

import Button from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import { SVGIcon } from '@uikit/Icon/Icon';
import { manualSearchTypes } from '@constants/manualSearch.constants';
import Display from '@components/Display/Display';
import getDomainFromUrl from '@utils/getDomainFromUrl';

import { OpportunityPageType } from '@ts/opportunity.types';

import './ContactsSearchField.scss';
import PersonSidebar from '@components/PersonSidebar/PersonSidebar';
import { createPersonApi } from '@api/people.api';
import { PersonBaseType } from '@ts/people.types';
import { isSemrushMode } from '@constants/app-modes';

type SearchType = (typeof manualSearchTypes)[keyof typeof manualSearchTypes];

declare const INTEGRATION_MODE: string;

type ContactsSearchFieldPropsType = {
  onSearchContacts: (searchOptions: FieldsType) => void;
  onAddContact?: (person: PersonBaseType, email: string, accuracy: number) => void;
  searchType: SearchType;
  activeOpportunity?: OpportunityPageType;
  isInputLabel?: string;
};

type FieldsType = {
  domainString?: string;
  jobPosition?: string;
  fullName?: string;
  domain?: string;
  linkedIn?: string;
  department?: Option[];
};

function ContactsSearchField({
  onSearchContacts,
  onAddContact,
  searchType,
  activeOpportunity,
  isInputLabel,
}: ContactsSearchFieldPropsType): JSX.Element {
  const cnContactSearchField = cn('contacts-search-field', {
    'contacts-search-field--two-columns': searchType === manualSearchTypes.linkedin,
  });

  const buttonRef = useRef<HTMLInputElement>();

  const handleSubmit = (values: FieldsType) => {
    const fields: FieldsType = {};

    // eslint-disable-next-line default-case
    switch (searchType) {
      case manualSearchTypes.domain:
        fields.domainString = values.domainString.trim();
        fields.jobPosition = values.jobPosition.trim();
        break;
      case manualSearchTypes.name:
        fields.fullName = values.fullName.trim();
        fields.domainString = values.domainString.trim();
        break;
      case manualSearchTypes.linkedin:
        fields.linkedIn = values.linkedIn.trim();
        break;
    }

    onSearchContacts(fields);
  };

  const handleCreatePerson = (newPerson: any) =>
    createPersonApi(newPerson).then((personDetails) =>
      onAddContact(
        personDetails,
        personDetails.emailsList[0].email,
        personDetails.emailsList[0].accuracy
      )
    );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        domainString: getDomainFromUrl(activeOpportunity?.url) || '',
        jobPosition: '',
        fullName: '',
        linkedIn: '',
        department: [],
      }}
    >
      {({ values, handleChange, setFieldValue }) => {
        useEffect(() => {
          if (!activeOpportunity) {
            return;
          }

          setFieldValue('domainString', getDomainFromUrl(activeOpportunity?.url));
        }, [activeOpportunity]);

        if (searchType === manualSearchTypes.manually) {
          return <PersonSidebar isOpen onCreate={handleCreatePerson} />;
        }

        return (
          <Form>
            <div className={cnContactSearchField}>
              <Display isVisible={searchType === manualSearchTypes.domain}>
                <div>
                  <Input
                    name="domainString"
                    icon="link"
                    iconSize={16}
                    iconColor="#251FB6"
                    className="contacts-search-field__domain-value"
                    value={values.domainString}
                    onChange={handleChange}
                    placeholder={isSemrushMode(INTEGRATION_MODE) ? 'semrush.com' : 'respona.com'}
                    isFullWidth
                    onKeyDown={(e) => (e.key === 'Enter' ? buttonRef.current?.click() : null)}
                  />
                </div>

                <div>
                  {isInputLabel && (
                    <label className="input__field_label" htmlFor="jobPosition">
                      {isInputLabel}
                    </label>
                  )}
                  <Input
                    name="jobPosition"
                    icon="suitcase"
                    iconColor="#251FB6"
                    value={values.jobPosition}
                    onChange={handleChange}
                    placeholder="marketing"
                    isFullWidth
                    onKeyDown={(e) => (e.key === 'Enter' ? buttonRef.current?.click() : null)}
                    id="jobPosition"
                  />
                </div>
              </Display>
              <Display isVisible={searchType === manualSearchTypes.name}>
                <Input
                  name="domainString"
                  icon="link"
                  iconSize={16}
                  iconColor="#251FB6"
                  value={values.domainString}
                  onChange={handleChange}
                  placeholder={isSemrushMode(INTEGRATION_MODE) ? 'semrush.com' : 'respona.com'}
                  isFullWidth
                  onKeyDown={(e) => (e.key === 'Enter' ? buttonRef.current?.click() : null)}
                />
                {isInputLabel && (
                  <label className="input__field_label" htmlFor="fullName">
                    {isInputLabel}
                  </label>
                )}
                <Input
                  name="fullName"
                  icon="user"
                  iconColor="#251FB6"
                  className="contacts-search-field__domain-value"
                  value={values.fullName}
                  onChange={handleChange}
                  placeholder="Farzad Rashidi"
                  isFullWidth
                  onKeyDown={(e) => (e.key === 'Enter' ? buttonRef.current?.click() : null)}
                  id="fullName"
                />
              </Display>
              <Display isVisible={searchType === manualSearchTypes.linkedin}>
                <Input
                  name="linkedIn"
                  icon="linkedin"
                  iconColor="#251FB6"
                  value={values.linkedIn}
                  onChange={handleChange}
                  placeholder="linkedin.com/in/farzadrashidi/"
                  isFullWidth
                  onKeyDown={(e) => (e.key === 'Enter' ? buttonRef.current?.click() : null)}
                />
              </Display>
              <div className="contacts-search-field__search-btn">
                <Button isSubmit innerRef={buttonRef}>
                  <SVGIcon icon="search" />
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContactsSearchField;
