import { handleActions } from 'redux-actions';

import {
  setInsightsFiltersCampaignIds,
  setInsightsFiltersPeopleIds,
  setInsightsDateFilter,
  throwInsightsFilter,
  setInsightsCampaignPerformanceFilters,
  setInsightsLeaderBoardsFilters,
  setInsightsCreditsUsageByUserFilters,
} from '@redux/actions/insights.actions';
import { DateRangeNumberType } from '@ts/common.types';

import { InsightsTypeMapper } from '@mappers/insights.mapper';
import { InsightsFilterField, InsightsFilterFieldMap } from 'respona_api/generated/analytics_pb';
import { FilterOperation } from 'respona_api/generated/common_pb';
import { FilterJoins } from '@constants/filters/filterOperations';

export const initialInsightsFilterState = {
  order: 0,
  field: InsightsFilterField.DROPDOWN_INSIGHT_WORKSPACE_ID,
  value: '',
  operation: FilterOperation.STRING_EQUAL,
  join: FilterJoins[0].value,
};

export type InsightFilterType = {
  type: InsightsFilterFieldMap[keyof InsightsFilterFieldMap];
  peopleIds?: number[];
  campaignsIds?: number[];
};

export type InsightsStateType = {
  filters: InsightFilterType;
  campaignPerformanceFilters: any;
  leaderBoardsFilters: any;
  creditsUsageByUserFilters: any;
  dateFilter: DateRangeNumberType;
};

const initialState: InsightsStateType = {
  filters: {
    type: InsightsTypeMapper.DROPDOWN_INSIGHT_WORKSPACE_ID,
    peopleIds: [],
    campaignsIds: [],
  },
  campaignPerformanceFilters: [initialInsightsFilterState],
  leaderBoardsFilters: [initialInsightsFilterState],
  creditsUsageByUserFilters: [initialInsightsFilterState],
  dateFilter: {
    startDate: null,
    endDate: null,
  },
};

export default handleActions(
  {
    [setInsightsFiltersPeopleIds]: (state: InsightsStateType, { payload }): InsightsStateType => ({
      ...state,
      filters: {
        type: InsightsTypeMapper.DROPDOWN_INSIGHT_USER_ID,
        peopleIds: payload,
        campaignsIds: [],
      },
    }),
    [setInsightsFiltersCampaignIds]: (
      state: InsightsStateType,
      { payload }
    ): InsightsStateType => ({
      ...state,
      filters: {
        type: InsightsTypeMapper.DROPDOWN_INSIGHT_CAMPAIGN_ID,
        campaignsIds: payload,
        peopleIds: [],
      },
    }),
    [setInsightsDateFilter]: (state: InsightsStateType, { payload }): InsightsStateType => {
      return {
        ...state,
        dateFilter: payload,
      };
    },
    [throwInsightsFilter]: (): InsightsStateType => {
      return initialState;
    },
    [setInsightsCampaignPerformanceFilters]: (
      state: InsightsStateType,
      { payload }
    ): InsightsStateType => ({
      ...state,
      campaignPerformanceFilters: payload,
    }),
    [setInsightsLeaderBoardsFilters]: (
      state: InsightsStateType,
      { payload }
    ): InsightsStateType => ({
      ...state,
      leaderBoardsFilters: payload,
    }),
    [setInsightsCreditsUsageByUserFilters]: (
      state: InsightsStateType,
      { payload }
    ): InsightsStateType => ({
      ...state,
      creditsUsageByUserFilters: payload,
    }),
  },
  initialState
);
