/**
 * NOT CHECKED
 */
import React, { useRef } from 'react';

import cn from 'class-names';

import sideScroll from '@uikit/HorizontalScrollWithArrows/scrollHelper';
import { SVGIcon } from '@uikit/Icon/Icon';

import useScrollTransform from '@hooks/useScrollTransform';

import './HorizontalScrollWithArrows.scss';

const HorizontalScrollWithArrows: React.FC<{ withBottomBorder?: boolean; className?: string }> = ({
  children,
  className,
  withBottomBorder = true,
}): JSX.Element => {
  const blockRef = useRef<HTMLDivElement>(null);
  const rightArrow = useRef<HTMLDivElement>(null);
  const leftArrow = useRef<HTMLDivElement>(null);

  const handleScrollingLeft = (e) => {
    e.preventDefault();
    sideScroll(blockRef.current, 'left', leftArrow.current, rightArrow.current);
  };

  const handleScrollingRight = (e) => {
    e.preventDefault();
    sideScroll(blockRef.current, 'right', leftArrow.current, rightArrow.current);
  };

  const isXOverflown = (element: HTMLDivElement) => {
    return element?.scrollWidth > element?.clientWidth;
  };

  const wrapperClass = cn('scroll-groups', className, {
    'scroll-groups--without-scrolling': blockRef.current && !isXOverflown(blockRef.current),
    'scroll-groups--with-bottom-border': withBottomBorder,
  });

  useScrollTransform(
    blockRef,
    () => {
      if (!blockRef.current || !leftArrow.current || !rightArrow.current) {
        return;
      }

      const maxScrollLeft = blockRef.current.scrollWidth - blockRef.current.clientWidth - 30;

      if (blockRef.current.scrollLeft >= 10 && leftArrow.current.style.opacity === '0') {
        leftArrow.current.style.opacity = '1';
        leftArrow.current.style.zIndex = '1';
      } else if (blockRef.current.scrollLeft <= 10 && leftArrow.current.style.opacity !== '0') {
        leftArrow.current.style.opacity = '0';
        leftArrow.current.style.zIndex = '-1';
      }

      if (
        blockRef.current.scrollLeft >= maxScrollLeft &&
        rightArrow.current.style.opacity !== '0'
      ) {
        rightArrow.current.style.opacity = '0';
      } else if (
        blockRef.current.scrollLeft < maxScrollLeft &&
        rightArrow.current.style.opacity === '0'
      ) {
        rightArrow.current.style.opacity = '1';
      }
    },
    [blockRef.current, leftArrow.current, rightArrow.current]
  );

  return (
    <div ref={blockRef} className={wrapperClass}>
      <div
        ref={leftArrow}
        onClick={handleScrollingLeft}
        style={{ display: isXOverflown(blockRef.current) ? 'flex' : 'none' }}
        className="scroll-groups__arrow scroll-groups__arrow--left"
        tabIndex={0}
        role="button"
      >
        <div className="scroll-groups__arrow-container">
          <SVGIcon icon="arrowRightGrey" color="#000000" />
        </div>
      </div>

      <div
        className={cn('scroll-groups__children', {
          'scroll-groups__children--no-scroll': blockRef.current && !isXOverflown(blockRef.current),
        })}
      >
        {children}
      </div>

      <div
        ref={rightArrow}
        onClick={handleScrollingRight}
        style={{ display: isXOverflown(blockRef.current) ? 'flex' : 'none' }}
        className="scroll-groups__arrow scroll-groups__arrow--right"
        tabIndex={0}
        role="button"
      >
        <div className="scroll-groups__arrow-container">
          <SVGIcon icon="arrowRightGrey" color="#000000" />
        </div>
      </div>
    </div>
  );
};

export default HorizontalScrollWithArrows;
