import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { DispatchType } from 'src/store';

import { verifyEmailApi } from '@api/searchContacts.api';

import { fetchBillingCredits } from '@redux/thunks/billings.thunks';

import Loader from '@uikit/Loader/Loader';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';

import Display from '@components/Display/Display';
import PageHeader from '@components/PageHeader/PageHeader';
import ToolboxEmailVerifierStatus, {
  ToolboxEmailVerifierStatusName,
  ToolboxEmailVerifierStatusText,
} from '@components/Toolbox/ToolboxEmailVerifier/_components/ToolboxEmailVerifierStatus/ToolboxEmailVerifierStatus';

import './ToolboxEmailVerifier.scss';
import ClickToShowMore from '@components/CampaignSettings/ManualConfirmContactSideBar/_components/Plugs/ClickToShowMore';
import {getCurrentWorkspaceId} from "@redux/selectors/workspaces.selectors";

const ToolboxEmailVerifier = ({
  setUpsellCreditsSidebarOpen,
}: {
  setUpsellCreditsSidebarOpen: (open: boolean) => void;
}): JSX.Element => {
  const dispatch = useDispatch<DispatchType>();
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [email, setEmail] = useState<string>('');

  const { data, refetch, isFetching } = useQuery<any, Error>({
    queryFn: () => verifyEmailApi(email.trim(), currentWorkspaceId),
    onSuccess: () => {
      dispatch(fetchBillingCredits());
    },
    onError: (e) => {
      if (e.message?.includes('enough credits')) {
        setUpsellCreditsSidebarOpen(true);
      }
    },
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return (
    <div className="toolbox-email-verifier">
      <PageHeader title="Email Verifier" />

      <div className="toolbox-email-verifier__content">
        <div className="toolbox-email-verifier__search">
          <Input
            name="email"
            icon="email"
            type="email"
            iconColor="#251FB6"
            className="toolbox-email-verifier__search-input"
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            placeholder="farzad@piedpiper.com"
            isFullWidth
          />

          <div className="toolbox-email-verifier__search-btn">
            <Button onClick={refetch}>
              <SVGIcon icon="search" />
            </Button>
          </div>
        </div>

        <Display isVisible={!data && !isFetching}>
          <ClickToShowMore title="Click search to verify emails" />
        </Display>

        {!!data || isFetching ? (
          <Loader withTopMargin isLoading={isFetching}>
            <div>
              <div className="toolbox-email-verifier__status-header">
                <div>
                  Status
                  <span data-for="status-tooltip" data-tip="">
                    <SVGIcon icon="questionInCircleFilled" />
                  </span>
                  <ReactTooltip
                    id="status-tooltip"
                    className="react-tooltip"
                    place="bottom"
                    effect="solid"
                    arrowColor="transparent"
                  >
                    <div className="toolbox-email-verifier__tooltip-content">
                      <div>
                        We verify if the email address exists and can receive emails. Possible
                        results:
                      </div>
                      <div>Valid: You can use this email address safely.</div>
                      <div>
                        Catch-all: This domain accepts all emails. Email verification is not
                        possible.
                      </div>
                      <div>Invalid: We do not recommend using this email address.</div>
                    </div>
                  </ReactTooltip>
                </div>
                <div>
                  Format
                  <span data-for="format-tooltip" data-tip="">
                    <SVGIcon icon="questionInCircleFilled" />
                  </span>
                  <ReactTooltip
                    id="format-tooltip"
                    className="react-tooltip"
                    place="bottom"
                    effect="solid"
                    arrowColor="transparent"
                  >
                    <div className="toolbox-email-verifier__tooltip-content">
                      We verify if email address has the correct format.
                    </div>
                  </ReactTooltip>
                </div>
                <div>
                  Type
                  <span data-for="type-tooltip" data-tip="">
                    <SVGIcon icon="questionInCircleFilled" />
                  </span>
                  <ReactTooltip
                    id="type-tooltip"
                    className="react-tooltip"
                    place="bottom"
                    effect="solid"
                    arrowColor="transparent"
                  >
                    <div className="toolbox-email-verifier__tooltip-content">
                      <div>
                        We identify the type of emails and make sure they aren’t temporary inboxes.
                      </div>
                      <div>Possible results are: Work, Personal, Generic.</div>
                    </div>
                  </ReactTooltip>
                </div>
                <div>
                  Server status
                  <span data-for="server-status-tooltip" data-tip="">
                    <SVGIcon icon="questionInCircleFilled" />
                  </span>
                  <ReactTooltip
                    id="server-status-tooltip"
                    className="react-tooltip"
                    place="bottom"
                    effect="solid"
                    arrowColor="transparent"
                  >
                    <div className="toolbox-email-verifier__tooltip-content">
                      We check the domain’s MX records and see if we can connect to the SMTP server.
                    </div>
                  </ReactTooltip>
                </div>
              </div>

              <div className="toolbox-email-verifier__status">
                <div>
                  <ToolboxEmailVerifierStatus
                    status={data?.status as ToolboxEmailVerifierStatusName}
                  />
                </div>
                <div>
                  <ToolboxEmailVerifierStatusText
                    status={data?.format as ToolboxEmailVerifierStatusName}
                  >
                    {data?.format}
                  </ToolboxEmailVerifierStatusText>
                </div>
                <div>
                  <ToolboxEmailVerifierStatusText>{data?.type}</ToolboxEmailVerifierStatusText>
                </div>
                <div>
                  <ToolboxEmailVerifierStatusText
                    status={data?.serverStatus as ToolboxEmailVerifierStatusName}
                  >
                    {data?.serverStatus}
                  </ToolboxEmailVerifierStatusText>
                </div>
              </div>
            </div>
          </Loader>
        ) : null}
      </div>
    </div>
  );
};

export default ToolboxEmailVerifier;
