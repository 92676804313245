/**
 * NOT CHECKED
 */
import { nanoid } from 'nanoid';
import React, { memo, useEffect, useState } from 'react';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';

import './Textarea.scss';

type InputPropsType = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  onKeyDown?: (any) => void;
  isTransparent?: boolean;
  isClearable?: boolean;
  resizeDisabled?: boolean;
  onClear?: () => void;
  isError?: boolean;
  autosize?: boolean;
  error?: any;
  maxHeight?: number;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, InputPropsType>((props, ref) => {
  const [id] = useState(nanoid());

  const {
    value,
    placeholder,
    onChange,
    isFullWidth,
    isFullHeight,
    onKeyDown,
    isTransparent,
    isClearable,
    resizeDisabled,
    onClear,
    disabled,
    error,
    isError,
    className,
    autosize,
    maxHeight = 200,
    ...textAreaProps
  } = props;

  const cnContainer = cn('textarea', {
    'textarea--disabled': disabled,
    'textarea--transparent': isTransparent,
    'textarea--full-width': isFullWidth,
    'textarea--full-height': isFullHeight,
    'textarea--clearable': isClearable,
    'textarea--not-resizable': resizeDisabled,
    'textarea--error': error?.length > 0 || isError,
    [className]: className?.length > 0,
  });

  useEffect(() => {
    if (!autosize) {
      return;
    }

    const textarea = document.getElementById(id);

    // const shadowTextarea = document.createElement('textarea')
    // shadowTextarea.style.width = textarea.style.width;
    // shadowTextarea.value = placeholder;

    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 2 + 'px';
    textarea.style.maxHeight = (value ? textarea.scrollHeight : Math.max(textarea.scrollHeight, 134)) + 2 + 'px';

    textarea.addEventListener('input', function (e) {
      // @ts-ignore
      // console.log(this.value, placeholder);
      // const shadowTextarea = document.createElement('textarea');
      // shadowTextarea.style.width = this.clientWidth + 'px'; // @ts-ignore
      // shadowTextarea.innerHTML = this.value || placeholder;
      // shadowTextarea.placeholder = placeholder;
      // shadowTextarea.classList.add(...this.classList);
      // shadowTextarea.style.height = 'auto';
      //
      // console.log(this, shadowTextarea, this.scrollHeight, shadowTextarea.scrollHeight);
      // this.style.height = shadowTextarea.scrollHeight + 2 + 'px';
      // document.removeChild(shadowTextarea);
      // return;

      // @ts-ignore
      // console.log('reglar', e.target.value, e.target.data);
      //
      // const a = this.cloneNode(true) as HTMLTextAreaElement;
      // a.getBoundingClientRect();

      // a.style.height = 'auto';
      //
      // console.log(this.scrollHeight)

      const minHeight = window.getComputedStyle(this, null).getPropertyValue('min-height');
      const height = window.getComputedStyle(this, null).getPropertyValue('height');

      this.style.height = 'auto';
      this.style.maxHeight = `${maxHeight}px`;
      const a = this.scrollHeight;

      this.style.height = height || minHeight;
      setTimeout(() => (this.style.height = a + 2 + 'px'), 3);
    });
  }, [autosize]);

  return (
    <div className={cnContainer}>
      <textarea
        id={id}
        ref={ref}
        placeholder={placeholder}
        onChange={onChange && !disabled ? onChange : null}
        value={value}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className="textarea__field"
        {...textAreaProps}
      />

      {isClearable && value !== '' ? (
        <div className="textarea__clear-icon" onClick={onClear}>
          <SVGIcon icon="crossDelete" />
        </div>
      ) : null}
      {error ? <span className="textarea__error">{error}</span> : null}
    </div>
  );
});

export default memo(Textarea);
