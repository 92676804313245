import createAction from '../utils/createAction';
import { InspectionAutomationSettings } from '@ts/automationInspection.types';

export const SET_INSPECTION_AUTOMATION_DATA = 'SET_INSPECTION_AUTOMATION_DATA';
export const UPDATE_CURRENT_INSPECTION_AUTOMATION_DATA =
  'UPDATE_CURRENT_INSPECTION_AUTOMATION_DATA';

export const setInspectionAutomationData = createAction<InspectionAutomationSettings>(
  SET_INSPECTION_AUTOMATION_DATA
);

export const updateCurrentInspectionAutomationData = createAction<
  Partial<InspectionAutomationSettings>
>(UPDATE_CURRENT_INSPECTION_AUTOMATION_DATA);
