import React from 'react';

import cn from 'class-names';
import { addNotification } from '@redux/actions/notifications.actions';
import { useDispatch } from 'react-redux';

type SearchTypesTabItemType = {
  isActive: boolean;
  title: string;
  onClick: () => void;
  style?: React.CSSProperties;
  icon?: () => JSX.Element;
  isBlurred?: boolean;
};

const SearchTypesTabItem = ({
  isActive,
  title,
  onClick,
  style,
  icon: Icon,
  isBlurred,
}: SearchTypesTabItemType): JSX.Element => {
  const dispatch = useDispatch();
  const cnItem = cn('search-types-tab__item', {
    'search-types-tab__item--active': isActive,
    'search-types-tab__item--blurred': isBlurred,
  });

  const handleClick = (): boolean => {
    if (isBlurred) {
      dispatch(
        addNotification({
          type: 'warning',
          title: 'For this campaign template, please use available automation type',
        })
      );
      return false;
    }

    onClick();
    return true;
  };

  return (
    <div className={cnItem} style={style} onClick={handleClick}>
      {Icon ? <Icon /> : null}
      <span className="search-types-tab__item-text">{title}</span>
    </div>
  );
};

export default SearchTypesTabItem;
