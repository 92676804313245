import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useDispatch, useSelector } from 'react-redux';

import { SVGIcon } from '@uikit/Icon/Icon';
import ControlledReactTooltip from '@uikit/ControlledReactTooltip/ControlledReactTooltip';
import { Button } from '@uikit/Button/Button';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

import {
  setInboxFilters,
  setSelectedInboxStatus,
  setSelectedMailboxesIds,
} from '@redux/actions/inbox.actions';
import { initialInboxFiltersState } from '@redux/reducers/inbox.reducer';
import {
  inboxFiltersSelector,
  inboxSelectedMailboxesIdsSelector,
  inboxSelectedStatusesSelector,
} from '@redux/selectors/inbox.selectors';

import { GlobalInboxFilterRequestType } from '@ts/inbox.types';

import './InboxFilters.scss';
import { ThreadStatus, ThreadStatusMap } from 'respona_api/generated/mailbox_pb';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { InboxAccountsPresetType } from '@ts/mailboxInbox.types';
import inboxFiltersConfig from './config';
import { isSemrushMode } from '@constants/app-modes';

declare const INTEGRATION_MODE: string;

function InboxFilters(): JSX.Element {
  const refToCloseTooltip = useRef(() => null);
  const dispatch = useDispatch();
  const inboxFilters = useSelector(inboxFiltersSelector);
  const inboxStatus = useSelector(inboxSelectedStatusesSelector);
  const inboxMailboxIds = useSelector(inboxSelectedMailboxesIdsSelector);
  const [mailboxIds, setMailboxIds] = useState([]);
  const [status, setStatus] = useState<ThreadStatusMap[keyof ThreadStatusMap]>(ThreadStatus.OPEN);
  const [filters, setFilters] = useState<GlobalInboxFilterRequestType>(initialInboxFiltersState);
  const [appliedFilter, setAppliedFilter] = useState<boolean>(
    JSON.stringify(filters) !== JSON.stringify(inboxFilters)
  );
  const currentWorkspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const { data, isFetching }: { data: InboxAccountsPresetType[]; isFetching: boolean } =
    queryClient.getQueryState(['inbox-mailboxes-preset', currentWorkspaceId]);

  const changeFilter =
    (filterKey: keyof GlobalInboxFilterRequestType) => (value: string | number | boolean) => {
      setFilters((prevState) => ({ ...prevState, [filterKey]: value }));
    };
  const changeStatusFilter = () => (value: ThreadStatusMap[keyof ThreadStatusMap]) => {
    setStatus(value);
  };

  const changeAccountsFilter = () => (values: number[]) => {
    setMailboxIds(values);
  };

  const setFiltersIntoRedux = () => {
    dispatch(setSelectedInboxStatus(status));
    dispatch(setSelectedMailboxesIds(mailboxIds.map(({ value }) => value)));
    dispatch(setInboxFilters(filters));
    setAppliedFilter(true);
    refToCloseTooltip.current();
  };

  const throwInboxFilters = () => {
    dispatch(setSelectedInboxStatus(ThreadStatus.OPEN));
    dispatch(setSelectedMailboxesIds([]));
    dispatch(setInboxFilters(initialInboxFiltersState));
    refToCloseTooltip.current();
    setAppliedFilter(false);
  };

  const mapper = {
    From: changeFilter('fromEmail'),
    'To/CC/BCC': changeFilter('toEmail'),
    Subject: changeFilter('subjectQuery'),
    Status: changeStatusFilter(),
    Campaign: changeFilter('campaignId'),
    'Email Accounts': changeAccountsFilter(),
  };

  useEffect(() => {
    setFilters(inboxFilters);
  }, [inboxFilters]);

  useEffect(() => {
    setStatus(inboxStatus);
  }, [inboxStatus]);

  useEffect(() => {
    setMailboxIds(
      inboxMailboxIds.map((mailboxId) => {
        const mailbox = data.find(({ id }) => id === mailboxId);

        return {
          value: mailbox.id,
          label: mailbox.email,
        };
      })
    );
  }, [inboxMailboxIds]);

  function pickValue(filterField: string) {
    switch (filterField) {
      case 'statusId':
        return status;
      case 'accountId':
        return mailboxIds;
      default:
        return filters[filterField];
    }
  }

  return (
    <>
      <div className="inbox-filters__wrapper-filter-btn">
        <div
          className={`inbox-filters__filter-btn ${
            Object.values(inboxFilters).find((value) => !!value) &&
            'inbox-filters__filter-btn--active'
          }`}
          data-for="inbox-filters-tooltip"
          data-tip=""
        >
          <span className="inbox-filters-tooltip__icon">
            <SVGIcon icon="filter" color="#C4C6CD" />
          </span>
          <p>Filters</p>
        </div>
        {appliedFilter && (
          <span className="inbox-filters-tooltip__title-reset" onClick={throwInboxFilters}>
            <SVGIcon icon="crossInGreyCircle" color="#ED483D" />
          </span>
        )}
      </div>
      <ControlledReactTooltip
        tooltipRefToClose={refToCloseTooltip}
        id="inbox-filters-tooltip"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        event="click"
        globalEventOff="click"
        clickable
        offset={{ left: 125, bottom: 10 }}
        arrowColor="transparent"
      >
        <div className="inbox-filters-tooltip">
          <p className="inbox-filters-tooltip__title">
            Filters
          </p>
          <div className="inbox-filters-tooltip__content">
            {inboxFiltersConfig.map(({ text, RenderField, renderProps, filterField }) => {
              return (
                <div className="inbox-filters-tooltip__row" key={text}>
                  <p className="inbox-filters-tooltip__row-title">{text}</p>
                  <RenderField
                    renderProps={renderProps}
                    onGrabValue={(value) => mapper[text](value)}
                    value={pickValue(filterField)}
                  />
                </div>
              );
            })}
            {/*
              We have this items in design but there are not realized in API
            */}
            {/* <div className="inbox-filters-tooltip__row"> */}
            {/*  <p className="inbox-filters-tooltip__row-title">Mailboxes</p> */}
            {/*  <InboxSelector /> */}
            {/* </div> */}
            <div className="inbox-filters-tooltip__only-respona-toogler">
              <span className="inbox-filters-tooltip__only-respona-text">
                Only show {isSemrushMode(INTEGRATION_MODE) ? 'our' : 'Respona'} campaign emails
              </span>
              <SlideToggler
                value={filters.onlyRelatedToCampaign}
                onChange={changeFilter('onlyRelatedToCampaign')}
              />
            </div>
          </div>
          <div className="inbox-filters-tooltip__footer">
            <Button onClick={setFiltersIntoRedux}>Search</Button>
            <Button
              type="black"
              className="inbox-filters-tooltip__reset-btn"
              onClick={throwInboxFilters}
            >
              Reset
            </Button>
          </div>
        </div>
      </ControlledReactTooltip>
    </>
  );
}

export default InboxFilters;
