import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import './SelectedOpportunitiesWidget.scss';

import { getCampaignOpportunitiesApi } from '@api/campaign.api';
import { useRefCallback } from '@helpers/refHelpers';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { setRefetchOpportunities } from '@redux/actions/pipelines.actions';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

import { DispatchType } from 'src/store';
import { CampaignOpportunityFilterType, OpportunityPageType } from '@ts/opportunity.types';
import SelectedOpportunityTableRow from '@components/CampaignSettings/Opportunities/_components/SelectedOpportunityTableRow/SelectedOpportunityTableRow';
import Loader from '@uikit/Loader/Loader';
import Checkbox from '@uikit/Checkbox/Checkbox';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import OpportunityIllustration from '@uikit/Icon/illustrations/OpportunityIllustration';
import useCampaignPipelines from '@hooks/useCampaignPipelines';
import { isSemrushMode } from '@constants/app-modes';

function SelectedOpportunitiesWidget({
  checkboxes,
  setCheckboxes,
  totalItemsNumber,
  handleChangeCheckbox,
  setOpenOpportunitySidebar,
  setActiveOpportunity,
  setOpportunityIndex,
  filters,
  isOpportunitiesPresent,
  setIsOpportunitiesPresent,
  isSelectedAll,
  setSelectedAll,
}: {
  checkboxes?: number[];
  setCheckboxes: (ids: number[]) => void;
  handleChangeCheckbox?: (id: number) => void;
  totalItemsNumber: number | undefined;
  setOpenOpportunitySidebar: Dispatch<SetStateAction<boolean>>;
  setIsOpportunitiesPresent: Dispatch<SetStateAction<boolean>>;
  setSelectedAll: Dispatch<SetStateAction<boolean>>;
  setActiveOpportunity: Dispatch<SetStateAction<OpportunityPageType>>;
  setOpportunityIndex: Dispatch<SetStateAction<number>>;
  filters: CampaignOpportunityFilterType[];
  isSelectedAll: boolean;
  isOpportunitiesPresent: boolean;
}): JSX.Element {
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const dispatch = useDispatch<DispatchType>();
  const { members: workspaceMembers } = useWorkspaceMembers();
  const {
    info: { id: campaignId, workspaceId, lastBatch },
  } = useSelector(getActiveCampaignInfo);
  const { items: pipelines } = useCampaignPipelines(workspaceId, campaignId);

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery<OpportunityPageType[]>(
    ['opportunities-with-metrics', campaignId, workspaceId, filters],
    ({ pageParam = 0 }) =>
      getCampaignOpportunitiesApi(campaignId, workspaceId, pageParam, 12, lastBatch, filters),
    {
      getNextPageParam: (lastPage: OpportunityPageType[], allPages) => {
        if (lastPage.length < 12) return false;

        return allPages.length;
      },
      enabled: !!workspaceId && !!campaignId,
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  useEffect(() => {
    dispatch(setRefetchOpportunities(refetch));
  }, []);

  useEffect(() => {
    if (filters != null && filters.length > 0) {
      refetch();
    }
  }, [filters]);

  useEffect(() => {
    if (!isFetchingNextPage && isSelectedAll) {
      setCheckboxes(flatDataWithMetrics.map(({ id }) => id));
    }
  }, [isFetchingNextPage]);

  const flatDataWithMetrics: OpportunityPageType[] = data?.pages?.flat() || [];

  useEffect(() => {
    if (!isOpportunitiesPresent) {
      setIsOpportunitiesPresent(true);
    }
    if (flatDataWithMetrics.length > 0) {
      if (flatDataWithMetrics.length === checkboxes.length && !isSelectedAll) {
        setSelectedAll(true);
      }
    }
  }, [flatDataWithMetrics.length]);

  useIntersectionObserver(loadingRef, () => fetchNextPage(), [ready]);

  const handlerSelectAll = () => {
    if (flatDataWithMetrics.length === checkboxes.length) {
      setCheckboxes([]);
      setSelectedAll(false);
    } else {
      setCheckboxes(flatDataWithMetrics.map(({ id }) => id));
      setSelectedAll(true);
    }
  };

  const renderLoader = () => {
    if (isFetchingNextPage) {
      return <Loader isLoading withTopMargin />;
    }

    if (
      flatDataWithMetrics.length &&
      flatDataWithMetrics[0].totalItems > flatDataWithMetrics.length &&
      hasNextPage
    ) {
      return (
        <div ref={setLoadingRef} style={{ marginBottom: '20px' }}>
          <Loader isLoading withTopMargin />
        </div>
      );
    }

    return <div />;
  };

  const isBotWorking =
    pipelines.length === 1 &&
    pipelines.find(({ status }) => status === 0) &&
    flatDataWithMetrics.length === 0;

  const isFindContactPipelineRunning = !!pipelines.find(
    ({ status, type }) => type === 5 && status === 0
  );

  function composeSelectAllText() {
    if (totalItemsNumber != null) {
      return `Select All (${totalItemsNumber})`;
    }
    if (flatDataWithMetrics.length > 0) {
      const firstElement = flatDataWithMetrics[0];
      if (firstElement.totalItems > 0) {
        return `Select All (${firstElement.totalItems})`;
      }
    }
    return 'Select All';
  }

  return (
    <div className="content-search-opportunity__content">
      <Loader
        isLoading={(isLoading || isFetching || isRefetching) && !isFetchingNextPage}
        withTopMargin
      >
        {isBotWorking ? (
          <div className="content-search-opportunity__content-bot">
            <OpportunityIllustration />
            <h2 className="content-search-opportunity__content-bot-title">
              <span>Hang tight!</span>
              <span>Our bots are working ...</span>
              {isSemrushMode && (
                <p className="sub-title">
                  You can close the tab and come back in a few minutes when results are ready.
                </p>
              )}
            </h2>
          </div>
        ) : (
          <>
            <table className="content-search-opportunity__content-table">
              <thead>
                <tr>
                  <th>
                    <div className="table-header-title">
                      <Checkbox onChange={handlerSelectAll} value={isSelectedAll} />
                      <span>{composeSelectAllText()}</span>
                    </div>
                  </th>
                  <th>Source</th>
                  <th>Contacts</th>
                  <th>Metrics</th>
                  <th>Date Added</th>
                </tr>
              </thead>
              <tbody>
                {flatDataWithMetrics.map((row, index) => (
                  <SelectedOpportunityTableRow
                    key={row.id}
                    data={row}
                    workspaceMembers={workspaceMembers}
                    handleChangeCheckbox={handleChangeCheckbox}
                    isChecked={checkboxes.includes(row.id)}
                    setActiveOpportunity={(activeOpportunity) => {
                      setActiveOpportunity(activeOpportunity);
                      setOpportunityIndex(index);
                    }}
                    setOpenOpportunitySidebar={setOpenOpportunitySidebar}
                    isFindContactPipelineRunning={isFindContactPipelineRunning}
                  />
                ))}
              </tbody>
            </table>
            {renderLoader()}
          </>
        )}
      </Loader>
    </div>
  );
}

export default SelectedOpportunitiesWidget;
