import BillingCard from '@components/Settings/Billing/_components/BillingCard/BillingCard';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import {
  BillingCycleTypeValue,
  BillingStateType as BillingStateTypeName,
  BillingSubscriptionTypeMapValue as BillingSubscriptionTypeName,
} from '@ts/billing.types';

import {
  BillingSubscriptionCycle,
  BillingSubscriptionType,
} from 'respona_api/generated/billing_pb';

import { getTimeDifference } from '@utils/getFormattedPastTime';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';

import SubscriptionTypeBadge from '@components/Settings/Billing/_components/SubscriptionCard/_components/SubscriptionTypeBadge/SubscriptionTypeBadge';

import './SubscriptionCard.scss';
import { redirectUserTo } from '@utils/historyHandler';
import { requestAdditionalBillingPopup } from '@api/semrush/integration.api';

declare const IS_STANDALONE_MODE: boolean;
declare const INTEGRATION_MODE: string;

function SubscriptionCard({
  type,
  state,
  amount,
  nextChargeTime,
  cycle,
  isActive,
}: {
  type: BillingSubscriptionTypeName;
  state: BillingStateTypeName;
  amount: number;
  nextChargeTime: number;
  cycle: BillingCycleTypeValue;
  isActive: boolean;
}): JSX.Element {
  const history = useHistory();
  const redirectionUrl = '/settings/billing/change-subscription';

  const subscriptionDaysLeft = useMemo(() => {
    return nextChargeTime > 0 ? getTimeDifference(nextChargeTime) : 0;
  }, [nextChargeTime, getTimeDifference]);

  const showBuyButton = useMemo(() => {
    if (IS_STANDALONE_MODE) {
      return type !== BillingSubscriptionType.ENTERPRISE;
    }
    return type === BillingSubscriptionType.TRIAL || isActive === false;
  }, [type]);

  const getBillingPeriodDaysNumber = useMemo(() => {
    const now = new Date();
    const year = now.getFullYear();

    if (cycle === BillingSubscriptionCycle.YEARLY) {
      return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
    }
    if (type === BillingSubscriptionType.TRIAL) {
      return 7;
    }
    return new Date(year, now.getMonth() + 1, 0).getDate();
  }, [cycle, type]);

  return (
    <BillingCard
      title="Subscription"
      warningReversed
      subtitle={
        nextChargeTime > 0 ? (
          <div className="subscription-card__footer">
            Next charge: {format(new Date(nextChargeTime), 'MMM dd, yyyy')}
          </div>
        ) : null
      }
      headerRight={
        showBuyButton ? (
          <Link
            to={redirectionUrl}
            className="subscription-card__change-link"
            onClick={(event) => {
              if (IS_STANDALONE_MODE) {
                redirectUserTo(history, redirectionUrl);
              } else {
                event.preventDefault();
                requestAdditionalBillingPopup();
              }
            }}
          >
            <TextWithIcon icon="billing" iconColor="#3424EB">
              {IS_STANDALONE_MODE ? 'Change subscription' : 'Buy'}
            </TextWithIcon>
          </Link>
        ) : null
      }
      chartInfo={{
        value: Math.min(
          getBillingPeriodDaysNumber - subscriptionDaysLeft,
          getBillingPeriodDaysNumber
        ),
        max: getBillingPeriodDaysNumber,
      }}
      chartLegend={<div className="subscription-card__info-amount">${amount}</div>}
      chartTitle={
        <div className="subscription-card__info">
          <div>
            <div className="subscription-card__info-title">Cost</div>

            {BillingSubscriptionCycle.NONE !== cycle ? (
              <div className="subscription-card__info-type">
                {cycle === BillingSubscriptionCycle.YEARLY ? 'Yearly' : null}
                {cycle === BillingSubscriptionCycle.MONTHLY ? 'Monthly' : null}
              </div>
            ) : null}
          </div>
          <SubscriptionTypeBadge type={type} state={state} />
        </div>
      }
    />
  );
}

export default SubscriptionCard;
