/**
 * Converts any object into a JSON string using Object.keys() for iteration.
 * Excludes keys with '__' prefix.
 * @param obj - The object to be converted
 * @returns The JSON string representation of the object
 */
const transformObjectToJsonString = (obj: Object): string => {
    if (!obj || typeof obj !== 'object') {
        throw new Error('Input must be a valid object.');
    }

    const result: { [key: string]: any } = {};

    Object.keys(obj).forEach((key) => {
        if (!key.startsWith('__')) {
            result[key] = obj[key];
        }
    });

    return JSON.stringify(result);
};

export default transformObjectToJsonString;
