import {
  TemplateContainerType,
  TemplateScopeType,
  TemplateStepTransformedType,
  TemplateStepType,
  TemplateType,
} from '@ts/template.types';
import mailboxTemplateService from '@api/services/mailboxTemplateService';
import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import stripHtml from '@utils/stripHtml';
import * as mailbox_template_pb from 'respona_api/generated/mailbox-template_pb';
import MailboxTemplateModels, {
  TemplateFilterRequest,
  TemplateStep,
  TemplateStepThread,
  TemplateWithStepsRequest,
} from '../../respona_api/generated/mailbox-template_pb';
import CommonModels from '../../respona_api/generated/common_pb';

export const getAllTemplatesApi = (
  page: number,
  limit: number,
  workspaceId: number,
  query?: string,
  ownersIds?: number[]
): Promise<TemplateType[]> => {
  const client = mailboxTemplateService.getInstance();
  const getTemplatesRequest = new TemplateFilterRequest();

  getTemplatesRequest.setPage(page);
  getTemplatesRequest.setLimit(limit);

  if (workspaceId) getTemplatesRequest.setWorkspaceId(workspaceId);
  if (query) getTemplatesRequest.setTitleQuery(query);
  if (ownersIds && ownersIds.length) getTemplatesRequest.setOwnerIdsList(ownersIds);

  return streamRequest<TemplateType[]>(client, client.getAllTemplates, getTemplatesRequest);
};

export const updateTemplateStepAndFutureQueuesApi = (
  sequenceStep: TemplateStepTransformedType,
  templateId: number
): Promise<TemplateStepType> => {
  const client = mailboxTemplateService.getInstance();
  const templateStepRequest = new MailboxTemplateModels.TemplateStep();
  const templateStepThread = new MailboxTemplateModels.TemplateStepThread();

  templateStepThread.setSubject(sequenceStep.subject);
  templateStepThread.setContent(sequenceStep.content);
  templateStepThread.setSameThread(stripHtml(sequenceStep.subject)?.trim() === '');

  templateStepRequest.setId(sequenceStep.id);
  templateStepRequest.setTemplateId(templateId);
  templateStepRequest.setOrder(sequenceStep.order);
  templateStepRequest.setSendDelay(sequenceStep.sendDelay);
  templateStepRequest.setEnabledThreads(true);
  templateStepRequest.setThreadA(templateStepThread);
  templateStepRequest.setThreadB(templateStepThread);

  return request<TemplateStepType>(
    client,
    client.updateTemplateStepAndFutureQueues,
    templateStepRequest
  );
};

export const updateTemplateStepApi = (
  templateId: number,
  step: TemplateStepTransformedType
): Promise<TemplateStepType> => {
  const service = mailboxTemplateService.getInstance();
  const templateStepRequest = new MailboxTemplateModels.TemplateStep();
  const stepModel = new MailboxTemplateModels.TemplateStepThread();

  stepModel.setSameThread(stripHtml(step.subject)?.trim() === '');
  stepModel.setSubject(step.subject);
  stepModel.setContent(step.content);
  stepModel.setAttachmentsList(
    step?.attachmentsList
      ? step.attachmentsList.map((attachment) => {
          const fileAttachmentModel = new CommonModels.FileAttachment();

          fileAttachmentModel.setKey(attachment.key);
          fileAttachmentModel.setTitle(attachment.title);
          fileAttachmentModel.setSize(attachment.size);

          return fileAttachmentModel;
        })
      : []
  );

  templateStepRequest.setTemplateId(templateId);
  templateStepRequest.setId(step.id);
  templateStepRequest.setSendDelay(step.sendDelay);
  templateStepRequest.setOrder(step.order);
  templateStepRequest.setThreadA(stepModel);

  return request<TemplateStepType>(service, service.updateTemplateStep, templateStepRequest);
};

export const updateTemplateStepDelayApi = (
  templateId: number,
  stepId: number,
  delay: number
): Promise<TemplateStepType> => {
  const service = mailboxTemplateService.getInstance();
  const updateStepDelayRequest = new MailboxTemplateModels.UpdateStepDelayRequest();

  updateStepDelayRequest.setTemplateId(templateId);
  updateStepDelayRequest.setId(stepId);
  updateStepDelayRequest.setSendDelay(delay);

  return request<TemplateStepType>(
    service,
    service.updateTemplateStepDelay,
    updateStepDelayRequest
  );
};

export const applyTemplateApi = (
  curTemplateId: number,
  newTemplateId: number,
  workspaceId: number
): Promise<TemplateContainerType> => {
  const service = mailboxTemplateService.getInstance();
  const applyTemplateRequest = new MailboxTemplateModels.ApplyTemplateRequest();

  applyTemplateRequest.setFromTemplateId(newTemplateId);
  applyTemplateRequest.setToTemplateId(curTemplateId);
  applyTemplateRequest.setWorkspaceId(workspaceId);

  return request<TemplateContainerType>(service, service.applyTemplate, applyTemplateRequest);
};

export const deleteTemplateStepApi = (
  stepId: number,
  templateId: number
): Promise<TemplateContainerType> => {
  const service = mailboxTemplateService.getInstance();
  const deleteRequest = new CommonModels.IdRequest();

  deleteRequest.setId(stepId);
  deleteRequest.setOptionalRelatedId(templateId);

  return request<TemplateContainerType>(service, service.deleteTemplateStep, deleteRequest);
};

export const deleteTemplateApi = (templateId: number, workspaceId?: number): Promise<void> => {
  const service = mailboxTemplateService.getInstance();
  const deleteRequest = new CommonModels.IdRequest();

  deleteRequest.setId(templateId);
  if (workspaceId) deleteRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.deleteTemplate, deleteRequest);
};

export const getTemplateStepsApi = (
  templateId: number,
  workspaceId?: number
): Promise<TemplateContainerType> => {
  const service = mailboxTemplateService.getInstance();
  const templateRequest = new CommonModels.IdRequest();

  templateRequest.setId(templateId);
  if (workspaceId) templateRequest.setOptionalWorkspaceId(workspaceId);

  return request<TemplateContainerType>(service, service.getTemplateSteps, templateRequest);
};

export const createTemplateApi = (title: string, workspaceId: number): Promise<TemplateType> => {
  const client = mailboxTemplateService.getInstance();
  const emailTemplateRequest = new MailboxTemplateModels.TemplateRequest();

  emailTemplateRequest.setWorkspaceId(workspaceId);
  emailTemplateRequest.setScope(1);
  emailTemplateRequest.setTitle(title);

  return request<TemplateType>(client, client.createTemplate, emailTemplateRequest);
};

export const duplicateTemplateApi = (id: number, workspaceId: number): Promise<TemplateType> => {
  const client = mailboxTemplateService.getInstance();
  const emailTemplateRequest = new CommonModels.IdRequest();

  emailTemplateRequest.setId(id);
  emailTemplateRequest.setOptionalWorkspaceId(workspaceId);

  return request<TemplateType>(client, client.duplicateTemplate, emailTemplateRequest);
};

export const saveTemplateWithStepsApi = (
  templateId: number,
  workspaceId: number,
  title: string,
  scope: TemplateScopeType,
  stepsList: TemplateStepType[]
): Promise<TemplateType> => {
  const client = mailboxTemplateService.getInstance();
  const templateWithStepsRequest = new TemplateWithStepsRequest();

  templateWithStepsRequest.setId(templateId);
  templateWithStepsRequest.setWorkspaceId(workspaceId);
  templateWithStepsRequest.setTitle(title);
  templateWithStepsRequest.setScope(scope);

  templateWithStepsRequest.setStepsList(
    stepsList.map((step: TemplateStepType) => {
      const templateStep = new TemplateStep();

      templateStep.setId(step.id);
      templateStep.setTemplateId(templateId);
      templateStep.setOrder(step.order);
      templateStep.setThreadA(createStepThreadModel(step.threadA, step.order === 1));
      templateStep.setThreadB(createStepThreadModel(step.threadB, step.order === 1));
      templateStep.setSendDelay(step.sendDelay);
      templateStep.setEnabledThreads(step.enabledThreads);

      return templateStep;
    })
  );

  return request<TemplateType>(client, client.saveTemplateWithSteps, templateWithStepsRequest);
};

export const saveAsNewTemplateApi = (
  title: string,
  workspaceId: number,
  templateId: number
): Promise<TemplateType> => {
  const client = mailboxTemplateService.getInstance();
  const saveAsNewTemplateRequest = new MailboxTemplateModels.SaveAsNewTemplateRequest();

  saveAsNewTemplateRequest.setTitle(title);
  saveAsNewTemplateRequest.setWorkspaceId(workspaceId);
  saveAsNewTemplateRequest.setCopyFromId(templateId);
  saveAsNewTemplateRequest.setScope(1);

  return request<TemplateType>(client, client.saveAsNewTemplate, saveAsNewTemplateRequest);
};

export const createTemplateStepApi = (templateId: number, step): Promise<TemplateStepType> => {
  const service = mailboxTemplateService.getInstance();
  const templateStepRequest = new MailboxTemplateModels.TemplateStep();
  // @ts-ignore
  const stepModel = new MailboxTemplateModels.TemplateStepThread();

  stepModel.setSameThread(step.isSameThread);
  stepModel.setSubject(step.subject);
  stepModel.setContent(step.content);

  templateStepRequest.setTemplateId(templateId);
  templateStepRequest.setSendDelay(step.sendDelay);
  templateStepRequest.setOrder(step.order);
  // @ts-ignore
  templateStepRequest.setThreadA(stepModel);
  // @ts-ignore
  templateStepRequest.setThreadB(stepModel);

  return request<TemplateStepType>(service, service.createTemplateStep, templateStepRequest);
};

export const changeUnsubscribeContentStateOfTemplateApi = (
  templateId: number,
  enabled: boolean
): Promise<TemplateStepType> => {
  const service = mailboxTemplateService.getInstance();
  const templateStepRequest = new mailbox_template_pb.ChangeStateRequest();

  templateStepRequest.setId(templateId);
  templateStepRequest.setEnabled(enabled);

  return request<TemplateStepType>(
    service,
    service.changeUnsubscribeContentStateOfTemplate,
    templateStepRequest
  );
};

// HELPERS
//TODO RES-3115 remove isFirst step when sameThread will be fixed in editor, this is a temporal fix that works only for first step
function createStepThreadModel(thread, isFirstStep?: boolean) {
  const threadModel = new TemplateStepThread();

  threadModel.setContent(thread.content);
  threadModel.setSubject(thread.subject);
  threadModel.setSameThread(isFirstStep ? false : thread.sameThread);
  threadModel.setAttachmentsList(
    thread?.attachmentsList?.map((attachment) => {
      const fileAttachmentModel = new CommonModels.FileAttachment();

      fileAttachmentModel.setKey(attachment.key);
      fileAttachmentModel.setTitle(attachment.title);
      fileAttachmentModel.setSize(attachment.size);

      return fileAttachmentModel;
    })
  );

  return threadModel;
}
