import MozMetrics from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MozMetrics/MozMetrics';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as common_pb from 'respona_api/generated/common_pb';

import { DispatchType } from 'src/store';

import {
  BacklinksSearchSourceMap,
  BacklinksSearchSource as BACKLINKS_SEARCH_SOURCE,
  ContentMetricsType,
} from 'respona_api/generated/common_pb';
import { KeywordSearchSourceMap } from 'respona_api/generated/automation-content_pb';

import MetricsSourceToggle from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/MetricsContainer/_components/MetricsSourceToggle/MetricsSourceToggle';
import AhrefsMetrics from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/AhrefsMetrics/AhrefsMetrics';
import SemrushMetrics from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SemrushMetrics/SemrushMetrics';

import './MetricsContainer.scss';
import { setMetricsType } from '@redux/actions/automationContent.actions';
import { isSemrushMode } from '@constants/app-modes';

declare const INTEGRATION_MODE;

function MetricsContainer({
  searchSource,
  isBacklinksSearch = false,
}: {
  isBacklinksSearch?: boolean;
  searchSource?:
    | KeywordSearchSourceMap[keyof KeywordSearchSourceMap]
    | common_pb.BacklinksSearchSourceMap[keyof common_pb.BacklinksSearchSourceMap];
}) {
  const dispatch: DispatchType = useDispatch();
  let sourceState = searchSource || BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH;

  if (isSemrushMode(INTEGRATION_MODE)) {
    sourceState = BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH;
  }

  const [source, setSource] =
    useState<BacklinksSearchSourceMap[keyof BacklinksSearchSourceMap]>(sourceState);

  useEffect(() => {
    if (source === BACKLINKS_SEARCH_SOURCE.AHREFS_BACKLINKS_SEARCH) {
      dispatch(setMetricsType(ContentMetricsType.AHREFS_METRICS));
    }

    if (source === BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH) {
      dispatch(setMetricsType(ContentMetricsType.SEMRUSH_METRICS));
    }

    if (
      source === BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH ||
      source === BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH
    ) {
      dispatch(setMetricsType(ContentMetricsType.MOZ_METRICS));
    }
  }, [source]);

  return (
    <div className="metrics-container">
      <div className="metrics-container__row">
        <MetricsSourceToggle setSource={setSource} source={source} />
      </div>
      {/* <AhrefsMetrics /> */}
      {source === BACKLINKS_SEARCH_SOURCE.AHREFS_BACKLINKS_SEARCH ? <AhrefsMetrics /> : null}
      {source === BACKLINKS_SEARCH_SOURCE.SEMRUSH_BACKLINKS_SEARCH ? <SemrushMetrics /> : null}
      {source === BACKLINKS_SEARCH_SOURCE.MOZ_BACKLINKS_SEARCH ||
      source === BACKLINKS_SEARCH_SOURCE.RESPONA_BACKLINKS_SEARCH ? (
        <MozMetrics
          source={source}
          searchSource={searchSource}
          isBacklinksSearch={isBacklinksSearch}
        />
      ) : null}
    </div>
  );
}

export default MetricsContainer;
