import React, { useEffect, useMemo, useState } from 'react';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { createEditor, Descendant, Editor, Node, Range, Transforms } from 'slate';
import cn from 'class-names';

import useNonInitialEffect from '@hooks/useNonInitialEffect';

import './PersonalizationInstructionsEditor.scss';

export type onChangeQueriesType = (queries: string[]) => void;

type EditorProps = {
  instructions: string[];
  onChangeInstructions: onChangeQueriesType;
  placeholder?: string;
  maxLines?: number;
  maxCharacters?: number;
  limit?: number;
  instructionsTitle?: string
};

const KEY_CODE = {
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  BACKSPACE: 8,
};

const renderElement = (props, pendingQuery, isSingleElement): JSX.Element => {
  return (
    <div className="personalization-instructions-editor__query" {...props.attributes}>
      <span
        className={cn('personalization-instructions-editor__query-text', {
          'personalization-instructions-editor__query-text--empty':
            !props.element.children[0].text && !pendingQuery && isSingleElement,
        })}
      >
        {props.children}
      </span>
    </div>
  );
};

const withLineLimits =
  (maxLines = 99) =>
  (editor: ReactEditor) => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
      const texts = Array.from(Node.texts(editor));

      if (texts.length >= maxLines) {
        return;
      }

      insertBreak();
    };

    return editor;
  };

const PersonalizationInstructionsEditor: React.FC<EditorProps> = ({
  instructions: initialQueries,
  onChangeInstructions,
  maxLines = 99,
  maxCharacters = -1,
  limit = -1,
  instructionsTitle = 'General instructions',
}): JSX.Element => {
  const editor = useMemo(
    () => withReact(withHistory(withLineLimits(maxLines)(createEditor() as ReactEditor))),
    []
  );
  const [componentKey, setComponentKey] = useState<number>(0);
  const [pendingQuery, setPendingQuery] = useState<boolean>(false);
  const [value, setValue] = useState<Descendant[]>(
    (initialQueries.length ? initialQueries : ['']).map((text) => ({
      children: [{ text: text || '' }],
    }))
  );

  useEffect(() => {
    if (initialQueries.length > value.length) {
      const initChildren = initialQueries.map((text) => ({
        children: [{ text: text || '' }],
      }));
      setValue(initChildren);
      setComponentKey(componentKey + 1);
    }
  }, [initialQueries]);

  const mapEditorValuesToStringArray = (data: Descendant[]) => {
    return Array.from(
      new Set(
        data.reduce((acc, item: { children }) => {
          if (!item?.children[0]?.text) {
            return acc;
          }
          return acc.concat([item?.children[0]?.text]);
        }, [])
      )
    );
  };

  const onKeyDown = (e) => {
    const endPoint = Range.end(editor.selection);
    const [selectedLeaf] = Editor.node(editor, endPoint);

    // @ts-ignore
    if (maxCharacters > 0 && selectedLeaf.text.length >= maxCharacters && e.keyCode >= 48) {
      e.preventDefault();
      return false;
    }

    if (e.keyCode === KEY_CODE.BACKSPACE && e.target.innerText.trim() === '') {
      // @ts-ignore
      document.activeElement.blur();
    }
  };

  const onPaste = (e) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text');
    const newPatterns = text?.split(/[\n,]/g)?.filter((string) => !!string);

    if (!newPatterns?.length) {
      return;
    }

    const texts = Array.from(Node.texts(editor));

    Transforms.insertText(editor, newPatterns[0]);

    if (texts.length >= maxLines) {
      return;
    }

    if (newPatterns.length > 1) {
      Transforms.insertNodes(
        editor,
        newPatterns.slice(1, maxLines - texts.length).map((query) => ({
          children: [{ text: query }],
        }))
      );
    }
  };

  const handleDOMBeforeInput = (event) => {
    if (limit === -1) {
      return false;
    }

    const { inputType, type } = event;
    let textLength = Editor.string(editor, []).length;

    if (inputType === 'insertText') {
      textLength += event.data.length;
    }

    if (type === 'paste') {
      textLength += event.clipboardData.getData('Text').length;
    }

    if (textLength > limit) {
      event.preventDefault();
      return true;
    }

    return false;
  };

  useNonInitialEffect(() => {
    onChangeInstructions(mapEditorValuesToStringArray(value));
  }, [value]);

  const isEmpty = !initialQueries?.[0]?.length;

  return (
    <div className="personalization-instructions-editor">
      <div className="personalization-instructions-editor__block-title">
        <span>{ instructionsTitle }:</span>
      </div>
      <div className="personalization-instructions-editor__query-editor-wrapper">
        <div
          className={cn('personalization-instructions-editor__query-editor', {
            'personalization-instructions-editor__query-editor--with-placeholder': isEmpty,
          })}
        >
          <Slate editor={editor} value={value} onChange={setValue} key={componentKey}>
            <Editable
              style={{
                overflowY: 'auto',
                height: '100%',
                padding: '9px 17px',
              }}
              onPaste={onPaste}
              onKeyDown={onKeyDown}
              onFocus={() => setPendingQuery(true)}
              onBlur={() => setPendingQuery(false)}
              renderElement={(props) => {
                return renderElement(props, pendingQuery, value.length === 1);
              }}
              onDOMBeforeInput={handleDOMBeforeInput}
            />
          </Slate>
        </div>
      </div>
    </div>
  );
};

export default PersonalizationInstructionsEditor;
