import { FileResponse, IdRequest, InstructionPair } from 'respona_api/generated/common_pb';
import {
  InspectionSettings,
  LaunchInspectionAutomationRequest,
  OpportunityInspectionExportRequest,
} from 'respona_api/generated/automation-inspection_pb';
import {
  InspectionAutomationSettings,
  InspectionAutomationType,
} from '@ts/automationInspection.types';
import streamRequest from '@api/base/streamRequest';
import AutomationInspectionService from '@api/services/automationInspectionService';
import request from '@api/base/request';

export const buildLaunchInspectionAutomationApiRequest = (
  campaignId: number,
  workspaceId: number,
  pipelineId: number,
  batch: number,
  automationSettings: InspectionAutomationSettings,
  allOpportunities: boolean = false
): LaunchInspectionAutomationRequest => {
  const startAutomationRequest = new LaunchInspectionAutomationRequest();
  startAutomationRequest.setCampaignId(campaignId);
  startAutomationRequest.setWorkspaceId(workspaceId);
  startAutomationRequest.setCampaignBatch(batch);
  startAutomationRequest.setPipelineId(pipelineId);
  startAutomationRequest.setAllOpportunities(allOpportunities);

  const settings = new InspectionSettings();
  settings.setModel(automationSettings.model);
  settings.setIncludeSummaries(automationSettings.includeSummaries);
  const instructions = [];
  for (let i = 0; i < automationSettings.instructions.length; i++) {
    const item = new InstructionPair();
    item.setOrder(i + 1);
    item.setValue(automationSettings.instructions[i]);
    instructions.push(item);
  }
  settings.setInstructionList(instructions);
  startAutomationRequest.setSettings(settings);

  return startAutomationRequest;
};

export const exportOpportunityInspectionResultApi = (
  pipelineId: number,
  campaignId: number,
  workspaceId: number
): Promise<FileResponse.AsObject> => {
  const client = AutomationInspectionService.getInstance();
  const exportRequest = new OpportunityInspectionExportRequest();
  exportRequest.setCampaignId(campaignId);
  exportRequest.setWorkspaceId(workspaceId);
  exportRequest.setPipelineId(pipelineId);

  return request(client, client.exportOpportunityInspectionResult, exportRequest);
};

export const getAiInspectionPresetApi = (
  campaignId: number,
  workspaceId: number,
  pipelineId: number
): Promise<InspectionAutomationType> => {
  const service = AutomationInspectionService.getInstance();
  const presetRequest = new IdRequest();

  presetRequest.setId(pipelineId);
  presetRequest.setOptionalWorkspaceId(workspaceId);
  presetRequest.setOptionalRelatedId(campaignId);

  return request<InspectionAutomationType>(service, service.getPreset, presetRequest);
};
