/**
 * NOT CHECKED
 */
import useOutsideClick from '@hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { TableSettingsResponse } from 'respona_api/generated/people_pb';
import { SVGIcon } from 'src/uikit/Icon/Icon';
import Checkbox from 'src/uikit/Checkbox/Checkbox';

import './ColumnsSelectIcon.scss';

type ColumnsSelectIconPropsType = {
  elementId: string;
  availableColumns: TableSettingsResponse.AsObject[];
  onToggleColumnsSelection: any;
  setCustomFieldsSidebarOpen?: (open: boolean) => void;
};

const ColumnsSelectIcon = ({
  elementId,
  availableColumns,
  onToggleColumnsSelection,
  setCustomFieldsSidebarOpen,
}: ColumnsSelectIconPropsType): JSX.Element => {
  const tooltipRef = useRef();
  const tooltipContentRef = useRef<HTMLDivElement>();
  const [selectedColumnsState, changeSelectedColumnsState] = useState([]);

  const handleSelectedColumnToggle = (isSelected, column) => {
    changeSelectedColumnsState((prevState) => {
      return prevState.map((item) => {
        if (item.columnMappingName === column.columnMappingName) {
          return {
            ...column,
            enabled: isSelected,
          };
        }

        return item;
      });
    });
  };

  const handleDragDrop = (res) => {
    const { source, destination } = res;

    if (!destination || destination?.index === source?.index) {
      return;
    }

    changeSelectedColumnsState((state) => {
      const newState = state.slice();
      newState.splice(source.index, 1);
      newState.splice(destination.index, 0, state[source.index]);

      return newState;
    });
  };

  useOutsideClick(tooltipContentRef, () => ReactTooltip.hide(tooltipRef.current));

  useEffect(() => {
    changeSelectedColumnsState(availableColumns);
  }, [availableColumns]);

  return (
    <DragDropContext onDragEnd={handleDragDrop}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span
        onClick={() => ReactTooltip.show(tooltipRef.current)}
        data-for={elementId}
        data-tip=""
        data-event="click focus"
        className="people-header-actions__select-column"
        ref={tooltipRef}
      >
        <SVGIcon icon="windowSection" />
      </span>
      <ReactTooltip
        arrowColor="transparent"
        afterHide={() =>
          onToggleColumnsSelection(
            onToggleColumnsSelection(
              selectedColumnsState.map((item, index) => ({
                ...item,
                order: index + 1,
              }))
            )
          )
        }
        id={elementId}
        className="react-tooltip columns-select-tooltip__wrapper"
        place="bottom"
        effect="solid"
        globalEventOff="none"
        offset={{ left: 113, top: 5 }}
        clickable
      >
        <div className="columns-select-tooltip" ref={tooltipContentRef}>
          <div className="columns-select-tooltip__title">Table columns</div>
          <Droppable droppableId="selectedRelationshipsColumns">
            {(provided) => (
              <div
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  maxHeight: '250px',
                }}
                {...provided.droppableProps}
              >
                {selectedColumnsState.map((column, index) => (
                  <Draggable
                    draggableId={column.columnMappingName + index}
                    index={index}
                    key={column.columnMappingName}
                  >
                    {(provided1) => (
                      <div
                        ref={provided1.innerRef}
                        className="columns-select-tooltip__row"
                        {...provided1.draggableProps}
                      >
                        <div
                          {...provided1.dragHandleProps}
                          className="columns-select-tooltip__row-drag-icon"
                        >
                          <SVGIcon icon="actionsTwoDots" color="#BDBDBD" />
                        </div>
                        <span className="columns-select-tooltip__row-name">
                          {column.columnTitle}
                        </span>
                        <Checkbox
                          className="columns-select-tooltip__checkbox"
                          onChange={({ target: { checked } }) =>
                            handleSelectedColumnToggle(checked, column)
                          }
                          value={column.enabled}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div
            className="columns-select-tooltip__add-custom"
            onClick={() => {
              ReactTooltip.hide(tooltipRef.current);
              setCustomFieldsSidebarOpen(true);
            }}
          >
            <span>+ Custom field</span>
          </div>
        </div>
      </ReactTooltip>
    </DragDropContext>
  );
};

export default ColumnsSelectIcon;
