import React, { ReactNode, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'class-names';

import Sidebar from '@components/Sidebar/Sidebar';

import './PageWrapper.scss';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

export default ({
  children,
  pathname,
  preview,
  isSideBarVisible,
}: {
  children: ReactNode;
  pathname: string;
  preview?: boolean;
  isSideBarVisible?: boolean;
}): JSX.Element => {
  const { campaignId, step } = useParams();
  const agentId = useMemo(() => {
    if (pathname?.length > 0) {
      const match = pathname.match(/ai-agents\/([^/]+)/);
      const urlPart = match ? match[1] : undefined;
      if (urlPart?.length > 0 && Number.isInteger(Number(urlPart))) {
        return Number(urlPart);
      }
    }
    return -1;
  }, [pathname]);
  const workspaceId = useCurrentWorkspaceId();
  const history = useHistory();
  const path = `/campaign-builder/${Number(campaignId)}/personalize`;
  const pathStep = `/campaign-builder/${Number(campaignId)}/${step}`;
  const isActivePersonalize = history.location.pathname === path;
  const isStepActive = history.location.pathname === pathStep;
  const chatPaths = ['chat', 'instructions', 'activity-log'];
  const chatBasePath = `/workspaces/${workspaceId}/ai-agents/${agentId}`;

  chatPaths.forEach(path => {
    const chatPath = `${chatBasePath}/${path}`;
    if (workspaceId && chatPath === history.location.pathname) {
      isSideBarVisible = false;
    }
  });

  return (
    <div
      className={cn('page-wrapper', {
        'page-wrapper--disabled': preview,
      })}
    >
      {isSideBarVisible && <Sidebar pathname={pathname} preview={preview} />}
      <div
        className={cn('page-wrapper__content', {
          'active-personalize': isActivePersonalize,
          'padding-fixed': isStepActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};
