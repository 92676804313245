import React from 'react';

import { RenderConfigurableSidebarLink } from '@uikit/ConfigurableSidebar/_components/ConfigurableSidebarLinks';

declare const IS_STANDALONE_MODE: boolean;

const groups = [
  // {
  //   title: 'Chat',
  //   pageKey: 'Chat',
  //   RenderLink: ({ pathname, campaignId, workspaceId }) => (
  //     <RenderConfigurableSidebarLink
  //       title="Chat"
  //       icon="aiChat"
  //       url={`/workspaces/${workspaceId}/ai-agents/${campaignId}/chat`}
  //       isActive={pathname === `/workspaces/${workspaceId}/ai-agents/${campaignId}/chat`}
  //     />
  //   ),
  // },
  {
    title: 'Instructions',
    pageKey: 'instructions',
    RenderLink: ({ pathname, campaignId, workspaceId }) => (
      <RenderConfigurableSidebarLink
        title="Instructions"
        icon="configuration"
        url={`/workspaces/${workspaceId}/ai-agents/${campaignId}/instructions`}
        isActive={pathname === `/workspaces/${workspaceId}/ai-agents/${campaignId}/instructions`}
      />
    ),
  },
  {
    title: 'Activity log',
    pageKey: 'activityLog',
    RenderLink: ({ pathname, campaignId, workspaceId }) => (
      <RenderConfigurableSidebarLink
        title="Activity log"
        icon="clockThree"
        url={`/workspaces/${workspaceId}/ai-agents/${campaignId}/activity-log`}
        isActive={pathname === `/workspaces/${workspaceId}/ai-agents/${campaignId}/activity-log`}
      />
    ),
  },
];

export default groups;
