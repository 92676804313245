/**
 * NOT CHECKED
 */
import React, { ReactNode } from 'react';

import cn from 'class-names';

import Loader from '@uikit/Loader/Loader';

import './Checkbox.scss';

type CheckboxPropsType = {
  children?: ReactNode;
  onChange: any;
  value: boolean;
  unbordered?: boolean;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isCustomYesNo?: boolean;
};

const Checkbox = ({
  isCustomYesNo,
  children = null,
  onChange = () => null,
  value = false,
  unbordered = false,
  className,
  disabled,
  isLoading,
}: CheckboxPropsType): JSX.Element => {
  const checkmarkClass = cn('checkbox__checkmark', {
    'checkbox__checkmark--unbordered': unbordered,
  });

  const cnLabel = cn('checkbox', {
    'checkbox--disabled': disabled,
    'custom-yes-no': isCustomYesNo,
    [className]: className?.length > 0,
  });

  if (isLoading) {
    return (
      <label className={cnLabel}>
        <Loader
          isLoading
          backgroundColor="#000"
          style={{ width: '15px', height: '15px', background: '#ffffff' }}
        />
      </label>
    );
  }

  return (
    <label className={cnLabel} onClick={(e) => e.stopPropagation()}>
      <input
        onChange={onChange}
        disabled={disabled}
        className="checkbox__field"
        type="checkbox"
        checked={value}
      />
      {children}
      <span className={checkmarkClass} />
    </label>
  );
};

export default Checkbox;
