/**
 * NOT CHECKED
 */
import React from 'react';

import DocumentSignatureIcon from '@uikit/Icon/svgIcons/DocumentSignatureIcon';
import PersonRoundedCrossedIcon from '@uikit/Icon/svgIcons/PersonRoundedCrossedIcon';
import ArrowRightClassicIcon from '@uikit/Icon/svgIcons/ArrowRightClassicIcon';
import FlagStripedIcon from '@uikit/Icon/svgIcons/FlagStripedIcon';
import ConnectionIcon from '@uikit/Icon/svgIcons/ConnectionIcon';
import CardioIcon from '@uikit/Icon/svgIcons/CardioIcon';
import LinkChainIcon from '@uikit/Icon/svgIcons/LinkChainIcon';
import PencilIcon from '@uikit/Icon/svgIcons/PencilIcon';
import cubesIcon from '@uikit/Icon/svgIcons/CubesIcon';
import LightBulbIcon from '@uikit/Icon/svgIcons/LightBulbIcon';
import CheckCircled2Icon from '@uikit/Icon/svgIcons/CheckCircled2Icon';
import Wallet2Icon from '@uikit/Icon/svgIcons/Wallet2Icon';
import BillIcon from '@uikit/Icon/svgIcons/BillIcon';
import StopMessagingIcon from '@uikit/Icon/svgIcons/StopMessagingIcon';
import RenameActionIcon from '@uikit/Icon/svgIcons/RenameActionIcon';
import KeyIcon from '@uikit/Icon/svgIcons/KeyIcon';
import LineChartMinimalIcon from '@uikit/Icon/svgIcons/LineChartMinimalIcon';
import CheckCircledIcon from '@uikit/Icon/svgIcons/CheckCircledIcon';
import InboxIcon from '@uikit/SidebarIcon/_components/InboxIcon';
import ArrowUpCircle from '@uikit/Icon/svgIcons/ArrowUpCircle';
import PersonIcon from '@uikit/Icon/svgIcons/PersonIcon';
import PersonRoundedIcon from '@uikit/Icon/svgIcons/PersonRoundedIcon';
import ExpandSidebarIcon from '@uikit/Icon/svgIcons/ExpandSidebarIcon';
import ExternalLinkIcon from '@uikit/Icon/svgIcons/ExternalLinkIcon';
import SuccessCircleFilledIcon from '@uikit/Icon/svgIcons/SuccessCircleFilledIcon';
import TrashSimpleIcon from '@uikit/Icon/svgIcons/TrashSimpleIcon';
import ArchivedInboxIcon from '@uikit/Icon/svgIcons/ArchivedInboxIcon';
import TextMessageIcon from '@uikit/Icon/svgIcons/TextMessageIcon';
import OpenMessageIcon from '@uikit/Icon/svgIcons/OpenMessageIcon';
import InboxUnreadMessage from '@uikit/Icon/svgIcons/InboxUnreadMessage';
import CalendarIcon from '@uikit/Icon/svgIcons/CalendarIcon';
import PauseTallIcon from '@uikit/Icon/svgIcons/PauseTallIcon';
import EmailIcon from '@uikit/Icon/svgIcons/EmailIcon';
import SocketPlugIcon from '@uikit/Icon/svgIcons/SocketPlugIcon';
import LinkBrokenIcon from '@uikit/Icon/svgIcons/LinkBrokenIcon';
import AiIcon from '@uikit/Icon/svgIcons/AiIcon';
import NumberIcon from '@uikit/Icon/svgIcons/NumberIcon';
import TextLetterIcon from '@uikit/Icon/svgIcons/TextLetterIcon';
import CrosedIcon from '@uikit/Icon/svgIcons/CrosedIcon';
import EmailPersonalized from '@uikit/Icon/svgIcons/EmailPersonalized';
import UserSearch from '@uikit/Icon/svgIcons/UserSearch';
import MetricIcon from '@uikit/Icon/svgIcons/MetricIcon';
import FoldersIcon from '@uikit/Icon/svgIcons/FoldersIcon';
import LockIcon from '@uikit/Icon/svgIcons/LockIcon';
import FlagMarkIcon from '@uikit/Icon/svgIcons/FlagMarkIcon';

// @ts-ignore
import ImageVideoFile from '@uikit/Icon/svgIcons/ImageVideoFile';
import RepliedNotInterestedIcon from '@uikit/Icon/svgIcons/RepliedNotInterestedIcon';
import RepliedInterestedIcon from '@uikit/Icon/svgIcons/RepliedInterestedIcon';
import ArrowRefreshCircle from '@uikit/Icon/svgIcons/ArrowRefreshCircle';
import GoogleIcon from '@uikit/Icon/svgIcons/googleIcon';
import SemrushSearchSvg from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/SemrushSearchSvg';
import BingShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/BingShortSVG';
import UploadShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/UploadShortSVG';
import KeywordsSearcgSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/KeywordsSearcgSVG';
import PodcastSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/PodcastSVG';
import RelationshipsSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/RelationshipsSVG';
import BingNewsShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/BingNewsShortSVG';
import EpisodesSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/EpisodesSVG';
import CSVImportSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/CSVImportSVG';
import CrossInGreyCircle from '@uikit/Icon/svgIcons/CrossInGreyCircle';
import PlayBigIcon from '@uikit/Icon/svgIcons/PlayBigIcon';
import QuestionMark from '@uikit/Icon/svgIcons/QuestionMark';
import BlueMark from '@uikit/Icon/svgIcons/BlueMark';
import TimeIcon from '@uikit/Icon/svgIcons/TimeIcon';
import CancelIcon from '@uikit/Icon/svgIcons/CancelIcon';
import MozSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/MozSVG';
// @ts-ignore
import ThreeDots from '@uikit/Icon/svgIcons/ThreeDots';
import House from '@uikit/Icon/svgIcons/House';
import LockGrey from '@uikit/Icon/svgIcons/LockGrey';
import Pin from '@uikit/Icon/svgIcons/Pin';
import LightningArrowIcon from '@uikit/Icon/svgIcons/LightningArrowIcon';
import PlayArrowIcon from '@uikit/Icon/svgIcons/PlayArrowIcon';
import CheckMarkIcon from '@uikit/Icon/svgIcons/checkMarkIcon';
import PieChartIcon from '@uikit/Icon/svgIcons/PieChartIcon';
import RefreshCircleIcon from '@uikit/Icon/svgIcons/RefreshCircleIcon';
import ChatGPTicon from '@uikit/Icon/svgIcons/ChatGPTicon';
// @ts-ignore
import CheckListIcon from '@uikit/Icon/svgIcons/CheckListIcon';
import ReduceIcon from '@uikit/Icon/svgIcons/ReduceIcon';
import TranslateIcon from '@uikit/Icon/svgIcons/TranslateIcon';
import RelaxedSmile from '@uikit/Icon/smiles/RelaxedSmile';
import BriefCaseIcon from '@uikit/Icon/smiles/BriefCaseIcon';
import ShySmileIcon from '@uikit/Icon/smiles/ShySmileIcon';
import TearsSmileIcon from '@uikit/Icon/smiles/TearsSmileIcon';
import LabelIcon from '@uikit/Icon/svgIcons/LabelIcon';
import CrossedOutCircle from '@uikit/Icon/svgIcons/CrossedOutCircle';
import draftIcon from '@uikit/Icon/svgIcons/DraftIcon';
import AssignedPersonIcon from '@uikit/Icon/svgIcons/AssignedPersonIcon';
import PersonsIcon from '@uikit/Icon/svgIcons/PersonsIcon';
import StatusBox from '@uikit/Icon/svgIcons/StatusBox';
import FullStarIcon from '@uikit/Icon/svgIcons/FullStarIcon';
import AvatarIcon from '@uikit/Icon/svgIcons/AvatarIcon';
import Bounced from '@uikit/Icon/svgIcons/Bounced';
import SelectIcon from '@uikit/Icon/svgIcons/SelectIcon';
import TeamMemberIcon from '@uikit/Icon/svgIcons/TeamMemberIcon';
import CheckboxIcon from '@uikit/Icon/svgIcons/CheckboxIcon';
import RatingIcon from '@uikit/Icon/svgIcons/RatingIcon';
import MultiSelectIcon from '@uikit/Icon/svgIcons/MultiSelectIcon';
import DateTimeIcon from '@uikit/Icon/svgIcons/DateTimeIcon';
import DragAndDropIcon from '@uikit/Icon/svgIcons/DragAndDropIcon';
import ShareWithUsIcon from '@uikit/Icon/svgIcons/ShareWithUsIcon';
import RecurringIcon from '@uikit/Icon/svgIcons/RecurringIcon';
import CreatedOpportunitiesIcon from '@uikit/Icon/svgIcons/CreatedOpportunitiesIcon';
import CreditsIcon from '@uikit/Icon/svgIcons/CreditsIcon';
import FlaggedIcon from '@uikit/Icon/svgIcons/FlaggedIcon';
import ReportIcon from '@uikit/Icon/svgIcons/ReportIcon';
import XIcon from '@uikit/Icon/svgIcons/XIcon';
// @ts-ignore
import mailIcon from './assets/mail-icon.svg';
// @ts-ignore
import responaSmall from './assets/respona-small.svg';
// @ts-ignore
import arrowLeftCircle from './assets/arrow-left-circle.svg';
// @ts-ignore
import info from './assets/info.svg';
// @ts-ignore
import check from './assets/check.svg';
// @ts-ignore
import wallet from './assets/wallet.svg';
// @ts-ignore
import arrowDown from './assets/arrow-down.svg';
// @ts-ignore
import arrowUp from './assets/arrow-up.svg';
// @ts-ignore
import search from './assets/search.svg';
// @ts-ignore
import statusEdit from './assets/status-edit.svg';
// @ts-ignore
import statusSwap from './assets/status-swap.svg';
// @ts-ignore
import statusSuccess from './assets/status-success.svg';
// @ts-ignore
import statusPause from './assets/status-pause.svg';
// @ts-ignore
import personIcon from './assets/person-icon.svg';
// @ts-ignore
import companyIcon from './assets/company.svg';
// @ts-ignore
import chevron from './assets/chevron.svg';

import MinusCircle from './svgIcons/MinusCircle';
import DocumentIcon from './svgIcons/DocumentIcon';
import PlusCircle from './svgIcons/PlusCircle';
import SlidersSettings from './svgIcons/SlidersSettings';
import Copy, { CopyIcon2 } from './svgIcons/Copy';
import MessageIcon from './svgIcons/MessageIcon';
import ArrowLeftCircle from './svgIcons/ArrowLeftCircle';
import ArrowLeft from './svgIcons/ArrowLeft';
import PlusIcon from './svgIcons/PlusIcon';
import ArrowDownIcon from './svgIcons/ArrowDownIcon';
import Info from './svgIcons/Info';
import Infinity from './svgIcons/Infinity';
import Trash from './svgIcons/Trash';
import BoldTextIcon from './svgIcons/BoldTextIcon';
import ItalicTextIcon from './svgIcons/ItalicTextIcon';
import UnderlineTextIcon from './svgIcons/UnderlineTextIcon';
import OrderedListIcon from './svgIcons/OrderedListIcon';
import UnorderedListIcon from './svgIcons/UnorderedListIcon';
import Pen from './svgIcons/Pen';
import SearchIcon from './svgIcons/SearchIcon';
import CrossEdit from './svgIcons/CrossEdit';
import PrevPageIcon from './svgIcons/PrevPageIcon';
import NextPageIcon from './svgIcons/NextPageIcon';
import UserIcon from './svgIcons/UserIcon';
import LinkIcon from './svgIcons/LinkIcon';
import NotificationsIcon from './svgIcons/NotificationsIcon';
import LocationIcon from './svgIcons/LocationIcon';
import PersonWithMinus from './svgIcons/PersonWithMinus';
import RefreshIconOneArrowAround from './svgIcons/RefreshIconOneArrowAround';
import WorkspaceOutIcon from './svgIcons/WorkspaceOutIcon';
import PlayIcon from './svgIcons/PlayIcon';
import PlayRoundedIcon from './svgIcons/PlayRoundedIcon';
import ProfileIcon from './svgIcons/ProfileIcon';
import CompanyIcon from './svgIcons/CompanyIcon';
import BillingIcon from './svgIcons/BillingIcon';
import WorkspacesIcon from './svgIcons/WorkspacesIcon';
import MemberIcon from './svgIcons/MemberIcon';
import FolderIcon from './svgIcons/FolderIcon';
import AllMembersIcon from './svgIcons/AllMembersIcon';
import EmailsIcon from './svgIcons/EmailsIcon';
import EmailFind from './svgIcons/EmailFindIcon';
import EmailVerify from './svgIcons/EmailVerifyIcon';
import BookmarkIcon from './svgIcons/BookmarkIcon';
import SchedulesIcon from './svgIcons/SchedulesIcon';
import CrossDeleteIcon from './svgIcons/CrossDeleteIcon';
import CrossInCircleIcon from './svgIcons/CrossInCircleIcon';
import RocketIcon from './svgIcons/RocketIcon';
import CrossBlackIcon from './svgIcons/CrossBlackIcon';
import WarningTriangleIcon from './svgIcons/WarningTriangleIcon';
import WarningTriangleCircleIcon from './svgIcons/WarningTriangleCircleIcon';
import LaunchingIcon from './svgIcons/LaunchingIcon';
import SuccessCircleIcon from './svgIcons/SuccessCircleIcon';
import ActionsIcon from './svgIcons/ActionsIcon';
import ShareIcon from './svgIcons/ShareIcon';
import TrashWhiteIcon from './svgIcons/TrashWhiteIcon';
import EditActionIcon from './svgIcons/EditActionIcon';
import EnvelopeIcon from './svgIcons/EnvelopeIcon';
import CheckIcon from './svgIcons/CheckIcon';
import ChevronIcon from './svgIcons/ChevronIcon';
import GearIcon from './svgIcons/GearIcon';
import CloseIcon from './svgIcons/CrosedIcon';
import ImportIcon from './svgIcons/ImportIcon';
import PauseBlueIcon from './svgIcons/PauseBlueIcon';
import PlayBlueIcon from './svgIcons/PlayBlueIcon';
import AddUserWhiteIcon from './svgIcons/AddUserWhiteIcon';
import PauseIcon from './svgIcons/PauseIcon';
import ArrowRightGreyIcon from './svgIcons/ArrowRightGreyIcon';
import WindowSectionIcon from './svgIcons/WindowSectionIcon';
import CrownIcon from './svgIcons/CrownIcon';
import BanWhiteIcon from './svgIcons/BanWhiteIcon';
import TagWhiteIcon from './svgIcons/TagWhiteIcon';
import InboxWhiteIcon from './svgIcons/InboxWhiteIcon';
import FolderWhiteIcon from './svgIcons/FolderWhiteIcon';
import FolderOpenIcon from './svgIcons/FolderOpenIcon';
import AddCompanyIcon from './svgIcons/AddCompanyIcon';
import InsightsIcon from './svgIcons/InsightsIcon';
import BarChartIcon from './svgIcons/BarChartIcon';
import LineChartIcon from './svgIcons/LineChartIcon';
import SaveIcon from './svgIcons/SaveIcon';
import VideoIcon from './svgIcons/VideoIcon';
import WalletIcon from './svgIcons/WalletIcon';
import List from './svgIcons/List';
import CrossList from './svgIcons/CrossList';
import AppleIcon from './svgIcons/AppleIcon';
import RrsIcon from './svgIcons/RrsIcon';
import CommentIcon from './svgIcons/CommentIcon';
import DownloadIcon from './svgIcons/DownloadIcon';
import SuitcaseIcon from './svgIcons/SuitcaseIcon';
import BuildingIcon from './svgIcons/BuildingIcon';
import OfficeIcon from './svgIcons/OfficeIcon';
import ProfileCrossBigIcon from './svgIcons/ProfileCrossBigIcon';
import BracketsIcon from './svgIcons/BracketsIcon';
import InfoCommentIcon from './svgIcons/InfoCommentIcon';
import DoubleCheckmarkIcon from './svgIcons/DoubleCheckmarkIcon';
import GreyRoundCheckmarkIcon from './svgIcons/GreyRoundCheckmarkIcon';
import InquiriesIcon from './svgIcons/InquiriesIcon';
import FilterIcon from './svgIcons/FilterIcon';
import PriceTagIcon from './svgIcons/PriceTagIcon';
import GlobeIcon from './svgIcons/GlobeIcon';
import ErrorInputFieldSignIcon from './svgIcons/ErrorInputFieldSignIcon';
import DownloadFileIcon from './svgIcons/DownloadFileIcon';
import StarIcon from './svgIcons/StarIcon';
import StarFilledIcon from './svgIcons/StarFilledIcon';
import UploadIcon from './svgIcons/UploadIcon';
import FlagIcon from './svgIcons/FlagIcon';
import CurvesIcon from './svgIcons/CurvesIcon';
import VolumeIcon from './svgIcons/VolumeIcon';
import VolumeMuteIcon from './svgIcons/VolumeMuteIcon';
import SpinnerIcon from './svgIcons/SpinnerIcon';
import ClockThreeIcon from './svgIcons/ClockThreeIcon';
import RefreshIcon from './svgIcons/RefreshIcon';
import RemoveRelationshipsIcon from './svgIcons/RemoveRelationshipsIcon';
import CreditCardIcon from './svgIcons/CreditCardIcon';
import TwoDotsActionsIcon from './svgIcons/TwoDotsActionsIcon';
import LogoutIcon from './svgIcons/LogoutIcon';
import AttachmentIcon from './svgIcons/AttachmentIcon';
import ImageIcon from './svgIcons/ImageIcon';
import SelectedOpportunitiesAbsoluteIcon from './svgIcons/SelectedOpportunitiesAbsoluteIcon';
import EmptySelectedOpportunitiesIcon from './svgIcons/EmptySelectedOpportunitiesIcon';
import WarningRoundedSignIcon from './svgIcons/WarningRoundedSignIcon';
import PlaneIcon from './svgIcons/PlaneIcon';
import HeartIcon from './svgIcons/HeartIcon';
import HeartCrossIcon from './svgIcons/HeartCrossIcon';
import BasketballIcon from './svgIcons/BasketballIcon';
import UserMinusIcon from './svgIcons/UserMinusIcon';
import UserPlusIcon from './svgIcons/UserPlusIcon';
import CommentsIcon from './svgIcons/CommentsIcon';
import TrophyIcon from './svgIcons/TrophyIcon';
import TrophyCrossIcon from './svgIcons/TrophyCrossIcon';
import SentIcon from './svgIcons/SentIcon';
import OutboxIcon from './svgIcons/OutboxIcon';
import NeedAttentionIcon from './svgIcons/NeedAttentionIcon';
import SequenceIcon from './svgIcons/SequenceIcon';
import ChecklistItemSuccesIcon from './svgIcons/ChecklistItemSuccesIcon';
import ResponaSmallIcon from './svgIcons/ResponaSmallIcon';
import ActionHorizontal from './svgIcons/ActionHorizontal';
import FileBlankIcon from './svgIcons/FileBlankIcon';
import CornerUpLeftIcon from './svgIcons/CornerUpLeftIcon';
import DoubleCornerUpLeftIcon from './svgIcons/DoubleCornerUpLeftIcon';
import SpamIcon from './svgIcons/SpamIcon';
import ArchiveIcon from './svgIcons/ArchiveIcon';
import SnoozeIcon from './svgIcons/SnoozeIcon';

import AngelListIcon from './socialSvg/AngelListIcon';
import AppStoreIcon from './socialSvg/AppStoreIcon';
import CrunchBaseIcon from './socialSvg/CrunchBaseIcon';
import FacebookIcon from './socialSvg/FacebookIcon';
import GithubIcon from './socialSvg/GithubIcon';
import GooglePlayIcon from './socialSvg/GooglePlayIcon';
import InstagramIcon from './socialSvg/InstagramIcon';
import LinkedinIcon from './socialSvg/LinkedinIcon';
import MediumIcon from './socialSvg/MediumIcon';
import PenterestIcon from './socialSvg/PenterestIcon';
import QuoraIcon from './socialSvg/QuoraIcon';
import RedditIcon from './socialSvg/RedditIcon';
import SnapchatIcon from './socialSvg/SnapchatIcon';
import SpotifyIcon from './socialSvg/SpotifyIcon';
import TikTokIcon from './socialSvg/TikTokIcon';
import TwitterIcon from './socialSvg/TwitterIcon';
import YoutubeIcon from './socialSvg/YoutubeIcon';
import ShieldIcon from './svgIcons/ShieldIcon';
import EditProfileImagePenIcon from './svgIcons/EditProfileImagePenIcon';
import QuestionInCircle from './svgIcons/QuestionInCircle';
import QuestionInCircleFilled from './svgIcons/QuestionInCircleFilled';
import SidebarDashBoardIcon from './svgIcons/SidebarDashBoardIcon';
import AllCampaignsSidebarIcon from './svgIcons/AllCampaignsSidebarIcon';
import PenSquared from './svgIcons/PenSquared';
import VisaIcon from './svgIcons/VisaIcon';
import BellIcon from './svgIcons/BellIcon';
import VerifyPlayIcon from './svgIcons/VerifyPlayIcon';
import CheckmarkInCircleIcon from './svgIcons/CheckmarkInCircleIcon';
import ExclamationMarkInCircleIcon from './svgIcons/ExclamationMarkInCircleIcon';
import VerifyiedOpportunityIcon from './svgIcons/VerifyiedOpportunityIcon';
import ChatUnfilledIcon from './svgIcons/ChatUnfilledIcon';
import TextAlignmentIcon from './svgIcons/TextAlignmentIcon';
import TextAlignmentLeftIcon from './svgIcons/TextAlignmentLeftIcon';
import TextAlignmentCenterIcon from './svgIcons/TextAlignmentCenterIcon';
import TextAlignmentRightIcon from './svgIcons/TextAlignmentRightIcon';
import SpeechBoxIcon from './svgIcons/SpeechBoxIcon';
import ReportBox from './svgIcons/ReportBox';
import ScaleIcon from './svgIcons/ScaleIcon';
import EmailHealthIcon from './svgIcons/EmailHealthIcon';
import LayoutIcon from './svgIcons/LayoutIcon';
import RateGraphic from './svgIcons/RateGraphic';
import CurveGraphIcon from './svgIcons/CurveGraphIcon';
import DownloadDocumentIcon from './svgIcons/DownloadDocumentIcon';
import SortUpArrow from './svgIcons/SortUpArrow';
import SortDownArrow from './svgIcons/SortDownArrow';
import BuildBacklinks from './svgIcons/BuildBacklinks';
import BookPodcastsInterview from './svgIcons/BookPodcastsInterview';
import GetPressMentions from './svgIcons/GetPressMentions';
import RecruitAffiliates from './svgIcons/RecruitAffiliates';
import KeyboardIcon from './svgIcons/KeyboardIcon';
import CoinsIcon from '@uikit/Icon/svgIcons/CoinsIcon';
import CancelCircle from '@uikit/Icon/svgIcons/CancelCircle';
import DuplicateIcon from "@uikit/Icon/svgIcons/DuplicateIcon";
import OpportunitiesIcon from "@uikit/Icon/svgIcons/OpportunitiesIcon";
import RepliedWonIcon from "@uikit/Icon/svgIcons/RepliedWonIcon";
import RepliedLostIcon from "@uikit/Icon/svgIcons/RepliedLostIcon";
import aiStarIcon from '@uikit/Icon/svgIcons/aiStarIcon';
import SendArrowIcon from '@uikit/Icon/svgIcons/sendArrowIcon';
import Duplicate from '@uikit/Icon/svgIcons/Duplicate';
import AiAvatarIcon from '@uikit/Icon/socialSvg/AiAvatarIcon';
import ViewIcon from '@uikit/Icon/svgIcons/ViewIcon';
import AiChatIcon from '@uikit/Icon/svgIcons/AiChatIcon';
import ConfigurationIcon from '@uikit/Icon/svgIcons/ConfigurationIcon';

type IconPropsType = {
  icon: string;
  className?: string;
  id?: string;
  styles?: {
    [styleKey: string]: string;
  };
};

const iconMapper = {
  mail: mailIcon,
  person: personIcon,
  company: companyIcon,
  responaSmall,
  arrowLeftCircle,
  info,
  check,
  wallet,
  arrowDown,
  arrowUp,
  search,
  statusEdit,
  statusSwap,
  statusSuccess,
  statusPause,
  chevron,
};

const svgIconWrapper = {
  textLetter: TextLetterIcon,
  number: NumberIcon,
  ai: AiIcon,
  select: SelectIcon,
  teamMember: TeamMemberIcon,
  checkbox: CheckboxIcon,
  rating: RatingIcon,
  multiSelect: MultiSelectIcon,
  dateTime: DateTimeIcon,
  responaSmall: ResponaSmallIcon,
  upload: UploadIcon,
  visa: VisaIcon,
  minusCircle: MinusCircle,
  plusCircle: PlusCircle,
  plusIcon: PlusIcon,
  slidersSettings: SlidersSettings,
  copy: Copy,
  copyBold: CopyIcon2,
  arrowLeftCircle: ArrowLeftCircle,
  arrowUpCircle: ArrowUpCircle,
  arrowLeft: ArrowLeft,
  arrowDown: ArrowDownIcon,
  arrowRightClassic: ArrowRightClassicIcon,
  info: Info,
  infinity: Infinity,
  trash: Trash,
  person: PersonIcon,
  personRounded: PersonRoundedIcon,
  personRoundedCrossed: PersonRoundedCrossedIcon,
  trashSimple: TrashSimpleIcon,
  messageIcon: MessageIcon,
  openMessageIcon: OpenMessageIcon,
  inboxUnreadMessage: InboxUnreadMessage,
  trashWhite: TrashWhiteIcon,
  boldText: BoldTextIcon,
  italicText: ItalicTextIcon,
  underlineText: UnderlineTextIcon,
  orderedList: OrderedListIcon,
  unorderedList: UnorderedListIcon,
  pen: Pen,
  inboxIcon: InboxIcon,
  penSquared: PenSquared,
  sidebarDashboardIcon: SidebarDashBoardIcon,
  search: SearchIcon,
  crossEdit: CrossEdit,
  crossInCircle: CrossInCircleIcon,
  calendar: CalendarIcon,
  prevPage: PrevPageIcon,
  notificationsIcon: NotificationsIcon,
  nextPage: NextPageIcon,
  user: UserIcon,
  duplicate: DuplicateIcon,
  cardio: CardioIcon,
  link: LinkIcon,
  linkIcon: LinkIcon,
  linkChain: LinkChainIcon,
  linkBroken: LinkBrokenIcon,
  externalLink: ExternalLinkIcon,
  location: LocationIcon,
  play: PlayIcon,
  playRounded: PlayRoundedIcon,
  pause: PauseIcon,
  profile: ProfileIcon,
  company: CompanyIcon,
  billing: BillingIcon,
  bill: BillIcon,
  workspace: WorkspacesIcon,
  removeRelationships: RemoveRelationshipsIcon,
  workspaceOut: WorkspaceOutIcon,
  member: MemberIcon,
  folder: FolderIcon,
  allMembers: AllMembersIcon,
  email: EmailIcon,
  emails: EmailsIcon,
  emailFind: EmailFind,
  emailVerify: EmailVerify,
  bookmark: BookmarkIcon,
  checklistItemSuccesIcon: ChecklistItemSuccesIcon,
  schedules: SchedulesIcon,
  crossDelete: CrossDeleteIcon,
  checkCircled: CheckCircledIcon,
  checkCircled2: CheckCircled2Icon,
  rocket: RocketIcon,
  crossBlack: CrossBlackIcon,
  warningTriangle: WarningTriangleIcon,
  warningTriangleCircle: WarningTriangleCircleIcon,
  launching: LaunchingIcon,
  successCircle: SuccessCircleIcon,
  successCircleFilled: SuccessCircleFilledIcon,
  actions: ActionsIcon,
  actionsTwoDots: TwoDotsActionsIcon,
  shareAction: ShareIcon,
  shareWithUsIcon: ShareWithUsIcon,
  editAction: EditActionIcon,
  renameAction: RenameActionIcon,
  envelope: EnvelopeIcon,
  check: CheckIcon,
  chevron: ChevronIcon,
  pencil: PencilIcon,
  lightBulb: LightBulbIcon,
  gear: GearIcon,
  close: CloseIcon,
  import: ImportIcon,
  allCampaignsSidebarIcon: AllCampaignsSidebarIcon,
  checkmark: GreyRoundCheckmarkIcon,
  checkmarkIcon: CheckMarkIcon,
  pauseBlue: PauseBlueIcon,
  playBlue: PlayBlueIcon,
  addUserWhite: AddUserWhiteIcon,
  arrowRightGrey: ArrowRightGreyIcon,
  windowSection: WindowSectionIcon,
  crown: CrownIcon,
  questionInCircle: QuestionInCircle,
  questionInCircleFilled: QuestionInCircleFilled,
  banWhite: BanWhiteIcon,
  tagWhite: TagWhiteIcon,
  inbox: InboxWhiteIcon,
  archived: ArchivedInboxIcon,
  textMessage: TextMessageIcon,
  folderWhite: FolderWhiteIcon,
  folderOpen: FolderOpenIcon,
  addCompany: AddCompanyIcon,
  insights: InsightsIcon,
  barChart: BarChartIcon,
  lineChart: LineChartIcon,
  save: SaveIcon,
  video: VideoIcon,
  videoFile: ImageVideoFile,
  xIcon: XIcon,
  wallet: WalletIcon,
  wallet2: Wallet2Icon,
  list: List,
  crossList: CrossList,
  documentIcon: DocumentIcon,
  documentSignatureIcon: DocumentSignatureIcon,
  apple: AppleIcon,
  rrs: RrsIcon,
  download: DownloadIcon,
  suitcase: SuitcaseIcon,
  building: BuildingIcon,
  comment: CommentIcon,
  profileCrossBig: ProfileCrossBigIcon,
  brackets: BracketsIcon,
  infoComment: InfoCommentIcon,
  filter: FilterIcon,
  doubleCheckmark: DoubleCheckmarkIcon,
  priceTag: PriceTagIcon,
  globe: GlobeIcon,
  downloadFile: DownloadFileIcon,
  star: StarIcon,
  starFilled: StarFilledIcon,
  flag: FlagIcon,
  flagStriped: FlagStripedIcon,
  curves: CurvesIcon,
  volume: VolumeIcon,
  volumeMute: VolumeMuteIcon,
  spinner: SpinnerIcon,
  clockThree: ClockThreeIcon,
  refresh: RefreshIcon,
  editProfileImagePenIcon: EditProfileImagePenIcon,
  refreshOneArrowAround: RefreshIconOneArrowAround,
  creditCard: CreditCardIcon,
  logout: LogoutIcon,
  attachment: AttachmentIcon,
  image: ImageIcon,
  selectedOpportunities: SelectedOpportunitiesAbsoluteIcon,
  emptySelectedOpportunities: EmptySelectedOpportunitiesIcon,
  warningRoundedSignIcon: WarningRoundedSignIcon,
  errorInputFieldSignIcon: ErrorInputFieldSignIcon,
  plane: PlaneIcon,
  heart: HeartIcon,
  heartCross: HeartCrossIcon,
  basketball: BasketballIcon,
  userMinus: UserMinusIcon,
  userPlus: UserPlusIcon,
  expandSidebar: ExpandSidebarIcon,
  comments: CommentsIcon,
  trophy: TrophyIcon,
  trophyCross: TrophyCrossIcon,
  verifyiedOpportunityIcon: VerifyiedOpportunityIcon,
  sent: SentIcon,
  outbox: OutboxIcon,
  needAttention: NeedAttentionIcon,
  personWithMinus: PersonWithMinus,
  sequence: SequenceIcon,
  opportunities: OpportunitiesIcon,
  shield: ShieldIcon,
  bell: BellIcon,
  actionHorizontal: ActionHorizontal,
  fileBlank: FileBlankIcon,
  cornerUpLeftIcon: CornerUpLeftIcon,
  doubleCornerUpLeftIcon: DoubleCornerUpLeftIcon,
  chatUnfilled: ChatUnfilledIcon,
  spam: SpamIcon,
  archive: ArchiveIcon,
  snooze: SnoozeIcon,
  textAlignment: TextAlignmentIcon,
  textAlignmentLeft: TextAlignmentLeftIcon,
  textAlignmentRight: TextAlignmentRightIcon,
  textAlignmentCenter: TextAlignmentCenterIcon,
  connection: ConnectionIcon,
  angellist: AngelListIcon,
  appstore: AppStoreIcon,
  crunchbase: CrunchBaseIcon,
  facebook: FacebookIcon,
  github: GithubIcon,
  googleplay: GooglePlayIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  medium: MediumIcon,
  penterest: PenterestIcon,
  quora: QuoraIcon,
  verifyPlayIcon: VerifyPlayIcon,
  checkmarkInCircleIcon: CheckmarkInCircleIcon,
  exclamationMarkInCircleIcon: ExclamationMarkInCircleIcon,
  pauseIcon: PauseIcon,
  pauseTall: PauseTallIcon,
  reddit: RedditIcon,
  snapchat: SnapchatIcon,
  spotify: SpotifyIcon,
  tiktok: TikTokIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
  inquiries: InquiriesIcon,
  office: OfficeIcon,

  socketPlug: SocketPlugIcon,
  key: KeyIcon,
  lineChartMinimal: LineChartMinimalIcon,
  stopMessaging: StopMessagingIcon,
  notInterestedIcon: RepliedNotInterestedIcon,
  interestedIcon: RepliedInterestedIcon,
  wonIcon: RepliedWonIcon,
  lostIcon: RepliedLostIcon,
  cubesIcon,
  speechBoxIcon: SpeechBoxIcon,
  reportBox: ReportBox,
  scaleIcon: ScaleIcon,
  emailHealth: EmailHealthIcon,
  layoutIcon: LayoutIcon,
  rateGraphic: RateGraphic,
  curveGraph: CurveGraphIcon,
  downloadDocument: DownloadDocumentIcon,
  sortUpArrow: SortUpArrow,
  sortDownErrow: SortDownArrow,
  crosedIcon: CrosedIcon,
  arrowRefreshCircle: ArrowRefreshCircle,
  googleIcon: GoogleIcon,
  episodesIcon: EpisodesSVG,
  bingNewsIcon: BingNewsShortSVG,
  relationshipsIcon: RelationshipsSVG,
  podcastIcon: PodcastSVG,
  keywordSearchIcon: KeywordsSearcgSVG,
  csvImportSVG: CSVImportSVG,
  uploadFileIcon: UploadShortSVG,
  semrushIcon: SemrushSearchSvg,
  mozIcon: MozSVG,
  bingIcon: BingShortSVG,
  emailPersonalized: EmailPersonalized,
  userSearch: UserSearch,
  metricIcon: MetricIcon,
  crossInGreyCircle: CrossInGreyCircle,
  playBig: PlayBigIcon,
  questionMark: QuestionMark,
  blueMark: BlueMark,
  folders: FoldersIcon,
  time: TimeIcon,
  cancelIcon: CancelIcon,
  cancelCircle: CancelCircle,
  lock: LockIcon,
  bookPodcastsInterview: BookPodcastsInterview,
  buildBacklinks: BuildBacklinks,
  getPressMentions: GetPressMentions,
  recruitAffiliates: RecruitAffiliates,
  keyboard: KeyboardIcon,
  threeDots: ThreeDots,
  house: House,
  lockGrey: LockGrey,
  pin: Pin,
  lightningArrow: LightningArrowIcon,
  playArrow: PlayArrowIcon,
  pieChart: PieChartIcon,
  refreshCircle: RefreshCircleIcon,
  flagMark: FlagMarkIcon,
  chatGPT: ChatGPTicon,
  checkList: CheckListIcon,
  reduce: ReduceIcon,
  translate: TranslateIcon,
  relaxed: RelaxedSmile,
  briefCase: BriefCaseIcon,
  shySmile: ShySmileIcon,
  tearsSmile: TearsSmileIcon,
  label: LabelIcon,
  crossedOut: CrossedOutCircle,
  draft: draftIcon,
  assigned: AssignedPersonIcon,
  persons: PersonsIcon,
  statusBox: StatusBox,
  fullStar: FullStarIcon,
  avatarIcon: AvatarIcon,
  bounced: Bounced,
  dragAndDrop: DragAndDropIcon,
  recurringIcon: RecurringIcon,
  createdOpportunitiesIcon: CreatedOpportunitiesIcon,
  creditsIcon: CreditsIcon,
  flaggedIcon: FlaggedIcon,
  reportIcon: ReportIcon,
  coins: CoinsIcon,
  aiStar: aiStarIcon,
  sendArrow: SendArrowIcon,
  duplicatePlus: Duplicate,
  aiIcon: AiAvatarIcon,
  viewIcon: ViewIcon,
  aiChat: AiChatIcon,
  configuration: ConfigurationIcon,
};

export type SvgIconType = keyof typeof svgIconWrapper;

export default function ({ icon, className, styles, ...rest }: IconPropsType): JSX.Element {
  return <img style={styles} src={iconMapper[icon]} className={className} {...rest} alt="" />;
}

export const isSVGIconExist = (icon: string): boolean => svgIconWrapper[icon] !== undefined;

export function SVGIcon({
  icon,
  className,
  ...rest
}: {
  icon: SvgIconType;
  className?: string;
  color?: string;
  size?: number;
  backgroundIcon?: string;
  [key: string]: any;
}): JSX.Element {
  const Component = svgIconWrapper[icon];

  if (!Component) {
    return null;
  }

  // @ts-ignore
  return <Component {...rest} className={className} />;
}
