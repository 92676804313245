import React from 'react';

import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import TextWithIcon from '@uikit/TextWithIcon/TextWithIcon';

import WarningTriangle from '@components/Settings/Billing/_components/LastInvoiceCard/_components/WarningTriangle';

import './LastInvoiceCard.scss';
import { linkGoTo, redirectUserTo } from '@utils/historyHandler';

function LastInvoiceCard({
  invoiceNumber,
  invoiceAmount,
  invoiceChargeTime,
}: {
  invoiceNumber: string;
  invoiceAmount: number;
  invoiceChargeTime: number;
}): JSX.Element {
  const history = useHistory();
  const redirectionUrl = '/settings/billing/history';
  return (
    <div className="last-invoice-card">
      <div className="last-invoice-card__header">
        <div className="last-invoice-card__last-invoice">
          Last invoice&nbsp;
          <span className="last-invoice-card__last-invoice-number">#{invoiceNumber}</span>
          <WarningTriangle />
        </div>
        <Link
          to={linkGoTo(redirectionUrl)}
          className="last-invoice-card__last-invoice-link"
          onClick={(event) => {
            redirectUserTo(history, redirectionUrl);
          }}
        >
          <TextWithIcon icon="clockThree" iconColor="#3424EB">
            Payment history
          </TextWithIcon>
        </Link>
      </div>
      <div className="last-invoice-card__body">
        <div className="last-invoice-card__body-row">
          <div className="last-invoice-card__body-label">Amount</div>
          <div className="last-invoice-card__body-value">
            {invoiceAmount > 0 ? `$${invoiceAmount}` : '-'}
          </div>
        </div>
        <div className="last-invoice-card__body-row">
          <div className="last-invoice-card__body-label">Date</div>
          <div className="last-invoice-card__body-value">
            {invoiceChargeTime > 0 ? format(new Date(invoiceChargeTime), 'MMM dd, yyyy') : '-'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastInvoiceCard;
