import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import Input from '@uikit/Input/Input';
import Select from '@uikit/Select/Select';
import Loader from '@uikit/Loader/Loader';

import { getAllWorkspacesByUserIdApi } from '@api/userOrganization.api';
import { workspacesSelector } from '@redux/selectors/workspaces.selectors';

import { transformWorkspacesToOptions } from '@mappers/optionTransformers';
import { checkIsAnyNotLoaded } from '@utils/loadingChecks';
import urls from '@constants/urls';

import './EditMemberSidebar.scss';

const rolesOptions = [
  {
    value: 0,
    label: 'Owner',
  },
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 2,
    label: 'Member',
  },
];

function EditMemberSidebar({ member, isOpen, onClose, onUpdate }) {
  if (!isOpen || !member) {
    return null;
  }

  const {
    location: { pathname },
  } = useHistory();
  const isAllMembersPage = pathname === urls.ALL_MEMBERS;

  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);
  const [workspacesValue, changeWorkspacesValue] = useState([]);
  const [isUserWorkspacesLoaded, changeIsUserWorkspacesLoaded] = useState(false);
  const [role, changeRole] = useState(
    isAllMembersPage ? rolesOptions.find(({ value }) => value === member.rolesList[0]) : null
  );
  const [fullname, changeFullname] = useState(member.fullName);
  const [email, changeEmail] = useState(member.email);

  /*
    We cant update member name and email for now
    There is no API methods
   */

  const handleChangeFullname = ({ target: { value } }) => changeFullname(value);
  const handleChangeEmail = ({ target: { value } }) => changeEmail(value);

  /*
    We cant update member name and email for now
    There is no API methods
   */

  const handleSave = () => {
    onUpdate(
      member.id,
      role?.value,
      workspacesValue.map(({ id }) => id)
    );

    //   // Get all workspaces, which are not selected, but were in user workspaces
    //   /* const removedWorkspaces = userWorkspaces
    //     .filter(({ id }) => !workspacesValue.some(({ value }) => id === value))
    //     .map(({ id }) => id);
    //   // Get all workspaces, which are selected, but were not in user workspaces
    //   const addedWorkspaces = workspacesValue
    //     .filter(({ value }) => !userWorkspaces.some(({ id }) => id === value))
    //     .map(({ id }) => id);
    //
    //   const thunks = [];
    //
    //   if (removedWorkspaces.length > 0) {
    //     thunks.push(removeMembersFromWorkspaceBatchApi([member.userId], removedWorkspaces));
    //   }
    //   if (addedWorkspaces.length > 0) {
    //     thunks.push(addMembersToWorkspaceBatchApi([member.userId], addedWorkspaces));
    //   }
    //   Promise.all(thunks).then(() => {
    //     dispatch(addNotification({ title: 'Member was updated '}));
    //     onClose();
    //   }); */
  };

  useEffect(() => {
    getAllWorkspacesByUserIdApi(member.userId)
      .then((workspacesResponse) => {
        // @ts-ignore
        changeWorkspacesValue(workspacesResponse);
      })
      .finally(() => {
        changeIsUserWorkspacesLoaded(true);
      });
  }, []);

  return (
    <RightSidebar
      isOpen={isOpen}
      title="Edit member"
      onClose={onClose}
      onSave={handleSave}
      width="350px"
    >
      <div className="edit-member-sidebar">
        <div className="edit-member-sidebar__row">
          <div className="edit-member-sidebar__field-label">Full name</div>
          <div className="edit-member-sidebar__field">
            <Input disabled isFullWidth value={fullname} onChange={handleChangeFullname} />
          </div>
        </div>
        <div className="edit-member-sidebar__row">
          <div className="edit-member-sidebar__field-label">Email</div>
          <div className="edit-member-sidebar__field">
            <Input disabled isFullWidth value={email} onChange={handleChangeEmail} />
          </div>
        </div>

        {isAllMembersPage ? (
          <div className="edit-member-sidebar__row">
            <div className="edit-member-sidebar__field-label">Role</div>
            <div className="edit-member-sidebar__field">
              <Select value={role} options={rolesOptions} onChange={changeRole} />
            </div>
          </div>
        ) : null}
        <Loader isLoading={!isUserWorkspacesLoaded || checkIsAnyNotLoaded(workspacesLs)}>
          <div className="edit-member-sidebar__row">
            <div className="edit-member-sidebar__field-label">Workspaces</div>
            <div className="edit-member-sidebar__field">
              <Select
                isMulti
                value={transformWorkspacesToOptions(workspacesValue)}
                options={transformWorkspacesToOptions(workspaces)}
                onChange={changeWorkspacesValue}
              />
            </div>
          </div>
        </Loader>
      </div>
    </RightSidebar>
  );
}

export default EditMemberSidebar;
