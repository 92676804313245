import React, { useState } from 'react';

import { NoteType } from '@ts/common.types';
import Avatar from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';
import NoteEditor from '@components/SidebarCRM/sections/SidebarCRMSectionNotes/_components/NoteEditor';
import { WorkspaceMemberShortType } from '@ts/workspace.types';
import { getDateShort } from '@utils/date';
import { Button } from '@uikit/Button/Button';

function NotePreview({
  note,
  members,
  onSave,
  onRemove,
}: {
  note: NoteType;
  members: WorkspaceMemberShortType[];
  onSave?: (newValue: string, noteId: number) => Promise<void>;
  onRemove?: (noteId: number) => Promise<void>;
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const owner = members.find((member) => member.userId === note.ownerId);

  if (isEditing) {
    return (
      <NoteEditor
        onSave={async (data) => {
          await onSave(data, note.id);
          setIsEditing(false);
        }}
        onClose={() => setIsEditing(false)}
        initialValue={note.content}
      />
    );
  }

  return (
    <div className="contacts-sidebar-section-notes__note">
      <div className="contacts-sidebar-section-notes__note-header">
        <span onClick={() => setIsEditing(true)}>
          <SVGIcon icon="pencil" color="#868686" size={10} />
        </span>
        {!isDeleting && (
          <span onClick={() => setIsDeleting(true)}>
            <SVGIcon icon="trashSimple" color="#868686" size={10} />
          </span>
        )}
        {isDeleting && (
          <div className="contacts-sidebar-section-notes__note-header-confirm">
            <Button
              onClick={() => onRemove(note.id)}
              leftIcon="checkmarkIcon"
              type="additional"
              iconSize={15}
              colorIcon="green"
            />
            <Button
              onClick={() => setIsDeleting(false)}
              leftIcon="crosedIcon"
              type="additional"
              iconSize={15}
              colorIcon="red"
            />
          </div>
        )}
      </div>
      {note.content}
      <div className="contacts-sidebar-section-notes__footer">
        <div className="contacts-sidebar-section-notes__footer-owner">
          <Avatar
            url={owner?.logoUrl}
            size="xxs"
            className="contacts-sidebar-section-notes__footer-owner-logo"
          />
          <span>{owner?.fullName || '-'}</span>
        </div>
        <div className="contacts-sidebar-section-notes__footer-time">
          {getDateShort(new Date(note.createdAt), true, true)}
        </div>
      </div>
    </div>
  );
}

export default NotePreview;
