import { getInvitationInfoApi, getTimeZone, loginByIntegrationApi } from '@api/auth.api';
import BingShortSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/assets/BingShortSVG';

import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import { LoginStep } from '@pages/LoginPage/LoginPage';
import { useGoogleLogin } from '@react-oauth/google';
import { addNotification } from '@redux/actions/notifications.actions';
import { LoginResponseType, VerificationEmailResponseType } from '@ts/auth.types';
import { Button } from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import PasswordScore from '@uikit/PasswordScore/PasswordScore';
import passwordValidation from '@utils/passwordValidation';
import { validateEmail } from '@utils/validations';
import cn from 'classnames';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';
import {
  AuthHandlerType,
  AuthHandlerTypeMap,
  VerificationCodeType,
} from 'respona_api/generated/auth-shared_pb';

// @ts-ignore
import googleIcon from 'src/assets/googleIcon.svg';
import { authService } from 'src/services/authService';
import { DispatchType } from 'src/store';
import Avatar from '@uikit/Avatar/Avatar';
import gtm from 'src/libs/gtm';
import WarningTriangleIcon from '@uikit/Icon/svgIcons/WarningTriangleIcon';
import { CodeType } from 'respona_api/generated/common_pb';

declare const IS_DEV: boolean;

type Props = {
  isLoading: boolean;
  handleInput?: (key: string, value: string | boolean) => void;
  updateRef?: (key: string, value: boolean) => void;
  onSubmit: (step?: LoginStep) => void;
  history?: History;
  redirectUserTo?: (history: History, url) => void;
  state?: {
    email: string;
    password: string;
    fullName: string;
    code: string;
    captcha: string;
    newPassword: string;
    showCaptcha: boolean;
    type: AuthHandlerTypeMap[keyof AuthHandlerTypeMap];
  };
};

export function LoginSignupFlow(
  props: Props & {
    setIsLoading: (isLoading: boolean) => void;
    setLoginResponse: (response: LoginResponseType) => void;
    verification?: VerificationEmailResponseType;
  }
) {
  const location = useLocation();
  const parsedSearch = qs.parse(location.search);
  const [googleHover, setGoogleHover] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [affiliateCode, setAffiliateCode] = useState<string | null>(null);
  const { ref, src, ps_xid } = parsedSearch;

  useEffect(() => {
    if (ref && ref.length > 0) {
      setReferralCode(ref as string);
    }
  }, [ref]);

  useEffect(() => {
    if (ps_xid && ps_xid.length > 0) {
      setAffiliateCode(ps_xid as string);
    }
  }, [ps_xid]);

  const utmTagPairs = Object.entries(parsedSearch).filter(([key]) => key.includes('utm_')) as [
    string,
    string,
  ][];

  const loginWithGoogle = useGoogleLogin({
    onSuccess: ({ code }) => {
      props.setIsLoading(true);
      loginByIntegrationApi(
        code,
        [
          { key: referralCode, type: CodeType.REFERRAL_CODE },
          { key: affiliateCode, type: CodeType.PARTNER_STACK },
        ],
        utmTagPairs,
        src as string
      )
        .then((result) => {
          props.setLoginResponse(result);
          if (result.firstLogin) {
            gtm.signUp(result.userEmail);
            gtm.signIn(result.userId);
            props.redirectUserTo(props.history, `/${LoginStep.onboarding}`);
          } else {
            gtm.signIn(result.userId);
            authService.loginCallback(props.history, result);
          }
        })
        .finally(() => props.setIsLoading(false));
    },
    onError: (errorResponse) => {
      console.error('Google Auth error', errorResponse);
    },
    flow: 'auth-code',
    scope: 'profile email',
  });

  const verificationFailed = props.verification?.valid === false;

  return (
    <div className="login-container-content">
      <div className="login-container__title">Log in or sign up</div>
      <div className="login-container__subtitle">
        By continuing, you agree to Respona’s{' '}
        <a
          className="login-container__subtitle-link"
          href="https://respona.com/terms-of-use/"
          target="_blank"
        >
          Terms of Use
        </a>
        . Read our{' '}
        <a
          className="login-container__subtitle-link"
          href="https://respona.com/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
        .
      </div>

      <div
        className="login-container__button-column"
        // onMouseEnter={() => setGoogleHover(true)}
        // onMouseLeave={() => setGoogleHover(false)}
      >
        <Button className="login-container__button" onClick={() => loginWithGoogle()}>
          {!googleHover ? (
            <>
              <img src={googleIcon} className="login-container__button-img" alt="google-icon" />
              Continue with Google
            </>
          ) : (
            <>
              <WarningTriangleIcon size={24} />
              <div className="login-container__button-text">
                Temporarily unavailable
                <span className="login-container__button-sub-text">
                  Please continue with “Work email” below
                </span>
              </div>
            </>
          )}
        </Button>

        <Button
          className="login-container__button"
          onClick={() =>
            window.open(
              `https://${
                IS_DEV ? 'dev.respona.com' : 'app.respona.com'
              }/auth/microsoft/secure/aad?time-zone=${encodeURIComponent(getTimeZone())}${
                ref ? `&ref=${ref}` : ''
              }`,
              '_self'
            )
          }
        >
          <div
            className="login-container__button-img"
            style={{
              width: 'auto',
              paddingLeft: '14px',
              marginRight: '20px',
            }}
          >
            <BingShortSVG size={30} />
          </div>
          Continue with Microsoft
        </Button>
      </div>

      <div className="login-container__divider-row">
        <div className="login-container__divider" />
        <span>or continue with your email </span>
        <div className="login-container__divider" />
      </div>

      <div className="login-container__label">Work email</div>
      <Input
        type="email"
        name="email"
        className="login-container__input"
        value={props.state.email}
        disabled={
          props.state.type === AuthHandlerType.GOOGLE ||
          props.state.type === AuthHandlerType.MICROSOFT
        }
        onKeyDown={(e) =>
          e.key === 'Enter' &&
          validateEmail(props.state.email) &&
          !verificationFailed &&
          props.onSubmit()
        }
        onChange={({ target: { value } }) => props.handleInput('email', value)}
        placeholder="name@domain.com"
        afterText={
          verificationFailed ? (
            <QuestionTooltip icon="warningTriangle" text={props.verification?.message} />
          ) : null
        }
      />

      <Button
        className="login-container__login-button"
        onClick={props.onSubmit}
        isLoading={props.isLoading}
        disabled={
          (!props.state.captcha && props.state.showCaptcha) ||
          !validateEmail(props.state.email) ||
          verificationFailed
        }
      >
        Continue
      </Button>
    </div>
  );
}

export function LoginFlow(props: Props) {
  const isDisabled = !validateEmail(props.state.email) || props.state.password?.length < 6;

  const onKeyDown = (e) => e.key === 'Enter' && !isDisabled && props.onSubmit();

  return (
    <div className="login-container-content">
      <div className="login-container__title">Welcome back</div>
      <div className="login-container__subtitle">
        Enter your password to log in to your Respona account.
      </div>

      <div className="login-container__input-column">
        <div className="login-container__label">Work email</div>
        <Input
          disabled
          className="login-container__input"
          onKeyDown={onKeyDown}
          value={props.state.email}
          onChange={({ target: { value } }) => props.handleInput('email', value)}
          placeholder="name@domain.com"
        />

        <div className="login-container__label">
          Password
          <span
            className="login-container__label-link"
            onClick={() => props.redirectUserTo(props.history, `/${LoginStep.forgotPassword}`)}
          >
            Forgot your password?
          </span>
        </div>
        <Input
          type="password"
          className="login-container__input login-container__input-password"
          onKeyDown={onKeyDown}
          value={props.state.password}
          onChange={({ target: { value } }) => props.handleInput('password', value)}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        />
      </div>

      <Button
        className="login-container__login-button"
        onClick={props.onSubmit}
        isLoading={props.isLoading}
        disabled={(!props.state.captcha && props.state.showCaptcha) || isDisabled}
      >
        Log in
      </Button>

      {/* <div className="login-container__switch-step"> */}
      {/*  <span onClick={() => props.redirectUserTo(props.history, `/${LoginStep.welcome}`)}>Go back</span> */}
      {/* </div> */}

      <div className="login-container__switch-step">
        Don’t have an account?{' '}
        <span onClick={() => props.redirectUserTo(props.history, `/${LoginStep.welcome}`)}>
          Start for free
        </span>
      </div>
    </div>
  );
}

export function SignUpFlow(props: Props) {
  const { search } = useLocation();
  const { token } = qs.parse(search);

  const [isLoading, setIsLoading] = useState(Boolean(token));

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      getInvitationInfoApi(token.toString())
        .then(({ email }) => {
          props.handleInput('email', email);
        })
        .finally(() => setIsLoading(false));
    }
  }, [token]);

  return (
    <div className="login-container-content">
      {token ? (
        <>
          <div className="login-container__title">Welcome to Respona</div>
          <div className="login-container__subtitle">
            Please enter your full name and password to join your team.
          </div>
        </>
      ) : (
        <>
          <div className="login-container__title">Create a new account</div>
          <div className="login-container__subtitle">
            Your login email can be different from the sending emails you connect to Respona.
          </div>
        </>
      )}

      <div className="login-container__input-column">
        <div className="login-container__label">Work email</div>
        <Input
          disabled
          type="email"
          name="email"
          className="login-container__input"
          value={props.state.email}
          // onChange={({ target: { value } }) => props.handleInput('email', value)}
          onChange={({ target: { value } }) => null}
          placeholder={isLoading ? 'Loading...' : 'name@domain.com'}
        />

        <div className="login-container__label">Full name</div>
        <Input
          className="login-container__input"
          name="name"
          value={props.state.fullName}
          onChange={({ target: { value } }) => props.handleInput('fullName', value)}
          placeholder="John Doe"
        />

        <div className="login-container__label">Password</div>
        <Input
          type="password"
          className="login-container__input"
          value={props.state.password}
          onChange={({ target: { value } }) => props.handleInput('password', value)}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        />
        <div className="login-container__input-message">
          <PasswordScore {...passwordValidation(props.state.password)} />
        </div>
      </div>

      <Button
        className="login-container__login-button"
        onClick={props.onSubmit}
        isLoading={props.isLoading}
        disabled={
          (!props.state.captcha && props.state.showCaptcha) ||
          !validateEmail(props.state.email) ||
          passwordValidation(props.state.password).score < 2 ||
          !props.state.fullName ||
          props.state.fullName.trim().length < 2 ||
          props.state.fullName.trim().split(/\s+/).length < 2 ||
          props.state.fullName
            .trim()
            .split(/\s+/)
            .some((name) => name.length < 2)
        }
      >
        {token ? 'Continue' : 'Get verification code'}
      </Button>

      {!token && (
        <div className="login-container__switch-step">
          Already have an account?{' '}
          <span onClick={() => props.redirectUserTo(props.history, `/${LoginStep.welcome}`)}>
            Log in
          </span>
        </div>
      )}
    </div>
  );
}

export function CodeConfirmationFlow(props: Props) {
  const dispatch = useDispatch<DispatchType>();

  const [resendDisabled, setResendDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendNewCode = () => {
    if (resendDisabled || isLoading) {
      return;
    }

    setIsLoading(true);
    authService
      .sendVerificationCode(props.state.email, VerificationCodeType.REGISTRATION)
      .then(() => {
        setResendDisabled(true);
        dispatch(addNotification({ title: 'New code is sent.', type: 'success' }));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (resendDisabled) {
      const id = setTimeout(() => setResendDisabled(false), 10000);
      return () => clearTimeout(id);
    }
  }, [resendDisabled]);

  return (
    <div className="login-container-content">
      <div className="login-container__title">You’re almost signed up</div>
      <div className="login-container__subtitle">
        Enter the 6-digit code we just sent to your email to finish signing up.
      </div>

      <div className="login-container__input-column">
        <div className="login-container__label">
          Code sent to <div className="login-container__label-blue">{props.state.email}</div>
        </div>
        <Input
          className="login-container__input"
          value={props.state.code}
          onChange={({ target: { value } }) => props.handleInput('code', value)}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
        />
      </div>

      <Button
        className="login-container__login-button"
        onClick={props.onSubmit}
        isLoading={props.isLoading}
        disabled={(!props.state.captcha && props.state.showCaptcha) || props.state.code.length < 6}
      >
        Verify email
      </Button>

      <div className="login-container__code-row">
        <div
          className={cn('login-container__code-button', {
            'login-container__code-button--disabled': resendDisabled || isLoading,
          })}
          onClick={sendNewCode}
        >
          Send a new code
        </div>
        <div
          className="login-container__code-button"
          onClick={() => props.redirectUserTo(props.history, '/welcome')}
        >
          Try a different email
        </div>
      </div>
    </div>
  );
}
export function OnboardingSessionFlow(props: Props) {
  const avatarUrl = 'https://respona-public-files.s3.amazonaws.com/farzad.jpeg';

  return (
    <div className="login-container-content">
      <div className="login-container__title">Onboarding webinar</div>
      <div className="login-container__subtitle">
        Attend a free onboarding session with the founder of Respona!
      </div>

      <div className="login-container__onboarding_session-block">
        <div className="login-container__onboarding_session-block__text">
          “In 30 mins, I’ll share little-known tips and strategies to help you get the most from
          your trial and see real results-fast.”
        </div>
        <div className="login-container__onboarding_session-block__farzad">
          <Avatar url={avatarUrl} fullName="" size="xm" />
          <div className="login-container__onboarding_session-block__farzad__text">
            <span className="login-container__onboarding_session-block__farzad__name">
              Farzad Rashidi
            </span>
            <span className="login-container__onboarding_session-block__farzad__position">
              Co-founder of Respona
            </span>
          </div>
        </div>
      </div>

      <Button
        className="login-container__login-button"
        onClick={() => {
          props.updateRef('setupDemo', true);
          props.onSubmit();
        }}
        isLoading={props.isLoading}
        disabled={false}
      >
        Schedule free onboarding webinar
      </Button>

      <div className="login-container__switch-step">
        <span
          onClick={() => {
            props.updateRef('setupDemo', false);
            props.onSubmit();
          }}
        >
          I’ve previously used Respona
        </span>
      </div>
    </div>
  );
}

export function RequestUpdatePasswordFlow(props: Props) {
  return (
    <div className="login-container-content">
      <div className="login-container__title">Update your password</div>
      <div className="login-container__subtitle" style={{ width: '304px' }}>
        Enter your login email to reset your password. We’ll send you the verification code.
      </div>

      <div className="login-container__input-column">
        <div className="login-container__label">Work email</div>
        <Input
          className="login-container__input"
          value={props.state.email}
          onChange={({ target: { value } }) => props.handleInput('email', value)}
          placeholder="name@domain.com"
        />
      </div>

      <Button
        className="login-container__login-button"
        onClick={props.onSubmit}
        isLoading={props.isLoading}
        disabled={
          (!props.state.captcha && props.state.showCaptcha) || !validateEmail(props.state.email)
        }
      >
        Get verification code
      </Button>

      <div className="login-container__switch-step">
        <span onClick={() => props.redirectUserTo(props.history, `/${LoginStep.welcome}`)}>
          Back to login
        </span>
      </div>
    </div>
  );
}

export function UpdatePasswordFlow(props: Props) {
  const dispatch = useDispatch<DispatchType>();

  const [resendDisabled, setResendDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendNewCode = () => {
    if (resendDisabled || isLoading) {
      return;
    }

    setIsLoading(true);
    authService
      .sendVerificationCode(props.state.email, VerificationCodeType.RESET_PASSWORD)
      .then(() => {
        setResendDisabled(true);
        dispatch(addNotification({ title: 'New code is sent.', type: 'success' }));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (resendDisabled) {
      const id = setTimeout(() => setResendDisabled(false), 10000);
      return () => clearTimeout(id);
    }
  }, [resendDisabled]);

  return (
    <div className="login-container-content">
      <div className="login-container__title">Update your password</div>
      <div className="login-container__subtitle" style={{ width: '304px' }}>
        Enter the 6-digit code we just sent to your email to finish signing up.
      </div>

      <div className="login-container__input-column">
        <div className="login-container__label">
          Code sent to <div className="login-container__label-blue">{props.state.email}</div>
        </div>
        <Input
          className="login-container__input"
          value={props.state.code}
          onChange={({ target: { value } }) => props.handleInput('code', value)}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;"
        />
        <div className="login-container__label">New Password</div>
        <Input
          type="password"
          className="login-container__input"
          value={props.state.newPassword}
          onChange={({ target: { value } }) => props.handleInput('newPassword', value)}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
        />

        <div className="login-container__input-message">
          <PasswordScore {...passwordValidation(props.state.newPassword)} />
        </div>
      </div>

      <Button
        className="login-container__login-button"
        onClick={props.onSubmit}
        isLoading={props.isLoading}
        disabled={
          (!props.state.captcha && props.state.showCaptcha) ||
          props.state.code.length < 6 ||
          props.state.newPassword.length < 6
        }
      >
        Update password
      </Button>

      <div className="login-container__code-row">
        <div
          className={cn('login-container__code-button', {
            'login-container__code-button--disabled': resendDisabled || isLoading,
          })}
          onClick={sendNewCode}
        >
          Send a new code
        </div>
        <div
          className="login-container__code-button"
          onClick={() => props.redirectUserTo(props.history, `/${LoginStep.forgotPassword}`)}
        >
          Try a different email
        </div>
      </div>
    </div>
  );
}
