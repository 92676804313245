import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import cn from 'classnames';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { Button } from '@uikit/Button/Button';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import {
  CampaignAutomationStatus,
  CampaignAutomationStatusMap,
} from 'respona_api/generated/common_pb';
import { deleteCampaignPipelineApi } from '@api/pipeline.api';
import { downloadImportReportApi } from '@api/fileImport.api';
import { getBlobFromBase64 } from '@utils/createFileFromBytesArray';
import downloadCSV from '@utils/downloadCSV';
import Display from '@components/Display/Display';
import PipelineStatistics from '@components/CampaignSettings/ContentSearchActivityLogs/_components/PipelineStatistics';
import ReviewStep from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/ReviewStep/ReviewStep';
import { CampaignPipelineResponseType } from '@ts/pipelilne.types';
import { CampaignPipelineType, CampaignPipelineTypeMap } from 'respona_api/generated/pipeline_pb';
import useFormatDate from '@components/CampaignSettings/ContentSearchActivityLogs/hooks/useFormatDate';
import useOpportunitySections from '@components/CampaignSettings/ContentSearchActivityLogs/hooks/useOpportunitySections';
import getUIForType from '@components/CampaignSettings/ContentSearchActivityLogs/utils/getUIForType';
import getUIForStatus from '@components/CampaignSettings/ContentSearchActivityLogs/utils/getUIForStatus';
import { PRIMARY_COLOR } from '@constants/colors';
import useCampaignPipelines from '@hooks/useCampaignPipelines';
import { exportOpportunityInspectionResultApi } from '@api/inspectionAutomation.api';
import { downloadResponse } from '@helpers/fileHelpers';

type PipelineListItemProps = {
  id: number;
  campaignId: number;
  workspaceId: number;
  type: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap];
  status: CampaignAutomationStatusMap[keyof CampaignAutomationStatusMap];
  searchQueriesList: string[];
  finishedAt: number;
  nextStartAt: number;
  opportunitiesAdded: number;
  importErrorRowsNumber: number;
  inspectionRemovedNumber: number;
  contactsAdded: number;
  personalizationAdded: number;
  warningMessage: string;
  opportunitiesWithContacts: number;
  creditsSpent: number;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setOpenFindContactsModal: Dispatch<SetStateAction<boolean>>;
  setOpenPersonalizerModal: Dispatch<SetStateAction<boolean>>;
  setActivePipelineId: Dispatch<SetStateAction<number>>;
  hideRow: number;
  setHideRow: Dispatch<SetStateAction<number>>;
};

const PipelineListItem: React.FC<PipelineListItemProps> = ({
  id,
  campaignId,
  workspaceId,
  type,
  status,
  searchQueriesList,
  finishedAt,
  nextStartAt,
  opportunitiesAdded,
  importErrorRowsNumber,
  inspectionRemovedNumber,
  contactsAdded,
  personalizationAdded,
  warningMessage,
  opportunitiesWithContacts,
  creditsSpent,
  setOpenModal,
  setOpenFindContactsModal,
  setOpenPersonalizerModal,
  setActivePipelineId,
  hideRow,
  setHideRow,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { formatDate } = useFormatDate();
  const { getOpportunitySectionState, changeOpenOpportunitySectionState } =
    useOpportunitySections();
  const { updateItem: updatePipelineInCache, removeItem: removePipelineFromCache } =
    useCampaignPipelines(workspaceId, campaignId);

  const onPipelineChangeState = useCallback(
    (pipelineId: number, newState: CampaignPipelineResponseType) => {
      updatePipelineInCache(pipelineId, newState);

      queryClient.refetchQueries({
        queryKey: ['contacts-exists-by-campaign'],
        exact: false,
      });
    },
    [dispatch, queryClient]
  );

  const handleConfirmDeleteRow = useCallback(
    (pipelineId: number) => {
      deleteCampaignPipelineApi(pipelineId, campaignId, workspaceId).then(() => {
        removePipelineFromCache(pipelineId);
        setHideRow(-1);
      });
    },
    [campaignId, workspaceId, dispatch]
  );

  const downloadImportReport = useCallback(
    (pipelineId: number) => {
      downloadImportReportApi(campaignId, pipelineId).then(({ title, fileBytes }) => {
        if (!fileBytes) {
          throw new Error('File not found');
        }

        const fileBlob = getBlobFromBase64(fileBytes, 'text/csv');
        downloadCSV(title || `Flagged-items-${Date.now()}`, fileBlob);
      });
    },
    [campaignId]
  );

  const downloadFlaggedReport = useCallback(
    (pipelineId: number) => {
      exportOpportunityInspectionResultApi(pipelineId, campaignId, workspaceId).then((response) => {
        downloadResponse(response);
      });
    },
    [campaignId]
  );

  const reviewStepItemCn = cn('review-step-list__item', {
    'review-step-list__item--open-wide':
      getOpportunitySectionState(id)
  });

  const reviewStepStatisticCn = cn('review-step-list__statistics', {
    'review-step-list__statistics--wide':
      getOpportunitySectionState(id),
  });

  const getIconByType = useCallback(
    (value: CampaignPipelineTypeMap[keyof CampaignPipelineTypeMap]): SvgIconType => {
      switch (value) {
        case CampaignPipelineType.GOOGLE_SEARCH:
          return 'googleIcon';
        case CampaignPipelineType.BACKLINK_SEARCH:
          return 'keywordSearchIcon';
        case CampaignPipelineType.PODCAST_SEARCH:
          return 'podcastIcon';
        case CampaignPipelineType.IMPORT:
          return 'csvImportSVG';
        case CampaignPipelineType.CONTACT_AUTOMATION:
          return 'relationshipsIcon';
        case CampaignPipelineType.PERSONALIZATION_AUTOMATION:
          return 'emailPersonalized';
        default:
          return 'csvImportSVG';
      }
    },
    []
  );

  const getItemFooter = () => {
    const isVisible = (type === CampaignPipelineType.IMPORT && importErrorRowsNumber > 0)
      || (type !== CampaignPipelineType.IMPORT && inspectionRemovedNumber > 0);
    const noItemsVisible = (type === CampaignPipelineType.IMPORT && importErrorRowsNumber <= 0)
      || (type !== CampaignPipelineType.IMPORT && inspectionRemovedNumber <= 0)

    return (
      <>
        {isVisible ? (
          <div className="review-step-list__statistics__download">
            Download the list of opportunities that were flagged{' '}
            <span
              className="review-step-list__statistics__download__button"
              onClick={(event) => {
                event.stopPropagation();
                type !== CampaignPipelineType.IMPORT ? downloadImportReport(id) : downloadFlaggedReport(id);
              }}
            >
                <SVGIcon icon="reportIcon" size={12} color={PRIMARY_COLOR} />
                Flagged-items.csv
              </span>
          </div>
        ) : null}
        {noItemsVisible ? (
          <div className="review-step-list__statistics__download">
            No flagged items
          </div>
        ) : null}
      </>
    );
  };

  return status === CampaignAutomationStatus.IN_PROCESS ? (
    <ReviewStep
      pipelineId={id}
      key={id}
      onUpdateState={(pipeline) => onPipelineChangeState(id, pipeline)}
    />
  ) : (
    <div
      className={reviewStepItemCn}
      key={id}
      onClick={() => {
        if (getOpportunitySectionState(id)) {
          changeOpenOpportunitySectionState(id);
        } else if (type === CampaignPipelineType.CONTACT_AUTOMATION) {
          setOpenFindContactsModal(true);
        } else if (type === CampaignPipelineType.PERSONALIZATION_AUTOMATION) {
          setOpenPersonalizerModal(true);
        } else {
          setActivePipelineId(id);
          setOpenModal(true);
        }
      }}
    >
      <div className={`review-step-list__item-container ${hideRow === id ? 'hidden' : ''}`}>
        <div className="review-step-list__item-container-content">
          <SVGIcon icon={getIconByType(type)} size={17} />
          <span
            className="review-step-list__item-container-content-text"
            style={{ color: '#393939' }}
          >
            <div>{getUIForType(type)}</div>
          </span>
        </div>
        <div className="review-step-list__item-container-content">
          {(status === CampaignAutomationStatus.DRAFT ||
            status === CampaignAutomationStatus.FUTURE) && (
            <span className="review-step-list__item-container-content-icon-sircle">
              <SVGIcon icon="arrowRefreshCircle" color="#221CB6" />
            </span>
          )}
          {status === CampaignAutomationStatus.FINISHED && (
            <span className="review-step-list__item-container-content-icon-sircle green">
              <SVGIcon icon="check" color="#ffffff" size={10} />
            </span>
          )}
          {status === CampaignAutomationStatus.CANCELED && (
            <span className="review-step-list__item-container-content-icon-sircle green">
              <SVGIcon icon="cancelIcon" color="#ffffff" size={10} />
            </span>
          )}
          <span
            className="review-step-list__item-container-content-text"
            style={{ color: '#393939' }}
          >
            {getUIForStatus(status)}
          </span>
          {warningMessage && warningMessage.length > 0 && (
            <QuestionTooltip
              place="top"
              icon="warningTriangle"
              color="#C6C6C6"
              style={{ marginTop: '2px' }}
              text={warningMessage}
            />
          )}
        </div>
        <div className="review-step-list__item-container-content">
          {type !== CampaignPipelineType.IMPORT && (
            <span className="review-step-list__item-container-content-number">
              {searchQueriesList.length}
            </span>
          )}
          <span
            className="review-step-list__item-container-content-text"
            style={{ color: '#8F9199' }}
          >
            <span className="mr-5">
              {searchQueriesList[0]}
              {searchQueriesList.length > 1 ? ',' : null}
            </span>
            <Display isVisible={searchQueriesList.length > 1}>
              <span className={searchQueriesList.length > 1 ? 'long-text' : ''}>
                {searchQueriesList.slice(1).map((word, index) => (
                  <span key={index}>
                    {index > 0 ? ', ' : ''}
                    {word}
                  </span>
                ))}
              </span>
            </Display>
          </span>
        </div>
        {status === CampaignAutomationStatus.FUTURE ? (
          <div className="review-step-list__item-container-content">
            <span
              className="review-step-list__item-container-content-text"
              style={{ color: '#8F9199' }}
            >
              Next run: {formatDate(nextStartAt, true)}
            </span>
          </div>
        ) : (
          <div className="review-step-list__item-container-content">
            <span
              className="review-step-list__item-container-content-text"
              style={{ color: '#8F9199' }}
            >
              {formatDate(finishedAt)}
            </span>
          </div>
        )}
        {type === CampaignPipelineType.CONTACT_AUTOMATION && (
          <div className="review-step-list__item-container-content">
            <span
              className="review-step-list__item-container-content-text"
              style={{ color: '#8F9199' }}
            >
              {contactsAdded} {contactsAdded <= 1 ? 'contact' : 'contacts'} found
            </span>
          </div>
        )}
        {type === CampaignPipelineType.PERSONALIZATION_AUTOMATION && (
          <div className="review-step-list__item-container-content">
            <span
              className="review-step-list__item-container-content-text"
              style={{ color: '#8F9199' }}
            >
              {personalizationAdded} personalized{' '}
              {personalizationAdded <= 1 ? 'opportunity' : 'opportunities'}
            </span>
          </div>
        )}
        {type < CampaignPipelineType.CONTACT_AUTOMATION && (
          <div className="review-step-list__item-container-content">
            <span
              className="review-step-list__item-container-content-text"
              style={{ color: '#8F9199' }}
            >
              {opportunitiesAdded} {opportunitiesAdded <= 1 ? 'opportunity' : 'opportunities'} added
            </span>
          </div>
        )}
        {status === CampaignAutomationStatus.FUTURE && (
          <span
            className="review-step-list__item-container-delete-button"
            onClick={(event) => {
              event.stopPropagation();
              setHideRow(id);
            }}
          >
            <SVGIcon icon="xIcon" size={20} color="#ffffff" />
          </span>
        )}
        {(status === CampaignAutomationStatus.FINISHED ||
          opportunitiesAdded > 0 ||
          contactsAdded > 0 ||
          personalizationAdded > 0) &&
          finishedAt > 1720282318810 && (
            <div
              className="review-step-list__dropdown"
              onClick={(event) => {
                event.stopPropagation();
                changeOpenOpportunitySectionState(id);
              }}
            >
              <span
                className={cn('review-step-list__dropdown__btn', {
                  'review-step-list__dropdown__btn--open': getOpportunitySectionState(id),
                })}
              >
                <SVGIcon icon="arrowDown" color="#7B7B7BB0" />
              </span>
            </div>
          )}
        {importErrorRowsNumber > 0 && (
          <span
            className="review-step-list__item-container-download-button"
            onClick={(event) => {
              event.stopPropagation();
              downloadImportReport(id);
            }}
          >
            <SVGIcon icon="download" color="#7B7B7BB0" size={20} />
          </span>
        )}
        {inspectionRemovedNumber > 0 && (
          <span
            className="review-step-list__item-container-download-button"
            onClick={(event) => {
              event.stopPropagation();
              downloadFlaggedReport(id);
            }}
          >
            <SVGIcon icon="download" color="#7B7B7BB0" size={20} />
          </span>
        )}
      </div>
      <div
        className={`review-step-list__item-confirm-delete ${hideRow === id ? 'open' : 'hidden'}`}
      >
        <p>Are you sure?</p>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleConfirmDeleteRow(id);
          }}
        >
          Confirm
        </Button>
        <Button
          type="alert"
          onClick={(event) => {
            event.stopPropagation();
            setHideRow(-1);
          }}
        >
          Cancel
        </Button>
      </div>
      {getOpportunitySectionState(id) && (
        <div className={reviewStepStatisticCn}>
          <PipelineStatistics
            type={type}
            opportunitiesAdded={opportunitiesAdded}
            opportunitiesWithContacts={opportunitiesWithContacts}
            importErrorRowsNumber={importErrorRowsNumber}
            inspectionRemovedNumber={inspectionRemovedNumber}
            contactsAdded={contactsAdded}
            creditsSpent={creditsSpent}
          />
          {getItemFooter()}
        </div>
      )}
    </div>
  );
};

export default PipelineListItem;
