/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './PriorityIcon.scss';

type PriorityIconPropsType = {
  priority: number;
  leftMargin?: boolean;
  rightMargin?: boolean;
};

const priorityToStringMap = {
  0: 'high',
  1: 'medium',
  2: 'low',
};

export default ({ priority, leftMargin, rightMargin }: PriorityIconPropsType): JSX.Element => (
  <span
    className={cn('priority-icon', {
      [`priority-icon--${priorityToStringMap[priority]}`]: true,
      'priority-icon--left-margined': leftMargin,
      'priority-icon--right-margined': rightMargin,
    })}
  />
);
