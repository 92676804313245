// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import qs from 'query-string';

import requestErrorParser from '@helpers/requestErrorParser';

import {
  creatSmtpAccountApi,
  reAuthenticateSmtpAccountApi,
  testMailboxAccountApi,
} from '@api/mailboxAccounts.api';
import {
  EncryptionMethodMap,
  MailboxAccountResponse,
  TestConnectionResponseStreamContainer,
} from 'respona_api/generated/mailbox-account_pb';

import { addNotification } from '@redux/actions/notifications.actions';
import Loader from '@uikit/Loader/Loader';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';

import RightSidebar from '@components/RightSidebar/RightSidebar';
import PageHeader from '@components/PageHeader/PageHeader';
import UpsellEmailSidebar from '@components/UpsellSidebars/UpsellEmailSidebar/UpsellEmailSidebar';

import './SMTPEmailSettings.scss';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import ReactTooltip from 'react-tooltip';
import useMailboxAccountSettings from '@hooks/useMailboxAccountSettings';
import { redirectUserTo } from '@utils/historyHandler';


type EncryptionType = {
  validSmtpEncryption: EncryptionMethodMap[keyof EncryptionMethodMap];
  validSmtpPort: number;
  validImapEncryption: EncryptionMethodMap[keyof EncryptionMethodMap];
  validImapPort: number;
};

type FieldsValuesType = {
  mainFields?: {
    senderEmail?: string;
    senderFullName?: string;
  };
  smtpFields?: {
    username?: string;
    password?: string;
    serverAddress?: string;
    port?: string | number;
  };
  imapFields?: {
    username?: string;
    password?: string;
    serverAddress?: string;
    port?: string | number;
  };
};

function Field({
  label,
  value,
  children,
  onChange,
  placeholder,
  name,
  isError = false,
  error = '',
  type = 'text',
  disabled = false,
  onBlur = () => null,
  ...rest
}) {
  return (
    <div className="smpt-email-settings__field-row">
      <div className="smpt-email-settings__field-label">
        {label} {children != null && children}
      </div>
      <Input
        {...rest}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        isError={isError}
        error={error}
        type={type}
        disabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
}

function DoubledField({
  firstLabel,
  firstValue,
  onFirstChange,
  firstPlaceholder,
  firstName,
  isFirstError = false,
  secondLabel,
  secondValue,
  onSecondChange,
  secondPlaceholder,
  secondName,
  isSecondError = false,
}) {
  return (
    <div className="smpt-email-settings__field-row smpt-email-settings__field-row--doubled">
      <div className="smpt-email-settings__left-field">
        <div className="smpt-email-settings__field-label">{firstLabel}</div>
        <Input
          value={firstValue}
          placeholder={firstPlaceholder}
          onChange={onFirstChange}
          name={firstName}
          isError={isFirstError}
          isFullWidth
        />
      </div>
      <div className="smpt-email-settings__right-field">
        <div className="smpt-email-settings__field-label">{secondLabel}</div>
        <Input
          value={secondValue}
          placeholder={secondPlaceholder}
          onChange={onSecondChange}
          name={secondName}
          isError={isSecondError}
          isFullWidth
        />
      </div>
    </div>
  );
}

const validate = (values: FieldsValuesType) => {
  let isValid = true;
  const errors: FieldsValuesType = {
    mainFields: {},
    smtpFields: {},
    imapFields: {},
  };

  Object.keys(values).forEach((mailFieldKey) => {
    Object.keys(values[mailFieldKey]).forEach((secondaryFieldKey) => {
      if (String(values[mailFieldKey][secondaryFieldKey])?.trim() === '') {
        errors[mailFieldKey][secondaryFieldKey] = 'Required field';
        isValid = false;
      }
    });
  });

  if (Number.isNaN(Number(values.smtpFields.port))) {
    errors.smtpFields.port = 'Should be a number';
    isValid = false;
  }

  if (Number.isNaN(Number(values.imapFields.port))) {
    errors.imapFields.port = 'Should be a number';
    isValid = false;
  }

  return isValid ? null : errors;
};

function SMTPEmailSettings({
  withHeader = true,
  onSuccess,
  onClose,
}: {
  withHeader?: boolean;
  onSuccess?: (res?: MailboxAccountResponse.AsObject, isReAuthentication: boolean) => void;
  onClose?: () => void;
}): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: mailboxIdUrlValue } = qs.parse(location.search);
  const [isUpsellEmailSidebarOpen, changeIsUpsellEmailSidebarOpen] = useState(false);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const [isButtonWaitForResponse, changeIsButtonWaitForResponse] = useState(false);
  const [initialValues, setInitialValues] = useState<FieldsValuesType>();

  const refForCacheDataBeforeAutobuy = useRef();

  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const handleCloseUpsellEmailSidebar = () => changeIsUpsellEmailSidebarOpen(false);

  const mailboxId = useMemo(() => {
    if (mailboxIdUrlValue == null) {
      return -1;
    }
    const id = parseInt(mailboxIdUrlValue as string, 10);
    if (Number.isInteger(id)) {
      return id;
    }

    return -1;
  }, [mailboxIdUrlValue]);

  const handleOpenUpsellEmailSidebar = (data) => {
    if (billingDetails.state === 6) {
      setPaymentSidebarOpen(true);
    } else {
      changeIsUpsellEmailSidebarOpen(true);
    }
    refForCacheDataBeforeAutobuy.current = data;
  };

  const { item: mailboxCrudData } = useMailboxAccountSettings(mailboxId);

  useEffect(() => {
    if (mailboxId < 1) {
      setInitialValues({
        mainFields: {
          senderEmail: '',
          senderFullName: '',
        },
        smtpFields: {
          username: '',
          password: '',
          serverAddress: '',
          port: 587,
        },
        imapFields: {
          username: '',
          password: '',
          serverAddress: '',
          port: 993,
        },
      });
      return;
    }

    if (mailboxCrudData == null) {
      return;
    }

    setInitialValues({
      mainFields: {
        senderEmail: mailboxCrudData.email,
        senderFullName: mailboxCrudData.senderName,
      },
      smtpFields: {
        username: mailboxCrudData.smtp?.smtpUsername,
        password: '',
        serverAddress: mailboxCrudData.smtp?.smtpHost,
        port: mailboxCrudData.smtp?.smtpport,
      },
      imapFields: {
        username: mailboxCrudData.smtp?.imapUsername,
        password: '',
        serverAddress: mailboxCrudData.smtp?.imapHost,
        port: mailboxCrudData.smtp?.imapPort,
      },
    });
  }, [mailboxId, mailboxCrudData]);

  const handeTestSMPTResult = (
    data: TestConnectionResponseStreamContainer.AsObject,
    formValues
  ) => {
    const { inProcess, result } = data;

    if (inProcess) {
      return;
    }

    if (!result.success) {
      dispatch(addNotification({ title: result.message, type: 'error' }));
      changeIsButtonWaitForResponse(false);
      return;
    }

    handleCreateAccount(
      {
        validSmtpEncryption: result.validSmtpEncryption,
        validSmtpPort: result.validSmtpPort,
        validImapEncryption: result.validImapEncryption,
        validImapPort: result.validImapPort,
      },
      formValues
    );
  };

  const handleClose = () => (onClose ? onClose() : history.goBack());

  const handleTestSmpt = (values) => {
    testMailboxAccountApi(
      values.mainFields.senderFullName,
      values.mainFields.senderEmail,
      values.smtpFields,
      values.imapFields,
      ([data]) => handeTestSMPTResult(data, values)
    ).catch((err) => {
      requestErrorParser(err, false);
      changeIsButtonWaitForResponse(false);
    });
  };

  const handleCreateAccount = (validEncryption: EncryptionType, values) => {
    if (mailboxId > 0) {
      reAuthenticateSmtpAccountApi(
        mailboxId,
        values.mainFields.senderFullName,
        values.mainFields.senderEmail,
        values.smtpFields,
        values.imapFields,
        validEncryption.validSmtpEncryption,
        validEncryption.validImapEncryption
      )
        .then((res: MailboxAccountResponse.AsObject) => {
          dispatch(addNotification({ title: 'Account re-authenticated', type: 'success' }));
          onSuccess
            ? onSuccess(res, mailboxId > 0)
            : redirectUserTo(history, '/settings/all-emails');
        })
        .finally(() => changeIsButtonWaitForResponse(false));
    } else {
      handleCreateSMTPAccount(values, validEncryption);
    }
  };

  const handleCreateSMTPAccountSubmit = (values) => {
    changeIsButtonWaitForResponse(true);
    handleTestSmpt(values);
  };

  const handleCreateSMTPAccount = (values, validEncryption: EncryptionType, isAutoBuy = false) => {
    changeIsButtonWaitForResponse(true);
    creatSmtpAccountApi(
      values.mainFields.senderFullName,
      values.mainFields.senderEmail,
      { ...values.smtpFields, port: validEncryption.validSmtpPort },
      { ...values.imapFields, port: validEncryption.validImapPort },
      validEncryption.validSmtpEncryption,
      validEncryption.validImapEncryption,
      isAutoBuy
    )
      .then((res: MailboxAccountResponse.AsObject) => {
        onSuccess ? onSuccess(res, mailboxId > 0) : redirectUserTo(history, '/settings/all-emails');
      })
      .catch((err) => {
        const error = JSON.parse(err.message);

        if (error.code === 4444) {
          handleOpenUpsellEmailSidebar({
            ...values,
            validEncryption,
          });
        }
      })
      .finally(() => changeIsButtonWaitForResponse(false));
  };

  const handleCreateAccountAfterUpsell = () => {
    const { validEncryption, ...values } = refForCacheDataBeforeAutobuy.current;
    handleCreateSMTPAccount(values, validEncryption);
  };

  const handleClickByDisabledButton = () => {
    dispatch(
      addNotification({
        title: 'Please fill out all fields before connecting the email',
        type: 'warning',
      })
    );
  };

  return (
    <div className="smpt-email-settings">
      {withHeader ? (
        <PageHeader
          title={mailboxId > 0 ? 'Re-authenticate' : 'Set up a new email'}
          renderHeaderActions={() => (
            <span
              className="smpt-email-settings__close"
              onClick={handleClose}
              tabIndex={0}
              role="button"
            >
              <SVGIcon icon="crossBlack" />
            </span>
          )}
        />
      ) : null}
      <div className="smpt-email-settings__content">
        <Loader isLoading={!initialValues}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleCreateSMTPAccountSubmit}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
          >
            {({ handleChange, setFieldValue, values, errors, handleSubmit }) => {
              // Validation for empty fields to make button status disabled without errors
              const [noErrors, changeNoErrors] = useState<boolean>(false);

              const checkValuesAndDecideStatusOfButton = useCallback((valuesFromForm) => {
                const arrayValues = Object.keys(valuesFromForm);
                for (const arrayValuesKey of arrayValues) {
                  const arrayValuesSecondLevel = Object.values(valuesFromForm[arrayValuesKey]);
                  for (const value of arrayValuesSecondLevel) {
                    if (value && String(value)?.trim() === '') {
                      changeNoErrors(false);
                      return;
                    }
                  }
                }
                changeNoErrors(true);
              }, []);

              useEffect(() => {
                checkValuesAndDecideStatusOfButton(values);
              }, [values]);

              /*
              For autocomplete if fields are empty
             */
              const setSmtpPasswordToImap = (value) => setFieldValue('imapFields.password', value);
              const setImapPasswordToSmtp = (value) => setFieldValue('smtpFields.password', value);
              const setSenderEmailToSmtp = (value) => setFieldValue('smtpFields.username', value);
              const setSenderEmailToImap = (value) => setFieldValue('imapFields.username', value);

              return (
                <>
                  <div className="smpt-email-settings__main-grid">
                    <div className="smpt-email-settings__smtp-setting">
                      <Field
                        value={values.mainFields.senderEmail}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (!values.smtpFields.username) {
                            setSenderEmailToSmtp(e.target.value);
                          }

                          if (!values.imapFields.username) {
                            setSenderEmailToImap(e.target.value);
                          }
                        }}
                        label="Sender Email"
                        placeholder="e.g. name@domain.com"
                        name="mainFields.senderEmail"
                        isError={errors?.mainFields?.senderEmail}
                      />
                      <div className="smpt-email-settings__title">SMTP Settings</div>
                      <Field
                        value={values.smtpFields.username}
                        onChange={handleChange}
                        label="SMTP Username"
                        placeholder="e.g. name@domain.com"
                        name="smtpFields.username"
                        isError={errors?.smtpFields?.username}
                      />
                      <Field
                        value={values.smtpFields.password}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (!values.imapFields.password) {
                            setSmtpPasswordToImap(e.target.value);
                          }
                        }}
                        label="App Password"
                        placeholder="Passwords are secure and encrypted"
                        name="smtpFields.password"
                        isError={errors?.smtpFields?.password}
                        type="password"
                        autoComplete="new-password"
                      >
                        <>
                          <span
                            className="mail-settings-smtp-password"
                            data-for="mail-settings-smtp-password"
                            data-tip=""
                          >
                            <SVGIcon color="#C6C6C6" icon="questionInCircleFilled" />
                          </span>
                          <ReactTooltip
                            id="mail-settings-smtp-password"
                            className="react-tooltip"
                            place="top"
                            effect="solid"
                            arrowColor="transparent"
                          >
                            <p className="custom-fields-sidebar__field-label-tooltip">
                              {`We recommend setting up 2 factor authentication for your email, and
                                creating an app password for this application. If you don’t have
                                2FA enabled, you can instead enter your email’s password.`}
                            </p>
                          </ReactTooltip>
                        </>
                      </Field>
                      <DoubledField
                        firstLabel="SMTP Server"
                        firstPlaceholder="e.g. smtp.domain.com"
                        firstValue={values.smtpFields.serverAddress}
                        onFirstChange={handleChange}
                        firstName="smtpFields.serverAddress"
                        isFirstError={errors?.smtpFields?.serverAddress}
                        secondLabel="Port"
                        secondPlaceholder="e.g. 587"
                        secondValue={values.smtpFields.port}
                        onSecondChange={handleChange}
                        secondName="smtpFields.port"
                        isSecondError={errors?.smtpFields?.port}
                      />
                    </div>
                    <div className="smpt-email-settings__imap-settings">
                      <Field
                        value={values.mainFields.senderFullName}
                        onChange={handleChange}
                        label="Sender’s Full Name"
                        placeholder="e.g. Petr Novak"
                        name="mainFields.senderFullName"
                        isError={errors?.mainFields?.senderFullName}
                      />
                      <div className="smpt-email-settings__title">IMAP Settings</div>
                      <Field
                        value={values.imapFields.username}
                        onChange={handleChange}
                        label="IMAP Username"
                        placeholder="e.g. name@domain.com"
                        name="imapFields.username"
                        isError={errors?.imapFields?.username}
                      />
                      <Field
                        value={values.imapFields.password}
                        onChange={handleChange}
                        onBlur={(e) => {
                          if (!values.smtpFields.password) {
                            setImapPasswordToSmtp(e.target.value);
                          }
                        }}
                        label="App Password"
                        placeholder="Passwords are secure and encrypted"
                        name="imapFields.password"
                        isError={errors?.imapFields?.password}
                        type="password"
                        autoComplete="new-password"
                      >
                        <>
                          <span
                            className="mail-settings-imap-password"
                            data-for="mail-settings-imap-password"
                            data-tip=""
                          >
                            <SVGIcon color="#C6C6C6" icon="questionInCircleFilled" />
                          </span>
                          <ReactTooltip
                            id="mail-settings-imap-password"
                            className="react-tooltip"
                            place="top"
                            effect="solid"
                            arrowColor="transparent"
                          >
                            <p className="custom-fields-sidebar__field-label-tooltip">
                              {`We recommend setting up 2 factor authentication for your email, and
                                creating an app password for this application. If you don’t have 2FA enabled,
                                you can instead enter your email’s password.`}
                            </p>
                          </ReactTooltip>
                        </>
                      </Field>
                      <DoubledField
                        firstLabel="IMAP Server"
                        firstPlaceholder="e.g. imap.domain.com"
                        firstValue={values.imapFields.serverAddress}
                        onFirstChange={handleChange}
                        firstName="imapFields.serverAddress"
                        isFirstError={errors?.imapFields?.serverAddress}
                        secondLabel="Port"
                        secondPlaceholder="e.g. 993"
                        secondValue={values.imapFields.port}
                        onSecondChange={handleChange}
                        secondName="imapFields.port"
                        isSecondError={errors?.imapFields?.port}
                      />
                    </div>
                  </div>

                  <div className="smpt-email-settings__footer">
                    <Button type="alert" onClick={handleClose}>
                      Cancel
                    </Button>

                    <div className="smpt-email-settings__footer-right">
                      <Button
                        onClick={handleSubmit}
                        isLoading={isButtonWaitForResponse}
                        disabled={!noErrors || isButtonWaitForResponse}
                        onClickDisabled={handleClickByDisabledButton}
                      >
                        {mailboxId > 0 ? 'Re-authenticate Email' : 'Connect Email'}
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Loader>
      </div>

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {isUpsellEmailSidebarOpen && (
        <UpsellEmailSidebar
          title="You have exceeded your email account limit. Would you like to increase your limit?"
          onClose={handleCloseUpsellEmailSidebar}
          onActionAfterPay={() => {
            handleCreateAccountAfterUpsell();
          }}
        />
      )}
    </div>
  );
}

export default SMTPEmailSettings;

export function SMTPEmailSettingsSidebar({ onClose, onSuccess }) {
  return (
    <div className="smpt-email-settings__sidebar">
      <RightSidebar isOpen onClose={onClose} title="Set up a new email" showFooter={false}>
        <div className="smpt-email-settings__sidebar-content">
          <SMTPEmailSettings onSuccess={onSuccess} onClose={onClose} withHeader={false} />
        </div>
      </RightSidebar>
    </div>
  );
}
