import {
  CampaignAutomationFilterField,
} from 'respona_api/generated/automation-campaign_pb';

import {
  FilterField,
} from '@components/HeaderTemplate/HeaderTemplateFilters/FiltersTooltipContentRaw/FiltersTooltipContentRaw';

enum CampaignAgentStatus {
  AGENT_DRAFT_STATUS = 0,
  AGENT_LAUNCHED_STATUS = 1,
  AGENT_PAUSED_STATUS = 2,
}

export const aiAgentsStatuses = [
  { value: CampaignAgentStatus[CampaignAgentStatus.AGENT_LAUNCHED_STATUS], label: 'In Progress' },
  { value: CampaignAgentStatus[CampaignAgentStatus.AGENT_DRAFT_STATUS], label: 'Draft' },
  { value: CampaignAgentStatus[CampaignAgentStatus.AGENT_PAUSED_STATUS], label: 'Paused' },
];

export const aiAgentsListFilterFields = (
  users: { value: string | number; label: string }[] = []
): FilterField[] => [
  {
    label: 'Agent name',
    value: CampaignAutomationFilterField.STRING_AGENT_TITLE,
    type: 'string',
  },
  {
    label: 'Status',
    value: CampaignAutomationFilterField.DROPDOWN_AGENT_STATUS,
    type: 'enumString',
    inputOptions: aiAgentsStatuses,
  },
  {
    label: 'Owner',
    value: CampaignAutomationFilterField.DROPDOWN_AGENT_OWNER_ID,
    type: 'enumString',
    inputOptions: users,
  },
  {
    label: 'Created on',
    value: CampaignAutomationFilterField.DATE_AGENT_CREATED_AT,
    type: 'date',
  },
];
