/**
 * NOT CHECKED
 */
import React from 'react';

import './FileExtensionIcon.scss';

const FileExtensionIcon = ({ extension }: { extension: string }): JSX.Element => (
  <div className="file-extension-icon">{extension}</div>
);

export default FileExtensionIcon;
