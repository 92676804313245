import React, { useEffect } from 'react';

import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import Loader from '@uikit/Loader/Loader';
import NoAccessMessage from '@uikit/NoAccessMessage/NoAccessMessage';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

import queryCacheKeys from '@constants/queryCacheKeys.constants';

import { createUpdaterCacheItemById } from '@helpers/cacheUpdateHelper';
import Pagination from '@uikit/Pagination/Pagination';
import usePagination from '@hooks/usePagination';
import useNonInitialEffect from '@hooks/useNonInitialEffect';
import StubIllustration from '@uikit/StubIllustration/StubIllustration';

import tableConfig, { RenderRow } from './tableConfig';

import { redirectUserTo } from '@utils/historyHandler';
import {
  changeCampaignAutomationStatusApi,
  deleteCampaignAutomationApi,
  getAutomationCampaignsApi,
} from '@api/campaignAutomation.api';
import { CampaignAutomationType } from '@ts/automationCampaigns.types';
import { FilterFieldType } from '@ts/filterField.type';
import {
  CampaignAutomationFilterFieldMap,
} from 'respona_api/generated/automation-campaign_pb';

import './AiAgentsTable.scss';

function AiAgentsTable({
  checkboxes,
  setCheckboxes,
  getRefetchCampaigns,
  onCreateNewAgentClick,
  isLoading,
  appliedFilters,
}: {
  checkboxes: CampaignAutomationType[];
  setCheckboxes: (ids: CampaignAutomationType[]) => void;
  getRefetchCampaigns: any;
  onCreateNewAgentClick: () => void;
  isLoading: boolean;
  appliedFilters: FilterFieldType<CampaignAutomationFilterFieldMap>[];
}): JSX.Element {
  const queryClient = useQueryClient();
  const history = useHistory();
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { page, changePage, limit, changeLimit, changeToDefault } = usePagination();

  const campaignsQueryKey = [queryCacheKeys.agentsList, page, limit];

  const {
    isFetching: isCampaignsFetching,
    data: campaigns = [],
    error: campaignsError,
    refetch: refetchCampaigns,
  } = useQuery(
    campaignsQueryKey,
    () => getAutomationCampaignsApi(workspaceId, page - 1, limit, appliedFilters),
    {
      enabled: false,
      retry: false,
    }
  );

  useEffect(() => {
    getRefetchCampaigns(refetchCampaigns);
  }, []);

  useEffect(() => {
    if (workspaceId) {
      refetchCampaigns();
      changeToDefault();
    }
  }, [workspaceId, appliedFilters]);

  useNonInitialEffect(() => {
    if (workspaceId) {
      refetchCampaigns();
    }
  }, [page, limit]);

  const handleAgentClick = (campaignId, status) => {
    redirectUserTo(history, `/workspaces/${workspaceId}/ai-agents/${campaignId}/instructions`);
  };

  const handleChangeAgentStatus = (campaignId, newStatus) => {
    return changeCampaignAutomationStatusApi(campaignId, workspaceId, newStatus).then(() => {
      return queryClient.setQueryData(
        campaignsQueryKey,
        createUpdaterCacheItemById(campaignId, 'status', newStatus)
      );
    });
  };

  const handleRemoveAutomation = (automationId: number) =>
    deleteCampaignAutomationApi(automationId, workspaceId).then(() => refetchCampaigns());

  const handleChangeStatusFilter = (filter: number) => {
    // dispatch(setStatusForFilterCampaign(filters?.status === filter ? 0 : filter));
  };

  if (isCampaignsFetching) {
    return <Loader isLoading withTopMargin />;
  }

  // @ts-ignore
  const errorMessage = campaignsError && campaignsError.message;

  if (errorMessage && errorMessage.indexOf('User is not a member of workspace') !== -1) {
    return <NoAccessMessage message="Oops! You don't have access to this workspace." />;
  }

  if (campaigns.length === 0) {
    return (
      <StubIllustration
        text="Let’s create your first agent :)"
        buttonText="Start new automation"
        onClickButton={() => onCreateNewAgentClick()}
      />
    );
  }

  const handlerSelectAll = () => {
    if (campaigns.length === checkboxes.length) {
      setCheckboxes([]);
    } else {
      setCheckboxes(campaigns);
    }
  };

  const handlerSelectOne = (row: CampaignAutomationType) => {
    if (checkboxes.find((campaign) => campaign.id === row.id)) {
      setCheckboxes(checkboxes.filter((campaign) => campaign.id !== row.id));
    } else {
      setCheckboxes([row, ...checkboxes]);
    }
  };

  const isSelectedAll = () => {
    if (campaigns.length > checkboxes.length) {
      return false;
    }

    return campaigns.every(({ id }) => checkboxes.find(({ id: campaignId }) => id === campaignId));
  };

  return (
    <Loader isLoading={isLoading}>
      <div className="ai-agents-table-wrapper">
        <ConfigurableTable
          config={tableConfig}
          data={campaigns}
          tableClassName="ai-agents-table"
          renderProps={{
            onRowClick: handleAgentClick,
            onRemove: handleRemoveAutomation,
            onUpdateStatus: handleChangeAgentStatus,
            handlerSelectAll,
            handlerSelectOne,
            isSelectedAll: isSelectedAll(),
            checkboxes,
          }}
          RenderRow={RenderRow}
        />
      </div>
      <Pagination
        curPage={page}
        onChangePage={changePage}
        pageLimit={limit}
        onChangePageLimit={changeLimit}
        totalItems={campaigns[0]?.totalItems}
      />
    </Loader>
  );
}

export default AiAgentsTable;
