/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './TextWithIcon.scss';

export default ({
  children,
  icon,
  iconColor,
  iconSize,
  disabled,
  className,
}: {
  children: JSX.Element | string;
  icon: string;
  iconColor?: string;
  iconSize?: number;
  disabled?: boolean;
  className?: string;
}): JSX.Element => (
  <span
    className={cn('text-with-icon', {
      'text-with-icon--disabled': disabled,
      [className]: !!className,
    })}
  >
    <span className="text-with-icon__icon">
      <SVGIcon
        icon={icon as SvgIconType}
        color={disabled ? '#BFC2CC' : iconColor}
        size={iconSize}
      />
    </span>
    {children}
  </span>
);
