import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CampaignShortcutType, CampaignType } from '@ts/campaigns.types';
import { WorkspaceMemberShortType } from '@ts/workspace.types';

import { getFirstNamePlusBigFirstCharOfLastName } from '@utils/string.utils';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import Display from '@components/Display/Display';

import { FolderType, TagType } from '@ts/common.types';

import { peopleFiltersSelector } from '@redux/selectors/people.selectors';
import { throwFiltersForPeople } from '@redux/actions/people.actions';
import { getPeopleFilterFields } from '@constants/filters/peopleFilterFields';
import withSmallScreensCheck from '@hocs/withSmallScreensCheck';

import HeaderSearchInput from '@components/HeaderSearchInput/HeaderSearchInput';
import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import RelationshipsFiltersTooltipContent from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContent';

import MoreActionsButton, {
  OnAddToCampaignFunctionType,
} from './MoreActionsButton/MoreActionsButton';

import './PeopleHeaderActions.scss';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import { workspacesSelector } from '@redux/selectors/workspaces.selectors';
import { WorkspaceShortType } from '@ts/userOrganization.types';
import { initialRelationshipPeopleFilterRawState } from '@redux/reducers/people.reducer';

type PeopleHeaderActionsPropsType = {
  selectedIds?: number[];
  onCreatePerson?: () => void;
  onOpenRemovePeople: () => void;
  onCreateNewFolder: () => void;
  onSearch?: (string) => void;
  onMoveToFolder: ({ value, label }: { value: number; label: string }) => void;
  onOpenExportPeople: () => void;
  onAddTag?: (title?: any, id?: any) => void;
  onAddToBlackList: () => void;
  onAddToCampaign: OnAddToCampaignFunctionType;
  folders?: FolderType[];
  tags?: TagType[];
  onApplyFilters?: (PeopleFilterObjectType) => void;
  onChangeSearchString: (string) => void;
  members?: WorkspaceMemberShortType[];
  campaignsList?: CampaignShortcutType[];
  searchString?: string;
  allSelected?: boolean;
};

function PeopleHeaderActions({
  selectedIds = [],
  onCreatePerson,
  onOpenRemovePeople,
  onOpenExportPeople,
  onCreateNewFolder,
  onMoveToFolder,
  onAddTag,
  onAddToBlackList,
  onAddToCampaign,
  folders = [],
  tags = [],
  onSearch,
  onApplyFilters,
  searchString,
  onChangeSearchString,
  isSmallScreen,
  members,
  allSelected,
  campaignsList,
}: PeopleHeaderActionsPropsType & { isSmallScreen: boolean }): JSX.Element {
  const dispatch = useDispatch();
  const peopleFiltersFromRedux = useSelector(peopleFiltersSelector);
  const { workspaces, loadingStatus: workspacesLs } = useSelector(workspacesSelector);

  const checkFilterValues = useCallback(
    (filtersArray) => filtersArray.some(({ value }) => value.toString()?.length > 0),
    []
  );
  const areFiltersEnabled = useMemo(
    () => checkFilterValues(Object.values(peopleFiltersFromRedux)),
    [peopleFiltersFromRedux]
  );

  const { data: billingDetails } = useSelector(billingDetailsSelector);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);

  const handleClearPeopleFilters = () => dispatch(throwFiltersForPeople());

  const peopleFilterFields = getPeopleFilterFields(
    members.map((item) => ({
      value: item.userId,
      label: getFirstNamePlusBigFirstCharOfLastName(item.fullName),
    })),
    tags.map((item: TagType) => ({
      value: item.id,
      label: item.title,
    })),
    campaignsList?.map((item: CampaignType) => ({
      value: item.id,
      label: item.title,
    })),
    workspaces?.map((item: WorkspaceShortType) => ({ value: item.id, label: item.title }))
  );

  function onExportClick() {
    if (billingDetails.state === 6) {
      setPaymentSidebarOpen(true);
    } else {
      onOpenExportPeople();
    }
  }

  return (
    <div className="people-header-actions">
      <Display isVisible={!selectedIds.length}>
        <HeaderSearchInput
          onUpdateSearchQuery={onChangeSearchString}
          searchQuery={searchString}
          placeholder="Search contacts"
        />
      </Display>

      <Display isVisible={selectedIds.length > 0}>
        <MoreActionsButton
          onMoveToFolder={onMoveToFolder}
          onCreateNewFolder={onCreateNewFolder}
          onAddTag={onAddTag}
          onAddToBlackList={onAddToBlackList}
          onAddToCampaign={onAddToCampaign}
          folders={folders}
          tags={tags}
          isSmallScreen={isSmallScreen}
        />
        <Display isVisible={onOpenRemovePeople !== undefined}>
          <Button
            onClick={onOpenRemovePeople}
            type="alert"
            className="people-header-actions__remove-btn"
          >
            <SVGIcon icon="trashWhite" />
            {isSmallScreen ? null : 'Remove'}
          </Button>
        </Display>
        <div className="people-header-actions__separator" />
        <Button
          onClick={() => onExportClick()}
          className="people-header-actions__export-users-btn"
          type="additional"
          size="xl"
        >
          <SVGIcon icon="download" />
          {isSmallScreen ? null : 'Export'}
        </Button>
      </Display>

      <Display isVisible={onApplyFilters !== undefined}>
        <HeaderFiltersButtonWithTooltip
          areFiltersEnabled={areFiltersEnabled}
          onClearAllFilters={handleClearPeopleFilters}
        >
          <RelationshipsFiltersTooltipContent
            onApplyFilters={onApplyFilters}
            filterFields={peopleFilterFields}
            filtersFromRedux={peopleFiltersFromRedux}
            initialFilterState={initialRelationshipPeopleFilterRawState}
          />
        </HeaderFiltersButtonWithTooltip>
      </Display>
      <Display isVisible={onCreatePerson !== undefined}>
        <Button onClick={onCreatePerson} className="people-header-actions__add-user-btn" size="l">
          <SVGIcon icon="addUserWhite" />
        </Button>
      </Display>
      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
            onOpenExportPeople();
          }}
        />
      )}
    </div>
  );
}

export default withSmallScreensCheck<PeopleHeaderActionsPropsType>(PeopleHeaderActions);
