/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './PasswordScore.scss';

const PasswordScore = ({ score, text }: { score: 0 | 1 | 2 | 3; text: string }): JSX.Element => {
  const cnPasswordScore = cn('password-score', {
    'password-score--bad': score === 1,
    'password-score--average': score === 2,
    'password-score--strong': score === 3,
  });

  return (
    <div className={cnPasswordScore}>
      <div className="password-score__label">{text}</div>
      <div className="password-score__blocks-list">
        <div className="password-score__block" />
        <div className="password-score__block" />
        <div className="password-score__block" />
        <div className="password-score__block" />
      </div>
    </div>
  );
};

export default PasswordScore;
