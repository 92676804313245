import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SVGIcon } from '@uikit/Icon/Icon';
import ProgressBar from '@uikit/ProgressBar/ProgressBar';

import './ReviewStep.scss';
import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import { DispatchType } from 'src/store';
import Display from '@components/Display/Display';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { getRefetchOpportunities } from '@redux/selectors/pipelines.selectors';
import { Button } from '@uikit/Button/Button';
import { CampaignPipelineResponseType } from '@ts/pipelilne.types';
import { getCampaignPipelineProgressApi, stopActiveCampaignPipelineApi } from '@api/pipeline.api';
import { CampaignPipelineType, PipelineStepType } from 'respona_api/generated/pipeline_pb';
import { useQuery } from 'react-query';
import { getOpportunitiesStatsApi } from '@api/campaign.api';
import useCampaignPipelines from '@hooks/useCampaignPipelines';

function ReviewCard({
  icon,
  iconColor,
  iconSize,
  title,
  number,
  numberColor,
  titleColor,
  iconSircle,
}: {
  icon?;
  iconColor?;
  iconSize?;
  title;
  number?;
  numberColor?;
  titleColor?;
  iconSircle?;
}): JSX.Element {
  return (
    <div className="review-card">
      {icon && !iconSircle && <SVGIcon icon={icon} size={iconSize || ''} color={iconColor} />}
      {iconSircle && (
        <span className="review-card__icon-sircle">
          <SVGIcon icon={icon} size={iconSize || ''} color={iconColor} />
        </span>
      )}
      <span className={`review-card__title ${icon ? 'ml-5' : ''}`} style={{ color: titleColor }}>
        {title}
      </span>
      <span className="review-card__number" style={{ color: numberColor }}>
        {number}
      </span>
    </div>
  );
}

function ReviewStep({
  pipelineId,
  onUpdateState,
}: {
  pipelineId: number;
  onUpdateState: (automationState: CampaignPipelineResponseType) => void;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const [automationState, setAutomationState] = useState<CampaignPipelineResponseType>(null);
  const [pipelineStream, setPipelineStream] = useState<any>(null);
  const [cancelButtonVisible, setCancelButtonVisible] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const refEl = useRef(null);
  const {
    info: { id: campaignId, workspaceId, lastBatch },
  } = useSelector(getActiveCampaignInfo);
  const refetchOpportunities = useSelector(getRefetchOpportunities);

  const { data: dataOpportunitiesStats } = useQuery(
    ['opportunities-stats-counter', campaignId, workspaceId, lastBatch],
    () => getOpportunitiesStatsApi(campaignId, workspaceId, lastBatch),
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
    }
  );

  const composeIcon = () => {
    if (automationState == null) {
      return '';
    }
    switch (automationState.type) {
      case 0:
        return 'googleIcon';
      case 1:
        return 'keywordSearchIcon';
      case 3:
        return 'podcastIcon';
      case 4:
        return 'csvImportSVG';
      case 5:
        return 'relationshipsIcon';
      case 6:
        return 'emailPersonalized';
      default:
        return '';
    }
  };

  const composeProgressState = () => {
    let progress = 'In progress';
    if (automationState == null) {
      return progress;
    }

    if (automationState.stepType === PipelineStepType.FINDING_OPPORTUNITIES) {
      progress = 'Finding opportunities';
    } else if (automationState.stepType === PipelineStepType.FINDING_CONTACTS) {
      progress = 'Getting contacts';
    } else if (automationState.stepType === PipelineStepType.PERSONALIZING_EMAILS) {
      progress = 'Personalizing emails';
    } else if (automationState.stepType === PipelineStepType.INSPECTING_OPPORTUNITIES) {
      progress = 'Opportunity inspections';
    }
    if (automationState.currentStepNumber > 0) {
      progress += ` (step ${automationState.currentStepNumber} / ${automationState.totalStepsNumber})`;
    }
    return progress;
  };

  const composeTitle = () => {
    if (automationState == null) {
      return '';
    }
    switch (automationState.type) {
      case 0:
        return 'Google Search';
      case 1:
        return 'Backlink Search';
      case 3:
        return 'Podcast Search';
      case 4:
        return 'Import Flow';
      case 5:
        return 'Contacts Search';
      case 6:
        return 'AI Personalization';
      default:
        return '';
    }
  };

  const composeOppAdded = () => {
    if (automationState == null) {
      return 0;
    }
    return automationState.opportunitiesAdded;
  };

  const composePersAdded = () => {
    if (automationState == null) {
      return 0;
    }
    return automationState.personalizationAdded;
  };

  const composePersFound = () => {
    if (dataOpportunitiesStats == null) {
      return 0;
    }
    return dataOpportunitiesStats.withoutPersonalization;
  };

  const composeContactsAdded = () => {
    if (automationState == null) {
      return 0;
    }
    return automationState.contactsAdded;
  };

  const composeContactsFound = () => {
    if (dataOpportunitiesStats == null) {
      return 0;
    }
    return dataOpportunitiesStats.withoutContacts;
  };

  const composeCreditsSpent = () => {
    if (automationState == null) {
      return 0;
    }
    return automationState.creditsSpent;
  };

  const composeProgress = () => {
    if (automationState == null) {
      return 0;
    }
    return Math.ceil(automationState.completedPercentage);
  };

  const fetchProgress = () => {
    try {
      getCampaignPipelineProgressApi(
        pipelineId,
        workspaceId,
        (data: [CampaignPipelineResponseType, any]) => {
          const pipelineTick = data[0];
          setAutomationState(pipelineTick);
          setPipelineStream(data[1]);

          if (pipelineTick.status === 0 && pipelineTick.reloadOpportunities) {
            if (refEl != null && refEl.current != null) {
              refEl.current.click();
            }
          } else if (pipelineTick.status === 1) {
            if (refEl != null && refEl.current != null) {
              refEl.current.click();
            }
            dispatch(fetchBillingCredits());
            onUpdateState(pipelineTick);
          }
        },
        () => {
          console.log('Processing finished');
        }
      );
    } catch (e) {
      setTimeout(() => fetchProgress(), 500);
    }
  };

  useEffect(() => {
    fetchProgress();
  }, [pipelineId]);

  const onApproveCancelClick = () => {
    setIsCancelling(true);
    stopActiveCampaignPipelineApi(pipelineId, campaignId, workspaceId)
      .then((response) => {
        onUpdateState(response);
        if (pipelineStream != null) {
          try {
            setCancelButtonVisible(false);
            pipelineStream.cancel();
            setPipelineStream(null);
          } catch (e) {
            console.error(`Error while closing active stream: ${e?.message}`, e);
          }
        }
      })
      .finally(() => setIsCancelling(false));
  };

  function renderTotalValueReviewCardByType() {
    switch (automationState?.type) {
      case CampaignPipelineType.PERSONALIZATION_AUTOMATION:
        return (
          <ReviewCard title="Total opportunities:" number={composePersFound()} numberColor="#000" />
        );
      case CampaignPipelineType.CONTACT_AUTOMATION:
        return (
          <ReviewCard
            title="Total opportunities:"
            number={composeContactsFound()}
            numberColor="#000"
          />
        );
      default:
        return (
          <ReviewCard title="Total opportunities:" number={composeOppAdded()} numberColor="#000" />
        );
    }
  }

  function renderAdditionalReviewCardByType() {
    if (automationState != null && automationState?.contactAutomationEnabled) {
      return (
        <ReviewCard title="Contacts found:" number={composeContactsAdded()} numberColor="#000" />
      );
    }
    if (automationState != null && automationState?.personalizationEnabled) {
      return (
        <ReviewCard title="Personalized emails:" number={composePersAdded()} numberColor="#000" />
      );
    }

    return <div className="review-card" />;
  }

  return (
    <div className="review-step-wrapper">
      <div ref={refEl} onClick={refetchOpportunities} />
      <div className={`review-step ${cancelButtonVisible ? 'hidden' : ''}`}>
        <div className="review-step__cards">
          <ReviewCard
            icon={composeIcon()}
            iconSize="20"
            title={composeTitle()}
            titleColor="#393939"
          />
          <ReviewCard
            icon="clockThree"
            iconColor="#AEAFB4"
            title={composeProgressState()}
            titleColor="#393939"
            iconSircle
          />
          {renderTotalValueReviewCardByType()}
          {renderAdditionalReviewCardByType()}
          {/* <ReviewCard title="Credits spent:" number={composeCreditsSpent()} numberColor="#000" /> */}
        </div>
        <div className="review-step__process">
          <ProgressBar percentage={composeProgress()} />
        </div>
        <Display isVisible={pipelineStream != null && !cancelButtonVisible}>
          <span className="review-step-delete-button" onClick={(e) => setCancelButtonVisible(true)}>
            <SVGIcon icon="xIcon" size={20} />
          </span>
        </Display>
      </div>
      <Display isVisible={pipelineStream != null && cancelButtonVisible}>
        <div
          className={`review-step-list__item-confirm-delete ${
            cancelButtonVisible ? 'open' : 'hidden'
          }`}
        >
          <p>Are you sure?</p>
          <Button isLoading={isCancelling} onClick={() => onApproveCancelClick()}>
            Confirm
          </Button>
          <Button type="alert" onClick={() => setCancelButtonVisible(false)}>
            Cancel
          </Button>
        </div>
      </Display>
    </div>
  );
}

export default ReviewStep;
