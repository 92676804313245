/**
 * NOT CHECKED
 */
import React, { ReactNode, useRef } from 'react';

import './Accordion.scss';

const Accordion = ({ children, isOpen }: { children: ReactNode; isOpen: boolean }): JSX.Element => {
  const refEl = useRef(null);

  return (
    <div ref={refEl} className={`accordion-content ${isOpen ? 'active' : ''}`}>
      {children}
    </div>
  );
};

export default Accordion;
