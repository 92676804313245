/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';
import { Link, useHistory } from 'react-router-dom';

import { SVGIcon } from '@uikit/Icon/Icon';

import './LinkedSteps.scss';
import { redirectUserTo } from '@utils/historyHandler';

export type LinkedStepType = {
  url: string;
  title: string;
  taken?: boolean;
  checkIsActive?: () => boolean;
  disabled?: boolean;
};

type LinkedStepsPropsType = {
  stepsConfig: LinkedStepType[];
  authStep: number;
  isClickable: boolean;
};

function LinkedSteps({
  stepsConfig,
  authStep,
  isClickable = true,
}: LinkedStepsPropsType): JSX.Element {
  const history = useHistory();
  return (
    <div className="linked-steps">
      {stepsConfig.map((step, index) => {
        const stepIndex = index + 1;
        const chLinkedStep = cn('linked-steps__step', {
          'linked-steps__step--active': step.checkIsActive(),
          'linked-steps__step--disabled': !isClickable || step.disabled,
        });

        return (
          // TODO: Link
          <Link
            key={step.title}
            to={step.url}
            className={chLinkedStep}
            onClick={(event) => {
              redirectUserTo(history, step.url);
            }}
          >
            {stepIndex}.&nbsp;{step.title}
            {stepIndex < authStep ? <SVGIcon icon="checkmark" /> : null}
          </Link>
        );
      })}
    </div>
  );
}

export default LinkedSteps;
