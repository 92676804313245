import React, { useState } from 'react';

import SocialLinks from '@uikit/SocialLinks/SocialLinks';
import { getDateShort } from '@utils/date';

import Checkbox from '@uikit/Checkbox/Checkbox';
import ActionsMenu from '@components/ActionsMenu/ActionsMenu';
import { ColumnSettingsType } from '@ts/common.types';
import _ from 'lodash';
import Avatar from '@uikit/Avatar/Avatar';
import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import { WebsiteType } from '@ts/company.types';
import EmailAccuracy from '@uikit/EmailAccuracy/EmailAccuracy';
import { format } from 'date-fns';
import { ContentMetricsType } from 'respona_api/generated/common_pb';
import { IconsMapByType } from '@helpers/opportunitiesHelpers';
import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import ReactTooltip from 'react-tooltip';
import { isSemrushMode } from '@constants/app-modes';

type RenderPropsType = {
  row: WebsiteType;
  renderProps: {
    [key: string]: any;
  };
};

declare const IS_STANDALONE_MODE: boolean;
declare const INTEGRATION_MODE: string;

export default (tableSettings?: ColumnSettingsType[]) => [
  {
    title: '',
    dataKey: 'checkbox',
    RenderTitle: ({
      renderProps: { isAllSelected, onToggleSelectAll, selectedRows },
    }): JSX.Element => {
      const handleToggleSelectAll = ({ target: { checked } }) => onToggleSelectAll(checked);

      return (
        <div className="organizations-table__select-all-cb">
          <Checkbox onChange={handleToggleSelectAll} value={isAllSelected} />
        </div>
      );
    },
    RenderCell: ({ row, renderProps: { selectedRows, onToggleRowSelection } }): JSX.Element => {
      const handleSelectRow = (e) => {
        e.stopPropagation();
        onToggleRowSelection(row.id);
      };

      return <Checkbox onChange={handleSelectRow} value={Boolean(selectedRows[row.id])} />;
    },
    isSelectable: false,
    isFunctional: true,
  },

  ...(tableSettings
    ?.filter((item) => item.enabled)
    ?.map((item) => ({
      rawData: item,
      title: item.columnTitle,
      dataKey: item.columnMappingName,
      RenderCell: defaultColumnsRendererMap[item.columnMappingName],
    })) || []),

  {
    title: '',
    dataKey: 'actions',
    isSelectable: false,
    isFunctional: true,
    RenderCell: ({ row, renderProps }): JSX.Element => {
      const actionsConfig = [
        {
          icon: 'editAction',
          title: 'Edit',
          handler: () => renderProps.onEditCompany(row),
        },
        {
          icon: 'folder',
          title: 'Move',
          handler: () => renderProps.onMoveCompany(row),
        },
      ];

      return (
        <ActionsMenu
          onRemove={() => renderProps.onRemoveCompany([row.id])}
          id={`people-actions-${row.id}`}
          actionsConfig={actionsConfig}
          iconClassName="schedules-table__actions-icon"
          tooltipPlace="left"
          catchEvents={false}
        />
      );
    },
  },
];

const defaultColumnsRendererMap = {
  contact: ({ row }: RenderPropsType): JSX.Element => {
    const [isEmailsListShown, setEmailsListShown] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    if (row.contactsList.length === 0) {
      return <div className="organizations-table__cell" />;
    }

    const contact = row.contactsList[0];
    return (
      <div className="organizations-table__cell">
        <div className="organizations-table__contact-header">
          <div
            className="organizations-table__name"
            style={{
              marginBottom: contact?.socialLinksList?.length ? '8px' : 0,
            }}
          >
            {contact?.name?.length ? _.unescape(contact.name) : '-'}
          </div>
          <div className="organizations-table__socials">
            <SocialLinks
              hideUknownType
              socialLinks={contact?.socialLinksList?.length ? contact?.socialLinksList : []}
            />
          </div>
        </div>
        <div className="organizations-table__job-position organizations-table__cell--color-light-gray">
          {contact?.jobPosition?.length ? _.unescape(contact?.jobPosition) : '-'}
        </div>
        <div className="organizations-table__email organizations-table__cell--color-dark-gray">
          <span className="mr-5">
            {contact?.emailsList.length ? (
              isEmailsListShown ? (
                contact?.emailsList.map(({ email, accuracy }, index) => (
                  <div key={index} className="organizations-table__email-container">
                    {email}
                    <EmailAccuracy accuracy={accuracy} size="s" />
                  </div>
                ))
              ) : (
                <div className="organizations-table__email-container">
                  {_.unescape(contact?.emailsList[0].email)}
                  <EmailAccuracy accuracy={contact?.emailsList[0].accuracy} size="s" />
                </div>
              )
            ) : (
              '-'
            )}
          </span>
        </div>
        {!isEmailsListShown && contact?.emailsList.length > 1 && (
          <div
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            className="organizations-table__emails_more"
            data-for={`contact-emails-${contact.id}`}
            data-tip=""
          >
            +{contact.emailsList.length - 1} more
          </div>
        )}
        {tooltipVisible && (
          <ReactTooltip
            id={`contact-emails-${contact.id}`}
            className="react-tooltip"
            place="bottom"
            effect="solid"
            arrowColor="transparent"
            clickable
          >
            <div className="contacts-result-emails-tooltip">
              {contact.emailsList.map(({ email, accuracy }) => (
                <div className="contacts-result-emails-tooltip__email" key={email}>
                  <EmailAccuracy accuracy={accuracy} />
                  {email}
                </div>
              ))}
            </div>
          </ReactTooltip>
        )}
      </div>
    );
  },
  website: ({ row }: RenderPropsType): JSX.Element => (
    <div className="organizations-table__cell" style={{ whiteSpace: 'nowrap' }}>
      <div className="organizations-table__website-header">
        <Avatar
          url={row.logoUrl}
          fullName={row.title}
          className="organizations-table__website-square-logo"
        />
        <div className="organizations-table__website">
          <div
            className="organizations-table__website_title"
            style={{ marginBottom: row?.socialLinksList?.length ? '8px' : '4px' }}
          >
            {row?.title.length ? _.unescape(row.title) : '-'}
          </div>
          <SocialLinks
            hideUknownType={false}
            socialLinks={[{ type: 0, url: `https://${row.domain}` }, ...row.socialLinksList]}
          />
        </div>
      </div>
    </div>
  ),
  metrics: ({ row }: RenderPropsType): JSX.Element => (
    <div className="organizations-table__cell" style={{ whiteSpace: 'nowrap' }}>
      {row.metricsList
        .filter((metrics) => {
          if (!IS_STANDALONE_MODE && isSemrushMode(INTEGRATION_MODE)) {
            return metrics.type === ContentMetricsType.SEMRUSH_METRICS;
          }
          return true;
        })
        .filter((metrics) => {
          return metrics.rating > 0 || metrics.traffic > 0 || metrics.spamScore > 0;
        })
        .map((metrics, index) => {
          const icon = IconsMapByType[metrics.type] || null;

          const isMoz = metrics.type === ContentMetricsType.MOZ_METRICS;
          const isSemrush = metrics.type === ContentMetricsType.SEMRUSH_METRICS;

          return (
            <div key={index} className="metrics-block">
              {metrics.rating > 0 && (
                <div className="metrics-block_logo">
                  <img src={icon} alt="metrics-logo" />
                  <div className="metrics-block_logo-text">
                    <p>{isSemrush ? 'Authority score' : 'Domain rating'}</p>
                    <span>{metrics.rating}</span>
                  </div>
                </div>
              )}
              {(metrics.traffic > 0 || metrics.spamScore > 0) && (
                <div className="metrics-block_logo">
                  <img src={icon} alt="metrics-logo" />{' '}
                  {!isMoz ? (
                    <div className="metrics-block_logo-text">
                      <p>Domain Traffic</p>
                      <span>{fromNumberAhrefFormatHelper(Number(metrics.traffic))}</span>
                    </div>
                  ) : (
                    <div className="metrics-block_logo-text">
                      <p>Spam score</p>
                      <span>{fromNumberAhrefFormatHelper(Number(metrics.spamScore))}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  ),
  status: ({ row: { status, isBlocked } }): JSX.Element => (
    <span
      className="organizations-table__cell organizations-table__status organizations-table__cell--color-light-gray"
      style={{ whiteSpace: 'nowrap' }}
    >
      <RelationshipStatus status={status} isBlocked={isBlocked} />
    </span>
  ),
  created_on: ({ row, renderProps }: RenderPropsType): JSX.Element => (
    <div className="organizations-table__cell date" style={{ whiteSpace: 'nowrap' }}>
      {format(new Date(row.createdAt), 'MM/dd/yyyy')} at{' '}
      {format(new Date(row.createdAt), 'hh:mm aaa')}
      {IS_STANDALONE_MODE && (
        <div
          className="organizations-table__cell organizations-table__cell--color-light-gray"
          style={{ whiteSpace: 'nowrap' }}
        >
          by{' '}
          {renderProps.workspaceMembers?.find((item) => item.userId === row.ownerId)?.fullName ||
            '-'}
        </div>
      )}
    </div>
  ),
};
