/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './EmailAccuracy.scss';

const EmailAccuracy = ({
  accuracy = 0,
  order,
  size = 'm',
}: {
  accuracy?: 0 | 1 | 2 | 3;
  order?: number;
  size?: 's' | 'm' | 'l';
}): JSX.Element => {
  const cnAccuracy = cn('email-accuracy', {
    'email-accuracy--has-order': order !== undefined,
    'email-accuracy--undefined': accuracy === 0,
    'email-accuracy--low': accuracy === 1,
    'email-accuracy--medium': accuracy === 2,
    'email-accuracy--high': accuracy === 3,
    [`email-accuracy--size-${size}`]: size !== undefined,
  });

  return <div className={cnAccuracy}>{order !== undefined ? order : null}</div>;
};

export default EmailAccuracy;
