import AutomationPersonalizationService from 'respona_api/generated/automation-personalization_pb_service';

declare const API_HOST: string;

const service = (function () {
  let instance = null;

  return {
    getInstance: (): AutomationPersonalizationService.PersonalizationAutomationServiceClient => {
      if (!instance) {
        // @ts-ignore
        instance = new AutomationPersonalizationService.PersonalizationAutomationServiceClient(
          API_HOST
        );
      }

      return instance;
    },
  };
})();

export default service;
