import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from '@utils/debounce';
import { FilterJoins, FilterOperationsMapForString } from '@constants/filters/filterOperations';
import { getOpportunityFilterFields } from '@constants/filters/opportunitiesSearchFilterFields';

import { DispatchType } from 'src/store';
import { setTittleForFilterCampaign } from '@redux/actions/campaigns.actions';

import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import RelationshipsFiltersTooltipContentFooter from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentFooter/RelationshipsFiltersTooltipContentFooter';
import RelationshipsFiltersTooltipContentRaw from '@components/Relationships/RelationshipsFiltersTooltipContent/RelationshipsFiltersTooltipContentRaw/RelationshipsFiltersTooltipContentRaw';

import { getRefetchOpportunities } from '@redux/selectors/pipelines.selectors';
import {
  OpportunityFilterField,
  OpportunityFilterFieldMap,
} from 'respona_api/generated/campaign_pb';
import * as common_pb from 'respona_api/generated/common_pb';

import './ContentSearchActivityOpportunitiesSearch.scss';

const initialFilterState = {
  '1': {
    order: 0,
    field: OpportunityFilterField.OPPORTUNITY_TITLE,
    value: '',
    operation: FilterOperationsMapForString[2].value,
    join: FilterJoins[0].value,
  },
};

function ContentSearchActivityOpportunitiesSearch({
  isFilterBtnText,
  onApplyFilters,
  areFiltersEnabled,
}: {
  isFilterBtnText?: boolean;
  areFiltersEnabled: boolean;
  onApplyFilters: (
    filtersArray: {
      field: OpportunityFilterFieldMap[keyof OpportunityFilterFieldMap];
      join: common_pb.FilterJoinTypeMap[keyof common_pb.FilterJoinTypeMap];
      value: string;
      operation: common_pb.FilterOperationMap[keyof common_pb.FilterOperationMap];
      order: number;
    }[]
  ) => void;
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();
  const refetchOpportunities = useSelector(getRefetchOpportunities);
  const [filters, changeFilters] = useState(initialFilterState);
  const [searchString, changeSearchString] = useState('');

  const debounedSetReduxSearchString = useCallback(
    debounce((value) => dispatch(setTittleForFilterCampaign(value)), 500),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    changeSearchString(value);
    debounedSetReduxSearchString(value);
  };

  const handleClear = () => {
    changeSearchString('');
    dispatch(setTittleForFilterCampaign(''));
  };

  const handleAddFilterInput = () =>
    changeFilters((prevState) => {
      const keys = Object.keys(prevState);
      const newKey = Number(keys[keys.length - 1]) + 1;

      return { ...prevState, [newKey]: initialFilterState['1'] };
    });

  const handleApplyFilters = () => {
    const filtersArray = Object.values(filters);

    onApplyFilters(filtersArray);
  };

  const handleChangeFilterState = (filterRawKey, filterInputKey, value) => {
    changeFilters((prevState) => {
      return {
        ...prevState,
        [filterRawKey]: {
          ...prevState[filterRawKey],
          [filterInputKey]: value,
        },
      };
    });
  };

  const createHandleDeleteFilterRaw = (dummy: number) => () => {
    changeFilters((prevState) => {
      const newState = { ...prevState };
      delete newState[dummy];
      return newState;
    });
  };

  return (
    <>
      {/* <Input */}
      {/*  className="campaigns-header__search" */}
      {/*  isTransparent */}
      {/*  isClearable */}
      {/*  icon="search" */}
      {/*  iconColor={searchString ? PRIMARY_COLOR : GREY_COLOR} */}
      {/*  value={searchString} */}
      {/*  onChange={handleSearchChange} */}
      {/*  onClear={handleClear} */}
      {/*  onKeyDown={(e) => { */}
      {/*    if (e.keyCode === 13) { */}
      {/*      e.stopPropagation(); */}
      {/*    } */}
      {/*  }} */}
      {/*  placeholder="Search" */}
      {/* /> */}
      <HeaderFiltersButtonWithTooltip
        isFilterBtnText={isFilterBtnText}
        areFiltersEnabled={areFiltersEnabled}
        tooltipOffset={105}
        onClearAllFilters={() => {
          changeFilters(initialFilterState);
          onApplyFilters([]);
          // dirty solution: we need to wait when the parent's component's state is updated
          setTimeout(() => {
            refetchOpportunities();
          });
        }}
      >
        <div className="campaign-filters-tooltip-content">
          <div className="campaign-filters-tooltip-content__body">
            {Object.entries(filters).map((filter, index) => {
              const filterId = Number(filter[0]);
              const filterRawData = filter[1];

              return (
                <RelationshipsFiltersTooltipContentRaw
                  key={filterId}
                  rawId={filterId}
                  isAdditional={!!index}
                  onDeleteFilterInput={createHandleDeleteFilterRaw(filterId)}
                  filterRawData={filterRawData}
                  onChangeFilter={handleChangeFilterState}
                  filterFields={getOpportunityFilterFields()}
                />
              );
            })}
          </div>
          <RelationshipsFiltersTooltipContentFooter
            onAddFilterInput={handleAddFilterInput}
            onApplyFilters={handleApplyFilters}
          />
        </div>
      </HeaderFiltersButtonWithTooltip>
    </>
  );
}

export default ContentSearchActivityOpportunitiesSearch;
