import request from '@api/base/request';
import aiPromptsService from '@api/services/aiPromptsService';
import { AgentPromptType, AiConversationType } from '@ts/aiPrompts.types';
import { AgentPromptRequest, AiConversationRequest } from 'respona_api/generated/ai-prompts_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import streamRequest from '@api/base/streamRequest';
import { InvoiceShortType } from '@ts/billing.types';

export const executePromptApi = (
  systemPromptName: common_pb.AiPromptNameMap[keyof common_pb.AiPromptNameMap],
  userPrompt: string,
  instructionsJson: string,
  settingsJson: string,
  workspaceId: number,
  relatedId: number,
  threadId: string,
  type: common_pb.AiConversationRelatedTypeMap[keyof common_pb.AiConversationRelatedTypeMap]
): Promise<AgentPromptType> => {
  const client = aiPromptsService.getInstance();
  const requestModel = new AgentPromptRequest();

  requestModel.setWorkspaceId(workspaceId);
  requestModel.setRelatedId(relatedId);
  requestModel.setRelatedType(type);
  requestModel.setThreadId(threadId);

  requestModel.setName(systemPromptName);
  requestModel.setUserMessage(userPrompt);
  requestModel.setInstructionsJson(instructionsJson);
  requestModel.setSettingsJson(settingsJson);

  return request<AgentPromptType>(client, client.execute, requestModel);
};

export const getAllConversationMessagesApi = (
  workspaceId: number,
  relatedId: number,
  type: common_pb.AiConversationRelatedTypeMap[keyof common_pb.AiConversationRelatedTypeMap]
): Promise<AiConversationType[]> => {
  const client = aiPromptsService.getInstance();
  const requestModel = new AiConversationRequest();
  requestModel.setWorkspaceId(workspaceId);
  requestModel.setRelatedId(relatedId);
  requestModel.setRelatedType(type);

  return streamRequest<AiConversationType[]>(
    client,
    client.getAllConversationMessages,
    requestModel
  );
};
