import React, { useMemo } from 'react';
import { ActivePagePropsType } from '@ts/internalSidebar.types';
import ConfigurableSidebar from '@uikit/ConfigurableSidebar/ConfigurableSidebar';
import sidebarConfig from '@components/AiAgents/AiAgentsSidebar/sidebarConfig';
import { useLocation } from 'react-router-dom';

import './AiAgentsSidebar.css';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

function AiAgentsSidebar({ activePage, searchParams, campaignId }: ActivePagePropsType): JSX.Element {
  const { pathname } = useLocation();
  const workspaceId = useCurrentWorkspaceId();

  const renderProps = {
    activePage,
    searchParams,
    pathname,
    workspaceId,
    campaignId,
  };

  return (
    <div className="ai-agents-page__sidebar">
      <ConfigurableSidebar
        config={sidebarConfig}
        renderProps={renderProps}
      />
    </div>
  )
}

export default AiAgentsSidebar;