/**
 * NOT CHECKED
 */
import React, { useRef, useEffect, MutableRefObject } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

const ControlledReactTooltip = ({
  children,
  ...rest
}: TooltipProps & {
  children?: React.ReactNode;
  tooltipRefToClose?: MutableRefObject<any>;
}): JSX.Element => {
  const tooltipRef = useRef(null);

  const handleCloseTooltip = () => {
    if (tooltipRef.current.tooltipRef) {
      tooltipRef.current.tooltipRef = null;
      ReactTooltip.hide();
    }
  };

  if (rest.tooltipRefToClose) {
    // eslint-disable-next-line no-param-reassign
    rest.tooltipRefToClose.current = handleCloseTooltip;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tooltipRef &&
        tooltipRef.current &&
        !tooltipRef.current.tooltipRef?.contains(event.target)
      ) {
        handleCloseTooltip();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef.current]);

  return (
    <ReactTooltip {...rest} ref={tooltipRef}>
      {children}
    </ReactTooltip>
  );
};

export default ControlledReactTooltip;
