import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import './ToolboxSidebar.scss';
import { linkGoTo, redirectUserTo } from '@utils/historyHandler';

const links = [
  {
    value: '/toolbox/email-finder',
    icon: 'emailFind',
    label: 'Email finder',
  },
  {
    value: '/toolbox/email-verifier',
    icon: 'emailVerify',
    label: 'Email verifier',
  },
];

function ToolboxSidebar() {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <div className="toolbox-sidebar">
      <div className="toolbox-sidebar__header">Toolbox</div>

      <div className="toolbox-sidebar__content">
        {links.map(({ value, icon, label }) => {
          const isActive = pathname === value;

          return (
            <Link
              key={value}
              to={linkGoTo(value)}
              className={cn('toolbox-sidebar__link', {
                'toolbox-sidebar__link--active': isActive,
              })}
              onClick={(event) => {
                event.preventDefault();
                redirectUserTo(history, value);
              }}
            >
              <span className="toolbox-sidebar__link-icon">
                <SVGIcon icon={icon as SvgIconType} color="currentColor" />
              </span>
              {label}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default ToolboxSidebar;
