import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReferralLinkData } from 'respona_api/generated/user-organization_pb';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { createEditor, Descendant, Transforms } from 'slate';

import { addNotification } from '@redux/actions/notifications.actions';

import { uploadFile } from '@api/fileImport.api';

import withBulletPoints from '@hocs/withBulletPoints';
import withEditorLinks from '@hocs/withEditorLinks';

import EditorChangeLink from '@uikit/RichEditor/_components/EditorChangeLink/EditorChangeLink';
import { patchSlateElementByPath } from '@uikit/RichEditor/utils/stateUtils';
import { Button } from '@uikit/Button/Button';
import { SVGIcon } from '@uikit/Icon/Icon';
import MimeMessage from '@uikit/MimeMessage/MimeMessage';
import { Element, Leaf } from '@uikit/RichEditor/SlateComponents';
import { serialize } from '@uikit/RichEditor/utils/serialize';
import Input from '@uikit/Input/Input';

import Display from '@components/Display/Display';
import EditorFooter from '@components/Inbox/InboxWidget/_components/InboxWidgetEditor/_components/EditorFooter/EditorFooter';
import InboxEditorAttachments from '@components/Inbox/InboxWidget/_components/InboxEditorAttachments/InboxEditorAttachments';

import './InboxWidgetEditor.scss';
import { DispatchType } from 'src/store';
import stripHtml from '@utils/stripHtml';
import debounce from '@utils/debounce';
import { executeAIAssistantActionApi, getLatestEmailByThreadId } from '@api/mailbox.api';
import { MailboxThreadDraftType } from '@ts/mailboxInbox.types';
import { prepareMessageValueForEditor } from '@uikit/RichEditor/utils/prepareMessageValueForEditor';
import { MailAIAction, MailAIActionMap, ThreadMessage } from 'respona_api/generated/mailbox_pb';
import { getTemplateStepsApi } from '@api/templates.api';
import { useQuery } from 'react-query';
import {
  transformLinkUrlDefault,
  transformVariableDefault,
} from '@uikit/RichEditor/utils/variableUtils';
import {
  MessageRequestType as MESSAGE_TYPE,
  MessageRequestTypeMap,
} from 'respona_api/generated/mailbox-sender_pb';
import { getVariableByValue } from '@constants/variables';
import useAllVariablesInEditor from '@hooks/useAllVariablesInEditor';
import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';
import { PersonBaseType } from '@ts/people.types';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';

export const initialValue: Descendant[] = [
  // @ts-ignore
  { type: 'paragraph', children: [{ text: '', type: 'text' }] },
];

const AUTO_SAVING_DELAY_MS: number = 300;

type EditorNode = {
  type: string;
  text?: string;
  character?: string;
  href?: string;
  fallback?: string;
  children?: EditorNode[];
};

type InboxWidgetEditorProps = {
  selectedSignature: string;
  contactEmail: string;
  onSend: (subject: string, content: string, attachments: any) => Promise<void>;
  workspaceId: number;
  onDelete: () => void;
  originalMessage?: { [key: string]: any };
  subject?: string;
  setSubject?: (subject: string) => void;
  referralContent?: ReferralLinkData.AsObject;
  threadUid?: string;
  messageId?: string;
  onDraftCreation: (
    threadUid: string,
    messageId: string,
    emailTo: string,
    type: MessageRequestTypeMap[keyof MessageRequestTypeMap],
    draftSubject: string,
    serializedContent: string,
    draftAttachments: []
  ) => void;
  draft?: MailboxThreadDraftType;
  activePerson?: PersonBaseType;
};

const InboxWidgetEditor: React.FC<InboxWidgetEditorProps> = ({
  subject,
  setSubject,
  contactEmail,
  selectedSignature,
  onSend,
  workspaceId,
  onDelete,
  originalMessage,
  referralContent,
  threadUid,
  messageId,
  onDraftCreation,
  draft,
  activePerson,
}) => {
  const dispatch = useDispatch<DispatchType>();

  const editorContainerRef = useRef<HTMLDivElement | null>();

  const [value, setValue] = useState<Descendant[]>(initialValue);
  const [isDisabledLinkButton, setDisabledLinkButton] = useState(true);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const [attachments, changeAttachments] = useState([]);
  const [changingLink, setChangingLink] = useState(null);
  const [isOriginalMessageCollapsed, setOriginalMessageCollapsed] = useState(true);
  const [isAttachmentLoading, changeIsAttachmentLoading] = useState(false);
  const [isLoadingAI, setLoadingAI] = useState(false);

  const isReply = draft?.type === MESSAGE_TYPE.SEND_REPLY_TO || false;

  useEffect(() => {
    if (draft != null && draft.threadUid != null) {
      const cont = prepareMessageValueForEditor(draft.content);
      setValue(cont);
      changeAttachments(draft.attachmentsList);
    }
  }, []);

  const onSaveDraft = (
    draftSubject: string,
    draftContent: Descendant[],
    emailTo: string,
    draftAttachments
  ) => {
    if (messageId != null && threadUid?.length > 0) {
      const serializedContent = handleSerializedValue(draftContent);
      onDraftCreation(
        threadUid,
        messageId,
        emailTo,
        draft.type,
        draftSubject,
        serializedContent,
        draftAttachments
      );
    }
  };

  const handleSaveDraftDebounced = useRef(
    debounce(
      (newSubject: string, newContent: Descendant[], emailTo: string, newAttachments: []) => {
        onSaveDraft(newSubject, newContent, emailTo, newAttachments);
      },
      AUTO_SAVING_DELAY_MS
    )
  ).current;

  const handleChangeSubject = (
    newSubjectValue: string,
    existingContent: Descendant[],
    existingEmailTo: string,
    existingAttachments: any[]
  ) => {
    if (newSubjectValue !== subject) {
      setSubject(newSubjectValue);

      handleSaveDraftDebounced(
        newSubjectValue,
        existingContent,
        existingEmailTo,
        existingAttachments
      );
    }
  };

  const handleChangeContent = useRef(
    debounce(
      (
        newContent: Descendant[],
        existingSubject: string,
        existingEmailTo: string,
        existingAttachments: []
      ) => {
        handleSaveDraftDebounced(existingSubject, newContent, existingEmailTo, existingAttachments);
      },
      AUTO_SAVING_DELAY_MS
    )
  ).current;

  const editor = useMemo(
    () => withReact(withHistory(withEditorLinks(withBulletPoints(createEditor() as ReactEditor)))),
    []
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        transformLinkUrl={transformLinkUrlDefault}
        transformVariable={transformVariableDefault}
        onChangeLink={setChangingLink}
        disableVariable
        editor={editor}
      />
    ),
    [editor]
  );

  const handleSendClick = () => onSend(subject ?? '', handleSerializedValue(value), attachments);
  const { variables: allVariables } = useAllVariablesInEditor();

  const handleTemplateSelected = (templateId: number) => {
    getTemplateStepsApi(templateId, workspaceId).then((response) => {
      try {
        const firstStep = response.stepsList.find((step) => step.order === 1);
        const initialContent = firstStep.threadA.content;
        const preparedContent = prepareMessageValueForEditor(initialContent);
        const replacedContent = processAndReplaceVariables(preparedContent);
        // @ts-ignore
        setValue(replacedContent);
        handleSaveDraftDebounced(subject, replacedContent, contactEmail, attachments);
        dispatch(addNotification({ title: 'Template applied', type: 'success' }));
      } catch (e) {
        console.error('Error applying template', e);
      }
    });
  };

  function processAndReplaceVariables(data: EditorNode[]): EditorNode[] {
    return data.map((node) => processNode(node));
  }

  function processNode(node: EditorNode): EditorNode {
    // If the node is of type 'variable', replace it with the specified structure
    if (node.type === 'variable') {
      const variable = getVariableByValue(node.character, allVariables);
      if (
        variable.relatedTo === CustomVariableValueRelatedTo.PERSON ||
        variable.relatedTo === CustomVariableValueRelatedTo.WEBSITE
      ) {
        const val = variable.transform('', activePerson || {}, null, null, null, []);
        return { text: val, type: 'text' };
      }
      return { text: '', type: 'text' };
    }

    // If the node has children, recursively process each child
    if (node.children) {
      return {
        ...node,
        children: node.children.map((child) => processNode(child)),
      };
    }

    // Return the node as is if it's neither a 'variable' nor has children
    return node;
  }

  const { data: latestEmailByThread } = useQuery<ThreadMessage.AsObject>(
    ['latest-email-by-thread', threadUid],
    () => getLatestEmailByThreadId(threadUid, currentWorkspaceId),
    {
      enabled: !!threadUid,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  const handleApplyAiAction = (
    action: MailAIActionMap[keyof MailAIActionMap],
    language: string
  ) => {
    let lastEmailContent: string = null;
    if (action === MailAIAction.WRITE_DRAFT) {
      if (latestEmailByThread == null || latestEmailByThread?.messageId.length === 0) {
        dispatch(
          addNotification({
            title:
              'Unable to create a draft without a previous email. Please send or receive an email first.',
            type: 'warning',
          })
        );
        return;
      }
      lastEmailContent = latestEmailByThread.messageId;
    }

    setLoadingAI(true);

    function textToHtml(inputText: string): string {
      const paragraphs = inputText.split('\n\n');

      const formattedParagraphs: string[] = [];

      paragraphs.forEach((paragraph) => {
        formattedParagraphs.push(`<p><span>${paragraph}</span></p><p><span></span></p>`);
      });

      return formattedParagraphs.join('');
    }

    executeAIAssistantActionApi(
      action,
      language,
      handleSerializedValue(value),
      currentWorkspaceId,
      lastEmailContent
    ).then((response) => {
      if (response.success) {
        while (response.result.includes('[Your Name]')) {
          response.result = response.result.replace('[Your Name]', latestEmailByThread.toEmail);
        }

        const transformedResult = prepareMessageValueForEditor(textToHtml(response.result));
        setValue(transformedResult);
        dispatch(addNotification({ title: 'Action applied', type: 'success' }));
      } else {
        dispatch(addNotification({ title: 'Oups! Something went wrong', type: 'error' }));
      }
      setLoadingAI(false);
    });
  };

  const handleChangeHref = useCallback(
    (newHref, path) => {
      Transforms.deselect(editor);
      setValue((prevValue) => patchSlateElementByPath(prevValue, path, { url: newHref }));
    },
    [value]
  );

  const handleUploadAttachment = (files) => {
    const file = files[0];

    const readableFileSizeMB = Math.floor(file.size / 1000000);

    if (readableFileSizeMB > 25) {
      dispatch(addNotification({ title: 'Maximum file size is 25MB', type: 'warning' }));
      return;
    }

    changeIsAttachmentLoading(true);

    file?.arrayBuffer()?.then((buffer) => {
      uploadFile(undefined, workspaceId, file, buffer)
        .then((processResponse) => {
          const uploadedFileInfo = processResponse[processResponse.length - 1];
          const newAttachments = [
            ...attachments,
            {
              key: uploadedFileInfo.uid,
              title: uploadedFileInfo.title,
              size: file.size,
            },
          ];
          changeAttachments(newAttachments);
          onSaveDraft(subject, value, contactEmail, newAttachments);
        })
        .finally(() => changeIsAttachmentLoading(false));
    });
  };

  const handleRemoveAttachment = (fileKey: string) =>
    changeAttachments((prevState) => prevState.filter(({ key }) => key !== fileKey));

  editor.children = value;

  const handleSerializedValue = (rawData: Descendant[]): string => {
    try {
      const filteredData = rawData.filter((it) => it != null);
      if (!filteredData.length) {
        return serialize(initialValue);
      }
      return serialize(filteredData);
    } catch (e) {
      return serialize(initialValue);
    }
  };

  return (
    <div ref={editorContainerRef}>
      <Display isVisible={!isReply || !!subject.length}>
        <div className="inbox-widget__editor-subject">
          <Input
            value={subject}
            onChange={({ target: { value: inputValue } }) =>
              handleChangeSubject(inputValue, value, contactEmail, attachments)
            }
            placeholder="Subject"
            isFullWidth
            disabled={isReply}
          />
        </div>
      </Display>
      <Slate
        editor={editor}
        value={value}
        onChange={(newContent: Descendant[]) => {
          setValue(newContent);
          handleChangeContent(newContent, subject, contactEmail, attachments);
        }}
      >
        <div className="inbox-widget__editor">
          <Editable
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            placeholder="Message"
            onSelect={() => {
              setDisabledLinkButton(
                editor.selection?.anchor?.offset === editor.selection?.focus?.offset
              );
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault();

                const newLine = {
                  type: 'paragraph',
                  children: [
                    {
                      text: '',
                      marks: [],
                    },
                  ],
                };

                Transforms.insertNodes(editor, newLine);
              }
            }}
          />

          <Display isVisible={!!referralContent?.content}>
            <div
              className="slate-editor__referral-content"
              dangerouslySetInnerHTML={{
                __html: referralContent?.content?.replace('%s', referralContent?.link),
              }}
            />
          </Display>

          <div className="inbox-widget__signature">
            <Display isVisible={!!selectedSignature}>
              <div
                className="inbox-widget__signature-content"
                dangerouslySetInnerHTML={{ __html: selectedSignature }}
              />
            </Display>
          </div>

          <Display isVisible={!!originalMessage}>
            <Button
              type="ghost"
              onClick={() => setOriginalMessageCollapsed((visible) => !visible)}
              className="inbox-widget__show-original-btn"
            >
              <SVGIcon icon="actionHorizontal" />
            </Button>
          </Display>

          <Display isVisible={changingLink !== null}>
            <EditorChangeLink
              onChangeHref={handleChangeHref}
              linkInfo={changingLink}
              containerRef={editorContainerRef}
              editor={editor}
              onClose={() => setChangingLink(null)}
              transformLinkUrl={(url: string): string => url}
              disableVarsInLink
              useApplyButton
            />
          </Display>

          <Display isVisible={!isOriginalMessageCollapsed}>
            <div className="inbox-widget__original-message">
              <MimeMessage mimeMessage={originalMessage} />
            </div>
          </Display>
        </div>

        <div className="inbox-widget__attachments">
          <InboxEditorAttachments
            attachments={attachments}
            isLoading={isAttachmentLoading}
            onRemoveFile={handleRemoveAttachment}
          />
        </div>

        <EditorFooter
          onDelete={onDelete}
          onChangeLink={setChangingLink}
          onFileUpload={handleUploadAttachment}
          onSend={handleSendClick}
          editor={editor}
          isDisabledLinkButton={isDisabledLinkButton}
          isDisabledAIBtn={!stripHtml(handleSerializedValue(value)).trim()}
          isLoadingAI={isLoadingAI}
          onApplyAiAction={handleApplyAiAction}
          onTemplateSelected={handleTemplateSelected}
        />
      </Slate>
    </div>
  );
};

export default InboxWidgetEditor;
