// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.common.AhrefIntegrationSettings', null, global);
goog.exportSymbol('proto.common.AhrefMetrics', null, global);
goog.exportSymbol('proto.common.AiConversationMessageRole', null, global);
goog.exportSymbol('proto.common.AiConversationRelatedType', null, global);
goog.exportSymbol('proto.common.AiPromptName', null, global);
goog.exportSymbol('proto.common.AiPromptReplyTone', null, global);
goog.exportSymbol('proto.common.AiPromptRequest', null, global);
goog.exportSymbol('proto.common.AuthStep', null, global);
goog.exportSymbol('proto.common.AutomationInternalProgressResponse', null, global);
goog.exportSymbol('proto.common.AutomationInternalRequest', null, global);
goog.exportSymbol('proto.common.AutomationInternalResult', null, global);
goog.exportSymbol('proto.common.BacklinkStatus', null, global);
goog.exportSymbol('proto.common.BacklinksRel', null, global);
goog.exportSymbol('proto.common.BacklinksSearchSource', null, global);
goog.exportSymbol('proto.common.BillingInterval', null, global);
goog.exportSymbol('proto.common.CampaignAgentStatus', null, global);
goog.exportSymbol('proto.common.CampaignAutomationAction', null, global);
goog.exportSymbol('proto.common.CampaignAutomationAgentInstructionType', null, global);
goog.exportSymbol('proto.common.CampaignAutomationAutonomyLevel', null, global);
goog.exportSymbol('proto.common.CampaignAutomationStatus', null, global);
goog.exportSymbol('proto.common.CampaignAutomationStrategy', null, global);
goog.exportSymbol('proto.common.CampaignFilterType', null, global);
goog.exportSymbol('proto.common.CodeType', null, global);
goog.exportSymbol('proto.common.ContactSearchSource', null, global);
goog.exportSymbol('proto.common.ContactStatus', null, global);
goog.exportSymbol('proto.common.ContactVariable', null, global);
goog.exportSymbol('proto.common.ContentMetricsType', null, global);
goog.exportSymbol('proto.common.ContentSearchOrderBy', null, global);
goog.exportSymbol('proto.common.ContentSearchType', null, global);
goog.exportSymbol('proto.common.CustomVariableType', null, global);
goog.exportSymbol('proto.common.CustomVariableValue', null, global);
goog.exportSymbol('proto.common.CustomVariableValue.ValueCase', null, global);
goog.exportSymbol('proto.common.CustomVariableValueRelatedTo', null, global);
goog.exportSymbol('proto.common.CustomVariableValueSelect', null, global);
goog.exportSymbol('proto.common.CustomVariableValueSelect.SelectItemValue', null, global);
goog.exportSymbol('proto.common.DateOfLastEpisode', null, global);
goog.exportSymbol('proto.common.Email', null, global);
goog.exportSymbol('proto.common.EmailAccuracyStatus', null, global);
goog.exportSymbol('proto.common.EmailAccuracyType', null, global);
goog.exportSymbol('proto.common.EntityRelationRequest', null, global);
goog.exportSymbol('proto.common.EntityRelationRequest.SelectionType', null, global);
goog.exportSymbol('proto.common.FileAttachment', null, global);
goog.exportSymbol('proto.common.FileResponse', null, global);
goog.exportSymbol('proto.common.FileResponseContainer', null, global);
goog.exportSymbol('proto.common.FilterJoinType', null, global);
goog.exportSymbol('proto.common.FilterOperation', null, global);
goog.exportSymbol('proto.common.Folder', null, global);
goog.exportSymbol('proto.common.FoundAtCategory', null, global);
goog.exportSymbol('proto.common.GenericShortResponse', null, global);
goog.exportSymbol('proto.common.GlobalStatisticsRequest', null, global);
goog.exportSymbol('proto.common.HunterIntegrationSettings', null, global);
goog.exportSymbol('proto.common.IdContainer', null, global);
goog.exportSymbol('proto.common.IdRequest', null, global);
goog.exportSymbol('proto.common.IdResponse', null, global);
goog.exportSymbol('proto.common.InstructionPair', null, global);
goog.exportSymbol('proto.common.IntegrationSource', null, global);
goog.exportSymbol('proto.common.IntegrationStatus', null, global);
goog.exportSymbol('proto.common.IntegrationType', null, global);
goog.exportSymbol('proto.common.ListennotesMetrics', null, global);
goog.exportSymbol('proto.common.MozIntegrationSettings', null, global);
goog.exportSymbol('proto.common.MozMetrics', null, global);
goog.exportSymbol('proto.common.Note', null, global);
goog.exportSymbol('proto.common.NumericPair', null, global);
goog.exportSymbol('proto.common.OnNotInterestedReplyAction', null, global);
goog.exportSymbol('proto.common.OpenAIModelType', null, global);
goog.exportSymbol('proto.common.OpenAITone', null, global);
goog.exportSymbol('proto.common.OpportunityType', null, global);
goog.exportSymbol('proto.common.PageRequest', null, global);
goog.exportSymbol('proto.common.Pair', null, global);
goog.exportSymbol('proto.common.PairContainer', null, global);
goog.exportSymbol('proto.common.PairType', null, global);
goog.exportSymbol('proto.common.PeopleOpportunity', null, global);
goog.exportSymbol('proto.common.PeopleRelationshipStatus', null, global);
goog.exportSymbol('proto.common.PersonalizationThread', null, global);
goog.exportSymbol('proto.common.PodcastQuerySearchIn', null, global);
goog.exportSymbol('proto.common.Range', null, global);
goog.exportSymbol('proto.common.RealmType', null, global);
goog.exportSymbol('proto.common.RegistrationCode', null, global);
goog.exportSymbol('proto.common.RelationshipPipelineStageOpportunityRelatedTo', null, global);
goog.exportSymbol('proto.common.RocketReachIntegrationSettings', null, global);
goog.exportSymbol('proto.common.SemrushIntegrationSettings', null, global);
goog.exportSymbol('proto.common.SemrushMetrics', null, global);
goog.exportSymbol('proto.common.SnovioIntegrationSettings', null, global);
goog.exportSymbol('proto.common.SocialLink', null, global);
goog.exportSymbol('proto.common.SocialNetwork', null, global);
goog.exportSymbol('proto.common.SortDirection', null, global);
goog.exportSymbol('proto.common.StarterPackCodes', null, global);
goog.exportSymbol('proto.common.StringListContainer', null, global);
goog.exportSymbol('proto.common.StringRequest', null, global);
goog.exportSymbol('proto.common.Tag', null, global);
goog.exportSymbol('proto.common.Tags', null, global);
goog.exportSymbol('proto.common.TimestampRange', null, global);
goog.exportSymbol('proto.common.UserRequest', null, global);
goog.exportSymbol('proto.common.Variable', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Variable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Variable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Variable.displayName = 'proto.common.Variable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.TimestampRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.TimestampRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.TimestampRange.displayName = 'proto.common.TimestampRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.UserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.UserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.UserRequest.displayName = 'proto.common.UserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.PageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.PageRequest.displayName = 'proto.common.PageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GenericShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GenericShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GenericShortResponse.displayName = 'proto.common.GenericShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Pair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Pair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Pair.displayName = 'proto.common.Pair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.NumericPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.NumericPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.NumericPair.displayName = 'proto.common.NumericPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Email.displayName = 'proto.common.Email';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PersonalizationThread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.PersonalizationThread.repeatedFields_, null);
};
goog.inherits(proto.common.PersonalizationThread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.PersonalizationThread.displayName = 'proto.common.PersonalizationThread';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SocialLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SocialLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SocialLink.displayName = 'proto.common.SocialLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CustomVariableValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.common.CustomVariableValue.oneofGroups_);
};
goog.inherits(proto.common.CustomVariableValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CustomVariableValue.displayName = 'proto.common.CustomVariableValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CustomVariableValueSelect = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.CustomVariableValueSelect.repeatedFields_, null);
};
goog.inherits(proto.common.CustomVariableValueSelect, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CustomVariableValueSelect.displayName = 'proto.common.CustomVariableValueSelect';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.CustomVariableValueSelect.SelectItemValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.CustomVariableValueSelect.SelectItemValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.CustomVariableValueSelect.SelectItemValue.displayName = 'proto.common.CustomVariableValueSelect.SelectItemValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.IdContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.IdContainer.repeatedFields_, null);
};
goog.inherits(proto.common.IdContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.IdContainer.displayName = 'proto.common.IdContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PairContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.PairContainer.repeatedFields_, null);
};
goog.inherits(proto.common.PairContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.PairContainer.displayName = 'proto.common.PairContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StringListContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.StringListContainer.repeatedFields_, null);
};
goog.inherits(proto.common.StringListContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StringListContainer.displayName = 'proto.common.StringListContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.StringRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.StringRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.StringRequest.displayName = 'proto.common.StringRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.IdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.IdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.IdResponse.displayName = 'proto.common.IdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.IdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.IdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.IdRequest.displayName = 'proto.common.IdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Folder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Folder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Folder.displayName = 'proto.common.Folder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Note.displayName = 'proto.common.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Tag.displayName = 'proto.common.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Tags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Tags.repeatedFields_, null);
};
goog.inherits(proto.common.Tags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Tags.displayName = 'proto.common.Tags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.PeopleOpportunity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.PeopleOpportunity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.PeopleOpportunity.displayName = 'proto.common.PeopleOpportunity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.GlobalStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.GlobalStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.GlobalStatisticsRequest.displayName = 'proto.common.GlobalStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FileAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.FileAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FileAttachment.displayName = 'proto.common.FileAttachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.EntityRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.EntityRelationRequest.repeatedFields_, null);
};
goog.inherits(proto.common.EntityRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.EntityRelationRequest.displayName = 'proto.common.EntityRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.FileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FileResponse.displayName = 'proto.common.FileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.FileResponseContainer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.FileResponseContainer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.FileResponseContainer.displayName = 'proto.common.FileResponseContainer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Range = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Range, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Range.displayName = 'proto.common.Range';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AhrefIntegrationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AhrefIntegrationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AhrefIntegrationSettings.displayName = 'proto.common.AhrefIntegrationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SnovioIntegrationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SnovioIntegrationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SnovioIntegrationSettings.displayName = 'proto.common.SnovioIntegrationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.HunterIntegrationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.HunterIntegrationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.HunterIntegrationSettings.displayName = 'proto.common.HunterIntegrationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.RocketReachIntegrationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.RocketReachIntegrationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.RocketReachIntegrationSettings.displayName = 'proto.common.RocketReachIntegrationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SemrushIntegrationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SemrushIntegrationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SemrushIntegrationSettings.displayName = 'proto.common.SemrushIntegrationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MozIntegrationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MozIntegrationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MozIntegrationSettings.displayName = 'proto.common.MozIntegrationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AutomationInternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AutomationInternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AutomationInternalRequest.displayName = 'proto.common.AutomationInternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AutomationInternalProgressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AutomationInternalProgressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AutomationInternalProgressResponse.displayName = 'proto.common.AutomationInternalProgressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AutomationInternalResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AutomationInternalResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AutomationInternalResult.displayName = 'proto.common.AutomationInternalResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.ListennotesMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.ListennotesMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.ListennotesMetrics.displayName = 'proto.common.ListennotesMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.MozMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.MozMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.MozMetrics.displayName = 'proto.common.MozMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AhrefMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AhrefMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AhrefMetrics.displayName = 'proto.common.AhrefMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.SemrushMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.SemrushMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.SemrushMetrics.displayName = 'proto.common.SemrushMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.RegistrationCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.RegistrationCode.repeatedFields_, null);
};
goog.inherits(proto.common.RegistrationCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.RegistrationCode.displayName = 'proto.common.RegistrationCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.AiPromptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.AiPromptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.AiPromptRequest.displayName = 'proto.common.AiPromptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.InstructionPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.InstructionPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.InstructionPair.displayName = 'proto.common.InstructionPair';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Variable.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Variable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Variable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Variable.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Variable}
 */
proto.common.Variable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Variable;
  return proto.common.Variable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Variable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Variable}
 */
proto.common.Variable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Variable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Variable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Variable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Variable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Variable.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Variable} returns this
 */
proto.common.Variable.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.Variable.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Variable} returns this
 */
proto.common.Variable.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.TimestampRange.prototype.toObject = function(opt_includeInstance) {
  return proto.common.TimestampRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.TimestampRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TimestampRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.TimestampRange}
 */
proto.common.TimestampRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.TimestampRange;
  return proto.common.TimestampRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.TimestampRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.TimestampRange}
 */
proto.common.TimestampRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.TimestampRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.TimestampRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.TimestampRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.TimestampRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 from = 1;
 * @return {number}
 */
proto.common.TimestampRange.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.TimestampRange} returns this
 */
proto.common.TimestampRange.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 to = 2;
 * @return {number}
 */
proto.common.TimestampRange.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.TimestampRange} returns this
 */
proto.common.TimestampRange.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.UserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.UserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.UserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.UserRequest}
 */
proto.common.UserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.UserRequest;
  return proto.common.UserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.UserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.UserRequest}
 */
proto.common.UserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.UserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.UserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.UserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.UserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.common.UserRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.UserRequest} returns this
 */
proto.common.UserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalWorkspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionalCampaignId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    optionalEntityId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PageRequest}
 */
proto.common.PageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PageRequest;
  return proto.common.PageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PageRequest}
 */
proto.common.PageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalCampaignId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOptionalWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOptionalCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOptionalEntityId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.common.PageRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PageRequest} returns this
 */
proto.common.PageRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.common.PageRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PageRequest} returns this
 */
proto.common.PageRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_workspace_id = 3;
 * @return {number}
 */
proto.common.PageRequest.prototype.getOptionalWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PageRequest} returns this
 */
proto.common.PageRequest.prototype.setOptionalWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 optional_folder_id = 4;
 * @return {number}
 */
proto.common.PageRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PageRequest} returns this
 */
proto.common.PageRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 optional_campaign_id = 5;
 * @return {number}
 */
proto.common.PageRequest.prototype.getOptionalCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PageRequest} returns this
 */
proto.common.PageRequest.prototype.setOptionalCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 optional_entity_id = 6;
 * @return {number}
 */
proto.common.PageRequest.prototype.getOptionalEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PageRequest} returns this
 */
proto.common.PageRequest.prototype.setOptionalEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GenericShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GenericShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GenericShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GenericShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GenericShortResponse}
 */
proto.common.GenericShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GenericShortResponse;
  return proto.common.GenericShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GenericShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GenericShortResponse}
 */
proto.common.GenericShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GenericShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GenericShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GenericShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GenericShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.GenericShortResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.GenericShortResponse} returns this
 */
proto.common.GenericShortResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.common.GenericShortResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.GenericShortResponse} returns this
 */
proto.common.GenericShortResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo_url = 3;
 * @return {string}
 */
proto.common.GenericShortResponse.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.GenericShortResponse} returns this
 */
proto.common.GenericShortResponse.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Pair.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Pair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Pair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Pair.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Pair}
 */
proto.common.Pair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Pair;
  return proto.common.Pair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Pair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Pair}
 */
proto.common.Pair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.common.PairType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Pair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Pair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Pair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Pair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Pair.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Pair} returns this
 */
proto.common.Pair.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.Pair.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Pair} returns this
 */
proto.common.Pair.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PairType type = 3;
 * @return {!proto.common.PairType}
 */
proto.common.Pair.prototype.getType = function() {
  return /** @type {!proto.common.PairType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.PairType} value
 * @return {!proto.common.Pair} returns this
 */
proto.common.Pair.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.NumericPair.prototype.toObject = function(opt_includeInstance) {
  return proto.common.NumericPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.NumericPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.NumericPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.NumericPair}
 */
proto.common.NumericPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.NumericPair;
  return proto.common.NumericPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.NumericPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.NumericPair}
 */
proto.common.NumericPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.NumericPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.NumericPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.NumericPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.NumericPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 key = 1;
 * @return {number}
 */
proto.common.NumericPair.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.NumericPair} returns this
 */
proto.common.NumericPair.prototype.setKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.common.NumericPair.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.NumericPair} returns this
 */
proto.common.NumericPair.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accuracy: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    verified: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Email}
 */
proto.common.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Email;
  return proto.common.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Email}
 */
proto.common.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {!proto.common.EmailAccuracyStatus} */ (reader.readEnum());
      msg.setAccuracy(value);
      break;
    case 3:
      var value = /** @type {!proto.common.EmailAccuracyType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccuracy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getVerified();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.common.Email.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Email} returns this
 */
proto.common.Email.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EmailAccuracyStatus accuracy = 2;
 * @return {!proto.common.EmailAccuracyStatus}
 */
proto.common.Email.prototype.getAccuracy = function() {
  return /** @type {!proto.common.EmailAccuracyStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.EmailAccuracyStatus} value
 * @return {!proto.common.Email} returns this
 */
proto.common.Email.prototype.setAccuracy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional EmailAccuracyType type = 3;
 * @return {!proto.common.EmailAccuracyType}
 */
proto.common.Email.prototype.getType = function() {
  return /** @type {!proto.common.EmailAccuracyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.EmailAccuracyType} value
 * @return {!proto.common.Email} returns this
 */
proto.common.Email.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool verified = 4;
 * @return {boolean}
 */
proto.common.Email.prototype.getVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Email} returns this
 */
proto.common.Email.prototype.setVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.PersonalizationThread.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PersonalizationThread.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PersonalizationThread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PersonalizationThread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PersonalizationThread.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sameThread: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    emailsToCcList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    emailsToBccList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PersonalizationThread}
 */
proto.common.PersonalizationThread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PersonalizationThread;
  return proto.common.PersonalizationThread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PersonalizationThread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PersonalizationThread}
 */
proto.common.PersonalizationThread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameThread(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToCc(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmailsToBcc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PersonalizationThread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PersonalizationThread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PersonalizationThread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PersonalizationThread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSameThread();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEmailsToCcList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEmailsToBccList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string subject = 1;
 * @return {string}
 */
proto.common.PersonalizationThread.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.common.PersonalizationThread.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool same_thread = 3;
 * @return {boolean}
 */
proto.common.PersonalizationThread.prototype.getSameThread = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.setSameThread = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string emails_to_cc = 4;
 * @return {!Array<string>}
 */
proto.common.PersonalizationThread.prototype.getEmailsToCcList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.setEmailsToCcList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.addEmailsToCc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.clearEmailsToCcList = function() {
  return this.setEmailsToCcList([]);
};


/**
 * repeated string emails_to_bcc = 5;
 * @return {!Array<string>}
 */
proto.common.PersonalizationThread.prototype.getEmailsToBccList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.setEmailsToBccList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.addEmailsToBcc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.PersonalizationThread} returns this
 */
proto.common.PersonalizationThread.prototype.clearEmailsToBccList = function() {
  return this.setEmailsToBccList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SocialLink.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SocialLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SocialLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SocialLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    personal: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SocialLink}
 */
proto.common.SocialLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SocialLink;
  return proto.common.SocialLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SocialLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SocialLink}
 */
proto.common.SocialLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {!proto.common.SocialNetwork} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SocialLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SocialLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SocialLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SocialLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPersonal();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.common.SocialLink.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SocialLink} returns this
 */
proto.common.SocialLink.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SocialNetwork type = 2;
 * @return {!proto.common.SocialNetwork}
 */
proto.common.SocialLink.prototype.getType = function() {
  return /** @type {!proto.common.SocialNetwork} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.SocialNetwork} value
 * @return {!proto.common.SocialLink} returns this
 */
proto.common.SocialLink.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool personal = 3;
 * @return {boolean}
 */
proto.common.SocialLink.prototype.getPersonal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.SocialLink} returns this
 */
proto.common.SocialLink.prototype.setPersonal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.common.CustomVariableValue.oneofGroups_ = [[8,9]];

/**
 * @enum {number}
 */
proto.common.CustomVariableValue.ValueCase = {
  VALUE_NOT_SET: 0,
  STRING_VALUE: 8,
  SELECT_VALUE: 9
};

/**
 * @return {proto.common.CustomVariableValue.ValueCase}
 */
proto.common.CustomVariableValue.prototype.getValueCase = function() {
  return /** @type {proto.common.CustomVariableValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.common.CustomVariableValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CustomVariableValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CustomVariableValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CustomVariableValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CustomVariableValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    variableId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    variableName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    relatedId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    relatedTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
    optCompanionId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    optCompanionType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    stringValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    selectValue: (f = msg.getSelectValue()) && proto.common.CustomVariableValueSelect.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CustomVariableValue}
 */
proto.common.CustomVariableValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CustomVariableValue;
  return proto.common.CustomVariableValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CustomVariableValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CustomVariableValue}
 */
proto.common.CustomVariableValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVariableId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariableName(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CustomVariableType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedId(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setRelatedTo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptCompanionId(value);
      break;
    case 7:
      var value = /** @type {!proto.common.CustomVariableValueRelatedTo} */ (reader.readEnum());
      msg.setOptCompanionType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 9:
      var value = new proto.common.CustomVariableValueSelect;
      reader.readMessage(value,proto.common.CustomVariableValueSelect.deserializeBinaryFromReader);
      msg.setSelectValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CustomVariableValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CustomVariableValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CustomVariableValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CustomVariableValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVariableId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVariableName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRelatedTo();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOptCompanionId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getOptCompanionType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSelectValue();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.common.CustomVariableValueSelect.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 variable_id = 1;
 * @return {number}
 */
proto.common.CustomVariableValue.prototype.getVariableId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setVariableId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string variable_name = 2;
 * @return {string}
 */
proto.common.CustomVariableValue.prototype.getVariableName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setVariableName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CustomVariableType type = 3;
 * @return {!proto.common.CustomVariableType}
 */
proto.common.CustomVariableValue.prototype.getType = function() {
  return /** @type {!proto.common.CustomVariableType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CustomVariableType} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 related_id = 4;
 * @return {number}
 */
proto.common.CustomVariableValue.prototype.getRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CustomVariableValueRelatedTo related_to = 5;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.common.CustomVariableValue.prototype.getRelatedTo = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setRelatedTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 opt_companion_id = 6;
 * @return {number}
 */
proto.common.CustomVariableValue.prototype.getOptCompanionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setOptCompanionId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional CustomVariableValueRelatedTo opt_companion_type = 7;
 * @return {!proto.common.CustomVariableValueRelatedTo}
 */
proto.common.CustomVariableValue.prototype.getOptCompanionType = function() {
  return /** @type {!proto.common.CustomVariableValueRelatedTo} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.common.CustomVariableValueRelatedTo} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setOptCompanionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string string_value = 8;
 * @return {string}
 */
proto.common.CustomVariableValue.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.common.CustomVariableValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 8, proto.common.CustomVariableValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.CustomVariableValue.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional CustomVariableValueSelect select_value = 9;
 * @return {?proto.common.CustomVariableValueSelect}
 */
proto.common.CustomVariableValue.prototype.getSelectValue = function() {
  return /** @type{?proto.common.CustomVariableValueSelect} */ (
    jspb.Message.getWrapperField(this, proto.common.CustomVariableValueSelect, 9));
};


/**
 * @param {?proto.common.CustomVariableValueSelect|undefined} value
 * @return {!proto.common.CustomVariableValue} returns this
*/
proto.common.CustomVariableValue.prototype.setSelectValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.common.CustomVariableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.CustomVariableValue} returns this
 */
proto.common.CustomVariableValue.prototype.clearSelectValue = function() {
  return this.setSelectValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.CustomVariableValue.prototype.hasSelectValue = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.CustomVariableValueSelect.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CustomVariableValueSelect.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CustomVariableValueSelect.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CustomVariableValueSelect} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CustomVariableValueSelect.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.common.CustomVariableValueSelect.SelectItemValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CustomVariableValueSelect}
 */
proto.common.CustomVariableValueSelect.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CustomVariableValueSelect;
  return proto.common.CustomVariableValueSelect.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CustomVariableValueSelect} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CustomVariableValueSelect}
 */
proto.common.CustomVariableValueSelect.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.CustomVariableValueSelect.SelectItemValue;
      reader.readMessage(value,proto.common.CustomVariableValueSelect.SelectItemValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CustomVariableValueSelect.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CustomVariableValueSelect.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CustomVariableValueSelect} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CustomVariableValueSelect.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.CustomVariableValueSelect.SelectItemValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.CustomVariableValueSelect.SelectItemValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.CustomVariableValueSelect.SelectItemValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.CustomVariableValueSelect.SelectItemValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CustomVariableValueSelect.SelectItemValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.CustomVariableValueSelect.SelectItemValue}
 */
proto.common.CustomVariableValueSelect.SelectItemValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.CustomVariableValueSelect.SelectItemValue;
  return proto.common.CustomVariableValueSelect.SelectItemValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.CustomVariableValueSelect.SelectItemValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.CustomVariableValueSelect.SelectItemValue}
 */
proto.common.CustomVariableValueSelect.SelectItemValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.CustomVariableValueSelect.SelectItemValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.CustomVariableValueSelect.SelectItemValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.CustomVariableValueSelect.SelectItemValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.CustomVariableValueSelect.SelectItemValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.common.CustomVariableValueSelect.SelectItemValue.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CustomVariableValueSelect.SelectItemValue} returns this
 */
proto.common.CustomVariableValueSelect.SelectItemValue.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.CustomVariableValueSelect.SelectItemValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.CustomVariableValueSelect.SelectItemValue} returns this
 */
proto.common.CustomVariableValueSelect.SelectItemValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SelectItemValue values = 1;
 * @return {!Array<!proto.common.CustomVariableValueSelect.SelectItemValue>}
 */
proto.common.CustomVariableValueSelect.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.common.CustomVariableValueSelect.SelectItemValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.CustomVariableValueSelect.SelectItemValue, 1));
};


/**
 * @param {!Array<!proto.common.CustomVariableValueSelect.SelectItemValue>} value
 * @return {!proto.common.CustomVariableValueSelect} returns this
*/
proto.common.CustomVariableValueSelect.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.CustomVariableValueSelect.SelectItemValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.CustomVariableValueSelect.SelectItemValue}
 */
proto.common.CustomVariableValueSelect.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.CustomVariableValueSelect.SelectItemValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.CustomVariableValueSelect} returns this
 */
proto.common.CustomVariableValueSelect.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.IdContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.IdContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.common.IdContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.IdContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.IdContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    optionalRelatedId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalWorkspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.IdContainer}
 */
proto.common.IdContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.IdContainer;
  return proto.common.IdContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.IdContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.IdContainer}
 */
proto.common.IdContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setIdList(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalRelatedId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.IdContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.IdContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.IdContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.IdContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getOptionalRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOptionalWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated int64 id = 1;
 * @return {!Array<number>}
 */
proto.common.IdContainer.prototype.getIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.common.IdContainer} returns this
 */
proto.common.IdContainer.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.common.IdContainer} returns this
 */
proto.common.IdContainer.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.IdContainer} returns this
 */
proto.common.IdContainer.prototype.clearIdList = function() {
  return this.setIdList([]);
};


/**
 * optional int64 optional_related_id = 2;
 * @return {number}
 */
proto.common.IdContainer.prototype.getOptionalRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.IdContainer} returns this
 */
proto.common.IdContainer.prototype.setOptionalRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_workspace_id = 3;
 * @return {number}
 */
proto.common.IdContainer.prototype.getOptionalWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.IdContainer} returns this
 */
proto.common.IdContainer.prototype.setOptionalWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.PairContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PairContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PairContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PairContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PairContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.common.Pair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PairContainer}
 */
proto.common.PairContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PairContainer;
  return proto.common.PairContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PairContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PairContainer}
 */
proto.common.PairContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Pair;
      reader.readMessage(value,proto.common.Pair.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PairContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PairContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PairContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PairContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Pair.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Pair items = 1;
 * @return {!Array<!proto.common.Pair>}
 */
proto.common.PairContainer.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.common.Pair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Pair, 1));
};


/**
 * @param {!Array<!proto.common.Pair>} value
 * @return {!proto.common.PairContainer} returns this
*/
proto.common.PairContainer.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Pair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Pair}
 */
proto.common.PairContainer.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Pair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.PairContainer} returns this
 */
proto.common.PairContainer.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.StringListContainer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StringListContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StringListContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StringListContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StringListContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StringListContainer}
 */
proto.common.StringListContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StringListContainer;
  return proto.common.StringListContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StringListContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StringListContainer}
 */
proto.common.StringListContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StringListContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StringListContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StringListContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StringListContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.common.StringListContainer.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.StringListContainer} returns this
 */
proto.common.StringListContainer.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.StringListContainer} returns this
 */
proto.common.StringListContainer.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.StringListContainer} returns this
 */
proto.common.StringListContainer.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.StringRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.StringRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.StringRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StringRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.StringRequest}
 */
proto.common.StringRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.StringRequest;
  return proto.common.StringRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.StringRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.StringRequest}
 */
proto.common.StringRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.StringRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.StringRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.StringRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.StringRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.common.StringRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.StringRequest} returns this
 */
proto.common.StringRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.common.StringRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.StringRequest} returns this
 */
proto.common.StringRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.IdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.IdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.IdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.IdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.IdResponse}
 */
proto.common.IdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.IdResponse;
  return proto.common.IdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.IdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.IdResponse}
 */
proto.common.IdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.IdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.IdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.IdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.IdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.IdResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.IdResponse} returns this
 */
proto.common.IdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.IdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.IdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.IdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.IdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    optionalRelatedId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    optionalWorkspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.IdRequest}
 */
proto.common.IdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.IdRequest;
  return proto.common.IdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.IdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.IdRequest}
 */
proto.common.IdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalRelatedId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.IdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.IdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.IdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.IdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOptionalRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOptionalWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.IdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.IdRequest} returns this
 */
proto.common.IdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 optional_related_id = 2;
 * @return {number}
 */
proto.common.IdRequest.prototype.getOptionalRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.IdRequest} returns this
 */
proto.common.IdRequest.prototype.setOptionalRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 optional_workspace_id = 3;
 * @return {number}
 */
proto.common.IdRequest.prototype.getOptionalWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.IdRequest} returns this
 */
proto.common.IdRequest.prototype.setOptionalWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Folder.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Folder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Folder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Folder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    relatedid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isOwner: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Folder}
 */
proto.common.Folder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Folder;
  return proto.common.Folder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Folder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Folder}
 */
proto.common.Folder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Folder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Folder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Folder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Folder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRelatedid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIsOwner();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.Folder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Folder} returns this
 */
proto.common.Folder.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.common.Folder.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Folder} returns this
 */
proto.common.Folder.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 relatedId = 3;
 * @return {number}
 */
proto.common.Folder.prototype.getRelatedid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Folder} returns this
 */
proto.common.Folder.prototype.setRelatedid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_owner = 4;
 * @return {boolean}
 */
proto.common.Folder.prototype.getIsOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Folder} returns this
 */
proto.common.Folder.prototype.setIsOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    relatedId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Note}
 */
proto.common.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Note;
  return proto.common.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Note}
 */
proto.common.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.Note.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Note} returns this
 */
proto.common.Note.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.common.Note.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Note} returns this
 */
proto.common.Note.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.common.Note.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Note} returns this
 */
proto.common.Note.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 related_id = 4;
 * @return {number}
 */
proto.common.Note.prototype.getRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Note} returns this
 */
proto.common.Note.prototype.setRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 owner_id = 5;
 * @return {number}
 */
proto.common.Note.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Note} returns this
 */
proto.common.Note.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    relatedId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Tag}
 */
proto.common.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Tag;
  return proto.common.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Tag}
 */
proto.common.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.Tag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Tag} returns this
 */
proto.common.Tag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.common.Tag.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Tag} returns this
 */
proto.common.Tag.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 related_id = 3;
 * @return {number}
 */
proto.common.Tag.prototype.getRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Tag} returns this
 */
proto.common.Tag.prototype.setRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Tags.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Tags.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Tags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Tags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Tags.toObject = function(includeInstance, msg) {
  var f, obj = {
    relatedId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.common.Tag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Tags}
 */
proto.common.Tags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Tags;
  return proto.common.Tags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Tags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Tags}
 */
proto.common.Tags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRelatedId(value);
      break;
    case 2:
      var value = new proto.common.Tag;
      reader.readMessage(value,proto.common.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Tags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Tags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Tags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Tags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelatedId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.Tag.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 related_id = 1;
 * @return {number}
 */
proto.common.Tags.prototype.getRelatedId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Tags} returns this
 */
proto.common.Tags.prototype.setRelatedId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Tag tags = 2;
 * @return {!Array<!proto.common.Tag>}
 */
proto.common.Tags.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.common.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Tag, 2));
};


/**
 * @param {!Array<!proto.common.Tag>} value
 * @return {!proto.common.Tags} returns this
*/
proto.common.Tags.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Tag}
 */
proto.common.Tags.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Tags} returns this
 */
proto.common.Tags.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.PeopleOpportunity.prototype.toObject = function(opt_includeInstance) {
  return proto.common.PeopleOpportunity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.PeopleOpportunity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PeopleOpportunity.toObject = function(includeInstance, msg) {
  var f, obj = {
    opportunityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    opportunityUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    opportunityTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    opportunityStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    campaignTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastActivity: jspb.Message.getFieldWithDefault(msg, 8, 0),
    nextActivity: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.PeopleOpportunity}
 */
proto.common.PeopleOpportunity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.PeopleOpportunity;
  return proto.common.PeopleOpportunity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.PeopleOpportunity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.PeopleOpportunity}
 */
proto.common.PeopleOpportunity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpportunityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpportunityTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.common.PeopleRelationshipStatus} */ (reader.readEnum());
      msg.setOpportunityStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastActivity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.PeopleOpportunity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.PeopleOpportunity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.PeopleOpportunity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.PeopleOpportunity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpportunityId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpportunityUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpportunityTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOpportunityStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCampaignTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastActivity();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getNextActivity();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional int64 opportunity_id = 1;
 * @return {number}
 */
proto.common.PeopleOpportunity.prototype.getOpportunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setOpportunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string opportunity_url = 2;
 * @return {string}
 */
proto.common.PeopleOpportunity.prototype.getOpportunityUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setOpportunityUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string opportunity_title = 3;
 * @return {string}
 */
proto.common.PeopleOpportunity.prototype.getOpportunityTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setOpportunityTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PeopleRelationshipStatus opportunity_status = 4;
 * @return {!proto.common.PeopleRelationshipStatus}
 */
proto.common.PeopleOpportunity.prototype.getOpportunityStatus = function() {
  return /** @type {!proto.common.PeopleRelationshipStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.PeopleRelationshipStatus} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setOpportunityStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 campaign_id = 5;
 * @return {number}
 */
proto.common.PeopleOpportunity.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string campaign_title = 6;
 * @return {string}
 */
proto.common.PeopleOpportunity.prototype.getCampaignTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setCampaignTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.common.PeopleOpportunity.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 last_activity = 8;
 * @return {number}
 */
proto.common.PeopleOpportunity.prototype.getLastActivity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setLastActivity = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 next_activity = 9;
 * @return {number}
 */
proto.common.PeopleOpportunity.prototype.getNextActivity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.PeopleOpportunity} returns this
 */
proto.common.PeopleOpportunity.prototype.setNextActivity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.GlobalStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.GlobalStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.GlobalStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GlobalStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeTo: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.GlobalStatisticsRequest}
 */
proto.common.GlobalStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.GlobalStatisticsRequest;
  return proto.common.GlobalStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.GlobalStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.GlobalStatisticsRequest}
 */
proto.common.GlobalStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.GlobalStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.GlobalStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.GlobalStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.GlobalStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeFrom();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimeTo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 time_from = 1;
 * @return {number}
 */
proto.common.GlobalStatisticsRequest.prototype.getTimeFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.GlobalStatisticsRequest} returns this
 */
proto.common.GlobalStatisticsRequest.prototype.setTimeFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 time_to = 2;
 * @return {number}
 */
proto.common.GlobalStatisticsRequest.prototype.getTimeTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.GlobalStatisticsRequest} returns this
 */
proto.common.GlobalStatisticsRequest.prototype.setTimeTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FileAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FileAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FileAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FileAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FileAttachment}
 */
proto.common.FileAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FileAttachment;
  return proto.common.FileAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FileAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FileAttachment}
 */
proto.common.FileAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FileAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FileAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FileAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FileAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.common.FileAttachment.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FileAttachment} returns this
 */
proto.common.FileAttachment.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.common.FileAttachment.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FileAttachment} returns this
 */
proto.common.FileAttachment.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 size = 3;
 * @return {number}
 */
proto.common.FileAttachment.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.FileAttachment} returns this
 */
proto.common.FileAttachment.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.EntityRelationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.EntityRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.EntityRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.EntityRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EntityRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relatedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalFolderId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    optionalNumberTopResults: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.EntityRelationRequest}
 */
proto.common.EntityRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.EntityRelationRequest;
  return proto.common.EntityRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.EntityRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.EntityRelationRequest}
 */
proto.common.EntityRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setRelatedIdsList(value);
      break;
    case 3:
      var value = /** @type {!proto.common.EntityRelationRequest.SelectionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOptionalFolderId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOptionalNumberTopResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.EntityRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.EntityRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.EntityRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.EntityRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRelatedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOptionalFolderId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOptionalNumberTopResults();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.EntityRelationRequest.SelectionType = {
  SELECTED: 0,
  ALL: 1,
  FOLDER_ID: 2,
  TOP_RESULTS: 3
};

/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.common.EntityRelationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 related_ids = 2;
 * @return {!Array<number>}
 */
proto.common.EntityRelationRequest.prototype.getRelatedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.setRelatedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.addRelatedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.clearRelatedIdsList = function() {
  return this.setRelatedIdsList([]);
};


/**
 * optional SelectionType type = 3;
 * @return {!proto.common.EntityRelationRequest.SelectionType}
 */
proto.common.EntityRelationRequest.prototype.getType = function() {
  return /** @type {!proto.common.EntityRelationRequest.SelectionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.EntityRelationRequest.SelectionType} value
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 optional_folder_id = 4;
 * @return {number}
 */
proto.common.EntityRelationRequest.prototype.getOptionalFolderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.setOptionalFolderId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 optional_number_top_results = 5;
 * @return {number}
 */
proto.common.EntityRelationRequest.prototype.getOptionalNumberTopResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.EntityRelationRequest} returns this
 */
proto.common.EntityRelationRequest.prototype.setOptionalNumberTopResults = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    format: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileBytes: msg.getFileBytes_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FileResponse}
 */
proto.common.FileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FileResponse;
  return proto.common.FileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FileResponse}
 */
proto.common.FileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFileBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.common.FileResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FileResponse} returns this
 */
proto.common.FileResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string format = 2;
 * @return {string}
 */
proto.common.FileResponse.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.FileResponse} returns this
 */
proto.common.FileResponse.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes file_bytes = 3;
 * @return {!(string|Uint8Array)}
 */
proto.common.FileResponse.prototype.getFileBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes file_bytes = 3;
 * This is a type-conversion wrapper around `getFileBytes()`
 * @return {string}
 */
proto.common.FileResponse.prototype.getFileBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFileBytes()));
};


/**
 * optional bytes file_bytes = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFileBytes()`
 * @return {!Uint8Array}
 */
proto.common.FileResponse.prototype.getFileBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFileBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.common.FileResponse} returns this
 */
proto.common.FileResponse.prototype.setFileBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.FileResponseContainer.prototype.toObject = function(opt_includeInstance) {
  return proto.common.FileResponseContainer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.FileResponseContainer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FileResponseContainer.toObject = function(includeInstance, msg) {
  var f, obj = {
    complete: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    file: (f = msg.getFile()) && proto.common.FileResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.FileResponseContainer}
 */
proto.common.FileResponseContainer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.FileResponseContainer;
  return proto.common.FileResponseContainer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.FileResponseContainer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.FileResponseContainer}
 */
proto.common.FileResponseContainer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplete(value);
      break;
    case 2:
      var value = new proto.common.FileResponse;
      reader.readMessage(value,proto.common.FileResponse.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.FileResponseContainer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.FileResponseContainer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.FileResponseContainer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.FileResponseContainer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplete();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.FileResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool complete = 1;
 * @return {boolean}
 */
proto.common.FileResponseContainer.prototype.getComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.FileResponseContainer} returns this
 */
proto.common.FileResponseContainer.prototype.setComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional FileResponse file = 2;
 * @return {?proto.common.FileResponse}
 */
proto.common.FileResponseContainer.prototype.getFile = function() {
  return /** @type{?proto.common.FileResponse} */ (
    jspb.Message.getWrapperField(this, proto.common.FileResponse, 2));
};


/**
 * @param {?proto.common.FileResponse|undefined} value
 * @return {!proto.common.FileResponseContainer} returns this
*/
proto.common.FileResponseContainer.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.FileResponseContainer} returns this
 */
proto.common.FileResponseContainer.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.FileResponseContainer.prototype.hasFile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Range.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Range.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Range} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Range.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Range}
 */
proto.common.Range.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Range;
  return proto.common.Range.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Range} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Range}
 */
proto.common.Range.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Range.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Range.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Range} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Range.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 min = 1;
 * @return {number}
 */
proto.common.Range.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Range} returns this
 */
proto.common.Range.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.common.Range.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Range} returns this
 */
proto.common.Range.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AhrefIntegrationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AhrefIntegrationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AhrefIntegrationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AhrefIntegrationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AhrefIntegrationSettings}
 */
proto.common.AhrefIntegrationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AhrefIntegrationSettings;
  return proto.common.AhrefIntegrationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AhrefIntegrationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AhrefIntegrationSettings}
 */
proto.common.AhrefIntegrationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AhrefIntegrationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AhrefIntegrationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AhrefIntegrationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AhrefIntegrationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.common.AhrefIntegrationSettings.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AhrefIntegrationSettings} returns this
 */
proto.common.AhrefIntegrationSettings.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SnovioIntegrationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SnovioIntegrationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SnovioIntegrationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SnovioIntegrationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SnovioIntegrationSettings}
 */
proto.common.SnovioIntegrationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SnovioIntegrationSettings;
  return proto.common.SnovioIntegrationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SnovioIntegrationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SnovioIntegrationSettings}
 */
proto.common.SnovioIntegrationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SnovioIntegrationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SnovioIntegrationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SnovioIntegrationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SnovioIntegrationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.common.SnovioIntegrationSettings.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SnovioIntegrationSettings} returns this
 */
proto.common.SnovioIntegrationSettings.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_secret = 2;
 * @return {string}
 */
proto.common.SnovioIntegrationSettings.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SnovioIntegrationSettings} returns this
 */
proto.common.SnovioIntegrationSettings.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.HunterIntegrationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.HunterIntegrationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.HunterIntegrationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.HunterIntegrationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.HunterIntegrationSettings}
 */
proto.common.HunterIntegrationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.HunterIntegrationSettings;
  return proto.common.HunterIntegrationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.HunterIntegrationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.HunterIntegrationSettings}
 */
proto.common.HunterIntegrationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.HunterIntegrationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.HunterIntegrationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.HunterIntegrationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.HunterIntegrationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.common.HunterIntegrationSettings.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.HunterIntegrationSettings} returns this
 */
proto.common.HunterIntegrationSettings.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.RocketReachIntegrationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.RocketReachIntegrationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.RocketReachIntegrationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RocketReachIntegrationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.RocketReachIntegrationSettings}
 */
proto.common.RocketReachIntegrationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.RocketReachIntegrationSettings;
  return proto.common.RocketReachIntegrationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.RocketReachIntegrationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.RocketReachIntegrationSettings}
 */
proto.common.RocketReachIntegrationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.RocketReachIntegrationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.RocketReachIntegrationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.RocketReachIntegrationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RocketReachIntegrationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.common.RocketReachIntegrationSettings.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.RocketReachIntegrationSettings} returns this
 */
proto.common.RocketReachIntegrationSettings.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SemrushIntegrationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SemrushIntegrationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SemrushIntegrationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SemrushIntegrationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SemrushIntegrationSettings}
 */
proto.common.SemrushIntegrationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SemrushIntegrationSettings;
  return proto.common.SemrushIntegrationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SemrushIntegrationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SemrushIntegrationSettings}
 */
proto.common.SemrushIntegrationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SemrushIntegrationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SemrushIntegrationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SemrushIntegrationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SemrushIntegrationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.common.SemrushIntegrationSettings.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.SemrushIntegrationSettings} returns this
 */
proto.common.SemrushIntegrationSettings.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MozIntegrationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MozIntegrationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MozIntegrationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MozIntegrationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secretKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MozIntegrationSettings}
 */
proto.common.MozIntegrationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MozIntegrationSettings;
  return proto.common.MozIntegrationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MozIntegrationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MozIntegrationSettings}
 */
proto.common.MozIntegrationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MozIntegrationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MozIntegrationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MozIntegrationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MozIntegrationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecretKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string access_id = 1;
 * @return {string}
 */
proto.common.MozIntegrationSettings.prototype.getAccessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MozIntegrationSettings} returns this
 */
proto.common.MozIntegrationSettings.prototype.setAccessId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret_key = 2;
 * @return {string}
 */
proto.common.MozIntegrationSettings.prototype.getSecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.MozIntegrationSettings} returns this
 */
proto.common.MozIntegrationSettings.prototype.setSecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AutomationInternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AutomationInternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AutomationInternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AutomationInternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pipelineId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AutomationInternalRequest}
 */
proto.common.AutomationInternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AutomationInternalRequest;
  return proto.common.AutomationInternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AutomationInternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AutomationInternalRequest}
 */
proto.common.AutomationInternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPipelineId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AutomationInternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AutomationInternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AutomationInternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AutomationInternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPipelineId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 campaign_id = 1;
 * @return {number}
 */
proto.common.AutomationInternalRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.AutomationInternalRequest} returns this
 */
proto.common.AutomationInternalRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 pipeline_id = 2;
 * @return {number}
 */
proto.common.AutomationInternalRequest.prototype.getPipelineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.AutomationInternalRequest} returns this
 */
proto.common.AutomationInternalRequest.prototype.setPipelineId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 user_id = 3;
 * @return {number}
 */
proto.common.AutomationInternalRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.AutomationInternalRequest} returns this
 */
proto.common.AutomationInternalRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AutomationInternalProgressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AutomationInternalProgressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AutomationInternalProgressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AutomationInternalProgressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    progress: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AutomationInternalProgressResponse}
 */
proto.common.AutomationInternalProgressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AutomationInternalProgressResponse;
  return proto.common.AutomationInternalProgressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AutomationInternalProgressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AutomationInternalProgressResponse}
 */
proto.common.AutomationInternalProgressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AutomationInternalProgressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AutomationInternalProgressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AutomationInternalProgressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AutomationInternalProgressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool finished = 1;
 * @return {boolean}
 */
proto.common.AutomationInternalProgressResponse.prototype.getFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.AutomationInternalProgressResponse} returns this
 */
proto.common.AutomationInternalProgressResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 progress = 2;
 * @return {number}
 */
proto.common.AutomationInternalProgressResponse.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.AutomationInternalProgressResponse} returns this
 */
proto.common.AutomationInternalProgressResponse.prototype.setProgress = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AutomationInternalResult.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AutomationInternalResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AutomationInternalResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AutomationInternalResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AutomationInternalResult}
 */
proto.common.AutomationInternalResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AutomationInternalResult;
  return proto.common.AutomationInternalResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AutomationInternalResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AutomationInternalResult}
 */
proto.common.AutomationInternalResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AutomationInternalResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AutomationInternalResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AutomationInternalResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AutomationInternalResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.common.AutomationInternalResult.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.AutomationInternalResult} returns this
 */
proto.common.AutomationInternalResult.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.common.AutomationInternalResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.AutomationInternalResult} returns this
 */
proto.common.AutomationInternalResult.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.ListennotesMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.common.ListennotesMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.ListennotesMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ListennotesMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalEpisodes: (f = msg.getTotalEpisodes()) && proto.common.Range.toObject(includeInstance, f),
    dateoflastepisode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    customDateValue: (f = msg.getCustomDateValue()) && proto.common.TimestampRange.toObject(includeInstance, f),
    searchIn: jspb.Message.getFieldWithDefault(msg, 4, 0),
    listenScore: (f = msg.getListenScore()) && proto.common.Range.toObject(includeInstance, f),
    sortBy: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.ListennotesMetrics}
 */
proto.common.ListennotesMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.ListennotesMetrics;
  return proto.common.ListennotesMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.ListennotesMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.ListennotesMetrics}
 */
proto.common.ListennotesMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setTotalEpisodes(value);
      break;
    case 2:
      var value = /** @type {!proto.common.DateOfLastEpisode} */ (reader.readEnum());
      msg.setDateoflastepisode(value);
      break;
    case 3:
      var value = new proto.common.TimestampRange;
      reader.readMessage(value,proto.common.TimestampRange.deserializeBinaryFromReader);
      msg.setCustomDateValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.PodcastQuerySearchIn} */ (reader.readEnum());
      msg.setSearchIn(value);
      break;
    case 5:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setListenScore(value);
      break;
    case 6:
      var value = /** @type {!proto.common.ContentSearchOrderBy} */ (reader.readEnum());
      msg.setSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.ListennotesMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.ListennotesMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.ListennotesMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.ListennotesMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalEpisodes();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getDateoflastepisode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomDateValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.TimestampRange.serializeBinaryToWriter
    );
  }
  f = message.getSearchIn();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getListenScore();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getSortBy();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional Range total_episodes = 1;
 * @return {?proto.common.Range}
 */
proto.common.ListennotesMetrics.prototype.getTotalEpisodes = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 1));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.ListennotesMetrics} returns this
*/
proto.common.ListennotesMetrics.prototype.setTotalEpisodes = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ListennotesMetrics} returns this
 */
proto.common.ListennotesMetrics.prototype.clearTotalEpisodes = function() {
  return this.setTotalEpisodes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ListennotesMetrics.prototype.hasTotalEpisodes = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DateOfLastEpisode dateOfLastEpisode = 2;
 * @return {!proto.common.DateOfLastEpisode}
 */
proto.common.ListennotesMetrics.prototype.getDateoflastepisode = function() {
  return /** @type {!proto.common.DateOfLastEpisode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.DateOfLastEpisode} value
 * @return {!proto.common.ListennotesMetrics} returns this
 */
proto.common.ListennotesMetrics.prototype.setDateoflastepisode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TimestampRange custom_date_value = 3;
 * @return {?proto.common.TimestampRange}
 */
proto.common.ListennotesMetrics.prototype.getCustomDateValue = function() {
  return /** @type{?proto.common.TimestampRange} */ (
    jspb.Message.getWrapperField(this, proto.common.TimestampRange, 3));
};


/**
 * @param {?proto.common.TimestampRange|undefined} value
 * @return {!proto.common.ListennotesMetrics} returns this
*/
proto.common.ListennotesMetrics.prototype.setCustomDateValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ListennotesMetrics} returns this
 */
proto.common.ListennotesMetrics.prototype.clearCustomDateValue = function() {
  return this.setCustomDateValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ListennotesMetrics.prototype.hasCustomDateValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PodcastQuerySearchIn search_in = 4;
 * @return {!proto.common.PodcastQuerySearchIn}
 */
proto.common.ListennotesMetrics.prototype.getSearchIn = function() {
  return /** @type {!proto.common.PodcastQuerySearchIn} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.PodcastQuerySearchIn} value
 * @return {!proto.common.ListennotesMetrics} returns this
 */
proto.common.ListennotesMetrics.prototype.setSearchIn = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Range listen_score = 5;
 * @return {?proto.common.Range}
 */
proto.common.ListennotesMetrics.prototype.getListenScore = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 5));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.ListennotesMetrics} returns this
*/
proto.common.ListennotesMetrics.prototype.setListenScore = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.ListennotesMetrics} returns this
 */
proto.common.ListennotesMetrics.prototype.clearListenScore = function() {
  return this.setListenScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.ListennotesMetrics.prototype.hasListenScore = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ContentSearchOrderBy sort_by = 6;
 * @return {!proto.common.ContentSearchOrderBy}
 */
proto.common.ListennotesMetrics.prototype.getSortBy = function() {
  return /** @type {!proto.common.ContentSearchOrderBy} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.ContentSearchOrderBy} value
 * @return {!proto.common.ListennotesMetrics} returns this
 */
proto.common.ListennotesMetrics.prototype.setSortBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.MozMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.common.MozMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.MozMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MozMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    includePaidMetrics: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    domainAuthority: (f = msg.getDomainAuthority()) && proto.common.Range.toObject(includeInstance, f),
    domainSpamScore: (f = msg.getDomainSpamScore()) && proto.common.Range.toObject(includeInstance, f),
    pageAuthority: (f = msg.getPageAuthority()) && proto.common.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.MozMetrics}
 */
proto.common.MozMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.MozMetrics;
  return proto.common.MozMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.MozMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.MozMetrics}
 */
proto.common.MozMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePaidMetrics(value);
      break;
    case 2:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainAuthority(value);
      break;
    case 3:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainSpamScore(value);
      break;
    case 4:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setPageAuthority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.MozMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.MozMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.MozMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.MozMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludePaidMetrics();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDomainAuthority();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getDomainSpamScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getPageAuthority();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool include_paid_metrics = 1;
 * @return {boolean}
 */
proto.common.MozMetrics.prototype.getIncludePaidMetrics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.MozMetrics} returns this
 */
proto.common.MozMetrics.prototype.setIncludePaidMetrics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Range domain_authority = 2;
 * @return {?proto.common.Range}
 */
proto.common.MozMetrics.prototype.getDomainAuthority = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 2));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.MozMetrics} returns this
*/
proto.common.MozMetrics.prototype.setDomainAuthority = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MozMetrics} returns this
 */
proto.common.MozMetrics.prototype.clearDomainAuthority = function() {
  return this.setDomainAuthority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MozMetrics.prototype.hasDomainAuthority = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Range domain_spam_score = 3;
 * @return {?proto.common.Range}
 */
proto.common.MozMetrics.prototype.getDomainSpamScore = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 3));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.MozMetrics} returns this
*/
proto.common.MozMetrics.prototype.setDomainSpamScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MozMetrics} returns this
 */
proto.common.MozMetrics.prototype.clearDomainSpamScore = function() {
  return this.setDomainSpamScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MozMetrics.prototype.hasDomainSpamScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Range page_authority = 4;
 * @return {?proto.common.Range}
 */
proto.common.MozMetrics.prototype.getPageAuthority = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 4));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.MozMetrics} returns this
*/
proto.common.MozMetrics.prototype.setPageAuthority = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.MozMetrics} returns this
 */
proto.common.MozMetrics.prototype.clearPageAuthority = function() {
  return this.setPageAuthority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.MozMetrics.prototype.hasPageAuthority = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AhrefMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AhrefMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AhrefMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AhrefMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    includePaidMetrics: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    domainRating: (f = msg.getDomainRating()) && proto.common.Range.toObject(includeInstance, f),
    domainMonthlyTraffic: (f = msg.getDomainMonthlyTraffic()) && proto.common.Range.toObject(includeInstance, f),
    domainOrganicKeywords: (f = msg.getDomainOrganicKeywords()) && proto.common.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AhrefMetrics}
 */
proto.common.AhrefMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AhrefMetrics;
  return proto.common.AhrefMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AhrefMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AhrefMetrics}
 */
proto.common.AhrefMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePaidMetrics(value);
      break;
    case 2:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainRating(value);
      break;
    case 3:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainMonthlyTraffic(value);
      break;
    case 4:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainOrganicKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AhrefMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AhrefMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AhrefMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AhrefMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludePaidMetrics();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDomainRating();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getDomainMonthlyTraffic();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getDomainOrganicKeywords();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool include_paid_metrics = 1;
 * @return {boolean}
 */
proto.common.AhrefMetrics.prototype.getIncludePaidMetrics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.AhrefMetrics} returns this
 */
proto.common.AhrefMetrics.prototype.setIncludePaidMetrics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Range domain_rating = 2;
 * @return {?proto.common.Range}
 */
proto.common.AhrefMetrics.prototype.getDomainRating = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 2));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.AhrefMetrics} returns this
*/
proto.common.AhrefMetrics.prototype.setDomainRating = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AhrefMetrics} returns this
 */
proto.common.AhrefMetrics.prototype.clearDomainRating = function() {
  return this.setDomainRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AhrefMetrics.prototype.hasDomainRating = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Range domain_monthly_traffic = 3;
 * @return {?proto.common.Range}
 */
proto.common.AhrefMetrics.prototype.getDomainMonthlyTraffic = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 3));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.AhrefMetrics} returns this
*/
proto.common.AhrefMetrics.prototype.setDomainMonthlyTraffic = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AhrefMetrics} returns this
 */
proto.common.AhrefMetrics.prototype.clearDomainMonthlyTraffic = function() {
  return this.setDomainMonthlyTraffic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AhrefMetrics.prototype.hasDomainMonthlyTraffic = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Range domain_organic_keywords = 4;
 * @return {?proto.common.Range}
 */
proto.common.AhrefMetrics.prototype.getDomainOrganicKeywords = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 4));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.AhrefMetrics} returns this
*/
proto.common.AhrefMetrics.prototype.setDomainOrganicKeywords = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.AhrefMetrics} returns this
 */
proto.common.AhrefMetrics.prototype.clearDomainOrganicKeywords = function() {
  return this.setDomainOrganicKeywords(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.AhrefMetrics.prototype.hasDomainOrganicKeywords = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.SemrushMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.common.SemrushMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.SemrushMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SemrushMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    includePaidMetrics: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    domainAuthorityScore: (f = msg.getDomainAuthorityScore()) && proto.common.Range.toObject(includeInstance, f),
    domainMonthlyTraffic: (f = msg.getDomainMonthlyTraffic()) && proto.common.Range.toObject(includeInstance, f),
    domainOrganicKeywords: (f = msg.getDomainOrganicKeywords()) && proto.common.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.SemrushMetrics}
 */
proto.common.SemrushMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.SemrushMetrics;
  return proto.common.SemrushMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.SemrushMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.SemrushMetrics}
 */
proto.common.SemrushMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePaidMetrics(value);
      break;
    case 2:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainAuthorityScore(value);
      break;
    case 3:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainMonthlyTraffic(value);
      break;
    case 4:
      var value = new proto.common.Range;
      reader.readMessage(value,proto.common.Range.deserializeBinaryFromReader);
      msg.setDomainOrganicKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.SemrushMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.SemrushMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.SemrushMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.SemrushMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludePaidMetrics();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDomainAuthorityScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getDomainMonthlyTraffic();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
  f = message.getDomainOrganicKeywords();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.common.Range.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool include_paid_metrics = 1;
 * @return {boolean}
 */
proto.common.SemrushMetrics.prototype.getIncludePaidMetrics = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.SemrushMetrics} returns this
 */
proto.common.SemrushMetrics.prototype.setIncludePaidMetrics = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Range domain_authority_score = 2;
 * @return {?proto.common.Range}
 */
proto.common.SemrushMetrics.prototype.getDomainAuthorityScore = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 2));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.SemrushMetrics} returns this
*/
proto.common.SemrushMetrics.prototype.setDomainAuthorityScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.SemrushMetrics} returns this
 */
proto.common.SemrushMetrics.prototype.clearDomainAuthorityScore = function() {
  return this.setDomainAuthorityScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.SemrushMetrics.prototype.hasDomainAuthorityScore = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Range domain_monthly_traffic = 3;
 * @return {?proto.common.Range}
 */
proto.common.SemrushMetrics.prototype.getDomainMonthlyTraffic = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 3));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.SemrushMetrics} returns this
*/
proto.common.SemrushMetrics.prototype.setDomainMonthlyTraffic = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.SemrushMetrics} returns this
 */
proto.common.SemrushMetrics.prototype.clearDomainMonthlyTraffic = function() {
  return this.setDomainMonthlyTraffic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.SemrushMetrics.prototype.hasDomainMonthlyTraffic = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Range domain_organic_keywords = 4;
 * @return {?proto.common.Range}
 */
proto.common.SemrushMetrics.prototype.getDomainOrganicKeywords = function() {
  return /** @type{?proto.common.Range} */ (
    jspb.Message.getWrapperField(this, proto.common.Range, 4));
};


/**
 * @param {?proto.common.Range|undefined} value
 * @return {!proto.common.SemrushMetrics} returns this
*/
proto.common.SemrushMetrics.prototype.setDomainOrganicKeywords = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.SemrushMetrics} returns this
 */
proto.common.SemrushMetrics.prototype.clearDomainOrganicKeywords = function() {
  return this.setDomainOrganicKeywords(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.SemrushMetrics.prototype.hasDomainOrganicKeywords = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.RegistrationCode.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.RegistrationCode.prototype.toObject = function(opt_includeInstance) {
  return proto.common.RegistrationCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.RegistrationCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RegistrationCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.common.Pair.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.RegistrationCode}
 */
proto.common.RegistrationCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.RegistrationCode;
  return proto.common.RegistrationCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.RegistrationCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.RegistrationCode}
 */
proto.common.RegistrationCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.common.Pair;
      reader.readMessage(value,proto.common.Pair.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.RegistrationCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.RegistrationCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.RegistrationCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.RegistrationCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.Pair.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.common.RegistrationCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.RegistrationCode} returns this
 */
proto.common.RegistrationCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Pair tags = 2;
 * @return {!Array<!proto.common.Pair>}
 */
proto.common.RegistrationCode.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.common.Pair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Pair, 2));
};


/**
 * @param {!Array<!proto.common.Pair>} value
 * @return {!proto.common.RegistrationCode} returns this
*/
proto.common.RegistrationCode.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Pair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Pair}
 */
proto.common.RegistrationCode.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Pair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.RegistrationCode} returns this
 */
proto.common.RegistrationCode.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional CodeType type = 3;
 * @return {!proto.common.CodeType}
 */
proto.common.RegistrationCode.prototype.getType = function() {
  return /** @type {!proto.common.CodeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CodeType} value
 * @return {!proto.common.RegistrationCode} returns this
 */
proto.common.RegistrationCode.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.AiPromptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.common.AiPromptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.AiPromptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AiPromptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.AiPromptRequest}
 */
proto.common.AiPromptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.AiPromptRequest;
  return proto.common.AiPromptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.AiPromptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.AiPromptRequest}
 */
proto.common.AiPromptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.AiPromptName} */ (reader.readEnum());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.AiPromptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.AiPromptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.AiPromptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.AiPromptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AiPromptName name = 1;
 * @return {!proto.common.AiPromptName}
 */
proto.common.AiPromptRequest.prototype.getName = function() {
  return /** @type {!proto.common.AiPromptName} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.AiPromptName} value
 * @return {!proto.common.AiPromptRequest} returns this
 */
proto.common.AiPromptRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.InstructionPair.prototype.toObject = function(opt_includeInstance) {
  return proto.common.InstructionPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.InstructionPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.InstructionPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.InstructionPair}
 */
proto.common.InstructionPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.InstructionPair;
  return proto.common.InstructionPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.InstructionPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.InstructionPair}
 */
proto.common.InstructionPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.InstructionPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.InstructionPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.InstructionPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.InstructionPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.common.InstructionPair.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.InstructionPair} returns this
 */
proto.common.InstructionPair.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.InstructionPair.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.InstructionPair} returns this
 */
proto.common.InstructionPair.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.common.RealmType = {
  RESPONA_APP: 0,
  RESPONA_ADMIN: 1,
  SEMRUSH: 2
};

/**
 * @enum {number}
 */
proto.common.StarterPackCodes = {
  NONE: 0,
  APP_SUMO: 1,
  VISME_EBOOK: 2,
  TRAVEL_PAYOUTS: 3,
  STARTUPS_DOT_COM: 4,
  RANKMATH: 5,
  THE_AGENCY_GUY: 6
};

/**
 * @enum {number}
 */
proto.common.AuthStep = {
  LOGIN: 0,
  PAYMENT: 1,
  WORKSPACE: 2,
  MEMBERS: 3,
  TUTORIAL: 4,
  CAMPAIGN: 5,
  DASHBOARD: 6
};

/**
 * @enum {number}
 */
proto.common.OpportunityType = {
  ARTICLE_OPPORTUNITY: 0,
  HARO_OPPORTUNITY: 1,
  MANUAL_OPPORTUNITY: 2
};

/**
 * @enum {number}
 */
proto.common.ContactStatus = {
  NOT_CONTACTED: 0,
  CONTACTED: 1,
  REPLIED: 2,
  ACCEPTED: 3,
  REJECTED: 4,
  NOT_APPLICABLE: 5,
  IN_PROGRESS: 6,
  BOUNCED: 7,
  ATTEMPT_TO_REACH: 8,
  UNSUBSCRIBED: 9,
  STOPPED: 10,
  UNKNOWN: 11
};

/**
 * @enum {number}
 */
proto.common.SortDirection = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.common.EmailAccuracyStatus = {
  UNDEFINED: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3
};

/**
 * @enum {number}
 */
proto.common.EmailAccuracyType = {
  EMAIL_TYPE_UNDEFINED: 0,
  DOMAIN: 1,
  PERSONAL: 2,
  GENERIC: 3
};

/**
 * @enum {number}
 */
proto.common.PairType = {
  NUMBER: 0,
  STRING: 1,
  DOUBLE: 2,
  TIMESTAMP: 3
};

/**
 * @enum {number}
 */
proto.common.ContentSearchType = {
  GOOGLE_SEARCH: 0,
  BING_SEARCH: 1,
  PODCAST: 2,
  EPISODE: 3,
  TRENDING_CONTENT: 4,
  BING_NEWS: 5,
  IMPORT: 6,
  RELATIONSHIPS: 7,
  AHREF_BACKLINKS: 8,
  SEMRUSH_BACKLINKS: 9,
  MOZ_BACKLINKS: 10,
  RESPONA_BACKLINKS: 11,
  GOOGLE_NEWS: 12
};

/**
 * @enum {number}
 */
proto.common.BacklinksRel = {
  FOLLOW_AND_NO_FOLLOW: 0,
  FOLLOW_ONLY: 1,
  NO_FOLLOW_ONLY: 2
};

/**
 * @enum {number}
 */
proto.common.CampaignFilterType = {
  ALL: 0,
  EDITING: 1,
  PAUSE: 2,
  ACTIVE: 3
};

/**
 * @enum {number}
 */
proto.common.CustomVariableType = {
  TEXT: 0,
  URL: 1,
  INT: 2,
  OPEN_AI: 3,
  TEAM_MEMBER: 4,
  CHECKBOX: 5,
  DATE_AND_TIME: 6,
  SELECT: 7,
  MULTI_SELECT: 8,
  RATING: 9
};

/**
 * @enum {number}
 */
proto.common.CustomVariableValueRelatedTo = {
  PERSON: 0,
  OPPORTUNITY: 1,
  WEBSITE: 2,
  MISC: 3,
  RELATIONSHIP_OPPORTUNITY: 4
};

/**
 * @enum {number}
 */
proto.common.RelationshipPipelineStageOpportunityRelatedTo = {
  RELATIONSHIP_PERSON: 0,
  RELATIONSHIP_WEBSITE: 1
};

/**
 * @enum {number}
 */
proto.common.SocialNetwork = {
  UNKNOWN_SOCIAL_NETWORK: 0,
  TWITTER: 1,
  FACEBOOK: 2,
  LINKED_IN: 3,
  CRUNCHBASE: 4,
  ANGELLIST: 5,
  MEETUP: 6,
  GRAVATAR: 7,
  FOURSQUARE: 8,
  GOOGLE: 9,
  SPOTIFY: 10,
  YOUTUBE: 11
};

/**
 * @enum {number}
 */
proto.common.BillingInterval = {
  MONTHLY: 0,
  YEARLY: 1,
  UNSET: 2
};

/**
 * @enum {number}
 */
proto.common.FoundAtCategory = {
  FROM_UNKNOWN: 0,
  FROM_PAGE: 1,
  FROM_SEARCH: 2,
  FROM_AUTOMATION: 3,
  FROM_IMPORT: 4,
  FROM_MANUAL: 5,
  FROM_RELATIONSHIPS: 6
};

/**
 * @enum {number}
 */
proto.common.ContactVariable = {
  UNDEFINED_VARIABLE: 0,
  URL_VARIABLE: 1,
  URL_TITLE_VARIABLE: 2,
  FIRST_NAME_VARIABLE: 3,
  FULL_NAME_VARIABLE: 4,
  LAST_NAME_VARIABLE: 5,
  EMAIL_VARIABLE: 6,
  EMAIL_2_VARIABLE: 7,
  EMAIL_3_VARIABLE: 8,
  EMAIL_4_VARIABLE: 9,
  EMAIL_5_VARIABLE: 10,
  LOCATION_VARIABLE: 11,
  JOB_TITLE_VARIABLE: 12,
  ORGANIZATION_VARIABLE: 13,
  LINKEDIN_URL_VARIABLE: 14,
  TAG_VARIABLE: 15,
  CUSTOM_VARIABLE: 21
};

/**
 * @enum {number}
 */
proto.common.FilterOperation = {
  STRING_EQUAL: 0,
  STRING_EQUAL_NOT: 1,
  STRING_CONTAINS: 2,
  STRING_CONTAINS_NOT: 3,
  STRING_STARTS_WITH: 4,
  STRING_ENDS_WITH: 5,
  STRING_IS_BLANK: 6,
  STRING_IS_NOT_BLANK: 7,
  NUMBER_EQUAL: 8,
  NUMBER_EQUAL_NOT: 9,
  NUMBER_GREATER_THAN: 10,
  NUMBER_GREATER_THAN_OR_EQUAL: 11,
  NUMBER_LESS_THAN: 12,
  NUMBER_LESS_THAN_OR_EQUAL: 13,
  BOOLEAN_EQUAL: 14,
  BOOLEAN_EQUAL_NOT: 15
};

/**
 * @enum {number}
 */
proto.common.FilterJoinType = {
  JOIN_QUERY_AND: 0,
  JOIN_QUERY_OR: 1
};

/**
 * @enum {number}
 */
proto.common.CampaignAutomationStatus = {
  IN_PROCESS: 0,
  FINISHED: 1,
  DRAFT: 2,
  FUTURE: 3,
  CANCELED: 4
};

/**
 * @enum {number}
 */
proto.common.CampaignAgentStatus = {
  AGENT_DRAFT_STATUS: 0,
  AGENT_LAUNCHED_STATUS: 1,
  AGENT_PAUSED_STATUS: 2
};

/**
 * @enum {number}
 */
proto.common.IntegrationType = {
  UNKNOWN_INTEGRATION: 0,
  AHREFS_INTEGRATION: 1,
  HUNTER_INTEGRATION: 2,
  SNOVIO_INTEGRATION: 3,
  ROCKET_REACH_INTEGRATION: 4,
  SEMRUSH_INTEGRATION: 5,
  MOZ_INTEGRATION: 6
};

/**
 * @enum {number}
 */
proto.common.ContactSearchSource = {
  UNKNOWN_SEARCH_SOURCE: 0,
  RESPONA_SEARCH_SOURCE: 1,
  HUNTER_SEARCH_SOURCE: 2,
  SNOVIO_SEARCH_SOURCE: 3,
  ROCKET_REACH_SEARCH_SOURCE: 4,
  GOOGLE_SEARCH_SOURCE: 5
};

/**
 * @enum {number}
 */
proto.common.IntegrationStatus = {
  NOT_CONNECTED_INTEGRATION: 0,
  ACTIVE_INTEGRATION: 1,
  AUTH_FAILED_INTEGRATION: 2,
  PAUSED_INTEGRATION: 3
};

/**
 * @enum {number}
 */
proto.common.BacklinksSearchSource = {
  RESPONA_BACKLINKS_SEARCH: 0,
  AHREFS_BACKLINKS_SEARCH: 1,
  SEMRUSH_BACKLINKS_SEARCH: 2,
  MOZ_BACKLINKS_SEARCH: 3
};

/**
 * @enum {number}
 */
proto.common.BacklinkStatus = {
  ALL_BACKLINKS: 0,
  LIVE_BACKLINKS: 1,
  LOST_BACKLINKS: 2,
  NEW_LAST_60_DAYS_BACKLINKS: 3,
  LOST_LAST_60_DAYS_BACKLINKS: 4
};

/**
 * @enum {number}
 */
proto.common.ContentMetricsType = {
  AHREFS_METRICS: 0,
  SEMRUSH_METRICS: 1,
  LISTENNOTES_METRICS: 2,
  MOZ_METRICS: 3,
  EMPTY_METRICS: 4
};

/**
 * @enum {number}
 */
proto.common.IntegrationSource = {
  PERSONAL_INTEGRATION: 0,
  PUBLIC_INTEGRATION: 1,
  SYSTEM_INTEGRATION: 2
};

/**
 * @enum {number}
 */
proto.common.ContentSearchOrderBy = {
  RELEVANCE: 0,
  DATE: 1
};

/**
 * @enum {number}
 */
proto.common.DateOfLastEpisode = {
  ALL_TIMES: 0,
  LAST_24_HOURS: 1,
  LAST_7_DAYS: 2,
  LAST_30_DAYS: 3,
  LAST_6_MONTHS: 4,
  LAST_YEAR: 5,
  CUSTOM: 6
};

/**
 * @enum {number}
 */
proto.common.PodcastQuerySearchIn = {
  PODCAST_SEARCH_IN_EVERYWHERE: 0,
  PODCAST_SEARCH_IN_TITLE: 1,
  PODCAST_SEARCH_IN_DESCRIPTION: 2,
  PODCAST_SEARCH_IN_AUTHOR: 3,
  PODCAST_SEARCH_IN_AUDIO: 4
};

/**
 * @enum {number}
 */
proto.common.PeopleRelationshipStatus = {
  RELATIONSHIP_STATUS_NOT_CONTACTED: 0,
  RELATIONSHIP_STATUS_ATTEMPTING_TO_REACH: 1,
  RELATIONSHIP_STATUS_CONTACTED: 2,
  RELATIONSHIP_STATUS_REPLIED: 3,
  RELATIONSHIP_STATUS_BOUNCED: 4,
  RELATIONSHIP_STATUS_UNSUBSCRIBED: 5,
  RELATIONSHIP_STATUS_CANCELED_MANUALLY: 6,
  RELATIONSHIP_STATUS_INTERESTED: 7,
  RELATIONSHIP_STATUS_NOT_INTERESTED: 8,
  RELATIONSHIP_STATUS_WON: 9,
  RELATIONSHIP_STATUS_LOST: 10
};

/**
 * @enum {number}
 */
proto.common.CodeType = {
  UNDEFINED_CODE: 0,
  REFERRAL_CODE: 1,
  A_B_TESTING_CODE: 2,
  PARTNER_STACK: 3
};

/**
 * @enum {number}
 */
proto.common.OpenAIModelType = {
  GPT_CHEAP: 0,
  GPT_EXPENSIVE: 1
};

/**
 * @enum {number}
 */
proto.common.OpenAITone = {
  CREATIVE: 0,
  VERY_CREATIVE: 1
};

/**
 * @enum {number}
 */
proto.common.AiPromptName = {
  GREETING_AGENT_PROMPT: 0,
  LINK_BUILDING_AGENT_PROMPT: 1,
  LINK_BUILDING_SHOULDER_NICHES_PROMPT: 2,
  REPLY_TONE_DETECTION_PROMPT: 3,
  LINK_BUILDING_ASSISTANT_PROMPT: 4,
  OPPORTUNITY_INSPECTION_PROMPT: 5,
  DOMAIN_GENERAL_DATA_PROMPT: 6,
  DOMAIN_PARENT_KEYWORDS_PROMPT: 7,
  DOMAIN_COMPETING_WEBSITES_PROMPT: 8,
  DOMAIN_TARGET_PAGES_PROMPT: 9,
  DOMAIN_INCENTIVE_PROMPT: 10,
  INTERNAL_OUTREACH_DOMAIN_PROMPT: 11,
  INTERNAL_LINK_INSERTION_PRICING_PROMPT: 12,
  INTERNAL_GUEST_POSTING_PRICING_PROMPT: 13,
  INTERNAL_OUTREACH_CONVERSATION_SUMMARY_PROMPT: 14
};

/**
 * @enum {number}
 */
proto.common.AiPromptReplyTone = {
  NOT_INTERESTED: 0,
  NEUTRAL: 1,
  INTERESTED: 2
};

/**
 * @enum {number}
 */
proto.common.AiConversationRelatedType = {
  CAMPAIGN_AGENT: 0
};

/**
 * @enum {number}
 */
proto.common.AiConversationMessageRole = {
  ASSISTANT: 0,
  USER: 1
};

/**
 * @enum {number}
 */
proto.common.CampaignAutomationStrategy = {
  LINK_BUILDING: 0
};

/**
 * @enum {number}
 */
proto.common.CampaignAutomationAction = {
  CA_CAMPAIGN_CREATED: 0,
  CA_CAMPAIGN_LAUNCHED: 1,
  CA_EMAIL_REPLIED: 2,
  CA_PIPELINE_FINISHED: 3,
  CA_CAMPAIGN_VERIFICATION_FINISHED: 4
};

/**
 * @enum {number}
 */
proto.common.CampaignAutomationAutonomyLevel = {
  REQUIRES_APPROVAL: 0,
  FULLY_AUTONOMOUS: 1
};

/**
 * @enum {number}
 */
proto.common.OnNotInterestedReplyAction = {
  DO_NOTHING: 0,
  ARCHIVE_THREAD: 1
};

/**
 * @enum {number}
 */
proto.common.CampaignAutomationAgentInstructionType = {
  LINK_BUILDING_GENERAL: 0,
  LINK_BUILDING_PARENT_KEYWORDS: 1,
  LINK_BUILDING_COMPETING_WEBSITES: 2,
  LINK_BUILDING_TARGET_PAGES: 3,
  LINK_BUILDING_INCENTIVE: 4
};

goog.object.extend(exports, proto.common);
