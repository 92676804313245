import streamRequest from '@api/base/streamRequest';
import request from '@api/base/request';
import campaignService from '@api/services/campaignsService';

import { OpportunityContactContainerType, OpportunityPersonContactType } from '@ts/contact.types';
import {
  OpportunityShortType,
  OpportunityDetailsType,
  OpportunityPageType,
  CampaignOpportunityFilterType,
} from '@ts/opportunity.types';
import {
  FolderType,
  CampaignEmailSuggestionType,
  IdResponseType,
  FileResponseType,
} from '@ts/common.types';
import {
  OpenCampaignType,
  CampaignType,
  CampaignShortcutType,
  CampaignValidationType,
  CampaignLaunchType,
  CampaignOpportunitiesStats,
  CampaignDuplicateResultType,
} from '@ts/campaigns.types';
import { ChangeCampaignStatusType } from '@ts/apiModels.types';
import { SearchItemType } from '@ts/contentSearch.types';
import processStreamRequest from '@api/base/processStreamRequest';

import { ExportPeopleToCSVApiOptionsType } from '@api/people.api';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { TemplateStep, TemplateStepThread } from 'respona_api/generated/mailbox-template_pb';

import { ArticleItemDetails } from 'respona_api/generated/search-content_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';
import CampaignModels, {
  AddCampaignOpportunityListResponse,
  BatchOpportunitiesRequest,
  CampaignCreateRequest,
  CampaignCrudRequest,
  CampaignFilter,
  CampaignFilterField,
  CampaignFilterStatusMap,
  CampaignLaunchRequest,
  CampaignOpportunityVerificationRequest,
  CampaignTemplateCreateRequest,
  CampaignTemplateDetailsResponse,
  CampaignTemplateInputRequest,
  CampaignTemplatePreviewResponse,
  OpportunityArticleMetrics,
  OpportunityAudioEpisodeMetrics,
  OpportunityAudioPodcastMetrics,
  OpportunityBacklinksMetrics,
  OpportunityFilter,
  OpportunityFilterField,
  UpdateOpportunityUrlRequest,
  CampaignBatchOperations,
  CampaignDuplicateRequest,
  CampaignDeleteRequest,
} from 'respona_api/generated/campaign_pb';
import * as common_pb from 'respona_api/generated/common_pb';
import CommonModels, {
  FilterJoinType,
  FilterOperation,
  FilterOperationMap,
  StringListContainer,
} from 'respona_api/generated/common_pb';

const updateCampaignCrudRequest = ({
  id,
  workspaceId,
  title,
  settings,
}: CampaignCrudRequest.AsObject): CampaignCrudRequest => {
  const campaignCrudRequest = new CampaignModels.CampaignCrudRequest();

  if (id) {
    campaignCrudRequest.setId(id);
  }
  campaignCrudRequest.setTitle(title);
  campaignCrudRequest.setWorkspaceId(workspaceId);

  const campaignSettings = new CampaignModels.CampaignSettings();

  campaignSettings.setTrackOpens(settings.trackOpens);
  campaignSettings.setSendingStrategy(settings.sendingStrategy);
  campaignSettings.setSendingPriority(settings.sendingPriority);
  campaignSettings.setSenderMailboxIdList(
    settings.senderMailboxIdList.filter((mailboxId: number | string) => mailboxId !== 'select-all')
  );
  campaignSettings.setCcEmailsList(settings.ccEmailsList);
  campaignSettings.setBccEmailsList(settings.bccEmailsList);
  campaignSettings.setScheduleId(settings.scheduleId);
  campaignSettings.setNotRepliedStrategyBufferDays(settings.notRepliedStrategyBufferDays);
  campaignSettings.setContactedPastDaysNumber(settings.contactedPastDaysNumber);
  campaignSettings.setEnabledTrackingInPastDays(settings.enabledTrackingInPastDays);
  campaignSettings.setEnabledTrackingInOtherActiveCampaign(
    settings.enabledTrackingInOtherActiveCampaign
  );

  campaignCrudRequest.setSettings(campaignSettings);

  return campaignCrudRequest;
};

const createPageRequest = (workspaceId: number, folderId: number, page = 0, limit = 10) => {
  const campaignRequest = new CommonModels.PageRequest();

  campaignRequest.setPage(page);
  campaignRequest.setLimit(limit);
  campaignRequest.setOptionalWorkspaceId(workspaceId);
  if (folderId) {
    campaignRequest.setOptionalFolderId(folderId);
  }

  return campaignRequest;
};

const createPrefilledMetricsModel = (searchResult: ArticleItemDetails.AsObject) => {
  const metrics = new OpportunityArticleMetrics();
  metrics.setDomainRating(searchResult.domainRating);
  metrics.setRank(searchResult.rank);
  metrics.setDomainTraffic(searchResult.domainTraffic);
  metrics.setDomainTrafficValue(searchResult.domainTrafficValue);
  metrics.setDomainTrafficKeywordsNumber(searchResult.domainTrafficKeywordsNumber);
  metrics.setReferringDomains(searchResult.referringDomains);
  metrics.setBacklinksTotal(searchResult.backlinksTotal);
  metrics.setBacklinksNofollow(searchResult.backlinksNofollow);
  metrics.setBacklinksDofollow(searchResult.backlinksDofollow);
  metrics.setBacklinksOther(searchResult.backlinksOther);

  return metrics;
};

export const getCampaignShortcutsApi = (
  workspaceId: number,
  searchKey: string = '',
  page: number = 0,
  limit: number = 50
): Promise<CampaignShortcutType[]> => {
  const service = campaignService.getInstance();
  const campaignsShortcutsRequest = new CampaignModels.CampaignShortcutsRequest();

  campaignsShortcutsRequest.setWorkspaceId(workspaceId);
  campaignsShortcutsRequest.setPage(page);
  campaignsShortcutsRequest.setLimit(limit);
  if (searchKey?.length > 0) {
    campaignsShortcutsRequest.setOptionalCampaignTitle(searchKey);
  }

  return streamRequest<CampaignShortcutType[]>(
    service,
    service.getCampaignShortcutsPageable,
    campaignsShortcutsRequest
  );
};

export const getDeletedCampaignApi = (
  workspaceId: number,
  page = 0,
  limit = 10,
  folderId?: number
): Promise<CampaignType[]> => {
  const service = campaignService.getInstance();
  const campaignRequest = new CommonModels.PageRequest();

  campaignRequest.setPage(page);
  campaignRequest.setLimit(limit);
  campaignRequest.setOptionalWorkspaceId(workspaceId);
  if (folderId) {
    campaignRequest.setOptionalFolderId(folderId);
  }

  return streamRequest<CampaignType[]>(service, service.getCampaignsFromTrash, campaignRequest);
};

export const getCampaignsByFiltersApi = (
  titleQueryString: string,
  ownersIds: number[],
  dateRange: { startDate: number; endDate: number },
  workspaceId: number,
  page: number,
  limit: number,
  folderId: number,
  status?: CampaignFilterStatusMap[keyof CampaignFilterStatusMap],
  extendedFilters?: CampaignFilter.AsObject[]
): Promise<CampaignType[]> => {
  const service = campaignService.getInstance();
  const filteredCampaignRequest = new CampaignModels.CampaignRequest();

  // if (!!status) {
  //   filteredCampaignRequest.setStatus(status);
  // } else {
  //   filteredCampaignRequest.setStatus(0);
  // }
  //
  // filteredCampaignRequest.setOwnerIdsList(ownersIds);

  // if (dateRange) {
  //   const timeStamp = new CommonModels.TimestampRange();
  //   timeStamp.setFrom(dateRange.startDate);
  //   timeStamp.setTo(dateRange.endDate);
  //
  //   filteredCampaignRequest.setDateRange(timeStamp);
  // }

  filteredCampaignRequest.setWorkspaceId(workspaceId);
  filteredCampaignRequest.setPage(page);
  filteredCampaignRequest.setLimit(limit);

  if (folderId) {
    const filter = new CampaignFilter();
    filter.setJoin(FilterJoinType.JOIN_QUERY_AND);
    filter.setField(CampaignFilterField.DROPDOWN_CAMPAIGN_FOLDER_ID);
    filter.setOrder(0);
    filter.setValue(folderId.toString());
    filter.setOperation(FilterOperation.NUMBER_EQUAL);

    filteredCampaignRequest.addOptionalFilters(filter);
  }

  if (extendedFilters?.length) {
    filteredCampaignRequest.setOptionalFiltersList([]);

    extendedFilters.forEach((item) => {
      const filter = new CampaignFilter();
      filter.setJoin(item.join);
      filter.setField(item.field);
      filter.setOrder(item.order);
      filter.setValue(item.value.toString());
      filter.setOperation(item.operation);

      filteredCampaignRequest.addOptionalFilters(filter);
    });
  }

  if (titleQueryString) {
    const titleQueryStringFilter = new CampaignFilter();
    titleQueryStringFilter.setJoin(0);
    titleQueryStringFilter.setField(0);
    titleQueryStringFilter.setValue(titleQueryString);
    titleQueryStringFilter.setOperation(2);

    filteredCampaignRequest.addOptionalFilters(titleQueryStringFilter);
  }

  return streamRequest<CampaignType[]>(service, service.getCampaigns, filteredCampaignRequest);
};

export const duplicateCampaignApi = (
  campaignId: number,
  workspaceId: number,
  folderId?: number
): Promise<CampaignDuplicateResultType[]> => {
  const service = campaignService.getInstance();
  const duplicateCampaignRequest = new CampaignDuplicateRequest();
  duplicateCampaignRequest.setCampaignId(campaignId);
  duplicateCampaignRequest.setWorkspaceId(workspaceId);
  if (folderId > 0) {
    duplicateCampaignRequest.setOptionalFolderId(folderId);
  }

  return streamRequest<CampaignDuplicateResultType[]>(
    service,
    service.duplicateCampaign,
    duplicateCampaignRequest
  );
};

export const createCampaignApi = (
  workspaceId: number,
  folderId?: number
): Promise<OpenCampaignType> => {
  const service = campaignService.getInstance();
  const createCampaignRequest = new CampaignCreateRequest();

  createCampaignRequest.setWorkspaceId(workspaceId);
  if (folderId != null && folderId > 0) {
    createCampaignRequest.setOptionalFolderId(folderId);
  }

  return request<OpenCampaignType>(service, service.createCampaign, createCampaignRequest);
};

export const changeCampaignStatusApi = (
  campaignId: number,
  workspaceId: number,
  status: 0 | 1 | 2 | 3 | 4 | 5
): Promise<ChangeCampaignStatusType> => {
  const service = campaignService.getInstance();
  const changeCampaignStatusRequest = new CampaignModels.ChangeCampaignStatus();

  changeCampaignStatusRequest.setId(campaignId);
  changeCampaignStatusRequest.setWorkspaceId(workspaceId);
  changeCampaignStatusRequest.setStatus(status);

  return request<ChangeCampaignStatusType>(
    service,
    service.changeCampaignStatus,
    changeCampaignStatusRequest
  );
};

export const updateCampaignApi = (
  campaignInfo: CampaignCrudRequest.AsObject
): Promise<OpenCampaignType> => {
  const service = campaignService.getInstance();
  const createCampaignRequest = updateCampaignCrudRequest(campaignInfo);

  return request<OpenCampaignType>(service, service.updateCampaign, createCampaignRequest);
};

export const deleteCampaignApi = (
  campaignId: number,
  workspaceId: number,
  hardDelete: boolean
): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new CampaignDeleteRequest();

  idRequest.setId(campaignId);
  idRequest.setWorkspaceId(workspaceId);
  idRequest.setHardDelete(hardDelete);

  return request<void>(service, service.deleteCampaign, idRequest);
};

export const restoreCampaignApi = (campaignId: number, workspaceId: number): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.restoreCampaign, idRequest);
};

export const batchCampaignOperationsApi = (
  workspaceId: number,
  action: CampaignBatchOperations.CampaignBatchActionMap[keyof CampaignBatchOperations.CampaignBatchActionMap],
  campaignIds: number[],
  destinationId?: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const batchRequest = new CampaignModels.CampaignBatchOperations();

  batchRequest.setWorkspaceId(workspaceId);
  batchRequest.setAction(action);
  batchRequest.setCampaignIdsList(campaignIds);
  if (destinationId && destinationId > 0) {
    batchRequest.setOptDestinationId(destinationId);
  }

  return request<void>(service, service.batchCampaignOperations, batchRequest);
};

export const addOpportunityApi = (
  campaignId: number,
  workspaceId: number,
  lastBatch: number,
  {
    uid,
    title,
    url,
    type,
    description,
    ahrefBacklinks: ahrefsBacklinks,
    semrushBacklinks,
    googleSearch,
    bingSearch,
    bingNews,
    podcast,
    episode,
  }: Partial<SearchItemType>
): Promise<IdResponseType> => {
  const service = campaignService.getInstance();
  const opportunityModel = new CampaignModels.AddCampaignOpportunityRequest();

  opportunityModel.setCampaignId(campaignId);
  opportunityModel.setWorkspaceId(workspaceId);
  opportunityModel.setUid(uid);
  opportunityModel.setType(type as 0 | 2 | 1 | 3 | 4 | 5 | 6 | 7);
  opportunityModel.setUrl(url);
  opportunityModel.setTitle(title);
  opportunityModel.setBatch(lastBatch);

  if (googleSearch) {
    opportunityModel.setGoogleSearch(createPrefilledMetricsModel(googleSearch));
  }
  if (bingNews) {
    opportunityModel.setBingNews(createPrefilledMetricsModel(bingNews));
  }
  if (bingSearch) {
    opportunityModel.setBingSearch(createPrefilledMetricsModel(bingSearch));
  }

  if (ahrefsBacklinks) {
    const ahrefsBacklinksModel = new OpportunityBacklinksMetrics();

    ahrefsBacklinksModel.setFirstSeenDate(ahrefsBacklinks.firstSeenDate);
    ahrefsBacklinksModel.setUrlTo(ahrefsBacklinks.urlTo);
    ahrefsBacklinksModel.setTextPre(ahrefsBacklinks.textPre);
    ahrefsBacklinksModel.setAnchor(ahrefsBacklinks.anchor);
    ahrefsBacklinksModel.setTextPost(ahrefsBacklinks.textPost);
    ahrefsBacklinksModel.setNoFollow(ahrefsBacklinks.noFollow);
    ahrefsBacklinksModel.setDomainRating(ahrefsBacklinks.domainRating);
    ahrefsBacklinksModel.setDomainTraffic(ahrefsBacklinks.domainTraffic);
    ahrefsBacklinksModel.setDomainTrafficValue(ahrefsBacklinks.domainTrafficValue);
    ahrefsBacklinksModel.setDomainTrafficKeywordsNumber(
      ahrefsBacklinks.domainTrafficKeywordsNumber
    );
    ahrefsBacklinksModel.setReferringDomains(ahrefsBacklinks.referringDomains);
    ahrefsBacklinksModel.setBacklinksTotal(ahrefsBacklinks.backlinksTotal);
    ahrefsBacklinksModel.setBacklinksNofollow(ahrefsBacklinks.backlinksNofollow);
    ahrefsBacklinksModel.setBacklinksDofollow(ahrefsBacklinks.backlinksDofollow);
    ahrefsBacklinksModel.setBacklinksOther(ahrefsBacklinks.backlinksOther);

    opportunityModel.setAhrefBacklinks(ahrefsBacklinksModel);
  }

  if (semrushBacklinks) {
    const semrushBacklinksModel = new OpportunityBacklinksMetrics();

    semrushBacklinksModel.setFirstSeenDate(semrushBacklinks.firstSeenDate);
    semrushBacklinksModel.setUrlTo(semrushBacklinks.urlTo);
    semrushBacklinksModel.setTextPre(semrushBacklinks.textPre);
    semrushBacklinksModel.setAnchor(semrushBacklinks.anchor);
    semrushBacklinksModel.setTextPost(semrushBacklinks.textPost);
    semrushBacklinksModel.setNoFollow(semrushBacklinks.noFollow);
    semrushBacklinksModel.setDomainRating(semrushBacklinks.domainRating);
    semrushBacklinksModel.setDomainTraffic(semrushBacklinks.domainTraffic);
    semrushBacklinksModel.setDomainTrafficValue(semrushBacklinks.domainTrafficValue);
    semrushBacklinksModel.setDomainTrafficKeywordsNumber(
      semrushBacklinks.domainTrafficKeywordsNumber
    );
    semrushBacklinksModel.setReferringDomains(semrushBacklinks.referringDomains);
    semrushBacklinksModel.setBacklinksTotal(semrushBacklinks.backlinksTotal);
    semrushBacklinksModel.setBacklinksNofollow(semrushBacklinks.backlinksNofollow);
    semrushBacklinksModel.setBacklinksDofollow(semrushBacklinks.backlinksDofollow);
    semrushBacklinksModel.setBacklinksOther(semrushBacklinks.backlinksOther);

    opportunityModel.setSemrushBacklinks(semrushBacklinksModel);
  }

  if (podcast) {
    const podcastModel = new OpportunityAudioPodcastMetrics();

    podcastModel.setPodcastUid(podcast.podcastUid);
    podcastModel.setRrsUrl(podcast.rrsUrl);
    podcastModel.setImageUrl(podcast.imageUrl);
    podcastModel.setITunesUrl(podcast.iTunesUrl);
    podcastModel.setPublishingDate(podcast.publishingDate);
    podcastModel.setPublisherName(podcast.publisherName);
    podcastModel.setListenScore(podcast.listenScore);
    podcastModel.setGlobalRank(podcast.globalRank);
    podcastModel.setTotalEpisodes(podcast.totalEpisodes);
    podcastModel.setDescription(description);
    podcastModel.setPublisherEmail(podcast.publisherEmail);

    opportunityModel.setPodcast(podcastModel);
  }

  if (episode) {
    const episodeModel = new OpportunityAudioEpisodeMetrics();

    episodeModel.setPodcastUid(episode.podcastUid);
    episodeModel.setRrsUrl(episode.rrsUrl);
    episodeModel.setImageUrl(episode.imageUrl);
    episodeModel.setITunesUrl(episode.iTunesUrl);
    episodeModel.setPublishingDate(episode.publishingDate);
    episodeModel.setPublisherName(episode.publisherName);
    episodeModel.setListenScore(episode.listenScore);
    episodeModel.setGlobalRank(episode.globalRank);
    episodeModel.setAudioUrl(episode.audioUrl);
    episodeModel.setDescription(description);
    episodeModel.setPublisherEmail(episode.publisherEmail);

    opportunityModel.setEpisode(episodeModel);
  }

  return request<IdResponseType>(service, service.addOpportunity, opportunityModel);
};

export const addOpportunitiesBatchApi = (
  campaignId: number,
  workspaceId: number,
  lastBatch: number,
  opportunities: SearchItemType[]
): Promise<AddCampaignOpportunityListResponse.AsObject> => {
  const service = campaignService.getInstance();
  const batchOpportunityModel = new CampaignModels.AddCampaignOpportunityListRequest();

  batchOpportunityModel.setCampaignId(campaignId);
  batchOpportunityModel.setWorkspaceId(workspaceId);
  batchOpportunityModel.setBatch(lastBatch);
  batchOpportunityModel.setItemsList(
    opportunities.map(
      ({
        uid,
        url,
        title,
        type,
        semrushBacklinks,
        ahrefBacklinks: ahrefsBacklinks,
        googleSearch,
        bingNews,
        bingSearch,
        episode,
        podcast,
        description,
      }) => {
        const batchItemModel = new CampaignModels.AddCampaignOpportunityListRequest.Opportunity();

        batchItemModel.setUid(uid);
        batchItemModel.setUrl(url);
        batchItemModel.setTitle(title);
        batchItemModel.setType(type);

        if (googleSearch) {
          batchItemModel.setGoogleSearch(createPrefilledMetricsModel(googleSearch));
        }
        if (bingNews) {
          batchItemModel.setBingNews(createPrefilledMetricsModel(bingNews));
        }
        if (bingSearch) {
          batchItemModel.setBingSearch(createPrefilledMetricsModel(bingSearch));
        }

        if (ahrefsBacklinks) {
          const ahrefsBacklinksModel = new OpportunityBacklinksMetrics();

          ahrefsBacklinksModel.setFirstSeenDate(ahrefsBacklinks.firstSeenDate);
          ahrefsBacklinksModel.setUrlTo(ahrefsBacklinks.urlTo);
          ahrefsBacklinksModel.setTextPre(ahrefsBacklinks.textPre);
          ahrefsBacklinksModel.setAnchor(ahrefsBacklinks.anchor);
          ahrefsBacklinksModel.setTextPost(ahrefsBacklinks.textPost);
          ahrefsBacklinksModel.setNoFollow(ahrefsBacklinks.noFollow);
          ahrefsBacklinksModel.setDomainRating(ahrefsBacklinks.domainRating);
          ahrefsBacklinksModel.setDomainTraffic(ahrefsBacklinks.domainTraffic);
          ahrefsBacklinksModel.setDomainTrafficValue(ahrefsBacklinks.domainTrafficValue);
          ahrefsBacklinksModel.setDomainTrafficKeywordsNumber(
            ahrefsBacklinks.domainTrafficKeywordsNumber
          );
          ahrefsBacklinksModel.setReferringDomains(ahrefsBacklinks.referringDomains);
          ahrefsBacklinksModel.setBacklinksTotal(ahrefsBacklinks.backlinksTotal);
          ahrefsBacklinksModel.setBacklinksNofollow(ahrefsBacklinks.backlinksNofollow);
          ahrefsBacklinksModel.setBacklinksDofollow(ahrefsBacklinks.backlinksDofollow);
          ahrefsBacklinksModel.setBacklinksOther(ahrefsBacklinks.backlinksOther);

          batchItemModel.setAhrefBacklinks(ahrefsBacklinksModel);
        }

        if (semrushBacklinks) {
          const semrushBacklinksModel = new OpportunityBacklinksMetrics();

          semrushBacklinksModel.setFirstSeenDate(semrushBacklinks.firstSeenDate);
          semrushBacklinksModel.setUrlTo(semrushBacklinks.urlTo);
          semrushBacklinksModel.setTextPre(semrushBacklinks.textPre);
          semrushBacklinksModel.setAnchor(semrushBacklinks.anchor);
          semrushBacklinksModel.setTextPost(semrushBacklinks.textPost);
          semrushBacklinksModel.setNoFollow(semrushBacklinks.noFollow);
          semrushBacklinksModel.setDomainRating(semrushBacklinks.domainRating);
          semrushBacklinksModel.setDomainTraffic(semrushBacklinks.domainTraffic);
          semrushBacklinksModel.setDomainTrafficValue(semrushBacklinks.domainTrafficValue);
          semrushBacklinksModel.setDomainTrafficKeywordsNumber(
            semrushBacklinks.domainTrafficKeywordsNumber
          );
          semrushBacklinksModel.setReferringDomains(semrushBacklinks.referringDomains);
          semrushBacklinksModel.setBacklinksTotal(semrushBacklinks.backlinksTotal);
          semrushBacklinksModel.setBacklinksNofollow(semrushBacklinks.backlinksNofollow);
          semrushBacklinksModel.setBacklinksDofollow(semrushBacklinks.backlinksDofollow);
          semrushBacklinksModel.setBacklinksOther(semrushBacklinks.backlinksOther);

          batchItemModel.setSemrushBacklinks(semrushBacklinksModel);
        }

        if (podcast) {
          const podcastModel = new OpportunityAudioPodcastMetrics();

          podcastModel.setPodcastUid(podcast.podcastUid);
          podcastModel.setRrsUrl(podcast.rrsUrl);
          podcastModel.setImageUrl(podcast.imageUrl);
          podcastModel.setITunesUrl(podcast.iTunesUrl);
          podcastModel.setPublishingDate(podcast.publishingDate);
          podcastModel.setPublisherName(podcast.publisherName);
          podcastModel.setListenScore(podcast.listenScore);
          podcastModel.setGlobalRank(podcast.globalRank);
          podcastModel.setTotalEpisodes(podcast.totalEpisodes);
          podcastModel.setDescription(description);
          podcastModel.setPublisherEmail(podcast.publisherEmail);

          batchItemModel.setPodcast(podcastModel);
        }

        if (episode) {
          const episodeModel = new OpportunityAudioEpisodeMetrics();

          episodeModel.setPodcastUid(episode.podcastUid);
          episodeModel.setRrsUrl(episode.rrsUrl);
          episodeModel.setImageUrl(episode.imageUrl);
          episodeModel.setITunesUrl(episode.iTunesUrl);
          episodeModel.setPublishingDate(episode.publishingDate);
          episodeModel.setPublisherName(episode.publisherName);
          episodeModel.setListenScore(episode.listenScore);
          episodeModel.setGlobalRank(episode.globalRank);
          episodeModel.setAudioUrl(episode.audioUrl);
          episodeModel.setDescription(description);
          episodeModel.setPublisherEmail(episode.publisherEmail);

          batchItemModel.setEpisode(episodeModel);
        }

        return batchItemModel;
      }
    )
  );

  return request<AddCampaignOpportunityListResponse.AsObject>(
    service,
    service.addListOpportunities,
    batchOpportunityModel
  );
};

export const removeOpportunityApi = (
  campaignId: number,
  workspaceId: number,
  batch: number,
  opportunityUid: string
): Promise<void> => {
  const service = campaignService.getInstance();
  const opportunityModel = new CampaignModels.RemoveOpportunityRequest();

  opportunityModel.setOpportunityUid(opportunityUid);
  opportunityModel.setWorkspaceId(workspaceId);
  opportunityModel.setCampaignId(campaignId);
  opportunityModel.setBatch(batch);

  return request<void>(service, service.removeOpportunity, opportunityModel);
};

export const removeOpportunitiesBatchApi = (
  campaignId: number,
  workspaceId: number,
  batch: number,
  removingIds: number[] = [],
  extendedFilters: CampaignOpportunityFilterType[] = []
): Promise<void> => {
  const service = campaignService.getInstance();
  const opportunityBatchModel = new CampaignModels.BatchOpportunitiesRequest();

  opportunityBatchModel.setCampaignId(campaignId);
  opportunityBatchModel.setWorkspaceId(workspaceId);
  opportunityBatchModel.setBatch(batch);

  if (removingIds?.length > 0) {
    opportunityBatchModel.setOptionalSelectedList(removingIds);
    opportunityBatchModel.setType(BatchOpportunitiesRequest.OperationType.SELECTED);
  } else if (extendedFilters.length > 0) {
    opportunityBatchModel.setOptionalFiltersList([]);

    extendedFilters.forEach((item) => {
      const filter = new OpportunityFilter();
      filter.setJoin(item.join);
      filter.setField(item.field);
      filter.setOrder(item.order);
      filter.setOperation(item.operation);

      if (
        item.field === OpportunityFilterField.OPPORTUNITY_REVIEWED_STATE ||
        item.field === OpportunityFilterField.OPPORTUNITY_TYPE
      ) {
        // @ts-ignore
        filter.setValue(item.value.value);
      } else if (
        item.field === OpportunityFilterField.OPPORTUNITY_CONTACT_EMAIL &&
        (item.operation === FilterOperation.STRING_IS_BLANK ||
          item.operation === FilterOperation.STRING_IS_NOT_BLANK)
      ) {
        filter.setField(OpportunityFilterField.OPPORTUNITY_CONTACTS_EXISTS);
        filter.setOperation(FilterOperation.STRING_EQUAL);
        filter.setValue(item.operation === FilterOperation.STRING_IS_BLANK ? 'false' : 'true');
      } else {
        filter.setValue(item.value);
      }

      opportunityBatchModel.addOptionalFilters(filter);
    });
    opportunityBatchModel.setType(BatchOpportunitiesRequest.OperationType.FILTERED);
  } else {
    opportunityBatchModel.setType(BatchOpportunitiesRequest.OperationType.ALL);
  }

  return request<void>(service, service.removeListOpportunities, opportunityBatchModel);
};

export const getCampaignOpportunitiesApi = (
  campaignId: number,
  workspaceId: number,
  page: number,
  limit: number,
  lastBatch?: number,
  extendedFilters?: CampaignOpportunityFilterType[]
): Promise<OpportunityPageType[]> => {
  const service = campaignService.getInstance();
  const opportunityModel = new CampaignModels.GetCampaignOpportunities();

  opportunityModel.setCampaignId(campaignId);
  opportunityModel.setWorkspaceId(workspaceId);

  opportunityModel.setPage(page);
  opportunityModel.setLimit(limit);

  if (lastBatch > 0) {
    opportunityModel.setBatch(lastBatch);
  } else {
    opportunityModel.setLaunched(true);
  }

  if (extendedFilters?.length) {
    opportunityModel.setOptionalFiltersList([]);

    extendedFilters
      .filter((item) => {
        if (item.field === OpportunityFilterField.OPPORTUNITY_CONTACT_EMAIL) {
          return true;
        }
        if (item.field === OpportunityFilterField.OPPORTUNITY_CONTACTS_EXISTS) {
          return true;
        }
        if (typeof item.value === 'string') {
          return item.value.trim().length > 0;
        }
        if (
          item.value != null &&
          typeof item.value === 'object' &&
          Object.prototype.hasOwnProperty.call(item.value, 'value')
        ) {
          const { value } = item.value;
          if (typeof value === 'number') {
            return value > 0;
          }
          if (!Number.isNaN(Number(value))) {
            return Number(value) > 0;
          }
        }
        return false;
      })
      .forEach((item) => {
        const filter = new OpportunityFilter();
        filter.setJoin(item.join);
        filter.setField(item.field);
        filter.setOrder(item.order);
        filter.setOperation(item.operation);

        if (
          item.field === OpportunityFilterField.OPPORTUNITY_REVIEWED_STATE ||
          item.field === OpportunityFilterField.OPPORTUNITY_TYPE
        ) {
          // @ts-ignore
          filter.setValue(item.value.value);
        } else if (
          item.field === OpportunityFilterField.OPPORTUNITY_CONTACT_EMAIL &&
          (item.operation === FilterOperation.STRING_IS_BLANK ||
            item.operation === FilterOperation.STRING_IS_NOT_BLANK)
        ) {
          filter.setField(OpportunityFilterField.OPPORTUNITY_CONTACTS_EXISTS);
          filter.setOperation(FilterOperation.STRING_EQUAL);
          filter.setValue(item.operation === FilterOperation.STRING_IS_BLANK ? 'false' : 'true');
        } else {
          filter.setValue(item.value);
        }

        opportunityModel.addOptionalFilters(filter);
      });
  }

  return streamRequest<OpportunityPageType[]>(service, service.getOpportunities, opportunityModel);
};

export const getCampaignOpportunitiesShortApi = (
  campaignId: number,
  workspaceId: number,
  page: number,
  limit: number,
  lastBatch: number,
  extendedFilters?: CampaignOpportunityFilterType[]
): Promise<OpportunityShortType[]> => {
  const service = campaignService.getInstance();
  const opportunityModel = new CampaignModels.GetCampaignOpportunities();

  opportunityModel.setCampaignId(campaignId);
  opportunityModel.setWorkspaceId(workspaceId);

  opportunityModel.setPage(page);
  opportunityModel.setLimit(limit);

  opportunityModel.setBatch(lastBatch);

  if (extendedFilters?.length) {
    opportunityModel.setOptionalFiltersList([]);

    extendedFilters.forEach((item) => {
      const filter = new OpportunityFilter();
      filter.setJoin(item.join);
      filter.setField(item.field);
      filter.setOrder(item.order);
      filter.setOperation(item.operation);

      if (
        item.field === OpportunityFilterField.OPPORTUNITY_VERIFICATION_STATUS ||
        item.field === OpportunityFilterField.OPPORTUNITY_TYPE
      ) {
        // @ts-ignore
        filter.setValue(item.value.value);
      } else {
        filter.setValue(item.value);
      }

      opportunityModel.addOptionalFilters(filter);
    });
  }

  return streamRequest<OpportunityShortType[]>(
    service,
    service.getOpportunitiesShort,
    opportunityModel
  );
};

export const checkContactsExistsByCampaignIdApi = (
  opportunityId: number,
  workspaceId: number,
  lastBatch: number
): Promise<google_protobuf_wrappers_pb.BoolValue> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(opportunityId);
  idRequest.setOptionalWorkspaceId(workspaceId);
  idRequest.setOptionalRelatedId(lastBatch);

  return request<google_protobuf_wrappers_pb.BoolValue>(
    service,
    service.checkContactsExistsByCampaignId,
    idRequest
  );
};

export const checkCampaignHasPerosnalizationsApi = (
  campaignId: number,
  workspaceId: number,
  lastBatch: number
): Promise<google_protobuf_wrappers_pb.BoolValue> => {
  const service = campaignService.getInstance();
  const opportunityModel = new CommonModels.IdRequest();

  opportunityModel.setId(campaignId);
  opportunityModel.setOptionalWorkspaceId(workspaceId);
  opportunityModel.setOptionalRelatedId(lastBatch);

  return request<google_protobuf_wrappers_pb.BoolValue>(
    service,
    service.checkPersonalizationsExistsByCampaignId,
    opportunityModel
  );
};

export const getOpportunityByIdApi = (
  opportunityId: number,
  workspaceId: number,
  campaignId: number
): Promise<OpportunityDetailsType> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(opportunityId);
  idRequest.setOptionalRelatedId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<OpportunityDetailsType>(service, service.getOpportunityDetails, idRequest);
};

export const markOpportunityAsReviewedApi = (
  opportunityId: number,
  workspaceId: number,
  campaignId: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(opportunityId);
  idRequest.setOptionalRelatedId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.markAsReviewed, idRequest);
};

export const moveOpportunityToCampaignApi = (
  opportunityId: number,
  workspaceId: number,
  campaignId: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(opportunityId);
  idRequest.setOptionalRelatedId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.moveToCampaign, idRequest);
};

export const replaceOpportunityUrlByOpportunityIdApi = (
  opportunityId: number,
  workspaceId: number,
  url: string
): Promise<OpportunityShortType> => {
  const service = campaignService.getInstance();
  const requestObj = new UpdateOpportunityUrlRequest();

  requestObj.setId(opportunityId);
  requestObj.setUrl(url);
  requestObj.setWorkspaceId(workspaceId);

  return request<OpportunityShortType>(
    service,
    service.replaceOpportunityUrlByOpportunityId,
    requestObj
  );
};

export const getOpenCampaignApi = (
  campaignId: number,
  workspaceId: number
): Promise<OpenCampaignType> => {
  const service = campaignService.getInstance();
  const openCampaignRequest = new CommonModels.IdRequest();

  openCampaignRequest.setId(campaignId);
  openCampaignRequest.setOptionalWorkspaceId(workspaceId);

  return request<OpenCampaignType>(service, service.openCampaign, openCampaignRequest);
};

export const addContactToOpportunityApi = (
  workspaceId: number,
  opportunityId: number,
  personId: number,
  email: string,
  category: common_pb.FoundAtCategoryMap[keyof common_pb.FoundAtCategoryMap],
  campaignId: number,
  accuracy: number,
  lastBatch: number
): Promise<OpportunityPersonContactType> => {
  const service = campaignService.getInstance();
  const addContactRequest = new CampaignModels.OpportunityContactRequest();
  const emailRequest = new CommonModels.Email();

  emailRequest.setEmail(email);
  emailRequest.setAccuracy(accuracy as 0 | 1 | 2 | 3);

  addContactRequest.setWorkspaceId(workspaceId);
  addContactRequest.setOpportunityId(opportunityId);
  addContactRequest.setPersonId(personId);
  addContactRequest.setEmail(emailRequest);
  addContactRequest.setCategory(category);
  addContactRequest.setCampaignId(campaignId);
  addContactRequest.setBatch(lastBatch);

  return request<OpportunityPersonContactType>(
    service,
    service.addContactToOpportunity,
    addContactRequest
  );
};

export const removeOpportunityContactApi = (
  opportunityId: number,
  workspaceId: number,
  contactId: number
): Promise<OpportunityContactContainerType> => {
  const service = campaignService.getInstance();
  const contactsRequest = new CommonModels.IdRequest();

  contactsRequest.setId(contactId);
  contactsRequest.setOptionalRelatedId(opportunityId);
  contactsRequest.setOptionalWorkspaceId(workspaceId);

  return request<OpportunityContactContainerType>(
    service,
    service.removeContactFromOpportunity,
    contactsRequest
  );
};

export const removeAllContactsByPersonIdAndOpportunityIdApi = (
  opportunityId: number,
  workspaceId: number,
  personId: number
): Promise<OpportunityContactContainerType> => {
  const service = campaignService.getInstance();
  const contactsRequest = new CommonModels.IdRequest();

  contactsRequest.setId(personId);
  contactsRequest.setOptionalRelatedId(opportunityId);
  contactsRequest.setOptionalWorkspaceId(workspaceId);

  return request<OpportunityContactContainerType>(
    service,
    service.removeAllContactsByPersonIdAndOpportunityId,
    contactsRequest
  );
};

export const launchCampaignApi = (
  campaignId: number,
  workspaceId: number,
  type: CampaignLaunchType
): Promise<void> => {
  const service = campaignService.getInstance();
  const launchCampaignRequest = new CampaignLaunchRequest();

  launchCampaignRequest.setId(campaignId);
  launchCampaignRequest.setWorkspaceId(workspaceId);
  launchCampaignRequest.setType(type);

  return request<void>(service, service.launchCampaign, launchCampaignRequest);
};

export const getCampaignsFoldersApi = (
  workspaceId: number,
  page: number,
  limit: number
): Promise<FolderType[]> => {
  const service = campaignService.getInstance();

  return streamRequest<FolderType[]>(
    service,
    service.getAllCampaignFolders,
    createPageRequest(workspaceId, null, page, limit)
  );
};

export const createCampaignsFolderApi = (
  workspaceId: number,
  title?: string
): Promise<FolderType> => {
  const service = campaignService.getInstance();
  const createRequest = new CommonModels.Folder();

  createRequest.setTitle(title || 'New folder');
  createRequest.setRelatedid(workspaceId);

  return request<FolderType>(service, service.createCampaignFolder, createRequest);
};

export const removeCampaignsFolderApi = (
  folderId: number,
  workspaceId: number
): Promise<FolderType> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(folderId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<FolderType>(service, service.deleteCampaignFolder, idRequest);
};

export const updateCampaignsFoldersApi = (folderId: number, title: string): Promise<FolderType> => {
  const service = campaignService.getInstance();
  const folderRequest = new CommonModels.Folder();

  folderRequest.setId(folderId);
  folderRequest.setTitle(title);

  return request(service, service.updateCampaignFolder, folderRequest);
};

export const changeFolderOfCampaignApi = (
  campaignId: number,
  folderId: number,
  workspaceId: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setOptionalRelatedId(folderId);
  idRequest.setId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.changeFolderOfCampaign, idRequest);
};

export const getEmailSuggestionsApi = (
  workspaceId: number
): Promise<CampaignEmailSuggestionType> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(workspaceId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<CampaignEmailSuggestionType>(service, service.getEmailSuggestions, idRequest);
};

export type AddPeopleToCampaignOptionsType = ExportPeopleToCSVApiOptionsType;

export const addPeopleToCampaignApi = (
  campaignId: number,
  peopleIds: number[],
  workspaceId: number,
  options: AddPeopleToCampaignOptionsType
): Promise<void> => {
  const service = campaignService.getInstance();
  const requestContainer = new CampaignModels.AddPeopleToCampaign();

  requestContainer.setPersonIdsList(peopleIds);
  requestContainer.setCampaignId(campaignId);
  requestContainer.setWorkspaceId(workspaceId);

  requestContainer.setUnverifiedEmails(options.setUnverified);
  requestContainer.setWorkEmails(options.setWork);
  requestContainer.setPersonalEmails(options.setPersonal);
  requestContainer.setGenericEmails(options.setGeneric);

  return request<void>(service, service.addPeopleToCampaign, requestContainer);
};

export const changeOwnerOfCampaignApi = (
  campaignId: number,
  newOwnerId: number,
  workspaceId: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const IdContainer = new CommonModels.IdRequest();

  IdContainer.setId(campaignId);
  IdContainer.setOptionalRelatedId(newOwnerId);
  IdContainer.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.changeOwnerOfCampaign, IdContainer);
};

export const runOpportunityVerificationApi = (
  campaignId: number,
  workspaceId: number,
  withContacted: boolean,
  withVariables: boolean,
  withPlaceholder: boolean,
  includedInAnotherCampaign: boolean,
  notIncludedInUnsubscribeList: boolean,
  pastDaysNumber?: number
): Promise<CampaignValidationType> => {
  const service = campaignService.getInstance();
  const validationRequest = new CampaignModels.CampaignValidationRequest();

  validationRequest.setCampaignId(campaignId);
  validationRequest.setWorkspaceId(workspaceId);

  validationRequest.setCheckOnContactedInPast(withContacted);
  // eslint-disable-next-line no-unused-expressions
  withContacted && validationRequest.setOptionalContactedInPastDaysNumber(pastDaysNumber);

  validationRequest.setCheckOnEmptyVariables(withVariables);
  validationRequest.setCheckOnPlaceholderBrackets(withPlaceholder);
  validationRequest.setCheckOnExistingInOtherActiveCampaign(includedInAnotherCampaign);
  validationRequest.setCheckOnUnsubscribeList(notIncludedInUnsubscribeList);

  return request<CampaignValidationType>(
    service,
    service.runOpportunityVerification,
    validationRequest
  );
};

export const getOpportunityVerificationProgressApi = (
  campaignId: number,
  workspaceId: number,
  processCallback: (response) => void
): Promise<CampaignValidationType[]> => {
  const service = campaignService.getInstance();
  const idRequest = new common_pb.IdRequest();

  idRequest.setId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return processStreamRequest<CampaignValidationType[]>(
    service,
    service.getOpportunityVerificationProgress,
    idRequest,
    processCallback
  );
};

export const stopOpportunityVerificationApi = (
  campaignId: number,
  workspaceId: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new common_pb.IdRequest();

  idRequest.setId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.stopOpportunityVerification, idRequest);
};

export const resetOpportunityVerificationApi = (
  campaignId: number,
  workspaceId: number
): Promise<void> => {
  const service = campaignService.getInstance();
  const idRequest = new common_pb.IdRequest();

  idRequest.setId(campaignId);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.resetOpportunityVerification, idRequest);
};

export const changeNotifyStateForOpportunityVerificationApi = (
  campaignId: number,
  workspaceId: number,
  batch: number
): Promise<{ value: boolean }> => {
  const service = campaignService.getInstance();
  const opportunityVerificationRequest = new CampaignOpportunityVerificationRequest();

  opportunityVerificationRequest.setCampaignId(campaignId);
  opportunityVerificationRequest.setWorkspaceId(workspaceId);
  opportunityVerificationRequest.setBatch(batch);

  return request<{ value: boolean }>(
    service,
    service.changeNotifyStateForOpportunityVerification,
    opportunityVerificationRequest
  );
};

export const exportListOpportunityVerificationResultApi = (
  campaignId: number,
  workspaceId: number,
  batch: number
): Promise<FileResponseType> => {
  const service = campaignService.getInstance();
  const exportRequest = new CampaignOpportunityVerificationRequest();

  exportRequest.setCampaignId(campaignId);
  exportRequest.setWorkspaceId(workspaceId);
  exportRequest.setBatch(batch);

  return request(service, service.exportListOpportunityVerificationResult, exportRequest);
};

export const exportListOpportunitiesApi = (
  campaignId: number,
  workspaceId: number,
  launched: boolean,
  batch: number,
  opportunitiesList?: number[],
  extendedFilters?: CampaignOpportunityFilterType[]
): Promise<FileResponseType> => {
  const service = campaignService.getInstance();
  const exportRequest = new CampaignModels.BatchOpportunitiesRequest();

  exportRequest.setCampaignId(campaignId);
  exportRequest.setWorkspaceId(workspaceId);
  exportRequest.setBatch(batch);
  exportRequest.setLaunched(launched);

  if (opportunitiesList?.length) {
    exportRequest.setOptionalSelectedList(opportunitiesList);
    exportRequest.setType(BatchOpportunitiesRequest.OperationType.SELECTED);
  } else if (extendedFilters?.length) {
    exportRequest.setOptionalFiltersList([]);

    extendedFilters.forEach((item) => {
      const filter = new OpportunityFilter();
      filter.setJoin(item.join);
      filter.setField(item.field);
      filter.setOrder(item.order);
      filter.setOperation(item.operation);

      if (
        item.field === OpportunityFilterField.OPPORTUNITY_VERIFICATION_STATUS ||
        item.field === OpportunityFilterField.OPPORTUNITY_TYPE
      ) {
        // @ts-ignore
        filter.setValue(item.value.value);
      } else {
        filter.setValue(item.value);
      }

      exportRequest.addOptionalFilters(filter);
    });
    exportRequest.setType(BatchOpportunitiesRequest.OperationType.FILTERED);
  } else {
    exportRequest.setType(BatchOpportunitiesRequest.OperationType.ALL);
  }

  return request(service, service.exportListOpportunities, exportRequest);
};

export const getAllCampaignTemplatesApi = (): Promise<
  CampaignTemplatePreviewResponse.AsObject[]
> => {
  const service = campaignService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest(service, service.getAllCampaignTemplates, emptyRequest);
};

export const getCampaignTemplateDetailsApi = (
  id: number,
  workspaceId: number
): Promise<CampaignTemplateDetailsResponse.AsObject> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(id);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request(service, service.getCampaignTemplateDetails, idRequest);
};

export const changeFavoriteStateForCampaignTemplateApi = (
  id: number,
  workspaceId: number
): Promise<{ value: boolean }> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(id);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request(service, service.changeFavoriteStateForCampaignTemplate, idRequest);
};

export const getAIKeywordSuggestionsApi = (
  value: string,
  workspaceId: number
): Promise<StringListContainer.AsObject> => {
  const service = campaignService.getInstance();
  const aiRequest = new common_pb.StringRequest();

  aiRequest.setValue(value);
  aiRequest.setWorkspaceId(workspaceId);

  return request(service, service.getAIKeywordSuggestions, aiRequest);
};

export const createCampaignFromTemplateApi = (
  templateId: number,
  workspaceId: number,
  sequenceSteps: TemplateStep.AsObject[],
  variables: Partial<CampaignTemplateInputRequest.AsObject>[],
  folderId?: number
): Promise<{ id: number }> => {
  const service = campaignService.getInstance();
  const campaignRequest = new CampaignTemplateCreateRequest();

  campaignRequest.setId(templateId);
  campaignRequest.setWorkspaceId(workspaceId);

  if (folderId != null && folderId > 0) {
    campaignRequest.setOptionalFolderId(folderId);
  }

  campaignRequest.setSequenceStepsList(
    sequenceSteps.map((item) => {
      const step = new TemplateStep();

      step.setId(item.id);
      step.setTemplateId(item.templateId);
      step.setOrder(item.order);
      step.setSendDelay(item.sendDelay);
      step.setEnabledThreads(item.enabledThreads);

      const threadA = new TemplateStepThread();

      threadA.setSameThread(item.threadA.sameThread);
      threadA.setContent(item.threadA.content);
      threadA.setSubject(item.threadA.subject);
      threadA.setAttachmentsList([]);

      step.setThreadA(threadA);

      if (item.threadB) {
        const threadB = new TemplateStepThread();

        threadB.setSameThread(item.threadB.sameThread);
        threadB.setContent(item.threadB.content);
        threadB.setSubject(item.threadB.subject);
        threadB.setAttachmentsList([]);

        step.setThreadB(threadB);
      }

      return step;
    })
  );

  campaignRequest.setInputsList(
    variables.map((item) => {
      const input = new CampaignTemplateInputRequest();

      input.setValue(item.value);
      input.setType(item.type);
      input.setVariable(item.variable);
      input.setOptional(item.optional);

      return input;
    })
  );

  return request(service, service.createCampaignFromTemplate, campaignRequest);
};

export const getOpportunitiesStatsApi = (
  campaignId: number,
  workspaceId: number,
  batch: number
): Promise<CampaignOpportunitiesStats> => {
  const service = campaignService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(campaignId);
  idRequest.setOptionalRelatedId(batch);
  idRequest.setOptionalWorkspaceId(workspaceId);

  return request<CampaignOpportunitiesStats>(service, service.getOpportunitiesStat, idRequest);
};
