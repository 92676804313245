import React, { useEffect, useState } from 'react';
import ConfigurableTable from '@uikit/ConfigurableTable/ConfigurableTable';
import { useInfiniteQuery } from 'react-query';
import { getCampaignAutomationActivitiesApi } from '@api/campaignAutomation.api';
import queryCacheKeys from '@constants/queryCacheKeys.constants';
import { useSelector } from 'react-redux';
import usePagination from '@hooks/usePagination';
import Pagination from '@uikit/Pagination/Pagination';

import {
  CampaignAutomationActivityType,
  CampaignAutomationDetailsType,
} from '@ts/automationCampaigns.types';
import Loader from '@uikit/Loader/Loader';
import { useRefCallback } from 'src/helpers/refHelpers';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import tableConfig from './tableConfig';

import './AiAgentActivityLogTable.css';

function AiAgentActivityLogTable({
  agent,
  sortingSelectedKey,
}: {
  agent?: CampaignAutomationDetailsType;
  sortingSelectedKey: any;
}): JSX.Element {
  const { page, changePage, limit, changeLimit } = usePagination();
  const [loadingRef, setLoadingRef, ready] = useRefCallback<HTMLDivElement>();
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery<CampaignAutomationActivityType[]>(
    [queryCacheKeys.activityLogs, workspaceId, agent?.id, sortingSelectedKey],
    ({ pageParam = 0 }) => getCampaignAutomationActivitiesApi(agent?.id, workspaceId, pageParam, 10),
    {
      getNextPageParam: (lastPage: CampaignAutomationActivityType[], allPages) => {
        if (lastPage.length < 10) return false;

        return allPages.length;
      },
      enabled: agent?.id > 0,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const activities = data?.pages.flat() || [];

  useIntersectionObserver(loadingRef, () => fetchNextPage(), [ready]);

  const [sortingValue, setSortingValue] = useState<string>('');
  const [sortingDirection, setSortingDirection] = useState<string>('none');

  const handleSortClick = (direction: string, key: string): void => {
    if (key === sortingValue && sortingDirection !== 'none') {
      setSortingDirection('none');
      setSortingValue('');
    } else {
      setSortingDirection(direction);
      setSortingValue(key);
    }
  };

  useEffect(() => {
    setSortingValue(sortingSelectedKey);
    setSortingDirection('down');
  }, [sortingSelectedKey]);

  if (isFetchingNextPage || isLoading) {
    return <Loader isLoading withTopMargin />;
  }

  if (hasNextPage) {
    return (
      <div ref={setLoadingRef} style={{ marginBottom: '10px' }}>
        <Loader isLoading withTopMargin />
      </div>
    );
  }

  return (
    <div className="ai-agents-table-wrapper">
      <ConfigurableTable
        config={tableConfig}
        data={activities}
        tableClassName="ai-agents-logs-table"
        renderProps={{
          handleSortClick,
          selectedKey: sortingValue,
          direction: sortingDirection,
        }}
      />
      <Pagination
        curPage={page}
        onChangePage={changePage}
        pageLimit={limit}
        onChangePageLimit={changeLimit}
        totalItems={activities.length && activities[0].totalItems}
      />
    </div>
  );
}

export default AiAgentActivityLogTable;
