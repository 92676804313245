/**
 * NOT CHECKED
 */
import React from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';
import withInlineMargins from '@hocs/withInlineMargins';

import './Spinner.scss';

export const Spinner = ({
  color,
  style,
}: {
  color: string;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <span className="spinner" style={style}>
      <SVGIcon icon="spinner" color={color} />
    </span>
  );
};

export default withInlineMargins(Spinner);
