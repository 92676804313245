import useOutsideClick from '@hooks/useOutsideClick';
import React, { ReactNode, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'class-names';

import ActionsTooltipContent, {
  ConfigType,
} from '@uikit/ActionsTooltipContent/ActionsTooltipContent';
import { SVGIcon } from '@uikit/Icon/Icon';

import './ActionsMenu.scss';
import { createPortal } from 'react-dom';

export enum ActionsIconsTypeEnum {
  ThreeDots = 'actions',
  TwoDots = 'actionsTwoDots',
  Chevron = 'chevron',
  Trash   = 'trash',
}

type ActionsMenuPropsType = {
  id: string | number;
  onRemove?: any;
  onRename?: (string) => void;
  actionsConfig?: ConfigType;
  iconClassName?: string;
  itemTitle?: string;
  tooltipClassName?: string;
  tooltipPlace?: 'top' | 'left' | 'right' | 'bottom';
  children?: ReactNode;
  catchEvents?: boolean;
  isSingleStepRemove?: boolean;
  icon?: ActionsIconsTypeEnum;
  iconSize?: number;
  portalSelector?: string;
  removeActionTitle?: string;
};

function ActionsMenu({
  id,
  onRemove,
  onRename,
  actionsConfig,
  iconClassName = '',
  tooltipClassName = '',
  tooltipPlace = 'top',
  children = null,
  itemTitle = '',
  catchEvents = true,
  isSingleStepRemove = false,
  icon = ActionsIconsTypeEnum.ThreeDots,
  portalSelector = '',
  iconSize,
  removeActionTitle,
}: ActionsMenuPropsType): JSX.Element {
  const tooltipRef = useRef();

  const handleClick = (e) => {
    if (catchEvents) {
      e.stopPropagation();
    }

    ReactTooltip.show(tooltipRef.current);
  };

  const handleHideTooltip = () => {
    // Manualy closing tooltip with mouse in it
    // @ts-ignore
    tooltipRef!.current.mouseOnToolTip = () => false;
    ReactTooltip.hide(tooltipRef.current);
  };

  const cnActionsIcon = cn('actions-menu__icon', {
    [iconClassName]: iconClassName.length !== 0,
  });

  const getTooltip = () => {
    return (
      <ReactTooltip
        ref={tooltipRef}
        className={tooltipClassName || 'react-tooltip react-tooltip--actions'}
        id={String(id)}
        place={tooltipPlace}
        effect="solid"
        event="click"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        {children || (
          <ActionsTooltipContent
            itemTitle={itemTitle}
            onRename={onRename}
            onRemove={onRemove}
            config={actionsConfig}
            onHideTooltip={handleHideTooltip}
            isSingleStepRemove={isSingleStepRemove}
            removeActionTitle={removeActionTitle}
          />
        )}
      </ReactTooltip>
    );
  };

  // useOutsideClick(tooltipRef, () => ReactTooltip.hide());

  return (
    <span
      className="actions-menu"
      onClickCapture={() => ReactTooltip.hide()}
      onClick={handleClick}
      tabIndex={0}
      role="button"
    >
      <span
        className={cnActionsIcon}
        data-for={String(id)}
        data-tip=""
        data-event="click"
        role="button"
        tabIndex={0}
      >
        <SVGIcon icon={icon} size={iconSize} />
      </span>
      {portalSelector
        ? createPortal(getTooltip(), document.querySelector(portalSelector))
        : getTooltip()}
    </span>
  );
}

export default ActionsMenu;
