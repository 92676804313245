// package: automation.campaign
// file: automation-campaign.proto

var automation_campaign_pb = require("./automation-campaign_pb");
var common_pb = require("./common_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CampaignAutomationService = (function () {
  function CampaignAutomationService() {}
  CampaignAutomationService.serviceName = "automation.campaign.CampaignAutomationService";
  return CampaignAutomationService;
}());

CampaignAutomationService.getCampaignAutomations = {
  methodName: "getCampaignAutomations",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: true,
  requestType: automation_campaign_pb.CampaignAutomationRequest,
  responseType: automation_campaign_pb.CampaignAutomationResponse
};

CampaignAutomationService.createCampaignAutomation = {
  methodName: "createCampaignAutomation",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: automation_campaign_pb.CampaignAutomationDetailsRequest
};

CampaignAutomationService.updateCampaignAutomation = {
  methodName: "updateCampaignAutomation",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: automation_campaign_pb.CampaignAutomationDetailsRequest,
  responseType: automation_campaign_pb.CampaignAutomationDetailsRequest
};

CampaignAutomationService.openCampaignAutomation = {
  methodName: "openCampaignAutomation",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: automation_campaign_pb.CampaignAutomationDetailsRequest
};

CampaignAutomationService.changeCampaignAutomationStatus = {
  methodName: "changeCampaignAutomationStatus",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: automation_campaign_pb.CampaignAutomationChangeStatusRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignAutomationService.deleteCampaignAutomation = {
  methodName: "deleteCampaignAutomation",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignAutomationService.launchCampaignAutomation = {
  methodName: "launchCampaignAutomation",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: false,
  requestType: automation_campaign_pb.CampaignAutomationLaunchRequest,
  responseType: google_protobuf_empty_pb.Empty
};

CampaignAutomationService.getCampaignAutomationActivities = {
  methodName: "getCampaignAutomationActivities",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: automation_campaign_pb.CampaignAutomationActivityResponse
};

CampaignAutomationService.generateCampaignAutomationInstructions = {
  methodName: "generateCampaignAutomationInstructions",
  service: CampaignAutomationService,
  requestStream: false,
  responseStream: true,
  requestType: automation_campaign_pb.GenerateInstructionsRequest,
  responseType: automation_campaign_pb.GenerateInstructionsResponse
};

exports.CampaignAutomationService = CampaignAutomationService;

function CampaignAutomationServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CampaignAutomationServiceClient.prototype.getCampaignAutomations = function getCampaignAutomations(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignAutomationService.getCampaignAutomations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.createCampaignAutomation = function createCampaignAutomation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignAutomationService.createCampaignAutomation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.updateCampaignAutomation = function updateCampaignAutomation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignAutomationService.updateCampaignAutomation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.openCampaignAutomation = function openCampaignAutomation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignAutomationService.openCampaignAutomation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.changeCampaignAutomationStatus = function changeCampaignAutomationStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignAutomationService.changeCampaignAutomationStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.deleteCampaignAutomation = function deleteCampaignAutomation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignAutomationService.deleteCampaignAutomation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.launchCampaignAutomation = function launchCampaignAutomation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CampaignAutomationService.launchCampaignAutomation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.getCampaignAutomationActivities = function getCampaignAutomationActivities(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignAutomationService.getCampaignAutomationActivities, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

CampaignAutomationServiceClient.prototype.generateCampaignAutomationInstructions = function generateCampaignAutomationInstructions(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(CampaignAutomationService.generateCampaignAutomationInstructions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.CampaignAutomationServiceClient = CampaignAutomationServiceClient;

