import React, { memo, useEffect, useRef, useState } from 'react';
import cn from 'class-names';

import HowToUseButton from '@uikit/HowToUseButton/HowToUseButton';

import BackArrowButton from '@components/BackArrowButton/BackArrowButton';

import './PageHeader.scss';
import Loader from '@uikit/Loader/Loader';
import Input from '@uikit/Input/Input';
import CrosedIcon from '@uikit/Icon/svgIcons/CrosedIcon';
import { Button } from '@uikit/Button/Button';
import { useHistory } from 'react-router-dom';
import { redirectUserTo } from '@utils/historyHandler';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

import { isSemrushMode } from '@constants/app-modes';

declare const INTEGRATION_MODE: string;

type EditableTitleProps = {
  title: string;
  isEditable: boolean;
  onUpdate: (newTitle: string) => void;
  isUpdating?: boolean;
};

function EditableTitle({ title, isEditable, onUpdate, isUpdating }: EditableTitleProps) {
  const [titlePage, setTitlePage] = useState(title);
  const inputRef = useRef<HTMLInputElement>();
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  useEffect(() => {
    if (titlePage === title) {
      return;
    }
    setTitlePage(title);
  }, [title]);

  const handleBlur = () => {
    if (titlePage === title) {
      return;
    }

    if (titlePage.length === 0) {
      setTitlePage(title);
      return;
    }

    onUpdate(titlePage);
  };

  const adjustedWidth = (pageTitle: string): number => {
    let width = 0;
    if (pageTitle === undefined) return 0;
    for (const char of pageTitle) {
      width += char === char.toUpperCase() ? 1 : 0.9;
    }
    return Math.ceil(width);
  };

  return isEditable ? (
    <>
      <Input
        className="page-header-creation-wrapper__settings-name"
        placeholder="Enter campaign name"
        type="text"
        value={titlePage}
        maxLength={40}
        onBlur={handleBlur}
        onFocus={() => setInputFocused(true)}
        onKeyDown={(e) => e.key === 'Enter' && inputRef.current?.blur()}
        onChange={({ target: { value } }) => setTitlePage(value)}
        afterText={<Loader isLoading={isUpdating} style={{ width: '14px', height: '14px' }} />}
        style={{ width: `${adjustedWidth(titlePage)}` + 'ch' }}
      />

      {titlePage?.length > 39 && inputFocused ? (
        <div className="page-header-creation-wrapper__page-name-length">
          Name should not exceed 40 character
        </div>
      ) : null}
    </>
  ) : (
    <>{title}</>
  );
}

type PageHeaderPropsType = {
  title?: string;
  withBackArrow?: boolean;
  withCloseButton?: boolean;
  backArrowForceLink?: string;
  renderHeaderActions?: (renderProps: any) => JSX.Element;
  renderHeaderTitle?: () => JSX.Element;
  headerActionsProps?: {
    [key: string]: any;
    [key: number]: any;
  };
  children?: any;
  howToLink?: string;
  isEditable?: boolean;
  onUpdate?: (updatedTitle: string) => void;
  activeSwitcher?: boolean;
  switcherHandler?: () => void;
  isDisabledSwitcher?: boolean;
  isFixed?: boolean;
};

function PageHeader({
  title,
  withBackArrow,
  backArrowForceLink,
  withCloseButton,
  renderHeaderActions: RenderHeader = (renderProps) => null,
  renderHeaderTitle: RenderTitle,
  headerActionsProps,
  children,
  howToLink,
  isEditable = false,
  onUpdate,
  activeSwitcher,
  switcherHandler,
  isDisabledSwitcher,
  isFixed,
}: PageHeaderPropsType): JSX.Element {
  const [isToggled, setIsToggled] = useState(false);
  const history = useHistory();

  const updatePageName = (newTitle: string) => {
    if (onUpdate) {
      onUpdate(newTitle);
    }
  };

  const headerTitle = (
    <EditableTitle
      title={title}
      isEditable={isEditable}
      onUpdate={updatePageName}
    />
  );

  return (
    <div className={`page-header ${withCloseButton ? 'page-header__with-close-btn' : ''} ${isFixed ? 'is-fixed' : ''}`}>
      {withCloseButton && (
        <Button
          className="close-btn"
          type="ghost"
          onClick={(event) => {
            event.stopPropagation;
            backArrowForceLink ? redirectUserTo(history, backArrowForceLink) : history.goBack()
          }}
        >
          <CrosedIcon color="#BDBDBD"/>
        </Button>
      )}
      <div
        className={cn('page-header__title', {
          'page-header__title-with-how-to': !!howToLink,
        })}
      >
        {activeSwitcher && (
          <SlideToggler
            value={isToggled}
            isDisabled={isDisabledSwitcher}
            onChange={() => {
              switcherHandler();
              setIsToggled(!isToggled);
            }}
          />
        )}
        {withBackArrow && <BackArrowButton forceLink={backArrowForceLink} />}
        {RenderTitle ? <RenderTitle /> : headerTitle}
        {howToLink && !isSemrushMode(INTEGRATION_MODE) &&  <HowToUseButton style={{ margin: '0 0 4px 19px' }} to={howToLink} />}
      </div>
      <div className="page-header__actions">
        {children || <RenderHeader {...headerActionsProps} />}
      </div>
    </div>
  );
}

export default memo(PageHeader);
