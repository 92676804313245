import GoogleSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/GoogleSVG';
import KeywordsSearcgSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/KeywordsSearcgSVG';
import PodcastSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/PodcastSVG';
import CSVImportSVG from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/CSVImportSVG';
import { SearchModeType } from '@ts/automationContent.types';
import SemrushSearchSvg
  from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/SearchTypesTab/assets/SemrushSearchSvg';
import { isSemrushMode } from '@constants/app-modes';

declare const INTEGRATION_MODE: string;

export type SearchTypesTabItemConfigType = {
  icon: (e?: any) => JSX.Element;
  title: string;
  value: SearchModeType;
};

const config: SearchTypesTabItemConfigType[] = [
  {
    icon: GoogleSVG,
    title: 'Google search',
    value: 0,
  },
  {
    icon: isSemrushMode(INTEGRATION_MODE) ? SemrushSearchSvg : KeywordsSearcgSVG,
    title: 'Backlink search',
    value: 1,
  },
  {
    icon: PodcastSVG,
    title: 'Podcast search',
    value: 2,
  },
  {
    icon: CSVImportSVG,
    title: 'CSV Import',
    value: 3,
  },
];

export default config;
