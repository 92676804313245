/**
 * NOT CHECKED
 */
import React from 'react';

import CampaignEmptyMessageSVG from '@uikit/StubIllustration/_components/CampaignEmptyMessageSVG';
import Button from '@uikit/Button/Button';

import './StubIllustration.scss';

type CampaignsEmptyMessageType = {
  text: string;
  subText?: string;
  buttonText?: string;
  onClickButton: (e) => void;
  IllustrationSVG?: () => JSX.Element;
  isLoading?: boolean;
};

const StubIllustration = ({
  text,
  subText,
  buttonText,
  onClickButton,
  IllustrationSVG,
  isLoading,
}: CampaignsEmptyMessageType) => (
  <div className="campaigns-empty-message">
    {IllustrationSVG ? <IllustrationSVG /> : <CampaignEmptyMessageSVG />}

    <div className="campaigns-empty-message__message">{text}</div>
    <div className="campaigns-empty-message__sub-message">{subText}</div>

    {buttonText ? (
      <Button
        isLoading={isLoading}
        loadingText="Loading..."
        onClick={onClickButton}
        className="campaigns-empty-message__link"
      >
        {buttonText}
      </Button>
    ) : null}
  </div>
);

export default StubIllustration;
