/**
 * NOT CHECKED
 */
import React, { useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';

export default ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}): JSX.Element => {
  const portalEl = document.createElement('div');

  if (portalEl && className) {
    portalEl.classList.add(className);
  }

  useEffect(() => {
    document.body.appendChild(portalEl);

    return () => {
      document.body.removeChild(portalEl);
    };
  }, [children, className]);

  return ReactDOM.createPortal(children, portalEl);
};
