import {
  IntegrationTypeMap,
  IntegrationType as INTEGRATION_TYPE,
} from 'respona_api/generated/common_pb';

declare const IS_DEV: boolean;
declare const IS_STANDALONE_MODE: boolean;
declare const INTEGRATION_URL: string;

const ahrefsLogo = require('@assets/ahrefs-round-icon.png');
const hunterLogo = require('@assets/hunter-round-icon.png');
const snovioLogo = require('@assets/snovio-round-icon.png');
const rocketLogo = require('@assets/rocket-round-icon.png');
const semrushLogo = require('@assets/semrush-logo-square.jpeg');
const mozLogo = require('@assets/moz-round-icon.png');

const redirectDomain = () => {
  if (IS_STANDALONE_MODE) {
    return IS_DEV ? 'https://dev.respona.com' : 'https://app.respona.com';
  }
  return INTEGRATION_URL;
};

const ahrefsDetails = {
  clientId: IS_DEV ? 'Respona-development' : 'Respona',
  secret: IS_DEV ? 'xn8sZGPc7' : 'U3AdR8dmM',
};

type integrationsSettingsMapType = {
  logo?: string;
  link?: string;
  categories?: string[];
};

export const integrationsSettingsMap: {
  [key in IntegrationTypeMap[keyof IntegrationTypeMap]]?: integrationsSettingsMapType;
} = {
  [INTEGRATION_TYPE.UNKNOWN_INTEGRATION]: {},
  [INTEGRATION_TYPE.AHREFS_INTEGRATION]: {
    logo: ahrefsLogo.default,
    link: `https://ahrefs.com/web/oauth/authorize?response_type=code&client_id=${ahrefsDetails.clientId}&scope=api&state=bf29d26710a4b72dc559118667d1fc75&redirect_uri=${redirectDomain()}/settings/integrations/ahrefs?state=123456`,
    categories: ['SEO', 'Ahrefs'],
  },
  [INTEGRATION_TYPE.HUNTER_INTEGRATION]: {
    logo: hunterLogo.default,
    categories: ['SEO', 'Hunter'],
  },
  [INTEGRATION_TYPE.SNOVIO_INTEGRATION]: {
    logo: snovioLogo.default,
    categories: ['SEO', 'Snovio'],
  },
  [INTEGRATION_TYPE.ROCKET_REACH_INTEGRATION]: {
    logo: rocketLogo.default,
    categories: ['SEO', 'Rocket'],
  },
  [INTEGRATION_TYPE.SEMRUSH_INTEGRATION]: {
    logo: semrushLogo.default,
    link: `https://oauth.semrush.com/oauth2/authorize?ref=1530981283&client_id=respona&redirect_uri=${redirectDomain()}/settings/integrations/semrush&response_type=code&scope=domains.info,user.limits,backlinks.info`,
    categories: ['SEO', 'Semrush'],
  },
  [INTEGRATION_TYPE.MOZ_INTEGRATION]: {
    logo: mozLogo.default,
    categories: ['SEO', 'Moz'],
  },
};
