/**
 * NOT CHECKED
 */
import React, { useState } from 'react';
import { nanoid } from 'nanoid';

import {
  contentTypes,
  getMessageReplacements,
  inlineImage,
  sanitizeMimeHtml,
} from '@helpers/emailsHelpers';

import { SVGIcon } from '@uikit/Icon/Icon';
import Button from '@uikit/Button/Button';
import PdfMimeMessage from '@uikit/MimeMessage/_components/PdfMimeMessage/PdfMimeMessage';

import Display from '@components/Display/Display';

import './MimeMessage.scss';

const decodeContent = (content) => {
  return new TextDecoder('utf-8').decode(content);
};

const filterChildNodes = (nodes) => {
  const isHtmlPresent = nodes.find((it) => it.contentType.value === 'text/html');
  if (isHtmlPresent) {
    return nodes.filter((it) => it.contentType.value !== 'text/plain');
  }

  return nodes;
};

const parseQuoteNodes = (nodes) => {
  const baseElems = [];
  const additionalElems = [];
  let meetQuote = false;

  for (let i = 0; i < nodes?.length; i++) {
    if (nodes[i]?.classList?.contains('gmail_quote')) {
      meetQuote = true;
      additionalElems.push(nodes[i]);
      // eslint-disable-next-line no-continue
      continue;
    }
    if (meetQuote) {
      additionalElems.push(nodes[i]);
    } else {
      baseElems.push(nodes[i]);
    }
  }

  return [baseElems, additionalElems];
};

const HTMLMimeMessage = ({
  htmlContent,
  replacements,
}: {
  htmlContent: string;
  replacements: { value: string; name: string }[];
}): JSX.Element => {
  const [isCollapsed, changeIsCollapsed] = useState(true);

  const wrapper = document.createElement('div');
  wrapper.innerHTML = htmlContent;

  const wrapperChildNodes = wrapper.childNodes;
  const [baseNodes, additionalNodes] = parseQuoteNodes(wrapperChildNodes);

  const baseNodesWrapper = document.createElement('div');
  baseNodesWrapper.append(...baseNodes);

  const additionalNodesWrapper = document.createElement('div');
  additionalNodesWrapper.append(...additionalNodes);

  const onToggleIsCollapsed = () => changeIsCollapsed((prevState) => !prevState);

  const handleClickContent = (event) => {
    const possibleLink = event.target.closest('a');

    if (possibleLink && possibleLink.href.startsWith('http')) {
      event.preventDefault();
      window.open(possibleLink.href, '_blank');
    }
  };

  return (
    <div className="mime-html-message" id="mimeMessageContent" onClick={handleClickContent}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeMimeHtml(baseNodesWrapper.outerHTML, undefined, replacements),
        }}
      />
      <Display isVisible={additionalNodes?.length > 0}>
        <Button
          type="ghost"
          onClick={onToggleIsCollapsed}
          className="mime-html-message__show-more-btn"
        >
          <SVGIcon icon="actionHorizontal" />
        </Button>
      </Display>
      <Display isVisible={!isCollapsed}>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeMimeHtml(additionalNodesWrapper.outerHTML, undefined, replacements),
          }}
        />
      </Display>
    </div>
  );
};

const MimeMessage = ({ mimeMessage, replacements = [] }): JSX.Element => {
  if (!mimeMessage) {
    return <div>&#60;Empty content&#62;</div>;
  }

  let replacementsArray;

  if (!replacements?.length) {
    replacementsArray = getMessageReplacements(mimeMessage);
  }

  const renderNodes = (nodes) => {
    return (
      <>
        {nodes.map((node) => (
          <MimeMessage
            key={nanoid()}
            mimeMessage={node}
            replacements={replacements?.length ? replacements : replacementsArray}
          />
        ))}
      </>
    );
  };

  switch (true) {
    case mimeMessage.contentType.value === contentTypes.textHtml:
      return (
        <HTMLMimeMessage
          htmlContent={decodeContent(mimeMessage.content)}
          replacements={replacements}
        />
      );
    case mimeMessage.contentType.value === contentTypes.textPlain:
      return <pre>{decodeContent(mimeMessage.content)}</pre>;
    case mimeMessage.contentType.value === contentTypes.multipartRelated:
      if (mimeMessage?.childNodes?.length > 0) {
        const nodes = filterChildNodes(mimeMessage.childNodes);
        return renderNodes(nodes);
      }

      return <pre>{decodeContent(mimeMessage.content)}</pre>;
    case mimeMessage.contentType.value === contentTypes.multipartReport:
    case mimeMessage.contentType.value === contentTypes.multipartMixed:
    case mimeMessage.contentType.value === contentTypes.multipartAlternative:
      // eslint-disable-next-line no-case-declarations
      const nodes = filterChildNodes(mimeMessage.childNodes);
      return renderNodes(nodes);
    case mimeMessage.contentType.value.startsWith(contentTypes.image):
      if (mimeMessage.headers['content-disposition']?.[0]?.value === 'inline') {
        return null;
      }

      return (
        <img
          alt=""
          style={{ maxWidth: '100%' }}
          src={inlineImage(mimeMessage.content, mimeMessage.contentType.value)}
        />
      );
    case mimeMessage.contentType.value === contentTypes.pdf:
      return (
        <PdfMimeMessage title={mimeMessage.contentType.params.name} content={mimeMessage.content} />
      );
    default:
      const otherContentTypeNodes = filterChildNodes(mimeMessage.childNodes);
      return renderNodes(otherContentTypeNodes);
  }
};

export default MimeMessage;
