/**
 * NOT CHECKED
 */
import React, { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import cn from 'class-names';

import { SVGIcon } from '@uikit/Icon/Icon';
import useHotkeys from '@hooks/useHotkeys';

import './BaseModal.scss';

export type BaseModalType = {
  onClose?: (props?: any) => any;
  isOpen: boolean;
  style?: React.CSSProperties;
  classNames?: {
    closeButton?: string;
    modal?: string;
  };
  iconColor?: string;
  closeButton?: boolean;
  closeOnBackgroundClick?: boolean;
  fitWidthContent?: boolean;
};

const BaseModal = ({
  children,
  isOpen,
  onClose,
  style,
  classNames,
  iconColor,
  closeButton = true,
  closeOnBackgroundClick = true,
  fitWidthContent = false,
}: BaseModalType & { children: ReactNode }) => {
  useHotkeys({
    Escape: () => onClose(),
  });

  if (!isOpen) {
    return null;
  }

  function onBackgroundClick() {
    if (closeOnBackgroundClick && !!onClose) {
      onClose();
    }
  }

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onBackgroundClick} tabIndex={0} role="button">
      <div
        className={cn('modal', {
          [classNames?.modal]: !!classNames?.modal,
          fitWidthContent: fitWidthContent,
        })}
        style={style}
        onClick={(event) => event.stopPropagation()}
      >
        {closeButton && (
          <span
            className={cn('modal__cross-btn', {
              [classNames?.closeButton]: !!classNames?.closeButton,
            })}
            onClick={onClose}
            tabIndex={0}
            role="button"
          >
            <SVGIcon icon="crossBlack" color={iconColor || '#000000'} />
          </span>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
};

export default React.memo<BaseModalType & { children: ReactNode }>(BaseModal);
