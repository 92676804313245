// source: automation-campaign.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationActivityResponse', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationChangeStatusRequest', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationDetailsRequest', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationDetailsRequest.InstructionsCase', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationFilter', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationFilterField', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationLaunchRequest', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationRequest', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationResponse', null, global);
goog.exportSymbol('proto.automation.campaign.CampaignAutomationSettings', null, global);
goog.exportSymbol('proto.automation.campaign.GenerateInstructionsRequest', null, global);
goog.exportSymbol('proto.automation.campaign.GenerateInstructionsResponse', null, global);
goog.exportSymbol('proto.automation.campaign.GenerateInstructionsResponse.DataCase', null, global);
goog.exportSymbol('proto.automation.campaign.LinkBuildingStrategyInstructions', null, global);
goog.exportSymbol('proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.campaign.CampaignAutomationRequest.repeatedFields_, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationRequest.displayName = 'proto.automation.campaign.CampaignAutomationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationFilter.displayName = 'proto.automation.campaign.CampaignAutomationFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationChangeStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationChangeStatusRequest.displayName = 'proto.automation.campaign.CampaignAutomationChangeStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationLaunchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationLaunchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationLaunchRequest.displayName = 'proto.automation.campaign.CampaignAutomationLaunchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.GenerateInstructionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.automation.campaign.GenerateInstructionsResponse.oneofGroups_);
};
goog.inherits(proto.automation.campaign.GenerateInstructionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.GenerateInstructionsResponse.displayName = 'proto.automation.campaign.GenerateInstructionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.GenerateInstructionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.GenerateInstructionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.GenerateInstructionsRequest.displayName = 'proto.automation.campaign.GenerateInstructionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.automation.campaign.CampaignAutomationDetailsRequest.oneofGroups_);
};
goog.inherits(proto.automation.campaign.CampaignAutomationDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationDetailsRequest.displayName = 'proto.automation.campaign.CampaignAutomationDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.campaign.CampaignAutomationSettings.repeatedFields_, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationSettings.displayName = 'proto.automation.campaign.CampaignAutomationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.LinkBuildingStrategyInstructions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.automation.campaign.LinkBuildingStrategyInstructions.repeatedFields_, null);
};
goog.inherits(proto.automation.campaign.LinkBuildingStrategyInstructions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.LinkBuildingStrategyInstructions.displayName = 'proto.automation.campaign.LinkBuildingStrategyInstructions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.displayName = 'proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationResponse.displayName = 'proto.automation.campaign.CampaignAutomationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.automation.campaign.CampaignAutomationActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.automation.campaign.CampaignAutomationActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.automation.campaign.CampaignAutomationActivityResponse.displayName = 'proto.automation.campaign.CampaignAutomationActivityResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.campaign.CampaignAutomationRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionalFiltersList: jspb.Message.toObjectList(msg.getOptionalFiltersList(),
    proto.automation.campaign.CampaignAutomationFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationRequest}
 */
proto.automation.campaign.CampaignAutomationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationRequest;
  return proto.automation.campaign.CampaignAutomationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationRequest}
 */
proto.automation.campaign.CampaignAutomationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = new proto.automation.campaign.CampaignAutomationFilter;
      reader.readMessage(value,proto.automation.campaign.CampaignAutomationFilter.deserializeBinaryFromReader);
      msg.addOptionalFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptionalFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.automation.campaign.CampaignAutomationFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 workspace_id = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationRequest} returns this
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationRequest} returns this
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationRequest} returns this
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CampaignAutomationFilter optional_filters = 4;
 * @return {!Array<!proto.automation.campaign.CampaignAutomationFilter>}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.getOptionalFiltersList = function() {
  return /** @type{!Array<!proto.automation.campaign.CampaignAutomationFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.campaign.CampaignAutomationFilter, 4));
};


/**
 * @param {!Array<!proto.automation.campaign.CampaignAutomationFilter>} value
 * @return {!proto.automation.campaign.CampaignAutomationRequest} returns this
*/
proto.automation.campaign.CampaignAutomationRequest.prototype.setOptionalFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.automation.campaign.CampaignAutomationFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.campaign.CampaignAutomationFilter}
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.addOptionalFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.automation.campaign.CampaignAutomationFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.campaign.CampaignAutomationRequest} returns this
 */
proto.automation.campaign.CampaignAutomationRequest.prototype.clearOptionalFiltersList = function() {
  return this.setOptionalFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    join: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationFilter}
 */
proto.automation.campaign.CampaignAutomationFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationFilter;
  return proto.automation.campaign.CampaignAutomationFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationFilter}
 */
proto.automation.campaign.CampaignAutomationFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.automation.campaign.CampaignAutomationFilterField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {!proto.common.FilterOperation} */ (reader.readEnum());
      msg.setOperation(value);
      break;
    case 5:
      var value = /** @type {!proto.common.FilterJoinType} */ (reader.readEnum());
      msg.setJoin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getJoin();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 order = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationFilter} returns this
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CampaignAutomationFilterField field = 2;
 * @return {!proto.automation.campaign.CampaignAutomationFilterField}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.getField = function() {
  return /** @type {!proto.automation.campaign.CampaignAutomationFilterField} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.automation.campaign.CampaignAutomationFilterField} value
 * @return {!proto.automation.campaign.CampaignAutomationFilter} returns this
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationFilter} returns this
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.FilterOperation operation = 4;
 * @return {!proto.common.FilterOperation}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.getOperation = function() {
  return /** @type {!proto.common.FilterOperation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.FilterOperation} value
 * @return {!proto.automation.campaign.CampaignAutomationFilter} returns this
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.setOperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional common.FilterJoinType join = 5;
 * @return {!proto.common.FilterJoinType}
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.getJoin = function() {
  return /** @type {!proto.common.FilterJoinType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.FilterJoinType} value
 * @return {!proto.automation.campaign.CampaignAutomationFilter} returns this
 */
proto.automation.campaign.CampaignAutomationFilter.prototype.setJoin = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationChangeStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationChangeStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationChangeStatusRequest}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationChangeStatusRequest;
  return proto.automation.campaign.CampaignAutomationChangeStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationChangeStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationChangeStatusRequest}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CampaignAgentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationChangeStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationChangeStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationChangeStatusRequest} returns this
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationChangeStatusRequest} returns this
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.CampaignAgentStatus status = 3;
 * @return {!proto.common.CampaignAgentStatus}
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.common.CampaignAgentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CampaignAgentStatus} value
 * @return {!proto.automation.campaign.CampaignAutomationChangeStatusRequest} returns this
 */
proto.automation.campaign.CampaignAutomationChangeStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationLaunchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationLaunchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationLaunchRequest}
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationLaunchRequest;
  return proto.automation.campaign.CampaignAutomationLaunchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationLaunchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationLaunchRequest}
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationLaunchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationLaunchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationLaunchRequest} returns this
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationLaunchRequest} returns this
 */
proto.automation.campaign.CampaignAutomationLaunchRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.automation.campaign.GenerateInstructionsResponse.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.automation.campaign.GenerateInstructionsResponse.DataCase = {
  DATA_NOT_SET: 0,
  LINK_BUILDING: 3
};

/**
 * @return {proto.automation.campaign.GenerateInstructionsResponse.DataCase}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.getDataCase = function() {
  return /** @type {proto.automation.campaign.GenerateInstructionsResponse.DataCase} */(jspb.Message.computeOneofCase(this, proto.automation.campaign.GenerateInstructionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.GenerateInstructionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.GenerateInstructionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.GenerateInstructionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    linkBuilding: (f = msg.getLinkBuilding()) && proto.automation.campaign.LinkBuildingStrategyInstructions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.GenerateInstructionsResponse}
 */
proto.automation.campaign.GenerateInstructionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.GenerateInstructionsResponse;
  return proto.automation.campaign.GenerateInstructionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.GenerateInstructionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.GenerateInstructionsResponse}
 */
proto.automation.campaign.GenerateInstructionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CampaignAutomationAgentInstructionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.automation.campaign.LinkBuildingStrategyInstructions;
      reader.readMessage(value,proto.automation.campaign.LinkBuildingStrategyInstructions.deserializeBinaryFromReader);
      msg.setLinkBuilding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.GenerateInstructionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.GenerateInstructionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.GenerateInstructionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLinkBuilding();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.automation.campaign.LinkBuildingStrategyInstructions.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool completed = 1;
 * @return {boolean}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.automation.campaign.GenerateInstructionsResponse} returns this
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional common.CampaignAutomationAgentInstructionType type = 2;
 * @return {!proto.common.CampaignAutomationAgentInstructionType}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.getType = function() {
  return /** @type {!proto.common.CampaignAutomationAgentInstructionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CampaignAutomationAgentInstructionType} value
 * @return {!proto.automation.campaign.GenerateInstructionsResponse} returns this
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional LinkBuildingStrategyInstructions link_building = 3;
 * @return {?proto.automation.campaign.LinkBuildingStrategyInstructions}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.getLinkBuilding = function() {
  return /** @type{?proto.automation.campaign.LinkBuildingStrategyInstructions} */ (
    jspb.Message.getWrapperField(this, proto.automation.campaign.LinkBuildingStrategyInstructions, 3));
};


/**
 * @param {?proto.automation.campaign.LinkBuildingStrategyInstructions|undefined} value
 * @return {!proto.automation.campaign.GenerateInstructionsResponse} returns this
*/
proto.automation.campaign.GenerateInstructionsResponse.prototype.setLinkBuilding = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.automation.campaign.GenerateInstructionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.campaign.GenerateInstructionsResponse} returns this
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.clearLinkBuilding = function() {
  return this.setLinkBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.campaign.GenerateInstructionsResponse.prototype.hasLinkBuilding = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.GenerateInstructionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.GenerateInstructionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.GenerateInstructionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optDomain: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.GenerateInstructionsRequest}
 */
proto.automation.campaign.GenerateInstructionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.GenerateInstructionsRequest;
  return proto.automation.campaign.GenerateInstructionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.GenerateInstructionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.GenerateInstructionsRequest}
 */
proto.automation.campaign.GenerateInstructionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CampaignAutomationAgentInstructionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.GenerateInstructionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.GenerateInstructionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.GenerateInstructionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOptDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.GenerateInstructionsRequest} returns this
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.GenerateInstructionsRequest} returns this
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.CampaignAutomationAgentInstructionType type = 3;
 * @return {!proto.common.CampaignAutomationAgentInstructionType}
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.getType = function() {
  return /** @type {!proto.common.CampaignAutomationAgentInstructionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CampaignAutomationAgentInstructionType} value
 * @return {!proto.automation.campaign.GenerateInstructionsRequest} returns this
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string opt_domain = 4;
 * @return {string}
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.getOptDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.GenerateInstructionsRequest} returns this
 */
proto.automation.campaign.GenerateInstructionsRequest.prototype.setOptDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.oneofGroups_ = [[8]];

/**
 * @enum {number}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.InstructionsCase = {
  INSTRUCTIONS_NOT_SET: 0,
  LINK_BUILDING: 8
};

/**
 * @return {proto.automation.campaign.CampaignAutomationDetailsRequest.InstructionsCase}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getInstructionsCase = function() {
  return /** @type {proto.automation.campaign.CampaignAutomationDetailsRequest.InstructionsCase} */(jspb.Message.computeOneofCase(this, proto.automation.campaign.CampaignAutomationDetailsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threadId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    strategy: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    settings: (f = msg.getSettings()) && proto.automation.campaign.CampaignAutomationSettings.toObject(includeInstance, f),
    linkBuilding: (f = msg.getLinkBuilding()) && proto.automation.campaign.LinkBuildingStrategyInstructions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationDetailsRequest;
  return proto.automation.campaign.CampaignAutomationDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadId(value);
      break;
    case 5:
      var value = /** @type {!proto.common.CampaignAutomationStrategy} */ (reader.readEnum());
      msg.setStrategy(value);
      break;
    case 6:
      var value = /** @type {!proto.common.CampaignAgentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new proto.automation.campaign.CampaignAutomationSettings;
      reader.readMessage(value,proto.automation.campaign.CampaignAutomationSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 8:
      var value = new proto.automation.campaign.LinkBuildingStrategyInstructions;
      reader.readMessage(value,proto.automation.campaign.LinkBuildingStrategyInstructions.deserializeBinaryFromReader);
      msg.setLinkBuilding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreadId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.automation.campaign.CampaignAutomationSettings.serializeBinaryToWriter
    );
  }
  f = message.getLinkBuilding();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.automation.campaign.LinkBuildingStrategyInstructions.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 workspace_id = 2;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thread_id = 4;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getThreadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.CampaignAutomationStrategy strategy = 5;
 * @return {!proto.common.CampaignAutomationStrategy}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getStrategy = function() {
  return /** @type {!proto.common.CampaignAutomationStrategy} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.CampaignAutomationStrategy} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional common.CampaignAgentStatus status = 6;
 * @return {!proto.common.CampaignAgentStatus}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getStatus = function() {
  return /** @type {!proto.common.CampaignAgentStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.CampaignAgentStatus} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional CampaignAutomationSettings settings = 7;
 * @return {?proto.automation.campaign.CampaignAutomationSettings}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getSettings = function() {
  return /** @type{?proto.automation.campaign.CampaignAutomationSettings} */ (
    jspb.Message.getWrapperField(this, proto.automation.campaign.CampaignAutomationSettings, 7));
};


/**
 * @param {?proto.automation.campaign.CampaignAutomationSettings|undefined} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
*/
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LinkBuildingStrategyInstructions link_building = 8;
 * @return {?proto.automation.campaign.LinkBuildingStrategyInstructions}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.getLinkBuilding = function() {
  return /** @type{?proto.automation.campaign.LinkBuildingStrategyInstructions} */ (
    jspb.Message.getWrapperField(this, proto.automation.campaign.LinkBuildingStrategyInstructions, 8));
};


/**
 * @param {?proto.automation.campaign.LinkBuildingStrategyInstructions|undefined} value
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
*/
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.setLinkBuilding = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.automation.campaign.CampaignAutomationDetailsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.automation.campaign.CampaignAutomationDetailsRequest} returns this
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.clearLinkBuilding = function() {
  return this.setLinkBuilding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.automation.campaign.CampaignAutomationDetailsRequest.prototype.hasLinkBuilding = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.campaign.CampaignAutomationSettings.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    scheduleId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    autonomyLevel: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notificationEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mailboxAccountIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    mailboxTemplateId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    metrics: jspb.Message.getFieldWithDefault(msg, 6, 0),
    inspectionInstructionsList: jspb.Message.toObjectList(msg.getInspectionInstructionsList(),
    common_pb.InstructionPair.toObject, includeInstance),
    assignInterestedToUserId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    onNotInterestedAction: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationSettings}
 */
proto.automation.campaign.CampaignAutomationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationSettings;
  return proto.automation.campaign.CampaignAutomationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationSettings}
 */
proto.automation.campaign.CampaignAutomationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CampaignAutomationAutonomyLevel} */ (reader.readEnum());
      msg.setAutonomyLevel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotificationEmail(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setMailboxAccountIdsList(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMailboxTemplateId(value);
      break;
    case 6:
      var value = /** @type {!proto.common.ContentMetricsType} */ (reader.readEnum());
      msg.setMetrics(value);
      break;
    case 7:
      var value = new common_pb.InstructionPair;
      reader.readMessage(value,common_pb.InstructionPair.deserializeBinaryFromReader);
      msg.addInspectionInstructions(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAssignInterestedToUserId(value);
      break;
    case 9:
      var value = /** @type {!proto.common.OnNotInterestedReplyAction} */ (reader.readEnum());
      msg.setOnNotInterestedAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScheduleId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAutonomyLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNotificationEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMailboxAccountIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getMailboxTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMetrics();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getInspectionInstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_pb.InstructionPair.serializeBinaryToWriter
    );
  }
  f = message.getAssignInterestedToUserId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getOnNotInterestedAction();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional int64 schedule_id = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getScheduleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setScheduleId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CampaignAutomationAutonomyLevel autonomy_level = 2;
 * @return {!proto.common.CampaignAutomationAutonomyLevel}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getAutonomyLevel = function() {
  return /** @type {!proto.common.CampaignAutomationAutonomyLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CampaignAutomationAutonomyLevel} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setAutonomyLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string notification_email = 3;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getNotificationEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setNotificationEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int64 mailbox_account_ids = 4;
 * @return {!Array<number>}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getMailboxAccountIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setMailboxAccountIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.addMailboxAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.clearMailboxAccountIdsList = function() {
  return this.setMailboxAccountIdsList([]);
};


/**
 * optional int64 mailbox_template_id = 5;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getMailboxTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setMailboxTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional common.ContentMetricsType metrics = 6;
 * @return {!proto.common.ContentMetricsType}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getMetrics = function() {
  return /** @type {!proto.common.ContentMetricsType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.common.ContentMetricsType} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setMetrics = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated common.InstructionPair inspection_instructions = 7;
 * @return {!Array<!proto.common.InstructionPair>}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getInspectionInstructionsList = function() {
  return /** @type{!Array<!proto.common.InstructionPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.InstructionPair, 7));
};


/**
 * @param {!Array<!proto.common.InstructionPair>} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
*/
proto.automation.campaign.CampaignAutomationSettings.prototype.setInspectionInstructionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.InstructionPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.InstructionPair}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.addInspectionInstructions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.InstructionPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.clearInspectionInstructionsList = function() {
  return this.setInspectionInstructionsList([]);
};


/**
 * optional int64 assign_interested_to_user_id = 8;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getAssignInterestedToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setAssignInterestedToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional common.OnNotInterestedReplyAction on_not_interested_action = 9;
 * @return {!proto.common.OnNotInterestedReplyAction}
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.getOnNotInterestedAction = function() {
  return /** @type {!proto.common.OnNotInterestedReplyAction} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.common.OnNotInterestedReplyAction} value
 * @return {!proto.automation.campaign.CampaignAutomationSettings} returns this
 */
proto.automation.campaign.CampaignAutomationSettings.prototype.setOnNotInterestedAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.LinkBuildingStrategyInstructions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    websiteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    websiteDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    linksNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    parentKeywordsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    competingWebsitesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    targetPageToPreferredAnchorList: jspb.Message.toObjectList(msg.getTargetPageToPreferredAnchorList(),
    proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.toObject, includeInstance),
    incentive: jspb.Message.getFieldWithDefault(msg, 8, ""),
    additionalNotes: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.LinkBuildingStrategyInstructions;
  return proto.automation.campaign.LinkBuildingStrategyInstructions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinksNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addParentKeywords(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addCompetingWebsites(value);
      break;
    case 7:
      var value = new proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor;
      reader.readMessage(value,proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.deserializeBinaryFromReader);
      msg.addTargetPageToPreferredAnchor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncentive(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.LinkBuildingStrategyInstructions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWebsiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWebsiteDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLinksNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getParentKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getCompetingWebsitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getTargetPageToPreferredAnchorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.serializeBinaryToWriter
    );
  }
  f = message.getIncentive();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdditionalNotes();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetPage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preferredAnchor: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor;
  return proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetPage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredAnchor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetPage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreferredAnchor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string target_page = 1;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.prototype.getTargetPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.prototype.setTargetPage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string preferred_anchor = 2;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.prototype.getPreferredAnchor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor.prototype.setPreferredAnchor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string website_domain = 1;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getWebsiteDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setWebsiteDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string website_name = 2;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getWebsiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setWebsiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string website_description = 3;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getWebsiteDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setWebsiteDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 links_number = 4;
 * @return {number}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getLinksNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setLinksNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string parent_keywords = 5;
 * @return {!Array<string>}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getParentKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setParentKeywordsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.addParentKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.clearParentKeywordsList = function() {
  return this.setParentKeywordsList([]);
};


/**
 * repeated string competing_websites = 6;
 * @return {!Array<string>}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getCompetingWebsitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setCompetingWebsitesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.addCompetingWebsites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.clearCompetingWebsitesList = function() {
  return this.setCompetingWebsitesList([]);
};


/**
 * repeated TargetPageToPreferredAnchor target_page_to_preferred_anchor = 7;
 * @return {!Array<!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor>}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getTargetPageToPreferredAnchorList = function() {
  return /** @type{!Array<!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor, 7));
};


/**
 * @param {!Array<!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor>} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
*/
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setTargetPageToPreferredAnchorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.addTargetPageToPreferredAnchor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.automation.campaign.LinkBuildingStrategyInstructions.TargetPageToPreferredAnchor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.clearTargetPageToPreferredAnchorList = function() {
  return this.setTargetPageToPreferredAnchorList([]);
};


/**
 * optional string incentive = 8;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getIncentive = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setIncentive = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string additional_notes = 9;
 * @return {string}
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.getAdditionalNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.LinkBuildingStrategyInstructions} returns this
 */
proto.automation.campaign.LinkBuildingStrategyInstructions.prototype.setAdditionalNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    strategy: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    campaignsTotal: jspb.Message.getFieldWithDefault(msg, 5, 0),
    campaignsLaunched: jspb.Message.getFieldWithDefault(msg, 6, 0),
    opportunitiesTotal: jspb.Message.getFieldWithDefault(msg, 7, 0),
    opportunitiesContacted: jspb.Message.getFieldWithDefault(msg, 8, 0),
    emailsSent: jspb.Message.getFieldWithDefault(msg, 9, 0),
    emailsReplied: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 12, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationResponse}
 */
proto.automation.campaign.CampaignAutomationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationResponse;
  return proto.automation.campaign.CampaignAutomationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationResponse}
 */
proto.automation.campaign.CampaignAutomationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.CampaignAutomationStrategy} */ (reader.readEnum());
      msg.setStrategy(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CampaignAgentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCampaignsTotal(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCampaignsLaunched(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesTotal(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesContacted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmailsSent(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmailsReplied(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedBy(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCampaignsTotal();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCampaignsLaunched();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOpportunitiesTotal();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOpportunitiesContacted();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEmailsSent();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getEmailsReplied();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCreatedBy();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional common.CampaignAutomationStrategy strategy = 2;
 * @return {!proto.common.CampaignAutomationStrategy}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getStrategy = function() {
  return /** @type {!proto.common.CampaignAutomationStrategy} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.CampaignAutomationStrategy} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.CampaignAgentStatus status = 3;
 * @return {!proto.common.CampaignAgentStatus}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.CampaignAgentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CampaignAgentStatus} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 campaigns_total = 5;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getCampaignsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setCampaignsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 campaigns_launched = 6;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getCampaignsLaunched = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setCampaignsLaunched = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 opportunities_total = 7;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getOpportunitiesTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setOpportunitiesTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 opportunities_contacted = 8;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getOpportunitiesContacted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setOpportunitiesContacted = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 emails_sent = 9;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getEmailsSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setEmailsSent = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 emails_replied = 10;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getEmailsReplied = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setEmailsReplied = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 created_at = 11;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 created_by = 12;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getCreatedBy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 total_items = 13;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationResponse} returns this
 */
proto.automation.campaign.CampaignAutomationResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.automation.campaign.CampaignAutomationActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.automation.campaign.CampaignAutomationActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    summary: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creditsUsed: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.automation.campaign.CampaignAutomationActivityResponse;
  return proto.automation.campaign.CampaignAutomationActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.automation.campaign.CampaignAutomationActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.CampaignAutomationAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsUsed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.automation.campaign.CampaignAutomationActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.automation.campaign.CampaignAutomationActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.automation.campaign.CampaignAutomationActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreditsUsed();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional common.CampaignAutomationAction action = 1;
 * @return {!proto.common.CampaignAutomationAction}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.getAction = function() {
  return /** @type {!proto.common.CampaignAutomationAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.CampaignAutomationAction} value
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse} returns this
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string summary = 2;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse} returns this
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse} returns this
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 credits_used = 4;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.getCreditsUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse} returns this
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.setCreditsUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse} returns this
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_items = 6;
 * @return {number}
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.automation.campaign.CampaignAutomationActivityResponse} returns this
 */
proto.automation.campaign.CampaignAutomationActivityResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.automation.campaign.CampaignAutomationFilterField = {
  STRING_AGENT_TITLE: 0,
  DROPDOWN_AGENT_OWNER_ID: 1,
  DROPDOWN_AGENT_STATUS: 2,
  DATE_AGENT_CREATED_AT: 3
};

goog.object.extend(exports, proto.automation.campaign);
