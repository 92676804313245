
/**
 * if there is at least one field which has a value false (false/0/''/undefined/null)
 * then it returns false. it works recursively
 *
 * @param obj
 * @param excludedFields
 */
export const isAllFieldsFull = (obj, excludedFields = []) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (excludedFields.includes(key)) {
      continue;
    }

    const value = obj[key];

    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return false;
      }

      for (const item of value) {
        if (typeof item === 'object' && !isAllFieldsFull(item)) {
          return false;
        }
      }
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      if (Object.keys(value).length === 0 || !isAllFieldsFull(value)) {
        return false;
      }
    }
  }

  return true;
};
