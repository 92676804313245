/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './AutosaveIndicator.scss';
import { SVGIcon } from '../Icon/Icon';

const AutosaveIndicator = ({
  isSaving,
  errorMessage,
  className = '',
}: {
  isSaving?: boolean;
  errorMessage?: string;
  className?: string;
}): JSX.Element => {
  const cnAutosaveIndicator = cn('autosave-indicator', {
    [className]: className.length > 0,
  });

  if (isSaving) {
    return (
      <div className={cnAutosaveIndicator}>
        <div className="autosave-indicator__spinner" />
      </div>
    );
  }

  if (errorMessage && errorMessage.length) {
    return <div className={`${cnAutosaveIndicator} autosave-indicator--error`}>{errorMessage}</div>;
  }

  return (
    <div className={`${cnAutosaveIndicator} autosave-indicator--saved`}>
      <span className="autosave-indicator__icon">
        <SVGIcon icon="check" color="#23E771" />
      </span>
      Saved
    </div>
  );
};

export default AutosaveIndicator;
