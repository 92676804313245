import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useExitIntent } from 'use-exit-intent';
import { useSelector } from 'react-redux';

import './CalendlyPopup.scss';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import { calendlyStatusTypes } from '@constants/onboardingPopupStatuses';
import { OnboardingSessionFlow } from '@pages/LoginPage/_components/LoginPageFlows';
import { LoginPageGradient } from '@pages/LoginPage/_components/LoginPageGradient';

import { setOnboardingStep } from '@api/analytics.api';
import { getItemFromStorage } from '@utils/localStorageHandler';
// @ts-ignore
import responaLogo from '../../assets/ResponaLogoLarge.svg';

const DELAY_POPUP = 30_000;

const OnboardingCallPopup = (): JSX.Element => {
  const calendlyStatus = () => getItemFromStorage('showCalendly', calendlyStatusTypes.NONE);
  const [isCalendlyShown, setIsCalendlyShown] = useState(false);
  const [isCalendlyLoading, setIsCalendlyLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const setupDemo = useRef<boolean>(false);

  const { registerHandler, resetState } = useExitIntent({
    desktop: {
      triggerOnIdle: true,
      useBeforeUnload: false,
      triggerOnMouseLeave: true,
    },
    mobile: {
      triggerOnIdle: true,
    },
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      setVisibility(true);
      resetState();
    }, DELAY_POPUP);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  if (visibility) {
    registerHandler({
      id: 'exitIntent',
      handler: () => {
        const status = calendlyStatus();
        if (status === calendlyStatusTypes.NONE) {
          return;
        }
        if (status === calendlyStatusTypes.AWAITING) {
          setIsCalendlyLoading(true);
        }
      },
    });
  }

  const { info: userProfileInfo } = useSelector(userProfileSelector);

  const onClose = () => {
    setIsCalendlyShown(false);
  };

  const composedName = useMemo(() => {
    return [userProfileInfo.firstName, userProfileInfo.lastName]
      .filter((it) => it != null && it.length > 0 && it.trim().length > 0)
      .join('%20');
  }, [userProfileInfo, userProfileInfo.firstName, userProfileInfo.lastName]);

  const url = useMemo(() => {
    // return `https://calendly.com/respona/onboarding?hide_gdpr_banner=1&text_color=000000&primary_color=251fb6&name=${composedName}&email=${
    //   userProfileInfo.email
    // }`;
    return `https://respona.ewebinar.com/webinar/start-your-first-respona-campaign-in-30-minutes-19178/register?showform=1&Name=${composedName}&email=${userProfileInfo.email}&proprietaryId=${userProfileInfo.email}`;
  }, [userProfileInfo, userProfileInfo.email, composedName]);

  useEffect(() => {
    const status = calendlyStatus();
    if (status === calendlyStatusTypes.NONE || status === calendlyStatusTypes.COMPLETE) {
      return;
    }
    if (isCalendlyLoading && !isCalendlyShown) {
      setIsCalendlyShown(true);
      setIsCalendlyLoading(false);
      localStorage.setItem('showCalendly', calendlyStatusTypes.COMPLETE);
    }
  }, [isCalendlyShown, isCalendlyLoading]);

  const updateRef = (key: string, value: boolean) => {
    if (key === 'setupDemo') {
      setupDemo.current = value;
    }
  };

  const onSubmit = async () => {
    if (setupDemo.current) {
      window.open(url, '_blank', 'noopener,noreferrer');
      localStorage.setItem('showCalendly', calendlyStatusTypes.COMPLETE);
      await setOnboardingStep(2);
    }

    setIsCalendlyShown(false);
  };

  function OnboardingModal() {
    return (
      <div className="login-page__container">
        <LoginPageGradient />
        <div className="login-page">
          <div className="login-modal">
            <div className="login-container" style={{ height: '565px' }}>
              <img
                alt="Respona"
                onClick={() => window.open('https://respona.com', '_self')}
                src={responaLogo}
                className="login-container__logo"
              />

              <OnboardingSessionFlow
                isLoading={isCalendlyLoading}
                updateRef={updateRef}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(isCalendlyShown && <OnboardingModal />, document.body);
};

export default OnboardingCallPopup;
