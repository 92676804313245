// @ts-nocheck
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { OpenAIModelType, OpenAITone } from 'respona_api/generated/common_pb';

import urls from '@constants/urls';
import Button from '@uikit/Button/Button';
import Spinner from '@uikit/Spinner/Spinner';

import { fetchBillingCredits } from '@redux/thunks/billings.thunks';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import SlideToggler from '@uikit/SlideToggler/SlideToggler';
import Loader from '@uikit/Loader/Loader';

import { DispatchType } from 'src/store';
// eslint-disable-next-line import/order
import AutomationCardsInfo from '../AutomationCardsInfo/AutomationCardsInfo';

import './PersonalizationAutomationSetup.scss';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import { SVGIcon } from '@uikit/Icon/Icon';
import { getOpportunitiesStatsApi } from '@api/campaign.api';
import Select from '@uikit/Select/Select';
import { ContentAutomationSearchContext } from '@pages/OpportunitiesCampaignSettingsPage/OpportunitiesCampaignSettingsPage';
import {
  setPersonalizationAutomationData,
  updateCurrentPersonalizationAutomationData,
} from '@redux/actions/automationPeronalization.actions';
import { personalizationAutomationSelector } from '@redux/selectors/automationPersonalization.selectors';
import PersonalizationInstructionsEditor from '@components/CampaignSettings/ContentSearch/ContentAutomationSearch/_components/PersonalizationInstructionsEditor/PersonalizationInstructionsEditor';
import { buildLaunchPersonalizationAutomationApiRequest } from '@api/personalizationAutomation.api';

import { sequenceAiStepsSelector } from '@redux/selectors/sequence.selectors';
import { buildPipelineApiRequest, launchPipelineApi } from '@api/pipeline.api';
import { CampaignPipelineType } from 'respona_api/generated/pipeline_pb';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';
import useCampaignPipelines from '@hooks/useCampaignPipelines';
import { BillingState } from 'respona_api/generated/billing_pb';

declare const IS_STANDALONE_MODE: boolean;

function PersonalizationAutomationSetup({
  setOpenModal,
  setPaymentSidebarOpen,
  setUpsellCreditsSidebarOpened,
  setUpsellCreditsNotEnough,
}: {
  pipelineId: number;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPaymentSidebarOpen: (boolean) => void;
  setUpsellCreditsSidebarOpened: (boolean) => void;
  setUpsellCreditsNotEnough: (number) => void;
}): JSX.Element {
  const history = useHistory();
  const dispatch: DispatchType = useDispatch();

  const [isAutomationStarting, changeIsAutomationStarting] = useState(false);

  const { settings, setSettings } = useContext(ContentAutomationSearchContext);
  const { info: campaignInfo, loadingStatus: campaignInfoLs } = useSelector(getActiveCampaignInfo);
  const { data: billingDetails } = useSelector(billingDetailsSelector);

  const { data: automationPersonalization } = useSelector(personalizationAutomationSelector);
  const [randomMultiplier, setRandomMultiplier] = useState<number>(null);
  const aiSequenceSteps = useSelector(sequenceAiStepsSelector);
  const { addItem: addPipelineToCache } = useCampaignPipelines(
    campaignInfo.workspaceId,
    campaignInfo.id
  );

  const initialInstructions = [
    'Use short, choppy sentences and paragraphs.',
    'Limit the use of commas.',
    '5th-grade reading level, use simple words and avoid using jargon.',
  ];

  const personalizationToneOptions = [
    { value: OpenAITone.CREATIVE, label: 'Creative' },
    { value: OpenAITone.VERY_CREATIVE, label: 'Very Creative' },
  ];

  const personalizationModeOptions = [
    { value: OpenAIModelType.GPT_CHEAP, label: 'GPT 4o-mini' },
    { value: OpenAIModelType.GPT_EXPENSIVE, label: 'GPT 4o' },
  ];

  useEffect(() => {
    dispatch(
      setPersonalizationAutomationData({
        tone: OpenAITone.CREATIVE,
        model: OpenAIModelType.GPT_EXPENSIVE,
        instructions: initialInstructions,
        includeSummaries: true,
      })
    );

    if (randomMultiplier == null) {
      setRandomMultiplier(1.1 + Math.random() * 0.2);
    }
  }, []);

  const { data: dataOpportunitiesStats, isLoading: isLoadingOpportunitiesStats } = useQuery(
    [
      'opportunities-stats-counter',
      campaignInfo.id,
      campaignInfo.workspaceId,
      campaignInfo.lastBatch,
    ],
    () =>
      getOpportunitiesStatsApi(campaignInfo.id, campaignInfo.workspaceId, campaignInfo.lastBatch),
    {
      enabled: !!campaignInfo.id,
      refetchOnWindowFocus: false,
    }
  );

  const handleRunAutomationClick = (autobuy = false) => {
    if (aiSequenceSteps.length <= 0) {
      dispatch(
        addNotification({
          title: 'Add at least one AI variable to the sequence',
          type: 'warning',
        })
      );
      return;
    }
    if (billingDetails.currentBonusCreditsNumber + billingDetails.currentPaidCreditsNumber < 10) {
      if (billingDetails.state === BillingState.BILLING_TRIAL) {
        setPaymentSidebarOpen(true);
      } else {
        setUpsellCreditsSidebarOpened(true);
        setUpsellCreditsNotEnough(getCardsInfo().secondValue);
      }
      return;
    }

    changeIsAutomationStarting(true);

    launchPipelineApi(
      buildPipelineApiRequest(
        -1,
        campaignInfo.workspaceId,
        campaignInfo.id,
        CampaignPipelineType.PERSONALIZATION_AUTOMATION,
        false,
        -1,
        -1,
        false,
        true,
        false,
        false,
        null,
        null,
        null,
        buildLaunchPersonalizationAutomationApiRequest(
          campaignInfo.id,
          campaignInfo.workspaceId,
          campaignInfo.lastBatch,
          campaignInfo.lastSequenceId,
          automationPersonalization
        )
      )
    )
      .then((response) => {
        addPipelineToCache(response);
        dispatch(fetchBillingCredits());
        setOpenModal(false);
      })
      .catch((error) => {
        console.error(error);
        // TODO when codes and JSON from back will be ready -> need to change to condition by code
        if (error.message.includes('All opportunities has contacts')) {
          dispatch(
            addNotification({ title: 'All opportunities already have contacts', type: 'warning' })
          );
          return;
        }

        if (error.message.includes('Exception while auto-buy')) {
          dispatch(
            addNotification({
              title:
                'There was a problem with processing this transaction, please update your billing info',
              type: 'warning',
              delay: 5000,
            })
          );
          redirectUserTo(history, urls.CHANGE_BILLING);
        }

        if (error.message.includes('credits')) {
          if (billingDetails.state === 6) {
            setPaymentSidebarOpen(true);
          } else {
            setUpsellCreditsSidebarOpened(true);
            setUpsellCreditsNotEnough(getCardsInfo().secondValue);
          }
        }
      });
  };

  const handleSettingsState = (value: string | number | boolean, field: string): void => {
    setSettings((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePersonalizationAutomationState = (value: string[] | number, fieldName) => {
    dispatch(
      updateCurrentPersonalizationAutomationData({
        [fieldName]: value,
      })
    );
  };

  const createFieldChangeHandler = (fieldName) => (value, additionalProps) => {
    if (fieldName === 'notify') {
      handleSettingsState(value, fieldName);
    }
  };

  function composePersonalizationPrice(): number {
    if (dataOpportunitiesStats.withoutPersonalizedVariables <= 0) {
      return 0;
    }
    let pricePerStep = 0;
    if (automationPersonalization.model === OpenAIModelType.GPT_EXPENSIVE) {
      pricePerStep = 5;
    } else if (automationPersonalization.model === OpenAIModelType.GPT_CHEAP) {
      pricePerStep = 1;
    }
    const stepsNumber = aiSequenceSteps.length;
    if (automationPersonalization.includeSummaries) {
      return (
        dataOpportunitiesStats.withoutPersonalizedTextVariables * stepsNumber * (1 + pricePerStep) +
        dataOpportunitiesStats.withoutPersonalizedAudioVariables * stepsNumber * (10 + pricePerStep)
      );
    }

    return dataOpportunitiesStats.withoutPersonalizedVariables * stepsNumber * pricePerStep;
  }

  const getCardsInfo = () => {
    if (dataOpportunitiesStats == null) {
      return {
        secondValue: 0,
      };
    }
    return {
      firstTitle: 'Unpesonalized opportunities',
      firstValue: dataOpportunitiesStats.withoutPersonalizedVariables === 0,
      secondTitle: 'Reserved credits',
      secondValue: composePersonalizationPrice(),
      thirdTitle: 'Time (mins)',
      thirdValue: Math.ceil(dataOpportunitiesStats.withoutPersonalizedVariables * randomMultiplier),
    };
  };

  return (
    <div className="campaign-automation">
      <Loader isLoading={isLoadingOpportunitiesStats}>
        <div className="campaign-automation-content-body">
          <ul className="campaign-automation-content-body__list">
            <li className="campaign-automation-content-body__list-item">
              <div className="campaign-automation-content-body__list-item-label">AI model:</div>
              <Select
                options={personalizationModeOptions}
                value={personalizationModeOptions.find(
                  (it) => it.value === automationPersonalization.model
                )}
                onChange={({ value }) => handlePersonalizationAutomationState(value, 'model')}
              />
            </li>

            {/* <li className="campaign-automation-content-body__list-item"> */}
            {/*  <div className="campaign-automation-content-body__list-item-label"> */}
            {/*    Tone of Voice: */}
            {/*  </div> */}
            {/*  <Select */}
            {/*    options={personalizationToneOptions} */}
            {/*    value={personalizationToneOptions.find( */}
            {/*      (it) => it.value === automationPersonalization.tone */}
            {/*    )} */}
            {/*    onChange={({ value }) => handlePersonalizationAutomationState(value, 'tone')} */}
            {/*  /> */}
            {/* </li> */}
            <li className="campaign-automation-content-body__list-item">
              <div className="campaign-automation-content-body__list-item-label">
                <PersonalizationInstructionsEditor
                  instructions={automationPersonalization.instructions}
                  onChangeInstructions={(value) =>
                    handlePersonalizationAutomationState(value, 'instructions')
                  }
                />
              </div>
            </li>

            <li className="campaign-automation-content-body__list-item">
              <SlideToggler
                value={automationPersonalization.includeSummaries}
                onChange={(value) =>
                  handlePersonalizationAutomationState(value, 'includeSummaries')
                }
              />
              <div className="campaign-automation-content-body__list-item-label">
                Include opportunity summaries in the prompt
                <div className="accordion-content-body-list__item-info">
                  <SVGIcon size={10} icon="wallet" />
                  <span>1-10 credits per opportunity</span>
                </div>
                <QuestionTooltip
                  place="top"
                  icon="questionInCircleFilled"
                  color="#C6C6C6"
                  style={{ marginTop: '2px' }}
                  text={
                    'Including opportunity summaries in the prompt will give the model more context and results in more accurate outputs. \n' +
                    'Websites: 1 credit per summary\n' +
                    'Podcasts: 10 credits per summary'
                  }
                />
              </div>
            </li>
          </ul>
        </div>

        <Loader isLoading={false}>
          <AutomationCardsInfo {...getCardsInfo()} />
        </Loader>

        <div className="campaign-automation-footer">
          {IS_STANDALONE_MODE && (
            <div className="campaign-automation-footer__notify">
              <SlideToggler value={settings.notify} onChange={createFieldChangeHandler('notify')} />
              <div className="campaign-automation-footer__notify-title">
                Email me when the search is complete
              </div>
            </div>
          )}

          {isAutomationStarting ? (
            <Button disabled className="campaign-automation-footer__button">
              <Spinner marginRight={10} color="#fff" />
              <span>Launching</span>
            </Button>
          ) : (
            <Button
              onClick={handleRunAutomationClick}
              className="campaign-automation-footer__button"
              disabled={getCardsInfo().firstValue}
            >
              <span className="campaign-automation-footer__play-icon">
                <SVGIcon icon="playBig" />
              </span>
              <span>Run automation</span>
            </Button>
          )}
        </div>
      </Loader>
    </div>
  );
}

export default PersonalizationAutomationSetup;
