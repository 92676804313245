import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationType } from '@ts/notifications.types';

import { getCurrentVersionApi } from '@api/apiGateway.api';

import { addNotification } from '@redux/actions/notifications.actions';
import { VersionMetadata } from 'respona_api/generated/api-gateway_pb';

const UPDATE_FREQUENCY_MINUTES = 5;

const UPDATE_TYPE = {
  RELEASE: 'RELEASE',
  MAJOR: 'MAJOR',
  MINOR: 'MINOR',
};

const useAppUpdates = (): void => {
  const dispatch = useDispatch();

  const notificationRef = useRef<boolean>(null);
  const intervalRef = useRef<number>(null);

  const getUpdateType = (newVersion: string, currentVersion?: string) => {
    const currentVersionParts = currentVersion?.split('.');
    const newVersionParts = newVersion?.split('.');

    if (!currentVersionParts?.length || !newVersion?.length) {
      return null;
    }

    if (newVersionParts[0] > currentVersionParts[0]) {
      return UPDATE_TYPE.RELEASE;
    }
    if (newVersionParts[1] > currentVersionParts[1]) {
      return UPDATE_TYPE.MAJOR;
    }
    if (newVersionParts[2] > currentVersionParts[2]) {
      return UPDATE_TYPE.MINOR;
    }

    return null;
  };

  const checkAppVersion = async () => {
    try {
      const { version: newVersion } = await getCurrentVersionApi();
      const currentVersion = sessionStorage.getItem('appVersion');

      const notification: Partial<NotificationType> = {
        delay: null,
        dismissible: false,
        onConfirm: () => {
          notificationRef.current = null;
          window.location.reload();
        },
        onDecline: () => {
          setUpdateInterval();
          notificationRef.current = null;
        },
        onConfirmText: 'Refresh',
        onDeclineText: 'Remind later',
      };

      switch (getUpdateType(newVersion, currentVersion)) {
        case UPDATE_TYPE.RELEASE:
          notification.title = `New version ${newVersion} available.`;
          notification.onDecline = null;
          break;
        case UPDATE_TYPE.MAJOR:
          notification.title = 'We have received an update';
          notification.content = (
            <span>
              This includes improvements and bug fixes. Please, save your progress and reload the
              page to ensure the best usage experience.
            </span>
          );
          break;
        case UPDATE_TYPE.MINOR:
          notification.title = 'We have received an update';
          notification.content = (
            <span>
              This includes improvements and bug fixes. Please, save your progress and reload the
              page to ensure the best usage experience.
            </span>
          );
          break;
        default:
          break;
      }

      if (notification.title) {
        dispatch(addNotification(notification));
        notificationRef.current = true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const setUpdateInterval = () => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current);
    }

    intervalRef.current = window.setInterval(() => {
      if (notificationRef.current) {
        return;
      }

      checkAppVersion();
    }, UPDATE_FREQUENCY_MINUTES * 60000);

    return () => window.clearInterval(intervalRef.current);
  };

  useEffect(() => {
    getCurrentVersionApi().then(({ version }: VersionMetadata.AsObject) =>
      sessionStorage.setItem('appVersion', version)
    );

    return setUpdateInterval();
  }, []);
};

export default useAppUpdates;
