// source: pipeline.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var automation$contact_pb = require('./automation-contact_pb.js');
goog.object.extend(proto, automation$contact_pb);
var automation$content_pb = require('./automation-content_pb.js');
goog.object.extend(proto, automation$content_pb);
var automation$personalization_pb = require('./automation-personalization_pb.js');
goog.object.extend(proto, automation$personalization_pb);
var automation$inspection_pb = require('./automation-inspection_pb.js');
goog.object.extend(proto, automation$inspection_pb);
var file$importer_pb = require('./file-importer_pb.js');
goog.object.extend(proto, file$importer_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.campaigns.CampaignPipelineResponse', null, global);
goog.exportSymbol('proto.campaigns.CampaignPipelineType', null, global);
goog.exportSymbol('proto.campaigns.LaunchCampaignPipelineRequest', null, global);
goog.exportSymbol('proto.campaigns.LaunchCampaignPipelineRequest.OpportunityAutomationCase', null, global);
goog.exportSymbol('proto.campaigns.PipelineStepType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.LaunchCampaignPipelineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.campaigns.LaunchCampaignPipelineRequest.oneofGroups_);
};
goog.inherits(proto.campaigns.LaunchCampaignPipelineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.LaunchCampaignPipelineRequest.displayName = 'proto.campaigns.LaunchCampaignPipelineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.campaigns.CampaignPipelineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.campaigns.CampaignPipelineResponse.repeatedFields_, null);
};
goog.inherits(proto.campaigns.CampaignPipelineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.campaigns.CampaignPipelineResponse.displayName = 'proto.campaigns.CampaignPipelineResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.campaigns.LaunchCampaignPipelineRequest.oneofGroups_ = [[12,13]];

/**
 * @enum {number}
 */
proto.campaigns.LaunchCampaignPipelineRequest.OpportunityAutomationCase = {
  OPPORTUNITY_AUTOMATION_NOT_SET: 0,
  CONTENT: 12,
  IMPORT: 13
};

/**
 * @return {proto.campaigns.LaunchCampaignPipelineRequest.OpportunityAutomationCase}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getOpportunityAutomationCase = function() {
  return /** @type {proto.campaigns.LaunchCampaignPipelineRequest.OpportunityAutomationCase} */(jspb.Message.computeOneofCase(this, proto.campaigns.LaunchCampaignPipelineRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.LaunchCampaignPipelineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.LaunchCampaignPipelineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.LaunchCampaignPipelineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    recurringEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    recurringPeriodInHours: jspb.Message.getFieldWithDefault(msg, 6, 0),
    recurringEndAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    contactAutomationEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    contactAutomation: (f = msg.getContactAutomation()) && automation$contact_pb.LaunchContactAutomationRequest.toObject(includeInstance, f),
    personalizationAutomationEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    personalizationAutomation: (f = msg.getPersonalizationAutomation()) && automation$personalization_pb.LaunchPersonalizationAutomationRequest.toObject(includeInstance, f),
    content: (f = msg.getContent()) && automation$content_pb.LaunchContentAutomationRequest.toObject(includeInstance, f),
    pb_import: (f = msg.getImport()) && file$importer_pb.ImportRequest.toObject(includeInstance, f),
    notify: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    inspectionAutomationEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    inspectionAutomation: (f = msg.getInspectionAutomation()) && automation$inspection_pb.LaunchInspectionAutomationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.LaunchCampaignPipelineRequest;
  return proto.campaigns.LaunchCampaignPipelineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.LaunchCampaignPipelineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCampaignId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {!proto.campaigns.CampaignPipelineType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurringEnabled(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecurringPeriodInHours(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecurringEndAt(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContactAutomationEnabled(value);
      break;
    case 9:
      var value = new automation$contact_pb.LaunchContactAutomationRequest;
      reader.readMessage(value,automation$contact_pb.LaunchContactAutomationRequest.deserializeBinaryFromReader);
      msg.setContactAutomation(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalizationAutomationEnabled(value);
      break;
    case 11:
      var value = new automation$personalization_pb.LaunchPersonalizationAutomationRequest;
      reader.readMessage(value,automation$personalization_pb.LaunchPersonalizationAutomationRequest.deserializeBinaryFromReader);
      msg.setPersonalizationAutomation(value);
      break;
    case 12:
      var value = new automation$content_pb.LaunchContentAutomationRequest;
      reader.readMessage(value,automation$content_pb.LaunchContentAutomationRequest.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 13:
      var value = new file$importer_pb.ImportRequest;
      reader.readMessage(value,file$importer_pb.ImportRequest.deserializeBinaryFromReader);
      msg.setImport(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotify(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionAutomationEnabled(value);
      break;
    case 16:
      var value = new automation$inspection_pb.LaunchInspectionAutomationRequest;
      reader.readMessage(value,automation$inspection_pb.LaunchInspectionAutomationRequest.deserializeBinaryFromReader);
      msg.setInspectionAutomation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.LaunchCampaignPipelineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.LaunchCampaignPipelineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.LaunchCampaignPipelineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCampaignId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRecurringEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRecurringPeriodInHours();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRecurringEndAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getContactAutomationEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getContactAutomation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      automation$contact_pb.LaunchContactAutomationRequest.serializeBinaryToWriter
    );
  }
  f = message.getPersonalizationAutomationEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPersonalizationAutomation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      automation$personalization_pb.LaunchPersonalizationAutomationRequest.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      automation$content_pb.LaunchContentAutomationRequest.serializeBinaryToWriter
    );
  }
  f = message.getImport();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      file$importer_pb.ImportRequest.serializeBinaryToWriter
    );
  }
  f = message.getNotify();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getInspectionAutomationEnabled();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getInspectionAutomation();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      automation$inspection_pb.LaunchInspectionAutomationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 campaign_id = 2;
 * @return {number}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getCampaignId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 workspace_id = 3;
 * @return {number}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getWorkspaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional CampaignPipelineType type = 4;
 * @return {!proto.campaigns.CampaignPipelineType}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignPipelineType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.campaigns.CampaignPipelineType} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool recurring_enabled = 5;
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getRecurringEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setRecurringEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 recurring_period_in_hours = 6;
 * @return {number}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getRecurringPeriodInHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setRecurringPeriodInHours = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 recurring_end_at = 7;
 * @return {number}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getRecurringEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setRecurringEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool contact_automation_enabled = 8;
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getContactAutomationEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setContactAutomationEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional automation.LaunchContactAutomationRequest contact_automation = 9;
 * @return {?proto.automation.LaunchContactAutomationRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getContactAutomation = function() {
  return /** @type{?proto.automation.LaunchContactAutomationRequest} */ (
    jspb.Message.getWrapperField(this, automation$contact_pb.LaunchContactAutomationRequest, 9));
};


/**
 * @param {?proto.automation.LaunchContactAutomationRequest|undefined} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
*/
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setContactAutomation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.clearContactAutomation = function() {
  return this.setContactAutomation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.hasContactAutomation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool personalization_automation_enabled = 10;
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getPersonalizationAutomationEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setPersonalizationAutomationEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional automation.LaunchPersonalizationAutomationRequest personalization_automation = 11;
 * @return {?proto.automation.LaunchPersonalizationAutomationRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getPersonalizationAutomation = function() {
  return /** @type{?proto.automation.LaunchPersonalizationAutomationRequest} */ (
    jspb.Message.getWrapperField(this, automation$personalization_pb.LaunchPersonalizationAutomationRequest, 11));
};


/**
 * @param {?proto.automation.LaunchPersonalizationAutomationRequest|undefined} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
*/
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setPersonalizationAutomation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.clearPersonalizationAutomation = function() {
  return this.setPersonalizationAutomation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.hasPersonalizationAutomation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional automation.LaunchContentAutomationRequest content = 12;
 * @return {?proto.automation.LaunchContentAutomationRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getContent = function() {
  return /** @type{?proto.automation.LaunchContentAutomationRequest} */ (
    jspb.Message.getWrapperField(this, automation$content_pb.LaunchContentAutomationRequest, 12));
};


/**
 * @param {?proto.automation.LaunchContentAutomationRequest|undefined} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
*/
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.campaigns.LaunchCampaignPipelineRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.hasContent = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional importer.file.ImportRequest import = 13;
 * @return {?proto.importer.file.ImportRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getImport = function() {
  return /** @type{?proto.importer.file.ImportRequest} */ (
    jspb.Message.getWrapperField(this, file$importer_pb.ImportRequest, 13));
};


/**
 * @param {?proto.importer.file.ImportRequest|undefined} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
*/
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setImport = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.campaigns.LaunchCampaignPipelineRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.clearImport = function() {
  return this.setImport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.hasImport = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool notify = 14;
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getNotify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setNotify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool inspection_automation_enabled = 15;
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getInspectionAutomationEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setInspectionAutomationEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional automation.LaunchInspectionAutomationRequest inspection_automation = 16;
 * @return {?proto.automation.LaunchInspectionAutomationRequest}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.getInspectionAutomation = function() {
  return /** @type{?proto.automation.LaunchInspectionAutomationRequest} */ (
    jspb.Message.getWrapperField(this, automation$inspection_pb.LaunchInspectionAutomationRequest, 16));
};


/**
 * @param {?proto.automation.LaunchInspectionAutomationRequest|undefined} value
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
*/
proto.campaigns.LaunchCampaignPipelineRequest.prototype.setInspectionAutomation = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.campaigns.LaunchCampaignPipelineRequest} returns this
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.clearInspectionAutomation = function() {
  return this.setInspectionAutomation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.campaigns.LaunchCampaignPipelineRequest.prototype.hasInspectionAutomation = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.campaigns.CampaignPipelineResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.campaigns.CampaignPipelineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.campaigns.CampaignPipelineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.campaigns.CampaignPipelineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPipelineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    searchQueriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    opportunitiesAdded: jspb.Message.getFieldWithDefault(msg, 5, 0),
    contactsAdded: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    nextStartAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    finishedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    recurringEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    recurringPeriodInHours: jspb.Message.getFieldWithDefault(msg, 11, 0),
    recurringEndAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    completedPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    reloadOpportunities: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    creditsSpent: jspb.Message.getFieldWithDefault(msg, 15, 0),
    contactAutomationEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    importErrorRowsNumber: jspb.Message.getFieldWithDefault(msg, 17, 0),
    personalizationEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    personalizationAdded: jspb.Message.getFieldWithDefault(msg, 19, 0),
    stepType: jspb.Message.getFieldWithDefault(msg, 20, 0),
    totalStepsNumber: jspb.Message.getFieldWithDefault(msg, 21, 0),
    currentStepNumber: jspb.Message.getFieldWithDefault(msg, 22, 0),
    warningMessage: jspb.Message.getFieldWithDefault(msg, 23, ""),
    opportunitiesWithContacts: jspb.Message.getFieldWithDefault(msg, 24, 0),
    inspectionRemovedNumber: jspb.Message.getFieldWithDefault(msg, 25, 0),
    inspectionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.campaigns.CampaignPipelineResponse}
 */
proto.campaigns.CampaignPipelineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.campaigns.CampaignPipelineResponse;
  return proto.campaigns.CampaignPipelineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.campaigns.CampaignPipelineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.campaigns.CampaignPipelineResponse}
 */
proto.campaigns.CampaignPipelineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.campaigns.CampaignPipelineType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {!proto.common.CampaignAutomationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSearchQueries(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesAdded(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContactsAdded(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextStartAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurringEnabled(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecurringPeriodInHours(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecurringEndAt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCompletedPercentage(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReloadOpportunities(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditsSpent(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContactAutomationEnabled(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImportErrorRowsNumber(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPersonalizationEnabled(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonalizationAdded(value);
      break;
    case 20:
      var value = /** @type {!proto.campaigns.PipelineStepType} */ (reader.readEnum());
      msg.setStepType(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalStepsNumber(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentStepNumber(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setWarningMessage(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpportunitiesWithContacts(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInspectionRemovedNumber(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInspectionEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.campaigns.CampaignPipelineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.campaigns.CampaignPipelineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.campaigns.CampaignPipelineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.campaigns.CampaignPipelineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSearchQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getOpportunitiesAdded();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getContactsAdded();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStartedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNextStartAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getFinishedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRecurringEnabled();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getRecurringPeriodInHours();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getRecurringEndAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCompletedPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getReloadOpportunities();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCreditsSpent();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getContactAutomationEnabled();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getImportErrorRowsNumber();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPersonalizationEnabled();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getPersonalizationAdded();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getTotalStepsNumber();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getCurrentStepNumber();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getWarningMessage();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOpportunitiesWithContacts();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getInspectionRemovedNumber();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getInspectionEnabled();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CampaignPipelineType type = 2;
 * @return {!proto.campaigns.CampaignPipelineType}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getType = function() {
  return /** @type {!proto.campaigns.CampaignPipelineType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.campaigns.CampaignPipelineType} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional common.CampaignAutomationStatus status = 3;
 * @return {!proto.common.CampaignAutomationStatus}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getStatus = function() {
  return /** @type {!proto.common.CampaignAutomationStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.CampaignAutomationStatus} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string search_queries = 4;
 * @return {!Array<string>}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getSearchQueriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setSearchQueriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.addSearchQueries = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.clearSearchQueriesList = function() {
  return this.setSearchQueriesList([]);
};


/**
 * optional int32 opportunities_added = 5;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getOpportunitiesAdded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setOpportunitiesAdded = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 contacts_added = 6;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getContactsAdded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setContactsAdded = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 started_at = 7;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 next_start_at = 8;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getNextStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setNextStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 finished_at = 9;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getFinishedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setFinishedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool recurring_enabled = 10;
 * @return {boolean}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getRecurringEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setRecurringEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 recurring_period_in_hours = 11;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getRecurringPeriodInHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setRecurringPeriodInHours = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 recurring_end_at = 12;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getRecurringEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setRecurringEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional double completed_percentage = 13;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getCompletedPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setCompletedPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional bool reload_opportunities = 14;
 * @return {boolean}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getReloadOpportunities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setReloadOpportunities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 credits_spent = 15;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getCreditsSpent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setCreditsSpent = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool contact_automation_enabled = 16;
 * @return {boolean}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getContactAutomationEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setContactAutomationEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int32 import_error_rows_number = 17;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getImportErrorRowsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setImportErrorRowsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool personalization_enabled = 18;
 * @return {boolean}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getPersonalizationEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setPersonalizationEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int32 personalization_added = 19;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getPersonalizationAdded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setPersonalizationAdded = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional PipelineStepType step_type = 20;
 * @return {!proto.campaigns.PipelineStepType}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getStepType = function() {
  return /** @type {!proto.campaigns.PipelineStepType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.campaigns.PipelineStepType} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional int32 total_steps_number = 21;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getTotalStepsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setTotalStepsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 current_step_number = 22;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getCurrentStepNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setCurrentStepNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string warning_message = 23;
 * @return {string}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getWarningMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setWarningMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 opportunities_with_contacts = 24;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getOpportunitiesWithContacts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setOpportunitiesWithContacts = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 inspection_removed_number = 25;
 * @return {number}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getInspectionRemovedNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setInspectionRemovedNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool inspection_enabled = 26;
 * @return {boolean}
 */
proto.campaigns.CampaignPipelineResponse.prototype.getInspectionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.campaigns.CampaignPipelineResponse} returns this
 */
proto.campaigns.CampaignPipelineResponse.prototype.setInspectionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * @enum {number}
 */
proto.campaigns.CampaignPipelineType = {
  GOOGLE_SEARCH: 0,
  BACKLINK_SEARCH: 1,
  PODCAST_SEARCH: 3,
  IMPORT: 4,
  CONTACT_AUTOMATION: 5,
  PERSONALIZATION_AUTOMATION: 6,
  INSPECTION_AUTOMATION: 7
};

/**
 * @enum {number}
 */
proto.campaigns.PipelineStepType = {
  FINDING_OPPORTUNITIES: 0,
  FINDING_CONTACTS: 1,
  PERSONALIZING_EMAILS: 2,
  INSPECTING_OPPORTUNITIES: 3
};

goog.object.extend(exports, proto.campaigns);
