import uniquePodcastsFilter from '@components/CampaignSettings/Filters/filtersConfig/uniquePodcastsFilter';
import { SearchTypesEnum } from '@ts/contentSearch.types';
import freshnessFilter from './freshnessFilter';
import languageFilter from './languageFilter';
import minWordCountFilter from './minWordCountFilter';
import locationFilter from './locationFilter';
import podcastCategoryFilter from './podcastCategoryFilter';

/*
  All comments we need ^) It means we temporarily delete them
 */

export type RenderFunctionType = (props: {
  onApply: (filterValue: any, filterKey?: string) => void;
  sourceType?: number | string;
  value: any;
}) => JSX.Element;

export type FilterType = {
  label: string;
  filterKey: string;
  icon: string;
  defaultValue?: string | boolean | number | null;
  render: RenderFunctionType;
};

export default {
  [SearchTypesEnum.googleSearch]: [locationFilter, languageFilter /*, freshnessFilter */],
  [SearchTypesEnum.googleNews]: [locationFilter, languageFilter /*, freshnessFilter */],
  [SearchTypesEnum.bingSearch]: [locationFilter, languageFilter /*, freshnessFilter */],
  [SearchTypesEnum.bingNews]: [locationFilter /*, freshnessFilter */],
  trendingContent: [locationFilter, languageFilter, freshnessFilter, minWordCountFilter],
  [SearchTypesEnum.podcasts]: [
    podcastCategoryFilter,
    freshnessFilter,
    // locationFilter,
    languageFilter,
  ],
  episodes: [
    freshnessFilter,
    uniquePodcastsFilter,
    // locationFilter,
    // languageFilter,
  ],
};
