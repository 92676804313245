import React from 'react';

export default ({ color = '#212121' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 10.2083C10.5753 10.2083 11.0417 9.74196 11.0417 9.16667C11.0417 8.59137 10.5753 8.125 10 8.125C9.42474 8.125 8.95837 8.59137 8.95837 9.16667C8.95837 9.74196 9.42474 10.2083 10 10.2083Z"
      fill={color} />
    <path
      d="M6.04167 10.2083C6.61696 10.2083 7.08333 9.74196 7.08333 9.16667C7.08333 8.59137 6.61696 8.125 6.04167 8.125C5.46637 8.125 5 8.59137 5 9.16667C5 9.74196 5.46637 10.2083 6.04167 10.2083Z"
      fill={color} />
    <path
      d="M15 17.2917C16.7259 17.2917 18.125 15.8926 18.125 14.1667C18.125 12.4408 16.7259 11.0417 15 11.0417C13.2741 11.0417 11.875 12.4408 11.875 14.1667C11.875 15.8926 13.2741 17.2917 15 17.2917Z"
      stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.5408 11.075L14.8433 10H15.1558L15.4592 11.075" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.8617 11.6559L17.8359 11.11L18.0567 11.3309L17.5109 12.305" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.0917 13.7075L19.1667 14.01V14.3225L18.0917 14.6259" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5109 16.0284L18.0567 17.0025L17.8359 17.2234L16.8617 16.6775" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.4592 17.2584L15.1567 18.3334H14.8442L14.5408 17.2584" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1384 16.6775L12.1642 17.2234L11.9434 17.0025L12.4892 16.0284" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9084 14.6259L10.8334 14.3234V14.0109L11.9084 13.7075" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.3334 7.50002V5.83335C18.3334 4.91252 17.5875 4.16669 16.6667 4.16669H3.33335C2.41252 4.16669 1.66669 4.91252 1.66669 5.83335V12.5C1.66669 13.4209 2.41252 14.1667 3.33335 14.1667H7.50002"
      stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.4892 12.305L11.9434 11.3309L12.1642 11.11L13.1384 11.6559" stroke={color} strokeWidth="1.6"
          strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
