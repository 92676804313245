import React from 'react';
import cn from 'class-names';

import { SelectOptionType } from '@ts/common.types';

import Select from '@uikit/Select/Select';
import SlideToggler from '@uikit/SlideToggler/SlideToggler';

import AutomationPositionTooltipContent from '@components/CampaignSettings/ContactAutomationSetup/_components/AutomationPositionTooltipContent/AutomationPositionTooltipContent';
import QuestionTooltip from '@pages/CampaignCreationPage/_components/QuestionTooltip/QuestionTooltip';

type AutomationPositionAndSeniorityPropsType = {
  isRequiredPositions: boolean;
  onChangeRequiredPositions: (value: boolean) => void;
  positionsList: SelectOptionType[];
  positionSuggestions: SelectOptionType[];
  senioritiesList: SelectOptionType[];
  senioritySuggestions: SelectOptionType[];
  onChangePositionsList: (options: SelectOptionType | SelectOptionType[]) => void;
  onChangeSenioritiesList: (options: SelectOptionType | SelectOptionType[]) => void;
};

const AutomationPositionAndSeniority = ({
  isRequiredPositions,
  onChangeRequiredPositions,
  positionsList,
  positionSuggestions,
  senioritiesList,
  senioritySuggestions,
  onChangePositionsList,
  onChangeSenioritiesList,
}: AutomationPositionAndSeniorityPropsType): JSX.Element => {
  const createRequiredLabelCn = (value) =>
    cn('automation-position-and-seniority__required-label', {
      'automation-position-and-seniority__required-label--active': value,
    });

  const getPositionOrDepartmentOptions = () => {
    return positionSuggestions;
  };

  const getSeniorityOptions = () => {
    return senioritySuggestions;
  };

  function requiredPositionsText() {
    if (isRequiredPositions)
      return 'Adding restrictions to job titles significantly reduces the search results. We recommend turning this toggle off to maximize your results.';
    return 'If enabled, we will assign contacts only if employees with specific job titles are found. We recommend keeping this toggle off to allow us to find the best available contacts.';
  }

  return (
    <div className="automation-position-and-seniority">
      <div className="automation-position-and-seniority__wrapper">
        <div className="automation-position-and-seniority__title-row">Job titles:</div>

        <Select
          closeMenuOnSelect={false}
          placeholder="Add job titles"
          onChange={onChangePositionsList}
          allowCustomInput
          options={getPositionOrDepartmentOptions()}
          value={positionsList}
          additionalComponents={!!positionsList?.length && { DropdownIndicator: () => null }}
          isMulti
          visibleMultiCount={4}
          additionalStyles={{
            control: (provided) => ({
              ...provided,
              height: '40px',
              borderRadius: '8px',
            }),
          }}
          renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
        />

        <div className="automation-position-and-seniority__title-row">
          <div className="automation-position-and-seniority__title-row-block">
            <span className={createRequiredLabelCn(isRequiredPositions)}>Required </span>
            <SlideToggler value={isRequiredPositions} onChange={onChangeRequiredPositions} />
            <QuestionTooltip
              place="top"
              color="#C6C6C6"
              icon={isRequiredPositions ? 'warningTriangle' : 'questionInCircleFilled'}
              style={{ marginLeft: '6px', marginTop: '2px' }}
              text={requiredPositionsText()}
            />
          </div>
        </div>
      </div>

      <div className="automation-position-and-seniority__wrapper">
        <div className="automation-position-and-seniority__title-row">Seniority</div>

        <Select
          closeMenuOnSelect={false}
          placeholder="Add seniority"
          onChange={onChangeSenioritiesList}
          allowCustomInput
          options={getSeniorityOptions()}
          value={senioritiesList}
          additionalComponents={!!senioritiesList?.length && { DropdownIndicator: () => null }}
          isMulti
          visibleMultiCount={4}
          additionalStyles={{
            control: (provided) => ({
              ...provided,
              height: '40px',
              borderRadius: '8px',
            }),
          }}
          renderMultipleValuesTooltipContent={AutomationPositionTooltipContent}
        />
      </div>
    </div>
  );
};

export default AutomationPositionAndSeniority;
