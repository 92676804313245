/**
 * NOT CHECKED
 */
import React, { useState } from 'react';

import './ColoredLabel.scss';

export default ({
  classNames,
  color,
  fontSize,
  position,
  children,
  isHovered = true,
}: {
  classNames?: string,
  color: string,
  fontSize?: string,
  position?: string,
  children: string | JSX.Element,
  isHovered?: boolean
}): JSX.Element => {

  let classes = 'label';

  if (classNames) {
    classes += classNames;
  }

  if (color) {
    classes += ` label-color-${color}`;
  }

  if (fontSize) {
    classes += ` label-font-size-${fontSize}`;
  }

  if (position) {
    classes += ` label-position-${position}`;
  }

  if (isHovered) {
    classes += ' label-hover';
  }

  return <label className={classes}>{children}</label>;
};
