import { ApiError } from 'src/errors/ApiError';
import fetchMetadata from '@api/base/fetchMetadata';
import { getItemFromStorage } from '@utils/localStorageHandler';

declare const IS_DEV: boolean;
declare const HIDE_NETWORK_LOGS: boolean;

const GOD_MODE = getItemFromStorage('GOD_MODE', false);

export default <T>(
  client,
  method,
  request = null,
  processCallback = (data: [T, any]) => {},
  onEndCallback: (data: T[]) => void = null
): Promise<T> => {
  if (IS_DEV || GOD_MODE) {
    if (HIDE_NETWORK_LOGS !== true) {
      console.group(`>> ${method.name}`);
      if (request != null) {
        console.info(request.toObject());
      }
      console.groupEnd();
    }
  }

  return fetchMetadata().then((metadata) => {
    return new Promise((resolve, reject) => {
      const resData = [];
      const stream = method.call(client, request, metadata);

      stream.on('data', (res) => {
        if (processCallback) {
          processCallback([res.toObject(), stream]);
        }
        resData.push(res.toObject());
      });

      stream.on('status', (status) => {
        if (status.code !== 0) {
          if (IS_DEV || GOD_MODE) {
            console.error(status);
          }

          const errorMessage = status.errorMessage || status.message || status.details;
          reject(new ApiError(errorMessage, status.code, status));
        }
      });

      stream.on('end', () => {
        if (IS_DEV || GOD_MODE) {
          if (HIDE_NETWORK_LOGS !== true) {
            console.group(`<< ${method.name}`);
            console.info(resData);
            console.groupEnd();
          }
        }

        if (onEndCallback) {
          onEndCallback(resData);
        }

        // @ts-ignore
        resolve(resData);
      });
    });
  });
};
