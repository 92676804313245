import React from 'react';

export default ({ color = '#FFFFFF', size = 16 }): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.71484 4.73425V8.69425" stroke="white" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round" />
    <path d="M4.73438 6.71423H8.69437" stroke="white"  strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round" />
    <path
      d="M10.143 2.42859H3.28585C2.81247 2.42859 2.42871 2.81234 2.42871 3.28573V10.1429C2.42871 10.6163 2.81247 11 3.28585 11H10.143C10.6164 11 11.0001 10.6163 11.0001 10.1429V3.28573C11.0001 2.81234 10.6164 2.42859 10.143 2.42859Z"
      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 13.5714H12.7143C12.9416 13.5714 13.1596 13.4811 13.3204 13.3204C13.4811 13.1596 13.5714 12.9416 13.5714 12.7143V5"
      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);