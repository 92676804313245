/**
 * NOT CHECKED
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NoAccessIllustration from '@uikit/Icon/illustrations/NoAccessIllustration';
import { Button } from '@uikit/Button/Button';

import { redirectUserTo } from '@utils/historyHandler';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { changeCurrentWorkspace } from '@redux/actions/workspaces.actions';
import { workspacesSelector } from '@redux/selectors/workspaces.selectors';

import './NoAccessMessage.scss';
import urls from '@constants/urls';

declare const IS_STANDALONE_MODE: boolean;

function NoAccessMessage({
  showButton = false,
  message = "Oops! You don't have access to this tab.",
  subMessage = 'Please contact your administrator.',
}: {
  showButton?: boolean;
  message?: string;
  subMessage?: string;
}): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const workspaceId = useCurrentWorkspaceId();
  const { workspaces } = useSelector(workspacesSelector);

  const handleHomeCLick = () => {
    const params = history.location.pathname.split('/');
    const startOfUrl = params[1];
    let hasAccess = true;
    if (startOfUrl === 'workspaces') {
      const workspaceIdFromUrl = parseInt(params[2], 10);
      hasAccess = workspaces.some((workspace) => workspace.id === workspaceIdFromUrl);
    }
    if (workspaceId === -1 || !hasAccess) {
      const activeWorkspace = workspaces.find((item) => item.favorite) || workspaces[0];
      dispatch(changeCurrentWorkspace(activeWorkspace.id));
    }
    redirectUserTo(history, IS_STANDALONE_MODE ? urls.DASHBOARD : urls.ALL_CAMPAIGNS(workspaceId));
  };

  return (
    <div className="no-access-error">
      <NoAccessIllustration />
      <h1 className="no-access-error__title">{message}</h1>
      <p className="no-access-error__sub-title">{subMessage}</p>
      {showButton && (
        <Button
          onClick={handleHomeCLick}
          className="no-access-error__home"
          type="primary"
          leftIcon="house"
        >
          Home
        </Button>
      )}
    </div>
  );
}

export default NoAccessMessage;
