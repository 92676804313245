import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { getOpportunityByIdApi, removeOpportunityContactApi } from '@api/campaign.api';
import {
  addCompanyNoteApi,
  addCompanyTagApi,
  deleteMetricsValueByIdApi,
  fetchPeopleCompanyNotesApi,
  getAllCompanyTagsApi,
  getAllMetricsViewSettingsByCompanyIdApi,
  getCampaignOpportunitiesByWebsiteIdApi,
  getPipelineOpportunitiesByWebsiteIdApi,
  loadMetricsValueByIdApi,
  removeCompanyNoteApi,
  removeCompanyTagApi,
  renameWebsiteApi,
  setMetricsValueByIdApi,
  showMetricsByTypeApi,
  updateCompanyNoteApi,
} from '@api/website.api';
import { getPreviewEmailsApi } from '@api/mailbox.api';
import {
  createOrUpdateCustomVariableValueApi,
  getCustomVariableValuesApi,
} from '@api/customVariables.api';
import { assignStageOpportunityApi, moveStageOpportunityApi } from '@api/relationshipPipeline';

import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';

import ErrorBoundary from '@uikit/ErrorBoundaries/ErrorBoundary';
import Loader from '@uikit/Loader/Loader';
import SidebarCRM, {
  ContentDataType,
  GeneralDataType,
  sidebarSize,
} from '@components/SidebarCRM/SidebarCRM';
import { SECTION_TYPE } from '@components/SidebarCRM/SidebarCRMContent/SidebarCRMContent';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { OpportunityDetailsType } from '@ts/opportunity.types';
import { TagType } from '@ts/common.types';
import { WebsiteType } from '@ts/company.types';
import { PipelineOpportunityType } from '@ts/relationshipPipeline.types';
import { RelatedPreviewResponseType } from '@ts/mailboxInbox.types';

function OpportunityContactSidebarWrapper({
  opportunityId,
  isSidebarShadow,
  onClose,
}: {
  opportunityId: number;
  isSidebarShadow?: boolean;
  onClose?: () => void;
}): JSX.Element {
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const workspaceId = useCurrentWorkspaceId();
  const queryClient = useQueryClient();
  const QUERY_KEY_BASE = 'active-opportunity';

  const {
    data: activeOpportunity,
    isLoading: isLoadingActiveOpportunity,
    refetch: refetchOpportunities,
  } = useQuery(
    [QUERY_KEY_BASE, opportunityId],
    () => getOpportunityByIdApi(opportunityId, campaignInfo.workspaceId, campaignInfo.id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignInfo && !!opportunityId,
    }
  );

  if (isLoadingActiveOpportunity) {
    return <Loader isLoading={isLoadingActiveOpportunity} />;
  }

  const itemId = activeOpportunity.website.id;
  const headerData = {
    logoUrl: activeOpportunity.website.logoUrl,
    title: activeOpportunity.website.title,
    url: activeOpportunity.website.website,
  };

  const mapContentData: GeneralDataType[] = [
    {
      key: 'websiteName',
      title: 'Website name',
      value: activeOpportunity.website.title,
      type: ContentDataType.text,
      isEditable: true,
      onChange: (title: string) => {
        renameWebsiteApi(itemId, workspaceId, title);
      },
    },
    {
      key: 'createdOn',
      title: 'Created on',
      value: activeOpportunity.website.createdAt,
      transformCreatedAt: true,
      type: ContentDataType.text,
      isEditable: false,
    },
  ];

  const onAddTag = (tagId: number, tagTitle: string) => {
    return addCompanyTagApi(tagId, tagTitle, itemId).then(
      (createdTag: TagType) => {
        queryClient.setQueryData<OpportunityDetailsType>(
          [QUERY_KEY_BASE, opportunityId],
          (opportunity: OpportunityDetailsType) => {
            return {
              ...opportunity,
              website: {
                ...activeOpportunity.website,
                tagsList: [...activeOpportunity.website.tagsList, createdTag],
              },
            };
          }
        );
        return createdTag;
      }
    );
  };

  const onRemoveTag = (id: number) => {
    return removeCompanyTagApi(id, itemId).then((removedTag: TagType) => {
      queryClient.setQueryData<OpportunityDetailsType>(
        [QUERY_KEY_BASE, opportunityId],
        (opportunity: OpportunityDetailsType) => {
          return {
            ...opportunity,
            website: {
              ...activeOpportunity.website,
              tagsList: activeOpportunity.website.tagsList.filter((tag) => tag.id !== id),
            },
          };
        }
      );
      return removedTag;
    });
  };

  const onSuccessSetMetric = (_, variables) => {
    queryClient.setQueryData<OpportunityDetailsType>(
      [QUERY_KEY_BASE, opportunityId],
      (opportunity: OpportunityDetailsType) => {
        return {
          ...opportunity,
          website: {
            ...opportunity.website,
            metricsList: opportunity.website.metricsList.map((metric) => {
              if (metric.id !== variables.data.id) {
                return metric;
              }

              return { ...variables.data };
            }),
          },
        };
      }
    );
  };

  const onSuccessShowMetricByType = (res, variables) => {
    queryClient.setQueryData<OpportunityDetailsType>(
      [QUERY_KEY_BASE, opportunityId],
      (opportunity: OpportunityDetailsType) => {
        return {
          ...opportunity,
          website: {
            ...opportunity.website,
            metricsList: variables.enabled
              ? [...opportunity.website.metricsList, res]
              : opportunity.website.metricsList.filter((d) => d.type !== variables.type),
          },
        };
      }
    );
    queryClient.setQueryData<WebsiteType>(
      [`${QUERY_KEY_BASE}-website`, itemId, workspaceId],
      (website: WebsiteType) => {
        return {
          ...website,
          metricsList: variables.enabled
            ? [...website.metricsList, res]
            : website.metricsList.filter((d) => d.type !== variables.type),
        };
      }
    );
  };

  const onSuccessDeleteMetricValue = (_, variables) => {
    queryClient.setQueryData<OpportunityDetailsType>(
      [QUERY_KEY_BASE, opportunityId],
      (opportunity: OpportunityDetailsType) => {
        return {
          ...opportunity,
          website: {
            ...opportunity.website,
            metricsList: opportunity.website.metricsList.filter((item) => item.id !== variables.id),
          },
        };
      }
    );
  };

  const onGetEmail = (payloadId: number): Promise<RelatedPreviewResponseType[]> => {
    return getPreviewEmailsApi(workspaceId, null, payloadId);
  };

  const assignStageOpportunity = (userId: number, oppId: number) => {
    return assignStageOpportunityApi(oppId, workspaceId, userId);
  };

  const onMoveOpportunity = (
    stageId: number,
    oppId: number,
    pipelineId: number
  ): Promise<PipelineOpportunityType> => {
    return moveStageOpportunityApi(workspaceId, pipelineId, stageId, oppId);
  };

  const sectionsMeta = {
    [SECTION_TYPE.CONTACTS_OPPORTUNITY]: {
      queryKeyWebsite: `${QUERY_KEY_BASE}-website`,
      opportunity: activeOpportunity,
      removeContact(id: number) {
        removeOpportunityContactApi(opportunityId, workspaceId, id);
      },
      actions: { refetchOpportunities },
    },
    [SECTION_TYPE.TAGS]: {
      queryKey: `${QUERY_KEY_BASE}-tags`,
      getAllTags: getAllCompanyTagsApi,
      getAddedTags(): { tagsList: TagType[] } {
        return {
          tagsList: activeOpportunity.website.tagsList,
        };
      },
      addTag: onAddTag,
      removeTag: onRemoveTag,
    },
    [SECTION_TYPE.METRICS]: {
      queryKey: `${QUERY_KEY_BASE}-metrics`,
      getWebsite() {
        return activeOpportunity.website;
      },
      getAllMetricsViewSettings: getAllMetricsViewSettingsByCompanyIdApi,
      setMetricValue: setMetricsValueByIdApi,
      onSuccessSetMetric,
      deleteMetricValue: deleteMetricsValueByIdApi,
      onSuccessDeleteMetricValue,
      showMetricByType: showMetricsByTypeApi,
      onSuccessShowMetricByType,
      loadMetricValueById: loadMetricsValueByIdApi,
    },
    [SECTION_TYPE.NOTES]: {
      queryKey: `${QUERY_KEY_BASE}-notes`,
      getNote: fetchPeopleCompanyNotesApi,
      addNote: addCompanyNoteApi,
      updateNote: updateCompanyNoteApi,
      removeNote: removeCompanyNoteApi,
    },
    [SECTION_TYPE.CAMPAIGNS]: {
      queryKey: `${QUERY_KEY_BASE}-campaigns`,
      getOpportunities: getCampaignOpportunitiesByWebsiteIdApi,
    },
    [SECTION_TYPE.EMAILS]: {
      queryKey: `${QUERY_KEY_BASE}-emails`,
      title: 'Related conversations',
      getEmails: onGetEmail,
    },
    [SECTION_TYPE.VARIABLES]: {
      queryKey: `${QUERY_KEY_BASE}-variables`,
      relatedTo: CustomVariableValueRelatedTo.OPPORTUNITY,
      getVariables: getCustomVariableValuesApi,
      createOrUpdateVariable: createOrUpdateCustomVariableValueApi,
    },
    [SECTION_TYPE.OPPORTUNITIES]: {
      queryKey: `${QUERY_KEY_BASE}-opportunities`,
      title: 'Deals',
      getOpportunities: getPipelineOpportunitiesByWebsiteIdApi,
      createOrUpdateCustomVariableValue: createOrUpdateCustomVariableValueApi,
      assignUser: assignStageOpportunity,
      moveOpportunity: onMoveOpportunity,
    },
  };

  return (
    <ErrorBoundary style={{ height: '100%' }}>
      <SidebarCRM
        type="opportunity-contact"
        itemId={itemId}
        isOpen
        onClose={onClose}
        headerData={headerData}
        contentData={mapContentData}
        sectionsMeta={sectionsMeta}
        size={sidebarSize.SMALL}
        extraClass={isSidebarShadow && 'box-shadow'}
      />
    </ErrorBoundary>
  );
}

export default OpportunityContactSidebarWrapper;
