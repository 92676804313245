import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { campaignService } from 'src/services/campaignService';

import { DispatchType } from 'src/store';

import { sendTestMessageApi } from '@api/mailboxSender.api';

import EmailSelect from '@uikit/EmailSelect/EmailSelect';
import BaseModal from '@uikit/BaseModal/BaseModal';
import Button from '@uikit/Button/Button';
import Input from '@uikit/Input/Input';
import Spinner from '@uikit/Spinner/Spinner';
import { userProfileSelector } from '@redux/selectors/userProfile.selector';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import { addNotification } from '@redux/actions/notifications.actions';

import './TestPersonalizationModal.scss';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { QueryCollectionHookType } from '@ts/common.types';
import { MailboxAccountType } from '@ts/mailboxAccounts.types';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import Loader from '@uikit/Loader/Loader';
import { isSemrushMode } from '@constants/app-modes';

declare const IS_STANDALONE_MODE: boolean;
declare const INTEGRATION_MODE;

function TestPersonalizationModal({
  personalization,
  onClose,
  personId,
  opportunityId,
}): JSX.Element {
  const dispatch = useDispatch<DispatchType>();

  const [senderEmail, setSenderEmail] = useState<{ label: string; value: number }>(null);
  const [sendToEmail, chaneSendToEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, changeIsSending] = useState(false);
  const { items: mailboxAccounts }: QueryCollectionHookType<MailboxAccountType> =
    useMailboxAccounts(useCurrentWorkspaceId());

  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const {
    info: { email },
  } = useSelector(userProfileSelector);

  useEffect(() => {
    if (IS_STANDALONE_MODE) {
      chaneSendToEmail(email);
    } else if (isSemrushMode(INTEGRATION_MODE)) {
      if (mailboxAccounts?.length > 0) {
        chaneSendToEmail(mailboxAccounts[0].email);
      }
    }
  }, [email, mailboxAccounts]);

  useEffect(() => {
    if (
      campaignInfo?.settings?.senderMailboxIdList?.length &&
      mailboxAccounts?.length &&
      !senderEmail
    ) {
      const senderEmailOption = mailboxAccounts?.find((item) =>
        campaignInfo.settings.senderMailboxIdList.includes(item.id)
      );

      if (senderEmailOption?.id) {
        setSenderEmail({ value: senderEmailOption.id, label: senderEmailOption.email });
      }
    }
  }, []);

  const handleValueChange = (e) => {
    e.stopPropagation();
    chaneSendToEmail(e.target.value);
  };

  const handleClose = () => {
    changeIsSending(false);
    onClose();
  };

  const handleSend = () => {
    if (senderEmail == null || senderEmail.value <= 0) {
      dispatch(
        addNotification({
          title: 'Something went wrong. Please, reload the page and try again.',
          type: 'error',
        })
      );
      return;
    }
    changeIsSending(true);
    sendTestMessageApi(
      senderEmail.value,
      personId,
      campaignInfo.settings.scheduleId,
      opportunityId,
      personalization.subject,
      personalization.content,
      sendToEmail,
      personalization.attachmentsList,
      campaignInfo.id,
      personalization.enabledUnsubscribeContent
    )
      .then(() => {
        dispatch(addNotification({ title: 'Test email was sent', type: 'success' }));
        handleClose();
      })
      .finally(() => changeIsSending(false));
  };

  function isDisabled() {
    return isSending || !sendToEmail || !senderEmail?.value || senderEmail.value <= 0;
  }

  return (
    <BaseModal
      classNames={{ modal: 'personalisation-modal' }}
      isOpen={!!personalization}
      onClose={handleClose}
    >
      <Loader isLoading={isLoading} withTopMargin>
        <div className="test-personalisation-modal">
          <div className="test-personalisation-modal__header">
            <div className="test-personalisation-modal__title">Send test email</div>
            <div className="test-personalisation-modal__sub-title">
              Send a test email to view how the email will display in your
              <br />
              email client. Previous steps are not visible in test emails.
            </div>
          </div>
          <div className="test-personalisation-modal__body">
            <div className="test-personalisation-modal__label">Sender email</div>
            <EmailSelect
              isMulti={false}
              value={senderEmail}
              setLoading={(value) => setIsLoading(value)}
              onChange={(option) => {
                setSenderEmail(option);

                if (!campaignInfo.settings?.senderMailboxIdList?.length) {
                  campaignService.update(
                    {
                      ...campaignInfo,
                      settings: { ...campaignInfo.settings, senderMailboxIdList: [option.value] },
                    },
                    dispatch
                  );
                }
              }}
            />
            <div className="test-personalisation-modal__label">Recipient</div>
            <Input
              value={sendToEmail}
              onChange={handleValueChange}
              onFocus={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="test-personalisation-modal__footer">
            <Button
              className="test-personalisation-modal__send-button"
              onClick={handleSend}
              disabled={isDisabled()}
            >
              {isSending ? (
                <>
                  <Spinner marginRight={5} color="#fff" />
                  Sending
                </>
              ) : (
                <>Send test email</>
              )}
            </Button>
            <Button
              className="test-personalisation-modal__cancel-button"
              type="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Loader>
    </BaseModal>
  );
}

export default TestPersonalizationModal;
