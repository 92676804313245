import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'src/uikit/Select/Select';

import loadingStatuses from '@constants/loadingStatuses';

import { schedulesSelector } from '@redux/selectors/schedules.selectors';
import { fetchAllSchedules } from '@redux/thunks/schedulesRequests';

import SchedulesDaysList from '@components/Settings/SchedulesDaysList/SchedulesDaysList';
import Menu, { getSchedulesSelectOptions } from '@components/Schedules/Menu/Menu';

export default {
  type: 'select',
  label: 'Campaign Schedule',
  key: 'campaignSchedule',
  Renderer: (props: { [key: string]: any }): JSX.Element => {
    const dispatch = useDispatch();
    const { items, loadingStatus: schedulesLs } = useSelector(schedulesSelector);

    useEffect(() => {
      if (items.length > 0 && props.value === null) {
        props.onChange({
          label: items[0].title,
          value: items[0].id,
        });
      }
    }, [items.length]);

    useEffect(() => {
      if (!schedulesLs || schedulesLs === loadingStatuses.PENDING) {
        fetchAllSchedules(dispatch);
      }
    }, [schedulesLs]);

    return (
      <Select
        {...props}
        options={getSchedulesSelectOptions(items)}
        additionalComponents={{ Menu }}
        key={schedulesLs}
      />
    );
  },
};

export const campaignSchedulePreview = {
  key: 'campaignSchedulePreview',
  previewKey: 'campaignSchedule',
  label: '',
  Renderer: (props: { [key: string]: any }): JSX.Element => {
    const { items } = useSelector(schedulesSelector);

    const selectedSchedule = items?.find((item) => item.id === props?.value?.value);

    return selectedSchedule ? (
      <SchedulesDaysList
        timezone={selectedSchedule.timezoneValue}
        style={{ height: '36px' }}
        dayList={selectedSchedule?.dayList}
      />
    ) : null;
  },
};
