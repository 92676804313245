import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Sortable from 'sortablejs';

import { Button } from '@uikit/Button/Button';
import Folders from '@uikit/Folders/Folders';
import PipelineColumn from '@components/Relationships/RelationshipsPipelines/_conponents/PipelineColumn/PipelineColumn';
import PageHeader from '@components/PageHeader/PageHeader';
import HeaderActions from '@components/HeaderTemplate/HeaderActions/HeaderActions';
import PipelinesSidebar from '@components/Relationships/PipelinesSidebar/PipelinesSidebar';
import { FilterJoins, FilterOperationsMapForDate } from '@constants/filters/filterOperations';
import { getFirstNamePlusBigFirstCharOfLastName } from '@utils/string.utils';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import {
  createPipelineApi,
  createStageApi,
  deletePipelineApi,
  deleteStageApi,
  moveStageApi,
  updatePipelineApi,
} from '@api/relationshipPipeline';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { FolderType } from '@ts/common.types';
import {
  PipelineOpportunityType,
  RelationshipPipelineStageType,
  RelationshipPipelineType,
} from '@ts/relationshipPipeline.types';

import './RelationshipsPipelines.scss';
import {
  StageOpportunityFilterField,
  StageOpportunityFilterFieldMap,
} from 'respona_api/generated/relationship-pipeline_pb';
import useRelationshipPipelines from '@hooks/useRelationshipPipelines';
import useRelationshipPipelineStages, {
  PIPELINE_COLUMN_QUERY_KEY,
} from '@hooks/useRelationshipPipelineStages';
import DealsSidebar from '@components/Relationships/WebsitesSidebar/_components/DealsSidebar/DealsSidebar';
import RelationshipsPiplenesIllustration from '@uikit/Icon/illustrations/RelationshipsPiplenesIllustration';
import { useSelector } from 'react-redux';
import { billingDetailsSelector } from '@redux/selectors/billings.selectors';
import loadingStatuses from '@constants/loadingStatuses';
import useRelationshipOpportunitiesWithFilters, {
  DEALS_WITH_FILTERS_PAGE_LIMIT,
  DEALS_WITH_FILTERS_QUERY_KEY,
} from '@hooks/useRelationshipOpportunitiesWithFilters';
import { useQueryClient } from 'react-query';
import { DEALS_WITHOUT_FILTERS_QUERY_KEY } from '@hooks/useRelationshipOpportunities';
import { redirectUserTo, redirectUserToSearchParams } from '@utils/historyHandler';
import {
  FilterField
} from '@components/HeaderTemplate/HeaderTemplateFilters/FiltersTooltipContentRaw/FiltersTooltipContentRaw';
import { FilterFieldType } from '@ts/filterField.type';

export function RelationshipPipelinesPopup({ isOpen = true }: { isOpen?: boolean }): JSX.Element {
  const history = useHistory();

  if (!isOpen) {
    return null;
  }

  return (
    <div className="relationship-board-modal">
      <div className="relationship-board-modal_background" />
      <div className="relationship-board-modal_content">
        <div className="relationship-board-modal_content-image">
          <RelationshipsPiplenesIllustration />
        </div>
        <h2 className="relationship-board-modal_content-title">Unlock Pipelines</h2>
        <p className="relationship-board-modal_content-info">
          Stay organized with your deals and collaborations. Available on Pro and Unlimited plans.
        </p>
        <Button
          className="relationship-board-modal_content-button"
          onClick={() => redirectUserTo(history, '/settings/billing/change-subscription')}
        >
          Upgrade my plan
        </Button>
      </div>
    </div>
  );
}

const getFilterFields = (members: { value: string | number; label: string }[]): FilterField[] =>
  useMemo(
    () => [
      {
        label: 'Creation date',
        value: StageOpportunityFilterField.DATE_OPPORTUNITY_CREATED_AT,
        type: 'date',
      },
      {
        label: 'Creator',
        value: StageOpportunityFilterField.DROPDOWN_OPPORTUNITY_OWNER_ID,
        type: 'enumString',
        inputOptions: members,
      },
      {
        label: 'Assignee',
        value: StageOpportunityFilterField.DROPDOWN_OPPORTUNITY_ASSIGNED_TO_USER_ID,
        type: 'enumString',
        inputOptions: [{ value: -1, label: 'Unassigned' }, ...members],
      },
      {
        label: 'Website Domain',
        type: 'string',
        value: StageOpportunityFilterField.RELATIONSHIP_WEBSITE_DOMAIN,
      },
      {
        label: 'Website Title',
        type: 'string',
        value: StageOpportunityFilterField.RELATIONSHIP_WEBSITE_NAME,
      },
    ],
    [members]
  );

const initialFilterState: Record<string, FilterFieldType<StageOpportunityFilterFieldMap>> = {
  '1': {
    order: 0,
    field: StageOpportunityFilterField.DATE_OPPORTUNITY_CREATED_AT,
    value: new Date(),
    operation: FilterOperationsMapForDate[1].value,
    join: FilterJoins[0].value,
    isReverseCheck: false,
  },
};

function RelationshipsPipelines(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const sortableItemsRef = useRef<HTMLDivElement>(null);
  const { members } = useWorkspaceMembers();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const sortable = useRef(null);
  const queryClient = useQueryClient();
  const {
    items: pipelines,
    addItem: addPipelineItem,
    removeItem: removePipelineItem,
    updateItem: updatePipelineItem,
    isEmpty: isPipelinesEmpty,
    isLoading: isLoadingPipelines,
  } = useRelationshipPipelines(currentWorkspaceId);
  const [appliedFilters, setAppliedFilters] = useState<FilterFieldType<StageOpportunityFilterFieldMap>[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isOpenedSidebarCRM, setOpenedSidebarCRM] = useState(0);
  const [isOpenedDealsSidebar, setOpenedDealsSidebar] = useState<boolean>(false);
  const [sidebarCRMHeaderData, setSidebarCRMHeaderData] = useState({
    logoUrl: '',
    title: '',
    url: '',
  });
  const [sidebarCRMContentData, setSidebarCRMContentData] = useState(null);
  const [chosenPipeline, setChosenPipeline] = useState(null);
  const [dealStageId, setDealStageId] = useState(null);

  const pipelineId = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return Number.parseInt(query.get('id'), 10) || 0;
  }, [location.search]);

  const {
    items: stages,
    addItem: addStageItem,
    removeItem: removeStageItem,
    refetch: refetchStages,
  } = useRelationshipPipelineStages(pipelineId, currentWorkspaceId);

  const {
    items: opportunitiesByFilters,
    refetch: refetchOpportunitiesWithFilters,
    fetchNextPage: fetchNextOpportunitiesWithFiltersPage,
    hasNextPage: hasNextOpportunitiesWithFiltersPage,
    addItem: addOpportunityByFilters,
  } = useRelationshipOpportunitiesWithFilters(
    currentWorkspaceId,
    pipelineId,
    searchQuery,
    appliedFilters
  );

  useEffect(() => {
    if (isPipelinesEmpty && !isLoadingPipelines && currentWorkspaceId) {
      const currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.delete('id');
      redirectUserToSearchParams(history, {
        search: currentUrlParams.toString(),
      });
      return;
    }
    if (pipelines?.length > 0 && pipelineId > 0) {
      if (pipelines.find((it) => it.id === pipelineId) === undefined) {
        const currentUrlParams = new URLSearchParams(location.search);
        currentUrlParams.set('id', pipelines[0].id.toString());
        redirectUserToSearchParams(history, {
          search: currentUrlParams.toString(),
        });
        return;
      }
    }
    if (pipelineId === 0 && pipelines?.length) {
      const currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('id', pipelines[0].id.toString());
      redirectUserToSearchParams(history, {
        search: currentUrlParams.toString(),
      });
    }

    if (chosenPipeline === null && pipelines?.length) {
      setChosenPipeline(pipelines[0]);
    }
  }, [pipelineId, pipelines]);

  useEffect(() => {
    if (stages?.length > 1 && sortableItemsRef.current) {
      sortable.current = new Sortable(sortableItemsRef.current, {
        swapThreshold: 1,
        group: 'shared-column',
        animation: 150,
        selectedClass: 'sortable-selected',
        swap: true,
        onEnd(evt) {
          if (currentWorkspaceId > 0 && pipelineId > 0) {
            const itemEl = evt.item;
            const stageId = parseInt(itemEl.dataset.stageId, 10);
            const prevStageId =
              Array.from(evt.to.querySelectorAll('.pipeline-column'))
                .map((item: any) => parseInt(item.dataset.stageId, 10))
                .find((item, index, arr) => {
                  if (arr[index + 1] === stageId) {
                    return item;
                  }
                }) || -1;
            if (stageId > 0) {
              moveStageApi(currentWorkspaceId, pipelineId, stageId, prevStageId).then(() => {
                console.log('success');
              });
            }
          }
        },
      });
      return () => {
        sortable.current.destroy();
      };
    }
  }, [stages?.length]);

  const handleCreateStages = useCallback(() => {
    createStageApi(pipelineId, 'New stage').then((response) => {
      addStageItem(response);
    });
  }, [currentWorkspaceId, pipelineId]);

  const handleStageDelete = useCallback(
    (stageId: number) => {
      deleteStageApi(stageId, pipelineId, currentWorkspaceId).then(() => {
        removeStageItem(stageId);
      });
    },
    [currentWorkspaceId, pipelineId]
  );

  const handleCreatePipeline = useCallback(
    (title?: string) => {
      createPipelineApi(currentWorkspaceId, title).then((response) => {
        addPipelineItem(response);
      });
    },
    [currentWorkspaceId]
  );

  const selectCard = useCallback((oppoId: number, isAdded: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      return isAdded
        ? [...prevSelectedItems, oppoId]
        : prevSelectedItems.filter((item) => item !== oppoId);
    });
  }, []);

  const mapToFolder = useCallback((data: RelationshipPipelineType[]): FolderType[] => {
    return (
      data?.map((it) => ({
        id: it.id,
        isOwner: false,
        title: it.title,
        relatedid: -1,
      })) || []
    );
  }, []);

  const handleCloseSidebarCRM = () => {
    setOpenedSidebarCRM(0);
  };

  const handleCardClick = (opportunity: PipelineOpportunityType) => {
    const stage = stages.find(({ id }) => id === opportunity.stageId);

    setSidebarCRMHeaderData({
      logoUrl: opportunity.relatedLogo,
      title: opportunity.relatedName,
      url: opportunity.relatedDomain,
    });
    setSidebarCRMContentData({
      opportunityId: opportunity.id,
      assignedToUserId: opportunity.assignedToUserId,
      pipelineTitle: chosenPipeline.title,
      stageId: stage.id,
      stageTitle: stage.title,
      createdAt: opportunity.createdAt,
    });
    setOpenedSidebarCRM(opportunity.relatedId);
  };

  const handleChoosePipeline = (pipeline: FolderType) => {
    setChosenPipeline(pipeline);
  };

  const handleRemovePipeline = async (id: number) => {
    return deletePipelineApi(id, currentWorkspaceId).then(() => removePipelineItem(id));
  };

  const handleRenamePipeline = async (id: number, title: string) => {
    return updatePipelineApi(id, currentWorkspaceId, title).then((response) =>
      updatePipelineItem(id, response)
    );
  };

  const openCreateDealSideBar = (stageId: number) => {
    setDealStageId(stageId);
    setOpenedDealsSidebar(true);
  };

  function handleApplyFilters(filters: FilterFieldType<StageOpportunityFilterFieldMap>[]) {
    setAppliedFilters(filters);
    if (filters.length === 0) {
      refetchStages();
    }
  }

  useEffect(() => {
    if (appliedFilters.length > 0 || searchQuery?.length > 0) {
      refetchOpportunitiesWithFilters();
    }
  }, [JSON.stringify(appliedFilters), searchQuery]);

  function onMoveOpportunityWithFilter(
    evt: any,
    opportunityId: number,
    movedOpportunity: PipelineOpportunityType,
    filters: FilterFieldType<StageOpportunityFilterFieldMap>[]
  ) {
    const sourceSearchKey = [
      DEALS_WITH_FILTERS_QUERY_KEY,
      pipelineId,
      currentWorkspaceId,
      searchQuery,
      filters,
    ];
    queryClient.setQueryData(sourceSearchKey, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: PipelineOpportunityType) => item.id !== opportunityId)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

    const targetSearchKey = [
      DEALS_WITH_FILTERS_QUERY_KEY,
      pipelineId,
      currentWorkspaceId,
      searchQuery,
      filters,
    ];
    queryClient.setQueryData(targetSearchKey, ({ pages, pageParams }) => {
      const allItems = pages.flat() as PipelineOpportunityType[];
      allItems.splice(evt.newIndex, 0, movedOpportunity);
      const recalculatedItems = allItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      recalculatedItems.sort((a, b) => (a.order > b.order ? 1 : -1));
      const pageSize = DEALS_WITH_FILTERS_PAGE_LIMIT;
      const updatedPages: PipelineOpportunityType[][] = [];
      for (let i = 0; i < recalculatedItems.length; i += pageSize) {
        updatedPages.push(recalculatedItems.slice(i, i + pageSize));
      }

      return { pageParams, pages: updatedPages };
    });

    queryClient.removeQueries(['websites-sidebar-opportunities', opportunityId]);
  }

  const addToCacheNewOpportunity = (item: PipelineOpportunityType) => {
    const key = [DEALS_WITHOUT_FILTERS_QUERY_KEY, pipelineId, currentWorkspaceId, item.stageId];
    queryClient.setQueryData(key, (cachedData) => {
      // @ts-ignore
      if (cachedData?.pages == null || cachedData?.pages.length === 0) {
        return {
          pageParams: undefined,
          pages: [[item]],
        };
      }
      // @ts-ignore
      const { pages, pageParams } = cachedData;
      const updatedPages = [...pages];
      updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], item];

      return {
        pageParams,
        pages: updatedPages,
      };
    });
  };

  function onCreateNewOpportunity(response: PipelineOpportunityType) {
    queryClient.setQueryData(
      [PIPELINE_COLUMN_QUERY_KEY, currentWorkspaceId, pipelineId],
      (columns: RelationshipPipelineStageType[] = []) => {
        return columns.map((column) => {
          if (column.id === response.stageId) {
            return {
              ...column,
              opportunitiesList: [...column.opportunitiesList, response],
              opportunitiesNumber: column.opportunitiesNumber + 1,
            };
          }

          return column;
        });
      }
    );

    addOpportunityByFilters(response);
    addToCacheNewOpportunity(response);
  }

  return (
    <div className="relationships-pipelines">
      <PageHeader title="Pipelines">
        <HeaderActions
          // selectedItems={selectedItems}
          handleRemoveItems={() => console.error('not implementer')}
          handleSearchValue={(value) => setSearchQuery(value)}
          // moreActions={[
          //   { title: 'test', handler: () => console.log('test 1 !!'), iconName: 'user' },
          //   { title: 'test 2', handler: () => console.log('test 2 !!'), iconName: 'folderWhite' },
          //   { title: 'test 3', handler: () => console.log('test 3 !!'), iconName: 'folderWhite' },
          // ]}
          initialFilterState={initialFilterState}
          filterFields={getFilterFields(
            members.map((item) => ({
              value: item.userId,
              label: getFirstNamePlusBigFirstCharOfLastName(item.fullName),
            }))
          )}
          appliedFilters={appliedFilters}
          onApplyFilters={(filters) => handleApplyFilters(filters)}
          addNewBtn={{ title: 'Create new deal', handleClick: () => openCreateDealSideBar(-1) }}
        />
      </PageHeader>
      <Folders
        path={`/workspaces/${currentWorkspaceId}/relationships/pipelines`}
        items={mapToFolder(pipelines)}
        handleCreate={handleCreatePipeline}
        handleChoose={handleChoosePipeline}
        entityName="Pipeline"
        paramName="id"
        onRemoveClick={(id) => handleRemovePipeline(id)}
        onRenameClick={(id, title) => handleRenamePipeline(id, title)}
      />
      <div className="relationships-pipelines__column-container">
        <div className="relationships-pipelines__columns" ref={sortableItemsRef}>
          {stages
            ?.sort((a, b) => a.order - b.order)
            .map((stage) => (
              <PipelineColumn
                key={stage.id}
                pipelineId={pipelineId}
                stage={stage}
                appliedFilters={appliedFilters}
                searchQuery={searchQuery}
                selectCard={selectCard}
                handleStageDelete={handleStageDelete}
                openCreateDealSideBar={openCreateDealSideBar}
                handleCardClick={handleCardClick}
                opportunitiesByFilter={
                  opportunitiesByFilters?.filter((it) => it.stageId === stage.id) || []
                }
                onMoveOpportunityWithFilter={(
                  evt: any,
                  opportunityId: number,
                  movedOpportunity: PipelineOpportunityType,
                  filters: FilterFieldType<StageOpportunityFilterFieldMap>[]
                ) => onMoveOpportunityWithFilter(evt, opportunityId, movedOpportunity, filters)}
                hasNextPageWithFilters={hasNextOpportunitiesWithFiltersPage}
                fetchNextPageWithFilters={() => fetchNextOpportunitiesWithFiltersPage()}
              />
            ))}
        </div>
        {pipelineId > 0 && (
          <Button
            className="add-button"
            type="ghost"
            leftIcon="plusCircle"
            onClick={handleCreateStages}
            colorIcon="#BDBDBD"
          >
            New stage
          </Button>
        )}
      </div>
      <PipelinesSidebar
        isOpen={!!isOpenedSidebarCRM}
        itemId={isOpenedSidebarCRM}
        onClose={handleCloseSidebarCRM}
        headerData={sidebarCRMHeaderData}
        contentData={sidebarCRMContentData}
        pipelineId={pipelineId}
        pipelineStages={stages}
        searchQuery={searchQuery}
        appliedFilters={appliedFilters}
      />
      <DealsSidebar
        isOpen={!!isOpenedDealsSidebar}
        onClose={() => setOpenedDealsSidebar(false)}
        stageId={dealStageId}
        onSave={(response) => onCreateNewOpportunity(response)}
      />
    </div>
  );
}

function WrapperRelationshipsPipelines(): JSX.Element {
  const {
    loadingStatus,
    data: { extendedFeaturesEnabled },
  } = useSelector(billingDetailsSelector);

  const workspaceId = useCurrentWorkspaceId();
  if (loadingStatus !== loadingStatuses.LOADED) {
    return null;
  }

  if (extendedFeaturesEnabled) {
    return <RelationshipsPipelines />;
  }

  const stagesMock = [
    {
      id: 39,
      title: 'Scheduled',
      order: 1,
      opportunitiesNumber: 2,
      trackTime: false,
      targetTimeInDays: 2,
      opportunitiesList: [
        {
          id: 73,
          stageId: 39,
          order: 1,
          relatedTo: 1,
          relatedId: 4510,
          relatedName: 'Respona',
          relatedDomain: 'respona.com',
          relatedLogo: '',
          createdByUserId: 121,
          createdAt: 1726985198470,
          targetTimeAt: 1727110866582,
          assignedToUserId: 121,
        },
        {
          id: 79,
          stageId: 39,
          order: 2,
          relatedTo: 1,
          relatedId: 14477,
          relatedName: 'Respona',
          relatedDomain: 'respona.com',
          relatedLogo: '',
          createdByUserId: 121,
          createdAt: 1727111183769,
          targetTimeAt: 1727111183769,
          assignedToUserId: 121,
        },
      ],
    },
    {
      id: 84,
      title: 'Live',
      order: 3,
      opportunitiesNumber: 0,
      trackTime: false,
      targetTimeInDays: 2,
      opportunitiesList: [],
    },
    {
      id: 55,
      title: 'Negotiating',
      order: 2,
      opportunitiesNumber: 1,
      trackTime: false,
      targetTimeInDays: 2,
      opportunitiesList: [
        {
          id: 80,
          stageId: 55,
          order: 1,
          relatedTo: 1,
          relatedId: 14478,
          relatedName: 'Respona',
          relatedDomain: 'respona.com',
          relatedLogo: '',
          createdByUserId: 121,
          createdAt: 1727116678942,
          targetTimeAt: 1727117077748,
          assignedToUserId: 121,
        },
      ],
    },
  ] as any;

  const foldersMock = [
    {
      id: 1,
      isOwner: false,
      title: 'First pipeline',
      relatedid: -1,
      workspaceIdsList: [106],
      customVariablesList: [],
    },
    {
      id: 2,
      isOwner: false,
      title: 'Guest posts',
      relatedid: -1,
      workspaceIdsList: [106],
      customVariablesList: [],
    },
    {
      id: 3,
      isOwner: false,
      title: 'Bloggers',
      relatedid: -1,
      workspaceIdsList: [106],
      customVariablesList: [],
    },
    {
      id: 4,
      isOwner: false,
      title: 'Partnerships',
      relatedid: -1,
      workspaceIdsList: [106],
      customVariablesList: [],
    },
  ];

  return (
    <div className="relationships-pipelines__mock">
      <PageHeader title="Pipelines" />
      <Folders
        path={`/workspaces/${workspaceId}/relationships/pipelines`}
        items={foldersMock}
        handleCreate={() => {}}
        handleChoose={() => {}}
        entityName="Pipeline"
        paramName="id1"
        onRemoveClick={(id) => new Promise(() => {})}
        onRenameClick={(id, title) => new Promise(() => {})}
      />

      <div className="relationships-pipelines__columns">
        {stagesMock
          ?.sort((a, b) => a.order - b.order)
          .map((stage) => (
            <PipelineColumn
              key={stage.id}
              pipelineId={1}
              stage={stage}
              appliedFilters={[]}
              searchQuery=""
              selectCard={() => {}}
              handleStageDelete={() => {}}
              openCreateDealSideBar={() => {}}
              handleCardClick={() => {}}
              opportunitiesByFilter={[]}
              onMoveOpportunityWithFilter={() => {}}
              fetchNextPageWithFilters={() => {}}
              hasNextPageWithFilters={false}
            />
          ))}
        <Button
          className="add-button"
          type="ghost"
          leftIcon="plusCircle"
          onClick={() => {}}
          colorIcon="#BDBDBD"
        >
          New stage
        </Button>
      </div>

      <RelationshipPipelinesPopup />
    </div>
  );
}

export default WrapperRelationshipsPipelines;
