/**
 * NOT CHECKED
 */
import React, { MouseEventHandler } from 'react';

import Avatar from '@uikit/Avatar/Avatar';
import { SVGIcon } from '@uikit/Icon/Icon';

import './OwnerSharingCircle.scss';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

type OwnerSharingCircleType = {
  avatarUrl: string;
  userId: number;
  onClick: MouseEventHandler<HTMLDivElement>;
  useSharing?: boolean;
};

const OwnerSharingCircle = ({
  avatarUrl,
  userId,
  onClick,
  useSharing = true,
}: OwnerSharingCircleType): JSX.Element => {
  const { members: workspaceMembers } = useWorkspaceMembers();

  const fullName = () => {
    if (workspaceMembers == null || workspaceMembers.length <= 0) {
      return '-';
    }
    const user = workspaceMembers.find((it) => it.userId === userId);
    return user?.fullName || 'Deleted User';
  };

  return (
    <div
      className="owner-sharing-circle"
      onClick={useSharing ? onClick : () => null}
      tabIndex={0}
      role="button"
    >
      <Avatar url={avatarUrl} fullName={fullName()} />
      {useSharing ? (
        <div className="owner-sharing-circle__svg-pen-wrapper">
          <SVGIcon icon="pen" color="#fff" />
        </div>
      ) : null}
    </div>
  );
};

export default OwnerSharingCircle;
