import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import { FolderType, TagType, TagsType, NoteType } from '@ts/common.types';
import {
  PeopleOpportunityType,
  PersonBaseType,
  PersonType,
  RelationshipStatusType,
} from '@ts/people.types';

import request from '@api/base/request';
import streamRequest from '@api/base/streamRequest';
import peopleService from '@api/services/peopleService';
import * as common_pb from 'respona_api/generated/common_pb';

import CommonModels, {
  EntityRelationRequest,
  FileResponse,
  IdRequest,
  PageRequest,
  FilterOperation,
  FilterJoinType,
} from 'respona_api/generated/common_pb';
import PeopleModels, {
  ExportPeopleRequest,
  PeopleFilterField, PeopleFilterFieldMap,
  PeopleShortResponse,
  PeopleTableColumns,
  PersonInfoRequest,
  PersonInfoUpdateRequest,
} from 'respona_api/generated/people_pb';
import ExportType = ExportPeopleRequest.ExportType;
import { FilterFieldType } from '@ts/filterField.type';

export const createPersonApi = ({
  name,
  emailsList,
  jobPosition = undefined,
  accuracy = 0,
  company,
  workspaceId,
  websiteName,
}: {
  name: string;
  emailsList: any[];
  location?: string;
  jobPosition?: string;
  accuracy?: number;
  customFields?: any;
  company?: any;
  workspaceId: number;
  websiteName?: string;
}): Promise<PersonBaseType> => {
  const client = peopleService.getInstance();
  const createPersonRequest = new PeopleModels.PeopleCrudRequest();
  createPersonRequest.setName(name);
  createPersonRequest.setWorkspaceId(workspaceId);
  createPersonRequest.setEmailsList(
    emailsList.map(({ email, accuracy: emailAccuracy }) => {
      const emailRequest = new CommonModels.Email();

      emailRequest.setEmail(email);
      emailRequest.setAccuracy((emailAccuracy || accuracy) as 0 | 1 | 2 | 3);

      return emailRequest;
    })
  );

  // TODO check what we have in company object
  if (typeof company?.domain === 'string') {
    createPersonRequest.setWebsiteDomain(company?.domain);
  } else if (
    company?.domain != null &&
    Object.prototype.hasOwnProperty.call(company.domain, 'domain')
  ) {
    createPersonRequest.setWebsiteDomain(company.domain.domain);
    createPersonRequest.setWebsiteName(company.domain.name);
    createPersonRequest.setWebsiteLogo(company.domain.logo);
  }

  if (websiteName) {
    createPersonRequest.setWebsiteName(websiteName);
  }

  createPersonRequest.setJobPosition(jobPosition || '');

  return request<PersonBaseType>(client, client.create, createPersonRequest);
};

export const updatePersonApi = ({
  id,
  workspaceId,
  name,
  emailsList,
  jobPosition,
  company,
  status,
}: {
  id: number;
  workspaceId?: number;
  name: string;
  emailsList: any[];
  jobPosition?: string;
  company?: any;
  status: common_pb.PeopleRelationshipStatusMap[keyof common_pb.PeopleRelationshipStatusMap];
}): Promise<PersonBaseType> => {
  const client = peopleService.getInstance();
  const peopleRequest = new PeopleModels.PeopleCrudRequest();

  peopleRequest.setId(id);
  peopleRequest.setWorkspaceId(workspaceId);
  peopleRequest.setName(name);
  peopleRequest.setEmailsList(
    emailsList.map(({ email, accuracy }) => {
      const emailRequest = new CommonModels.Email();
      emailRequest.setEmail(email);
      emailRequest.setAccuracy(accuracy);
      return emailRequest;
    })
  );

  peopleRequest.setJobPosition(jobPosition || '');

  if (company) {
    peopleRequest.setWebsiteDomain(company.domain);
    peopleRequest.setWebsiteName(company.name);
    peopleRequest.setWebsiteLogo(company.logo);
  }

  if (status) {
    peopleRequest.setStatus(status);
  }

  return request<PersonBaseType>(client, client.update, peopleRequest);
};

export const getAllPeopleApi = (
  page: number,
  limit: number,
  optionalFolderId: number,
  optionalFiltersList: FilterFieldType<PeopleFilterFieldMap>[] = [],
  optionalSearchQuery?: string,
  additionalColumns: { id: number; name: string }[] = []
): Promise<PersonType[]> => {
  const client = peopleService.getInstance();
  const peopleRequest = new PeopleModels.PeoplePageRequest();

  peopleRequest.setPage(page);
  peopleRequest.setLimit(limit);

  if (optionalFolderId) {
    peopleRequest.setOptionalFolderId(optionalFolderId);
  }

  peopleRequest.setOptionalFiltersList(
    optionalFiltersList
      .filter((item) => {
        if (typeof item.value === 'string') {
          return item.value.trim().length > 0;
        }
        if (
          item.value != null &&
          typeof item.value === 'object' &&
          Object.prototype.hasOwnProperty.call(item.value, 'value')
        ) {
          // @ts-ignore
          const { value } = item.value;
          if (typeof value === 'string') {
            return value.trim().length > 0;
          }
          if (typeof value === 'number') {
            return value > 0;
          }
          if (!Number.isNaN(Number(value))) {
            return Number(value) > 0;
          }
        }
        return false;
      })
      .map((item) => {
        if (
          item.field === PeopleFilterField.DROPDOWN_PEOPLE_OWNER_ID ||
          item.field === PeopleFilterField.DROPDOWN_CAMPAIGN_ID ||
          item.field === PeopleFilterField.DROPDOWN_WORKSPACE_ID
        ) {
          return {
            ...item, // @ts-ignore
            value: item.value.value?.toString(),
            operation:
              item.operation === FilterOperation.NUMBER_EQUAL
                ? FilterOperation.STRING_EQUAL
                : FilterOperation.STRING_EQUAL_NOT,
          };
        }

        return item;
      })
      .map((filterData) => {
        const filter = new PeopleModels.PeopleFilter();

        filter.setOrder(filterData.order);
        filter.setField(filterData.field);

        // @ts-ignore
        if (filterData.value?.value !== undefined) {
          filter.setValue(
            // @ts-ignore
            filterData.value?.value.toString // @ts-ignore
              ? filterData.value?.value.toString() // @ts-ignore
              : filterData.value?.value
          );
        } else if (filterData.value.toString) {
          filter.setValue(filterData.value.toString());
        } else {
          filter.setValue(filterData.value);
        }

        filter.setOperation(filterData.operation);
        filter.setJoin(filterData.join);

        return filter;
      })
  );

  if (optionalSearchQuery) {
    const nameFilter = new PeopleModels.PeopleFilter();

    nameFilter.setField(PeopleFilterField.STRING_NAME);
    nameFilter.setValue(optionalSearchQuery);
    nameFilter.setOperation(FilterOperation.STRING_CONTAINS);
    nameFilter.setJoin(FilterJoinType.JOIN_QUERY_OR);

    const emailFilter = new PeopleModels.PeopleFilter();

    emailFilter.setField(PeopleFilterField.STRING_EMAIL);
    emailFilter.setValue(optionalSearchQuery);
    emailFilter.setOperation(FilterOperation.STRING_CONTAINS);
    emailFilter.setJoin(FilterJoinType.JOIN_QUERY_OR);

    peopleRequest.addOptionalFilters(nameFilter);
    peopleRequest.addOptionalFilters(emailFilter);
  }

  if (additionalColumns?.length) {
    peopleRequest.setAdditionalColumnsList(
      additionalColumns.map(({ id, name }) => {
        const column = new PeopleTableColumns();

        column.setId(id);
        column.setName(name);

        return column;
      })
    );
  }

  return streamRequest<PersonType[]>(client, client.getAllPeople, peopleRequest);
};

export const getAllFoldersApi = (): Promise<FolderType[]> => {
  const client = peopleService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<FolderType[]>(client, client.getAllFolders, emptyRequest);
};

export const updatePersonStatus = (
  personId: number,
  personStatus: RelationshipStatusType
): Promise<void> => {
  const client = peopleService.getInstance();
  const personRequest = new PeopleModels.PersonRelationshipStatusRequest();

  personRequest.setPersonId(personId);
  personRequest.setStatus(personStatus);

  return request<void>(client, client.updateRelationshipStatus, personRequest);
};

export const getAllTagsApi = (): Promise<TagType[]> => {
  const client = peopleService.getInstance();
  const emptyRequest = new google_protobuf_empty_pb.Empty();

  return streamRequest<TagType[]>(client, client.getAllTags, emptyRequest);
};

export const createTagApi = (title: string): Promise<TagType> => {
  const client = peopleService.getInstance();
  const tagRequest = new CommonModels.Tag();

  tagRequest.setTitle(title);

  return request<TagType>(client, client.addTag, tagRequest);
};

export const addTagApi = (tagId: number, tagTitle: string, personId: number): Promise<TagType> => {
  const client = peopleService.getInstance();
  const tagRequest = new CommonModels.Tag();

  if (tagId) {
    tagRequest.setId(tagId);
  }
  tagRequest.setRelatedId(personId);
  tagRequest.setTitle(tagTitle);

  return request<TagType>(client, client.addTag, tagRequest);
};

export const removeTagApi = (tagId: number, personId: number) => {
  const client = peopleService.getInstance();
  const removeRequest = new CommonModels.IdRequest();

  removeRequest.setId(tagId);
  removeRequest.setOptionalRelatedId(personId);

  return request(client, client.removeTag, removeRequest);
};

export const removePersonApi = (id: number): Promise<void> => {
  const client = peopleService.getInstance();
  const removeRequest = new CommonModels.IdRequest();

  removeRequest.setId(id);

  return request<void>(client, client.remove, removeRequest);
};

export const removePersonBatchApi = (
  peopleIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
): Promise<void> => {
  const client = peopleService.getInstance();
  const removeRequest = new common_pb.EntityRelationRequest();

  if (peopleIds != null && peopleIds.length > 0) {
    removeRequest.setRelatedIdsList(peopleIds);
  }
  removeRequest.setType(type);

  return request<void>(client, client.batchRemove, removeRequest);
};

export const createNewFolderApi = (title: string): Promise<FolderType> => {
  const client = peopleService.getInstance();
  const folderRequest = new CommonModels.Folder();

  folderRequest.setTitle(title);

  return request<FolderType>(client, client.createFolder, folderRequest);
};

export const movePersonToFolderApi = (
  folderId: number,
  personsIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
): Promise<void> => {
  const client = peopleService.getInstance();
  const moveRequest = new CommonModels.EntityRelationRequest();

  moveRequest.setId(folderId);
  moveRequest.setRelatedIdsList(personsIds);
  moveRequest.setType(type);

  return request<void>(client, client.addPeopleToFolder, moveRequest);
};

export const removePeopleFromFolderApi = (
  folderId: number,
  personsIds: number[],
  type: EntityRelationRequest.SelectionTypeMap[keyof EntityRelationRequest.SelectionTypeMap]
): Promise<void> => {
  const client = peopleService.getInstance();
  const removeRequest = new CommonModels.EntityRelationRequest();

  removeRequest.setId(folderId);
  removeRequest.setRelatedIdsList(personsIds);
  removeRequest.setType(type);

  return request(client, client.removePeopleFromFolder, removeRequest);
};

export const removeFolderApi = (folderId: number) => {
  const client = peopleService.getInstance();
  const folderRemoveRequest = new CommonModels.IdRequest();

  folderRemoveRequest.setId(folderId);

  return request(client, client.deleteFolder, folderRemoveRequest);
};

export const renameFolderApi = (folderId: number, folderName: string): Promise<FolderType> => {
  const client = peopleService.getInstance();
  const folderRenameRequest = new CommonModels.Folder();

  folderRenameRequest.setId(folderId);
  folderRenameRequest.setTitle(folderName);

  return request<FolderType>(client, client.updateFolder, folderRenameRequest);
};

export const getTagsByPersonIdApi = (personId: number, workspaceId?: number): Promise<TagsType> => {
  const client = peopleService.getInstance();
  const tagsRequest = new CommonModels.IdRequest();

  tagsRequest.setId(personId);
  tagsRequest.setOptionalWorkspaceId(workspaceId);

  return request<TagsType>(client, client.getTagsByPersonId, tagsRequest);
};

export const getBasePersonByIdApi = (personId: number): Promise<PersonBaseType> => {
  const client = peopleService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(personId);

  return request<PersonBaseType>(client, client.getPersonById, idRequest);
};

export const getAllNotesApi = (personId: number): Promise<NoteType[]> => {
  const client = peopleService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(personId);

  return streamRequest<NoteType[]>(client, client.getNotesByPersonId, idRequest);
};

export const addNoteApi = (content: string, personId: number): Promise<NoteType> => {
  const client = peopleService.getInstance();
  const noteRequest = new CommonModels.Note();

  noteRequest.setContent(content);
  noteRequest.setRelatedId(personId);
  noteRequest.setCreatedAt(+new Date());

  return request<NoteType>(client, client.addNote, noteRequest);
};

export const updateNoteApi = (
  noteId: number,
  newContent: string,
  personId: number
): Promise<NoteType> => {
  const client = peopleService.getInstance();
  const noteRequest = new CommonModels.Note();

  noteRequest.setId(noteId);
  noteRequest.setContent(newContent);
  noteRequest.setRelatedId(personId);

  return request<NoteType>(client, client.updateNote, noteRequest);
};

export const removeNoteApi = (noteId: number, personId: number): Promise<void> => {
  const client = peopleService.getInstance();
  const idRequest = new CommonModels.IdRequest();

  idRequest.setId(noteId);
  idRequest.setOptionalRelatedId(personId);

  return request<void>(client, client.removeNote, idRequest);
};

export type ExportPeopleToCSVApiOptionsType = {
  setUnverified: boolean;
  setWork: boolean;
  setPersonal: boolean;
  setGeneric: boolean;
};

export const exportPeopleToCSVApi = (
  type: ExportPeopleRequest.ExportTypeMap[keyof ExportPeopleRequest.ExportTypeMap],
  options: ExportPeopleToCSVApiOptionsType,
  peopleIds?: number[],
  filters?: FilterFieldType<PeopleFilterFieldMap>[],
  searchQuery?: string,
  typeConfig?: { campaignId: number }
): Promise<{ complete: boolean; file: FileResponse.AsObject }[]> => {
  const client = peopleService.getInstance();
  const exportPeopleRequest = new PeopleModels.ExportPeopleRequest();
  exportPeopleRequest.setType(type);
  if (typeConfig?.campaignId > 0) {
    exportPeopleRequest.setOptionalId(typeConfig.campaignId);
  }
  if (peopleIds?.length > 0) {
    exportPeopleRequest.setOptionalSelectedList(peopleIds);
  }
  exportPeopleRequest.setInvalidEmails(options.setUnverified);
  exportPeopleRequest.setWorkEmails(options.setWork);
  exportPeopleRequest.setPersonalEmails(options.setPersonal);
  exportPeopleRequest.setGenericEmails(options.setGeneric);

  if (searchQuery) {
    exportPeopleRequest.setOptionalSearchQuery(searchQuery);
  }

  // TODO: for now filters should not be applied
  //  since we use only "selected" items and there is no way to combine "SELECTED" and "FILTERED" for now
  if (false && filters?.length) {
    exportPeopleRequest.setType(ExportType.FILTERED);
    exportPeopleRequest.setOptionalFiltersList(
      filters
        .map((item) => {
          if (item.field === PeopleFilterField.DROPDOWN_PEOPLE_OWNER_ID) {
            return {
              ...item, // @ts-ignore
              value: item.value.value?.toString(),
              operation:
                item.operation === FilterOperation.NUMBER_EQUAL
                  ? FilterOperation.STRING_EQUAL
                  : FilterOperation.STRING_EQUAL_NOT,
            };
          }

          return item;
        })
        .map((filterData) => {
          const filter = new PeopleModels.PeopleFilter();

          filter.setOrder(filterData.order);
          filter.setField(filterData.field);

          // @ts-ignore
          if (filterData.value?.value !== undefined) {
            filter.setValue(
              // @ts-ignore
              filterData.value?.value.toString // @ts-ignore
                ? filterData.value?.value.toString() // @ts-ignore
                : filterData.value?.value
            );
          } else if (filterData.value.toString) {
            filter.setValue(filterData.value.toString());
          } else {
            filter.setValue(filterData.value);
          }

          filter.setOperation(filterData.operation);
          filter.setJoin(filterData.join);

          return filter;
        })
    );
  }

  return streamRequest(client, client.exportToCsv, exportPeopleRequest);
};

export const addToBlackListApi = (ids: number[], workspaceId: number) => {
  const client = peopleService.getInstance();
  const idsContainer = new CommonModels.IdContainer();

  idsContainer.setIdList(ids);
  idsContainer.setOptionalWorkspaceId(workspaceId);

  return request(client, client.addToBlacklist, idsContainer);
};

export const getCampaignOpportunitiesByPersonIdApi = (
  id: number,
  workspaceId: number
): Promise<PeopleOpportunityType[]> => {
  const client = peopleService.getInstance();
  const opportunitiesRequest = new IdRequest();

  opportunitiesRequest.setId(id);
  opportunitiesRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<PeopleOpportunityType[]>(
    client,
    client.getCampaignOpportunitiesByPersonId,
    opportunitiesRequest
  );
};

export const getCampaignOpportunitiesByPersonIdInApi = (
  personIds: number[],
  workspaceId: number
): Promise<PeopleOpportunityType[]> => {
  const client = peopleService.getInstance();
  const opportunitiesRequest = new CommonModels.IdContainer();

  opportunitiesRequest.setIdList(personIds);
  opportunitiesRequest.setOptionalWorkspaceId(workspaceId);

  return streamRequest<PeopleOpportunityType[]>(
    client,
    client.getCampaignOpportunitiesByPersonIdIn,
    opportunitiesRequest
  );
};

export const addEmailToPersonApi = (personId: number, email: string): Promise<void> => {
  const client = peopleService.getInstance();
  const opportunitiesRequest = new PersonInfoRequest();

  opportunitiesRequest.setPersonId(personId);
  opportunitiesRequest.setValue(email);

  return request<void>(client, client.addEmailToPerson, opportunitiesRequest);
};

export const updatePersonEmailApi = (
  personId: number,
  oldEmail: string,
  newEmail: string
): Promise<void> => {
  const client = peopleService.getInstance();
  const opportunitiesRequest = new PersonInfoUpdateRequest();

  opportunitiesRequest.setPersonId(personId);
  opportunitiesRequest.setValueFrom(oldEmail);
  opportunitiesRequest.setValueTo(newEmail);

  return request<void>(client, client.updatePersonEmail, opportunitiesRequest);
};

export const removePersonEmailApi = (personId: number, email: string): Promise<void> => {
  const client = peopleService.getInstance();
  const opportunitiesRequest = new PersonInfoRequest();

  opportunitiesRequest.setPersonId(personId);
  opportunitiesRequest.setValue(email);

  return request<void>(client, client.removePersonEmail, opportunitiesRequest);
};

export const updateSocialsApi = (
  personId: number,
  initialValue: string,
  newValue: string
): Promise<void> => {
  const client = peopleService.getInstance();
  const socRequest = new PersonInfoUpdateRequest();

  socRequest.setPersonId(personId);
  socRequest.setValueFrom(initialValue);
  socRequest.setValueTo(newValue);

  return request<void>(client, client.updateSocials, socRequest);
};

export const getPeopleByCompanyIdApi = (
  companyId: number,
  workspaceId: number
): Promise<PeopleShortResponse.AsObject[]> => {
  const client = peopleService.getInstance();
  const peopleRequest = new PageRequest();

  peopleRequest.setPage(0);
  peopleRequest.setLimit(200);
  peopleRequest.setOptionalWorkspaceId(workspaceId);
  peopleRequest.setOptionalEntityId(companyId);

  return streamRequest<PeopleShortResponse.AsObject[]>(
    client,
    client.getPeopleByCompanyId,
    peopleRequest
  );
};
