import React from 'react';

import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';

import SidebarCRMHeader from '@components/SidebarCRM/SidebarCRMHeader/SidebarCRMHeader';
import SidebarCRMContent from '@components/SidebarCRM/SidebarCRMContent/SidebarCRMContent';

import './SidebarCRM.scss';

export enum sidebarSize {
  SMALL = 'SMALL',
  NORMAL = 'NORMAL',
}

export enum ContentDataType {
  text = 'text',
  select = 'select',
  array = 'array',
}

export type GeneralDataType = {
  key: string;
  title: string;
  value: string | number | { id: string | number; value: string };
  type: ContentDataType;
  isEditable: boolean;
  transformCreatedAt?: boolean;
  options?: { value: number; label: string }[];
  onChange?: (value: number | string, id?: string) => void;
  icon?: SvgIconType;
};

type Props = {
  type: string;
  itemId: string | number;
  isOpen: boolean;
  onClose?: () => void;
  headerData: { logoUrl: string; title: string; url: string };
  contentData: GeneralDataType[];
  sectionsMeta: Record<string, any>;
  size?: sidebarSize;
  extraClass?: string;
};

function SidebarCRM({
  type,
  itemId,
  isOpen,
  onClose,
  headerData,
  contentData,
  sectionsMeta,
  size = sidebarSize.NORMAL,
  extraClass = '',
}: Props): JSX.Element {
  if (!isOpen) {
    return null;
  }

  const SidebarCRMHeaderComponent = (
    <SidebarCRMHeader logoUrl={headerData.logoUrl} title={headerData.title} url={headerData.url} />
  );
  const SidebarCRMContentComponent = (
    <SidebarCRMContent type={type} itemId={itemId} rows={contentData} sectionsMeta={sectionsMeta} />
  );
  const closeButton = (
    <div
      className="right-info-sidebar__cross-svg-close"
      onClick={onClose}
    >
      <SVGIcon icon="crossBlack" />
    </div>
  );

  if (size === sidebarSize.SMALL) {
    return (
      <div className={`sidebar-crm--small ${extraClass}`}>
        {onClose ? closeButton : null}
        {SidebarCRMHeaderComponent}
        {SidebarCRMContentComponent}
      </div>
    );
  }

  return (
    <RightInfoSidebar
      isOpen={isOpen}
      onClose={onClose}
      classNames={{
        container: 'sidebar-crm__container',
        header: 'sidebar-crm__header-container',
        closeIcon: 'sidebar-crm__close-icon',
        body: 'sidebar-crm__body',
      }}
      title={SidebarCRMHeaderComponent}
    >
      {SidebarCRMContentComponent}
    </RightInfoSidebar>
  );
}

export default SidebarCRM;
