import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AiAgentsList from '@components/AiAgents/AiAgentsList/AiAgentsList';
import AiAgentsChat from '@components/AiAgents/AiAgentsChat/AiAgentsChat';
import AiAgentInstructions from '@components/AiAgents/AiAgentInstructions/AiAgentInstructions';
import AiAgentActivityLog from '@components/AiAgents/AiAgentActivityLog/AiAgentActivityLog';
import AiAgentsWrapperPage from '@components/AiAgents/AiAgentsWrapperPage/AiAgentsWrapperPage';

import urls from '@constants/urls';

import './AiAgents.css';

function AiAgents() {
  return (
    <Switch>
      <Route exact path={urls.AI_AGENTS()}>
        <AiAgentsList />
      </Route>
      <Route exact path={urls.AI_AGENTS_CHAT()}>
        <AiAgentsWrapperPage>
          <AiAgentsChat
            match={{ params: { paramsPage: 'AiAgentsChat' } }}
            location={{ search: '' }}
          />
        </AiAgentsWrapperPage>
      </Route>
      <Route exact path="/workspaces/:workspaceId?/ai-agents/:agentId?/instructions">
        <AiAgentsWrapperPage>
          <AiAgentInstructions
            match={{ params: { paramsPage: 'AiAgentInstructions' } }}
            location={{ search: '' }}
          />
        </AiAgentsWrapperPage>
      </Route>
      <Route exact path="/workspaces/:workspaceId?/ai-agents/:agentId?/activity-log">
        <AiAgentsWrapperPage>
          <AiAgentActivityLog
            match={{ params: { paramsPage: 'AiAgentActivityLog' } }}
            location={{ search: '' }}
          />
        </AiAgentsWrapperPage>
      </Route>
    </Switch>
  );
}

export default AiAgents;
