import pipelineService from '@api/services/relationshipsPipelineService';
import {
  RelationshipPipelineCrudRequest,
  RelationshipPipelineMoveRequest,
  RelationshipPipelineStageCrudRequest,
  RelationshipPipelineStageOpportunityAssignRequest,
  RelationshipPipelineStageOpportunityCrudRequest,
  RelationshipPipelineStageOpportunitySuggestionRequest,
  StageOpportunityFilter, StageOpportunityFilterFieldMap,
  StageOpportunityFilterRequest,
} from 'respona_api/generated/relationship-pipeline_pb';
import request from '@api/base/request';
import {
  PipelineOpportunitySuggestionType,
  PipelineOpportunityType,
  RelationshipPipelineStageType,
  RelationshipPipelineType,
} from '@ts/relationshipPipeline.types';
import CommonModels, {
  CustomVariableValue,
  CustomVariableValueSelect,
  RelationshipPipelineStageOpportunityRelatedTo,
} from 'respona_api/generated/common_pb';
import streamRequest from '@api/base/streamRequest';
import { CustomVariableValueType } from '@ts/customVariales.types';
import * as common_pb from 'respona_api/generated/common_pb';
import { FilterFieldType } from '@ts/filterField.type';

// -------------------------------------- Pipeline API ----------------------------------------------------------------

export const createPipelineApi = (
  workspaceId: number,
  title: string
): Promise<RelationshipPipelineType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineCrudRequest();

  requestModel.setWorkspaceIdsList([workspaceId]);
  requestModel.setTitle(title);

  return request<RelationshipPipelineType>(service, service.createPipeline, requestModel);
};

export const updatePipelineApi = (
  pipelineId: number,
  workspaceId: number,
  title: string
): Promise<RelationshipPipelineType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineCrudRequest();

  requestModel.setId(pipelineId);
  requestModel.setWorkspaceIdsList([workspaceId]);
  requestModel.setTitle(title);

  return request<RelationshipPipelineType>(service, service.updatePipeline, requestModel);
};

export const deletePipelineApi = (pipelineId: number, workspaceId: number): Promise<void> => {
  const service = pipelineService.getInstance();
  const requestModel = new CommonModels.IdRequest();

  requestModel.setId(pipelineId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.deletePipeline, requestModel);
};

export const getAllRelationshipPipelinesApi = (
  workspaceId: number
): Promise<RelationshipPipelineType[]> => {
  const service = pipelineService.getInstance();
  const requestModel = new CommonModels.IdRequest();

  requestModel.setId(workspaceId);

  return streamRequest<RelationshipPipelineType[]>(service, service.getAllPipelines, requestModel);
};

// ----------------------------------------- Stage API ----------------------------------------------------------------

export const createStageApi = (
  pipelineId: number,
  title: string
): Promise<RelationshipPipelineStageType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineStageCrudRequest();

  requestModel.setPipelineId(pipelineId);
  requestModel.setTitle(title);
  requestModel.setTrackTime(false);
  requestModel.setTargetTimeInDays(2);

  return request<RelationshipPipelineStageType>(service, service.createStage, requestModel);
};

export const updateStageApi = (
  stageId: number,
  pipelineId: number,
  title: string,
  trackTime: boolean,
  targetTimeDays: number,
  changeAssigneeEnabled: boolean,
  assigneeUserIdDestination: number,
  changeWebsiteStatusEnabled: boolean,
  websiteStatusDestination: common_pb.PeopleRelationshipStatusMap[keyof common_pb.PeopleRelationshipStatusMap]
): Promise<RelationshipPipelineStageType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineStageCrudRequest();

  requestModel.setId(stageId);
  requestModel.setPipelineId(pipelineId);
  requestModel.setTitle(title);
  requestModel.setTrackTime(trackTime);
  requestModel.setTargetTimeInDays(targetTimeDays);
  requestModel.setChangeAssigneeEnabled(changeAssigneeEnabled);
  if (changeAssigneeEnabled) {
    requestModel.setAssigneeUserIdDestination(assigneeUserIdDestination);
  }
  requestModel.setChangeWebsiteStatusEnabled(changeWebsiteStatusEnabled);
  if (changeWebsiteStatusEnabled) {
    requestModel.setWebsiteStatusDestination(websiteStatusDestination);
  }
  return request<RelationshipPipelineStageType>(service, service.updateStage, requestModel);
};

export const moveStageApi = (
  workspaceId: number,
  pipelineId: number,
  stageId: number,
  headStageId: number
): Promise<void> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineMoveRequest();

  requestModel.setWorkspaceId(workspaceId);
  requestModel.setPipelineId(pipelineId);
  requestModel.setStageId(stageId);
  requestModel.setHeadRelatedId(headStageId);

  return request<void>(service, service.moveStage, requestModel);
};

export const deleteStageApi = (
  stageId: number,
  pipelineId: number,
  workspaceId: number
): Promise<void> => {
  const service = pipelineService.getInstance();
  const requestModel = new CommonModels.IdRequest();

  requestModel.setId(stageId);
  requestModel.setOptionalRelatedId(pipelineId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.deleteStage, requestModel);
};

export const getAllStagesApi = (
  pipelineId: number,
  workspaceId: number
): Promise<RelationshipPipelineStageType[]> => {
  const service = pipelineService.getInstance();
  const requestModel = new CommonModels.IdRequest();

  requestModel.setId(pipelineId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return streamRequest<RelationshipPipelineStageType[]>(
    service,
    service.getAllStages,
    requestModel
  );
};

// -------------------------------------- Pipeline Opportunity API ---------------------------------------------------

/**
 * Method should path existing Website ID or data that will be used for creating new website
 * In this case existingWebsiteId should be -1
 */
export const createStageOpportunityApi = (
  stageId: number,
  workspaceId: number,
  existingWebsiteId: number,
  newWebsiteLogo: string,
  newWebsiteDomain: string,
  newWebsiteName: string,
  assignToUserId: number,
  variableValues: CustomVariableValueType[] = []
): Promise<PipelineOpportunityType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineStageOpportunityCrudRequest();

  requestModel.setStageId(stageId);
  requestModel.setWorkspaceId(workspaceId);
  requestModel.setRelatedTo(RelationshipPipelineStageOpportunityRelatedTo.RELATIONSHIP_WEBSITE);
  requestModel.setRelatedId(existingWebsiteId);
  requestModel.setRelatedLogo(newWebsiteLogo);
  requestModel.setRelatedDomain(newWebsiteDomain);
  requestModel.setRelatedName(newWebsiteName);
  requestModel.setAssignToUserId(assignToUserId);
  requestModel.setVariableValuesList(
    variableValues.map((value) => {
      const customVariable = new CustomVariableValue();
      customVariable.setVariableId(value.variableId);
      customVariable.setVariableName(value.variableName);
      customVariable.setType(value.type);
      customVariable.setOptCompanionId(value.optCompanionId);
      if (value.stringValue != null && value.stringValue.length > 0) {
        customVariable.setStringValue(value.stringValue);
      }
      if (value.selectValue != null) {
        const selectReq = new CustomVariableValueSelect();
        selectReq.setValuesList(
          value.selectValue.valuesList.map((it) => {
            const item = new CustomVariableValueSelect.SelectItemValue();
            item.setValue(it.value);
            item.setUid(it.uid);
            return item;
          })
        );
        customVariable.setSelectValue(selectReq);
      }
      customVariable.setRelatedId(value.relatedId);
      customVariable.setRelatedTo(value.relatedTo);
      return customVariable;
    })
  );

  return request<PipelineOpportunityType>(service, service.createStageOpportunity, requestModel);
};

export const assignStageOpportunityApi = (
  relationshipOpportunityId: number,
  workspaceId: number,
  assignedUserId: number
): Promise<RelationshipPipelineStageType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineStageOpportunityAssignRequest();

  requestModel.setOpportunityId(relationshipOpportunityId);
  requestModel.setWorkspaceId(workspaceId);
  requestModel.setToUserId(assignedUserId);

  return request<RelationshipPipelineStageType>(
    service,
    service.assignStageOpportunity,
    requestModel
  );
};

export const moveStageOpportunityApi = (
  workspaceId: number,
  pipelineId: number,
  stageId: number,
  stageOpportunityId: number,
  headOpportunityId?: number
): Promise<PipelineOpportunityType> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineMoveRequest();

  requestModel.setWorkspaceId(workspaceId);
  requestModel.setStageId(stageId);
  requestModel.setOpportunityId(stageOpportunityId);
  requestModel.setPipelineId(pipelineId);
  requestModel.setHeadRelatedId(headOpportunityId || -1);

  return request<PipelineOpportunityType>(service, service.moveStageOpportunity, requestModel);
};

export const removeStageOpportunityApi = (
  relationshipOpportunityIds: number[],
  stageId: number,
  workspaceId: number
): Promise<void> => {
  const service = pipelineService.getInstance();
  const requestModel = new CommonModels.IdContainer();

  requestModel.setIdList(relationshipOpportunityIds);
  requestModel.setOptionalRelatedId(stageId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return request<void>(service, service.removeStageOpportunities, requestModel);
};

export const getStageOpportunitiesPageApi = (
  stageId: number,
  workspaceId: number,
  page: number,
  limit: number
): Promise<PipelineOpportunityType[]> => {
  const service = pipelineService.getInstance();
  const requestModel = new CommonModels.PageRequest();

  requestModel.setPage(page);
  requestModel.setLimit(limit);

  requestModel.setOptionalEntityId(stageId);
  requestModel.setOptionalWorkspaceId(workspaceId);

  return streamRequest<PipelineOpportunityType[]>(
    service,
    service.getStageOpportunitiesPage,
    requestModel
  );
};

export const getStageOpportunitiesWithFilterApi = (
  pipelineId: number,
  workspaceId: number,
  page: number,
  limit: number,
  filters: FilterFieldType<StageOpportunityFilterFieldMap>[],
  query?: string
): Promise<PipelineOpportunityType[]> => {
  const service = pipelineService.getInstance();
  const requestModel = new StageOpportunityFilterRequest();

  requestModel.setPage(page);
  requestModel.setLimit(limit);

  requestModel.setPipelineId(pipelineId);
  requestModel.setWorkspaceId(workspaceId);
  if (query?.length > 0) {
    requestModel.setQuery(query);
  }
  if (filters?.length > 0) {
    requestModel.setFiltersList(
      filters.map((it) => {
        const filterRequest = new StageOpportunityFilter();
        filterRequest.setJoin(it.join);
        filterRequest.setOperation(it.operation);
        filterRequest.setOrder(it.order);
        filterRequest.setField(it.field);
        if (Object.prototype.hasOwnProperty.call(it.value, 'value')) {
          // @ts-ignore
          filterRequest.setValue(it.value.value.toString());
        } else {
          filterRequest.setValue(it.value.toString());
        }
        return filterRequest;
      })
    );
  }

  return streamRequest<PipelineOpportunityType[]>(
    service,
    service.getStageOpportunitiesWithFilters,
    requestModel
  );
};

export const getStageOpportunitySuggestionsApi = (
  query: string,
  workspaceId: number
): Promise<PipelineOpportunitySuggestionType[]> => {
  const service = pipelineService.getInstance();
  const requestModel = new RelationshipPipelineStageOpportunitySuggestionRequest();

  requestModel.setWorkspaceId(workspaceId);
  requestModel.setQuery(query);

  return streamRequest<PipelineOpportunitySuggestionType[]>(
    service,
    service.getStageOpportunitySuggestions,
    requestModel
  );
};
