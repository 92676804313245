import { createUpdaterCacheItemById } from '@helpers/cacheUpdateHelper';
import EditReferralContentModal from '@pages/CampaignCreationPage/_components/EditLinkContentModal/EditReferralContentModal';

import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon } from '@uikit/Icon/Icon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import parse from 'emailjs-mime-parser';
import cn from 'class-names';

import { getCurrentWorkspaceId } from '@redux/selectors/workspaces.selectors';
import { getEmailStrings } from '@components/Inbox/InboxWidget/helpers';

import useHotkeys from '@hooks/useHotkeys';
import { sendMessageApi } from '@api/mailboxSender.api';
import { addNotification } from '@redux/actions/notifications.actions';
import { MailboxThreadDraftType, ThreadMessageType } from '@ts/mailboxInbox.types';
import {
  MessageRequestType,
  MessageRequestType as MESSAGE_TYPE,
  MessageRequestTypeMap,
} from 'respona_api/generated/mailbox-sender_pb';
import InboxWidgetEditor from './_components/InboxWidgetEditor/InboxWidgetEditor';
import MetaInfo, { MetaInfoStep } from './_components/MetaInfo/MetaInfo';

import './InboxWidget.scss';
import { createOrUpdateThreadDraftApi } from '@api/mailbox.api';
import { FileAttachment } from 'respona_api/generated/common_pb';
import { PersonBaseType } from '@ts/people.types';
import useMailboxAccounts from '@hooks/useMailboxAccounts';
import { MailboxAccountSignatureType } from '@ts/mailboxAccounts.types';
import { getAccountSignatureByIdApi } from '@api/mailboxAccounts.api';
import useReferralLink from '@hooks/useReferralLink';
import { Button } from '@uikit/Button/Button';
import { isSemrushMode } from '@constants/app-modes';

export type InboxWidgetInfoType = {
  type: MessageRequestTypeMap[keyof MessageRequestTypeMap];
  replyAll?: boolean;
  email?: ThreadMessageType;
  draft?: MailboxThreadDraftType;
};

declare const INTEGRATION_MODE: string;

function InboxWidget({
  inboxWidgetInfo,
  onClose,
  isEmbedded = false,
  onSend,
  activeThreadUid,
  onDraftCreation,
  onDraftDelete,
  activePerson,
}: {
  inboxWidgetInfo?: InboxWidgetInfoType;
  onClose: (deleteOnClose: boolean, threadUid?: string) => void;
  isEmbedded?: boolean;
  onSend?: () => void;
  activeThreadUid?: string;
  onDraftCreation: (threadUid: string) => void;
  onDraftDelete: (threadUid: string) => void;
  activePerson?: PersonBaseType;
}): JSX.Element {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const parsedEmail = useMemo(
    () =>
      inboxWidgetInfo?.email?.content?.length > 0 ? parse(inboxWidgetInfo?.email?.content) : null,
    [inboxWidgetInfo?.email?.messageId]
  );

  const [selectedSenderEmail, changeSelectedSenderEmail] = useState<string>('');
  const [contactEmail, changeContactEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [bccEmails, changeBccEmails] = useState<string[]>([]);
  const [ccEmails, changeCcEmails] = useState<string[]>([]);

  const [isUpdateReferralContentModalOpen, setUpdateReferralContentModalOpen] =
    useState<boolean>(false);
  const [isUpdatingReferralSettings, setIsUpdatingReferralSettings] = useState<boolean>(false);

  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const { items: mailboxAccounts, isLoading: isLoadingSenderEmail } =
    useMailboxAccounts(currentWorkspaceId);

  const { item: referralContentData } = useReferralLink();

  const senderMailboxId = mailboxAccounts?.find(({ email }) => email === selectedSenderEmail)?.id;

  const { data: senderMailbox } = useQuery<MailboxAccountSignatureType>({
    queryKey: ['mailbox-account-signature', senderMailboxId],
    queryFn: () => getAccountSignatureByIdApi(senderMailboxId),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: senderMailboxId != null && senderMailboxId > 0,
  });

  const handleClose = (deleteOnClose: boolean = false) => {
    changeContactEmail('');
    changeCcEmails([]);
    changeBccEmails([]);
    onClose(deleteOnClose, activeThreadUid);
  };

  const handleAddContact = (value: string) => {
    if (activeThreadUid?.length > 0) {
      handleDraftCreation(
        activeThreadUid,
        inboxWidgetInfo.draft?.messageId || inboxWidgetInfo.email?.messageId,
        value,
        inboxWidgetInfo.draft?.type || MessageRequestType.SEND_REPLY_TO,
        inboxWidgetInfo.draft?.subject,
        inboxWidgetInfo.draft?.content,
        inboxWidgetInfo.draft?.attachmentsList
      );
    }
    changeContactEmail(value);
  };

  const handleDraftCreation = (
    threadUid: string,
    messageId: string,
    emailTo: string,
    type: MessageRequestTypeMap[keyof MessageRequestTypeMap],
    draftSubject: string,
    serializedContent: string,
    draftAttachments: FileAttachment.AsObject[]
  ) => {
    createOrUpdateThreadDraftApi(
      threadUid,
      messageId,
      type,
      emailTo,
      draftSubject,
      serializedContent,
      draftAttachments
    ).then(() => onDraftCreation(threadUid));
  };

  const handleUpdateMeta = ({ emailsToBccList, emailsToCcList }: MetaInfoStep) => {
    changeCcEmails(emailsToCcList);
    changeBccEmails(emailsToBccList);
  };

  const handleSend = (messageSubject: string, content: string, attachments): Promise<void> => {
    return sendMessageApi(
      senderMailbox?.id,
      inboxWidgetInfo.type,
      contactEmail,
      messageSubject,
      content,
      ccEmails,
      bccEmails,
      attachments,
      senderMailbox?.referralContentEnabled,
      inboxWidgetInfo?.email?.messageId
    ).then(() => {
      dispatch(addNotification({ title: 'Email sent', type: 'success' }));
      onDraftDelete(activeThreadUid);
      if (onSend) {
        onSend();
      }
      onClose(false);
    });
  };

  // TODO RES-6366 uncomment
  const handleUpdateReferralContent = async ({ target: { checked: referralContentEnabled } }) => {
    // setIsUpdatingReferralSettings(true);
    queryClient.setQueryData(
      ['mailbox-accounts', currentWorkspaceId],
      createUpdaterCacheItemById(
        senderMailbox?.id,
        'referralContentEnabled',
        referralContentEnabled
      )
    );
    // await updateReferralContentStateApi(senderMailbox?.id, referralContentEnabled)
    //   .then((res) => {
    //     return queryClient.setQueryData(
    //       ['mailbox-accounts', currentWorkspaceId],
    //       createUpdaterCacheItemById(senderMailbox?.id, 'referralContentEnabled', res.state)
    //     );
    //   })
    //   .finally(() => setIsUpdatingReferralSettings(false));
  };

  useHotkeys({
    Escape: () => handleClose(false),
  });

  useEffect(() => {
    if (isLoadingSenderEmail) {
      return;
    }

    if (mailboxAccounts != null) {
      const emails = getEmailStrings(mailboxAccounts);
      if (emails != null && emails.length && inboxWidgetInfo) {
        const from = inboxWidgetInfo?.email?.fromEmail?.toLowerCase();
        const to = inboxWidgetInfo?.email?.toEmail?.toLowerCase();
        const existingMailbox = emails.find(
          (em) => em.toLowerCase() === from || em.toLowerCase() === to
        );
        if (existingMailbox) {
          changeSelectedSenderEmail(existingMailbox);
        } else if (selectedSenderEmail == null || !selectedSenderEmail.length) {
          changeSelectedSenderEmail(emails[0]);
        }
      }
    }
  }, [inboxWidgetInfo, mailboxAccounts, isLoadingSenderEmail]);

  useEffect(() => {
    if (isLoadingSenderEmail) {
      return;
    }

    if (inboxWidgetInfo?.type === MESSAGE_TYPE.SEND_REPLY_TO) {
      const fromEmail = inboxWidgetInfo?.email?.fromEmail;
      const existingMailbox = getEmailStrings(mailboxAccounts).find(
        (em) => em.toLowerCase() === fromEmail?.toLowerCase()
      );
      if (existingMailbox) {
        changeContactEmail(inboxWidgetInfo?.email?.toEmail);
      } else {
        changeContactEmail(fromEmail);
      }
    }
    if (inboxWidgetInfo != null && inboxWidgetInfo.type !== null) {
      if (inboxWidgetInfo.type === MESSAGE_TYPE.SEND_REPLY_TO) {
        setSubject(composeSubjectWithPrefix(inboxWidgetInfo?.email?.subject, 'Re:'));
      } else if (inboxWidgetInfo.type === MESSAGE_TYPE.FORWARD_EMAIL) {
        setSubject(composeSubjectWithPrefix(inboxWidgetInfo?.email?.subject, 'Fw:'));
      } else if (inboxWidgetInfo?.draft?.subject?.length) {
        setSubject(inboxWidgetInfo.draft.subject);
      } else {
        setSubject('');
      }
    }
  }, [inboxWidgetInfo, isLoadingSenderEmail]);

  useEffect(() => {
    if (inboxWidgetInfo?.replyAll && parsedEmail) {
      changeCcEmails(parsedEmail?.headers?.cc?.map((item) => item.value[0].address) || []);
      changeBccEmails(parsedEmail?.headers?.bcc?.map((item) => item.value[0].address) || []);
    } else if (!inboxWidgetInfo?.replyAll && ccEmails) {
      changeCcEmails([]);
      changeBccEmails([]);
    }
    if (inboxWidgetInfo?.draft?.emailTo?.length) {
      if (inboxWidgetInfo.email.fromEmail !== inboxWidgetInfo.draft.emailTo) {
        changeContactEmail(inboxWidgetInfo.draft.emailTo);
      }
    }
  }, [inboxWidgetInfo, parsedEmail]);

  if (!inboxWidgetInfo) {
    return null;
  }

  const cnInboxWidget = cn('inbox-widget', {
    'inbox-widget--embedded': isEmbedded,
  });

  const composeSubjectWithPrefix = (value: string, prefix: string): string => {
    if (value?.startsWith(prefix) === true) {
      return value;
    }
    return `${prefix} ${value}`;
  };

  return (
    <>
      <div className={cnInboxWidget}>
        <Button type="ghost" className="inbox-widget__close-btn" onClick={ () => handleClose(true)}>
          <SVGIcon icon="crossBlack" />
        </Button>
        <MetaInfo
          subject={subject}
          onUpdateMeta={handleUpdateMeta}
          step={{ emailsToCcList: ccEmails, emailsToBccList: bccEmails }}
          contactEmail={contactEmail}
          selectedSenderEmail={selectedSenderEmail}
          senderEmails={mailboxAccounts?.length ? getEmailStrings(mailboxAccounts) : ['']}
          isSenderEmailLoading={isLoadingSenderEmail}
          onChangeSenderEmail={(newSenderEmail: string) =>
            changeSelectedSenderEmail(newSenderEmail)
          }
          onAddContact={handleAddContact}
          onAddSubject={() =>
            setSubject(composeSubjectWithPrefix(inboxWidgetInfo?.email?.subject, 'Re:'))
          }
          onRemoveContact={() => changeContactEmail('')}
          isReply={inboxWidgetInfo?.type === MESSAGE_TYPE.SEND_REPLY_TO}
        />

        <InboxWidgetEditor
          subject={subject}
          setSubject={setSubject}
          contactEmail={contactEmail}
          selectedSignature={senderMailbox?.signature}
          originalMessage={parsedEmail}
          onSend={handleSend}
          onDelete={() => handleClose(true)}
          workspaceId={currentWorkspaceId}
          referralContent={senderMailbox?.referralContentEnabled ? referralContentData : null}
          threadUid={activeThreadUid}
          messageId={inboxWidgetInfo?.email?.messageId || inboxWidgetInfo?.draft?.messageId}
          onDraftCreation={handleDraftCreation}
          draft={inboxWidgetInfo?.draft}
          activePerson={activePerson}
        />

        {!isSemrushMode(INTEGRATION_MODE) && (
          <div
            className="sequence__unsubscribe-content"
            style={{ marginTop: 0, padding: '0 28px 20px' }}
          >
            <div className="sequence__unsubscribe-content-row">
              <Checkbox
                disabled={isUpdatingReferralSettings}
                onChange={handleUpdateReferralContent}
                value={senderMailbox?.referralContentEnabled}
              >
                <span style={{ marginLeft: '8px' }}>Include referral link to earn free credits</span>
              </Checkbox>
              <span
                className="sequence__edit-icon"
                onClick={() => setUpdateReferralContentModalOpen(true)}
              >
              <SVGIcon icon="pen" color="#221CB6" size={16} />
            </span>
            </div>
          </div>
        )}
      </div>

      <EditReferralContentModal
        isOpen={isUpdateReferralContentModalOpen}
        onClose={() => setUpdateReferralContentModalOpen(false)}
        content={referralContentData?.content}
      />
    </>
  );
}

export default InboxWidget;
