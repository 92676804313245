import { redirectUserTo } from '@utils/historyHandler';
import React from 'react';
import { components } from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { ScheduleType } from '@ts/schedules.types';

export const getSchedulesSelectOptions = (schedules: ScheduleType[]) =>
  schedules.map(({ id, title }) => ({
    label: title,
    value: id,
  }));

function Menu(props) {
  const history = useHistory();
  const linkStyle = {
    color: '#7974F7',
    fontSize: '14px',
    padding: '10px 0 10px 10px',
    display: 'inline-flex',
    textDecoration: 'none',
  };

  const url = '/settings/schedules?createNew=true';

  return (
    <components.Menu {...props}>
      <div>
        <div>{props.children}</div>
        <div style={{ textAlign: 'center' }}>
          <Link
            style={linkStyle}
            to={url}
            onClick={(event) => {
              redirectUserTo(history, url);
            }}
          >
            Add new schedule
          </Link>
        </div>
      </div>
    </components.Menu>
  );
}

export default Menu;
