/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './ProgressBar.scss';

const ProgressBar = ({
  title,
  percentage = 0,
  withPercentsCounter = true,
  className,
  completedText,
}: {
  title?: string;
  percentage: number;
  withPercentsCounter?: boolean;
  className?: string;
  completedText?: string;
}): JSX.Element => {

  const cnProgressBar = cn('progress-bar__bar', {
    [className]: !!className,
    'progress-bar__bar--zero-value': percentage === 0 || !withPercentsCounter,
  });

  return (
    <div className="progress-bar">
      <div className="progress-bar__title">
        {title ?? <span>{percentage !== 100 ? 'Processing...' : completedText || 'Done'}</span>}
        {withPercentsCounter && (
          <span className="progress-bar__percents">{percentage.toFixed(1)} %</span>
        )}
      </div>
      <ProgressBarComponent className={cnProgressBar} percentage={percentage} />
    </div>
  );
};

export default ProgressBar;

export const ProgressBarComponent = ({
  className,
  containerClassName,
  percentage,
}: {
  containerClassName?: string;
  className?: string;
  percentage?: number;
}) => (
  <div className={cn('progress-bar__bar-wrapper', { [containerClassName]: !!containerClassName })}>
    <div
      className={cn('progress-bar__bar', { [className]: !!className })}
      style={percentage !== 0 ? { width: `${percentage}%` } : {}}
    />
  </div>
);
