import { PeopleFilterField, PeopleFilterFieldMap } from 'respona_api/generated/people_pb';

declare const IS_STANDALONE_MODE: boolean;

export type PeopleFilterFieldType = {
  label: string;
  value: PeopleFilterFieldMap[keyof PeopleFilterFieldMap];
  type: 'string' | 'number' | 'boolean' | 'enum' | 'enumString' | 'stringNotEmpty' | 'date';
  inputOptions?: { value: string | number; label: string }[];
};

export const getPeopleFilterFields = (
  members: { value: string | number; label: string }[] = [],
  tags: { value: string | number; label: string }[] = [],
  campaigns: { value: string | number; label: string }[] = [],
  workspaces: { value: string | number; label: string }[] = []
): PeopleFilterFieldType[] => {
  const filterFields: PeopleFilterFieldType[] = [
    {
      label: 'Name',
      value: PeopleFilterField.STRING_NAME,
      type: 'string',
    },
    {
      label: 'Email',
      value: PeopleFilterField.STRING_EMAIL,
      type: 'string',
    },
    {
      label: 'Job Title',
      value: PeopleFilterField.STRING_JOB_TITLE,
      type: 'string',
    },
    {
      label: 'Relationship status',
      value: PeopleFilterField.DROPDOWN_RELATIONSHIP_STATUS,
      type: 'enumString',
      inputOptions: [
        { value: 'RELATIONSHIP_STATUS_NOT_CONTACTED', label: 'Not contacted' },
        { value: 'RELATIONSHIP_STATUS_ATTEMPTING_TO_REACH', label: 'Scheduled' },
        { value: 'RELATIONSHIP_STATUS_CONTACTED', label: 'Contacted' },
        { value: 'RELATIONSHIP_STATUS_REPLIED', label: 'Replied' },
        { value: 'RELATIONSHIP_STATUS_BOUNCED', label: 'Bounced' },
        { value: 'RELATIONSHIP_STATUS_UNSUBSCRIBED', label: 'Unsubscribed' },
        { value: 'RELATIONSHIP_STATUS_CANCELED_MANUALLY', label: 'Stopped' },
        { value: 'RELATIONSHIP_STATUS_INTERESTED', label: 'Interested' },
        { value: 'RELATIONSHIP_STATUS_NOT_INTERESTED', label: 'Not interested' },
        { value: 'RELATIONSHIP_STATUS_WON', label: 'Won' },
        { value: 'RELATIONSHIP_STATUS_LOST', label: 'Lost' },
      ],
    },
    {
      label: 'Created on',
      value: PeopleFilterField.DATE_PEOPLE_CREATED_AT,
      type: 'date',
    },
    {
      label: 'Tag',
      value: PeopleFilterField.DROPDOWN_PEOPLE_TAG,
      type: 'enum',
      inputOptions: tags,
    },
    {
      label: 'Campaign',
      value: PeopleFilterField.DROPDOWN_CAMPAIGN_ID,
      type: 'enum',
      inputOptions: campaigns,
    },
    {
      label: 'Workspace',
      value: PeopleFilterField.DROPDOWN_WORKSPACE_ID,
      type: 'enum',
      inputOptions: workspaces,
    },
  ];

  if (IS_STANDALONE_MODE) {
    filterFields.push({
      label: 'Owner',
      value: PeopleFilterField.DROPDOWN_PEOPLE_OWNER_ID,
      type: 'enum',
      inputOptions: members,
    });
  }

  return filterFields;
};
