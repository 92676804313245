/**
 * NOT CHECKED
 */
import React, { ReactNode } from 'react';
import cn from 'class-names';

import './Table.scss';

type BaseTablePropsType = {
  children?: ReactNode;
  className?: string;
  [key: string]: any;
};

const concatClassNames = (first, second = '') => `${first} ${second}`;

const Table = ({ children, className, tableRef, ...props }: BaseTablePropsType): JSX.Element => (
  <table className={concatClassNames('table', className)} ref={tableRef} {...props}>
    {children}
  </table>
);

export const THead = ({ children, className, ...props }: BaseTablePropsType): JSX.Element => (
  <thead className={concatClassNames('table__thead table-thead', className)} {...props}>
    {children}
  </thead>
);

export const TBody = ({ children, className, ...props }: BaseTablePropsType): JSX.Element => (
  <tbody className={concatClassNames('table__tbody table-tbody', className)} {...props}>
    {children}
  </tbody>
);

export const Tr = ({
  children,
  className = '',
  isSelected = false,
  ...props
}: BaseTablePropsType): JSX.Element => {
  const cnRow = cn('table__tr table-tr', {
    [className]: className.length,
    'table-tr--is-selected': isSelected,
  });

  return (
    <tr className={cnRow} {...props}>
      {children}
    </tr>
  );
};

export const Th = ({ children, className, colspan, ...props }: BaseTablePropsType): JSX.Element => (
  <th className={concatClassNames('table__th table-th', className)} colSpan={colspan || null} {...props}>
    {children}
  </th>
);

export const Td = ({ children, className, ...props }: BaseTablePropsType): JSX.Element => (
  <td className={concatClassNames('table__td table-td', className)} {...props}>
    {children}
  </td>
);

export default Table;
