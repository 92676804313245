/**
 * NOT CHECKED
 */
import React from 'react';
import cn from 'class-names';

import './SlideToggler.scss';

type SlideTogglerClassNamesType = {
  container?: string;
  containerActive?: string;
  slider?: string;
  sliderActive?: string;
};

type SlideTogglerPropsType = {
  value: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
  classNames?: SlideTogglerClassNamesType;
};

export const SlideToggler = ({
  value,
  onChange,
  isDisabled = false,
  classNames,
}: SlideTogglerPropsType): JSX.Element => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (!isDisabled) {
      onChange(!value);
    }
  };

  const classes = {
    container: cn('slide-toggler', classNames?.container || '', {
      'slide-toggler--active': value,
      'slide-toggler--disabled': isDisabled,
      ...(classNames?.containerActive ? { [classNames?.containerActive]: value } : {}),
    }),
    slider: cn('slide-toggler__pointer', classNames?.slider || '', {
      ...(classNames?.sliderActive ? { [classNames?.sliderActive]: value } : {}),
    }),
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.slider} />
    </div>
  );
};

export default SlideToggler;
