import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ToolboxSidebar from '@components/Toolbox/ToolboxSidebar/ToolboxSidebar';
import ToolboxEmailFinder from '@components/Toolbox/ToolboxEmailFinder/ToolboxEmailFinder';
import ToolboxEmailVerifier from '@components/Toolbox/ToolboxEmailVerifier/ToolboxEmailVerifier';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';

import './ToolboxPage.scss';

enum ToolboxPageName {
  EMAIL_FINDER = 'email-finder',
  EMAIL_VERIFIER = 'email-verifier',
}

export default function ToolboxPage(): JSX.Element {
  const { toolboxPage } = useParams();
  const [upsellCreditsSidebarOpen, setUpsellCreditsSidebarOpen] = useState<boolean>(false);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);

  return (
    <div className="toolbox-page">
      <ToolboxSidebar />
      {toolboxPage === ToolboxPageName.EMAIL_FINDER && (
        <ToolboxEmailFinder setUpsellCreditsSidebarOpen={setUpsellCreditsSidebarOpen} />
      )}
      {toolboxPage === ToolboxPageName.EMAIL_VERIFIER && (
        <ToolboxEmailVerifier setUpsellCreditsSidebarOpen={setUpsellCreditsSidebarOpen} />
      )}

      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
          }}
        />
      )}

      {upsellCreditsSidebarOpen && (
        <UpsellCreditsSidebar
          title="You don&rsquo;t have enough credits for this action. How would you like to proceed?"
          onClose={() => setUpsellCreditsSidebarOpen(false)}
        />
      )}
    </div>
  );
}
