import React from 'react';

export default function ({ color = '#777D8A', size = 26 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26598_3225)">
        <path
          d="M9.34454 6.00772C8.97906 5.82497 8.56058 6.13402 8.62773 6.5371L9.45803 11.5213C9.492 11.7252 9.64793 11.8872 9.85039 11.9289L12.6707 12.5097C13.2029 12.6193 13.2029 13.3795 12.6707 13.4891L9.85039 14.0699C9.64793 14.1116 9.492 14.2736 9.45803 14.4774L8.62773 19.4617C8.56058 19.8648 8.97906 20.1738 9.34455 19.9911L22.433 13.4466C22.8015 13.2623 22.8015 12.7364 22.433 12.5522L9.34454 6.00772Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_26598_3225">
          <rect
            width="17.3853"
            height="17.3853"
            fill="white"
            transform="translate(13 0.706055) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
