import React from 'react';
import SortButtons from '@uikit/SortButtons/SortButtons';
import ColoredLabel from '@uikit/ColoredLabel/ColoredLabel';

type TableRenderProps = {
  row: any;
  children?: any;
  renderProps?: {
    [key: string]: any;
  };
};


function TimestampToDate({ timestamp }) {
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <>
      {formattedDate} at {formattedTime}
    </>
  );
}


export default [
  {
    title: 'Action',
    dataKey: 'action',
    RenderTitle: (): JSX.Element => {
      return <div className="ai-agents-table-header">Action</div>;
    },
    RenderCell: ({ row: { action } }: TableRenderProps): JSX.Element => {
      const getStatusText = (action: string): string => {
        switch (String(action)) {
          case '0':
            return 'New campaign created';
          case '1':
            return 'Campaign launched';
          case '2':
            return 'Email replied';
          case '3':
            return 'Pipeline finished';
          default:
            return 'Unknown status';
        }
      };

      const getStatusColor = (action: string): string => {
        switch (String(action)) {
          case '0':
            return 'blue';
          case '1':
            return 'green';
          case '2':
            return 'orange';
          case '3':
            return 'gray';
          default:
            return 'black';
        }
      };

      return (
        <div className="ai-agents-table__name">
          <span className="subtitle-cell">
            <ColoredLabel isHovered={false} color={getStatusColor(action)}>
              <p className="status-text">{getStatusText(action)}</p>
            </ColoredLabel>
          </span>
        </div>
      );
    },
  },
  {
    title: 'Summary',
    dataKey: 'summary',
    RenderCell: ({
      row: { summary, link, repliedEmail, repliedInfo },
    }: TableRenderProps): JSX.Element => {
      const getClassForLink = (text) => {
        if (text.includes("created")) {
          return "bordered blue";
        } else if (text.includes("launched") || text.includes("finished")) {
          return "bordered";
        }
        return "";
      };

      const updatedData = summary.replace(/<a /g, (match) => {
        const className = getClassForLink(summary);
        return `<a class="${className}" `;
      });

      return (
        <div className="ai-agents-table__td">
        <span className="subtitle-cell">
         <p dangerouslySetInnerHTML={{ __html: updatedData }} />
        </span>
        </div>
      );
    },
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="ai-agents-table__name">
          <div className="text-align-center mr-5">
            <div className="">Credits used</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'creditUsed',
    RenderCell: ({ row: { creditsUsed } }: TableRenderProps): JSX.Element => (
      <div className="credit-used">{creditsUsed}</div>
    ),
  },
  {
    RenderTitle: ({
      renderProps: { handleSortClick, dataKey, selectedKey, direction },
    }): JSX.Element => {
      return (
        <div className="ai-agents-table__name">
          <div className="text-align-center mr-5">
            <div>Created by</div>
          </div>
          <SortButtons
            handleClick={handleSortClick}
            isActive={selectedKey === dataKey}
            direction={direction}
            dataKey={dataKey}
          />
        </div>
      );
    },
    dataKey: 'dateTime',
    RenderCell: ({ row: { createdAt } }: TableRenderProps): JSX.Element => (
      <div className="date-time">
        <TimestampToDate timestamp={createdAt} />
      </div>
    ),
  },
];
