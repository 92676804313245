/**
 * NOT CHECKED
 */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FolderType } from '@ts/common.types';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { SvgIconType } from '@uikit/Icon/Icon';
import SectionSelect from '@uikit/SectionSelect/SectionSelect';
import CreateItemComponent from '@uikit/Folders/_components/CreateItemComponent';
import FolderComponent from '@uikit/Folders/_components/FolderComponent';
import { redirectUserTo } from '@utils/historyHandler';

import './Folders.scss';

function Folders({
  items,
  handleCreate,
  handleChoose,
  path,
  paramName = 'itemId',
  onRenameClick,
  onRemoveClick,
  entityName,
  allText,
  allIcon,
}: {
  items: FolderType[];
  handleCreate: (title?: string) => void;
  handleChoose?: (item: FolderType) => void;
  path: string;
  entityName: string;
  paramName: string;
  onRenameClick: (id: number, title: string) => Promise<void>;
  onRemoveClick: (id: number) => Promise<void>;
  allText?: string;
  allIcon?: SvgIconType;
}): JSX.Element {
  const history = useHistory();
  const workspaceId = useCurrentWorkspaceId();
  const pathNameWithSearch = `${window.location.pathname}${window.location.search}`;
  const getOptions = (
    items: (FolderType & { icon?: SvgIconType })[],
    allItemsTitle: string = 'All'
  ) => {
    const options = items.map((item) => ({
      key: item.id.toString(),
      title: item.title,
      id: item.id,
      icon: item.icon,
    }));

    if (allText) {
      options.unshift({
        id: -1,
        key: 'all',
        title: allItemsTitle,
        icon: allIcon,
      });
    }

    return options;
  };

  const setOption = (key: string) => {
    const newUrl = `${path}${key === 'all' ? '' : `?${paramName}=${key}`}`;
    redirectUserTo(history, newUrl);

    if (handleChoose) {
      handleChoose(items.find(({ id }) => id.toString() === key));
    }
  };

  const getSelectedOption =
    (basePath: string) => (option: { key: string; title: React.ReactNode }) =>
      option.key === 'all'
        ? pathNameWithSearch === basePath
        : pathNameWithSearch === `${basePath}?${paramName}=${option.key}`;

  return (
    <SectionSelect
      classNames={{ container: 'relationships-folders__bar' }}
      options={getOptions(items, allText)}
      onChange={setOption}
      getSelectedOption={getSelectedOption(path)}
      RightComponent={() => <CreateItemComponent entityName={entityName} onCreate={handleCreate} />}
      Renderer={FolderComponent}
      onRenameClick={onRenameClick}
      onRemoveClick={onRemoveClick}
    />
  );
}

export default Folders;
